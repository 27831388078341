import {
  createMapInfo
} from './MapInfoJS'
import {
  createInfo
} from './analaysis/SVGAnalysis'
import {
  createDeviceMotionInfo_2
} from './motion/DeviceMotionJS.js' // 计算记步js 但是磁感方向需要手机传入

export function createModule() {
  const module = new Object()
  const map = new createMapInfo() // 创建地图对象
  const device_2 = new createDeviceMotionInfo_2() // 创建记步对象

  let blueBugBlock = null

  let blueListUpte = null

  function jsonKeyIsExist(json, keys) {
    if (keys == null || typeof (keys) == 'undefined' || json == null || typeof (json) == 'undefined' || keys.length ==
      0) {
      // console.log('json 格式出错')
      return false
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (key == null || typeof (key) == 'undefined') {
        continue
      }
      if (json.hasOwnProperty(key)) {
        const value = json[key]
        if (value == null || typeof (value) == 'undefined') {
          return false
        }
      } else {
        return false
      }
    }
    return true
  }

  module.blueBugAction = function (callBack) {
    blueBugBlock = callBack
  }

  module.blueListUpdateAction = function (callBack) {
    blueListUpte = callBack
  }

  module.setLookAtZone = function (callBack) {
    map.setLookAtZone(callBack)
  }

  module.setBGColor = function (color) {
    map.setBGColor(color)
  }

  // 解析svg文件地图
  module.readSvgStr = function (str, config) {
    const svg = createInfo()
    const info = svg.getSvgFileStr(str, config) // 解析出来json
    let north = 0
    let mapLead = {}
    if (jsonKeyIsExist(info, ['viewBox', 'mapinfo'])) {
      // 获取json的内容
      const box = info['viewBox']
      const width = box['width']
      const height = box['height']
      const mapinfo = info['mapinfo']
      if (jsonKeyIsExist(info, ['leadInfo'])) {
        mapLead = info['leadInfo']
      }
      if (jsonKeyIsExist(info, ['north'])) {
        north = info['north']
      }
      let showInfo = {}
      if (jsonKeyIsExist(mapinfo, ['show'])) {
        showInfo = mapinfo['show']
      }

      let nameInfo = {}
      if (jsonKeyIsExist(mapinfo, ['nameInfo'])) {
        nameInfo = mapinfo['nameInfo']
      }

      let coverInfo = []
      if (jsonKeyIsExist(mapinfo, ['coverInfo'])) {
        coverInfo = mapinfo['coverInfo']
      }

      let extraInfo = []
      if (jsonKeyIsExist(mapinfo, ['extraInfo'])) {
        extraInfo = mapinfo['extraInfo']
      }

      let lvhuaInfo = []
      if (jsonKeyIsExist(mapinfo, ['lvhuaInfo'])) {
        lvhuaInfo = mapinfo['lvhuaInfo']
      }

      let zoneInfo = []
      if (jsonKeyIsExist(mapinfo, ['zoneInfo'])) {
        zoneInfo = mapinfo['zoneInfo']
      }

      let targetInfo = []
      if (jsonKeyIsExist(mapinfo, ['target'])) {
        targetInfo = mapinfo['target']
      }

      let showOther = []
      if (jsonKeyIsExist(mapinfo, ['showOther'])) {
        showOther = mapinfo['showOther']
      }

      let wall = []
      if (jsonKeyIsExist(mapinfo, ['wall'])) {
        wall = mapinfo['wall']
      }

      let orgClass = []
      if (jsonKeyIsExist(mapinfo, ['orgClass'])) {
        orgClass = mapinfo['orgClass']
      }

      let background = [];
      if (jsonKeyIsExist(mapinfo, ['background'])) {
        background = mapinfo['background']
      }

      return {
        result: true,
        data: {
          width: width,
          height: height,
          mapinfo: mapinfo['mapinfo'],
          mapLead: mapLead,
          north: north,
          id: info['id'],
          name: info['name'],
          order: parseFloat(info['order']),
          scale: parseFloat(info['scale']),
          show: showInfo,
          nameInfo: nameInfo,
          coverInfo: coverInfo,
          extraInfo: extraInfo,
          lvhuaInfo: lvhuaInfo,
          zoneInfo: zoneInfo,
          target: targetInfo,
          showOther: showOther,
          wall: wall,
          orgClass: orgClass,
          background: background,
        }
      }
    } else {
      return {
        result: false,
        data: null
      }
    }
  }

  /* 初始化地图参数 */
  module.createMap = function (divId, width, height, mode) {
    map.setselectDevice(divId)
    map.setViewSize(width, height)
    map.init()
    // 计算步数
    device_2.move((config) => {
      let useInertia = '0';
      if (typeof localStorage['useInertia'] != 'undefined' && localStorage['useInertia'] != null) {
        useInertia = localStorage['useInertia'];
      }
      if (useInertia == '1') {
        map.getUser().moveDistance(config)
      }
    })
    device_2.returAngleAction((res1, res2) => {
      map.getUser().setPersonDirection(res1, true, 100)
    })
    map.setMaxPolarAngle(Math.PI / 3.0)
    device_2.detectDeviceorientation()
  }

  module.openDevice = function (callBack) {
    device_2.open(callBack)
  }

  module.setUpDeviceConfig = function () {
    device_2.setUpConfig();
  }


  function collectionBeaconList(info) {
    let list = [];
    if (typeof info['tc'] == 'undefined' || info['tc'] == null) {
      return list;
    }
    let tc = parseInt(info['tc']);
    if (tc <= 0) {
      return list;
    }
    for (let i = 1; i <= tc; i++) {
      let idKey = 'id' + i;
      let rssiKey = 'r' + i;
      if (typeof info[idKey] == 'undefined' || info[idKey] == null) {
        continue;
      }
      if (typeof info[rssiKey] == 'undefined' || info[rssiKey] == null) {
        continue;
      }
      let beacon = {beaconId: parseInt(info[idKey]), rssi: parseInt(info[rssiKey]) * -1};
      list.push(beacon);
    }
    return list;
  }

  let beaconListBlock = null;

  module.setBeaconListAction = function (callBack) {
    beaconListBlock = callBack;
  }

  module.getParamer = function (info) {
    const type = info['type']
    if (typeof (type) == 'undefined' || type == null) {
      return
    }
    switch (type) {
      case 'move':
        map.getUser().moveDistance(info)
        break;
      case 'coordinate':
        getBuleInfoList(info)
        getBlueAlertInfo(info)
        device_2.getDirection(info)
        map.getUser().startSetLocaitonByCoordinate(info)
        break
      case 'changeMap':
        this.showMap(info['mapid'])
        break
      case 'alpha':
        device_2.updateAlpha(info.alpha)
        break
      case 'dis':
        break
      case 'location':
        getBuleInfoList(info)
        getBlueAlertInfo(info)
        map.getUser().startSetLocaiton(info)
        break
      case 'beaconList':
        getBuleInfoList(info)
        getBlueAlertInfo(info)
        device_2.getDirection(info)
        let list = collectionBeaconList(info);
        if (beaconListBlock == null) {
          break;
        }
        beaconListBlock(list);
        break
      case 'direction':
        getBuleInfoList(info)
        getBlueAlertInfo(info)
        device_2.getDirection(info)
        map.getUser().startSetLocaitonByCoordinate(info)
        break
      case 'error':
        getBlueAlertInfo(info)
        break
      default:
        break
    }
  }

  module.makeNewMapInfo = function (config) {
    return map.makeNewMapInfo(config)
  }

  module.getNowCameraMode = function () {
    return map.getNowCameraMode()
  }

  module.setLinkStyle = function (result) {
    map.setLinkStyle(result)
  }

  module.setMapImageUrl = function (key, value) {
    map.setMapImageUrl(key, value)
  }

  module.getMapOrgName = function (mapid) {
    return map.getMapOrgName(mapid)
  }

  module.scaleAction = function (callBack) {
    map.scaleAction(callBack)
  }

  function getBlueAlertInfo(info) {
    if (blueBugBlock != null) {
      if (jsonKeyIsExist(info, ['bug'])) {
        const key = info['bug']
        const msgs = []
        if (key == 'openBlue') {
          // msgs.push('蓝牙未打开,请检查:1.手机蓝牙是否打开 2.微信蓝牙权限是否开启')
          msgs.push('请检查')
          msgs.push('1.手机蓝牙是否打开 ')
          msgs.push('2.微信蓝牙权限是否开启')
        } else if (key == 'initBlueFail') {
          msgs.push('蓝牙初始化失败')
        } else if (key == 'searchFail') {
          msgs.push('搜索蓝牙失败')
        } else if (key == 'blueBug') {
          msgs.push('您的蓝牙工作的时候开小差了,')
          msgs.push('请您手动将蓝牙关闭后再开启。')
        } else if (key == 'openGps') {
          msgs.push('请开启手机的位置信息')
        } else if (key == 'ok') {
          blueBugBlock([], 0)
          return
        }
        const isError = 1
        blueBugBlock(msgs, isError)
        return
      }
    }
  }

  function getBuleInfoList(info) {
    if (jsonKeyIsExist(info, ['debug']) == false) {
      return;
    }
    const beacons = []
    var count = 1
    for (var key in info) {
      const idKey = 'id' + count
      const rssiKey = 'r' + count
      if (info.hasOwnProperty(idKey) && info.hasOwnProperty(rssiKey)) {
        count = count + 1
        const newInfo = {}
        newInfo['id'] = info[idKey]
        newInfo['rssi'] = info[rssiKey]
        beacons.push(newInfo)
      }
    }
    if (blueListUpte != null) {
      blueListUpte(beacons, '')
    }
  }


  module.updateMapDataInfo = function (mapInfos, config, callBack) {
    map.updateMapDataInfo(mapInfos, config, callBack)
  }


  /* 传入地图id和承载地图div的id 将地图显示出来 */
  module.showMap = function (mapid, isChange) {
    map.deleteNowMap()
    map.loadMap(mapid)
  }

  module.getCameraSetting = function (config) {
    return map.getCameraSetting(config)
  }

  module.updateOrgSetting = function (config, callBack) {
    map.updateOrgSetting(config, callBack)
  }

  module.showMapAndRotate = function (config, callBack) {
    const mapid = config.mapid
    map.showMaptextHiddenOrShow(false)
    map.deleteNowMap()
    map.loadMap(mapid)
    setTimeout(() => {
      map.changeTo3D()
      map.showMaptextHiddenOrShow(true)
      callBack()
    }, 300)
  }

  module.showMapWithoutZoom = function (mapid) {
    map.deleteNowMap()
    map.loadMap(mapid)
  }

  module.zoomToSize = function (config) {
    map.zoomToSize(config)
  }

  // 直接显示地图canvas
  module.showMapRender = function () {
    map.showRender()
  }

  /* 显示第一张地图 */
  module.showFirstMap = function (isChange) {
    let newIsChange = true
    if (isChange != null && typeof (isChange) != 'undefined') {
      newIsChange = isChange
    }
    const result = map.returnFirstMapid()
    if (result.result == true) {
      this.showMap(result.id, newIsChange)
    } else {
      console.log('不存在地图id - showFirstMap -')
    }
  }


  /* 传入地图的点击事件 - 点击房间视角移动 */
  module.clickMapRoomEvent = function (event, roomInfo, config) {
    return map.clickMapRoomEvent(event, roomInfo, config)
  }

  module.clickChartLet = function (event) {
    return map.clickChartLet(event)
  }

  module.changeMapCameraMode = function (type) {
    map.changeCameraMode(type)
  }

  module.getCameraMode = function () {
    return map.getCameraMode()
  }

  /* ============================== 单个打点 ==============================  */

  /* 传入地图的点击事件 - 点击地图获取地图位置 */
  module.clickMapCoordinate = function (event) {
    return map.addPoistion(event, 'clickPoint', null)
  }
  /* ============================== *** ==============================  */

  /* 将地图放大一个等级 */
  module.mapZoomOut = function (isAdd /* true为放大 false为缩小 */) {
    map.zoomOut(isAdd)
  }

  module.change2D3DDetect = function (callBack) {
    map.change2D3DDetect(callBack)
  }

  /* 将地图设置成2D */
  module.change2D = function (config) {
    map.changeTo2D(config)
  }

  /* 将地图设置成3D */
  module.change3D = function () {
    map.changeTo3D()
  }

  module.deleteMapGroup = function () {
    map.deleteMapGroup()
  }

  module.realseMap = function () {
    map.deleteAll()
    device_2.close()
  }

  module.realseMap_forceContextLoss = function () {
    map.deleteAll()
    device_2.close()
    map.forceContextLoss();
  }


  module.reutrnPointRoomId = function (mapid, point) {
    return map.reutrnPointRoomId(mapid, point)
  }

  module.returnRoomInfoByPoint = function (mapid, point) {
    return map.returnRoomInfoByPoint(mapid, point);
  }

  module.clickMapPoint = function (event) {
    return map.clickMapPoint(event)
  }

  module.clearSelectIbeacon = function () {
    map.clearSelectIbeacon()
  }


  /* ============================== 导航信息 ==============================  */

  module.showImitateWay = function (info, leadType) {
    return map.showImitateWay(info, leadType)
  }

  module.findRoomInfo = function (info) {
    return map.findRoomInfo(info)
  }

  module.showMainViewDetailView = function (config, callBack) {
    map.showMainViewDetailView(config, callBack)
  }

  module.getZoneInfo = function () {
    return map.getZoneInfo()
  }

  module.getLookAtZoneInfo = function () {
    return map.getLookAtZoneInfo()
  }

  module.changeLinkType = function (type) {
    map.setLeadTypeInfo(type)
  }

  module.mapLeadTest = function (event) {
    map.clickMapAndLead(event)
  }

  module.leadWayMsgAction = function (callBack) {
    map.setLeadWayActionBlock(function (info) {
      callBack(info)
    })
  }

  module.changeMapidBlock = function (callBlock) {
    map.changeMapid(function (id) {
      callBlock(id)
    })
  }
  /* ============================== *** ==============================  */

  /* ============================== 测试数据 ==============================  */
  module.outPutMsgByName = function (config) {
    return map.outPutMsgByName(config)
  }

  /* 销毁地图组件 在页面回退的时候调用 */
  module.releaseMap = function () {
    map.deleteAll()
  }

  module.reset = function () {
    map.reset()
  }

  module.logShow = function (fences, fences2) {
    map.logShow(fences, fences2)
  }

  let findIndex = -1

  module.testLead = function () {
    const leadStr = ''
    const json = JSON.parse(leadStr)
    const data = json.data
    let startX = 400.5
    let startZ = 217.18
    let mapid = ''
    for (let i = (findIndex < 0 ? 0 : findIndex + 1); i < data.length; i++) {
      const info = data[i]
      if (findIndex < 0) {
        if (startX == parseFloat(info.x) && startZ == parseFloat(info.z)) {
          findIndex = i
          startX = parseFloat(info.x)
          startZ = parseFloat(info.z)
          mapid = info.mapId + ''
          break
        }
      } else {
        findIndex = i
        startX = parseFloat(info.x)
        startZ = parseFloat(info.z)
        mapid = info.mapId + ''
        break
      }
    }
    map.getUser().startSetLocaiton({
      x: startX,
      y: startZ,
      mapid: mapid,
      scale: 10
    })
  }

  module.locationTest = function (event) {
    map.locationTest(event)
  }

  module.renderAddNewEvent = function (event) {
    map.renderAddNewEvent(event)
  }

  module.renderRemoveEvent = function (event) {
    map.renderRemoveEvent(event)
  }

  /* ---------- 截图 ----------*/
  module.shot = function (callBack) {
    map.shotimage(callBack)
  }
  /* --------------------*/

  module.resizeAction = function (selector) {
    map.resizeAction(selector)
  }

  module.setMapConfig = function (config) {
    map.setMapConfig(config)
  }

  module.updateCss2DList = function (config) {
    map.updateCss2DList(config)
  }

  module.selectCss2D = function (config) {
    map.selectCss2D(config)
  }

  module.cancelSelectCss2D = function (config) {
    map.cancelSelectCss2D(config)
  }

  module.mouseMoveOnMap = function (event, config) {
    return map.mouseMoveOnMap(event, config)
  }

  module.forceToResize = function () {
    map.forceToResize()
  }

  module.getMapLocationName = function (config) {
    return map.getMapLocationName(config)
  }

  module.simulationMove = function (dataList) {
    device_2.simulationMove(dataList)
  }

  module.downFrontEXImage = function (config) {
    map.downFrontEXImage(config)
  }

  module.showFrontEXImage = function (config, callBack) {
    map.showFrontEXImage(config, callBack)
  }

  module.updateFaceImage = function (config) {
    map.updateFaceImage(config)
  }

  module.getFaceInfo = function (config) {
    return map.getFaceInfo(config)
  }

  module.changeChartColor = function (config) {
    map.changeChartColor(config)
  }

  module.clearPath = function () {
    map.clearPath()
  }

  module.returnAllMapLeadInfo = function () {
    return map.returnAllMapLeadInfo()
  }

  const positionList = []

  let positionIndex = 0

  const pStr = ''

  const pInfo = {
    data: {
      list: []
    }
  }

  module.testLeadLocation = function () {
    if (positionList.length == 0) {
      const data = pInfo.data.list
      const startTime = 1616653394000
      const endTime = 1616660594000
      for (let i = 0; i < data.length; i++) {
        const timeInfo = data[i]
        if (timeInfo.timestamp < startTime || timeInfo.timestamp > endTime) {
          continue
        }
        if (typeof timeInfo['x'] == 'undefined' || typeof timeInfo['y'] == 'undefined' || typeof timeInfo['z'] ==
          'undefined') {
          continue
        }
        if (timeInfo.x == 0 && timeInfo.y == 0 && timeInfo.z == 0) {
          continue
        }
        positionList.push(timeInfo)
      }
    }

    if (positionIndex >= positionList.length) {
      console.log('end')
      return
    }
    const timeInfo = positionList[positionIndex]
    const info = {
      type: 'location',
      x: timeInfo.x,
      y: timeInfo.z,
      scale: 10,
      mapid: timeInfo.mapId,
      direction: timeInfo.direction,
      mode: 3
    }
    device_2.getDirection(info)
    map.getUser().startSetLocaiton(info)
    positionIndex++
  }

  module.hiddenZero = function () {
    map.hiddenZero();
  }

  module.returnScene = function () {
    return map.returnScene();
  }

  module.forceContextLoss = function () {
    map.forceContextLoss();
  }

  module.getMap = function () {
    return map;
  }


  return module
}
