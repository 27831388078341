import {createAStart} from "./AStartLead";

import {createMatrixInfo} from "../../analaysis/makeMatrix";

export function createUserManager_location(manager) {

  let matrix = createMatrixInfo() // 导航路线计算的js

  let gpsLeadWay = {
    ways: [],
    selectWay: ''
  }

  manager.changeGpsWay = function (type) {
    gpsLeadWay.selectWay = type
  }

  let aStart = createAStart();

  let analysis = manager.getAnalysis();

  let changeMapBlock = null;

  manager.changeMapAction = function (callBack) {
    changeMapBlock = callBack;
  }

  let updateLeadGroupBlock = null;

  manager.updateLeadGroupAction = function (callBack) {
    updateLeadGroupBlock = callBack;
  }

  let userGroup = manager.getUser();

  let mapSetInfo = manager.getMapSetInfo();

  let leadWay = manager.getLeadWay();

  let lockGetNewPath = false;

  let zeroInfoBlock = null;

  manager.zeroInfoAction = function (callBack) {
    zeroInfoBlock = callBack;
  }

  let fristLocationBlock = null;

  manager.userLocationMsg = function (callBack) {
    fristLocationBlock = callBack;
  }


  let showLastBlock = null;

  manager.showLastAction = function (callBack) {
    showLastBlock = callBack;
  }

  function setPhoneLocation(config) {
    let x = config['x']
    let z = config['z']
    let shouldZoom = config['shouldZoom']
    let mapid = config['mapid']
    let accuracy = config['accuracy']

    let translateX = 0
    let translateZ = 0
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let floorHieght = mapInfo['floorHieght'];
    let zero = zeroInfoBlock(mapid);
    let fence = zero['areas'];
    let isOutOfMap = !analysis.isInPolygon([x, 0, z], fence)
    let direction = manager.rotateDirection()
    let cameraModeBlock = manager.getCameraMode();
    let cameraMode = cameraModeBlock();

    let phone = manager.phoneInfo();
    userGroup.showUser({
      isDirectionAble: phone.isDirectionAble,
      cameraMode: cameraMode.selectCameraMode,
      x: x,
      y: floorHieght,
      z: z,
      shouldZoom: shouldZoom,
      mapid: mapid,
      direction: direction,
      translateX: translateX,
      translateZ: translateZ,
      isOutOfMap: isOutOfMap,
      accuracy: accuracy
    })
    manager.updateUserPhoneLocation()
    manager.setUserPhoneStyle({
      default: false,
      x: x,
      z: z,
      mapid: mapid
    })
    if (fristLocationBlock == null) {
      return;
    }
    let roomInfoByPointBlock = manager.getRoomInfoByPoint();
    let roomInfo = roomInfoByPointBlock(mapid, {
      x: x,
      z: z
    })
    let locationBlock = manager.getLocation();
    let positionRes = locationBlock({
      mapid: mapid,
      x: x,
      z: z
    })


    let positionName = positionRes.locationName;
    let relible = true

    if (analysis.isInPolygon([x, 0, z], fence) == false) {
      relible = false
    }

    let locationType = '';
    if (analysis.jsonKeyIsExist(config, ['locationType'])) {
      locationType = config['locationType'];
    }

    fristLocationBlock({
      isOutOfMap: isOutOfMap,
      result: relible,
      name: positionName,
      point: {
        orgClassId: positionRes.orgClassId,
        zoneInfoId: positionRes.zoneId,
        locationType: locationType,
        positionName: positionName,
        centerY: 0.1414,
        id: 65535,
        roomAreaId: roomInfo['areaid'],
        roomName: '',
        x: x,
        y: mapInfo['floorHeight'],
        z: z,
        mapid: mapid,
        direction: direction
      }
    })
  }

  manager.setPhoneLocation = function (config) {
    setPhoneLocation(config);
  }

  manager.moveDistance = function (setting) {
    if (lockGetNewPath) {
      return;
    }
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    if (leadCubeInfo.shouldSendLeadMsg == false) {
      return;
    }

    let useInertia = '1';
    if (analysis.jsonKeyIsExist(setting, ['useInertia'])) {
      useInertia = setting['useInertia'];
    }

    let user = userGroup.returnZoomObject()

    let direction = -1 * manager.rotateDirection();

    let finialDis = mapSetInfo.finialDis != null && isNaN(mapSetInfo.finialDis) == false ? mapSetInfo.finialDis : 11

    let leadDirectionType = mapSetInfo['leadDirectionType'];
    let pathInfoBlock = manager.getPathInfo();
    let pathInfo = pathInfoBlock();

    let zero = zeroInfoBlock(user.mapid)

    let config = {
      useInertia: useInertia,
      finialDis: finialDis * 10,
      zeroId: zero.areaid,
      findInfo: leadCubeInfo.groupInfo['findInfo'],
      userNewLocation: leadCubeInfo.groupInfo['userNewLocation'],
      shouldDirection: true,
      direction: direction,
      scale: 10,
      userLocatin: {
        x: user.position.x,
        y: 0,
        z: user.position.z,
        mapid: user.mapid
      },
      mapid: user.mapid,
      leadInfo: pathInfo,
      leadDirectionType: mapSetInfo['leadDirectionType']
    };
    if (useInertia == '1') {
      let distance = parseFloat(setting['distance']);
      config['distance'] = distance * 10;
    }

    leadWay.leadWhenMove(config).then(res => {
      if (res.result == false) {
        leadWay.forceReverse()
        let updateLeadInfoBlock = manager.getUpdateLeadInfo();
        updateLeadInfoBlock(res, {
          isPlayMusic: false,
          update: true
        });
        return;
      }
      let updateLeadInfo_groupInfoBlock = manager.getUpdateLeadInfo_groupInfo();
      updateLeadInfo_groupInfoBlock(res);

      let promise = new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 100)
      })

      let p = res.point;
      if (p.mapid != user.mapid) {
        promise = changeMapBlock(p.mapid);
      }
      promise.then(r => {
        let point = res.point;
        // 更新用户定位
        setPhoneLocation({
          accuracy: 0,
          x: point.x,
          z: point.z,
          shouldZoom: false,
          showImage: false,
          mapid: point.mapid
        })
        updateLeadGroupBlock(res);
        userGroup.updateUserDirection(res)
        let updateLeadInfoBlock = manager.getUpdateLeadInfo();
        updateLeadInfoBlock(res, {
          isPlayMusic: true,
          update: true,
          rotate: leadDirectionType == 'byWay' ? true : false,
        });
      })
    });
  }

  let pointToAllowLocationBlock = null;

  manager.pointToAllowLocationAction = function (callBack) {
    pointToAllowLocationBlock = callBack;
  }


  // 设置用户定位
  function startSetPhoneLocation(locationConfig) {
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    let groupInfo = leadCubeInfo.groupInfo;

    let showLast = false;
    if (analysis.jsonKeyIsExist(groupInfo, ['showLastWay'])) {
      showLast = groupInfo['showLastWay']
    }

    locationConfig['time'] = new Date().getTime(); // 获取时间戳
    groupInfo['userNewLocation'] = locationConfig;

    let phone = manager.phoneInfo();

    if (showLast) {
      phone.continueLocation = true
      return;
    }

    let useInertia = '0';
    if (analysis.jsonKeyIsExist(localStorage, ['useInertia'])) {
      useInertia = localStorage['useInertia'];
    }

    let cameraModeBlock = manager.getCameraMode();
    let cameraMode = cameraModeBlock();

    if (useInertia == '1') {
      // 使用惯导
      if (leadCubeInfo.shouldSendLeadMsg && cameraMode.selectCameraMode != cameraMode.cameraMode.simulation) {
        phone.continueLocation = true
        return;
      }
    }


    let x = locationConfig['x']
    let z = locationConfig['z']
    let scale = locationConfig['scale']
    let mapid = locationConfig['mapid']
    let shouldZoom = locationConfig['shouldZoom']
    let shouldCorrect = locationConfig['shouldCorrect']
    let accuracy = 0
    if (analysis.jsonKeyIsExist(locationConfig, ['accuracy'])) {
      accuracy = parseFloat(locationConfig['accuracy'])
    }

    function updateUserLocation(shouldChange, shouldZoom, mapid, X, Z, locationType) {
      let promise = new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 100)
      })
      if (shouldChange && cameraMode.selectCameraMode != cameraMode.cameraMode.simulation) {
        promise = changeMapBlock(mapid);
      }
      promise.then(r => {
        // 更新用户定位
        setPhoneLocation({
          accuracy: accuracy,
          x: X,
          z: Z,
          shouldZoom: shouldZoom,
          showImage: showImage,
          mapid: mapid,
          locationType: locationType,
        })
        phone.continueLocation = true
      })
    }

    if (phone.continueLocation == false) {
      return
    }
    phone.continueLocation = false
    let shouldChange = false
    let user = userGroup.returnZoomObject()
    if (user == null || user.mapid != mapid) {
      shouldChange = true
    }
    if (user == null && manager.isLeadWayExist() == true) {
      shouldChange = false
    }
    let showImage = false
    let X = x
    let Z = z


    if (leadCubeInfo.shouldSendLeadMsg && cameraMode.selectCameraMode != cameraMode.cameraMode.simulation) {
      // 更新导航信息
      manager.moveDistance({useInertia: '0'})
      phone.continueLocation = true
      return;
    }
    let allowNoLink = '0';
    if (typeof localStorage['allowNoLink'] != 'undefined' && localStorage['allowNoLink'] != null) {
      allowNoLink = localStorage['allowNoLink'];
    }


    if (allowNoLink == '1') {
      // 墙体纠偏
      let res = pointToAllowLocationBlock(mapid, X, Z, shouldCorrect)
      if (res.result == false) {
        phone.continueLocation = true
        return
      }
      X = res.x
      Z = res.z
    }
    let locationType = '';
    if (analysis.jsonKeyIsExist(locationConfig, ['locationType'])) {
      locationType = locationConfig['locationType']
    }
    updateUserLocation(shouldChange, shouldZoom, mapid, X, Z, locationType)
  }


  manager.testLocation = function (x, z, mapid, scale) {
    startSetPhoneLocation({
      x: x,
      z: z,
      scale: scale,
      mapid: mapid,
      shouldZoom: false,
      shouldCorrect: true
    })
  }

  // 通过蓝牙定位
  manager.startSetLocaiton = function (result) {
    if (analysis.jsonKeyIsExist(result, ['x', 'y', 'mapid']) == false) {
      return
    }
    result['z'] = parseFloat(result.y)
    result['x'] = parseFloat(result.x)

    if (analysis.jsonKeyIsExist(result, ['lat', 'lng']) && mapSetInfo.openTheWorld) {
      let lng = 113.12477
      let lat = 23.0063
      if (analysis.jsonKeyIsExist(result, ['lng'])) {
        lng = result['lng']
      }
      if (analysis.jsonKeyIsExist(result, ['lat'])) {
        lat = result['lat']
      }
      let positionRes = world.coordinateToThree(parseFloat(lng), parseFloat(lat), 16)
      if (positionRes.result) {
        result['x'] = positionRes.x
        result['z'] = positionRes.z
      } else {
        return;
      }
    }

    result['shouldZoom'] = false
    result['shouldCorrect'] = true

    startSetPhoneLocation(result)
  }

  let coordinateToThreeBlock = null;

  manager.coordinateToThreeAction = function (callBack) {
    coordinateToThreeBlock = callBack;
  }

  let threeToCoordinateBlock = null;

  manager.threeToCoordinateAction = function (callBack) {
    threeToCoordinateBlock = callBack;
  }

  // 通过经纬度定位
  manager.startSetLocaitonByCoordinate = function (config) {
    if (analysis.jsonKeyIsExist(config, ['lat', 'lng']) == false || mapSetInfo.openTheWorld == false) {
      return
    }
    let lng = 113.12477
    let lat = 23.0063
    if (analysis.jsonKeyIsExist(config, ['lng'])) {
      lng = config['lng']
    }
    if (analysis.jsonKeyIsExist(config, ['lat'])) {
      lat = config['lat']
    }

    let positionRes = coordinateToThreeBlock(parseFloat(lng), parseFloat(lat))
    if (positionRes.result == false) {
      return
    }
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let zero = zeroInfoBlock(mapInfo['mapid']);
    let orderList = mapInfo['orderList'];
    let zeroAreas = zero['areas'];
    if (analysis.isInPolygon([positionRes.x, 0, positionRes.z], zeroAreas)) {
      return
    }

    let x = positionRes.x
    let z = positionRes.z
    let locationConfig = {}
    locationConfig['x'] = x
    locationConfig['y'] = z
    locationConfig['scale'] = 10
    locationConfig['type'] = 'coordinate'
    if (analysis.jsonKeyIsExist(config, ['mapid']) == false) {
      let mapList = orderList
      if (mapList.length == 0) {
        return
      }
      let info = mapList[mapList.length - 1]
      locationConfig['mapid'] = info['mapid']
    } else {
      locationConfig['mapid'] = config['mapid']
    }

    if (analysis.jsonKeyIsExist(config, ['direction'])) {
      locationConfig['direction'] = config['direction']
    }

    if (analysis.jsonKeyIsExist(config, ['mode'])) {
      locationConfig['mode'] = config['mode']
    }

    if (analysis.jsonKeyIsExist(config, ['accuracy'])) {
      let accuracy = config['accuracy']
    }
    locationConfig['locationType'] = 'GPS';
    this.startSetLocaiton(locationConfig)
  }

  let startLeadBlock = null;

  manager.startLeadAction = function (callBack) {
    startLeadBlock = callBack;
  }


  // 更改地图发送消息状态
  function changeMsgStatus(change) {
    if (change == null || typeof (change) == 'undefined') {
      return
    }
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    leadCubeInfo.shouldSendLeadMsg = change
    if (userGroup.returnDirectionAble() == false) {
      return;
    }
    userGroup.changeUserStyle({
      type: change ? 'lead' : 'rotate'
    })
  }

  // 开始导航状态
  manager.startLead = function (callBack) {
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    let user = userGroup.returnZoomObject()
    let isOutOfMap = userGroup.returnIsOutOfMap()
    if (user == null && isOutOfMap) {
      callBack({
        result: false
      })
      leadCubeInfo.userStatusLead = false;
      return
    }
    leadCubeInfo.userStatusLead = true;
    startLeadBlock({
      'room': '',
      'point': '',
      'sharePoint': ''
    })
    changeMsgStatus(true)
    let phone = manager.phoneInfo();

    userGroup.setDirectionShow({
      result: phone.isDirectionAble
    })
    let direction = -1 * manager.rotateDirection();
    let zeroInfo = zeroInfoBlock(user.mapid);
    let pathInfoBlock = manager.getPathInfo();
    let pathInfo = pathInfoBlock();
    let config = {
      zeroId: zeroInfo.areaid,
      shouldDirection: true,
      direction: direction,
      scale: 10,
      userLocatin: {
        x: user.position.x,
        y: 0,
        z: user.position.z,
        mapid: user.mapid
      },
      distance: 0,
      mapid: user.mapid,
      leadInfo: pathInfo,
      leadDirectionType: mapSetInfo['leadDirectionType']
    };
    leadWay.startLead(config).then(res => {
      if (res.result == false) {
        leadWay.forceReverse()
        let updateLeadInfoBlock = manager.getUpdateLeadInfo();
        updateLeadInfoBlock(res, {
          isPlayMusic: false,
          update: true
        });
        callBack({
          result: false,
          msg: '',
          mapid: user.mapid
        })
        return;
      }

      leadCubeInfo.groupInfo['findInfo'] = res
      updateLeadGroupBlock(res);
      let updateLeadInfoBlock = manager.getUpdateLeadInfo();
      updateLeadInfoBlock(res, {
        isPlayMusic: true,
        update: true,
        rotate: true,
        setSize: true,
      });
      let point = res.point;
      // 更新用户定位
      setPhoneLocation({
        accuracy: 0,
        x: point.x,
        z: point.z,
        shouldZoom: false,
        showImage: false,
        mapid: point.mapid
      })
      callBack({
        result: true,
        msg: '',
        mapid: user.mapid
      })
    });
  }

  // 结束导航状态
  manager.quitLead = function (config) {
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    leadCubeInfo.userStatusLead = false;
    lockGetNewPath = false;
    aStart.cleanExtra()
    gpsLeadWay.ways = []
    gpsLeadWay.selectWay = ''
    userGroup.setDirectionShow({
      result: false
    })
    changeMsgStatus(false)
    let showLast = false
    if (analysis.jsonKeyIsExist(config, ['showLast'])) {
      showLast = config['showLast']
    }
    delete leadCubeInfo.groupInfo['findInfo']
    showLastBlock(showLast);
  }

  // 重新规划路线
  manager.getNewPath = function (callBack) {
    if (lockGetNewPath) {
      callBack({
        result: false
      })
      return;
    }
    lockGetNewPath = true;
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    if (analysis.jsonKeyIsExist(leadCubeInfo.groupInfo, ['userNewLocation']) == false) {
      lockGetNewPath = false;
      callBack({
        result: false
      })
      return;
    }
    let user = userGroup.returnZoomObject()
    if (user == null) {
      lockGetNewPath = false;
      callBack({
        result: false
      })
      return;
    }
    let userNewLocation = leadCubeInfo.groupInfo['userNewLocation'];
    let mapid = userNewLocation.mapid;
    let order = leadCubeInfo.leadOrder[0]
    let endPath = leadCubeInfo.leadPath[order.mapid]
    let endInfos = endPath[0].path
    let endInfo = endInfos[0]

    let startPoint = {
      x: userNewLocation.x,
      y: 0,
      z: userNewLocation.z
    }
    leadWay.forceReverse()
    findLeadWay(startPoint, mapid, endInfo, order.mapid, leadCubeInfo.linkType, (res) => {
      if (res.isLink == false) {
        setTimeout(() => {
          lockGetNewPath = false;
        }, 2000)
        callBack({
          result: false
        })
        return
      }
      if (res.isLink == true) {
        leadCubeInfo.leadPath = res.indexs
        leadCubeInfo.poInfo = res.poInfo
        leadCubeInfo.leadOrder = res.mapOrder
        leadCubeInfo.zoneIdInfo = res.zoneIdInfo
      }
      manager.startLead((res) => {
        callBack(res)
        setTimeout(() => {
          lockGetNewPath = false;
        }, 2000)
      })
    })
  }

  let targetBlock = null;

  manager.targetAction = function (callBack) {
    targetBlock = callBack;
  }

  let mapJson_WallBlock = null;

  manager.mapJson_WallAction = function (callBack) {
    mapJson_WallBlock = callBack;
  }

  let zoneInfoBlock = null;

  manager.zoneInfoAction = function (callBack) {
    zoneInfoBlock = callBack;
  }

  let updateLeadWayBlock = null;
  manager.updateLeadWayAction = function (callBack) {
    updateLeadWayBlock = callBack;
  }

  let mapNameBlock = null;

  manager.mapNameAction = function (callBack) {
    mapNameBlock = callBack;
  }

  let zoomFixBlock = null;

  manager.zoomFixAction = function (callBack) {
    zoomFixBlock = callBack;
  }

  function createStartAndEndInfo(startPoint, startMapid, endPoint, endMapid, config, callBack) {
    let target = targetBlock(endMapid)
    let findId = parseInt(endPoint.x) + ' ~ ' + parseInt(endPoint.z)
    if (analysis.jsonKeyIsExist(target, [findId])) {
      let find = target[findId]
      endPoint.x = find.tx
      endPoint.z = find.tz
    }
    let roomInfoByPointBlock = manager.getRoomInfoByPoint();
    let startRoom = {}

    if (analysis.jsonKeyIsExist(startRoom, ['areas', 'areaid', 'title'])) {
      startRoom['areas'] = endPoint['areas']
      startRoom['areaid'] = endPoint['areaid']
      startRoom['title'] = endPoint['title']
    } else {
      startRoom = roomInfoByPointBlock(startMapid, startPoint)
    }

    let endRoom = {}

    if (analysis.jsonKeyIsExist(endPoint, ['areas', 'areaid', 'title'])) {
      endRoom['areas'] = endPoint['areas']
      endRoom['areaid'] = endPoint['areaid']
      endRoom['title'] = endPoint['title']
    } else {
      endRoom = roomInfoByPointBlock(endMapid, endPoint)
    }

    let startP = {
      mapid: startMapid,
      x: startPoint['x'],
      z: startPoint['z'],
      room: {
        areas: startRoom['areas'],
        areaid: startRoom['areaid'],
        title: startRoom['title']
      }
    }
    let endP = {
      mapid: endMapid,
      x: endPoint['x'],
      z: endPoint['z'],
      room: {
        areas: endRoom['areas'],
        areaid: endRoom['areaid'],
        title: endRoom['title']
      }
    }

    leadWay.clearRemarkRecord()
    let startZero = zeroInfoBlock(startMapid)
    let endZero = zeroInfoBlock(endMapid)

    let startZeroId = startZero.areaid;
    let endZeroId = endZero.areaid;
    let startMap = mapJson_WallBlock(startMapid)
    let startWall = startMap.wall;
    let startMapInfo = startMap.mapJson;
    let endMap = mapJson_WallBlock(endMapid)
    let endWall = endMap.wall;
    let endMapInfo = endMap.mapJson;
    aStart.findStartAndEndLink({
      startWall: startWall,
      startMapInfo: startMapInfo,
      endWall: endWall,
      endMapInfo: endMapInfo,
      start: startP,
      end: endP,
      startZeroId: startZeroId,
      endZeroId: endZeroId
    }).then(res => {
      if (res.result == true) {
        // 寻找开始楼层存在的上楼方式
        let types = []
        if (analysis.jsonKeyIsExist(config, ['leadType'])) {
          types.push(config['leadType'])
        } else {
          types = aStart.getLeadTypes({
            startMapid: startMapid
          })
        }
        res['types'] = types
      }
      callBack(res)
    })
  }

  function findLeadWay(startPoint, startMapid, endPoint, endMapid, leadType, callBack) {
    createStartAndEndInfo(startPoint, startMapid, endPoint, endMapid, {
      leadType: leadType
    }, (res) => {
      if (res.result == false) {
        callBack({
          isLink: false,
          indexs: [],
          info: []
        })
        return
      }
      let zoneInfo = {}
      let mapInfoBlock = manager.getMapInfo();
      let mapInfo = mapInfoBlock();
      let mapOrderList = mapInfo['orderList'];
      for (let i = 0; i < mapOrderList.length; i++) {
        let order = mapOrderList[i]
        let orderMapid = order.mapid
        zoneInfo[orderMapid] = zoneInfoBlock(orderMapid)
      }
      aStart.getLeadWay({
        leadInfo: res.leadInfo,
        start: res.start,
        end: res.end,
        zoneInfo: zoneInfo,
        startMapid: res.start['mapid']
      }, res.types, (findResult) => {
        callBack(findResult)
      })
    })
  }

  function getLeadWay_showLeadWay(startPoint, startMapid, endPoint, endMapid) {
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    findLeadWay(startPoint, startMapid, endPoint, endMapid, leadCubeInfo.linkType, (result) => {
      if (result.isLink == false) {
        updateLeadWayBlock({result: false})
        return
      }
      leadCubeInfo.leadPath = result.indexs
      leadCubeInfo.poInfo = result.poInfo
      leadCubeInfo.leadOrder = result.mapOrder
      leadCubeInfo.zoneIdInfo = result.zoneIdInfo
      updateLeadWayBlock({result: true})
    })
  }

  function aStartFindWay(startPoint, startMapid, endPoint, endMapid, config, callBack) {
    createStartAndEndInfo(startPoint, startMapid, endPoint, endMapid, config, (res) => {
      if (res.result == false) {
        // 此路不通
        callBack({
          result: false,
          data: {
            noWay: true
          }
        })
        return
      }
      let groupInfoBlock = manager.getGroupInfo();
      let leadCubeInfo = groupInfoBlock();
      let zoneInfo = {}
      let mapInfoBlock = manager.getMapInfo();
      let mapInfo = mapInfoBlock();
      let mapOrderList = mapInfo['orderList'];
      for (let i = 0; i < mapOrderList.length; i++) {
        let order = mapOrderList[i]
        let orderMapid = order.mapid
        zoneInfo[orderMapid] = zoneInfoBlock(orderMapid)

      }
      aStart.startGetLeadWay({
        isPo: leadCubeInfo.isPo,
        leadInfo: res.leadInfo,
        start: res.start,
        end: res.end,
        zoneInfo: zoneInfo,
        startMapid: res.start['mapid']
      }, res.types, (findResult) => {
        callBack(findResult)
      })
    })
  }

  function startFindWay(startPoint, info, po, config, callBack) {
    new Promise((resolve, reject) => {
      let groupInfoBlock = manager.getGroupInfo();
      let leadCubeInfo = groupInfoBlock();
      if (analysis.jsonKeyIsExist(info, ['mapId'])) {
        info['mapid'] = info['mapId'] + ''
      }
      let isPo = true
      if (po != null && typeof (po) != 'undefined') {
        isPo = po
      }
      leadCubeInfo.isPo = isPo
      let roomInfoByIdBlock = manager.getRoomInfoById();
      let endPointRes = roomInfoByIdBlock(info)
      let startPointRes = roomInfoByIdBlock(startPoint)

      if (endPointRes.result == false || startPointRes.result == false) {
        callBack({
          result: false,
          msg: 'msg error',
          data: null
        })
        reject()
        return
      }
      let endPoint = endPointRes.pointInfo
      let start = startPointRes.pointInfo
      aStartFindWay(start, start['mapid'], endPoint, endPoint['mapid'], config, (res) => {
        if (res.result == false) {
          resolve(res)
          return
        }
        updateLeadWayBlock({result: false, clearPath: true})
        let type = res.data.type
        res.data.type = type
        leadCubeInfo.linkType = res.data.typeNum
        let way = res.data.way[type]
        leadCubeInfo.leadPath = way.list
        leadCubeInfo.poInfo = way.po
        leadCubeInfo.leadOrder = way.order
        leadCubeInfo.zoneIdInfo = way.zoneIdInfo
        for (let key in res.data.mapOrderInfo) {
          let order = res.data.mapOrderInfo[key]
          for (let i = 0; i < order.length; i++) {
            let mapid = order[i].mapid
            let msg = mapNameBlock(order[i].mapid) + (i == order.length - 1 ? endPoint['name'] : '')
            if (mapid == start['mapid']) {
              msg = mapNameBlock(start['mapid'])
            } else if (mapid == endPoint['mapid']) {
              let locationBlock = manager.getLocation();
              let positionRes = locationBlock({
                mapid: endPoint['mapid'],
                x: endPoint.x,
                z: endPoint.z
              })
              msg = positionRes.locationName + ' ' + endPoint['name']
            }
            order[i].mapName = msg
          }
        }
        resolve(res)
      })
    }).then(function (res) {
      let mapInfoBlock = manager.getMapInfo();
      let mapInfo = mapInfoBlock();
      if (mapInfo['mapid'] != startPoint.mapid && res.data.noWay == false) {
        changeMapBlock(startPoint.mapid)
      }
      res['gpsWay'] = gpsLeadWay.ways
      res['selectWay'] = gpsLeadWay.selectWay
      callBack(res)
      if (res.data.noWay == false) {
        zoomFixBlock(startPoint.mapid);
      }
    }, function (reject) {

    })
  }

  function changeCoordinatePath(gaodePathRes, startPoint, endPointInfo, po, mapid, callBack) {
    /* ------------------------------------- */
    function changeing(result, startPoint) {
      return new Promise(resolve => {
        let user = userGroup.returnZoomObject()
        let coordinatePoints = result['coordinatePoints']
        let threePoints = []
        if (analysis.jsonKeyIsExist(startPoint, ['x', 'z'])) {
          threePoints.push({
            result: true,
            x: startPoint.x,
            z: startPoint.z
          })
        }
        for (let i = coordinatePoints.length - 1; i >= 0; i--) {
          let c = coordinatePoints[i]
          let p = coordinateToThreeBlock(c.lng, c.lat, 16)
          threePoints.push(p)
        }
        if (threePoints.length > 0) {
          let lastP = threePoints[threePoints.length - 1]
          if (lastP.x != user.position.x && lastP.z != user.position.z) {
            threePoints.push({
              result: true,
              x: user.position.x,
              z: user.position.z
            })
          }
        }

        let extra = {
          type: result['type'],
          path: threePoints,
          mapid: mapid
        }
        aStart.setUpExtra(extra)

        resolve({
          result: true,
          type: result['type'],
          distanceMsg: result['distanceMsg'],
          durationMsg: result['durationMsg']
        })
      })
    }

    /* ------------------------------------- */

    let errorCount = 0
    let promiseList = []
    for (let i = 0; i < gaodePathRes.length; i++) {
      let res = gaodePathRes[i]
      if (res.result == false) {
        errorCount++
        continue
      }
      promiseList.push(changeing(res, startPoint))
    }
    if (errorCount == gaodePathRes.length) {
      callBack({
        result: false
      })
      return
    }

    Promise.all(promiseList).then(res => {
      let ways = []
      let selectWay = ''
      for (let i = 0; i < res.length; i++) {
        let r = res[i]
        if (r.result == false) {
          continue
        }
        let t = r.type
        let msg = ''
        if (t == 'driving') {
          msg = '驾车'
        } else if (t == 'walking') {
          msg = '步行'
        }
        ways.push({
          type: t,
          msg: msg,
          distanceMsg: r['distanceMsg'],
          durationMsg: r['durationMsg']
        })
        if (selectWay == '') {
          selectWay = t
        }
      }
      gpsLeadWay.ways = ways
      gpsLeadWay.selectWay = selectWay
      startFindWay({
        x: startPoint.x,
        y: 0,
        z: startPoint.z,
        mapid: mapid
      }, endPointInfo, po, {}, (result) => {
        callBack(result)
      })
    })
  }


  manager.getLeadWay_showLeadWay = function (startPoint, startMapid, endPoint, endMapid) {
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    findLeadWay(startPoint, startMapid, endPoint, endMapid, leadCubeInfo.linkType, (result) => {
      if (result.isLink == false) {
        updateLeadWayBlock({result: false})
        return
      }
      leadCubeInfo.leadPath = result.indexs
      leadCubeInfo.poInfo = result.poInfo
      leadCubeInfo.leadOrder = result.mapOrder
      leadCubeInfo.zoneIdInfo = result.zoneIdInfo
      updateLeadWayBlock({result: true})
    })
  }

  manager.findLeadWay = function (startPoint, startMapid, endPoint, endMapid, leadType, callBack) {
    findLeadWay(startPoint, startMapid, endPoint, endMapid, leadType, callBack)
  }

  // 重新规划路线
  manager.getNewPath = function (callBack) {
    if (lockGetNewPath) {
      callBack({
        result: false
      })
      return;
    }
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    lockGetNewPath = true;
    if (analysis.jsonKeyIsExist(leadCubeInfo.groupInfo, ['userNewLocation']) == false) {
      lockGetNewPath = false;
      callBack({
        result: false
      })
      return;
    }
    let user = userGroup.returnZoomObject()
    if (user == null) {
      lockGetNewPath = false;
      callBack({
        result: false
      })
      return;
    }
    let userNewLocation = leadCubeInfo.groupInfo['userNewLocation'];
    let mapid = userNewLocation.mapid;
    let order = leadCubeInfo.leadOrder[0]
    let endPath = leadCubeInfo.leadPath[order.mapid]
    let endInfos = endPath[0].path
    let endInfo = endInfos[0]

    let startPoint = {
      x: userNewLocation.x,
      y: 0,
      z: userNewLocation.z
    }
    leadWay.forceReverse()
    findLeadWay(startPoint, mapid, endInfo, order.mapid, leadCubeInfo.linkType, (res) => {
      if (res.isLink == false) {
        console.log('res.isLink == false return')
        setTimeout(() => {
          lockGetNewPath = false;
        }, 2000)
        callBack({
          result: false
        })
        return
      }
      if (res.isLink == true) {
        leadCubeInfo.leadPath = res.indexs
        leadCubeInfo.poInfo = res.poInfo
        leadCubeInfo.leadOrder = res.mapOrder
        leadCubeInfo.zoneIdInfo = res.zoneIdInfo
      }
      manager.startLead((res) => {
        callBack({
          result: true
        })
        setTimeout(() => {
          lockGetNewPath = false;
        }, 2000)
      })
    })
  }

  manager.gpsLeadWay = function () {
    return gpsLeadWay;
  }

  /* 传入需要寻找房间的id 规划路线 */
  manager.findWayByRoomid = function (info, po, start, isUser, callBack) {
    aStart.cleanExtra()
    gpsLeadWay.ways = []
    gpsLeadWay.selectWay = ''
    if (isUser) {
      let user = userGroup.returnZoomObject()
      let isOutOfMap = userGroup.returnIsOutOfMap() // 室外
      if (user == null) {
        if (info != null && typeof (info) != 'undefined') {
          setTimeout(() => {
            startFindWay(start, info, po, {}, callBack)
          }, 100)
        } else {
          callBack({
            result: false,
            msg: '当前用户尚未存在位置',
            data: null
          })
        }
      } else {
        if (isOutOfMap && mapSetInfo.openTheWorld) {
          findOutDoorPath(user, start, info, po, callBack)
        } else {
          setTimeout(() => {
            startFindWay({
              x: user.position.x,
              y: user.position.y,
              z: user.position.z,
              mapid: user.mapid
            }, info, po, {}, callBack)
          }, 100)
        }
      }
    } else {
      setTimeout(() => {
        startFindWay(start, info, po, {}, callBack)
      }, 100)
    }
  }

  function findOutDoorPath(user, start, endPointInfo, po, callBack) {
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let mapOrderList = mapInfo['orderList'];
    // 转换用户经纬度和导航门口的经纬度
    let gpsRes = aStart.returnLeadGpsDoor(mapOrderList);
    if (gpsRes.result) {
      let res = threeToCoordinateBlock(user.position.x, user.position.z)
      let gps = threeToCoordinateBlock(gpsRes.x, gpsRes.z)
      let config = {
        origin: res.lng + ',' + res.lat,
        destination: gps.lng + ',' + gps.lat
      }
      aStart.getGaoDePath(config, (gaodePathRes) => {
        changeCoordinatePath(gaodePathRes, {
          x: gpsRes.x,
          z: gpsRes.z
        }, endPointInfo, po, gpsRes.mapid, callBack)
      })
    } else {
      startFindWay(start, endPointInfo, po, {}, callBack)
    }
  }

  manager.deleteAll = function () {
    userGroup.deleteAll();
    aStart.deleteAll();
  }

  manager.getExtraPath = function (mapid) {
    return aStart.getExtraPath({
      type: gpsLeadWay.selectWay,
      mapid: mapid
    })
  }

  manager.returnLeadGpsDoor = function () {
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let mapOrderList = mapInfo['orderList'];
    return aStart.returnLeadGpsDoor(mapOrderList)
  }

  /* 获取实景图数据 */
  manager.getReality = function (datas) {
    let mapNameInfo = {}
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let mapOrderList = mapInfo['orderList'];
    for (let i = 0; i < mapOrderList.length; i++) {
      let info = mapOrderList[i]
      mapNameInfo[info.mapid] = mapNameBlock(info.mapid)
    }
    aStart.getReality(datas, mapNameInfo)
  }

  // 更新楼层连接信息
  manager.updateLinkFloor = function (dataInfo) {
    aStart.updateLinkFloor(dataInfo)
  }

  let leadInfoBlock = null;

  manager.leadInfoAction = function (callBack) {
    leadInfoBlock = callBack;
  }

  manager.updateAStartLeadInfo = function () {
    let leadInfo = {}
    let scaleInfo = {}
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let mapOrderList = mapInfo['orderList'];
    for (let i = 0; i < mapOrderList.length; i++) {
      let info = mapOrderList[i]
      leadInfo[info.mapid] = leadInfoBlock(info.mapid);
      scaleInfo[info.mapid] = 10
    }

    aStart.linkFloors({
      leadInfo: leadInfo,
      mapOrderList: mapOrderList
    })
  }

  /* 更新平移节点 用于多楼层 */
  manager.updateLeadInfo = function () {
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let mapOrderList = mapInfo['orderList'];
    aStart.updateLeadInfo({
      mapOrderList: mapOrderList,
    })
  }

  manager.setIsPo = function (isPo) {
    let groupInfoBlock = manager.getGroupInfo();
    let leadCubeInfo = groupInfoBlock();
    leadCubeInfo.isPo = isPo
  }

  manager.changeUserDirection = function () {
    return manager.isLeadWayExist() && lockGetNewPath == false;
  }

  manager.leadWhenMove = function (config) {
    return leadWay.leadWhenMove(config);
  }


  manager.startLead = function (config) {
    return leadWay.startLead(config);
  }

  let leadReverseBlock = null;

  manager.leadReverseAction = function (callBack) {
    leadReverseBlock = callBack;
  }

  manager.leadPointType = function () {
    return matrix.leadPointType();
  }

  let updatePointInfoBlock = null;

  manager.updatePointInfoAction = function (callBack) {
    updatePointInfoBlock = callBack;
  }


  manager.findRoomCenterCouldNotLink = function () {
    let mapInfoBlock = manager.getMapInfo();
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    if (mapid == '') {
      return
    }
    let floorHeight = mapInfo['floorHeight']
    let mapJson_Wall = mapJson_WallBlock(mapid);
    let leadInfo = leadInfoBlock(mapid)
    matrix.findRoomCenterCouldNotLink(mapJson_Wall.mapJson, leadInfo, mapid, floorHeight).then((alerts) => {
      updatePointInfoBlock({warnList: alerts})
    })
  }


  function setUpLeadWay() {
    leadWay.reverseAction(() => {
      if (leadReverseBlock == null) {
        return;
      }
      leadReverseBlock()
    })
  }

  function setUpMatrix() {
    matrix.findMapInfo( (mapid) => {
      let mapJson_Wall = mapJson_WallBlock(mapid);
      return {
        mapJson: mapJson_Wall.mapJson,
        zeroRoom: zeroInfoBlock(mapid)
      }
    })
  }




  setUpLeadWay();
  setUpMatrix();

}
