import {createAnalaysisInfo} from "../analaysis/dataAnalaysisInfo";
import * as THREE from 'three/build/three.min'

export function createIntersects() {
  let intersects = new Object();

  let analysis = createAnalaysisInfo();

  function returnScreenToThreePoint(event, config) {
    let windowWidth = config['windowWidth'];
    let windowHeight = config['windowHeight'];
    let divId = config['divId'];


    if (analysis.jsonKeyIsExist(event, ['offsetX', 'offsetY'])) {
      let mouse = new THREE.Vector2()

      let x = event.offsetX

      let y = event.offsetY

      let touches = []

      if (analysis.jsonKeyIsExist(event, ['touches'])) {
        touches = event.touches
      }

      if (touches.length > 0) {
        let touch = touches[0] //获取第一个触点
        if (analysis.jsonKeyIsExist(touch, ['pageX', 'pageY'])) {
          x = parseFloat(touch.pageX)
          y = parseFloat(touch.pageY)
        }
      }

      if (window.pageXOffset) {
        x = x + window.pageXOffset
        y = y + window.pageYOffset
      } else {
        x = x + document.body.scrollLeft + document.documentElement.scrollLeft
        y = y + document.body.scrollTop + document.documentElement.scrollTop
      }
      mouse.x = ((x - 0) / windowWidth) * 2 - 1
      mouse.y = -((y - 0) / windowHeight) * 2 + 1
      return mouse
    } else {
      let superdiv = document.getElementById(divId)
      let superDivLeft = superdiv.offsetLeft
      let superDivLeftTop = superdiv.offsetTop + document.body.offsetHeight
      let mouse = new THREE.Vector2()

      let x = 0
      if (analysis.jsonKeyIsExist(event, ['clientX'])) {
        x = event.clientX
      }

      let y = 0
      if (analysis.jsonKeyIsExist(event, ['clientY'])) {
        y = event.clientY
      }

      let touches = []

      if (analysis.jsonKeyIsExist(event, ['touches'])) {
        touches = event.touches
      }

      if (touches.length > 0) {
        let touch = touches[0] //获取第一个触点
        if (analysis.jsonKeyIsExist(touch, ['pageX', 'pageY'])) {
          x = parseFloat(touch.pageX)
          y = parseFloat(touch.pageY)
        }
      }

      if (window.pageXOffset) {
        x = x + window.pageXOffset
        y = y + window.pageYOffset
      } else {
        x = x + document.body.scrollLeft + document.documentElement.scrollLeft
        y = y + document.body.scrollTop + document.documentElement.scrollTop
      }
      mouse.x = ((x - superDivLeft) / windowWidth) * 2 - 1
      mouse.y = -((y - superDivLeftTop) / windowHeight) * 2 + 1
      return mouse
    }
  }

  intersects.getIntersects = function (event, config) {
    let camera = config['camera'];
    let mouse = returnScreenToThreePoint(event, config)
    let vector = new THREE.Vector3()//三维坐标对象
    vector.set(
      mouse.x,
      mouse.y,
      0.5)
    vector.unproject(camera)
    //在视点坐标系中形成射线,射线的起点向量是照相机， 射线的方向向量是照相机到点击的点，这个向量应该归一标准化。
    let raycaster = new THREE.Raycaster(camera.position, vector.sub(camera.position).normalize())
    raycaster.setFromCamera(mouse, camera)
    //射线和模型求交，选中一系列直线
    let meshs = config['meshs'];

    let intersects = raycaster.intersectObjects(meshs)
    if (intersects.length == 0) {
      return {
        result: false,
        object:{},
        intersects:intersects,
        x: 0,
        y: 0,
        z: 0,
      }
    }
    let object = intersects[0]
    let point = object.point
    return {
      result: true,
      object:object.object,
      x: parseFloat(point.x.toFixed(2)),
      y: parseFloat(point.y.toFixed(2)),
      z: parseFloat(point.z.toFixed(2)),
      intersects:intersects,
    }
  }

  return intersects;
}
