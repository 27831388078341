import * as THREE from 'three'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'

// 创建世界地图的层
function createPieceLayer() {
  const layer = new THREE.Group()

  let zoomLevel = 1

  let meshScale = 0.3

  let maxCount = 100

  let numberMeshInfo = {}

  let meshOrderInfo = {}

  const analaysis = createAnalaysisInfo()

  let textureInfo = {}

  let geometry = null

  const material = new THREE.MeshBasicMaterial({
    color: '#ffffff',
    transparent: true,
    side: THREE.DoubleSide, // 双面显示
    depthTest: true,
    polygonOffset: true,
    polygonOffsetFactor: 2,
    polygonOffsetUnits: 20,
    opacity: 1
  })

  layer.adventseLayer = function (config, callBack) {
    function startCreateMesh(index, keys, width, callBack) {
      if (index >= keys.length) {
        callBack()
        return
      }
      createMesh('', width, 0, 0, (mesh) => {
        const keyInfo = keys[index]
        numberMeshInfo[keyInfo.key] = keyInfo
        meshOrderInfo[keyInfo.key] = mesh
        layer.add(mesh)
        setTimeout(() => {
          startCreateMesh(index + 1, keys, width, callBack)
        }, 5)
      })
    }

    const meshCount = config['meshCount']
    const row = Math.sqrt(meshCount)
    const keys = []
    const c = 30
    const count = c >= maxCount ? maxCount : c
    for (let i = 0; i < row; i++) {
      for (let j = 0; j < row; j++) {
        const key = i + '-' + j
        keys.push({
          key: key,
          x: i,
          z: j
        })
        if (keys.length >= count) {
          break
        }
      }
      if (keys.length >= count) {
        break
      }
    }

    startCreateMesh(0, keys, config['meshWidth'], callBack)
  }

  layer.deleteAll = function () {
    for (const key in meshOrderInfo) {
      const mesh = meshOrderInfo[key]
      if (mesh.parent != null) {
        mesh.parent.remove(mesh)
      }
      mesh.geometry.dispose()
      mesh.material.dispose()
    }
    meshOrderInfo = {}
    numberMeshInfo = {}
    textureInfo = {}
  }

  function updateMesh(url, width, xno, yno, mesh, callback) {
    if (mesh['url'] == url) {
      callback()
      return
    }
    if (analaysis.jsonKeyIsExist(textureInfo, [url]) == true) {
      mesh['url'] = url
      mesh.material.map = textureInfo[url]
      mesh.position.x = xno + width / 2.0
      mesh.position.z = yno + width / 2.0
      callback()
      return
    }
    const loader = new THREE.TextureLoader()
    // 跨域加载图片
    loader.crossOrigin = true
    loader.load(url, function (texture) {
      if (analaysis.jsonKeyIsExist(textureInfo, [url]) == false) {
        textureInfo[url] = texture
      }
      mesh['url'] = url
      mesh.material.map = texture
      mesh.position.x = xno + width / 2.0
      mesh.position.z = yno + width / 2.0
      callback()
    })
  }

  function createMesh(url, width, xno, yno, callback) {
    // if (geometry == null) {
    //   geometry = new THREE.CubeGeometry(width, 1, width);
    // }
    // let cube = new THREE.Mesh(geometry, material.clone())
    // cube.position.x = xno + width / 2.0;
    // cube.position.z = yno + width / 2.0;
    // cube['url'] = url;
    // callback(cube);
    // return;

    if (geometry == null) {
      geometry = new THREE.PlaneBufferGeometry(width, width, 1)
    }

    const mesh = new THREE.Mesh(geometry.clone(), material.clone())
    mesh.rotateX(Math.PI / -2.0)
    mesh.position.x = xno + width / 2.0
    mesh.position.z = yno + width / 2.0
    mesh['url'] = url
    if (url == '') {
      callback(mesh)
      return
    }
    const loader = new THREE.TextureLoader()
    // 跨域加载图片
    loader.crossOrigin = true
    loader.load(url, function (texture) {
      if (analaysis.jsonKeyIsExist(textureInfo, [url]) == false) {
        textureInfo[url] = texture
      }
      mesh.material.map = texture
      callback(mesh)
    })
  }

  function loadMesh(config) {
    function numberIsExist(numberX, numberZ, storeInfo) {
      const key = numberX + '-' + numberZ
      return {
        result: !analaysis.jsonKeyIsExist(storeInfo, [key])
      }
    }

    function putInStore(storeInfo, storeList, config) {
      const numberX = config['numberX']
      const numberZ = config['numberZ']
      const meshCount = config['meshCount']
      const allCount = config['allCount']

      const list = [{
        x: 0,
        z: 0
      }, {
        x: -1,
        z: 0
      }, {
        x: 0,
        z: -1
      }, {
        x: 1,
        z: 0
      }, {
        x: 0,
        z: 1
      }]
      for (let i = 0; i < list.length; i++) {
        const length = Object.keys(storeInfo).length
        if (length >= allCount) {
          break
        }
        const info = list[i]
        let x = numberX + info.x
        let z = numberZ + info.z
        if (x > meshCount - 1) {
          x = meshCount - 1
        }
        if (x < 0) {
          x = 0
        }
        if (z > meshCount - 1) {
          z = meshCount - 1
        }
        if (z < 0) {
          z = 0
        }
        const res = numberIsExist(x, z, storeInfo)
        if (res.result == false) {
          continue
        }
        const key = x + '-' + z
        storeInfo[key] = {
          x: x,
          z: z
        }
        if (x == numberX && z == numberZ) {
          continue
        }
        // 将下一次的可选项放入数组
        storeList.push({
          numberX: x,
          numberZ: z,
          meshCount: meshCount,
          allCount: allCount
        })
      }

      const length = Object.keys(storeInfo).length
      if (length >= allCount) {
        return
      }
      if (storeList.length == 0) {
        return
      }
      storeList.splice(0, 1)
      if (storeList.length == 0) {
        return
      }
      putInStore(storeInfo, storeList, storeList[0])
    }

    function showPlaneMesh(config, storeInfo, finishCallBack) {
      const scale = config['scale']
      const width = config['meshWidth'] * meshScale
      const origen = config['maxWidth'] / 2.0 * meshScale

      function updateLayer(storeInfo, allKeys, index, config, callBack) {
        if (index >= allKeys.length || layer.visible == false) {
          callBack()
          return
        }
        const continueBlock = () => {
          setTimeout(() => {
            updateLayer(storeInfo, allKeys, index + 1, config, callBack)
          }, 1)
          // updateLayer(storeInfo, allKeys, index + 1, config, callBack)
        }

        const key = allKeys[index]
        if (analaysis.jsonKeyIsExist(meshOrderInfo, [key])) {
          // 原来就有 不用更新
          continueBlock()
          return
        }
        const info = storeInfo[key]
        const x = -1 * origen + info.x * width
        const z = -1 * origen + info.z * width
        let url = "https://wprd04.is.autonavi.com/appmaptile?style=7&x=" +
          info.x +
          "&y=" +
          info.z +
          "&z=" +
          zoomLevel +
          "&lang=zh_cn&size=1&scale=1";

        // const url = 'http://wprd04.is.autonavi.com/appmaptile?style=7&x=' +
        //   info.x +
        //   '&y=' +
        //   info.z +
        //   '&z=' +
        //   zoomLevel +
        //   '&scl=2'
        const length = Object.keys(numberMeshInfo).length
        if (length < maxCount) {
          // 数量没满
          createMesh(url, width, x, z, (mesh) => {
            layer.add(mesh)
            meshOrderInfo[key] = mesh
            numberMeshInfo[key] = info
            continueBlock()
          })
        } else {
          // 数量满了 拿最远的那个更新
          const numberRes = config['numberRes']
          let fastkey = ''
          let dis = 0
          for (const numberKey in numberMeshInfo) {
            if (analaysis.jsonKeyIsExist(storeInfo, [numberKey])) {
              continue
            }
            const numberInfo = numberMeshInfo[numberKey]
            const countX = numberInfo.x - numberRes.numberX
            const countZ = numberInfo.z - numberRes.numberX

            const d = Math.abs(countX) + Math.abs(countZ)
            if (d > dis) {
              dis = d
              fastkey = numberKey
            }
          }
          if (fastkey == '') {
            continueBlock()
            return
          }
          const mesh = meshOrderInfo[fastkey]
          delete numberMeshInfo[fastkey]
          delete meshOrderInfo[fastkey]
          meshOrderInfo[key] = mesh
          numberMeshInfo[key] = info
          updateMesh(url, width, x, z, mesh, () => {
            continueBlock()
          })
        }
      }

      updateLayer(storeInfo, Object.keys(storeInfo), 0, config, () => {
        finishCallBack()
      })
    }

    loadMeshFinished = false
    new Promise(resolve => {
      const count = config['count']
      const numberRes = config['numberRes']

      const storeInfo = {}
      const storeList = []
      const c = Math.abs(Math.sqrt(config['meshCount']))
      const setting = {
        numberX: numberRes.numberX,
        numberZ: numberRes.numberZ,
        meshCount: c,
        allCount: count
      }
      storeList.push(setting)
      putInStore(storeInfo, storeList, setting)
      resolve({
        storeInfo: storeInfo
      })
    }).then(res => {
      return new Promise(resolve => {
        showPlaneMesh(config, res['storeInfo'], () => {
          resolve()
        })
      })
    }).then(() => {
      loadMeshFinished = true
    })
  }

  layer.setLayerConfig = function (config) {
    zoomLevel = config['zoomLevel']
    meshScale = config['meshScale']
    maxCount = config['maxCount']
  }

  let loadMeshFinished = true

  layer.showWorldLayer = function (config) {
    if (loadMeshFinished == false) {
      return
    }
    loadMesh(config)
  }

  layer.setUpMaterial = function (config) {
    const transparent = config['transparent']
    const depthTest = config['depthTest']
    const polygonOffset = config['polygonOffset']
    for (const key in meshOrderInfo) {
      const mesh = meshOrderInfo[key]
      mesh.material.transparent = transparent
      mesh.material.depthTest = depthTest
      mesh.material.polygonOffset = polygonOffset
    }
  }

  return layer
}

export function theWholeWorld() {
  const world = new THREE.Group()

  const worldContent = new THREE.Group()

  let rotateAngle = 0

  const lng = 0
  const lat = 0

  world.add(worldContent)

  const analaysis = createAnalaysisInfo()

  const worldBaseInfo = {
    maxCount: 100,
    zoomMinSize: 1,
    zoomMaxSize: 17,
    maxH: 0,
    minH: 0,
    maxWidth: 0,
    draw: false
  }

  const meshScale = 0.2

  let zoomLevel = worldBaseInfo.zoomMaxSize

  const subWidth = 0.1

  let zoomLayerInfo = {} // 计算缩放等级的信息

  function judgeNumber(index, mechCount) {
    if (index >= mechCount - 1) {
      return mechCount - 1
    } else if (index <= 0) {
      return 0
    }
    return index
  }

  // 将storeInfo补正到能填充上一级的面积
  function judgeStoreInfo(storeInfo, repeatInfo) {
    const insertInfo = {}
    const parentNumberInfo = {}
    for (const key in storeInfo) {
      const info = storeInfo[key]
      const parentX = info.x % 2 == 0 ? info.x / 2 : (info.x - 1) / 2
      const parentZ = info.z % 2 == 0 ? info.z / 2 : (info.z - 1) / 2
      const parentKey = parentX + '-' + parentZ
      parentNumberInfo[parentKey] = {
        x: parentX,
        z: parentZ
      }
      const startX = parentX * 2
      const startZ = parentZ * 2
      const subs = [{
        x: startX,
        z: startZ
      }, {
        x: startX + 1,
        z: startZ
      }, {
        x: startX + 1,
        z: startZ + 1
      }, {
        x: startX,
        z: startZ + 1
      }]
      for (let i = 0; i < subs.length; i++) {
        const sub = subs[i]
        const subKey = sub.x + '-' + sub.z
        if (analaysis.jsonKeyIsExist(storeInfo, [subKey]) == false && analaysis.jsonKeyIsExist(repeatInfo, [subKey]) ==
          false) {
          insertInfo[subKey] = sub
        }
      }
    }

    for (const key in insertInfo) {
      storeInfo[key] = insertInfo[key]
    }

    return {
      parentNumberInfo: parentNumberInfo
    }
  }

  function lookAt(config) {
    if (worldBaseInfo.draw == false) {
      return
    }
    for (const key in zoomLayerInfo) {
      const level = parseInt(key)
      const zoomInfo = zoomLayerInfo[key]
      if (analaysis.jsonKeyIsExist(zoomInfo, ['layer']) == false) {
        continue
      }
      const layer = zoomInfo['layer']
      if (level != zoomLevel && level != zoomLevel - 1) {
        layer.visible = false
        continue;
      }
      layer.visible = true
      config['zoomLevel'] = level
      const numberRes = lookAtMeshNumber(config)
      layer.setUpMaterial({
        transparent: key == zoomLevel + '',
        depthTest: key == zoomLevel + '',
        polygonOffset: key == zoomLevel + ''
      })
      layer.showWorldLayer({
        zoomLevel: zoomLevel,
        count: 60,
        numberRes: numberRes,
        scale: config['scale'],
        maxWidth: worldBaseInfo.maxWidth,
        meshWidth: zoomInfo['meshWidth'],
        meshCount: zoomInfo['meshCount']
      })
    }
  }

  // 计算目前的焦点在那个块上
  function lookAtMeshNumber(config) {
    const level = config['zoomLevel'] + ''
    const zoomInfo = zoomLayerInfo[level]
    const target = config['target']
    const scale = config['scale']

    const width = zoomInfo['meshWidth'] * scale * meshScale
    const origen = worldBaseInfo.maxWidth / 2.0 * scale * meshScale

    const x = target.x
    const z = target.z

    const v1 = new THREE.Vector3(x, 0, z)
    const t = v1.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotateAngle * -1)
    const offsetX = t.x - (-1 * origen) - worldContent.position.x
    const offsetZ = t.z - (-1 * origen) - worldContent.position.z

    // let offsetX = target.x - (-1 * origen) - worldContent.position.x;
    // let offsetZ = target.z - (-1 * origen) - worldContent.position.z;

    let tileX = offsetX / width
    let tileZ = offsetZ / width

    let numberX = parseInt(tileX)
    let numberZ = parseInt(tileZ)

    const meshRow = config['meshRow']
    if (numberX < 0) {
      numberX = 0
      tileX = 0
    }
    if (numberX > meshRow) {
      numberX = meshRow
      tileX = meshRow
    }

    if (numberZ < 0) {
      numberZ = 0
      tileZ = 0
    }
    if (numberZ > meshRow) {
      numberZ = meshRow
      tileZ = meshRow
    }

    return {
      tileX: tileX,
      tileZ: tileZ,
      numberX: numberX,
      numberZ: numberZ
    }
  }

  world.setLevel = function (config) {
    const level = config['level']
    const zoomInfo = zoomLayerInfo['' + level]
    zoomLevel = level
    return zoomInfo
  }

  world.returnZoomInfo = function (level) {
    const zoomInfo = zoomLayerInfo[level + '']
    return zoomInfo.end * meshScale * meshScale
  }

  function getCameraSize(config) {
    const target = config['target']
    const camera = config['camera']
    // 计算距离
    const p = new THREE.Vector3(camera.x, camera.y, camera.z)
    const t = new THREE.Vector3(target.x, target.y, target.z)
    const dis = p.distanceTo(t)
    const minZoomInfo = zoomLayerInfo[worldBaseInfo.zoomMinSize + '']
    const d = minZoomInfo['end'] - minZoomInfo['start']
    const w = dis * minZoomInfo['meshWidth'] / d
    // 视野的面积
    const s1 = (window.innerWidth / window.innerHeight)
    const s2 = (window.innerHeight / window.innerWidth)
    const size = w * w / (s1 >= s2 ? s1 : s2)
    return size
  }

  function findLookAtCount(config) {
    function getZoomInfoSize(level, config) {
      const scale = config['scale']
      const key = level + ''
      const zoomInfo = zoomLayerInfo[key]
      const meshWidth = zoomInfo['meshWidth']
      const subSize = meshWidth * meshWidth
      return subSize
    }

    // 视野的面积
    const size = getCameraSize(config)
    let level = zoomLevel
    for (let i = worldBaseInfo.zoomMinSize; i <= worldBaseInfo.zoomMaxSize; i++) {
      const subSize = getZoomInfoSize(i, config)
      const result = size / subSize
      const startCount = 25
      const endCount = 36
      if (result <= endCount && result >= startCount) {
        level = i
        break
      }
    }
    if (level <= worldBaseInfo.zoomMinSize) {
      level = worldBaseInfo.zoomMinSize
    }
    if (level >= worldBaseInfo.zoomMaxSize) {
      level = worldBaseInfo.zoomMaxSize
    }
    return level
  }

  let cameraTarget = null

  world.lookingWorld = function (config) {
    if (Object.keys(zoomLayerInfo).length == 0) {
      return
    }
    const level = findLookAtCount(config)
    const target = config['target']
    cameraTarget = JSON.parse(JSON.stringify(target))
    zoomLevel = level
    config['zoomLevel'] = zoomLevel
    lookAt(config)
  }

  world.adventseLayer = function (callBack) {
    function createLayer(index, callBack) {
      if (index > worldBaseInfo.zoomMaxSize) {
        callBack()
        return
      }
      const key = index + ''
      if (analaysis.jsonKeyIsExist(zoomLayerInfo, [key]) == false) {
        createLayer(index + 1, callBack)
        return
      }
      const zoomInfo = zoomLayerInfo[key]
      const meshCount = zoomInfo['meshCount']
      const meshWidth = zoomInfo['meshWidth']
      const maxCount = zoomInfo['maxCount']
      const layer = createPieceLayer()
      layer.setLayerConfig({
        zoomLevel: index,
        meshScale: meshScale,
        maxCount: maxCount
      })
      zoomInfo['layer'] = layer
      worldContent.add(layer)
      layer.visible = false
      // layer.adventseLayer({meshCount: meshCount, meshWidth: meshWidth * meshScale}, () => {
      //   setTimeout(() => {
      //     createLayer(index + 1, callBack);
      //   }, 1)
      // });

      createLayer(index + 1, callBack)
    }

    if (worldContent.parent == null) {
      world.add(worldContent)
    }

    createLayer(worldBaseInfo.zoomMinSize, callBack)
  }

  // 计算世界地图的缩放等级
  world.analaysisZoomInfo = function (config) {
    const fov = config['fov'] / 2.0
    // 最大层级的宽
    const maxWidth = Math.pow(4, worldBaseInfo.zoomMaxSize) * subWidth
    // 最小层级的宽
    const minWidth = Math.pow(4, worldBaseInfo.zoomMinSize) * subWidth

    const maxH_Border = maxWidth / 2.0 / Math.tan(fov / 180 * Math.PI)
    const minH_Border = maxWidth / 2.0 / Math.tan(fov / 180 * Math.PI)

    const maxH = Math.sqrt(maxH_Border * maxH_Border - maxWidth * maxWidth / 4.0)
    const minH = Math.sqrt(minH_Border * minH_Border - minWidth * minWidth / 4.0)

    worldBaseInfo.maxH = maxH

    worldBaseInfo.minH = minH

    worldBaseInfo.maxWidth = maxWidth

    const count = worldBaseInfo.zoomMaxSize - worldBaseInfo.zoomMinSize + 1
    const space = maxH / count
    zoomLayerInfo = {}
    for (let i = 0; i <= worldBaseInfo.zoomMaxSize - worldBaseInfo.zoomMinSize; i++) {
      const level = i + worldBaseInfo.zoomMinSize
      const meshCount = Math.pow(4, level)
      const meshRow = Math.sqrt(meshCount)
      const meshWidth = maxWidth / meshRow
      zoomLayerInfo[level + ''] = {
        maxCount: meshCount >= worldBaseInfo.maxCount ? worldBaseInfo.maxCount : meshCount,
        level: level,
        end: maxH - i * space,
        start: maxH - (i + 1) * space,
        meshCount: meshCount,
        meshWidth: meshWidth,
        meshRow: meshRow
      }
    }
  }

  // 经纬度坐标（lng, lat）转瓦片坐标（tileX， tileY）
  function coordinateToTile(lng, lat, level) {
    const tileX = (lng + 180) / 360 * Math.pow(2, level)
    const num1 = Math.log((Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180))) / (2 * Math.PI)
    const tileY = (1 / 2.0 - num1) * Math.pow(2, level)
    return {
      tileX: tileX,
      tileY: tileY
    }
  }

  world.coordinateToThree = function (lng, lat, level) {
    return coordinateToThree(lng, lat, level)
  }

  world.coordinateToThreeAbsolute = function (lng, lat, level) {
    if (analaysis.jsonKeyIsExist(zoomLayerInfo, [level + '']) == false) {
      return {
        result: false
      }
    }
    const tile = coordinateToTile(lng, lat, level)
    const zoomInfo = zoomLayerInfo[level + '']
    const meshCount = Math.sqrt(zoomInfo['meshCount'])
    const scaleX = tile.tileX / meshCount
    const scaleZ = tile.tileY / meshCount
    const width = worldBaseInfo.maxWidth * scaleX * meshScale
    const height = worldBaseInfo.maxWidth * scaleZ * meshScale
    const origen = worldBaseInfo.maxWidth / 2.0 * meshScale
    const x = -1 * origen + width
    const z = -1 * origen + height
    return {
      result: true,
      x: x,
      z: z
    }
  }

  // 经纬度坐标（lng, lat）转three坐标（x, y）
  function coordinateToThree(lng, lat, level) {
    if (analaysis.jsonKeyIsExist(zoomLayerInfo, [level + '']) == false) {
      return {
        result: false
      }
    }
    const tile = coordinateToTile(lng, lat, level)
    const zoomInfo = zoomLayerInfo[level + '']
    const meshCount = Math.sqrt(zoomInfo['meshCount'])
    const scaleX = tile.tileX / meshCount
    const scaleZ = tile.tileY / meshCount
    const width = worldBaseInfo.maxWidth * scaleX * meshScale
    const height = worldBaseInfo.maxWidth * scaleZ * meshScale
    const origen = worldBaseInfo.maxWidth / 2.0 * meshScale
    // let x = -1 * origen + width + worldContent.position.x
    // let z = -1 * origen + height + worldContent.position.z

    const x = -1 * origen + width
    const z = -1 * origen + height

    const rx = 0 - worldContent.position.x
    const rz = 0 - worldContent.position.z

    const v1 = new THREE.Vector3(x - rx, 0, z - rz)
    const t = v1.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotateAngle)
    return {
      result: true,
      x: t.x,
      z: t.z
    }
  }

  function threeToCoordinate(x, z, level) {
    if (analaysis.jsonKeyIsExist(zoomLayerInfo, [level + '']) == false) {
      return {
        result: false
      }
    }
    const zoomInfo = zoomLayerInfo[level + '']
    const meshCount = Math.sqrt(zoomInfo['meshCount']) * 1.0

    const v1 = new THREE.Vector3(x, 0, z)
    const t = v1.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotateAngle * -1)
    const X = t.x - worldContent.position.x
    const Z = t.z - worldContent.position.z

    // let X = x - worldContent.position.x;
    // let Z = z - worldContent.position.z;
    const origen = worldBaseInfo.maxWidth / 2.0 * meshScale
    const width = worldBaseInfo.maxWidth * meshScale
    const height = worldBaseInfo.maxWidth * meshScale
    const countX = X - (origen * -1)
    const countZ = Z - (origen * -1)
    // let tileX = countX / width * meshCount;
    // let tileZ = countZ / height * meshCount;

    // let xx = countX / width * meshCount;
    // let zz = countZ / height * meshCount;

    const sw = width / meshCount
    const sh = height / meshCount
    const count_X = countX / sw
    const count_Z = countZ / sh

    const tileX = count_X + (countX - count_X * sw)
    const tileZ = count_Z + (countZ - count_Z * sh)

    const lng = (tileX) / meshCount * 360.0 - 180
    const lat = Math.atan(Math.sinh(Math.PI - 2 * Math.PI * (tileZ) / meshCount)) * 180 / Math.PI
    return {
      lng: lng,
      lat: lat
    }
  }

  // 经纬度坐标（lng, lat）转像素坐标（pixelX, pixelY）
  function coordinateToPixel(lng, lat, level) {
    const tile = coordinateToTile(lng, lat, level)
    const pixelX = tile.tileX * 256 % 256
    const pixelY = tile.tileY * 256 % 256
    return {
      pixelX: pixelX,
      pixelY: pixelY
    }
  }

  world.coordinateToPixel = function (lng, lat, level) {
    return coordinateToPixel(lng, lat, level)
  }

  // 瓦片（tileX， tileY）的像素坐标（pixelX, pixelY）转经纬度坐标（lng, lat）
  function pixelToCoordinate(tileX, tileY, pixelX, pixelY, zoomLevel) {
    const lng = (tileX + pixelX / 256.0) / Math.pow(2, zoomLevel) * 360.0 - 180
    const lat = Math.atan(Math.sinh(Math.PI - 2 * Math.PI * (tileY + pixelY / 256) / Math.pow(2, zoomLevel))) * 180 / Math
      .PI
    return {
      lng: lng,
      lat: lat
    }
  }

  function tileToCoordinate(tileX, tileY) {
    const n = Math.pow(2, zoomLevel)
    const lng = (tileX) / n * 360.0 - 180
    const lat = Math.atan(Math.sinh(Math.PI - 2 * Math.PI * (tileY) / n)) * 180 / Math.PI
    return {
      lng: lng,
      lat: lat
    }
  }

  world.threeToCoordinate = function (tileX, tileY) {
    return threeToCoordinate(tileX, tileY, zoomLevel)
  }

  world.getWorldBorder = function () {
    const origen = worldBaseInfo.maxWidth / 2.0 * meshScale
    return {
      leftUp: {
        x: -1 * origen,
        y: -1 * origen
      },
      rightDown: {
        x: origen,
        y: origen
      }
    }
  }

  world.setUpConfig = function (config) {
    for (const configKey in config) {
      if (analaysis.jsonKeyIsExist(worldBaseInfo, [configKey]) == false) {
        continue
      }
      worldBaseInfo[configKey] = config[configKey]
    }
  }

  world.deleteAll = function () {
    for (const key in zoomLayerInfo) {
      const zoomInfo = zoomLayerInfo[key]
      if (analaysis.jsonKeyIsExist(zoomInfo, ['layer']) == false) {
        continue
      }
      const layer = zoomInfo['layer']
      if (layer.parent != null) {
        layer.parent.remove(layer)
      }
      layer.deleteAll()
    }
    if (worldContent.parent != null) {
      worldContent.parent.remove(worldContent)
    }
    worldBaseInfo.draw = false
    zoomLayerInfo = {};
    worldContent.position.x = 0
    worldContent.position.y = 0
    worldContent.position.z = 0
    world.rotation.y = 0
  }

  world.setUpPosition = function (config) {
    let rotate = 0
    if (analaysis.jsonKeyIsExist(config, ['rotate'])) {
      rotate = config['rotate']
    }
    let position = {
      x: 0,
      y: 0,
      z: 0
    }
    if (analaysis.jsonKeyIsExist(config, ['position'])) {
      position = config['position']
    }
    worldContent.position.x = position.x
    worldContent.position.y = position.y
    worldContent.position.z = position.z
    world.rotation.y = rotate
    // world.rotateY(rotate);

    rotateAngle = rotate
  }

  return world
}
