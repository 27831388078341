export function shot(domElement, config, callBack) {
  const scale = typeof (config.scale) == 'undefined' ? 16 / 9 : config.scale
  const canvasScale = domElement.width / domElement.height
  let width = domElement.width
  let height = domElement.height
  if (canvasScale > scale) {
    width = height * scale
  } else if (canvasScale < scale) {
    height = width / scale
  }

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const cuntx = (domElement.width - width) / 2.0
  const cuntY = (domElement.height - height) / 2.0

  const ctx = canvas.getContext('2d')

  const image = new Image()
  const imgData = domElement.toDataURL('image/jpeg') // 这里可以选择png格式jpeg格式
  image.src = imgData
  image.onload = function() {
    ctx.drawImage(image, cuntx, cuntY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
    const newImage = new Image()
    const newData = canvas.toDataURL('image/jpeg')
    newImage.src = newData
    callBack(newImage)
  }
}
