import {createLeadCubeManager} from "./leadPointManager";
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {createIntersects} from "../../intersects/intersects";

export function createLeadPointManager() {
  let manager = new Object();

  let leadPoint = createLeadCubeManager();

  let analysis = createAnalaysisInfo();

  // 点击射线
  let intersect = createIntersects();

  let mapInfoBlock = null;

  manager.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  let textBlock = null;

  manager.textAction = function (callBack) {
    textBlock = callBack;
  }

  let cameraBlock = null;

  manager.cameraAction = function (callBack) {
    cameraBlock = callBack;
  }


  manager.addGroup = function (scene) {
    if (leadPoint.parent != null) {
      scene.remove(leadPoint);
    }
    scene.add(leadPoint);
  }

  manager.setLeadPointVisible = function (visible) {
    leadPoint.visible = visible;
  }

  // 根据cubeid将cube选中
  manager.getSelectCube = function (cubeId) {
    leadPoint.getSelectCube(cubeId)
  }

  manager.showLeadCube = function () {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];

    let alertList = []
    let mapList = mapInfo['mapJson']
    let leadInfo = mapInfo['leadInfo']
    let mapLeadInfo = {};
    if (analysis.jsonKeyIsExist(leadInfo, [mapid])) {
      mapLeadInfo = leadInfo[mapid];
    }
    let y = mapInfo['floorHeight'];
    for (let i = 0; i < mapList.length; i++) {
      let roomInfo = mapList[i]
      let title = roomInfo['title']
      if (title == '') {
        continue;
      }
      let isExist = false
      // 判断名字不为空的房间是否存在连接点
      for (let leadKey in mapLeadInfo) {
        let data = mapLeadInfo[leadKey]
        if (analysis.jsonKeyIsExist(data, ['x', 'y']) == false) {
          continue
        }
        let areas = roomInfo['areas']
        if (analysis.isInPolygon([parseFloat(data.x), 0, parseFloat(data.y)], areas) == true) {
          isExist = true
          break
        }
      }
      if (isExist) {
        continue;
      }
      let polygon = analysis.getPolygonAreaCenter(roomInfo['areas'])
      alertList.push({
        id: roomInfo['areaid'],
        mapid: mapid,
        x: polygon[1],
        y: y,
        z: polygon[0],
        title: '无连接点',
        type: 'textList',
        textColor: '#ffffff',
        bgColor: '#ff0000',
      })
    }
    leadPoint.visible = true


    let newTextList = leadPoint.showLeadCube({
      pointInfo: mapLeadInfo,
      textList: alertList,
      floorHeight: y,
      mapid: mapid,
    })
    textBlock({textList: newTextList})
  }

  let updateLeadInfoBlock = null;

  manager.updateLeadInfoAction = function (callBack) {
    updateLeadInfoBlock = callBack;
  }

  let clearPathBlock = null;

  manager.clearPathAction = function (callBack) {
    clearPathBlock = callBack;
  }

  manager.hiddenLeadCube = function (isClearPath) {
    leadPoint.visible = false;
    let clear = false
    if (isClearPath != null && typeof (isClearPath) != 'undefined') {
      clear = isClearPath
    }
    clearPathBlock(clear);
    manager.clearLeadIcon();
  }

  manager.clickMapSceneEvent = function (event, config) {
    let camera = cameraBlock();
    config['camera'] = camera
    config['meshs'] = leadPoint.returnMeshList();
    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return finder;
    }
    let intersects = finder.intersects
    let object = intersects[0].object
    let mapInfo = mapInfoBlock();
    let leadInfo = mapInfo['leadInfo'];
    if (config.type == 'leadCube') {
      leadPoint.clickLeadCube(object, leadInfo)
      updateLeadInfoBlock();
      return {
        result: true,
        data: object['dataInfo']
      }
    } else if (config.type == 'linkImage') {
      return leadPoint.clickCube(object)
    }
    return finder;
  }

  let mapJsonBlock = null;

  manager.mapJsonAction = function (callBack) {
    mapJsonBlock = callBack;
  }

  let zeroBlock = null;

  manager.zeroAction = function (callBack) {
    zeroBlock = callBack;
  }

  // 链接所有地图在走廊能链接的点
  manager.nowMapAtFloorPoint = function (link) {
    let mapInfo = mapInfoBlock();
    let leadInfo = {}
    let mapOrder = []
    let mapOrderList = mapInfo['mapOrderList'];
    let leadDataInfo = mapInfo['leadInfo'];
    let floorHeight = mapInfo['floorHeight'];
    let matrix = leadPoint.getMatrix();

    for (let i = 0; i < mapOrderList.length; i++) {
      let order = mapOrderList[i]
      let mapid = order['mapid']

      let mapLeadInfo = leadDataInfo[mapid]
      matrix.linkTheSameMap(floorHeight, mapJsonBlock(mapid), mapLeadInfo, zeroBlock(mapid), link)
      mapOrder.push({
        mapid: mapid
      })
      leadInfo[mapid] = JSON.parse(JSON.stringify(mapLeadInfo))
    }
    updateLeadInfoBlock();
    manager.showLeadCube();
  }


  manager.deleteAll = function () {
    leadPoint.deleteAll();
  }

  let leadIconInfo = {
    start: null,
    end: null,
  }

  manager.createLeadIcon = function (config) {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];
    let floorHeight = mapInfo['floorHeight'];
    let position = config['position']
    let index = config['index'];
    let imageUrl = config['imageUrl'];
    let id = leadIconInfo.start == null ? 'start' : 'end';
    let setting = {
      mapid: mapid,
      data: {x:position.x, y:floorHeight, z:position.z},
      index: index,
      id: id,
      x: position.x,
      y: floorHeight,
      z: position.z,
      centerY: 0.1414,
      type: 'point',
      select: imageUrl,
      unSelect: imageUrl,
    }
    let pointList = [];
    let result = {
      result: false
    }
    if (leadIconInfo.start == null) {
      leadIconInfo.start = JSON.parse(JSON.stringify(setting));
      pointList.push(setting);
    } else {
      leadIconInfo.end = JSON.parse(JSON.stringify(setting));
      pointList.push(JSON.parse(JSON.stringify(leadIconInfo.start)));
      pointList.push(setting);
      result.result = true
    }
    textBlock({leadPoint: pointList})
    return result;
  }

  manager.getLeadIconInfo = function () {
    return leadIconInfo;
  }

  manager.clearLeadIcon = function () {
    leadIconInfo.start = null;
    leadIconInfo.end = null;
    textBlock({leadPoint:[]})
  }

  let leadInfoBlock = null;

  manager.leadInfoAction = function (callBack) {
    leadInfoBlock = callBack;
  }


  manager.updateNewLeadInfo = function (leadData) {
    return new Promise(resolve => {
      let matrix = leadPoint.getMatrix();
      let mapInfo = mapInfoBlock();
      let leadDataInfo = mapInfo['leadInfo'];
      for (const mapid in leadData) {
        let info = leadData[mapid]
        if (analysis.jsonKeyIsExist(info, ['leadInfo']) == false) {
          continue
        }
        let leadInfo = info['leadInfo']
        let lead = {};
        if (analysis.jsonKeyIsExist(leadDataInfo, [mapid])) {
          lead = JSON.parse(JSON.stringify(leadDataInfo[mapid]));
        }
        let newLeadInfo = matrix.compareLeadInfo(leadInfo, lead, '', mapid)
        leadInfoBlock(mapid, newLeadInfo);
      }
      updateLeadInfoBlock();
      resolve()
    })
  }


  return manager;
}
