import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createObjectAction
} from '../createObject'

import * as THREE from 'three/build/three.min.js'

export function createLayerManager() {
  const layer = new THREE.Group()

  const create = createObjectAction()

  const analysis = createAnalaysisInfo() // 构造地图解析

  let layerInfo = {} // 数据

  let tempLayerInfo = {} // 新的绘制数据

  let layerPlanes = [] // 覆盖区域数组

  let points = [] // 点数组

  // 获取覆盖区域数据
  layer.getLayerData = function(data) {
    layerInfo = data
  }

  layer.clear = function(temp) {
    if (temp) {
      tempLayerInfo = {}
    } else {
      layerInfo = {}
    }
  }

  let showTextBlock = null;

  layer.showTextAction = function (callBack) {
    showTextBlock = callBack;
  }

  layer.showLayer = function(config) {
    if (analysis.jsonKeyIsExist(config, ['mapid']) == false) {
      return
    }
    const mapid = config.mapid
    const data = {}
    const newList = []
    for (const key in layerInfo) {
      if (key != mapid) {
        continue
      }
      const arr = layerInfo[key]
      for (let i = 0; i < arr.length; i++) {
        newList.push(arr[i])
      }
    }
    for (const key in tempLayerInfo) {
      if (key != mapid) {
        continue
      }
      const arr = tempLayerInfo[key]
      for (let i = 0; i < arr.length; i++) {
        newList.push(arr[i])
      }
    }
    data[mapid] = newList
    const layers = data[mapid]
    let planeCount = 0
    let pointCount = 0
    let textList = [];
    for (let i = 0; i < layers.length; i++) {
      const info = layers[i]
      for (let j = 0; j < info.path.length; j++) {
        const p = info.path[j]
        if (pointCount < points.length) {
          const cube = points[pointCount]
          cube.position.x = p.x
          cube.position.y = p.y
          cube.position.z = p.z
          cube.visible = true
        } else {
          const cube = create.createCube(p.x, p.y, p.z, '#0000ff', null, 3)
          cube.mapid = mapid
          points.push(cube)
          layer.add(cube)
        }
        pointCount = pointCount + 1
      }

      if (info.path.length >= 3) {
        config['color'] = info['color']
        const id = analysis.jsonKeyIsExist(info, ['id']) ? info.id : ''
        if (planeCount < layerPlanes.length) {
          const mesh = layerPlanes[planeCount]
          create.updateLayer(info.path, mesh, config)
          mesh.visible = true
          mesh.layerId = id
        } else {
          const mesh = create.createLayer(info.path, config)
          mesh.mapid = mapid
          layerPlanes.push(mesh)
          mesh.layerId = id
          layer.add(mesh)
        }
        planeCount = planeCount + 1
      }
      if (analysis.jsonKeyIsExist(info, ['name', 'point']) && info.name != '') {
        const textColor = '#000000'
        const bgColor = '#fffde8'
        const point = info.point
        const textConfig = {
          type: 'normal',
          title: info.name,
          textColor: textColor,
          bgColor: bgColor,
          multiple: 10,
          mode:0,
          x: point.x,
          y: config.y + 1,
          z: point.z,
          mapid:mapid
        }
        textList.push(textConfig);
      }
    }
    if (planeCount < layerPlanes.length) {
      for (let i = planeCount; i < layerPlanes.length; i++) {
        const arr = layerPlanes[i]
        arr.visible = false
      }
    }
    if (pointCount < points.length) {
      for (let i = pointCount; i < points.length; i++) {
        const arr = points[i]
        arr.visible = false
      }
    }
    showTextBlock(textList);
  }

  layer.drawLayer = function(result) {
    function addNewData(result, info) {
      info.push({
        path: [{
          x: result.x,
          y: result.y,
          z: result.z
        }],
        name: result.name,
        point: {
          x: result.x,
          y: result.y,
          z: result.z
        },
        color: result.color
      })
    }

    function updateLayer(result, info) {
      const item = info[info.length - 1]
      item.path.push({
        x: result.x,
        y: result.y,
        z: result.z
      })
      const polygon = analysis.getPolygonAreaCenter(item.path)
      item.point.x = polygon[1]
      item.point.z = polygon[0]
      item.color = result.color
      item.name = result.name
    }

    if (result.result == false) {
      return
    }
    const mapid = result.mapid
    let info = []
    if (analysis.jsonKeyIsExist(tempLayerInfo, [mapid]) == false) {
      tempLayerInfo[mapid] = info
    } else {
      info = tempLayerInfo[mapid]
    }
    if (info.length == 0) {
      // 没有数据则赋予数据
      addNewData(result, info)
    } else {
      updateLayer(result, info)
    }
  }

  layer.back = function(mapid) {
    let info = []
    if (analysis.jsonKeyIsExist(tempLayerInfo, [mapid])) {
      info = tempLayerInfo[mapid]
    }
    if (info.length == 0) {
      return
    }
    const item = info[info.length - 1]
    item.path.pop()
    if (item.path.length > 0) {
      const polygon = analysis.getPolygonAreaCenter(item.path)
      item.point.x = polygon[1]
      item.point.z = polygon[0]
      return
    }
    tempLayerInfo[mapid] = []
  }

  layer.exportInfo = function() {
    return tempLayerInfo
  }

  layer.returnLayerPlanes = function () {
    return layerPlanes;
  }

  layer.clickIntersects = function(intersects) {
    if (intersects.length == 0) {
      return {
        result: false
      }
    }
    const point = intersects[0].point
    const object = intersects[0].object
    if (analysis.jsonKeyIsExist(object, ['layerId']) == false) {
      return {
        result: false
      }
    }
    return {
      result: true,
      x: point.x,
      y: point.y,
      z: point.z,
      id: object.layerId
    }
  }

  layer.clearInfo = function() {
    layerInfo = {}
  }

  layer.deleteAll = function() {
    for (let i = 0; i < layerPlanes.length; i++) {
      const arr = layerPlanes[i]
      if (arr.parent != null) {
        layer.remove(arr)
        arr.geometry.dispose()
        arr.material.dispose()
      }
    }
    layerPlanes = []

    for (let i = 0; i < points.length; i++) {
      const arr = points[i]
      arr.visible = false
      if (arr.parent != null) {
        layer.remove(arr)
        arr.geometry.dispose()
        arr.material.dispose()
      }
    }
    points = []
  }

  return layer
}
