import {theWholeWorld} from "./theWorld";

import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";


export function createWorldManager() {
  let manager = new Object();

  let world = theWholeWorld()

  let analysis = createAnalaysisInfo();

  let mapSetInfoBlock = null;

  manager.mapSetInfoAction = function (callBack) {
    mapSetInfoBlock = callBack;
  }


  manager.addGroup = function (scene) {
    if (world.parent != null) {
      scene.remove(world);
    }
    scene.add(world);
  }

  manager.deleteAll = function () {
    world.deleteAll();
  }

  manager.analaysisZoomInfo = function (config) {
    world.analaysisZoomInfo(config)
  }

  manager.lookingWorld = function (config) {
    world.lookingWorld(config);
  }

  manager.judgeWorldPosition = function (config) {
    let lng = 0
    let lat = 0
    if (analysis.jsonKeyIsExist(config, ['lng'])) {
      lng = config['lng']
    }
    if (analysis.jsonKeyIsExist(config, ['lat'])) {
      lat = config['lat']
    }
    let rotate = 0
    if (analysis.jsonKeyIsExist(config, ['rotate'])) {
      rotate = parseFloat(config['rotate'])
    }

    let positionRes = world.coordinateToThreeAbsolute(lng, lat, 16)
    if (positionRes.result == false) {
      return;
    }
    world.setUpPosition({
      position: {
        x: -1 * positionRes.x,
        y: -1,
        z: -1 * positionRes.z
      },
      rotate: rotate
    })
    let mapSetInfo = mapSetInfoBlock();
    if (mapSetInfo.openTheWorld == false) {
      return;
    }
    world.lookingWorld({
      camera: camera.position,
      target: controls.target,
      scale: 1,
      fov: mapSetInfo.fov
    })
  }

  manager.changeCoordinateToVector = function (pointInfo) {
    let mapSetInfo = mapSetInfoBlock();
    if (analysis.jsonKeyIsExist(pointInfo, ['lat', 'lng']) == false || mapSetInfo.openTheWorld == false) {
      return {
        result: false,
      }
    }
    let lat = parseFloat(pointInfo['lat']);
    let lng = parseFloat(pointInfo['lng']);
    let positionRes = world.coordinateToThree(lng, lat, 16)
    if (positionRes.result == false) {
      return {
        result: false,
      }
    }
    let point = {
      x: positionRes.x,
      y: 0,
      z: positionRes.z,
      type: 'coordinate',
      coordinate: true,
    }
    return {
      result: true,
      point: point,
    }
  }


  let maxDistanceBlock = null;

  manager.maxDistanceAction = function (callBack) {
    maxDistanceBlock = callBack;
  }

  let sceneBlock = null;

  manager.sceneAction = function (callBack) {
    sceneBlock = callBack;
  }

  manager.setUpWorldConfig = function (config, callBack) {
    let mapSetInfo = mapSetInfoBlock();
    mapSetInfo.openTheWorld = true
    maxDistanceBlock();
    let lng = 113.12477
    let lat = 23.0063
    if (analysis.jsonKeyIsExist(config, ['lng'])) {
      lng = config['lng']
    }
    if (analysis.jsonKeyIsExist(config, ['lat'])) {
      lat = config['lat']
    }
    let rotate = 0
    if (analysis.jsonKeyIsExist(config, ['rotate'])) {
      rotate = parseFloat(config['rotate'])
    }



    let positionRes = world.coordinateToThree(lng, lat, 16)
    if (positionRes.result) {
      world.setUpPosition({
        position: {
          x: -1 * positionRes.x,
          y: -1,
          z: -1 * positionRes.z
        },
        rotate: rotate
      })
    }

    let scene = sceneBlock();
    manager.addGroup(scene);

    world.adventseLayer(() => {
      world.setUpConfig({
        draw: true
      })
      setTimeout(() => {
        callBack()
      }, 500)
    })
  }

  manager.threeToCoordinate = function (x, z) {
    return world.threeToCoordinate(x, z)
  }

  manager.coordinateToThreeAction = function (lng, lat) {
    return world.coordinateToThreeAction(lng, lat, 16)
  }

  manager.returnZoomInfo = function (scale) {
    return world.returnZoomInfo(scale)
  }

  return manager;
}
