import {
  createAnalaysisInfo
} from './dataAnalaysisInfo.js'
import * as THREE from 'three/build/three.min.js'

export function createLeadJs() {
  const lead = new Object()

  const dataJs = createAnalaysisInfo()

  lead.returnPathLineByWidth = function (width, paths, endSpaceX, endSpaceY /* endSpaceX endSpaceY 为起点向后偏移的距离  */) {
    const ps1 = []
    const ps2 = []

    function returnBbyK(k, h) {
      const angle = Math.atan(k)
      const newB = h / Math.sin(Math.abs(angle))
      return newB
    }

    function getRightOrLeftNum(k, count, dir, width, b) {
      if (k == 0) {
        return b + -dir * width
      } else if (count == 0) {
        return b + dir * width
      } else {
        return dir * returnBbyK(k, width) * -k + b
      }
    }

    function getLineCapPointJustTwo(k, x_, y_, x__, y__, dir, w, right, left) {
      if (k == 'Infinity' || k == '-Infinity' || isNaN(k)) {
        const startRightP = [x_ + dir * w, 0, y_]
        const startLeftP = [x_ - dir * w, 0, y_]
        ps1.push(startRightP)
        ps2.push(startLeftP)
        const startRightP1 = [x_ + dir * w, 0, y__]
        const startLeftP1 = [x_ - dir * w, 0, y__]
        ps1.push(startRightP1)
        ps2.push(startLeftP1)
      } else if (k == 0) {
        const startRightP = [x_, 0, y_ + dir * w]
        const startLeftP = [x_, 0, y_ - dir * w]
        ps1.push(startRightP)
        ps2.push(startLeftP)
        const startRightP1 = [x__, 0, y_ + dir * w]
        const startLeftP1 = [x__, 0, y_ - dir * w]
        ps1.push(startRightP1)
        ps2.push(startLeftP1)
      } else {
        const k_ = -1 / k // 斜线1的斜率
        const b_ = -k_ * x_ + y_ // 斜线的位移
        const right_p = dataJs.getLinesPoint(k, right, k_, b_)
        const left_p = dataJs.getLinesPoint(k, left, k_, b_)
        ps1.push(right_p)
        ps2.push(left_p)
        const b_1 = -k_ * x__ + y__ // 斜线的位移
        const right_p1 = dataJs.getLinesPoint(k, right, k_, b_1)
        const left_p1 = dataJs.getLinesPoint(k, left, k_, b_1)
        ps1.push(right_p1)
        ps2.push(left_p1)
      }
    }

    function getLineCapPoint(k, x_, dir, w, y_, right, left) {
      if (k == 'Infinity' || k == '-Infinity') {
        const startRightP = [x_ + dir * w, 0, y_]
        const startLeftP = [x_ - dir * w, 0, y_]
        ps1.push(startRightP)
        ps2.push(startLeftP)
      } else if (k == 0) {
        const startRightP = [x_, 0, y_ - dir * w]
        const startLeftP = [x_, 0, y_ + dir * w]
        ps1.push(startRightP)
        ps2.push(startLeftP)
      } else {
        const k_ = -1 / k // 斜线1的斜率
        const b_ = -k_ * x_ + y_ // 斜线的位移
        const right_p = dataJs.getLinesPoint(k, right, k_, b_)
        const left_p = dataJs.getLinesPoint(k, left, k_, b_)
        ps1.push(right_p)
        ps2.push(left_p)
      }
    }

    if (paths.length - 2 > 0) {
      for (let i = 0; i < paths.length - 2; i++) {
        const first = paths[i]
        const next = paths[i + 1]
        const third = paths[i + 2]
        const x = first.x
        const y = first.z
        const nextX = next.x
        const nextY = next.z
        const thirdX = third.x
        const thirdY = third.z
        const count1 = (nextX - x)
        const count2 = (thirdX - nextX)
        const k1 = (nextY - y) / count1 // 斜线1的斜率
        const b1 = count1 == 0 ? x : -k1 * x + y // 斜线的位移
        const k2 = (thirdY - nextY) / count2 // 斜线2的斜率
        const b2 = count2 == 0 ? nextX : -k2 * nextX + nextY // 斜线的位移
        const dir1 = nextX >= x ? (nextY >= y ? 1 : -1) : (nextY > y ? 1 : -1)
        const dir2 = thirdX >= nextX ? (thirdY >= nextY ? 1 : -1) : (thirdY > nextY ? 1 : -1)

        const newrightb1 = getRightOrLeftNum(k1, count1, dir1, width, b1)
        const newleftb1 = getRightOrLeftNum(k1, count1, -dir1, width, b1)

        const newrightb2 = getRightOrLeftNum(k2, count2, dir2, width, b2)
        const newleftb2 = getRightOrLeftNum(k2, count2, -dir2, width, b2)
        // 起始点
        if (i == 0) {
          getLineCapPoint(k1, x, dir1, width, y, newrightb1, newleftb1)
        }
        const right_p = dataJs.getLinesPoint(k1, newrightb1, k2, newrightb2)
        const left_p = dataJs.getLinesPoint(k1, newleftb1, k2, newleftb2)
        if (right_p.length > 0) {
          ps1.push(right_p)
        }
        if (left_p.length > 0) {
          ps2.push(left_p)
        }
        if (i + 2 == paths.length - 1) {
          const newx = thirdX + (thirdX > nextX ? endSpaceX : -endSpaceX)
          const newy = thirdY + (thirdY > nextY ? endSpaceY : -endSpaceY)
          getLineCapPoint(k2, newx, dir2, width, newy, newrightb2, newleftb2)
        }
      }
    } else if (paths.length == 2) {
      for (let i = 0; i < paths.length - 1; i++) {
        const p1 = paths[i]
        const p2 = paths[i + 1]
        if (p1.x == p2.x && p1.z == p2.z) {
          p2.z = p2.z + 0.001
        }
        const count = (p2.x - p1.x)
        const k = (p2.z - p1.z) / count // 斜线1的斜率
        const b = count == 0 ? p1.x : -k * p1.x + p1.z // 斜线的位移
        const dir = p2.x >= p1.x ? (p2.z >= p1.z ? 1 : -1) : (p2.z >= p1.z ? 1 : -1)
        const newrightb = getRightOrLeftNum(k, count, dir, width, b)
        const newleftb = getRightOrLeftNum(k, count, -dir, width, b)
        const X = p2.x + (p2.x > p1.x ? endSpaceX : -endSpaceX)
        const Y = p2.z + (p2.z > p1.z ? endSpaceY : -endSpaceY)
        getLineCapPointJustTwo(k, p1.x, p1.z, X, Y, dir, width, newrightb, newleftb)
      }
    }

    for (let j = ps2.length - 1; j >= 0; j--) {
      const p = ps2[j]
      ps1.push(p)
    }
    return ps1
  }

  return lead
}

export function createLeadWayAnalaysis() {
  const leadWay = new Object()

  const analaysis = createAnalaysisInfo()

  const line = new THREE.Line3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0))

  // 导航状态
  const leadStatus = {
    invalid: 0,
    /* 无效 */
    startGo: 1,
    /* 开始规划路径中 */
    go: 2 /* 前行 */,
    trunLeft: 3 /* 向左 */,
    shouldTrunLeft: 4 /* 即将向左 */,
    trunRight: 5 /* 向右 */,
    shouldTrunRight: 6 /* 即将向右 */,
    louti: 7,
    /* 抵达楼梯 */
    dianti: 8,
    /* 抵达电梯 */
    futi: 9,
    /* 抵达扶梯 */
    wrongWay: 10,
    /* 方向相反 */
    comeEnd: 98 /* 即将到达终点 */,
    end: 99 /* 抵达终点 */
  }

  function getLeadPathOrder(mapOrderIndex, allPathOrder, allPathInfo) {
    if (mapOrderIndex < 0) {
      // 超出路径 认为是到达终点
      return {
        result: false,
        isOut: true,
        stopWorking: false,
      };
    }
    let order = allPathOrder[mapOrderIndex];
    let pathList = allPathInfo[order.mapid];
    let path = pathList[order.index]['path'];
    return {
      result: true,
      mapOrderIndex: mapOrderIndex,
      orderIndex: order.index,
      index: path.length - 1,
    }
  }

  // 方向转动
  leadWay.turnAround = function (config) {
    if (analaysis.jsonKeyIsExist(config, ['findInfo', 'userLocatin']) == false) {
      return {
        result: false,
      }
    }

    let findInfo = config['findInfo'];
    const leadInfo = config.leadInfo;
    const allPathInfo = leadInfo.path;
    let userLocatin = config['userLocatin'];
    return updateAngleInfo(findInfo, allPathInfo, userLocatin, config);
  }

  // 开始导航
  leadWay.startLead = function (config) {
    return new Promise(resolve => {
      let findInfo = returnDefaultFindInfo()
      const leadInfo = config.leadInfo;
      const orderPath = leadInfo.order;
      const allPathInfo = leadInfo.path;
      // 先更新findInfo的所有index
      let res = getLeadPathOrder(orderPath.length - 1, orderPath, allPathInfo);
      if (res.result == false) {
        findInfo.result = true;
        findInfo.isOut = false;
        findInfo.stopWorking = false;
        resolve(findInfo);
        return;
      }
      findInfo.result = true;
      findInfo.mapOrderIndex = res.mapOrderIndex;
      findInfo.orderIndex = res.orderIndex;
      findInfo.index = res.index;
      findInfo.stopWorking = false;
      config['findInfo'] = findInfo;
      leadWay.leadWhenMove(config).then(res => {
        resolve(res);
      });
    })
  }

  function updateAngleInfo(leadInfo, allPathInfo, userPoint, config) {
    if (analaysis.jsonKeyIsExist(allPathInfo, [userPoint.mapid]) == false) {
      return leadInfo;
    }
    let orderPath = allPathInfo[userPoint.mapid];
    if (leadInfo.orderIndex >= orderPath.length) {
      return leadInfo;
    }
    if (leadInfo.index == 0) {
      // 超出目前路径 更换到下一条
      return leadInfo;
    }
    let pathInfo = orderPath[leadInfo.orderIndex];
    let path = pathInfo.path;
    let start = path[leadInfo.index];
    let next = path[leadInfo.index - 1];
    updateAngle(0, 1, next.x - start.x, next.z - start.z, leadInfo)
    let res = judgeDirectionAtPath(leadInfo, pathInfo, config)
    leadInfo.leadInfo['status'] = res.status
    leadInfo.leadInfo['leadUrl'] = res.leadUrl
    leadInfo.leadInfo['direction'] = res.msg
    return leadInfo;
  }

  function pathIsAtTheEnd(path, pathDis, roomDis, finialDis, findInfo) {
    let dis = 0
    for (let i = path.length - 1; i >= findInfo.index; i--) {
      if (i - 1 < 0) {
        break;
      }
      let next = findInfo.index == i ? findInfo.point : path[i - 1];
      let start = path[i];
      dis = dis + returnPointsDis(start, next);
      if (findInfo.index == i) {
        break;
      }
    }
    let leftDis = pathDis == 0 ? 0 : (pathDis - dis)
    let scale = dis / pathDis;
    if (scale < 0) {
      scale = 0;
    } else if (scale > 1) {
      scale = 1;
    }
    findInfo.scale = scale;

    let shouldWorkDis = leftDis - roomDis;
    if (shouldWorkDis < 0) {
      shouldWorkDis = 0;
    }

    if (shouldWorkDis > finialDis) {
      return {
        result: false
      }
    }
    const endPoint = path[0]
    switch (endPoint.type) {
      case 1:
        findInfo.leadInfo.status = leadStatus.louti
        break
      case 2:
        findInfo.leadInfo.status = leadStatus.dianti
        break
      case 3:
        findInfo.leadInfo.status = leadStatus.futi
        break
      case 4:
        findInfo.leadInfo.status = leadStatus.end
        findInfo.leadInfo.isEnd = true;
        findInfo.scale = 0;
        break
    }
    // 抵达连接点或者终点
    return {
      result: true
    }
  }

  function showRealImageMsg(point, nextPoint, config, findInfo) {
    let isEnd = false;
    if (analaysis.jsonKeyIsExist(findInfo, ['leadInfo']) && analaysis.jsonKeyIsExist(findInfo['leadInfo'], ['isEnd'])) {
      isEnd = findInfo['leadInfo']['isEnd']
    }

    const result = {
      isEnd: isEnd,
      type: nextPoint.type,
      reality: false,
      url: '',
      msg: '',
      upOrDown: {
        result: config.upOrDown
      }
    }

    if (analaysis.jsonKeyIsExist(nextPoint, ['img']) == false) {
      return result
    }

    const showRealDis = 60
    const dis = returnPointsDis(point, nextPoint)
    if (dis > showRealDis) {
      return result
    }
    const img = nextPoint['img']
    result.reality = true
    result.url = img['url']
    result.title = img['title']
    return result
  }

  // 导航中行走
  leadWay.leadWhenMove = function (config) {
    /* ------------------------------------------------------------ */
    function findNextPath(allPathInfo, allPathOrder, leadInfo, distance, userPoint) {
      let res = getLeadPathOrder(leadInfo.mapOrderIndex - 1, allPathOrder, allPathInfo);
      if (res.result == false) {
        leadInfo.isOut = false;
        leadInfo.stopWorking = false;
        return leadInfo;
      }
      leadInfo.mapOrderIndex = res.mapOrderIndex;
      leadInfo.index = res.index;
      leadInfo.orderIndex = res.orderIndex;
      return getPoint(allPathInfo, allPathOrder, userPoint, leadInfo, distance);
    }

    function updateFindInfo(leadInfo, i, point, start, next, path, pathInfo, config) {
      let endP = path[0];
      leadInfo.index = i;
      leadInfo.result = true;
      leadInfo.isOut = false;
      leadInfo.stopWorking = false;// 提示超出范围 是否纠偏
      leadInfo.point = point;


      let roomDis = 0;

      let useRoomDis = true;
      if (analaysis.jsonKeyIsExist(config, ['useRoomDis'])) {
        useRoomDis = config['useRoomDis']
      }
      let zeroId = config.zeroId;

      // 判断是否抵达终点
      if (useRoomDis && endP.inMapId != zeroId) {
        // 在房间内
        roomDis = returnEndPointAtRoomPathDis(path, zeroId)
        leadInfo['roomDis'] = roomDis
      } else {
        leadInfo['roomDis'] = 0
      }

      let finialDis = 110;
      if (analaysis.jsonKeyIsExist(config, ['finialDis'])) {
        finialDis = config['finialDis']
      }


      let isEndRes = pathIsAtTheEnd(path, pathInfo.dis, roomDis, finialDis, leadInfo);
      if (isEndRes.result) {
        returnEndTypeMsg(leadInfo)
        leadInfo.leadInfo = showRealImageMsg(leadInfo.point, endP, config, leadInfo)
      } else {
        leadInfo.leadInfo = showRealImageMsg(leadInfo.point, next, config, leadInfo)
      }

      updateAngle(0, 1, next.x - start.x, next.z - start.z, leadInfo)

      let res = judgeDirectionAtPath(leadInfo, pathInfo, config)
      leadInfo.leadInfo['status'] = res.status
      leadInfo.leadInfo['leadUrl'] = res.leadUrl
      leadInfo.leadInfo['direction'] = res.msg
      leadInfo.leadInfo.msg = '--------'
    }

    function getUserNewPoint(config, point, path, startIndex) {
      let userNewLocation = config['userNewLocation'];
      if (analaysis.jsonKeyIsExist(userNewLocation, ['time', 'type']) == false) {
        return {
          result: false,
        };
      }
      let type = userNewLocation['type'];
      if (type != "location") {
        return {
          result: false,
        };
      }
      let nowTime = new Date().getTime();
      let time = userNewLocation['time'];
      let timeCount = Math.abs(nowTime - time);
      let leadJuageTime = 6;
      if (analaysis.jsonKeyIsExist(localStorage, ['leadJuageTime'])) {
        leadJuageTime = parseFloat(localStorage['leadJuageTime']);
      }
      if (timeCount >= leadJuageTime * 1000) {
        return {
          result: false,
        };
      }

      let workScale = 10;
      if (analaysis.jsonKeyIsExist(localStorage, ['workScale'])) {
        workScale = parseFloat(localStorage['workScale']);
      }


      let locationDis = returnPointsDis(userNewLocation, point);

      let normalWork = 5;
      if (analaysis.jsonKeyIsExist(localStorage, ['normalWork'])) {
        normalWork = parseFloat(localStorage['normalWork']);
      }

      let cutDownWork = 10;
      if (analaysis.jsonKeyIsExist(localStorage, ['cutDownWork'])) {
        cutDownWork = parseFloat(localStorage['cutDownWork']);
      }

      if (normalWork > cutDownWork) {
        cutDownWork = normalWork + 5;
      }

      let addSpeed = false;

      let minDis = 65535;

      let index = -1;

      let comeToLineDis = 4;

      if (analaysis.jsonKeyIsExist(localStorage, ['comeToLineDis'])) {
        comeToLineDis = parseFloat(localStorage['comeToLineDis']);
      }

      let np = {x: null, y: 3, z: null};

      for (let i = startIndex; i > 0; i--) {
        let start = i == startIndex ? point : path[i];
        let next = path[i - 1];
        let res = pointCloseToLine(start, next, userNewLocation);
        if (res.scale > 1 || res.scale < 0) {
          continue;
        }
        let d = returnPointsDis(res.point, userNewLocation);
        if (d < (comeToLineDis * workScale) && d < minDis) {
          // 蓝牙定位落在前面需要加速
          minDis = d;
          addSpeed = true;
          index = i;
          np.x = res.point.x;
          np.z = res.point.z;
        }
      }

      if (np.x == null || np.z == null) {
        if (locationDis <= normalWork * workScale) {
          return {
            result: false,
          };
        } else if (locationDis > normalWork * workScale && locationDis <= cutDownWork * workScale) {
          // 减个速缓缓
          return {
            result: true,
            stopWorking: false,
            addSpeed: false, // 是否超前
          };
        } else {
          // 超出范围不行走
          return {
            result: true,
            stopWorking: true, // 提示纠偏
            addSpeed: false, // 是否超前
          };
        }
      }


      let dis = 0;
      // 计算该点距离目前位置的距离
      for (let i = startIndex; i >= index; i--) {
        let start = i == startIndex ? point : path[i];
        let next = i == index ? np : path[i - 1];
        let d = returnPointsDis(start, next);
        dis = d + dis;
      }

      let correctWork = 3;
      if (analaysis.jsonKeyIsExist(localStorage, ['correctWork'])) {
        correctWork = parseFloat(localStorage['correctWork']);
      }

      let correctLimitWork = 7;
      if (analaysis.jsonKeyIsExist(localStorage, ['correctLimitWork'])) {
        correctLimitWork = parseFloat(localStorage['correctLimitWork']);
      }

      if (dis <= correctWork * workScale) {
        // 在接收范围内 不做处理
        return {
          result: false,
          p: np,
          index: index,
        };
      } else if (dis > correctWork * workScale && dis <= correctLimitWork * workScale) {
        // 该提速
        return {
          result: true,
          addSpeed: true,
          dis: dis,
          p: np,
          index: index,
        };
      } else {
        // 超出范围直接拉到该位置
        return {
          result: true,
          forceToMove: true,
          dis: dis,
          p: np,
          index: index,
        };
      }
    }

    function findPathPointByDistance(leadInfo, distance, path, pathInfo, mapid, useCorrect, config) {
      let leftDis = distance;
      for (let i = leadInfo.index; i >= 1; i--) {
        let start = path[i];
        let next = path[i - 1];
        let dis = returnPointsDis(start, next);
        if (i == leadInfo.index && analaysis.jsonKeyIsExist(leadInfo.point, ['x', 'z'])) {
          let indexDis = returnPointsDis(leadInfo.point, start)
          dis = dis - indexDis;
        }
        let d = leftDis - dis;
        if (d > 0) {
          leftDis = d;
          continue;
        }
        // 说明前进距离落在该段上
        let v = new THREE.Vector3(next.x - start.x, 0, next.z - start.z)
        let s = Math.abs(leftDis / returnPointsDis(start, next));
        let newPoint = v.multiplyScalar(s)
        let x = 0;
        let z = 0;
        if (i == leadInfo.index && analaysis.jsonKeyIsExist(leadInfo.point, ['x', 'z'])) {
          x = leadInfo['point']['x'] + newPoint.x;
          z = leadInfo['point']['z'] + newPoint.z;
        } else {
          x = newPoint.x + start.x;
          z = newPoint.z + start.z
        }

        if (useCorrect && analaysis.jsonKeyIsExist(config, ['userNewLocation'])) {
          let correctRes = getUserNewPoint(config, {x: x, z: z, y: start.y, mapid: mapid}, path, i);
          if (analaysis.jsonKeyIsExist(correctRes, ['p'])) {
            leadInfo['p'] = correctRes.p;
          }
          if (correctRes.result) {
            if (correctRes.stopWorking) {
              leadInfo.stopWorking = true;
              return leadInfo;
            }
            leadInfo.stopWorking = false;


            let forceToMove = false;
            if (analaysis.jsonKeyIsExist(correctRes, ['forceToMove'])) {
              forceToMove = correctRes['forceToMove'];
            }

            if (forceToMove) {
              let newDistance = distance + correctRes.dis;
              return findPathPointByDistance(leadInfo, newDistance, path, pathInfo, mapid, false, config);
            }


            let addSpeed = false;
            if (analaysis.jsonKeyIsExist(correctRes, ['addSpeed'])) {
              addSpeed = correctRes['addSpeed'];
            }

            let multipleScale = 0.5;
            if (analaysis.jsonKeyIsExist(localStorage, ['multipleScale'])) {
              multipleScale = parseFloat(localStorage['multipleScale']);
            }

            if (addSpeed == false) {
              let fs = multipleScale * -1 + 1;
              if (fs < 0) {
                fs = 0;
              }
              let newDistance = distance * fs
              return findPathPointByDistance(leadInfo, newDistance, path, pathInfo, mapid, false, config);
            }

            let newDistance = distance + correctRes.dis * multipleScale;
            return findPathPointByDistance(leadInfo, newDistance, path, pathInfo, mapid, false, config);
          }
        }
        updateFindInfo(leadInfo, i, {x: x, y: start.y, z: z, mapid: mapid}, start, next, path, pathInfo, config);
        return {
          result: true,
        };
      }
      return {
        result: false,
      }
    }

    // 找到距离路径最近的点
    function findClosePointToLine(config, point, path, startIndex) {
      let userNewLocation = config['userNewLocation'];

      let d = returnPointsDis(point, userNewLocation);

      let workScale = 10;
      if (analaysis.jsonKeyIsExist(localStorage, ['workScale'])) {
        workScale = parseFloat(localStorage['workScale']);
      }

      let minDis = 65535;

      let index = -1;

      let comeToLineDis = 4;

      if (analaysis.jsonKeyIsExist(localStorage, ['comeToLineDis'])) {
        comeToLineDis = parseFloat(localStorage['comeToLineDis']);
      }

      let np = {x: null, y: 3, z: null};

      for (let i = path.length - 1; i > 0; i--) {
        let start = path[i];
        let next = path[i - 1];
        let res = pointCloseToLine(start, next, userNewLocation);
        if (res.scale > 1 || res.scale < 0) {
          let p = res.scale > 1 ? next : start;
          let lineDis = returnPointsDis(p, userNewLocation);
          if (lineDis < (comeToLineDis * workScale) && lineDis < minDis) {
            minDis = lineDis;
            index = i;
            np.x = p.x;
            np.z = p.z;
          }
          continue;
        }
        let getDis = returnPointsDis(res.point, userNewLocation);
        if (getDis < (comeToLineDis * workScale) && getDis < minDis) {
          minDis = getDis;
          index = i;
          np.x = res.point.x;
          np.z = res.point.z;
        }
      }

      if (minDis > comeToLineDis * workScale) {
        return {
          result: true,
          isOverFence: true,
        }
      }
      if (d <= comeToLineDis * workScale && (np.x == null || np.z == null)) {
        return {
          result: true,
          isOverFence: false,
          dis: 0,
          p: point,
          index: startIndex,
        };
      }
      return {
        result: true,
        isOverFence: false,
        dis: minDis,
        p: np,
        index: index,
      };
    }

    // 找到距离路径最近的点
    // function findClosePointToLine(config, point, path, startIndex) {
    //   let userNewLocation = config['userNewLocation'];
    //
    //   let d = returnPointsDis(point, userNewLocation);
    //
    //
    //   let workScale = 10;
    //   if (analaysis.jsonKeyIsExist(localStorage, ['workScale'])) {
    //     workScale = parseFloat(localStorage['workScale']);
    //   }
    //
    //   if (d <= 1 * workScale) {
    //     // 在一定范围内不动
    //     return {
    //       result: true,
    //       isOverFence: false,
    //       dis: 0,
    //       p: point,
    //       index: startIndex,
    //     };
    //   }
    //
    //   let minDis = 65535;
    //
    //   let index = -1;
    //
    //   let comeToLineDis = 4;
    //
    //   if (analaysis.jsonKeyIsExist(localStorage, ['comeToLineDis'])) {
    //     comeToLineDis = parseFloat(localStorage['comeToLineDis']);
    //   }
    //
    //   let np = {x: null, y: 3, z: null};
    //
    //   for (let i = startIndex; i > 0; i--) {
    //     let start = i == startIndex ? point : path[i];
    //     let next = path[i - 1];
    //     let res = pointCloseToLine(start, next, userNewLocation);
    //     if (res.scale > 1 || res.scale < 0) {
    //       continue;
    //     }
    //     let d = returnPointsDis(res.point, userNewLocation);
    //     if (d < (comeToLineDis * workScale) && d < minDis) {
    //       // 蓝牙定位落在前面需要加速
    //       minDis = d;
    //       index = i;
    //       np.x = res.point.x;
    //       np.z = res.point.z;
    //     }
    //   }
    //
    //
    //   let correctLimitWork = 7;
    //   if (analaysis.jsonKeyIsExist(localStorage, ['correctLimitWork'])) {
    //     correctLimitWork = parseFloat(localStorage['correctLimitWork']);
    //   }
    //
    //   if ((np.x == null || np.z == null)) {
    //     if (d <= correctLimitWork * workScale) {
    //       return {
    //         result: false,
    //       }
    //     } else {
    //       return {
    //         result: true,
    //         isOverFence: true,
    //       }
    //     }
    //   }
    //   return {
    //     result: true,
    //     isOverFence: false,
    //     dis: minDis,
    //     p: np,
    //     index: index,
    //   };
    //   // let dis = returnPointsDis(point, np);
    //   // if (dis <= correctLimitWork * workScale) {
    //   //   return {
    //   //     result: true,
    //   //     isOverFence: false,
    //   //     dis: minDis,
    //   //     p: np,
    //   //     index: index,
    //   //   };
    //   // } else {
    //   //   // 超出范围
    //   //   return {
    //   //     result: true,
    //   //     isOverFence: true,
    //   //     dis: minDis,
    //   //     p: np,
    //   //     index: index,
    //   //   };
    //   // }
    // }

    function getBluePathPoint(leadInfo, orderPath, config, pathInfo) {
      if (analaysis.jsonKeyIsExist(config, ['userNewLocation']) == false) {
        leadInfo.result = false;
        return {
          result: true,
        }
      }
      if (analaysis.jsonKeyIsExist(leadInfo, ['point']) == false) {
        leadInfo.result = false;
        return {
          result: true,
        }
      }

      let point = leadInfo.point;
      let pointInfo = {
        dis: 65535,
        result: false,
        index: -1,
        point: null,
        orderIndex: -1,
        isOverFence: false,
      }
      for (let i = leadInfo.orderIndex; i >= 0; i--) {
        let pathWay = orderPath[i].path;
        let res = findClosePointToLine(config, point, pathWay, i == leadInfo.orderIndex ? leadInfo.index : pathWay.length - 1);
        if (res.result == false) {
          continue;
        }
        if (pointInfo.dis < res.dis) {
          continue;
        }
        pointInfo.result = true;
        pointInfo.dis = res.dis;
        pointInfo.index = res.index;
        pointInfo.orderIndex = i;
        pointInfo.point = res.p;
        pointInfo.isOverFence = res.isOverFence;
      }
      if (pointInfo.result == false) {
        leadInfo.result = false;
        return {
          result: true,
        }
      }
      leadInfo.result = true;
      if (pointInfo.isOverFence) {
        // 超过范围
        leadInfo.stopWorking = true;
        return {
          result: true,
        }
      }

      leadInfo.stopWorking = false;

      let path = orderPath[pointInfo.orderIndex].path;
      let start = path[pointInfo.index];
      let next = path[pointInfo.index - 1];
      let userNewLocation = config['userNewLocation'];
      let newPoint = {
        x: pointInfo.point.x,
        y: start.y,
        z: pointInfo.point.z,
        mapid: userNewLocation.mapid,
      }
      updateFindInfo(leadInfo, pointInfo.index, newPoint, start, next, path, pathInfo, config);
      leadInfo.orderIndex = pointInfo.orderIndex;
      return {
        result: true,
      }
    }

    function getPoint(allPathInfo, allPathOrder, userPoint, leadInfo, distance) {
      if (leadInfo.mapOrderIndex < 0) {
        // 超出路径 认为是到达终点
        leadInfo.result = false;
        leadInfo.isOut = true;
        leadInfo.stopWorking = false;
        return leadInfo;
      }

      let mapid = userPoint.mapid
      if (analaysis.jsonKeyIsExist(allPathInfo, [mapid]) == false) {
        // 不存在该路径
        leadInfo.result = false;
        leadInfo.isOut = false;
        leadInfo.stopWorking = false;
        return leadInfo;
      }
      let orderPath = allPathInfo[mapid];
      if (leadInfo.orderIndex >= orderPath.length) {
        // 超出目前路径 更换到下一条
        return findNextPath(allPathInfo, allPathOrder, leadInfo, distance, userPoint)
      }

      if (leadInfo.index == 0) {
        // // 超出目前路径 更换到下一条
        // return findNextPath(allPathInfo, allPathOrder, leadInfo, distance, userPoint)
        return leadInfo;
      }

      let useCorrect = true;
      if (analaysis.jsonKeyIsExist(config, ['useCorrect'])) {
        useCorrect = config['useCorrect']
      }

      // 当前用户所在的路线
      let pathInfo = orderPath[leadInfo.orderIndex];
      let path = pathInfo.path;

      let useInertia = '1';
      if (analaysis.jsonKeyIsExist(config, ['useInertia'])) {
        useInertia = config['useInertia']
      }


      if (useInertia == '1') {
        // 使用惯导
        let res = findPathPointByDistance(leadInfo, distance, path, pathInfo, mapid, useCorrect, config);
        if (res.result) {
          return leadInfo;
        }
      } else {
        let res = getBluePathPoint(leadInfo, orderPath, config, pathInfo);
        if (res.result) {
          return leadInfo;
        }
      }


      let start = path[1];
      let next = path[0];
      updateFindInfo(leadInfo, 1, {
        x: next.x,
        y: start.y,
        z: next.z,
        mapid: mapid
      }, start, next, path, pathInfo, config);
      return leadInfo
    }

    // 计算用户在导航路线上行走距离后的点
    function getUserMoveOnPathPoint(findInfo, config) {
      if (analaysis.jsonKeyIsExist(config, ['userLocatin']) == false) {
        return {
          result: false,
          isOut: true,
        };
      }

      let userLocatin = config['userLocatin'];
      const leadInfo = config.leadInfo;
      const orderPath = leadInfo.order;
      const allPathInfo = leadInfo.path;
      let distance = 0; // 行走的距离
      if (analaysis.jsonKeyIsExist(config, ['distance'])) {
        distance = config['distance'];
      }

      return getPoint(allPathInfo, orderPath, userLocatin, findInfo, distance)
    }


    function isChangeMap(findInfo, config) {
      if (analaysis.jsonKeyIsExist(config, ['userLocatin', 'userNewLocation']) == false) {
        return {
          result: false,
        };
      }
      let userLocatin = config['userLocatin'];
      let userNewLocation = config['userNewLocation'];

      if (userLocatin.mapid == userNewLocation.mapid) {
        // 在同一层楼
        return {
          result: false,
        };
      }
      // 不在同一楼层 先判断路径中是否还有新的mapid的路径 如果没有 则其位置不变
      const leadInfo = config.leadInfo;
      const allPathInfo = leadInfo.path;
      let point = {
        x: userNewLocation.x,
        y: userNewLocation.y,
        z: userNewLocation.z,
        mapid: userNewLocation.mapid,
      }
      if (analaysis.jsonKeyIsExist(allPathInfo, [userNewLocation.mapid]) == false) {
        // updateAngleInfo(findInfo, allPathInfo, userLocatin, config);
        findInfo.stopWorking = true;
        findInfo.point.x = point.x;
        findInfo.point.z = point.z;
        findInfo.point.mapid = point.mapid;
        return {
          result: true,
        };
      }

      let mapOrderIndex = 0;

      let index = 0;

      let orderIndex = 0;

      let minDis = 65535;


      let comeToLineDis = 4;

      if (analaysis.jsonKeyIsExist(localStorage, ['comeToLineDis'])) {
        comeToLineDis = parseFloat(localStorage['comeToLineDis']);
      }

      let orderPath = allPathInfo[point.mapid];
      for (let i = orderPath.length - 1; i >= 0; i--) {
        let pathInfo = orderPath[i];
        let path = pathInfo.path;
        let dis = 0;
        for (let j = path.length - 1; j > 0; j--) {
          let start = path[j];
          let next = path[j - 1];
          let res = pointCloseToLine(start, next, userNewLocation);
          dis = dis + returnPointsDis(start, next);
          if (analaysis.jsonKeyIsExist(res, ['dis']) == false || minDis < res.dis) {
            continue;
          }
          // if (res.scale > 1 || res.scale < 0) {
          //   continue;
          // }
          let limitDis = comeToLineDis * 10;
          if (res.scale > 1) {
            let d = returnPointsDis(res.point, next);
            if (d > limitDis) {
              continue;
            }
            point.x = start.x;
            point.z = start.z;
            res.dis = d;
          } else if (res.scale < 0) {
            let d = returnPointsDis(res.point, start);
            if (d > limitDis) {
              continue;
            }
            point.x = next.x;
            point.z = next.z;
            res.dis = d;
          } else {
            point.x = res.point.x;
            point.z = res.point.z;
          }

          minDis = res.dis;
          mapOrderIndex = pathInfo.orderIndex;
          index = j;
          orderIndex = i;

        }
      }
      if (minDis == 65535) {
        return {
          result: false,
        };
      }
      findInfo.stopWorking = false;
      findInfo.index = index;
      findInfo.orderIndex = orderIndex;
      findInfo.mapOrderIndex = mapOrderIndex;
      let pathInfo = orderPath[orderIndex];
      let path = pathInfo.path;
      let dis = 0;
      for (let j = path.length - 1; j >= index; j--) {
        let start = path[j];
        let next = path[j - 1];
        if (j == index) {
          dis = dis + returnPointsDis(start, point);
        } else {
          dis = dis + returnPointsDis(start, next);
        }
      }
      findInfo.scale = dis / pathInfo.dis;
      findInfo.point.x = point.x;
      findInfo.point.z = point.z;
      findInfo.point.mapid = point.mapid;
      config.mapid = point.mapid;
      updateAngleInfo(findInfo, allPathInfo, userNewLocation, config);
      judgeLeadMessage(config, findInfo.orderIndex, findInfo)
      updateRemarkRecord(config, findInfo)
      findInfo.result = true;
      return {
        result: true,
      };
    }

    /* ------------------------------------------------------------ */
    return new Promise(resolve => {
      let findInfo = returnDefaultFindInfo();
      if (analaysis.jsonKeyIsExist(config, ['findInfo'])) {
        findInfo = config['findInfo'];
      }
      // 判断是否用户走到其他楼层
      let changeRes = isChangeMap(findInfo, config);
      if (changeRes.result) {
        resolve(findInfo);
        return;
      }


      let res = getUserMoveOnPathPoint(findInfo, config);
      if (res.result == false) {
        resolve(res);
        return;
      }
      judgeLeadMessage(config, findInfo.orderIndex, findInfo)
      updateRemarkRecord(config, findInfo)
      resolve(findInfo);
    })
  }

  leadWay.analaysisWay = function (config) {
    const mapid = config.mapid
    const leadInfo = config.leadInfo
    const allPathInfo = leadInfo.path
    if (analaysis.jsonKeyIsExist(allPathInfo, [mapid]) == false) {
      return {
        result: false,
        isOut: true
      }
    }

    const pathInfo = allPathInfo[mapid]
    for (let i = 0; i < pathInfo.length; i++) {
      const info = pathInfo[i]
      const res = isOutOfWay(info, config, i)
      if (res.result) {
        judgeLeadMessage(config, i, res)
        updateRemarkRecord(config, res)
        return res
      }
    }
    return {
      result: false,
      isOut: true
    }
  }

  // 导航信息计算
  function judgeLeadMessage(config, index, findInfo) {
    const leadInfo = config.leadInfo
    const allPathInfo = leadInfo.path
    let allDis = 0
    for (const mapid in allPathInfo) {
      const mapPathList = allPathInfo[mapid]
      for (let i = 0; i < mapPathList.length; i++) {
        const path = mapPathList[i]
        allDis = path.dis + allDis
      }
    }

    const pathOrder = leadInfo.order
    const mapid = config.mapid
    let dis = 0
    let fromMapid = ''
    let toMapid = ''
    for (let i = 0; i < pathOrder.length; i++) {
      const order = pathOrder[i]
      const pathMapid = order.mapid
      const pathList = allPathInfo[pathMapid]
      const orderIndex = order.index
      if (pathMapid == mapid && orderIndex == index) {
        // 当前所在路径
        const info = pathList[orderIndex]
        dis = dis + info.dis * (1 - findInfo.scale)
        fromMapid = pathMapid
        let nextIndex = i - 1
        if (nextIndex < 0) {
          nextIndex = 0
        }
        if (nextIndex >= pathOrder.length) {
          nextIndex = pathOrder.length - 1
        }
        const nextOrder = pathOrder[nextIndex]
        toMapid = nextOrder.mapid
        findInfo['mapOrderIndex'] = i
      } else {
        const info = pathList[orderIndex]
        dis = dis + info.dis
      }
    }


    if (analaysis.jsonKeyIsExist(findInfo, ['roomDis'])) {
      dis = dis - parseFloat(findInfo['roomDis'])
    }

    findInfo['workScale'] = (allDis == 0 ? 0 : (allDis - dis) / allDis)
    findInfo['allDis'] = allDis
    const scale = config.scale
    const stepLength = 0.6
    dis = dis / scale
    const time = dis / stepLength
    let timeMsg = ' 耗时约'
    if (time < 60) {
      timeMsg = timeMsg + (time).toFixed(0) + '秒'
    } else if (time >= 60 && time < 60 * 60) {
      timeMsg = timeMsg + (time / 60).toFixed(0) + '分'
    } else if (time < 60 * 60 * 24 && time >= 60 * 60) {
      timeMsg = timeMsg + (time / (60 * 60)).toFixed(0) + '小时'
    } else {
      timeMsg = timeMsg + time + '秒'
    }
    findInfo.leadInfo['timeMsg'] = timeMsg
    findInfo.leadInfo['dis'] = ' 与目的地相距约' + parseFloat(dis.toFixed(0)) + '米'
    findInfo['startMapid'] = mapid
    findInfo['nextMapid'] = mapid
    if (fromMapid == '' || toMapid == '') {
      return
    }
    const orderList = leadInfo.orderList
    let fromMapidIndex = -1
    let toMapidIndex = -1
    for (let i = 0; i < orderList.length; i++) {
      const info = orderList[i]
      if (info.mapid == fromMapid) {
        fromMapidIndex = info.order
      }
      if (info.mapid == toMapid) {
        toMapidIndex = info.order
      }
      if (toMapidIndex >= 0 && fromMapidIndex >= 0) {
        break
      }
    }
    if (toMapidIndex < 0 || fromMapidIndex < 0) {
      return
    }

    if (analaysis.jsonKeyIsExist(findInfo.leadInfo, ['upOrDown'])) {
      findInfo.leadInfo.upOrDown.result = true
      findInfo.leadInfo.upOrDown.isUp = toMapidIndex - fromMapidIndex < 0
    } else {
      findInfo.leadInfo.upOrDown = {
        result: true,
        isUp: toMapidIndex - fromMapidIndex < 0,
      }
      findInfo.leadInfo.reality = false;
    }


    findInfo['nextMapid'] = toMapid
  }

  function isOutOfWay(pathInfo, config) {
    const res = judgeIsOutOfWay(pathInfo, config)
    if (res.result == false) {
      return res
    }
    // 判断是否抵达终点
    return locationIsArriveEndRoom(pathInfo, config, res)
  }

  function pointCloseToLine(startP, endP, point) {
    if (startP.x == endP.x && startP.z == endP.z) {
      if (point.x == endP.x && point.z == endP.z) {
        return {
          result: true,
          point: point,
          scale: 1
        }
      } else {
        return {
          result: false
        }
      }
    }

    line.start = new THREE.Vector3(startP.x, 0, startP.z)
    line.end = new THREE.Vector3(endP.x, 0, endP.z)
    const newPoint = new THREE.Vector3(0, 0, 0)
    line.closestPointToPoint(new THREE.Vector3(point.x, 0, point.z), false, newPoint)
    let res = line.closestPointToPointParameter(newPoint, false)
    res = parseFloat(res.toFixed(2))
    let dis = returnPointsDis(newPoint, point);
    return {
      result: (res >= 0 && res <= 1),
      point: newPoint,
      scale: res,
      dis: dis
    }
  }

  // 是否显示实景图
  function shouldShowRealImage(point, nextPoint, config) {
    const result = {
      isEnd: false,
      type: nextPoint.type,
      reality: false,
      url: '',
      msg: '',
      upOrDown: {
        result: config.upOrDown
      }
    }
    const showRealDis = 60
    const dis = returnPointsDis(point, nextPoint)
    if (analaysis.jsonKeyIsExist(nextPoint, ['img']) && dis <= showRealDis) {
      const img = nextPoint['img']
      result.reality = true
      result.url = img['url']
      result.title = img['title']
    }
    return result
  }

  leadWay.getDirectionMsg = function (config, findInfo) {
    if (findInfo.result == false) {
      return findInfo
    }
    const mapid = config.mapid
    const leadInfo = config.leadInfo
    if (analaysis.jsonKeyIsExist(leadInfo, [mapid]) == false) {
      return findInfo
    }
    const mapPath = leadInfo[mapid]
    if (findInfo.orderIndex >= mapPath.length) {
      return findInfo
    }
    const pathInfo = mapPath[findInfo.orderIndex]
    const res = judgeDirectionAtPath(findInfo, pathInfo, config)
    let type = 'location'
    if (analaysis.jsonKeyIsExist(config, ['type'])) {
      type = config['type']
    }
    if (type == 'location') {
      findInfo.leadInfo['status'] = res.status
      findInfo.leadInfo['leadUrl'] = res.leadUrl
      findInfo.leadInfo['direction'] = res.msg
    }
    findInfo.leadInfo.msg = '--------'
    return findInfo
  }

  // 判断手机与道路的方向
  function judgeDirectionAtPath(findInfo, pathInfo, config) {
    function updateAngle(first, next) {
      const v1 = new THREE.Vector3(next[0], 0, next[1])
      const v2 = new THREE.Vector3(first[0], 0, first[1])
      const angle = parseFloat((v1.angleTo(v2) / Math.PI * 180.0).toFixed(1))
      return {
        rotateAngle: angle,
        closeWise: (-1 * first[0] * next[1] + first[1] * next[0]),
      }
    }

    // 返回两端线段的夹角
    function returnLinesAngle(dir1, dir2) {
      if (dir1.length != 2 && dir2.length != 2) {
        // 只计算二维向量的方向
        return 0
      }
      const cos = (dir1[0] * dir2[0] + dir1[1] * dir2[1]) / ((Math.sqrt(dir1[0] * dir1[0] + dir1[1] * dir1[1])) * (Math
        .sqrt(dir2[0] * dir2[0] + dir2[1] * dir2[1])))
      const arccos = Math.acos(cos)
      return arccos
    }

    let leadDirectionType = 'byUser'
    if (analaysis.jsonKeyIsExist(config, ['leadDirectionType'])) {
      leadDirectionType = config['leadDirectionType']
    }

    let shouldDirection = true
    if (analaysis.jsonKeyIsExist(config, ['shouldDirection'])) {
      shouldDirection = config['shouldDirection']
    }
    if (shouldDirection == false) {
      return {
        msg: '请按道路前方前行',
        status: leadStatus.go,
        leadUrl: 'gogo'
      }
    }

    const path = pathInfo.path
    if (findInfo.index < 0 || path.length == 0) {
      return {
        msg: '请按道路前方前行',
        status: leadStatus.go,
        leadUrl: 'gogo'
      }
    }

    const minDirection = Math.PI / 3 // 用于导航的时候 转角小于该角度判断是直线行走

    const first = []
    const next = []
    let p1 = null
    let p2 = null;

    if (leadDirectionType == 'byWay') {
      for (let i = findInfo.index; i >= 0; i--) {
        let p = path[i];
        if (analaysis.jsonKeyIsExist(p, ['radius'])) {
          continue;
        }
        if (p1 == null) {
          p1 = p;
          continue;
        }
        if (p2 == null) {
          p2 = p;
        }
        if (p1 != null && p2 != null) {
          break;
        }
      }

      if (p1 == null || p2 == null) {
        return {
          msg: '请按道路前方前行',
          status: leadStatus.go,
          leadUrl: 'gogo'
        }
      }

      // 按照道路方向提示
      let p3 = p1
      let p4 = p2

      let findP3 = false
      let findP4 = false

      for (let i = findInfo.index - 2; i >= 0; i--) {
        const p = path[i]
        if (analaysis.jsonKeyIsExist(p, ['radius'])) {
          continue
        }
        if (findP3 == false) {
          p3 = p
          findP3 = true
        } else if (findP4 == false) {
          p4 = p
          findP4 = true
        }
        if (findP3 && findP4) {
          break
        }
      }
      next.push(p4.x - p3.x)
      next.push(p4.z - p3.z)

      first.push(p2.x - p1.x)
      first.push(p2.z - p1.z)

      const trunNext = []

      trunNext.push(p2.x - p1.x)
      trunNext.push(p2.z - p1.z)
      let direction = config.direction
      while (direction >= 2 * Math.PI) {
        direction = direction - 2 * Math.PI
      }
      while (direction < 0) {
        direction = direction + 2 * Math.PI
      }
      const trunFrist = []

      trunFrist.push(Math.sin(direction))
      trunFrist.push(-Math.cos(direction))

      let rotateAngleRes = updateAngle([0, -1], first);
      let rotateAngle = rotateAngleRes.rotateAngle;
      let rotateWise = rotateAngleRes.closeWise;
      if (rotateWise > 0) {
        rotateAngle = -1 * rotateAngle;
      }
      findInfo['mapRotateAngle'] = rotateAngle;
      let closeWiseRes = updateAngle(trunFrist, trunNext)
      findInfo['closeWise'] = closeWiseRes.closeWise;
      findInfo['rotateAngle'] = closeWiseRes.rotateAngle;

    } else {
      p1 = path[findInfo.index]
      p2 = path[findInfo.index - 1]
      // 按照用户方向提示
      next.push(p2.x - p1.x)
      next.push(p2.z - p1.z)
      let direction = config.direction
      while (direction >= 2 * Math.PI) {
        direction = direction - 2 * Math.PI
      }
      while (direction < 0) {
        direction = direction + 2 * Math.PI
      }
      first.push(Math.sin(direction))
      first.push(-Math.cos(direction))
      let res = updateAngle(first, next);
      findInfo['rotateAngle'] = res.rotateAngle;
      findInfo['closeWise'] = res.closeWise;
    }

    const arccos = returnLinesAngle(first, next)
    const d = -1 * first[0] * next[1] + first[1] * next[0]
    if (arccos <= minDirection) {
      return {
        status: leadStatus.go,
        msg: '请按道路前方前行',
        leadUrl: 'gogo'
      }
    }

    if (arccos > Math.PI / 18 * 10) {
      return {
        status: leadStatus.wrongWay,
        msg: '偏离道路',
        leadUrl: 'gogo'
      }
    } else {
      let workScale = 10;
      if (analaysis.jsonKeyIsExist(localStorage, ['workScale'])) {
        workScale = parseFloat(localStorage['workScale']);
      }
      let shouldTrunDis = 5;
      if (analaysis.jsonKeyIsExist(localStorage, ['shouldTrunDis'])) {
        shouldTrunDis = parseFloat(localStorage['shouldTrunDis']);
      }
      let trunDis = 2;
      if (analaysis.jsonKeyIsExist(localStorage, ['trunDis'])) {
        trunDis = parseFloat(localStorage['trunDis']);
      }
      const dis = returnPointsDis(findInfo['point'], p2)
      if (d < 0) {
        // 右
        let res = {
          status: leadStatus.trunRight,
          msg: '右转',
          leadUrl: 'right'
        }
        if (leadDirectionType == 'byWay') {
          if (dis >= shouldTrunDis * workScale) {
            res = {
              status: leadStatus.go,
              msg: '请按道路前方前行',
              leadUrl: 'gogo'
            }
          } else if (dis <= shouldTrunDis * workScale && dis >= trunDis * workScale) {
            res.status = leadStatus.shouldTrunRight;
            res.msg = '前方即将右转'
          } else {
            res.msg = '请按道路方向右转'
          }
        }
        return res
      } else if (d == 0) {
        return {
          status: leadStatus.go,
          msg: '请按道路前方前行',
          leadUrl: 'gogo'
        }
      } else {
        // 左
        let res = {
          status: leadStatus.trunLeft,
          msg: '左转',
          leadUrl: 'left'
        }
        if (leadDirectionType == 'byWay') {
          if (dis >= shouldTrunDis * workScale) {
            res = {
              status: leadStatus.go,
              msg: '请按道路前方前行',
              leadUrl: 'gogo'
            }
          } else if (dis <= shouldTrunDis * workScale && dis >= trunDis * workScale) {
            res.msg = '前方即将左转'
            res.status = leadStatus.shouldTrunLeft;
          } else {
            res.msg = '请按道路方向左转'
          }
        }
        return res
      }
    }
    return {
      status: leadStatus.go,
      msg: '请按道路前方前行',
      leadUrl: 'gogo'
    }
  }

  // 判读是否远离轨迹
  function judgeIsOutOfWay(pathInfo, config) {
    /* --------------------------------------- */
    function findOutDistance(path, findInfo) {
      let dis = 0
      for (let i = path.length - 1; i > 0; i--) {
        const p1 = path[i]
        const p2 = path[i - 1]
        if (findInfo.index == i) {
          dis = returnPointsDis(p1, findInfo.point) + dis
          break
        }
        dis = returnPointsDis(p1, p2) + dis
      }
      return dis
    }

    // 定位落在走廊
    function locationNotAtRoom(pathInfo, config) {
      const pathDis = pathInfo.dis
      const path = pathInfo.path
      const point = config.point
      const space = config.space
      const findInfo = returnDefaultFindInfo()
      const zeroId = config.zeroId
      for (let i = path.length - 1; i > 0; i--) {
        const p1 = path[i]
        const p2 = path[i - 1]
        let p1InMapId = zeroId
        let p2InMapId = zeroId
        if (analaysis.jsonKeyIsExist(p1, ['inMapId'])) {
          p1InMapId = p1['inMapId']
        }
        if (analaysis.jsonKeyIsExist(p2, ['inMapId'])) {
          p2InMapId = p2['inMapId']
        }
        if (p1InMapId != zeroId || p2InMapId != zeroId) {
          continue
        }
        const res = pointCloseToLine(p1, p2, point)
        const p = res.point
        const closeTodis = returnPointsDis(p, point) // 定位点和透射点的距离
        if (closeTodis > space) {
          continue
        }
        const pointDis = returnPointsDis(p1, p2)
        const s = pointDis == 0 ? 0 : space / pointDis

        if (i != path.length - 1) {
          if (res.scale > 1.0 + s || res.scale < 0 - s) {
            // 超出范围
            continue
          }
        }
        if (res.result) {
          // 投影的点在该线段内
          findPointAtPath(p, p2, config, findInfo, i)
          updateAngle(0, 1, p2.x - p1.x, p2.z - p1.z, findInfo)
        } else {
          // 投影的点在该线段外
          const nextPoint = res.scale < 0 ? p1 : p2
          const findDis = returnPointsDis(p, nextPoint)
          if (findDis <= space) {
            findPointAtPath(nextPoint, nextPoint, config, findInfo, i)
            updateAngle(0, 1, p2.x - p1.x, p2.z - p1.z, findInfo)
          }
        }
      }
      const dis = findOutDistance(path, findInfo)
      findInfo.scale = dis / pathDis
      return findInfo
    }

    // 定位落在房间内
    function locationAtRoom(pathInfo, config) {
      const pathDis = pathInfo.dis
      const findInfo = returnDefaultFindInfo()
      const path = pathInfo.path
      let findDis = 65535
      const point = config.point
      for (let i = path.length - 1; i > 0; i--) {
        const p1 = path[i]
        const p2 = path[i - 1]
        const res = pointCloseToLine(p1, p2, point)
        const colseDis = returnPointsDis(res.point, point)
        if (findDis < colseDis) {
          continue
        }
        const pointDis = returnPointsDis(p1, p2)
        const s = pointDis == 0 ? 0 : findDis / pointDis

        if (i != path.length - 1) {
          if (res.scale > 1.0 + s || res.scale < 0 - s) {
            // 超出范围
            continue
          }
        }

        if (res.result) {
          findPointAtPath(res.point, p2, config, findInfo, i)
          updateAngle(0, 1, p2.x - p1.x, p2.z - p1.z, findInfo)
        } else {
          const nextPoint = res.scale < 0 ? p1 : p2
          findPointAtPath(nextPoint, nextPoint, config, findInfo, i)
          updateAngle(0, 1, p2.x - p1.x, p2.z - p1.z, findInfo)
        }
        findDis = colseDis
      }
      if (findInfo.result == false) {
        return {
          result: false,
          isOut: true
        }
      }
      const dis = findOutDistance(path, findInfo)
      findInfo.scale = dis / pathDis
      return findInfo
    }


    /* --------------------------------------- */

    let findInfo = returnDefaultFindInfo()
    const pointIsInRoom = config.pointIsInRoom
    if (pointIsInRoom) {
      // 定位在房间内则将其纠正到路线上
      findInfo = locationAtRoom(pathInfo, config)
    } else {
      findInfo = locationNotAtRoom(pathInfo, config)
    }

    if (findInfo.result == false) {
      // 路线偏离 开始纠偏
      return {
        result: false,
        isOut: true
      }
    }

    const directionInfo = judgeDirectionAtPath(findInfo, pathInfo, config)
    let type = 'location'
    if (analaysis.jsonKeyIsExist(config, ['type'])) {
      type = config['type']
    }
    if (type == 'location') {
      findInfo.leadInfo['status'] = directionInfo.status
      findInfo.leadInfo['leadUrl'] = directionInfo.leadUrl
      findInfo.leadInfo['direction'] = directionInfo.msg
    }
    findInfo.leadInfo.msg = '--------'
    return findInfo
  }

  function updateAngle(x1, z1, x2, z2, findInfo) {
    const v1 = new THREE.Vector3(x1, 0, z1)
    const v2 = new THREE.Vector3(x2, 0, z2)
    const d = -1 * x1 * z2 + z1 * x2
    findInfo['angle'] = v1.angleTo(v2) * (d >= 0 ? 1 : -1) + Math.PI
  }

  function findPointAtPath(p, nextPoint, config, findInfo, index) {
    findInfo.result = true
    findInfo.leadInfo = shouldShowRealImage(p, nextPoint, config)
    findInfo.point = p
    findInfo.index = index
  }

  function returnEndTypeMsg(findInfo) {
    const leadInfo = findInfo.leadInfo
    if (leadInfo.status == leadStatus.end) {
      leadInfo.direction = '抵达目的地附近'
      leadInfo.isEnd = true
    } else if (leadInfo.status == leadStatus.louti) {
      leadInfo.direction = '到达楼梯'
    } else if (leadInfo.status == leadStatus.dianti) {
      leadInfo.direction = '到达电梯'
    } else if (leadInfo.status == leadStatus.futi) {
      leadInfo.direction = '到达扶梯'
    }
  }

  function endPointNotAtRoom(point, path, pathDis, distance, findInfo) {
    let dis = 0
    for (let i = path.length - 1; i > 0; i--) {
      const p1 = path[i]
      const p2 = path[i - 1]
      if (findInfo.index == i) {
        dis = dis + returnPointsDis(p1, findInfo.point)
        break
      } else {
        dis = dis + returnPointsDis(p1, p2)
      }
    }
    if (pathDis - dis <= distance) {
      const endPoint = path[0]
      switch (endPoint.type) {
        case 1:
          findInfo.leadInfo.status = leadStatus.louti
          break
        case 2:
          findInfo.leadInfo.status = leadStatus.dianti
          break
        case 3:
          findInfo.leadInfo.status = leadStatus.futi
          break
        case 4:
          findInfo.leadInfo.status = leadStatus.end
          findInfo.point.x = point.x
          findInfo.point.z = point.z
          findInfo.scale = 0
          break
      }
      // 抵达连接点或者终点
      return {
        result: true
      }
    }
    return {
      result: false
    }
  }

  function returnEndPointAtRoomPathDis(path, zeroId) {
    let dis = 0
    for (let i = 0; i < path.length; i++) {
      const p1 = path[i]
      if (i + 1 >= path.length) {
        continue
      }
      const p2 = path[i + 1]
      if (p1['inMapId'] == zeroId) {
        break
      }
      dis = dis + returnPointsDis(p1, p2)
      if (p2['inMapId'] == zeroId) {
        break
      }
    }
    return dis
  }

  function endPointAtRoom(point, path, pathDis, distance, findInfo) {
    let roomDis = 0
    if (analaysis.jsonKeyIsExist(findInfo, ['roomDis'])) {
      roomDis = findInfo['roomDis']
    }
    const finialDis = pathDis - roomDis
    return endPointNotAtRoom(point, path, finialDis, distance, findInfo)
  }


  // 是否抵达终点
  function locationIsArriveEndRoom(pathInfo, config, findInfo) {
    /* ---------------------------------- */

    /* ---------------------------------- */
    const path = pathInfo.path
    const isInRoom = config.isInRoom
    const zeroId = config.zeroId
    const distance = config.distance
    const point = config.point
    let roomDis = 0
    if (isInRoom) {
      roomDis = returnEndPointAtRoomPathDis(path, zeroId)
      findInfo['roomDis'] = roomDis
    } else {
      findInfo['roomDis'] = 0
    }
    const res = isInRoom ? endPointAtRoom(point, path, pathInfo.dis, distance, zeroId, findInfo) : endPointNotAtRoom(
      point, path, pathInfo.dis, distance, findInfo)
    if (res.result) {
      // 抵达终点
      returnEndTypeMsg(findInfo)
    }
    findRealImg(findInfo, path, config)
    return findInfo
  }

  function returnPointsDis(p1, p2) {
    return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
  }

  function findRealImg(findInfo, path, config) {
    const showDis = config.showDis // 显示实景图的距离
    let dis = 0
    for (let i = findInfo.index; i > 0; i--) {
      const p1 = path[i]
      const p2 = path[i - 1]
      if (i == findInfo.index) {
        dis = dis + returnPointsDis(findInfo.point, p2)
      } else {
        dis = dis + returnPointsDis(p1, p2)
      }
      if (dis > showDis) {
        break
      }
      if (analaysis.jsonKeyIsExist(p2, ['img'])) {
        const leadInfo = findInfo.leadInfo
        const img = p2['img']
        // 存在实景图
        leadInfo.reality = true
        leadInfo.url = img.url
        leadInfo.title = img.title
        break
      }
    }
  }

  function returnDefaultFindInfo() {
    const findInfo = {
      result: false,
      point: {},
      leadInfo: {},
      scale: 0,
      index: 0, // point所在path起始点的index
      orderIndex: 0, // 地图上的哪一条路径
      isOut: false,
      mapid: '',
      direction: ''
    }
    return findInfo
  }

  leadWay.defaultFindInfo = function () {
    return returnDefaultFindInfo()
  }

  // 记录导航时 导航情况
  let leadRemarkRecord = []

  const remarkTime = 5 * 1000 // 时间间隔

  const shouldRemark = true

  function updateRemarkRecord(config, findInfo) {
    function addNewRemark() {
      const createTime = new Date().getTime() // 获取时间
      const createEndTime = createTime + remarkTime
      const info = {
        startTime: createTime,
        endTime: createEndTime,
        scaleList: []
      }
      leadRemarkRecord.push(info)
    }

    function compareReamrk(time, findInfo) {
      if (leadRemarkRecord.length == 0) {
        return
      }
      const scale = findInfo['workScale']
      const remark = leadRemarkRecord[leadRemarkRecord.length - 1]
      if (time >= remark.startTime && time <= remark.endTime) {
        // 放入统计内
        remark.scaleList.push(scale)
      } else {
        // 新开一个
        addNewRemark()
        const newRemark = leadRemarkRecord[leadRemarkRecord.length - 1]
        newRemark.scaleList.push(scale)
      }
      if (leadRemarkRecord.length >= 3) {
        //  开始计算反转次数 即在导航中是否存在不按照路线行走的次数
        analaysisLeadRemark(findInfo)
      }
    }

    function analaysisLeadRemark(findInfo, config) {
      const oneRemark = leadRemarkRecord[0]
      const twoRematrk = leadRemarkRecord[1]
      let oneScale = 0
      let twoScale = 0
      for (let i = 0; i < oneRemark.scaleList.length; i++) {
        const scale = oneRemark.scaleList[i]
        oneScale = oneScale + scale
      }

      for (let i = 0; i < twoRematrk.scaleList.length; i++) {
        const scale = twoRematrk.scaleList[i]
        twoScale = twoScale + scale
      }

      const allDis = findInfo['allDis']
      const oneDis = oneScale * allDis
      const twoDis = twoScale * allDis
      const mapScale = analaysis.jsonKeyIsExist(config, ['scale']) ? config['scale'] : 10
      const count = (twoDis - oneDis) / mapScale
      const errorDis = 5 // 前后正负算滞留
      if (count >= errorDis * -1 && count <= errorDis) {
        // 没有移动
      } else if (count < 0) {
        // 后退 反转次数 + 1
        if (reverseBlock != null) {
          reverseBlock()
        }
      } else {
        // 前进
      }
      leadRemarkRecord.splice(0, 2) // 删除元素
    }

    if (shouldRemark == false || analaysis.jsonKeyIsExist(findInfo, ['workScale']) == false) {
      return
    }
    const time = new Date().getTime() // 获取时间
    if (leadRemarkRecord.length == 0) {
      addNewRemark()
    }
    compareReamrk(time, findInfo)
  }

  let reverseBlock = null // 反转回调

  leadWay.reverseAction = function (callBack) {
    reverseBlock = callBack
  }

  // 当重新规划路径的时候 强制反转次数加1
  leadWay.forceReverse = function () {
    if (reverseBlock != null) {
      reverseBlock()
    }
    leadRemarkRecord = []
  }

  leadWay.clearRemarkRecord = function () {
    leadRemarkRecord = []
  }

  return leadWay
}
