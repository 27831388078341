import {createLayerManager} from "./layerManager";
import {createIntersects} from "../../intersects/intersects";

export function createLayerDataManager() {
  let manager = new Object();

  let layerManager = createLayerManager() // 绘制区域

  let intersect = createIntersects();

  // 将点添加到scene上
  manager.addGroup = function (scene) {
    if (layerManager.parent != null) {
      scene.remove(layerManager);
    }
    scene.add(layerManager);
  }

  manager.deleteAll = function () {
    layerManager.deleteAll();
  }

  let cameraBlock = null;

  manager.cameraAction = function (callBack) {
    cameraBlock = callBack;
  }

  let mapInfoBlock = null;

  manager.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }


  manager.clickLayerEvent = function (event, config) {
    config['camera'] = cameraBlock();
    config['meshs'] = layerManager.returnLayerPlanes();
    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return finder;
    }
    return layerManager.clickIntersects(finder.intersects);
  }

  // 根据数据设置覆盖区域
  manager.getLayerData = function (layerData) {
    layerManager.getLayerData(layerData)
  }

  manager.showOrHiddenLayer = function (show) {
    layerManager.visible = show
  }

  // 显示覆盖区域
  manager.showLayerRect = function (temp = false) {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];
    let floorHeight = mapInfo['floorHeight'];
    layerManager.showLayer({
      opacity: 0.3,
      depthTest: false,
      color: '#ff0000',
      mapid: mapid,
      y: floorHeight,
      polygonOffsetFactor: -2,
      polygonOffsetUnits: -20,
      mode: 0,
      temp: temp
    })
  }

  manager.clearLayer = function (temp) {
    layerManager.clear(temp)
    manager.showLayerRect(true)
  }

  /* 清除地图的覆盖区域 */
  manager.clearAllRectOnMap = function () {
    layerManager.clearInfo()
    manager.showLayerRect(true)
  }

  manager.startDrawLayer = function (event, config) {
    config['camera'] = cameraBlock();
    config['meshs'] = layerManager.returnLayerPlanes();
    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return;
    }
    let title = config['title']
    let color = config['color']
    let mapInfo = mapInfoBlock();
    let floorHeight = mapInfo['floorHeight'];
    finder['name'] = title
    finder['color'] = color
    finder['y'] = floorHeight;
    layerManager.drawLayer(finder)
    manager.showLayerRect(true)
  }

  // 覆盖区域回退
  manager.backDrawLayer = function () {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];
    layerManager.back(mapid)
    manager.showLayerRect(true)
  }

  // 返回覆盖区域的点的集合
  manager.getLayersRectData = function () {
    return layerManager.exportInfo()
  }

  manager.getLayer = function () {
    return layerManager;
  }

  return manager;
}
