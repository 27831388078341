import * as THREE from 'three/build/three.min'
import * as TWEEN from 'tween/tween.js'
import { createRoomObject } from './floorWall'
import { createAnalaysisInfo } from '../../analaysis/dataAnalaysisInfo'
import { createCanvasStyle } from '../canvasStyle'
import { createIntersects } from '../../intersects/intersects'

let number = 1

let planePolygonOffsetFactor = 0 * number
let planePolygonOffsetUnits = 0 * number
let planeOpacity = 1

let oncePolygonOffsetFactor = -0.4 * number
let oncePolygonOffsetUnits = -1 * number
let onceOpacity = 1

let lvhuaPolygonOffsetFactor = -0.1 * number
let lvhuaPolygonOffsetUnits = -1 * number
let lvhuaOpacity = 1

let showOtherPolygonOffsetFactor = -0.5 * number
let showOtherPolygonOffsetUnits = -1 * number
let showOtherOpacity = 1

let zeroPolygonOffsetFactor = -0.5 * number
let zeroPolygonOffsetUnits = -2.5 * number
let zeroOpacity = 1

let wallPolygonOffsetFactor = -0.5 * number
let wallPolygonOffsetUnits = -5 * number
let wallOpacity = 1

let roomPolygonOffsetFactor = -0.4 * number
let roomPolygonOffsetUnits = -3 * number
let roomOpacity = 0.95

let zonePolygonOffsetFactor = -0.25 * number
let zonePolygonOffsetUnits = -1 * number
let zoneOpacity = 1.0

let extraPolygonOffsetFactor = -2 * number
let extraPolygonOffsetUnits = -5 * number
let extraOpacity = 1

let subExtraPolygonOffsetFactor = -2 * number
let subExtraPolygonOffsetUnits = -6 * number
let subExtraOpacity = 1

export function createFloorManager() {
  let group = new THREE.Group()

  let intersect = createIntersects()

  let makeRoom = createRoomObject()

  let style = createCanvasStyle()

  let analysis = createAnalaysisInfo() // 构造地图解析

  let borderInfo = { leftUp: { x: -100, y: -100 }, rightDown: { x: 100, y: 100 }, height: 15 }

  let zeroFloorInfo = null

  let mapJson = []

  let extraMapJson = []

  let oldColor = ''

  let oldRoomColorInfo = {}

  let groupInfo = {}

  let extraInfo = {}

  let extraBoxInfo = {}

  let extraMeshAllList = []

  let zoneBoxInfo = {}

  let zoneDataInfo = {}

  // 更改暖箱所在房间的颜色
  group.changeRoomColorByIncubator = function(config) {
    let dataList = config.dataList
    if (dataList.length == 0) {
      return
    }
    let roomId = {}
    for (let i = 0; i < dataList.length; i++) {
      let p = dataList[i]
      for (let j = 1; j < mapJson.length; j++) {
        let room = mapJson[j]
        if (analysis.isInPolygon([p.x, 0, p.z], room.areas)) {
          let id = room['areaid']
          roomId[id] = '1'
          break
        }
      }
    }
    changeRoomColor({ idInfo: roomId, color: config.color })
  }

  function resetRoomColor(config) {
    let idInfo = config.idInfo
    let reset = false;
    if (analysis.jsonKeyIsExist(config, ['reset'])) {
      reset = config['reset']
    }
    if (reset == false) {
      return idInfo
    }

    let shouldChange = {}
    let shouldKeep = {}
    let shouldDelete = {}
    for (let id in idInfo) {
      if (analysis.jsonKeyIsExist(oldRoomColorInfo, [id])) {
        shouldKeep[id] = '1'
      } else {
        shouldChange[id] = '1'
      }
    }

    for (let id in oldRoomColorInfo) {
      if (analysis.jsonKeyIsExist(idInfo, [id])) {
        shouldKeep[id] = '1'
      } else {
        shouldDelete[id] = '1'
      }
    }

    for (let id in shouldDelete) {
      if (analysis.jsonKeyIsExist(oldRoomColorInfo, [id]) == false) {
        continue
      }
      let room = oldRoomColorInfo[id]
      let roomInfo = room['dataInfo']
      let material = room.material
      let color = roomInfo['color']
      if (analysis.jsonKeyIsExist(material, ['length'])) {
        for (let j = 0; j < material.length; j++) {
          let m = material[j]
          m.color.set(color)
        }
      } else {
        room.material.color.set(color)
      }
      delete oldRoomColorInfo[id]
    }

    if (oldColor != '' && oldColor != config.color) {
      for (let id in shouldKeep) {
        if (analysis.jsonKeyIsExist(oldRoomColorInfo, [id]) == false) {
          continue
        }
        let room = oldRoomColorInfo[id]
        let material = room.material
        if (analysis.jsonKeyIsExist(material, ['length'])) {
          for (let j = 0; j < material.length; j++) {
            let m = material[j]
            m.color.set(config.color)
          }
        } else {
          room.material.color.set(config.color)
        }
        delete oldRoomColorInfo[id]
      }
    }

    return shouldChange
  }

  group.changeRoomColor = function(config) {
    changeRoomColor(config)
  }

  function findMeshAndChangeColor(g, config) {
    let idInfo = resetRoomColor(config)
    if (g.type == 'Mesh' && analysis.jsonKeyIsExist(idInfo, [g.uuid])) {
      oldRoomColorInfo[room.uuid] = room
      let material = room.material
      if (analysis.jsonKeyIsExist(material, ['length'])) {
        for (let j = 0; j < material.length; j++) {
          let m = material[j]
          m.color.set(config.color)
        }
      } else {
        g.material.color.set(config.color)
      }
      return
    }
    if (g.type != 'Group') {
      return
    }
    let children = g.children
    for (let i = 0; i < children.length; i++) {
      let room = children[i]
      if (room.type != 'Mesh' || analysis.jsonKeyIsExist(idInfo, [room.uuid]) == false) {
        continue
      }
      oldRoomColorInfo[room.uuid] = room
      let material = room.material
      if (analysis.jsonKeyIsExist(material, ['length'])) {
        for (let j = 0; j < material.length; j++) {
          let m = material[j]
          m.color.set(config.color)
        }
      } else {
        room.material.color.set(config.color)
      }
    }
  }

  // 更改地图房间颜色
  function changeRoomColor(config) {
    oldColor = config.color
    let children = group.children
    for (let i = 0; i < children.length; i++) {
      let room = children[i]
      findMeshAndChangeColor(room, config);
    }
  }

  // 只显示区域
  group.returnZoneGroup = function(zoneId) {
    if (analysis.jsonKeyIsExist(groupInfo, [zoneId])) {
      let g = groupInfo[zoneId]
      let newGroup = g.clone()
      if (analysis.jsonKeyIsExist(g, ['centerPoint'])) {
        newGroup['centerPoint'] = g['centerPoint']
      }
      if (analysis.jsonKeyIsExist(g, ['fence'])) {
        newGroup['fence'] = g['fence']
      }
      let roomList = []
      if (analysis.jsonKeyIsExist(zoneDataInfo, [zoneId])) {
        roomList = zoneDataInfo[zoneId]
      }
      newGroup['roomList'] = roomList

      return {
        result: true,
        group: newGroup
      }
    }
    return {
      result: false
    }
  }

  function setUpDefaultGroup() {
    let key = 'zero-1'
    if (analysis.jsonKeyIsExist(groupInfo, [key])) {
      return
    }
    let g = new THREE.Group()
    groupInfo[key] = g
    g.uuid = key
    group.add(g)
  }

  function getDefaultGroup() {
    let key = 'zero-1'
    if (analysis.jsonKeyIsExist(groupInfo, [key]) == false) {
      setUpDefaultGroup()
    }
    let g = groupInfo[key]
    return g
  }

  function addToDefaultGroup(mesh) {
    let g = getDefaultGroup()
    g.add(mesh)
  }

  function showRoomByPromise(config, layerHeight) {
    let promiseList = []
    for (let i = 1; i < mapJson.length; i++) {
      let data = mapJson[i]
      updateZoneInfo(data)
      let copyInfo = data
      let settting = {
        roomInfo: copyInfo,
        heightSpace: layerHeight,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + roomPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + roomPolygonOffsetUnits,
        moveY: true,
        draw: true,
        opacity: roomOpacity,
        type: 'room',
        depthTest: true,
        transparent: true,
        castShadow: true
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    return Promise.all(promiseList)
  }

  function showWallByPromise(config, layerHeight) {
    let promiseList = []
    let wall = config.wall
    for (let i = 0; i < wall.length; i++) {
      let data = wall[i]
      let copyInfo = data
      let settting = {
        castShadow: true,
        roomInfo: copyInfo,
        heightSpace: layerHeight,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + wallPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + wallPolygonOffsetUnits,
        moveY: true,
        draw: true,
        opacity: wallOpacity,
        depthTest: true,
        polygonOffset: true,
        transparent: true,
        type: 'wall'
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    return Promise.all(promiseList)
  }

  function showZoneByPromise(config, layerHeight) {
    let receiveShadow = false
    if (analysis.jsonKeyIsExist(config, ['receiveShadow'])) {
      receiveShadow = config['receiveShadow']
    }
    let promiseList = []
    let zone = config.zone
    for (let i = 0; i < zone.length; i++) {
      let json = zone[i]
      updateZoneInfo(json)
      json['height'] = layerHeight + 1
      let settting = {
        roomInfo: json,
        heightSpace: 0,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + zonePolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + zonePolygonOffsetUnits,
        moveY: false,
        opacity: zoneOpacity,
        draw: false,
        type: 'zone'
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    return Promise.all(promiseList)
  }

  function updateExtraInfo(config) {
    extraMapJson = []
    let subExtra = []
    let info = config.extraInfo.list
    if (analysis.jsonKeyIsExist(info, ['length'])) {
      extraMapJson = extraMapJson.concat(info)
    }

    for (let i = 0; i < config.extraInfo.list.length; i++) {
      let r = config.extraInfo.list[i]
      if (analysis.jsonKeyIsExist(r, ['subs'])) {
        subExtra = subExtra.concat(findExtraSubs(r))
      }
    }
    extraMapJson = extraMapJson.concat(subExtra)
  }

  function showPlaneByPromise(config, layerHeight) {
    let promiseList = []

    let lvhua = config.lvhua
    for (let i = 0; i < lvhua.length; i++) {
      let json = lvhua[i]
      updateZoneInfo(json)
      if (analysis.jsonKeyIsExist(json, ['hidden'])) {
        continue
      }
      let copyInfo = JSON.parse(JSON.stringify(json))
      copyInfo['height'] = 1
      let setting = {
        roomInfo: copyInfo,
        heightSpace: layerHeight * -1,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + lvhuaPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + lvhuaPolygonOffsetUnits,
        moveY: true,
        opacity: 1.0,
        draw: false,
        depthTest: true
      }
      let promise = makeRoom.createWallByPromise(setting)
      promiseList.push(promise)
    }

    // once
    let show = config.show
    for (let i = 0; i < show.length; i++) {
      let json = show[i]
      updateZoneInfo(json)
      if (analysis.jsonKeyIsExist(json, ['hidden'])) {
        continue
      }
      let copyInfo = JSON.parse(JSON.stringify(json))
      copyInfo['height'] = 1
      let setting = {
        roomInfo: copyInfo,
        heightSpace: i == 0 ? -layerHeight : layerHeight,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + oncePolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + oncePolygonOffsetUnits,
        moveY: true,
        opacity: 1.0,
        draw: false,
        depthTest: true
      }
      let promise = makeRoom.createWallByPromise(setting)
      promiseList.push(promise)
    }
    return Promise.all(promiseList)
  }

  function showZreoByPromise(config, layerHeight) {

    let zeroId = ''
    let promiseList = []
    let background = []
    if (analysis.jsonKeyIsExist(config, ['background'])) {
      background = config['background']
    }

    let dataList = mapJson.length > 0 ? [mapJson[0]].concat(background) : background

    for (let i = 0; i < dataList.length; i++) {
      let data = dataList[i]
      let copyInfo = data
      copyInfo['height'] = layerHeight
      let moveY = i == 0 ? false : true
      let heightSpace = i == 0 ? 0 : -1
      let type = i == 0 ? 'zero' : 'background'
      let drawTop = i == 0 ? true : false
      let settting = {
        roomInfo: copyInfo,
        heightSpace: heightSpace,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + zeroPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + zeroPolygonOffsetUnits,
        moveY: moveY,
        opacity: zeroOpacity,
        draw: false,
        drawTop: drawTop,
        drawList: config.showOther.concat(config.zone),
        cutShape: false,
        cutList: config.showOther.concat(config.zone),
        type: type
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    return {
      zeroId: zeroId,
      promiseList: Promise.all(promiseList)
    }
  }

  function showOtherByPromise(config, layerHeight) {
    let promiseList = []
    let list = config.showOther
    for (let i = 0; i < list.length; i++) {
      let data = list[i]
      let copyInfo = JSON.parse(JSON.stringify(data))
      copyInfo['height'] = 0.5
      let settting = {
        roomInfo: copyInfo,
        heightSpace: layerHeight,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + showOtherPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + showOtherPolygonOffsetUnits,
        moveY: true,
        opacity: 1.0,
        draw: false,
        type: 'showOther'
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    return Promise.all(promiseList)
  }

  function showMainExtraByPromise(config, layerHeight) {
    let promiseList = []
    let subExtra = []
    extraMapJson = []
    let info = config.extraInfo.list
    if (analysis.jsonKeyIsExist(info, ['length'])) {
      extraMapJson = extraMapJson.concat(info)
    }

    for (let i = 0; i < info.length; i++) {
      let r = info[i]
      r['draw'] = true
      r['opacity'] = 0.9
      let settting = {
        roomInfo: r,
        heightSpace: layerHeight,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + extraPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + extraPolygonOffsetUnits,
        moveY: true,
        type: 'mainExtra',
        transparent: true
      }
      if (analysis.jsonKeyIsExist(r, ['subs'])) {
        subExtra = subExtra.concat(findExtraSubs(r))
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    extraMapJson = extraMapJson.concat(subExtra)
    return {
      promiseList: Promise.all(promiseList),
      subExtra: subExtra
    }
  }

  function showSubExtraByPromise(config, subExtra, layerHeight) {
    let promiseList = []
    for (let i = 0; i < subExtra.length; i++) {
      let r = subExtra[i]
      let judgeY = 0
      if (analysis.jsonKeyIsExist(config, ['judgeY']) == false) {
        judgeY = r['judgeY']
      }
      r['draw'] = true
      r['opacity'] = 0.9
      let settting = {
        roomInfo: r,
        heightSpace: layerHeight,
        mapid: config.mapid,
        polygonOffsetFactor: config.polygonOffsetFactor + extraPolygonOffsetFactor,
        polygonOffsetUnits: config.polygonOffsetUnits + extraPolygonOffsetUnits,
        moveY: true,
        type: 'subExtra',
        transparent: true
      }
      let promise = makeRoom.createWallByPromise(settting)
      promiseList.push(promise)
    }
    return Promise.all(promiseList)
  }

  let updateObjectInfo = {}

  group.updateObjectByPromise = function(config, callBack) {

    function showWallByPromise(config) {
      let promiseList = []
      let wall = config.wall
      for (let i = 0; i < wall.length; i++) {
        let data = wall[i]
        let copyInfo = data
        let settting = {
          roomInfo: copyInfo,
          heightSpace: config.layerHeight,
          mapid: config.mapid,
          polygonOffsetFactor: config.polygonOffsetFactor + wallPolygonOffsetFactor,
          polygonOffsetUnits: config.polygonOffsetUnits + wallPolygonOffsetUnits,
          moveY: true,
          draw: true,
          opacity: wallOpacity,
          depthTest: true,
          transparent: true,
          polygonOffset: true,
          type: 'wall',
          castShadow: true
        }
        let promise = makeRoom.createWallByPromise(settting)
        promiseList.push(promise)
      }
      return Promise.all(promiseList)
    }

    function showRoomByPromise(config) {
      let promiseList = []
      for (let i = 0; i < config.room.length; i++) {
        let data = config.room[i]
        updateZoneInfo(data)
        let copyInfo = data
        let settting = {
          roomInfo: copyInfo,
          heightSpace: config.layerHeight,
          mapid: config.mapid,
          polygonOffsetFactor: config.polygonOffsetFactor + roomPolygonOffsetFactor,
          polygonOffsetUnits: config.polygonOffsetUnits + roomPolygonOffsetUnits,
          moveY: true,
          draw: true,
          opacity: roomOpacity,
          type: 'room',
          depthTest: true,
          transparent: true,
          castShadow: true
        }
        let promise = makeRoom.createWallByPromise(settting)
        promiseList.push(promise)
      }
      return Promise.all(promiseList)
    }

    function addRoom(meshList, config) {
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        if (analysis.jsonKeyIsExist(config, ['group'])) {
          let g = config['group']
          g.add(mesh)
          continue
        }

        let dataInfo = mesh['dataInfo']
        if (analysis.jsonKeyIsExist(dataInfo, ['inZone'])) {
          let id = dataInfo['inZone']
          if (analysis.jsonKeyIsExist(groupInfo, [id])) {
            let zoneGroup = groupInfo[id]
            zoneGroup.add(mesh)
          } else {
            addToDefaultGroup(mesh)
          }
        } else {
          addToDefaultGroup(mesh)
        }
      }
    }

    function updateMapData(index, resList, indexList, config, callBack) {
      if (index >= indexList.length || index >= resList.length) {
        callBack()
        return
      }
      let res = resList[index]
      addRoom(res, config)
      let newIndex = index + 1
      updateMapData(newIndex, resList, indexList, config, callBack)
    }

    let mapid = config['mapid']
    let lookAtZone = config['lookAtZone']
    let mapInfo = {}
    if (analysis.jsonKeyIsExist(updateObjectInfo, [mapid])) {
      mapInfo = updateObjectInfo[mapid]
    } else {
      updateObjectInfo[mapid] = mapInfo
    }

    let createRoom = false

    let createWall = false

    let makeInfo = {}

    let promiseList = []

    let updateStatus = true // 是否更新状态

    if (analysis.jsonKeyIsExist(config, ['updateStatus'])) {
      updateStatus = config['updateStatus']
    }

    let updateList = []

    for (const zonekey in lookAtZone) {
      if (analysis.jsonKeyIsExist(mapInfo, [zonekey])) {
        makeInfo = mapInfo[zonekey]
        if (analysis.jsonKeyIsExist(makeInfo, ['createRoom']) && updateStatus) {
          createRoom = true
        }
        if (analysis.jsonKeyIsExist(makeInfo, ['createWall']) && updateStatus) {
          createWall = true
        }
      } else {
        mapInfo[zonekey] = makeInfo
      }
    }

    if (updateStatus) {
      if (createWall && createRoom) {
        return
      }

      if (createRoom == false) {
        let room = config['room']
        mapJson = mapJson.concat(room)
        promiseList.push(showRoomByPromise(config))
        updateList.push('createRoom')
        createRoom = true
        makeInfo['createRoom'] = ''
      }
      if (createWall == false) {
        promiseList.push(showWallByPromise(config))
        updateList.push('createWall')
        createWall = true
        makeInfo['createWall'] = ''
      }
    } else {
      promiseList.push(showRoomByPromise(config))
      promiseList.push(showWallByPromise(config))
      updateList.push('createRoom')
      updateList.push('createWall')
    }

    Promise.all(promiseList).then(resList => {
      updateMapData(0, resList, updateList, config, () => {
        callBack({
          mapid: mapid,
          lookAtZone: lookAtZone,
          createRoom: createRoom,
          createWall: createWall,
          types: updateList
        })
      })
    })
  }

  group.showObjectByPromise = function(config, orderList, callBack) {
    if (typeof (config.dataInfo) == 'undefined' || config.dataInfo == null) {
      callBack()
      return
    }

    let receiveShadow = false
    if (analysis.jsonKeyIsExist(config, ['receiveShadow'])) {
      receiveShadow = config['receiveShadow']
    }

    mapJson = config.dataInfo
    let layerHeight = this.returnFloorInfoHeight()
    let zeroId = ''
    let indexList = []
    let promiseList = []

    /* ------------------------- */
    function addShowOther(meshList, zeroId) {
      if (analysis.jsonKeyIsExist(groupInfo, [zeroId]) == false) {
        return
      }
      let g = groupInfo[zeroId]
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }

        let mesh = meshRes['mesh']
        g.add(mesh)
      }
    }

    function addExtra(meshList) {
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        let key = ''
        let dataInfo = mesh['dataInfo']
        if (analysis.jsonKeyIsExist(dataInfo, ['inZone'])) {
          key = dataInfo['inZone']
        } else {
          key = dataInfo['areaid']
        }
        let eGroup = null
        if (analysis.jsonKeyIsExist(extraInfo, [key])) {
          eGroup = extraInfo[key]
        } else {
          eGroup = createAnimationGroup()
          group.add(eGroup)
          eGroup['tagname'] = 'extra'
          extraInfo[key] = eGroup
        }
        eGroup.add(mesh)
        let boundingBox = mesh.geometry.boundingBox
        let fence = {
          leftUp: {
            x: boundingBox.min.x,
            y: 0,
            z: boundingBox.min.y
          },
          rightUp: {
            x: boundingBox.max.x,
            y: 0,
            z: boundingBox.min.y
          },
          rightDown: {
            x: boundingBox.max.x,
            y: 0,
            z: boundingBox.max.y
          },
          leftDown: {
            x: boundingBox.min.x,
            y: 0,
            z: boundingBox.max.y
          }
        }
        if (analysis.jsonKeyIsExist(eGroup, ['fence'])) {
          let oldFence = eGroup['fence']
          oldFence.leftUp.x = analysis.findMin([oldFence.leftUp.x, fence.leftUp.x])
          oldFence.leftUp.z = analysis.findMin([oldFence.leftUp.z, fence.leftUp.z])
          oldFence.rightDown.x = analysis.findMax([oldFence.rightDown.x, fence.rightDown.x])
          oldFence.rightDown.z = analysis.findMax([oldFence.rightDown.z, fence.rightDown.z])
          oldFence.leftDown.x = oldFence.leftUp.x
          oldFence.leftDown.z = oldFence.rightDown.z
          oldFence.rightUp.x = oldFence.rightDown.x
          oldFence.rightUp.z = oldFence.leftUp.z
          eGroup['maxY'] = analysis.findMax([eGroup['maxY'], boundingBox.max.z])
          eGroup['minY'] = analysis.findMax([eGroup['minY'], boundingBox.min.z])
        } else {
          eGroup['fence'] = fence
          eGroup['maxY'] = boundingBox.max.z
          eGroup['minY'] = boundingBox.min.z
        }
      }
      let zeroid = zeroFloorInfo['areaid']
      for (let key in extraInfo) {
        if (key == zeroid) {
          continue
        }
        let g = extraInfo[key]
        let height = g['maxY'] - g['minY']
        let fence = g['fence']
        let box = new THREE.Box3(new THREE.Vector3(fence.leftUp.x, 0, fence.leftUp.z), new THREE.Vector3(fence.rightDown.x, height, fence.rightDown.z))
        box['realHeight'] = height
        extraBoxInfo[key] = box
      }
    }

    function addSubExtraInfo(meshList) {
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        let dataInfo = mesh['dataInfo']
        let findKey = ''
        if (analysis.jsonKeyIsExist(dataInfo, ['inZone'])) {
          findKey = dataInfo['inZone']
        } else {
          findKey = dataInfo['isIn']
        }
        let eGroup = null
        if (analysis.jsonKeyIsExist(extraInfo, [findKey])) {
          eGroup = extraInfo[findKey]
        } else {
          eGroup = getDefaultGroup()
        }
        if (eGroup == null) {
          continue
        }

        let judgeY = 0
        if (analysis.jsonKeyIsExist(config, ['judgeY']) == false) {
          judgeY = dataInfo['judgeY']
        }
        mesh.position.y = mesh.position.y + judgeY
        eGroup.add(mesh)
      }
    }

    function addRoom(meshList) {
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        let dataInfo = mesh['dataInfo']
        if (analysis.jsonKeyIsExist(dataInfo, ['inZone'])) {
          let id = dataInfo['inZone']
          if (analysis.jsonKeyIsExist(groupInfo, [id])) {
            let zoneGroup = groupInfo[id]
            zoneGroup.add(mesh)
          } else {
            addToDefaultGroup(mesh)
          }
        } else {
          addToDefaultGroup(mesh)
        }
      }
    }

    function addZone(meshList) {

      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        let zoneGroup = new THREE.Group()
        zoneGroup.add(mesh)
        if (receiveShadow) {
          let dataInfo = mesh['dataInfo']
          let p = makeRoom.createMapPlane({
            roomInfo: dataInfo,
            heightSpace: dataInfo['height'],
            mapid: config.mapid,
            polygonOffsetFactor: config.polygonOffsetFactor + zonePolygonOffsetFactor,
            polygonOffsetUnits: config.polygonOffsetUnits + zonePolygonOffsetUnits,
            moveY: true,
            opacity: 0.1,
            draw: false,
            depthTest: true,
            materialName: 'lambertMaterial',
            receiveShadow: receiveShadow
          })
          p.name = 'shadowPlane'
          zoneGroup.add(p)
          p.visible = false
        }
        group.add(zoneGroup)
        let dataInfo = mesh['dataInfo']
        let id = dataInfo['areaid']
        groupInfo[id] = zoneGroup
        let polygon = analysis.getPolygonAreaCenter(dataInfo['areas'])
        zoneGroup['centerPoint'] = {
          x: polygon[1],
          z: polygon[0]
        }
        let boundingBox = mesh.geometry.boundingBox
        zoneGroup['fence'] = {
          leftUp: {
            x: boundingBox.min.x,
            y: 0,
            z: boundingBox.min.y
          },
          rightDown: {
            x: boundingBox.max.x,
            y: 0,
            z: boundingBox.max.y
          }
        }

        let height = 30
        let box = new THREE.Box3(new THREE.Vector3(boundingBox.min.x, 0, boundingBox.min.y), new THREE.Vector3(boundingBox.max.x, 10, boundingBox.max.y))
        box['realHeight'] = height
        zoneBoxInfo[id] = box
      }
    }

    function addZero(meshList) {
      let floorGroup = new THREE.Group()
      group.add(floorGroup)
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        floorGroup.add(mesh)
        if (i != 0) {
          continue
        }
        let dataInfo = mesh['dataInfo']
        let id = dataInfo['areaid']
        zeroId = id
        groupInfo[id] = floorGroup
        floorGroup.name = 'zero'
        let polygon = analysis.getPolygonAreaCenter(dataInfo['areas'])
        floorGroup['centerPoint'] = {
          x: polygon[1],
          z: polygon[0]
        }
        let boundingBox = mesh.geometry.boundingBox
        floorGroup['fence'] = {
          leftUp: {
            x: boundingBox.min.x,
            y: 0,
            z: boundingBox.min.y
          },
          rightDown: {
            x: boundingBox.max.x,
            y: 0,
            z: boundingBox.max.y
          }
        }
      }
    }

    function addPlane(meshList) {
      let planeGroup = null
      if (analysis.jsonKeyIsExist(groupInfo, ['plane'])) {
        planeGroup = groupInfo['plane']
      } else {
        planeGroup = new THREE.Group()
        groupInfo['plane'] = planeGroup
        group.add(planeGroup)
      }
      for (let i = 0; i < meshList.length; i++) {
        let meshRes = meshList[i]
        if (meshRes['result'] == false) {
          continue
        }
        let mesh = meshRes['mesh']
        planeGroup.add(mesh)
      }
    }

    function updateMapData(index, resList, indexList, callBack) {
      if (index >= indexList.length || index >= resList.length) {
        callBack()
        return
      }
      let promise = () => {
        return new Promise(resolve => {
          let res = resList[index]
          let resInfo = indexList[index]
          let name = resInfo['name']
          if (name == 'plane') {
            addPlane(res)
          } else if (name == 'zero') {
            addZero(res)
          } else if (name == 'zone') {
            addZone(res)
          } else if (name == 'room') {
            addRoom(res)
          } else if (name == 'wall') {
            addRoom(res)
          } else if (name == 'extra') {
            addExtra(res)
          } else if (name == 'subExtra') {
            addSubExtraInfo(res)
          } else if (name == 'showOther') {
            addShowOther(res, zeroId)
          }
          resolve({ index: index + 1 })
        })
      }
      promise().then(res => {
        updateMapData(res.index, resList, indexList, callBack)
      })
    }

    function readMapData(index, orderList, indexList, callBack) {
      if (index >= orderList.length) {
        callBack()
        return
      }

      let promise = () => {
        return new Promise(resolve => {
          let order = orderList[index]
          indexList.push({
            name: order,
            index: indexList.length
          })
          if (order == 'plane') {
            promiseList.push(showPlaneByPromise(config, layerHeight))
          } else if (order == 'zero') {
            let res = showZreoByPromise(config, layerHeight)
            zeroId = res.zeroId
            promiseList.push(res.promiseList)
          } else if (order == 'zone') {
            promiseList.push(showZoneByPromise(config, layerHeight))
          } else if (order == 'room') {
            promiseList.push(showRoomByPromise(config, layerHeight))
          } else if (order == 'wall') {
            promiseList.push(showWallByPromise(config, layerHeight))
          } else if (order == 'extra') {
            let extraRes = showMainExtraByPromise(config, layerHeight)
            promiseList.push(extraRes.promiseList)
            indexList.push({
              name: 'subExtra',
              index: indexList.length
            })
            promiseList.push(showSubExtraByPromise(config, extraRes.subExtra, layerHeight))
          } else if (order == 'showOther') {
            promiseList.push(showOtherByPromise(config, layerHeight))
          }
          resolve({ index: index + 1 })
        })
      }

      promise().then(res => {
        readMapData(res.index, orderList, indexList, callBack)
      })
    }

    /* ------------------------- */

    let readFinishCallBack = () => {
      Promise.all(promiseList).then(resList => {
        updateMapData(0, resList, indexList, () => {
          if (analysis.jsonKeyIsExist(config, ['updateExtra'])) {
            updateExtraInfo(config)
          }
          if (analysis.jsonKeyIsExist(config, ['updateBorder'])) {
            borderInfo = makeRoom.returnBorderInfo()
          }
          callBack()
        })
      })
    }

    readMapData(0, orderList, indexList, readFinishCallBack)
  }

  group.setExtraMeshOpacity = function(mesh, config) {
    function setMeshOpacity(mesh, opacity) {
      let material = mesh.material
      if (Array.isArray(material)) {
        for (let j = 0; j < material.length; j++) {
          let m = material[j]
          m.opacity = opacity
        }
      } else {
        material.opacity = opacity
      }
    }

    if (mesh == null) {
      for (let i = 0; i < extraMeshAllList.length; i++) {
        let extra = extraMeshAllList[i]
        setMeshOpacity(extra, 1)
      }
    }

    if (extraMeshAllList.length == 0 || analysis.jsonKeyIsExist(mesh, ['dataInfo']) == false) {
      return
    }
    let dataInfo = mesh['dataInfo']
    if (analysis.jsonKeyIsExist(dataInfo, ['inZone']) == false) {
      return
    }
    for (let i = 0; i < extraMeshAllList.length; i++) {
      let extra = extraMeshAllList[i]
      if (analysis.jsonKeyIsExist(extra, ['dataInfo']) == false) {
        continue
      }
      let extraData = extra['dataInfo']
      if (analysis.jsonKeyIsExist(extraData, ['inZone']) == false) {
        continue
      }
      setMeshOpacity(extra, extraData['inZone'] == dataInfo['inZone'] ? 0.3 : 1)
    }
  }

  group.getExtraList = function(config) {
    if (extraMeshAllList.length > 0) {
      return extraMeshAllList
    }
    let zeroId = ''
    if (analysis.jsonKeyIsExist(zeroFloorInfo, ['areaid'])) {
      zeroId = zeroFloorInfo['areaid']
    }
    for (const key in extraInfo) {
      if (key == zeroId) {
        continue
      }
      let g = extraInfo[key]
      let children = g.children
      for (let i = 0; i < children.length; i++) {
        let mesh = children[i]
        extraMeshAllList.push(mesh)
      }
    }
    return extraMeshAllList
  }

  group.clickChartLet = function(intersectConfig, event) {
    let chartList = []
    let meshs = returnExtra_GroupMesh()
    let list = mapJson.concat(extraMapJson)
    for (let i = 0; i < list.length; i++) {
      let info = list[i]
      if (analysis.jsonKeyIsExist(info, ['chartLet']) == false) {
        continue
      }
      let id = info['areaid']
      for (let j = 0; j < meshs.length; j++) {
        let mesh = meshs[j]
        let clockwise = true
        if (analysis.jsonKeyIsExist(mesh, ['clockwise'])) {
          clockwise = mesh['clockwise']
        }
        if (mesh.uuid == id) {
          chartList.push(mesh)
          break
        }
      }
    }

    let result = {
      result: false,
      dataInfo: null
    }
    intersectConfig['meshs'] = chartList
    let finder = intersect.getIntersects(event, intersectConfig)
    if (finder.result == false) {
      return result
    }
    let intersects = finder.intersects
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object
      if (analysis.jsonKeyIsExist(object, ['dataInfo'])) {
        result.dataInfo = object['dataInfo']
      }
      result.result = true
      break
    }
    return result
  }

  group.clickExtraEvent = function(config, event) {
    function returnMeshOpacity(mesh) {
      let material = mesh.material
      let opacity = 1.0
      if (Array.isArray(material)) {
        for (let j = 0; j < material.length; j++) {
          let m = material[j]
          opacity = m.opacity
        }
      } else {
        opacity = material.opacity
      }
      return opacity
    }

    let meshList = []
    let zeroId = ''
    if (analysis.jsonKeyIsExist(zeroFloorInfo, ['areaid'])) {
      zeroId = zeroFloorInfo['areaid']
    }
    for (const key in extraInfo) {
      let g = extraInfo[key]
      if (g.parent == null || key == zeroId) {
        continue
      }
      let children = g.children
      for (let i = 0; i < children.length; i++) {
        let mesh = children[i]
        let opacity = returnMeshOpacity(mesh)
        if (opacity == 0) {
          continue
        }
        mesh['fence'] = g['fence']
        meshList.push(mesh)
      }
    }

    let result = {
      result: false,
      dataInfo: null
    }
    config['meshs'] = meshList
    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return result
    }

    let intersects = finder.intersects
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object

      result['fence'] = JSON.parse(JSON.stringify(object['fence']))
      let leftUp = result['fence'].leftUp
      let rightDown = result['fence'].rightDown
      let width = (rightDown.x - leftUp.x) * 0.3
      let height = (rightDown.z - leftUp.z) * 0.3
      result['fence']['w'] = width
      result['fence']['h'] = height
      let x = (leftUp.x + rightDown.x) / 2.0
      let z = (leftUp.z + rightDown.z) / 2.0
      result['center'] = {
        x: x,
        z: z,
        y: 2
      }
      if (analysis.jsonKeyIsExist(object, ['dataInfo'])) {
        result.dataInfo = object['dataInfo']
      }
      result.result = true
      break
    }
    return result
  }

  // 点击地图房间或者外壳
  group.clickMapRoomEvent = function(config, event) {
    let room = config.room
    let mapid = config.mapid
    let index = 0
    if (analysis.jsonKeyIsExist(config, ['zoneInfo'])) {
      index = config['zoneInfo'] ? 0 : 1
    }

    let titleLimit = true
    if (analysis.jsonKeyIsExist(config, ['titleLimit'])) {
      titleLimit = config['titleLimit']
    }

    let meshs = group.returnGroupMesh(index)
    config['meshs'] = meshs

    let organizationName = config.organizationName
    let result = {
      result: false,
      isSameRoom: false,
      data: null
    }

    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return result
    }

    let intersects = finder.intersects

    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object

      if (analysis.jsonKeyIsExist(object, ['dataInfo']) == false) {
        continue
      }
      let findRoom = object['dataInfo']
      if (analysis.jsonKeyIsExist(findRoom, ['once'])) {
        continue
      }
      if (analysis.jsonKeyIsExist(findRoom, ['title']) == false) {
        continue
      }
      let title = findRoom['title']
      if (title == '' && titleLimit) {
        continue
      }
      let titleLV = findRoom['titleLV']
      if (parseInt(titleLV) <= 2) {
        continue
      }
      let areas = findRoom['areas']
      let roomid = findRoom['areaid']

      if (analysis.jsonKeyIsExist(room, ['roomid']) == true && room['roomid'] == roomid && analysis.jsonKeyIsExist(room, ['mapid']) && room['mapid'] == mapid) {
        result.isSameRoom = true
        break
      }
      result.result = true
      let polygon = analysis.getPolygonAreaCenter(areas)
      findRoom['roomid'] = roomid
      findRoom['mapid'] = mapid
      findRoom['organizationName'] = organizationName
      findRoom['roomName'] = title
      findRoom['roomAreaId'] = mapid + roomid
      findRoom['x'] = polygon[1]
      findRoom['y'] = findRoom['height']
      findRoom['z'] = polygon[0]
      findRoom['type'] = 'clickRoom'
      findRoom['centerY'] = 0
      result.isSameRoom = false
      result.data = findRoom
      break
    }
    return result
  }

  group.showOrHiddenZone = function(showInfo) {
    let zeroId = ''
    if (zeroFloorInfo != null) {
      zeroId = 'zero'
    } else {
      zeroId = zeroFloorInfo['areaid']
    }
    for (let key in showInfo) {
      if (analysis.jsonKeyIsExist(groupInfo, [key]) == false) {
        continue
      }
      let g = groupInfo[key]
      if (key == zeroId) {

      } else {
        let visible = showInfo[key].visible
        if (g.visible != visible) {
          g.visible = visible
        }
      }
    }
  }

  function find(subs) {
    let l = []
    for (let i = 0; i < subs.length; i++) {
      let roomInfo = subs[i]
      l = l.concat(findExtraSubs(roomInfo))
    }
    return l
  }

  function findExtraSubs(roomInfo) {
    let subs = []
    if (analysis.jsonKeyIsExist(roomInfo, ['subs'])) {
      subs = roomInfo['subs']
      subs = subs.concat(find(roomInfo['subs']))
    }
    return subs
  }

  function returnExtra_GroupMesh() {
    let meshs = []
    for (let i = 0; i < group.children.length; i++) {
      let children = group.children[i]
      if (children.type == 'Mesh') {
        meshs.push(children)
      } else if (children.type == 'Group') {
        meshs = meshs.concat(children.children)
      }
    }
    return meshs
  }

  group.getFaceInfo = function(config) {
    let infoList = []
    let list = mapJson.concat(extraMapJson)
    for (let i = 0; i < list.length; i++) {
      let roomInfo = list[i]
      if (analysis.jsonKeyIsExist(roomInfo, ['chartLet']) && analysis.jsonKeyIsExist(roomInfo, ['face'])) {
        let face = roomInfo['face']
        face.id = roomInfo['areaid']
        infoList.push(face)
      }
    }
    return infoList
  }

  function updateZoneInfo(roomInfo) {
    if (roomInfo['title'] == '') {
      return
    }
    if (analysis.jsonKeyIsExist(roomInfo, ['inZone']) == false) {
      return
    }
    let zoneId = roomInfo['inZone']
    if (zoneId == '') {
      return
    }
    let info = []
    if (analysis.jsonKeyIsExist(zoneDataInfo, [zoneId]) == false) {
      zoneDataInfo[zoneId] = info
    } else {
      info = zoneDataInfo[zoneId]
    }
    info.push(roomInfo)
  }

  // 更新楼层贴图
  group.updateFaceColor = function(config, roomInfo) {
    let meshs = returnExtra_GroupMesh()
    let list = config.list
    for (let i = 0; i < meshs.length; i++) {
      let color = '#ffffff'
      let visible = false
      if (analysis.jsonKeyIsExist(config, ['showAll'])) {
        visible = config['showAll']
      }
      let mesh = meshs[i]
      if (mesh.uuid != roomInfo['areaid']) {
        continue
      }
      for (let j = 0; j < list.length; j++) {
        let info = list[j]
        let id = info.id
        if (mesh.uuid == id) {
          color = '#ff0000'
          visible = true
          break
        }
      }
      let m = mesh.material
      let top = m[0]
      let front = m[1]
      // top.color.set(color);
      // front.color.set(color);
      mesh.visible = visible
    }
  }

  // 更新楼层贴图
  group.updateFaceImage = function(config) {
    function updateFloorFrontImg(face) {
      let list = mapJson.concat(extraMapJson)
      for (let i = 0; i < list.length; i++) {
        let roomInfo = list[i]
        if (roomInfo.areaid == face.id) {
          roomInfo['face'] = face
          break
        }
      }
    }

    let meshs = returnExtra_GroupMesh()
    let list = config.list
    let save = false
    if (analysis.jsonKeyIsExist(config, ['save'])) {
      save = config['save']
    }
    for (let i = 0; i < list.length; i++) {
      let info = list[i]
      let id = info.id
      for (let j = 0; j < meshs.length; j++) {
        let mesh = meshs[j]
        let clockwise = true
        if (analysis.jsonKeyIsExist(mesh, ['clockwise'])) {
          clockwise = mesh['clockwise']
        }
        if (mesh.uuid == id) {
          let m = mesh.material
          let top = m[0]
          let front = m[1]
          makeRoom.updateRoomTexttrue(top, info.topFace, clockwise)
          makeRoom.updateRoomTexttrue(front, info.frontFace, clockwise)
          if (save == true) {
            updateFloorFrontImg(info)
          }
          break
        }
      }
    }
  }

  group.getMapBorder = function() {
    return borderInfo
  }

  group.returnFloorInfo = function() {
    if (zeroFloorInfo == null) {
      if (mapJson.length > 0) {
        for (let i = 0; i < mapJson.length; i++) {
          let json = mapJson[i]
          if (analysis.jsonKeyIsExist(json, ['zero'])) {
            zeroFloorInfo = json
            return zeroFloorInfo
          }
        }
        zeroFloorInfo = mapJson[0]
      } else {
        zeroFloorInfo = { areas: [], areaid: '', height: 0 }
      }
    }
    return zeroFloorInfo
  }

  group.returnFloorInfoHeight = function() {
    if (zeroFloorInfo == null) {
      let info = this.returnFloorInfo()
      return info.height
    }
    return zeroFloorInfo.height
  }

  group.deleteAll = function() {
    function groupDeleteAll(deleteGroup) {
      for (const key in deleteGroup) {
        let g = deleteGroup[key]
        if (g.parent != null) {
          g.parent.remove(g)
        }
        let children = g.children
        for (let i = 0; i < children.length; i++) {
          let c = children[i]
          if (c.parent != null) {
            c.parent.remove(c)
          }
          let material = c.material
          if (analysis.jsonKeyIsExist(material, ['length'])) {
            c.material[0].dispose()
            c.material[1].dispose()
          } else {
            c.material.dispose()
          }
          c.geometry.dispose()
        }
      }
    }

    extraMeshAllList = []
    zoneDataInfo = {}
    groupDeleteAll(groupInfo)
    groupInfo = {}
    setUpDefaultGroup()
    groupDeleteAll(extraInfo)
    extraInfo = {}
    borderInfo = { leftUp: { x: -100, y: -100 }, rightDown: { x: 100, y: 100 }, height: 15 }
    zoneBoxInfo = {}
    zoneDataInfo = {}

    zeroFloorInfo = null

    mapJson = []

    extraMapJson = []

    oldRoomColorInfo = {}

    extraBoxInfo = {}

    for (let i = 0; i < extraMeshAllList.length; i++) {
      let mesh = extraMeshAllList[i]
      if (mesh.parent != null) {
        mesh.parent.remove(mesh)
      }
      let material = mesh.material
      if (analysis.jsonKeyIsExist(material, ['length'])) {
        mesh.material[0].dispose()
        mesh.material[1].dispose()
      } else {
        mesh.material.dispose()
      }
      mesh.geometry.dispose()
    }
    extraMeshAllList = []

    zoneBoxInfo = {}

    zoneDataInfo = {}

    makeRoom.clearBorderInfo()

  }

  group.returnGroupMesh = function(index) {
    let meshs = []
    for (const key in groupInfo) {
      let g = groupInfo[key]
      if (g.visible == false) {
        continue
      }
      let children = g.children
      for (let i = index; i < children.length; i++) {
        let m = children[i]
        if (m.visible == false) {
          continue
        }
        meshs.push(m)
      }
    }
    return meshs
  }

  group.changeChartColor = function(config) {
    let list = mapJson.concat(extraMapJson)
    for (let i = 0; i < list.length; i++) {
      let roomInfo = list[i]
      if (analysis.jsonKeyIsExist(roomInfo, ['chartLet'])) {
        group.updateFaceColor(config, roomInfo)
      }
    }
  }

  group.showFrontEXImage = function(config, callBack) {
    function returnTopImagePromise(roomInfo) {
      return new Promise((resolve, reject) => {
        let topName = roomInfo['areaid'] + '-top' + '.png'

        let face = { id: roomInfo['areaid'], topFace: '', frontFace: '' }
        if (analysis.jsonKeyIsExist(roomInfo, ['face'])) {
          face = roomInfo['face']
          face.id = roomInfo['areaid']
        }

        let leftUp = roomInfo['leftUp']
        let rightDown = roomInfo['rightDown']

        style.drawTopImg({
          list: roomInfo['areas'],
          width: rightDown.x - leftUp.x,
          height: rightDown.z - leftUp.z,
          rightDown: rightDown,
          leftUp: leftUp
        }).then(res => {
          face.topFace = res.src
          group.updateFaceImage({ list: [{ id: roomInfo['areaid'], topFace: face.topFace, frontFace: '' }] })
          resolve({
            face: face,
            name: topName,
            src: res.src
          })
        }).catch(() => {
          reject({
            result: false
          })
        })
      })
    }

    function returnImagePromise(roomInfo) {
      return new Promise((resolve, reject) => {
        let points = roomInfo['areas']
        let shapeList = []
        let length = 0
        let height = roomInfo['height']
        let closeWise = true
        if (analysis.jsonKeyIsExist(roomInfo, ['clockwise'])) {
          closeWise = roomInfo['clockwise']
        }

        for (let i = 0; i < points.length; i++) {
          let p = points[i]
          let x = parseFloat(p[0].toFixed(2))
          let z = parseFloat(p[2].toFixed(2))
          let next = i + 1
          if (next >= points.length) {
            next = 0
          }
          let nextP = points[next]
          let nx = parseFloat(nextP[0].toFixed(2))
          let nz = parseFloat(nextP[2].toFixed(2))

          let dis = parseFloat(Math.sqrt((nx - x) * (nx - x) + (nz - z) * (nz - z)).toFixed(2))
          if (dis == 0) {
            continue
          }
          let s = 7
          if (dis <= s && shapeList.length >= 1) {
            // 距离小则拼接成一段
            let lastInfo = shapeList[shapeList.length - 1]
            if (lastInfo.isEnd) {
              let info = {
                width: dis,
                height: height,
                isEnd: false
              }
              if (closeWise == false) {
                shapeList.push(info)
              } else {
                shapeList.unshift(info)
              }
            } else {
              lastInfo.width = lastInfo.width + dis
            }
          } else {
            let info = {
              width: dis,
              height: height,
              isEnd: dis <= s ? false : true
            }
            if (closeWise == false) {
              shapeList.push(info)
            } else {
              shapeList.unshift(info)
            }
          }
          length = dis + length
        }

        let name = roomInfo['areaid'] + '-front' + '.png'
        let face = { id: roomInfo['areaid'], topFace: '', frontFace: '' }
        if (analysis.jsonKeyIsExist(roomInfo, ['face'])) {
          face = roomInfo['face']
        }
        style.drawFrontImg({ list: shapeList, width: length, height: height }).then(res => {
          face.frontFace = res.src
          group.updateFaceImage({ list: [{ id: roomInfo['areaid'], topFace: '', frontFace: face.frontFace }] })
          resolve({
            face: face,
            name: name,
            src: res.src
          })
        }).catch(() => {
          reject({
            result: false
          })
        })
      })
    }

    let promiseList = []
    let list = mapJson.concat(extraMapJson)
    for (let i = 0; i < list.length; i++) {
      let roomInfo = list[i]
      if (analysis.jsonKeyIsExist(roomInfo, ['chartLet'])) {
        promiseList.push(returnImagePromise(roomInfo))
        promiseList.push(returnTopImagePromise(roomInfo))
      }
    }

    Promise.all(promiseList).then((res) => {
      callBack(res)
    }).catch(res => {

    })
  }

  group.hiddenOrShowDetail = function(config) {
    function hiddenOrShowShadowPlane(config, g) {
      let showShadow = false
      if (analysis.jsonKeyIsExist(config, ['showShadow'])) {
        showShadow = config['showShadow']
      }
      for (let i = 0; i < g.children.length; i++) {
        let c = g.children[i]
        if (c.name != 'shadowPlane') {
          continue
        }
        c.visible = showShadow
      }
    }

    function setMeshOpacity(mesh, opacity) {
      let material = mesh.material
      if (Array.isArray(material)) {
        for (let j = 0; j < material.length; j++) {
          let m = material[j]
          m.opacity = opacity
        }
      } else {
        material.opacity = opacity
      }
    }

    let zoneId = config['zoneId']
    let showOther = zoneId == '' ? true : false
    let hiddenExtra = true
    if (analysis.jsonKeyIsExist(config, ['hiddenExtra'])) {
      hiddenExtra = config['hiddenExtra']
    }
    if (hiddenExtra) {
      for (const key in extraInfo) {
        let g = extraInfo[key]
        if (showOther) {
          if (g.parent == null) {
            group.add(g)
          }
        } else {
          if (g.parent != null) {
            group.remove(g)
          }
        }
      }
    } else {
      let list = group.getExtraList()
      for (let i = 0; i < list.length; i++) {
        let extra = list[i]
        setMeshOpacity(extra, showOther ? 1 : 0.1)
      }
      for (const key in extraInfo) {
        let g = extraInfo[key]
        if (showOther) {
          if (g.parent == null) {
            group.add(g)
          }
          continue
        }
        if (key != zoneId) {
          continue
        }
        if (g.parent != null) {
          group.remove(g)
        }
      }
    }

    for (const key in groupInfo) {
      let g = groupInfo[key]
      hiddenOrShowShadowPlane(config, g)
      if (showOther) {
        if (g.parent == null) {
          group.add(g)
        }
      } else {
        if (key == zoneId) {
          if (g.parent == null) {
            group.add(g)
          }
        } else {
          if (g.parent != null) {
            group.remove(g)
          }
        }
      }
    }
  }

  // 根据点返回该点对应的楼高
  group.getFloorHeightByPoint = function(config) {
    function getPointHeight(data) {
      let zoneId = data['zoneId']
      let height = 0
      for (let i = 0; i < extraMapJson.length; i++) {
        let mapJson = extraMapJson[i]
        // if (mapJson['inZone'] != zoneId) {
        //   continue;
        // }
        if (analysis.isInPolygon([data['x'], 0, data['z']], mapJson['areas']) == false) {
          continue
        }
        let judgeY = 0
        if (analysis.jsonKeyIsExist(mapJson, ['judgeY'])) {
          judgeY = mapJson['judgeY']
        }
        let h = mapJson['height'] + judgeY
        height = height >= h ? height : h
        data['height'] = height
        if (analysis.jsonKeyIsExist(mapJson, ['inZone']) == false) {
          continue
        }
        data['zoneId'] = mapJson['inZone']
      }
      return height
    }

    let dataList = config['dataList']
    for (let i = 0; i < dataList.length; i++) {
      let data = dataList[i]
      data['y'] = getPointHeight(data)
    }
  }

  group.downFrontEXImage = function(config) {
    let list = mapJson.concat(extraMapJson)
    for (let i = 0; i < list.length; i++) {
      let roomInfo = list[i]
      if (analysis.jsonKeyIsExist(roomInfo, ['chartLet'])) {
        downLoadImg(roomInfo)
      }
    }
  }

  function downLoadImg(roomInfo) {
    let points = roomInfo['areas']
    let shapeList = []
    let length = 0
    let height = roomInfo['height']
    let leftUp = roomInfo['leftUp']
    let rightDown = roomInfo['rightDown']
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      let x = parseFloat(p[0].toFixed(2))
      let z = parseFloat(p[2].toFixed(2))
      let next = i + 1
      if (next >= points.length) {
        next = 0
      }
      let nextP = points[next]
      let nx = parseFloat(nextP[0].toFixed(2))
      let nz = parseFloat(nextP[2].toFixed(2))

      let dis = parseFloat(Math.sqrt((nx - x) * (nx - x) + (nz - z) * (nz - z)).toFixed(2))
      if (dis == 0) {
        continue
      }
      let s = 7
      if (dis <= s && shapeList.length >= 1) {
        // 距离小则拼接成一段
        let lastInfo = shapeList[shapeList.length - 1]
        if (lastInfo.isEnd) {
          let info = {
            width: dis,
            height: height,
            isEnd: false
          }
          shapeList.push(info)
        } else {
          lastInfo.width = lastInfo.width + dis
        }
      } else {
        let info = {
          width: dis,
          height: height,
          isEnd: dis <= s ? false : true
        }
        shapeList.push(info)
      }
      length = dis + length
    }
    let name = roomInfo['areaid'] + '-front' + '.png'
    let topName = roomInfo['areaid'] + '-top' + '.png'
    let face = { id: roomInfo['areaid'], topFace: '', frontFace: '' }
    if (analysis.jsonKeyIsExist(roomInfo, ['face'])) {
      face = roomInfo['face']
    }

    let promise = [style.drawFrontImg({
      list: shapeList,
      width: length,
      height: height
    }), style.drawTopImg({
      list: roomInfo['areas'],
      width: rightDown.x - leftUp.x,
      height: rightDown.z - leftUp.z,
      rightDown: rightDown,
      leftUp: leftUp
    })]
    Promise.all(promise).then(res => {
      let front = res[0]
      let element1 = document.createElement('a')
      element1.href = front.src
      face.frontFace = front.src
      element1.download = name
      element1.click()

      let top = res[1]
      let element2 = document.createElement('a')
      element2.href = top.src
      face.topFace = top.src
      element2.download = topName
      element2.click()
      group.updateFaceImage({ list: [face] })
    }).catch(() => {

    })
  }

  group.extraGroupIsExist = function() {
    return extraMapJson.length
  }

  group.getLookAtExtra = function(config) {
    let lv = config.lv
    if (lv >= 13) {
      return {}
    }

    function updateFindKeyInfo(findKey, target, camera) {
      let direction = target.clone().sub(camera.position.clone()).normalize()
      let ray = new THREE.Ray(camera.position, direction)
      // let distance = camera.position.distanceTo(target)
      for (let key in extraBoxInfo) {
        let box = extraBoxInfo[key]
        let t = new THREE.Vector3(0, 0, 0)
        let res = ray.intersectBox(box, t)
        if (res == null) {
          continue
        }
        findKey[key] = ''
      }
    }

    let target = config.target
    let camera = config.camera
    let fence = config.fence
    let w = fence.width
    let h = fence.height
    let spaceW = w * 0.1
    let spaceH = h * 0.1
    // let targets = [
    //   new THREE.Vector3(target.x - spaceW, target.y, target.z - spaceH),
    //   new THREE.Vector3(target.x + spaceW, target.y, target.z - spaceH),
    //   new THREE.Vector3(target.x + spaceW, target.y, target.z + spaceH),
    //   new THREE.Vector3(target.x - spaceW, target.y, target.z + spaceH),
    // ]
    let targets = [
      target
    ]
    let findKey = {}
    for (let i = 0; i < targets.length; i++) {
      let t = targets[i]
      updateFindKeyInfo(findKey, t, camera)
    }
    return findKey
  }

  group.getDefaultLazyZone = function() {
    const defaultInfo = {}
    for (const zoneId in groupInfo) {
      let g = groupInfo[zoneId]
      if (g.visible == false || g.parent == null) {
        continue
      }
      defaultInfo[zoneId] = ''
    }
    return {
      result: Object.keys(defaultInfo).length > 0 ? true : false,
      defaultInfo: defaultInfo
    }
  }

  group.getLookAtZone = function(config) {
    function intersectsBox(findKey, box, target) {
      for (const zoneId in zoneBoxInfo) {
        if (analysis.jsonKeyIsExist(groupInfo, [zoneId]) == false) {
          continue
        }
        let g = groupInfo[zoneId]
        if (g.parent == null) {
          continue
        }

        let zoneBox = zoneBoxInfo[zoneId]
        let res = box.intersectsBox(zoneBox)
        if (res == false) {
          continue
        }
        let x = (zoneBox.min.x + zoneBox.max.x) / 2.0
        let z = (zoneBox.min.z + zoneBox.max.z) / 2.0
        let y = target.y
        findKey[zoneId] = target.clone().distanceTo(new THREE.Vector3(x, y, z))
      }
    }

    let target = config.target
    let camera = config.camera

    // let targets = [
    //   target
    // ]

    let fence = config.fence
    // let w = fence.width
    // let h = fence.height
    // let spaceW = w * 0.02
    // let spaceH = h * 0.02

    let spaceW = 10
    let spaceH = 10

    let boxs = [
      new THREE.Box3(new THREE.Vector3(target.x - spaceW, 0, target.z - spaceH), new THREE.Vector3(target.x + spaceW, 10, target.z + spaceH))
    ]

    let findKey = {}

    for (let i = 0; i < boxs.length; i++) {
      intersectsBox(findKey, boxs[i], target)
    }

    // let targets = [
    //   new THREE.Vector3(target.x - spaceW, target.y, target.z - spaceH),
    //   new THREE.Vector3(target.x + spaceW, target.y, target.z - spaceH),
    //   new THREE.Vector3(target.x + spaceW, target.y, target.z + spaceH),
    //   new THREE.Vector3(target.x - spaceW, target.y, target.z + spaceH)
    // ]
    //
    // for (let i = 0; i < targets.length; i++) {
    //   let t = targets[i]
    //   updateFindKeyInfo(findKey, t, camera)
    // }
    if (Object.keys(findKey).length >= 2) {
      let zoneKey = ''
      let minDis = 65535
      for (const key in findKey) {
        let dis = findKey[key]
        if (minDis > dis) {
          minDis = dis
          zoneKey = key
        }
      }
      if (zoneKey == '') {
        return findKey
      }
      let newFindKey = {}
      newFindKey[zoneKey] = ''
      return newFindKey
    }
    return findKey
  }

  group.extraGroupHiddenOrShow = function(config) {
    let showZoneKey = {}

    let lockZone = false
    if (analysis.jsonKeyIsExist(config, ['lockZone'])) {
      lockZone = config['lockZone']
    }

    if (lockZone) {
      let lookAtZone = config.lookAtZone
      for (const key in lookAtZone) {
        showZoneKey[key] = {
          visible: true
        }
      }
      return {
        result: true,
        showZoneKey: showZoneKey,
        isAllVisible: false
      }
    }

    let forceShow = false
    if (analysis.jsonKeyIsExist(config, ['forceShow'])) {
      forceShow = config['forceShow']
    }
    if (forceShow) {
      for (let key in extraInfo) {
        let g = extraInfo[key]
        let scaleInfo = { scale: 1, opacity: 1 }
        g.scaleAnimation(scaleInfo)
        showZoneKey[key] = {
          visible: false
        }
      }
      return {
        result: false,
        showZoneKey: showZoneKey,
        isAllVisible: true
      }
    }

    let forceHidden = config['forceHidden']
    if (forceHidden) {
      for (let key in extraInfo) {
        let g = extraInfo[key]
        let scaleInfo = { scale: 0.01, opacity: 0 }
        g.scaleAnimation(scaleInfo)
        showZoneKey[key] = {
          visible: true
        }
      }
      return {
        result: true,
        showZoneKey: showZoneKey,
        isAllVisible: false
      }
    }

    let floorLock = config.floorLock
    if (floorLock) {
      let animation = { scale: 1, opacity: 1 }
      for (let key in extraInfo) {
        let g = extraInfo[key]
        g.scaleAnimation(animation)
        showZoneKey[key] = {
          visible: false
        }
      }
      return {
        result: false,
        showZoneKey: showZoneKey,
        isAllVisible: true
      }
    }

    let shouldShowAllText = false // 是否显示该楼层的所有的文字
    let findKey = {}
    let lookAtZone = config.lookAtZone
    let lookAtExtra = config.lookAtExtra
    let lv = config.lv
    let judgeLV = config.judgeLV
    let otherLookAtZone = config.otherLookAtZone

    if (lv < judgeLV || Object.keys(otherLookAtZone).length > 0) {
      for (let key in extraInfo) {
        if (analysis.jsonKeyIsExist(lookAtZone, [key]) == false && analysis.jsonKeyIsExist(otherLookAtZone, [key]) == false) {
          continue
        }
        shouldShowAllText = true
        findKey[key] = '1'
      }
    }

    let count = Object.keys(lookAtExtra).length
    if (shouldShowAllText == false) {
      shouldShowAllText = count > 0 ? true : shouldShowAllText
    }
    let isAllVisible = false

    for (let extrakey in extraInfo) {
      let g = extraInfo[extrakey]
      if (g.visible == true) {
        isAllVisible = true
      }
      let animation = { scale: 1, opacity: 1 }
      let visible = false
      if (shouldShowAllText) {
        if (analysis.jsonKeyIsExist(findKey, [extrakey])) {
          animation.scale = 0.01
          animation.opacity = 0
          visible = true
        } else {
          animation.scale = 0.5
          animation.opacity = 1
        }
      }
      showZoneKey[extrakey] = {
        visible: visible
      }
      g.scaleAnimation(animation)
    }

    return {
      result: shouldShowAllText,
      showZoneKey: showZoneKey,
      isAllVisible: isAllVisible
    }
  }

  setUpDefaultGroup()

  return group
}

function createAnimationGroup() {
  let extraGroup = new THREE.Group()

  function changeOpacity(opacity) {
    let extraList = extraGroup.children
    for (let i = 0; i < extraList.length; i++) {
      let mesh = extraList[i]
      let material = mesh.material
      for (let j = 0; j < material.length; j++) {
        let m = material[j]
        m.opacity = opacity
        if (opacity == 0) {
          extraGroup.visible = false
        } else {
          if (extraGroup.visible == false) {
            extraGroup.visible = true
          }
        }
      }
    }
  }

  let toScale = {
    scale: 1,
    opacity: 1
  }

  let endScale = {
    scale: 1,
    opacity: 1
  }

  let t = null

  extraGroup.scaleAnimation = function(config) {
    if (endScale.scale == config.scale && endScale.opacity == config.opacity) {
      return
    }
    endScale.scale = config.scale
    endScale.opacity = config.opacity
    if (t == null) {
      t = new TWEEN.Tween(toScale)
    } else {
      t.stop()
    }
    t.to(config, 300).onUpdate(() => {
      extraGroup.scale.y = toScale.scale
      changeOpacity(toScale.opacity)
    })
    t.start()
  }

  return extraGroup
}

export function createCommonGroup() {
  let common = createFloorManager()

  let created = false

  let analysis = createAnalaysisInfo() // 构造地图解析

  common.returnCreateStatus = function() {
    return created
  }

  common.showCommonGroupByPromise = function(config, callBack) {
    if (typeof (config.dataInfo) == 'undefined' || config.dataInfo == null) {
      callBack()
      return
    }

    let finishCallBack = () => {
      created = true
      callBack()
    }

    common.showObjectByPromise({
      receiveShadow: config.receiveShadow,
      updateBorder: true,
      updateExtra: true,
      mapid: config.mapid,
      showPolygonOffsetFactor: config.polygonOffsetFactor,
      showPolygonOffsetUnits: config.polygonOffsetUnits,
      polygonOffsetFactor: config.polygonOffsetFactor,
      polygonOffsetUnits: config.polygonOffsetUnits,
      dataInfo: config.dataInfo,
      draw: true,
      show: config.show,
      extraInfo: config.extraInfo,
      lvhua: config.lvhua,
      zone: config.zone,
      showOther: config.showOther,
      background: config.background
    }, ['plane', 'zero', 'showOther', 'extra'], finishCallBack)
  }

  let deleteAll = common.deleteAll

  common.deleteAll = function() {
    deleteAll()
    created = false
  }

  let returnGroupMesh = common.returnGroupMesh

  common.returnGroupMesh = function(config) {
    let index = 0
    if (analysis.jsonKeyIsExist(config, ['zoneInfo'])) {
      index = config['zoneInfo'] ? 0 : 1
    }
    return returnGroupMesh(index)
  }

  return common
}
