import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'

import {travlManager} from "../../MapConfig";

import * as THREE from 'three/build/three.min.js'

export function createAStart() {
  const aStart = new Object()

  const analysis = createAnalaysisInfo() // 构造地图解析

  const floorSpace = 0

  let manager = travlManager();

  const a_line = new THREE.Line3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0))

  const leadPointType = {
    start: 5,
    end: 4,
    louti: 1,
    dianti: 2,
    futi: 3,
    normal: 0,
    po: 99
  }

  let leadPointInfo = {
    gpsDoor: {}, // 导航gps门口
    leadInfo: {}, // 连接点连接信息
    leadDis: {}, // 楼层位移
    linkFloor: {}, // 楼层连接
    linkLine: {}, // 终点和起点可连接的点或者线
    poInfo: {} // 坡度信息
  }

  let extraPath = {}

  let endWayCount = 0

  const leadWayWidthInfo = {}

  aStart.deleteAll = function () {
    leadPointInfo = {
      gpsDoor: {}, // 导航gps门口
      leadInfo: {}, // 连接点连接信息
      leadDis: {}, // 楼层位移
      linkFloor: {}, // 楼层连接
      linkLine: {}, // 终点和起点可连接的点或者线
      poInfo: {} // 坡度信息
    }
  }

  function getMapSpace(info) {
    let dis = 0
    if (analysis.jsonKeyIsExist(leadPointInfo.leadDis, [info.mapid])) {
      dis = leadPointInfo.leadDis[info.mapid].dis
    }
    return dis
  }

  aStart.getExtraPath = function(config) {
    if (Object.keys(extraPath).length == 0) {
      return []
    }
    const type = config['type']
    if (analysis.jsonKeyIsExist(extraPath, [type]) == false) {
      return []
    }
    const info = extraPath[type]
    const mapid = config['mapid']
    if (info['mapid'] != mapid) {
      return []
    }
    return [{
      path: info['path'],
      pathScale: []
    }]
  }

  aStart.setUpExtra = function(res) {
    const type = res['type']
    const path = res['path']
    extraPath[type] = {
      path: path,
      mapid: res['mapid'],
      type: type
    }
  }

  aStart.cleanExtra = function() {
    extraPath = {}
  }

  aStart.getLeadTypes = function(config) {
    const types = []
    const typeInfo = {}
    const linkInfo = leadPointInfo.linkFloor
    for (const linkKey in linkInfo) {
      const info = linkInfo[linkKey]
      if (analysis.jsonKeyIsExist(info, [config.startMapid])) {
        if (linkKey.indexOf('D') >= 0) {
          typeInfo['D'] = {
            type: leadPointType.dianti
          }
        } else if (linkKey.indexOf('L') >= 0) {
          typeInfo['L'] = {
            type: leadPointType.louti
          }
        } else if (linkKey.indexOf('F') >= 0) {
          typeInfo['F'] = {
            type: leadPointType.futi
          }
        }
      }
    }
    for (const key in typeInfo) {
      types.push(typeInfo[key].type)
    }
    return types
  }

  // 更新楼层连接信息
  aStart.updateLinkFloor = function(dataInfo) {
    const leadInfo = leadPointInfo.leadInfo
    for (const mapid in dataInfo) {
      if (analysis.jsonKeyIsExist(leadInfo, [mapid]) == false) {
        continue
      }
      const lead = leadInfo[mapid]

      const info = dataInfo[mapid]
      for (const id in info) {
        const itemInfo = info[id]
        if (analysis.jsonKeyIsExist(lead, [id]) == false) {
          continue
        }
        const itemLeadInfo = lead[id]
        if (analysis.jsonKeyIsExist(itemLeadInfo, ['linkPoint']) == false) {
          continue
        }

        const floors = itemLeadInfo['linkPoint']
        for (const itemMapid in itemInfo) {
          const findInfo = itemInfo[itemMapid]
          for (const findId in findInfo) {
            if (analysis.jsonKeyIsExist(floors, [findId]) == false) {
              continue
            }
            delete floors[findId]
          }
        }
      }
    }
  }

  aStart.linkFloors = function(config) {
    let ftWeight = 0
    let dtWeight = 10
    let ltWeight = 20
    if (analysis.jsonKeyIsExist(localStorage, ['ftWeight'])) {
      ftWeight = parseFloat(localStorage['ftWeight'])
    }
    if (analysis.jsonKeyIsExist(localStorage, ['dtWeight'])) {
      dtWeight = parseFloat(localStorage['dtWeight'])
    }
    if (analysis.jsonKeyIsExist(localStorage, ['ltWeight'])) {
      ltWeight = parseFloat(localStorage['ltWeight'])
    }

    leadWayWidthInfo['futi'] = ftWeight
    leadWayWidthInfo['dianti'] = dtWeight
    leadWayWidthInfo['louti'] = ltWeight

    /* -------------------------------------- */
    function startLinkFloor(mapid1, mapid2, leadInfo) {
      const linkFloor = leadPointInfo.linkFloor
      if (analysis.jsonKeyIsExist(leadInfo, [mapid1, mapid2]) == false) {
        return
      }

      for (const link in linkFloor) {
        const info = linkFloor[link]
        if (analysis.jsonKeyIsExist(info, [mapid1, mapid2]) == false) {
          continue
        }
        const p1 = info[mapid1]
        const p2 = info[mapid2]
        let linkPoint1 = {}
        let linkPoint2 = {}
        if (analysis.jsonKeyIsExist(p1, ['linkPoint'])) {
          linkPoint1 = p1['linkPoint']
        } else {
          p1['linkPoint'] = linkPoint1
        }
        if (analysis.jsonKeyIsExist(p2, ['linkPoint'])) {
          linkPoint2 = p2['linkPoint']
        } else {
          p2['linkPoint'] = linkPoint2
        }
        let id = ''
        const p1Id = p1.id
        const p2Id = p2.id
        if (p1Id > p2Id) {
          id = 'line' + p1Id + '-' + p2Id
        } else {
          id = 'line' + p2Id + '-' + p1Id
        }
        const p_Info = {
          startP: {
            x: p1.x,
            y: 0,
            z: p1.y
          },
          endP: {
            x: p2.x,
            y: 0,
            z: p2.y
          },
          id: id,
          start: p1Id,
          end: p2Id,
          startMapid: mapid1,
          endMapid: mapid2,
          dis: 0
        }
        linkPoint1[p2Id] = JSON.parse(JSON.stringify(p_Info))
        linkPoint2[p1Id] = JSON.parse(JSON.stringify(p_Info))
      }
    }

    function findFloorLink(mapLeadInfo, mapid) {
      const linkFloor = leadPointInfo.linkFloor
      const poInfo = leadPointInfo.poInfo

      for (const key in mapLeadInfo) {
        const lead = mapLeadInfo[key]
        lead['mapid'] = mapid
        if (analysis.jsonKeyIsExist(lead, ['link']) == false) {
          continue
        }
        const link = lead['link']
        if (link.indexOf('P') >= 0) {
          let mapPoInfo = {}
          if (analysis.jsonKeyIsExist(poInfo, [mapid])) {
            mapPoInfo = poInfo[mapid]
          } else {
            poInfo[mapid] = mapPoInfo
          }
          const id = lead['id']
          mapPoInfo[id] = JSON.parse(JSON.stringify(lead))
          continue
        }

        let linkInfo = {}
        if (analysis.jsonKeyIsExist(linkFloor, [link])) {
          linkInfo = linkFloor[link]
        } else {
          linkFloor[link] = linkInfo
        }
        linkInfo[mapid] = lead
      }
    }

    /* -------------------------------------- */

    leadPointInfo.linkFloor = {}
    leadPointInfo.poInfo = {}
    const leadInfo = config.leadInfo
    const mapOrderList = config.mapOrderList
    // 先找出楼层连接点
    for (let i = mapOrderList.length - 1; i >= 0; i--) {
      const info = mapOrderList[i]
      if (analysis.jsonKeyIsExist(leadInfo, [info.mapid]) == false) {
        continue
      }
      findFloorLink(leadInfo[info.mapid], info.mapid)
    }
    // 开始连接楼层
    for (let i = mapOrderList.length - 1; i > 0; i--) {
      const info = mapOrderList[i]
      const nextInfo = mapOrderList[i - 1]
      if (analysis.jsonKeyIsExist(leadInfo, [info.mapid, nextInfo.mapid]) == false) {
        continue
      }
      startLinkFloor(info.mapid, nextInfo.mapid, leadInfo)
    }
    leadPointInfo.leadInfo = leadInfo
  }

  // 寻找gps导航的门口
  function findGPSDoor(mapOrderList) {

  }

  // 更新平移节点 用于多楼层
  aStart.updateLeadInfo = function(config) {
    leadPointInfo.leadDis = {}
    const leadInfo = leadPointInfo.leadInfo
    const mapOrderList = config.mapOrderList
    const linkLineInfo = {}
    // 先更新距离
    for (let i = 0; i < mapOrderList.length; i++) {
      const info = mapOrderList[i]
      const dis = floorSpace * (mapOrderList.length - i)
      leadPointInfo.leadDis[info.mapid] = {
        dis: dis
      }
    }

    for (let i = mapOrderList.length - 1; i >= 0; i--) {
      const info = mapOrderList[i]
      if (analysis.jsonKeyIsExist(leadInfo, [info.mapid]) == false) {
        continue
      }
      const linkLine = {}
      linkLineInfo[info.mapid] = linkLine
      const dis = getMapSpace(info)
      const mapLeadInfo = leadInfo[info.mapid]
      for (const key in mapLeadInfo) {
        const lead = mapLeadInfo[key]
        lead.x = lead.x + dis
        lead.y = lead.y + dis
        if (analysis.jsonKeyIsExist(lead, ['linkPoint']) == false) {
          continue
        }

        if (analysis.jsonKeyIsExist(lead, ['link']) && lead['link'].indexOf('GPS') >= 0) {
          let gpsInfo = {}
          if (analysis.jsonKeyIsExist(leadPointInfo.gpsDoor, [info.mapid])) {
            gpsInfo = leadPointInfo.gpsDoor[info.mapid]
          } else {
            leadPointInfo.gpsDoor[info.mapid] = gpsInfo
          }
          gpsInfo[key] = lead
        }
        const linkPoint = lead['linkPoint']
        for (const linkKey in linkPoint) {
          const linkInfo = linkPoint[linkKey]
          let startDis = dis
          let endDis = dis
          if (analysis.jsonKeyIsExist(linkInfo, ['startMapid', 'endMapid'])) {
            startDis = getMapSpace({
              mapid: linkInfo['startMapid']
            })
            endDis = getMapSpace({
              mapid: linkInfo['endMapid']
            })
          }
          const startP = linkInfo['startP']
          const endP = linkInfo['endP']
          startP.x = startP.x + startDis
          startP.z = startP.z + startDis
          endP.x = endP.x + endDis
          endP.z = endP.z + endDis
          linkLine[linkInfo['id']] = {
            start: linkInfo['start'],
            end: linkInfo['end'],
            startP: {
              x: startP.x,
              y: 0,
              z: startP.z
            },
            endP: {
              x: endP.x,
              y: 0,
              z: endP.z
            }
          }
        }
      }
    }
    leadPointInfo.linkLine = linkLineInfo
    leadPointInfo.leadInfo = leadInfo
  }

  // 查找一个能从室外导航到室内的一个连接点
  aStart.returnLeadGpsDoor = function(mapOrderList) {
    if (mapOrderList.length == 0) {
      return {
        result: false
      }
    }
    const mapInfo = mapOrderList[mapOrderList.length - 1]
    if (analysis.jsonKeyIsExist(leadPointInfo.gpsDoor, [mapInfo.mapid]) == false) {
      return {
        result: false
      }
    }
    let dis = 0
    if (analysis.jsonKeyIsExist(leadPointInfo.leadDis, [mapInfo.mapid])) {
      dis = leadPointInfo.leadDis[mapInfo.mapid].dis
    }
    const gpsInfo = leadPointInfo.gpsDoor[mapInfo.mapid]
    for (const key in gpsInfo) {
      const info = gpsInfo[key]
      const x = info.x - dis
      const z = info.y - dis
      return {
        result: true,
        x: x,
        z: z,
        mapid: mapInfo.mapid
      }
    }

    return {
      result: false
    }
  }

  // 开始寻找起点和终点可以连接的点
  aStart.findStartAndEndLink = function(config) {
    /* ------------------------------------------- */
    function returnNewPoint(id, mapid, room, x, z) {
      const spaceDis = getMapSpace({
        mapid: mapid
      })
      return {
        x: x + spaceDis,
        y: z + spaceDis,
        title: '',
        mapid: mapid,
        id: id,
        type: (id == 'startP' ? leadPointType.start : leadPointType.end),
        room: room,
        inMapId: room['areaid']
      }
    }

    function pointIsInRoom(point, zeroId, id, leadInfo) {
      const room = point['room']
      const areaid = room['areaid']
      const areas = room['areas']
      if (areas.length != 0 && areaid != '' && areaid != zeroId) {
        // 终点在房间
        const polygon = analysis.getPolygonAreaCenter(areas)
        const newPoint = returnNewPoint(id, point['mapid'], room, polygon[1], polygon[0])
        for (const key in leadInfo) {
          const link = leadInfo[key]
          if (analysis.jsonKeyIsExist(link, ['inMapId']) == false) {
            continue
          }
          const inMapId = link['inMapId']
          let mapid = link['mapid']
          if (inMapId != areaid && (mapid + inMapId) != areaid) {
            continue
          }
          let pointLink = {}
          if (analysis.jsonKeyIsExist(newPoint, ['linkPoint']) == false) {
            newPoint['linkPoint'] = pointLink
          } else {
            pointLink = newPoint['linkPoint']
          }
          let linkId = ''
          const p1Id = newPoint.id
          const p2Id = key
          if (p1Id > p2Id) {
            linkId = 'line' + p1Id + '-' + p2Id
          } else {
            linkId = 'line' + p2Id + '-' + p1Id
          }

          const p_Info = {
            startP: {
              x: newPoint.x,
              y: 0,
              z: newPoint.y
            },
            endP: {
              x: link.x,
              y: 0,
              z: link.y
            },
            id: linkId,
            start: p1Id,
            end: p2Id,
            dis: 0
          }
          pointLink[key] = JSON.parse(JSON.stringify(p_Info))
          let linkPoint = {}
          if (analysis.jsonKeyIsExist(link, ['linkPoint']) == false) {
            link['linkPoint'] = linkPoint
          } else {
            linkPoint = link['linkPoint']
          }
          linkPoint[newPoint.id] = JSON.parse(JSON.stringify(p_Info))
        }

        return {
          result: true,
          point: newPoint
        }
      }
      return {
        result: false
      }
    }

    function leadInfoAddNewPoint(point, newPoint, leadInfo, spaceDis, mapid) {
      const id = newPoint.x.toFixed(1) + '~' + newPoint.z.toFixed(1)
      let newPointInfo = {}
      if (analysis.jsonKeyIsExist(leadInfo, [id]) == false) {
        newPointInfo = {
          x: newPoint.x + spaceDis,
          y: newPoint.z + spaceDis,
          title: '',
          mapid: mapid,
          id: id,
          type: leadPointType.normal,
          room: [],
          inMapId: point['inMapId']
        }
        leadInfo[id] = newPointInfo
      } else {
        newPointInfo = leadInfo[id]
      }
      const pointInfo = leadInfo[point.id]
      const dis = Math.sqrt((newPointInfo.x - pointInfo.x) * (newPointInfo.x - pointInfo.x) + (newPointInfo.y -
        pointInfo.y) * (newPointInfo.y - pointInfo.y))

      let linkId = ''
      const p1Id = newPointInfo.id
      const p2Id = point.id
      if (p1Id > p2Id) {
        linkId = 'line' + p1Id + '-' + p2Id
      } else {
        linkId = 'line' + p2Id + '-' + p1Id
      }

      const p_Info = {
        startP: {
          x: newPointInfo.x,
          y: 0,
          z: newPointInfo.y
        },
        endP: {
          x: point.x,
          y: 0,
          z: point.y
        },
        id: linkId,
        start: p1Id,
        end: p2Id,
        dis: dis
      }

      let newPointLink = {}
      if (analysis.jsonKeyIsExist(newPointInfo, ['linkPoint'])) {
        newPointLink = newPointInfo['linkPoint']
      } else {
        newPointInfo['linkPoint'] = newPointLink
      }
      let pointLink = {}
      if (analysis.jsonKeyIsExist(point, ['linkPoint'])) {
        pointLink = point['linkPoint']
      } else {
        point['linkPoint'] = pointLink
      }
      pointLink[newPointInfo.id] = JSON.parse(JSON.stringify(p_Info))
      newPointLink[point.id] = JSON.parse(JSON.stringify(p_Info))
    }

    // 点投影到线上的点
    function findCountLinkLine(list, point, leadInfo, minDis, zeroId) {
      const spaceDis = getMapSpace(point)
      for (const leadInfoId in leadInfo) {
        const leadDetailInfo = leadInfo[leadInfoId]
        if (analysis.jsonKeyIsExist(leadDetailInfo, ['linkPoint']) == false) {
          continue
        }
        const lineInfo = leadDetailInfo['linkPoint']
        for (const leadId in lineInfo) {
          const info = lineInfo[leadId]
          const start = info['start']
          if (start != leadInfoId) {
            continue
          }
          const end = info['end']
          const startInfo = leadInfo[start]
          const endInfo = leadInfo[end]
          if (analysis.jsonKeyIsExist(leadInfo, [start, end]) == false) {
            continue
          }
          const startP = info['startP']
          const endP = info['endP']
          const p1 = {
            x: point.x - spaceDis,
            z: point.y - spaceDis
          }
          const sp = {
            x: startP.x - spaceDis,
            y: 0,
            z: startP.z - spaceDis
          }
          const ep = {
            x: endP.x - spaceDis,
            y: 0,
            z: endP.z - spaceDis
          }

          const res = analysis.updateLineAndclosestPointToPointParameterInfo(a_line, p1.x, 0, p1.z, sp, ep)
          const scale = res.scale
          if (scale >= 1 || scale <= 0 || isNaN(scale)) {
            continue
          }
          const targer = res.point
          let isCorss = false
          for (let i = 0; i < list.length; i++) {
            const room = list[i]
            if (room['areaid'] == zeroId) {
              continue
            }
            let cross = ''
            if (analysis.jsonKeyIsExist(room, ['cross'])) {
              cross = room['cross']
            }
            if (cross != '') {
              continue
            }
            isCorss = analysis.judgeIsCorssWall([{
              x: point.x - spaceDis,
              z: point.y - spaceDis
            }, {
              x: targer.x,
              z: targer.z
            }], room)
            if (isCorss) {
              break
            }
          }
          if (isCorss) {
            continue
          }

          // 先将两个点断开
          if (analysis.jsonKeyIsExist(startInfo, ['linkPoint']) && analysis.jsonKeyIsExist(endInfo, ['linkPoint'])) {
            const startLinkPoint = startInfo['linkPoint']
            const endLinkPoint = endInfo['linkPoint']
            delete startLinkPoint[endInfo.id]
            delete endLinkPoint[startInfo.id]
          }

          leadInfoAddNewPoint(startInfo, targer, leadInfo, spaceDis, point['mapid'])
          leadInfoAddNewPoint(endInfo, targer, leadInfo, spaceDis, point['mapid'])

          const dis = Math.sqrt((targer.x - point.x + spaceDis) * (targer.x - point.x + spaceDis) + (targer.z - point
            .y + spaceDis) * (targer.z - point.y + spaceDis))
          if (dis <= minDis) {
            continue
          }
          leadInfoAddNewPoint(point, targer, leadInfo, spaceDis, point['mapid'])
        }
      }
    }

    function findCouldLink(list, point, space, zeroId, leadInfo) {
      const spaceDis = getMapSpace(point)
      for (const key in leadInfo) {
        if (point.id == key || key == 'startP' || key == 'endP') {
          continue
        }
        const link = leadInfo[key]
        const dis = Math.sqrt((link.x - point.x) * (link.x - point.x) + (link.y - point.y) * (link.y - point.y))
        if (dis > space) {
          continue
        }
        let isCorss = false
        for (let i = 0; i < list.length; i++) {
          const room = list[i]
          if (room['areaid'] == zeroId) {
            continue
          }
          let cross = ''
          if (analysis.jsonKeyIsExist(room, ['cross'])) {
            cross = room['cross']
          }
          if (cross != '') {
            continue
          }
          isCorss = analysis.judgeIsCorssWall([{
            x: point.x - spaceDis,
            z: point.y - spaceDis
          }, {
            x: link.x - spaceDis,
            z: link.y - spaceDis
          }], room)
          if (isCorss) {
            break
          }
        }
        if (isCorss) {
          continue
        }
        let pointLink = {}
        if (analysis.jsonKeyIsExist(point, ['linkPoint']) == false) {
          point['linkPoint'] = pointLink
        } else {
          pointLink = point['linkPoint']
        }
        let id = ''
        const p1Id = point.id
        const p2Id = key
        if (p1Id > p2Id) {
          id = 'line' + p1Id + '-' + p2Id
        } else {
          id = 'line' + p2Id + '-' + p1Id
        }

        const p_Info = {
          startP: {
            x: point.x,
            y: 0,
            z: point.y
          },
          endP: {
            x: link.x,
            y: 0,
            z: link.y
          },
          id: id,
          start: p1Id,
          end: p2Id,
          dis: dis
        }
        pointLink[key] = JSON.parse(JSON.stringify(p_Info))
        let linkPoint = {}
        if (analysis.jsonKeyIsExist(link, ['linkPoint']) == false) {
          link['linkPoint'] = linkPoint
        } else {
          linkPoint = link['linkPoint']
        }
        linkPoint[point.id] = JSON.parse(JSON.stringify(p_Info))
      }
    }

    function createStartAndEnd(config) {
      const startZeroId = config['startZeroId']
      const endZeroId = config['endZeroId']
      const start = config['start']
      const end = config['end']

      const copyInfo = JSON.parse(JSON.stringify(leadPointInfo.leadInfo))
      const startLeadInfo = copyInfo[start['mapid']]
      const endLeadInfo = copyInfo[end['mapid']]
      const startRes = pointIsInRoom(start, startZeroId, 'startP', startLeadInfo)
      const endRes = pointIsInRoom(end, endZeroId, 'endP', endLeadInfo)
      let startPoint = {}
      let endPoint = {}

      const space = 3 * 10
      let startIsInRoom = false
      if (startRes.result) {
        startPoint = startRes.point
        // startIsInRoom = true
      } else {
        startPoint = returnNewPoint('startP', start['mapid'], start['room'], start['x'], start['z'])
      }

      let endIsInRoom = false
      if (endRes.result) {
        endPoint = endRes.point
        // endIsInRoom = true
      } else {
        endPoint = returnNewPoint('endP', end['mapid'], end['room'], end['x'], end['z'])
      }

      let startInfo = {}
      if (analysis.jsonKeyIsExist(copyInfo, [start['mapid']])) {
        startInfo = copyInfo[start['mapid']]
      } else {
        copyInfo[start['mapid']] = startInfo
      }

      startInfo['startP'] = startPoint

      let endInfo = {}
      if (analysis.jsonKeyIsExist(copyInfo, [end['mapid']])) {
        endInfo = copyInfo[end['mapid']]
      } else {
        copyInfo[end['mapid']] = endInfo
      }
      endInfo['endP'] = endPoint

      if (startIsInRoom == false) {
        const startWall = config['startWall']
        const startMapInfo = config['startMapInfo']
        const list = startWall.concat(startMapInfo)
        findCountLinkLine(list, startPoint, startLeadInfo, space, startZeroId)
        findCouldLink(list, startPoint, space, startZeroId, startLeadInfo)
      }

      if (endIsInRoom == false) {
        const endWall = config['endWall']
        const endMapInfo = config['endMapInfo']
        const list = endWall.concat(endMapInfo)
        findCountLinkLine(list, endPoint, endLeadInfo, space, endZeroId)
        findCouldLink(list, endPoint, space, endZeroId, endLeadInfo)
      }

      let allCount = 0
      for (const key in copyInfo) {
        allCount = allCount + Object.keys(copyInfo[key]).length
      }
      endWayCount = allCount
      return {
        result: (analysis.jsonKeyIsExist(startPoint, ['linkPoint']) && analysis.jsonKeyIsExist(startPoint, [
          'linkPoint'
        ])),
        leadInfo: copyInfo,
        start: startPoint,
        end: endPoint
      }
    }

    /* ------------------------------------------- */

    return new Promise(resolve => {
      resolve(createStartAndEnd(config))
    })
  }

  function getFliterTypes(fliters) {
    const linkFloor = leadPointInfo.linkFloor
    const close = {}
    for (let i = 0; i < fliters.length; i++) {
      const f = fliters[i]
      let link = ''
      if (f == leadPointType.dianti) {
        link = 'D'
      } else if (f == leadPointType.louti) {
        link = 'L'
      } else if (f == leadPointType.futi) {
        link = 'F'
      }
      if (link == '') {
        continue
      }
      for (const key in linkFloor) {
        if (key.indexOf(link) < 0) {
          continue
        }
        const linkInfo = linkFloor[key]
        for (const mapid in linkInfo) {
          const info = linkInfo[mapid]
          close[info.id] = '1'
        }
      }
    }
    return close
  }

  aStart.getLeadWay = function(config, types, callBack) {
    const promises = []
    for (let i = 0; i < types.length; i++) {
      const type = types[i]
      let fliters = []
      if (type == leadPointType.dianti) {
        fliters = [leadPointType.louti, leadPointType.futi]
      } else if (type == leadPointType.louti) {
        fliters = [leadPointType.dianti, leadPointType.futi]
      } else if (type == leadPointType.futi) {
        fliters = [leadPointType.louti, leadPointType.dianti]
      }
      promises.push(findWay(config, getFliterTypes(fliters)))
    }
    Promise.all(promises).then(res => {
      callBack(res[0].wayResult)
    })
  }

  // 开始寻找路线
  aStart.startGetLeadWay = function(config, types, callBack) {
    function returnLeadType(type) {
      let typeStr = ''
      if (type == leadPointType.dianti) {
        typeStr = 'dianti'
      } else if (type == leadPointType.louti) {
        typeStr = 'louti'
      } else if (type == leadPointType.futi) {
        typeStr = 'futi'
      }
      return typeStr
    }

    const promises = []
    for (let i = 0; i < types.length; i++) {
      const type = types[i]
      let fliters = []
      if (type == leadPointType.dianti) {
        fliters = [leadPointType.louti, leadPointType.futi]
      } else if (type == leadPointType.louti) {
        fliters = [leadPointType.dianti, leadPointType.futi]
      } else if (type == leadPointType.futi) {
        fliters = [leadPointType.louti, leadPointType.dianti]
      }
      promises.push(findWay(config, getFliterTypes(fliters)))
    }
    Promise.all(promises).then(res => {
      let noWay = false
      let noWayCount = 0
      const mapOrderInfo = {}
      const wayInfo = {}
      let poInfo = {}
      let isSameMap = true
      let typeNum = leadPointType.dianti
      let minDis = 65535 * 1000
      for (let i = 0; i < res.length; i++) {
        if (res[i].result == false) {
          noWayCount++
          continue
        }
        const dataInfoResult = res[i].wayResult
        const type = types[i]
        const typeStr = returnLeadType(type)
        let width = 0
        if (analysis.jsonKeyIsExist(leadWayWidthInfo, [typeStr])) {
          width = leadWayWidthInfo[typeStr] * 10
        }
        const dis = dataInfoResult.dis + width
        if (dis < minDis) {
          minDis = dis
          typeNum = type
        }
        wayInfo[typeStr] = {}
        wayInfo[typeStr]['list'] = []
        wayInfo[typeStr]['list'] = dataInfoResult.indexs
        wayInfo[typeStr]['po'] = dataInfoResult.poInfo
        wayInfo[typeStr]['order'] = dataInfoResult.mapOrder
        wayInfo[typeStr]['zoneIdInfo'] = dataInfoResult.zoneIdInfo
        poInfo = dataInfoResult.poInfo
        isSameMap = dataInfoResult.isSameMap
        const mapOrder = dataInfoResult.mapOrder
        const arr = []
        for (let j = mapOrder.length - 1; j >= 0; j--) {
          const id = mapOrder[j].mapid
          const orderInfo = {
            mapName: '',
            mapid: id
          }
          arr.push(orderInfo)
        }
        mapOrderInfo[typeStr] = arr
      }
      if (noWayCount == res.length) {
        noWay = true
      }
      const result = {
        result: !noWay,
        msg: '',
        data: {
          mapid: config['startMapid'],
          way: wayInfo,
          type: returnLeadType(typeNum),
          isSameMap: isSameMap,
          noWay: noWay,
          isPo: Object.keys(poInfo).length != 0,
          tuijian: '',
          mapOrderInfo: mapOrderInfo,
          typeNum: typeNum
        }
      }
      callBack(result)
    })
  }

  // 开始寻找节点
  function findWay(config, close) {
    const searchInfo = {} // 开放搜索节点
    const openSearchInfo = {}

    let isPo = true
    if (analysis.jsonKeyIsExist(config, ['isPo'])) {
      isPo = config['isPo']
    }

    if (isPo == false) {
      for (const mapid in leadPointInfo.poInfo) {
        const mapPoInfo = leadPointInfo.poInfo[mapid]
        for (const id in mapPoInfo) {
          close[id] = '1'
        }
      }
    }

    /* ------------------------------------------- */
    function getDis(start, end) {
      return Math.sqrt((start.x - end.x) * (start.x - end.x) + (start.y - end.y) * (start.y - end.y))
    }

    function getBestLinkInfo(nowNote, endInfo, close) {
      function resetNiceKey(niceKey, niceMapid) {
        const count = Object.keys(openSearchInfo).length
        if (count == 0) {
          return {
            result: false
          }
        }
        // 重新在searchInfo 寻找 同时不要已经便利过的
        let newKey = ''
        let compareDis = 65535 * 10000

        for (const searchKey in openSearchInfo) {
          const findInfo = searchInfo[searchKey]
          const dis = findInfo.expectDis + findInfo.dis
          if (dis < compareDis) {
            newKey = searchKey
            compareDis = dis
          }
        }
        if (newKey == '' || newKey == niceKey) {
          // 好吧/(ㄒoㄒ)/~~没有更好的选择了
          return {
            result: true,
            niceKey: niceKey,
            mapid: niceMapid
          }
        } else {
          // 重新调整open和niceKey
          return {
            result: true,
            niceKey: newKey,
            mapid: searchInfo[newKey].mapid
          }
        }
      }

      // 当前节点的连线 开始寻找下一个节点
      const linkPoint = nowNote['linkPoint']
      let minDis = 65535 * (floorSpace + 1)
      let niceKey = ''
      let niceMapid = nowNote.mapid
      let pointDis = 0
      let expectDis = 0
      let parentInfo = {
        dis: 65535
      }
      if (analysis.jsonKeyIsExist(searchInfo, [nowNote.id])) {
        parentInfo = searchInfo[nowNote.id]
      }
      for (const key in linkPoint) {
        if (analysis.jsonKeyIsExist(close, [key]) || analysis.jsonKeyIsExist(openSearchInfo, [key])) {
          continue
        }
        const info = linkPoint[key]
        let p = null
        let mapid = nowNote.mapid
        let isLinkPoint = false
        if (info['start'] == key) {
          p = info['startP']
          if (analysis.jsonKeyIsExist(info, ['startMapid'])) {
            mapid = info['startMapid']
            isLinkPoint = true
          }
        }
        if (info['end'] == key) {
          p = info['endP']
          if (analysis.jsonKeyIsExist(info, ['endMapid'])) {
            mapid = info['endMapid']
            isLinkPoint = true
          }
        }
        if (p == null) {
          continue
        }
        const startDis = getMapSpace({
          mapid: mapid
        })
        const endDis = getMapSpace(endInfo)
        const p1 = {
          x: p.x - startDis,
          y: p.z - startDis
        }
        const p2 = {
          x: endInfo.x - endDis,
          y: endInfo.y - endDis
        }

        const infoDis = getDis(p1, p2) + Math.abs((endDis - startDis))
        const d = info.dis + infoDis
        searchInfo[key] = {
          parentId: nowNote.id,
          dis: info.dis + parentInfo.dis,
          expectDis: infoDis,
          mapid: mapid
        }
        openSearchInfo[key] = '1'
        if (d <= minDis) {
          pointDis = info.dis
          expectDis = infoDis
          minDis = d
          niceKey = key
          niceMapid = mapid
        }
      }
      close[nowNote.id] = '1'
      delete openSearchInfo[nowNote.id]

      if (niceKey == '') {
        return resetNiceKey(niceKey, niceMapid)
      }

      // 寻找后判断是否距离终点更近了 如果比预期更远了 则更换open和key
      const lastDis = parentInfo.expectDis + parentInfo.dis
      const nowExcept = searchInfo[niceKey]
      const nowDis = nowExcept.expectDis + nowExcept.dis
      if (nowDis > lastDis) {
        // 居然更远了!
        const res = resetNiceKey(niceKey, niceMapid)
        if (res.result) {
          delete openSearchInfo[res.niceKey]
        }
        return res
      }
      delete openSearchInfo[niceKey]
      return {
        result: true,
        niceKey: niceKey,
        mapid: niceMapid
      }
    }

    function findOpenPath(id) {
      function findParentInfo(open, parentId) {
        open.push(parentId)
        const info = searchInfo[parentId]
        const newParentId = info.parentId
        if (newParentId == '') {
          return
        }
        findParentInfo(open, newParentId)
      }

      if (analysis.jsonKeyIsExist(searchInfo, [id]) == false) {
        return {
          result: false,
          open: []
        }
      }
      const list = []
      list.push(id)
      const info = searchInfo[id]
      findParentInfo(list, info.parentId)
      return {
        result: true,
        isEnd: true,
        open: list
      }
    }

    function findWay(nowNote, endInfo, close, leadInfo) {
      if (analysis.jsonKeyIsExist(searchInfo, [endInfo.id]) == true) {
        // 找到终点
        return findOpenPath(endInfo.id)
      }

      const findCount = Object.keys(close).length
      if (endWayCount == findCount) {
        return {
          result: false
        }
      }
      const findNiceRes = getBestLinkInfo(nowNote, endInfo, close)
      if (findNiceRes.result == false) {
        // 没有一个节点符合 终止
        return {
          result: false
        }
      } else {
        const mapid = findNiceRes.mapid
        const niceKey = findNiceRes.niceKey
        const nextNote = leadInfo[mapid][niceKey]
        return findWay(nextNote, endInfo, close, leadInfo)
      }
    }

    // 开始计算路径
    function returnAnalaysisLeadWay(config, close /* 记录已被考察过的节点 */) {
      return new Promise((resolve) => {
        const leadInfo = config.leadInfo
        const start = config.start
        const end = config.end
        if (analysis.jsonKeyIsExist(leadInfo, [start.mapid, end.mapid]) == false) {
          resolve({
            result: false
          })
          return
        }
        const startMapInfo = leadInfo[start.mapid]
        const endMapInfo = leadInfo[end.mapid]
        if (analysis.jsonKeyIsExist(startMapInfo, [start.id]) == false || analysis.jsonKeyIsExist(endMapInfo, [end
          .id
        ]) == false) {
          resolve({
            result: false
          })
          return
        }
        let nowNote = {} // 当前节点
        const startInfo = startMapInfo[start.id]
        nowNote = startInfo

        const startDis = getMapSpace(start)
        const endDis = getMapSpace(end)
        const p1 = {
          x: start.x - startDis,
          y: start.y - startDis
        }
        const p2 = {
          x: end.x - endDis,
          y: end.y - endDis
        }
        const infoDis = getDis(p1, p2) + Math.abs((endDis - startDis))

        searchInfo[start.id] = {
          parentId: '',
          dis: 0,
          expectDis: infoDis,
          mapid: start['mapid']
        }
        const endInfo = endMapInfo[end.id]

        const res = findWay(nowNote, endInfo, close, leadInfo)
        let wayResult = {
          isLink: false
        }
        const isSameMap = start['mapid'] == end['mapid']
        if (res.result) {
          wayResult = getLeadPathByNote(res, leadInfo, config)
        }
        resolve({
          result: res.result,
          wayResult: wayResult,
          isSameMap: isSameMap
        })
      })
    }

    /* ------------------------------------------- */

    return returnAnalaysisLeadWay(config, close)
  }

  function getLeadPathByNote(res, leadInfo, config) {
    /* -------------------------- */
    function returnPointType(info) {
      let type = leadPointType.normal
      if (analysis.jsonKeyIsExist(info, ['type'])) {
        type = info['type']
      }
      return type
    }

    function returnPoint(info) {
      const type = returnPointType(info)
      const mapid = info['mapid']
      const id = info['id']
      let height = 0
      if (analysis.jsonKeyIsExist(info, ['pHeight'])) {
        height = info['pHeight']
      }
      let imgs = []
      if (analysis.jsonKeyIsExist(info, ['imgs']) && info['imgs'].length > 0) {
        imgs = info['imgs']
      }
      const dis = getMapSpace(info)
      const x = parseFloat((info.x - dis).toFixed(6))
      const z = parseFloat((info.y - dis).toFixed(6))
      const p = {
        x: x,
        y: 2,
        z: z,
        mapid: mapid,
        type: type,
        id: id,
        height: height,
        url: '',
        room: analysis.jsonKeyIsExist(info, ['room']) ? info.room : {
          areas: [],
          areaid: '',
          color: '',
          height: 0
        },
        inMapId: info.inMapId
      }
      if (imgs.length > 0) {
        p['imgs'] = imgs
      }
      return p
    }

    // 开始分楼层
    function leadPathGoFloor(indexs, linkWayInfo, linkMap, info, mapOrdList, idOrder) {
      if (indexs.length > 1) {
        // 大于1的时候才返回
        const newIndexs = indexs
        if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
          const arr = linkWayInfo[linkMap]
          arr.push({
            path: newIndexs,
            dis: 0,
            start: newIndexs[0],
            end: newIndexs[newIndexs.length - 1],
            index: arr.length,
            orderIndex: mapOrdList.length
          })
        } else {
          linkWayInfo[linkMap] = []
          linkWayInfo[linkMap].push({
            index: 0,
            orderIndex: mapOrdList.length,
            path: newIndexs,
            dis: 0,
            start: newIndexs[0],
            end: newIndexs[newIndexs.length - 1]
          })
        }
        if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
          let from = ''
          let to = ''
          if (mapOrdList.length == 0) {
            if (analysis.jsonKeyIsExist(info, ['link'])) {
              from = info['link']
            }
          } else {
            const lastOrder = mapOrdList[mapOrdList.length - 1]
            to = lastOrder.from
            if (analysis.jsonKeyIsExist(info, ['link'])) {
              from = info['link']
            }
          }
          mapOrdList.push({
            mapid: linkMap,
            index: linkWayInfo[linkMap].length - 1,
            id: linkMap + '-' + (linkWayInfo[linkMap].length - 1),
            from: from,
            to: to,
            idOrder: idOrder
          })
          return {
            result: true
          }
        }
      }
      return {
        result: false
      }
    }

    // 判断实景图
    function findRealtiyImage(endP, startP) {
      if (analysis.jsonKeyIsExist(endP, ['imgs']) == false) {
        return
      }
      const imgs = endP['imgs']
      const v = new THREE.Vector3(endP.x - startP.x, 0, endP.z - startP.z)
      for (let i = 0; i < imgs.length; i++) {
        const info = imgs[i]
        const imageV = new THREE.Vector3(endP.x - info.x, 0, endP.z - info.z)
        const angle = v.angleTo(imageV)
        if (angle <= Math.PI / 4.0) {
          endP['img'] = {
            url: info.url,
            title: info.title
          }
          break
        }
      }
    }

    function leadPathAtSameMap(indexs, point) {
      if (point.type == leadPointType.futi || point.type == leadPointType.dianti || point.type == leadPointType.louti) {
        point['isInRoom'] = false
      }
      if (indexs.length >= 1) {
        const lastP = indexs[indexs.length - 1]
        if (lastP.x == point.x && lastP.z == point.z) {
          // 同一个点
        } else {
          findRealtiyImage(lastP, point)
          // if (point.type == leadPointType.start && indexs.length >= 2) {
          //   let p = indexs[indexs.length - 2];
          //   judgeStartPoint(point, lastP, p, indexs);
          // }
          indexs.push(point)
        }
      } else {
        indexs.push(point)
      }
    }

    // 终点
    function endPath(indexs, linkWayInfo, json1, mapOrdList) {
      // 楼层终止 对部分路径进行优化
      // if (indexs.length >= 3) {
      //   let endPoint = indexs[0];
      //   let p1 = indexs[1];
      //   let p2 = indexs[2];
      //   judgeEndPoint(endPoint, p1, p2, indexs);
      // }
      const newIndexs = indexs
      if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
        const arr = linkWayInfo[linkMap]
        arr.push({
          index: arr.length,
          orderIndex: mapOrdList.length,
          path: newIndexs,
          dis: 0,
          start: newIndexs[0],
          end: newIndexs[newIndexs.length - 1]
        })
      } else {
        linkWayInfo[linkMap] = []
        linkWayInfo[linkMap].push({
          index: 0,
          orderIndex: mapOrdList.length,
          path: newIndexs,
          dis: 0,
          start: newIndexs[0],
          end: newIndexs[newIndexs.length - 1]
        })
      }
      if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
        let from = ''
        let to = ''
        if (mapOrdList.length == 0) {
          if (analysis.jsonKeyIsExist(json1, ['link'])) {
            from = json1['link']
          }
        } else {
          const lastOrder = mapOrdList[mapOrdList.length - 1]
          to = lastOrder.from
          if (analysis.jsonKeyIsExist(json1, ['link'])) {
            from = json1['link']
          }
        }
        mapOrdList.push({
          mapid: linkMap,
          index: linkWayInfo[linkMap].length - 1,
          id: linkMap + '-' + (linkWayInfo[linkMap].length - 1),
          from: from,
          to: to,
          idOrder: idOrder
        })
      }
    }

    function judgePoInfo(poInfo, linkWayInfo, mapDataInfo, po) {
      // 获取坡度信息
      function getPoMsg(first, next) {
        let height1 = 0
        let height2 = 0
        if (analysis.jsonKeyIsExist(first, ['height'])) {
          height1 = first.height
        } else if (analysis.jsonKeyIsExist(first, ['pHeight'])) {
          height1 = first.pHeight
        }
        if (analysis.jsonKeyIsExist(next, ['height'])) {
          height2 = next.height
        } else if (analysis.jsonKeyIsExist(next, ['pHeight'])) {
          height2 = next.pHeight
        }
        const height = Math.abs(height1 - height2)
        const xCount = first.x - next.x
        const yCount = first.z - next.z
        const dis = Math.sqrt(xCount * xCount + yCount * yCount)
        if (height == 0) {
          return {
            result: false,
            x: 0,
            z: 0,
            msg: ''
          }
        }
        const tan = height / dis
        const angle = Math.atan(tan) * 180 / Math.PI
        let msg = height1 < height2 ? '上坡 ' : '下坡 '
        if (angle > 0 && angle <= 15) {
          msg = msg + ''
        } else if (angle > 15 && angle <= 45) {
          // msg = msg + '坡度较陡'
        } else {
          // msg = msg + '坡度非常陡'
        }
        return {
          result: true,
          x: (first.x + next.x) / 2.0,
          z: (first.z + next.z) / 2.0,
          msg: msg
        }
      }

      function findPoInfo(link, dataInfo, existInfo) {
        const linkPoint = link['linkPoint']
        for (const key in linkPoint) {
          const info = dataInfo[key]
          if (info.type == leadPointType.po && (link.x != info.x || link.y != info.y)) {
            const key1 = link.id + info.id
            const key2 = info.id + link.id
            if (analysis.jsonKeyIsExist(existInfo, [link.mapid]) && analysis.jsonKeyIsExist(existInfo[link.mapid], [
              key1, key2
            ])) {
              continue
            }
            const infoSpace = getMapSpace(info)
            const linkSpace = getMapSpace(link)
            const x1 = link.x - linkSpace
            const y1 = link.y - linkSpace

            const x2 = info.x - linkSpace
            const y2 = info.y - linkSpace

            const maxX = analysis.findMax([x1, x2])
            const minX = analysis.findMin([x1, x2])
            const maxZ = analysis.findMax([y1, y2])
            const minZ = analysis.findMin([y1, y2])
            const leftUp = {
              x: minX,
              z: minZ
            }
            const rightDown = {
              x: maxX,
              z: maxZ
            }
            if (leftUp.x == rightDown.x) {
              leftUp.x = leftUp.x - Math.abs(parseFloat(link.W / 2.0))
              rightDown.x = rightDown.x + Math.abs(parseFloat(link.W / 2.0))
            } else if (leftUp.z == rightDown.z) {
              leftUp.z = leftUp.z - Math.abs(parseFloat(link.H / 2.0))
              rightDown.z = rightDown.z + Math.abs(parseFloat(link.H / 2.0))
            } else {
              leftUp.x = leftUp.x - Math.abs(parseFloat(link.W / 2.0))
              rightDown.x = rightDown.x + Math.abs(parseFloat(link.W / 2.0))
              leftUp.z = leftUp.z - Math.abs(parseFloat(link.H / 2.0))
              rightDown.z = rightDown.z + Math.abs(parseFloat(link.H / 2.0))
            }
            const paths = linkWayInfo[link.mapid]
            for (let l = 0; l < paths.length; l++) {
              const path = paths[l].path
              for (let j = 0; j < path.length - 1; j++) {
                const p1 = path[j]
                const p2 = path[j + 1]
                const isCorss = analysis.judgeIsCorssWall([{
                  x: p1.x,
                  z: p1.z
                }, {
                  x: p2.x,
                  z: p2.z
                }], {
                  areas: [
                    [leftUp.x, 0, leftUp.z],
                    [leftUp.x, 0, rightDown.z],
                    [rightDown.x, 0, rightDown.z],
                    [rightDown.x, 0, leftUp.z]
                  ]
                })
                if (isCorss) {
                  // 经过该坡度区域
                  const copyLink = JSON.parse(JSON.stringify(link))
                  copyLink.z = copyLink.y - linkSpace
                  copyLink.x = copyLink.x - linkSpace

                  const copyInfo = JSON.parse(JSON.stringify(info))
                  copyInfo.z = copyInfo.y - infoSpace
                  copyInfo.x = copyInfo.x - infoSpace

                  const goX = p1.x - p2.x
                  const goZ = p1.z - p2.z
                  const go = Math.abs(goX) > Math.abs(goZ) ? {
                    x: goX / Math.abs(goX),
                    z: 0
                  } : {
                    x: 0,
                    z: goZ / Math.abs(goZ)
                  }
                  let first = copyLink
                  let next = copyInfo
                  if (go.x == 0) {
                    if (go.z > 0) {
                      first = copyLink.z > copyInfo.z ? copyInfo : copyLink
                      next = copyLink.z > copyInfo.z ? copyLink : copyInfo
                    } else {
                      next = copyLink.z > copyInfo.z ? copyInfo : copyLink
                      first = copyLink.z > copyInfo.z ? copyLink : copyInfo
                    }
                  } else {
                    if (go.x > 0) {
                      first = copyLink.x > copyInfo.x ? copyInfo : copyLink
                      next = copyLink.x > copyInfo.x ? copyLink : copyInfo
                    } else {
                      next = copyLink.x > copyInfo.x ? copyInfo : copyLink
                      first = copyLink.x > copyInfo.x ? copyLink : copyInfo
                    }
                  }

                  const poMsg = getPoMsg(first, next)
                  if (poMsg.result == true) {
                    const correct = analysis.setPointToLine(poMsg.x, poMsg.z, [{
                      x: p1.x,
                      z: p1.z
                    }, {
                      x: p2.x,
                      z: p2.z
                    }])
                    poMsg.z = correct.z
                    poMsg.x = correct.x
                    if (analysis.jsonKeyIsExist(poInfo, [link.mapid])) {
                      const poList = poInfo[link.mapid]
                      let isExist = false
                      for (let k = 0; k < poList.length; k++) {
                        const po = poList[k]
                        if (po.x == poMsg.x && po.z == poMsg.z) {
                          isExist = true
                          break
                        }
                      }
                      if (isExist == false) {
                        poList.push(poMsg)
                      }
                    } else {
                      const poList = []
                      poList.push(poMsg)
                      poInfo[link.mapid] = poList
                    }
                  }
                  let record = {}
                  if (analysis.jsonKeyIsExist(existInfo, [link.mapid])) {
                    record = existInfo[link.mapid]
                  } else {
                    existInfo[link.mapid] = record
                  }
                  const key = link.id + info.id
                  record[key] = 1
                  break
                }
              }
            }
          }
        }
      }

      // 防止丢失坡度信息
      const existInfo = {}
      for (const mapid in linkWayInfo) {
        if (analysis.jsonKeyIsExist(po, [mapid])) {
          const mapPo = po[mapid]
          const dataInfo = mapDataInfo[mapid]
          for (const dataKey in mapPo) {
            const link = dataInfo[dataKey]
            findPoInfo(link, dataInfo, existInfo)
          }
        }
      }
    }

    // 查找线段在区域的位置
    function getAreasPoint(zoneInfo, startP, endP, zoneIdInfo) {
      let startList = []
      let endList = []
      const line = analysis.createLine(startP, endP)
      for (let i = 0; i < zoneInfo.length; i++) {
        const areaInfo = zoneInfo[i]
        const areas = areaInfo['areas']
        let scaleList = []
        scaleList.push({
          x: startP.x,
          y: startP.z,
          scale: 0
        })
        for (let j = 0; j < areas.length; j++) {
          const p = areas[j]
          const np = areas[(j + 1 >= areas.length ? 0 : j + 1)]
          const res = analysis.twoLineXiangjiaoPoint(p[0], p[2], np[0], np[2], startP.x, startP.z, endP.x, endP.z)
          if (res.result == false) {
            continue
          }
          const scale1 = analysis.updateLineAndclosestPointToPointParameter(line, res.x, 0, res.y, {
            x: p[0],
            y: 0,
            z: p[2]
          }, {
            x: np[0],
            y: 0,
            z: np[2]
          })
          const scale2 = analysis.updateLineAndclosestPointToPointParameter(line, res.x, 0, res.y, startP, endP)
          if (isNaN(scale2) || isNaN(scale1)) {
            continue
          }
          if (scale1 < 0 || scale1 > 1 || scale2 < 0 || scale2 > 1) {
            continue
          }
          scaleList.push({
            x: res.x,
            y: res.y,
            scale: scale1
          })
        }

        scaleList.push({
          x: endP.x,
          y: endP.z,
          scale: 1
        })

        // 按照scale由小到大排序
        scaleList = scaleList.sort((a, b) => {
          return a.scale - b.scale
        })
        for (let i = 0; i < scaleList.length - 1; i++) {
          const info = scaleList[i]
          const nextInfo = scaleList[i + 1]
          const center = {
            x: (info.x + nextInfo.x) / 2.0,
            y: (info.y + nextInfo.y) / 2.0
          }
          if (analysis.isInPolygon([center.x, 0, center.y], areas)) {
            startList.push({
              title: areaInfo['title'],
              id: areaInfo['areaid'],
              start: info.scale,
              end: nextInfo.scale,
              startP: {
                x: info.x,
                y: 2,
                z: info.y
              },
              endP: {
                x: nextInfo.x,
                y: 2,
                z: nextInfo.y
              }
            })
            endList.push({
              title: areaInfo['title'],
              id: areaInfo['areaid'],
              start: info.scale,
              end: nextInfo.scale,
              startP: {
                x: info.x,
                y: 2,
                z: info.y
              },
              endP: {
                x: nextInfo.x,
                y: 2,
                z: nextInfo.y
              }
            })
            i = i + 1
            zoneIdInfo[areaInfo['areaid']] = '1'
          }
        }
      }

      // 按照scale由小到大排序
      endList = endList.sort((a, b) => {
        return a.start - b.start
      })
      startList = startList.sort((a, b) => {
        return a.start - b.start
      })
      return {
        start: startList,
        end: endList
      }
    }

    // 直角画圆角
    function createRadiusPath(path) {
      if (manager.pathUseRadius == false) {
        return path;
      }
      const newPath = []
      const defaultN = new THREE.Vector3(0, 1, 0)
      const radiusCount = 6
      const maxRadiusAngle = 160 / 180 * Math.PI
      const minRadiusAngle = 20 / 180 * Math.PI

      function getRadiusPoint(newPath, p1, p2, p3) {
        const fixNum = 6
        const x1 = parseFloat((p1.x - p2.x).toFixed(fixNum))
        const y1 = parseFloat((p1.y - p2.y).toFixed(fixNum))
        const z1 = parseFloat((p1.z - p2.z).toFixed(fixNum))

        const x2 = parseFloat((p3.x - p2.x).toFixed(fixNum))
        const y2 = parseFloat((p3.y - p2.y).toFixed(fixNum))
        const z2 = parseFloat((p3.z - p2.z).toFixed(fixNum))

        const v1 = new THREE.Vector3(x1, y1, z1)
        const v2 = new THREE.Vector3(x2, y2, z2)
        const angle = v1.angleTo(v2)
        if (angle >= maxRadiusAngle || angle <= minRadiusAngle) {
          newPath.unshift(p2)
          return
        }
        const radius = 1 * 5
        const dis1 = v1.length()
        const dis2 = v2.length()
        const minDis = dis1 > dis2 ? dis2 : dis1
        if (minDis <= radius) {
          newPath.unshift(p2)
          return
        }
        const halfAngle = angle / 2.0
        const sin = Math.sin(halfAngle)
        let getDis = 0
        if (sin != 0) {
          getDis = radius / sin
        }
        const m = new THREE.Matrix3()
        const n = new THREE.Vector3(0, 0, 0).crossVectors(v1, v2).normalize()
        n.x = Math.abs(n.x)
        n.y = Math.abs(n.y)
        n.z = Math.abs(n.z)
        m.elements = [
          n.x, n.y, n.z,
          v1.x, v1.y, v1.z,
          v2.x, v2.y, v2.z
        ]
        const r = m.determinant()
        let d = 1
        if (r != 0) {
          d = r / Math.abs(r)
        }
        const v3 = v1.clone().applyAxisAngle(defaultN, halfAngle * d).normalize()
        const v4 = v3.clone().multiplyScalar(getDis)
        const radiusPoint = v4.clone().add(new THREE.Vector3(p2.x, p2.y, p2.z))
        const v5 = v3.clone().negate().applyAxisAngle(defaultN, (Math.PI / 2.0 - halfAngle) * d).multiplyScalar(radius)
        const totalAngle = Math.PI - angle
        const rotateA = totalAngle / radiusCount
        for (let i = 0; i < radiusCount; i++) {
          const A = i * rotateA * d * -1
          const v6 = v5.clone().applyAxisAngle(defaultN, A)
          const p = radiusPoint.clone().add(v6)
          const copyInfo = JSON.parse(JSON.stringify(p2))
          copyInfo.x = parseFloat(p.x.toFixed(6))
          copyInfo.z = parseFloat(p.z.toFixed(6))
          if (i != 0 && i != radiusCount - 1) {
            copyInfo['radius'] = '1'
          }
          newPath.unshift(copyInfo)
        }
      }

      if (path.length >= 3) {
        for (let i = path.length - 1; i > 1; i--) {
          const p1 = newPath.length == 0 ? path[i] : newPath[0]
          const p2 = path[i - 1]
          const p3 = path[i - 2]
          if (i == path.length - 1) {
            newPath.unshift(p1)
          }
          getRadiusPoint(newPath, p1, p2, p3)

          if (i == 2) {
            newPath.unshift(p3)
          }
        }
      } else if (path.length == 2) {
        for (let i = path.length - 1; i > 0; i--) {
          const p1 = newPath.length == 0 ? path[i] : newPath[0]
          const p2 = path[i - 1]
          newPath.unshift(p1)
          newPath.unshift(p2)
        }
      } else if (path.length == 1) {
        const p1 = path[0]
        const p2 = path[0]
        newPath.unshift(p1)
        newPath.unshift(p2)
      }

      return newPath
    }

    function updatePathInfo(linkWayInfo, zoneInfo) {
      const zoneIdInfo = {} // 记录所在区域
      for (const mapid in linkWayInfo) {
        const pathInfos = linkWayInfo[mapid]
        for (let i = 0; i < pathInfos.length; i++) {
          const info = pathInfos[i]
          const path = info.path
          const newPath = createRadiusPath(path)
          let dis = 0 // 计算路径距离
          let zoneList = []
          const paths = []
          if (analysis.jsonKeyIsExist(zoneInfo, [mapid])) {
            zoneList = zoneInfo[mapid]
          }

          for (let j = 0; j < newPath.length - 1; j++) {
            const p1 = newPath[j]
            const p2 = newPath[j + 1]
            dis = dis + Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
            const res = getAreasPoint(zoneList, p1, p2, zoneIdInfo)
            paths.push(res.end)
          }
          info.path = newPath
          info.dis = dis
          info.pathScale = paths
        }
      }
      return zoneIdInfo
    }

    /* -------------------------- */

    const open = res.open
    let linkMap = ''
    const linkWayInfo = {}
    const poInfo = {}
    const mapOrdList = [] // 走地图的顺序
    let idOrder = {}
    let indexs = []

    // for (let i = open.length - 1; i >= 0; i--) {
    for (let i = 0; i < open.length; i++) {
      const id = open[i]
      let lead = {}
      for (const mapid in leadInfo) {
        const mapLeadInfo = leadInfo[mapid]
        if (analysis.jsonKeyIsExist(mapLeadInfo, [id]) == false) {
          continue
        }
        lead = mapLeadInfo[id]
        break
      }

      const p = returnPoint(lead)
      const mapid = p.mapid
      idOrder[lead.id] = {
        mapid: mapid,
        id: lead.id
      }
      if (linkMap == '') {
        // 开始的信息
        linkMap = mapid
      }
      if (linkMap != mapid) {
        // 开始分楼层
        const res = leadPathGoFloor(indexs, linkWayInfo, linkMap, lead, mapOrdList, idOrder)
        if (res.result) {
          idOrder = {}
        }
        indexs = []
        linkMap = mapid
      }
      leadPathAtSameMap(indexs, p)
      if (i == open.length - 1) {
        endPath(indexs, linkWayInfo, lead, mapOrdList)
      }
    }

    const zoneInfo = config.zoneInfo
    judgePoInfo(poInfo, linkWayInfo, leadInfo, leadPointInfo.poInfo)
    const zoneIdInfo = updatePathInfo(linkWayInfo, zoneInfo)
    let dis = 0
    for (const mapid in linkWayInfo) {
      const paths = linkWayInfo[mapid]
      for (let i = 0; i < paths.length; i++) {
        const pathInfo = paths[i]
        dis = dis + pathInfo.dis
      }
    }
    return {
      isLink: true,
      dis: dis,
      indexs: linkWayInfo,
      poInfo: poInfo,
      mapOrder: mapOrdList,
      zoneIdInfo: zoneIdInfo,
      isSameMap: mapOrdList.length <= 1
    }
  }

  aStart.getReality = function(datas, nameInfo) {
    for (let i = 0; i < datas.length; i++) {
      const data = datas[i]
      const shouldUpdate = (analysis.jsonKeyIsExist(data, ['linkPointId', 'shootX', 'shootY', 'shootZ', 'enable', 'url',
        'desc'
      ]) && data['shootX'] != '' && data['shootY'] != '' && data['shootZ'] != '' && data['url'] != '' && data[
        'enable'] == 1)
      if (shouldUpdate == false) {
        continue
      }
      const mapId = data.mapId + ''
      if (analysis.jsonKeyIsExist(leadPointInfo.leadInfo, [mapId]) == false) {
        continue
      }
      const getSpace = getMapSpace({
        mapid: mapId
      })
      const linkPointId = data.linkPointId
      const leadInfo = leadPointInfo.leadInfo[mapId]
      const cube = leadInfo[linkPointId]
      if (typeof cube == 'undefined') {
        continue
      }
      let list = []
      if (analysis.jsonKeyIsExist(cube, ['imgs'])) {
        list = cube['imgs']
      } else {
        cube['imgs'] = list
      }
      list.push({
        id: data['id'],
        url: data['url'],
        title: data['desc'],
        x: parseFloat(data['shootX']) + getSpace,
        z: parseFloat(data['shootZ']) + getSpace
      })
    }

    for (const mapid in nameInfo) {
      if (analysis.jsonKeyIsExist(leadPointInfo.leadInfo, [mapid]) == false) {
        continue
      }
      const leadInfo = leadPointInfo.leadInfo[mapid]
      for (const leadKey in leadInfo) {
        const cube = leadInfo[leadKey]
        const shouldUpdate = (analysis.jsonKeyIsExist(cube, ['imgs']) == false && analysis.jsonKeyIsExist(cube, [
          'linkPoint'
        ]))
        if (shouldUpdate == false) {
          continue
        }
        const list = []
        cube['imgs'] = list
        for (const key in cube.linkPoint) {
          if (analysis.jsonKeyIsExist(leadInfo, [key]) == false) {
            continue
          }
          let url = ''
          let title = ''
          if (cube.type == leadPointType.louti) {
            url = 'http://img.kang-cloud.com/2dce39fb2187913e6479a72279370af9?f=png'
            title = '接近' + nameInfo[mapid] + '楼梯'
          } else if (cube.type == leadPointType.dianti) {
            url = 'http://img.kang-cloud.com/a506221bff03ea8583eed436b539f1f8?f=jpeg'
            title = '接近' + nameInfo[mapid] + '电梯'
          } else if (cube.type == leadPointType.futi) {
            url = 'http://img.kang-cloud.com/3cc549edcc1379db74f42bde4ad50fa6?f=jpg'
            title = '接近' + nameInfo[mapid] + '扶梯'
          } else {
            break
          }
          const link = leadInfo[key]
          list.push({
            id: '',
            url: url,
            title: title,
            x: parseFloat(link.x),
            z: parseFloat(link.y)
          })
          break
        }
      }
    }
  }

  const gaodeKey = '0dd702228dee6e3b503354eb5d213634'

  function ajaxObject() {
    let xmlHttp
    try {
      // Firefox, Opera 8.0+, Safari
      xmlHttp = new XMLHttpRequest()
    } catch (e) {
      // Internet Explorer
      try {
        xmlHttp = new ActiveXObject('Msxml2.XMLHTTP')
      } catch (e) {
        try {
          xmlHttp = new ActiveXObject('Microsoft.XMLHTTP')
        } catch (e) {
          alert('您的浏览器不支持AJAX！')
          return false
        }
      }
    }
    return xmlHttp
  }

  function getGaoDePath(response) {
    if (analysis.jsonKeyIsExist(response, ['route']) == false) {
      return {
        result: false
      }
    }
    const route = response['route']
    if (analysis.jsonKeyIsExist(route, ['paths']) == false) {
      return {
        result: false
      }
    }
    const paths = route['paths']
    if (paths.length == 0) {
      return {
        result: false
      }
    }
    const path = paths[0]
    if (analysis.jsonKeyIsExist(path, ['steps']) == false) {
      return {
        result: false
      }
    }
    const steps = path['steps']
    if (steps.length == 0) {
      return {
        result: false
      }
    }
    const coordinatePoints = []
    for (let i = 0; i < steps.length; i++) {
      const info = steps[i]
      if (analysis.jsonKeyIsExist(info, ['polyline']) == false) {
        continue
      }
      const polyline = info['polyline']
      const p = polyline.split(';')
      for (let j = 0; j < p.length; j++) {
        const coordinateStr = p[j]
        const coordinates = coordinateStr.split(',')
        if (coordinates.length != 2) {
          continue
        }
        const lat = parseFloat(coordinates[1])
        const lng = parseFloat(coordinates[0])
        if (coordinatePoints.length > 0) {
          const last = coordinatePoints[coordinatePoints.length - 1]
          if (last.lat != lat && last.lng != lng) {
            coordinatePoints.push({
              lat: lat,
              lng: lng
            })
          }
        } else {
          coordinatePoints.push({
            lat: lat,
            lng: lng
          })
        }
      }
    }
    const distance = parseFloat(path['distance'])
    let distanceMsg = ''
    if (distance >= 1000) {
      distanceMsg = parseInt(distance / 1000.0) + '公里'
    } else {
      distanceMsg = distance + '米'
    }

    const duration = parseFloat(path['duration'])
    let durationMsg = ''
    if (duration <= 60) {
      durationMsg = duration + '秒'
    } else if (duration > 60 && duration <= 60 * 60) {
      durationMsg = parseInt(duration / 60.0) + '分钟'
    } else if (duration > 60 * 60 && duration <= 60 * 60 * 24) {
      durationMsg = parseInt(duration / (60 * 60)) + '小时'
    } else if (duration > 60 * 60 * 24) {
      durationMsg = parseInt(duration / (60 * 60 * 24)) + '天'
    }

    return {
      result: true,
      coordinatePoints: coordinatePoints,
      distanceMsg: distanceMsg,
      durationMsg: durationMsg
    }
  }

  function ajaxPost(url, type) {
    return new Promise(resolve => {
      const ajax = ajaxObject()
      ajax.open('get', url, true)
      ajax.setRequestHeader('Content-Type', 'application/json;charset=utf-8')
      ajax.onreadystatechange = function() {
        if (ajax.readyState == 4) {
          if (ajax.status == 200) {
            const response = JSON.parse(ajax.responseText)
            const res = getGaoDePath(response)
            res['type'] = type
            resolve(res)
          } else {
            resolve({
              result: false
            })
          }
        }
      }
      ajax.send(null)
    })
  }

  aStart.getGaoDePath = function(config, callBack) {
    const steps = ['driving', 'walking']
    const origin = config['origin']
    const destination = config['destination']
    const promiseList = []
    for (let i = 0; i < steps.length; i++) {
      const url = 'https://restapi.amap.com/v3/direction/' + steps[i] + '?origin=' + origin + '&destination=' +
        destination + '&output=json&key=' + gaodeKey
      promiseList.push(ajaxPost(url, steps[i]))
    }
    Promise.all(promiseList).then(res => {
      callBack(res)
    })
  }

  return aStart
}
