import * as THREE from 'three/build/three.min.js'
import { createObjectAction } from '@/assets/JS/threeCreate/createObject'
import { createAnalaysisInfo } from '@/assets/JS/analaysis/dataAnalaysisInfo.js'
import { createCSS2DManager } from '@/assets/JS/threeCreate/addNewRoom/addNewRoomCss2D'

export function createNewRoom() {
  let group = new THREE.Group()

  let create = createObjectAction()

  let analysis = createAnalaysisInfo() // 构造地图解析

  let shapeDataList = []

  let tempShapeDataList = []

  let newRoomList = [] // 地面标签mesh数组

  let selectIndex = []

  let lineList = []

  let checkBlock = null

  let checkRoomBlock = null

  let css2D = createCSS2DManager()

  group.init = function(config) {
    css2D.init(config)
  }

  group.reSize = function(config) {
    css2D.reSize(config)
  }

  group.returnRender = function() {
    return css2D.returnRender()
  }

  group.updateRender = function(camera, config) {
    css2D.updateRender(camera, config)
  }

  group.updateDivPosition = function() {
    css2D.updateDivPosition()
  }

  group.updateCSS2DList = function(config) {
    css2D.updateCSS2DList(config)
  }

  group.checkRoomAction = function(callBack) {
    checkRoomBlock = callBack
  }

  group.setUpNewLabel = function(config) {
    let list = config.list
    let maxX = null
    let minX = null
    let maxZ = null
    let minZ = null
    for (let i = 0; i < list.length; i++) {
      let p = list[i]
      maxX = maxX == null ? p.x : analysis.findMax([p.x, maxX])
      maxZ = maxZ == null ? p.z : analysis.findMax([p.z, maxZ])
      minX = minX == null ? p.x : analysis.findMin([p.x, minX])
      minZ = minZ == null ? p.z : analysis.findMin([p.z, minZ])
    }
    let areas = [
      new THREE.Vector3(minX, config.y, minZ),
      new THREE.Vector3(minX, config.y, maxZ),
      new THREE.Vector3(maxX, config.y, maxZ),
      new THREE.Vector3(maxX, config.y, minZ)
    ]
    let info = {
      areas: areas,
      mapId: config.mapId,
      leftUp: { x: minX, z: minZ },
      rightDown: { x: maxX, z: maxZ },
      row: config.row,
      column: config.column,
      y: config.y
    }
    tempShapeDataList = []
    tempShapeDataList.push(createLabelGroup(info))
    config['all'] = ''
    this.showLabelSetting(config)
  }

  function createLabelGroup(config) {
    let row = parseFloat(config.row)
    let column = parseFloat(config.column)
    let leftUp = config.leftUp
    let rightDown = config.rightDown
    let width = rightDown.x - leftUp.x
    let height = rightDown.z - leftUp.z
    let widthSpace = width / (column + 1)
    let heightSpace = height / (row + 1)
    let dataList = []
    for (let i = 0; i < column; i++) {
      let list = []
      for (let j = 0; j < row; j++) {
        let info = {}
        let x = leftUp.x + widthSpace * (i + 1)
        let z = leftUp.z + heightSpace * (j + 1)
        let y = config.y
        let valid = true
        if (checkBlock != null) {
          valid = checkBlock(x, y, z)
        }
        info['valid'] = valid
        info['x'] = x
        info['y'] = y
        info['z'] = z
        info['id'] = -1
        list.push(info)
      }
      dataList.push(list)
    }
    let dataInfo = {}
    dataInfo['mapId'] = config.mapId
    dataInfo['areas'] = config.areas
    dataInfo['dataList'] = dataList
    dataInfo['y'] = config.y
    dataInfo['id'] = -1
    return dataInfo
  }

  group.getShapeList = function(list, config) {
    if (typeof (config.shapeList) != 'undefined') {
      shapeDataList = list
    } else if (typeof (config.temp) != 'undefined') {
      tempShapeDataList = list
    }
  }

  group.returnDataList = function(config) {
    let list = []
    if (analysis.jsonKeyIsExist(config, ['all'])) {
      list = tempShapeDataList.concat(shapeDataList)
    } else if (analysis.jsonKeyIsExist(config, ['temp'])) {
      list = tempShapeDataList
    } else {
      list = shapeDataList
    }
    return listFillter(JSON.parse(JSON.stringify(list)))
  }


  group.returnPlaneList = function(config) {
    return planeList
  }

  let planeList = []

  group.addPlane = function(config) {
    function findCloseToLabel(list, point) {
      if (analysis.jsonKeyIsExist(point, ['labelGroupId']) == false) {
        return {
          result: false
        }
      }
      let labelGroupId = point['labelGroupId']
      for (let j = 0; j < list.length; j++) {
        let info = list[j]
        if (analysis.jsonKeyIsExist(info, ['dataInfo']) == false) {
          continue
        }
        let dataInfo = info['dataInfo']
        if (dataInfo.id == '' || dataInfo.id != labelGroupId) {
          continue
        }
        let scale = info.scale.x;
        let width = info.scale.x / 2.0
        let position = info.position;
        return {
          result: true,
          width:info.scale.x / 2.0,
          position:{
            x:position.x - scale / 2.0 - width / 2.0,
            y:position.y,
            z:position.z
          },
        }
      }
      return {
        result: false
      }
    }

    let infos = config['infos']
    let mapid = typeof (config.mapid) == 'undefined' ? '' : config.mapid
    let count = 0
    for (let i = 0; i < infos.length; i++) {
      let point = infos[i]
      if (point.mapid != mapid) {
        continue
      }

      let width = 32
      if (analysis.jsonKeyIsExist(point, ['width'])) {
        width = point['width']
      }

      let position = new THREE.Vector3(parseFloat(point.x), 3, parseFloat(point.z))

      let pointset = {
        id: point.id,
        size: 1,
        position: {
          x: position.x,
          y: position.y,
          z: position.z
        },
        width: width
      }

      if (analysis.jsonKeyIsExist(point, ['image'])) {
        pointset['image'] = point['image']
      }

      if (checkRoomBlock != null) {
        checkRoomBlock(pointset.position)
      }

      let res = findCloseToLabel(newRoomList, point);
      if (res.result) {
        pointset.width = res.width;
        pointset.position = res.position;
      }
      point.x = pointset.position.x;
      point.z = pointset.position.z;
      if (count < planeList.length) {
        let cube = planeList[count]
        cube.visible = true
        create.updateCube(cube, pointset)
        cube['dataInfo'] = point
        point.uuid = cube.uuid
      } else {
        let cube = create.createNewCube(pointset)
        group.add(cube)
        cube.visible = true
        planeList.push(cube)
        cube.scale.set(width, 0.01, width)
        cube['dataInfo'] = point
      }
      count = count + 1
    }
    if (count < planeList.length) {
      for (let i = count; i < planeList.length; i++) {
        let p = planeList[i]
        p.visible = false
      }
    }
  }

  group.showLabelSetting = function(config) {
    let type = 0
    if (analysis.jsonKeyIsExist(config, ['type'])) {
      type = config['type']
    }

    let showAll = true
    if (analysis.jsonKeyIsExist(config, ['showAll'])) {
      showAll = config['showAll']
    }
    let showLine = true
    if (analysis.jsonKeyIsExist(config, ['showLine'])) {
      showLine = config['showLine']
    }

    let list = []
    if (analysis.jsonKeyIsExist(config, ['all'])) {
      list = shapeDataList.concat(tempShapeDataList)
    } else if (analysis.jsonKeyIsExist(config, ['temp'])) {
      list = tempShapeDataList
    } else {
      list = shapeDataList
    }

    let mapId = typeof (config.mapId) == 'undefined' ? '' : config.mapId
    let areasCount = 0
    let cubeCount = 0
    for (let i = 0; i < list.length; i++) {
      let info = list[i]
      if (info.mapId != mapId) {
        continue
      }
      if (showLine) {
        let areas = info.areas
        let lineSelect = selectIndex.indexOf(i) >= 0 ? true : false
        let linesetting = {
          color: lineSelect ? '#e84a5f' : '#ffaa71',
          cirle: true
        }
        for (let j = 0; j < areas.length; j++) {
          let p = areas[j]
          p.x = parseFloat(p.x)
          p.y = parseFloat(p.y)
          p.z = parseFloat(p.z)
        }
        if (areasCount < lineList.length) {
          let line = lineList[areasCount]
          create.updateLine(lineList[areasCount], areas, linesetting)
          line.visible = true
        } else {
          let line = create.createNewLine(areas, linesetting)
          group.add(line)
          lineList.push(line)
        }
        areasCount = areasCount + 1
      }

      let areas = info.areas
      let leftUp = {
        x: null,
        z: null
      }
      let rightDown = {
        x: null,
        z: null
      }
      for (let j = 0; j < areas.length; j++) {
        let p = areas[j]
        p.x = parseFloat(p.x)
        p.y = parseFloat(p.y)
        p.z = parseFloat(p.z)

        leftUp.x = leftUp.x == null ? p.x : (leftUp.x >= p.x ? p.x : leftUp.x)
        leftUp.z = leftUp.z == null ? p.z : (leftUp.z >= p.z ? p.z : leftUp.z)

        rightDown.x = rightDown.x == null ? p.x : (rightDown.x <= p.x ? p.x : rightDown.x)
        rightDown.z = rightDown.z == null ? p.z : (rightDown.z <= p.z ? p.z : rightDown.z)
      }
      let dataList = info.dataList

      let width = rightDown.x - leftUp.x
      let height = rightDown.z - leftUp.z
      let scale = 8
      if (type == 1) {
        let column = dataList.length
        let row = 0
        if (column > 0) {
          let d = dataList[0]
          row = d.length
        }

        let sW = width / (column + 1)
        let sH = height / (row + 1)

        // scale = (sW > sH ? sH : sW) * 0.95
      }

      for (let j = 0; j < dataList.length; j++) {
        let sublist = dataList[j]
        let subWidth = parseInt(width / dataList.length)
        let widthSize = isNaN(subWidth) ? scale : (subWidth >= 6 ? scale : subWidth / 6 / 1.1 * scale)
        let subHeight = parseInt(height / sublist.length)
        let heightSize = isNaN(subHeight) ? scale : (subHeight >= 6 ? scale : subHeight / 6 / 1.1 * scale)
        let finialScale = widthSize >= heightSize ? heightSize : widthSize

        for (let k = 0; k < sublist.length; k++) {
          let point = sublist[k]
          let pointset = {
            id: point.id,
            size: 1,
            position: {
              x: point.x,
              y: point.y,
              z: point.z
            },
            width: 128,
            fillColor: '#ffffff',
            onlineColor: '#1dd3bd',
            outlineColor: '#968c83'
          }
          if (analysis.jsonKeyIsExist(config, ['screenWidth'])) {
            pointset['screenWidth'] = config['screenWidth']
          }

          let id = point.id
          let visible = true
          if (analysis.jsonKeyIsExist(point, ['image'])) {
            pointset['image'] = point['image']
          } else if (typeof (config.draw) != 'undefined') {
            pointset['bgColor'] = '#1dd3bd'
            pointset['draw'] = '1'
            if (typeof (id) != 'undefined' && id != null && id > 0) {
              if (showAll == false) {
                let enable = 0
                if (analysis.jsonKeyIsExist(point, ['enable'])) {
                  enable = point['enable']
                }
                visible = enable == 1 ? true : false
              }
            } else {
              if (showAll == false) {
                visible = false
              }
            }
            let online = false
            if (analysis.jsonKeyIsExist(point, ['onLine'])) {
              online = point['onLine']
            }
            pointset['online'] = online

            let pointType = 0
            if (analysis.jsonKeyIsExist(point, ['type'])) {
              pointType = point['type']
            }

            pointset['type'] = pointType

            let name = ''
            if (analysis.jsonKeyIsExist(point, ['name'])) {
              name = point['name']
            }
            pointset['name'] = name

            if (type == 1) {
              let groupName = ''
              if (analysis.jsonKeyIsExist(point, ['groupName'])) {
                groupName = point['groupName']
              }
              pointset['groupName'] = groupName

              let bedNo = ''
              if (analysis.jsonKeyIsExist(point, ['bedNo'])) {
                bedNo = point['bedNo']
              }
              pointset['bedNo'] = bedNo

            }

            if (analysis.jsonKeyIsExist(point, ['color']) && pointType == 1) {
              pointset['bgColor'] = point['color']
            }

          } else {
            let color = '#1dd3bd'
            if (typeof (id) != 'undefined' && id != null && id > 0) {
              color = '#fff48f'
            } else {
              if (showAll == false) {
                visible = false
              }
            }
            pointset['color'] = color
          }
          if (checkRoomBlock != null) {
            checkRoomBlock(pointset.position)
          }
          if (cubeCount < newRoomList.length) {
            let cube = newRoomList[cubeCount]
            cube.visible = visible
            let shouldChange = false
            let oldSetting = cube['dataInfo']
            for (let oldSettingKey in oldSetting) {
              let value = oldSetting[oldSettingKey]
              if (oldSettingKey == 'position') {
                let p = pointset.position
                if (value.x != p.x || value.z != p.z) {
                  shouldChange = true
                  break
                }
              } else {
                if (value != pointset[oldSettingKey]) {
                  shouldChange = true
                  break
                }
              }
            }
            if (shouldChange) {
              create.updateCube(cube, pointset)
            }
            cube['dataInfo'] = pointset
            point.uuid = cube.uuid
          } else {
            let cube = create.createNewCube(pointset)
            group.add(cube)
            cube.visible = visible
            newRoomList.push(cube)
            point.uuid = cube.uuid
            cube.scale.set(finialScale, 0.01, finialScale)
            cube['dataInfo'] = pointset
          }
          cubeCount = cubeCount + 1

        }
      }
    }

    if (areasCount < lineList.length) {
      for (let i = areasCount; i < lineList.length; i++) {
        let line = lineList[i]
        line.visible = false
      }
    }
    if (cubeCount < newRoomList.length) {
      for (let i = cubeCount; i < newRoomList.length; i++) {
        let cube = newRoomList[i]
        cube.visible = false
      }
    }
  }

  group.returnShapeDataList = function() {
    return shapeDataList
  }

  group.returnClickRoomList = function() {
    return newRoomList
  }

  function listFillter(list) {
    function isExist(data, compreaData) {
      let cDataList = compreaData.dataList
      let dataList = data.dataList
      if (cDataList.length != dataList.length) {
        return false
      }
      for (let i = 0; i < cDataList.length; i++) {
        let subC = cDataList[i]
        let sub = dataList[i]
        if (subC.length != sub.length) {
          return false
        }
        for (let j = 0; j < subC.length; j++) {
          let subC_D = subC[j]
          let sub_d = sub[j]
          if (subC_D.x.toFixed(2) == sub_d.x.toFixed(2) && subC_D.z.toFixed(2) == sub_d.z.toFixed(2)) {
            return true
          }
        }
      }
      return false
    }

    let newList = []
    for (let i = 0; i < list.length; i++) {
      let data = list[i]
      if (analysis.jsonKeyIsExist(data, ['areas', 'dataList']) == false) {
        continue
      }
      let should = false
      for (let j = 0; j < newList.length; j++) {
        let existData = newList[j]
        should = isExist(existData, data)
        if (should == true) {
          break
        }
      }
      if (should == false) {
        newList.push(data)
      }
    }
    return newList
  }

  group.cancelClickRoom = function(config) {
    let getId = typeof (config.id) == 'undefined' || config.id == null ? 0 : config.id
    if (getId <= 0) {
      return {
        result: false
      }
    }
    let list = listFillter(JSON.parse(JSON.stringify(shapeDataList)))
    for (let i = 0; i < list.length; i++) {
      let areaInfo = list[i]
      let dataList = areaInfo['dataList']
      for (let j = 0; j < dataList.length; j++) {
        let arr = dataList[j]
        for (let k = 0; k < arr.length; k++) {
          let p = arr[k]
          if (p.id == getId) {
            p.id = -1
            break
          }
        }
      }
    }
    return {
      result: true,
      areaJson: JSON.parse(JSON.stringify(list))
    }
  }

  group.clickRoom = function(object, config) {
    if (object.visible == false) {
      return {
        result: false
      }
    }
    let uuid = object.uuid
    let list = listFillter(JSON.parse(JSON.stringify(shapeDataList)))
    let getId = typeof (config.id) == 'undefined' || config.id == null ? 0 : config.id
    if (getId > 0) {
      for (let i = 0; i < list.length; i++) {
        let areaInfo = list[i]
        let dataList = areaInfo['dataList']
        for (let j = 0; j < dataList.length; j++) {
          let arr = dataList[j]
          for (let k = 0; k < arr.length; k++) {
            let p = arr[k]
            if (p.id == getId) {
              p.id = -1
              break
            }
          }
        }
      }
    }
    for (let i = 0; i < list.length; i++) {
      let areaInfo = list[i]
      let dataList = areaInfo['dataList']
      for (let j = 0; j < dataList.length; j++) {
        let arr = dataList[j]
        for (let k = 0; k < arr.length; k++) {
          let p = arr[k]
          if (p.uuid != uuid) {
            continue
          }

          let useId = true
          if (analysis.jsonKeyIsExist(config, ['useId'])) {
            useId = config['useId']
          }
          if (useId) {
            let id = typeof (p.id) == 'undefined' || p.id == null ? -1 : p.id
            if (id > 0) {
              return {
                result: false
              }
            }
          }
          object['dataInfo'] = JSON.parse(JSON.stringify(p))
          p['id'] = getId
          return {
            result: true,
            data: {
              x: p.x,
              y: p.y,
              z: p.z,
              areaJson: JSON.parse(JSON.stringify(list))
            }
          }
        }
      }
    }
    return {
      result: false
    }
  }

  group.cancelSelectAreas = function() {
    selectIndex = []
  }

  group.selectShpae = function(object, config) {
    let uuid = object.uuid
    let list = shapeDataList
    for (let i = 0; i < list.length; i++) {
      let areaInfo = list[i]
      let dataList = areaInfo['dataList']
      for (let j = 0; j < dataList.length; j++) {
        let arr = dataList[j]
        for (let k = 0; k < arr.length; k++) {
          let p = arr[k]
          if (p.uuid == uuid) {
            selectArea(i, config)
            return
          }
        }
      }
    }
  }

  function selectArea(index, config) {
    let findIndex = selectIndex.indexOf(index)
    if (findIndex >= 0) {
      selectIndex.splice(findIndex, 1)
    } else {
      selectIndex.push(index)
    }
    group.showLabelSetting(config)
  }

  group.deleteSelectAreas = function(config) {
    if (selectIndex.length == 0) {
      return {
        result: false,
        msg: '未选择区域'
      }
    }
    let newList = []
    for (let i = 0; i < shapeDataList.length; i++) {
      let data = shapeDataList[i]
      let findIndex = selectIndex.indexOf(i)
      if (findIndex >= 0) {
        let dataList = data.dataList
        for (let j = 0; j < dataList.length; j++) {
          let arr = dataList[j]
          for (let k = 0; k < arr.length; k++) {
            let p = arr[k]
            let id = typeof (p.id) == 'undefined' || p.id == null ? -1 : p.id
            if (id > 0) {
              return {
                result: false,
                msg: '删除区域存在标签组'
              }
            }
          }
        }
      } else {
        newList.push(data)
      }
    }
    shapeDataList = newList
    selectIndex = []
    this.showLabelSetting(config)
    return {
      result: true,
      msg: ''
    }
  }

  group.editorSelectAreas = function(config) {
    if (selectIndex.length == 0) {
      return {
        result: false,
        msg: '未选择区域'
      }
    }

    let row = parseFloat(config.row)
    let column = parseFloat(config.column)
    let newList = []
    for (let i = 0; i < shapeDataList.length; i++) {
      let data = shapeDataList[i]
      let findIndex = selectIndex.indexOf(i)
      let revertId = [] // 需要将原来打点的id恢复到新的数据上
      if (findIndex >= 0) {
        let dataList = data.dataList
        for (let j = 0; j < dataList.length; j++) {
          let arr = dataList[j]
          for (let k = 0; k < arr.length; k++) {
            let p = arr[k]
            let id = typeof (p.id) == 'undefined' || p.id == null ? -1 : p.id
            if (id > 0) {
              return {
                result: false,
                msg: '编辑区域存在标签组'
              }

              // if (i + 1 > row || j + 1 > column) {
              //   return {
              //     result: false,
              //     msg: '编辑区域存在标签组'
              //   }
              // }
              // revertId.push({
              //   index:i,
              //   row:j,
              //   column:k,
              //   id:id,
              // })
            }
          }
        }
        // 重新计算分布
        let areas = data.areas
        let maxX = null
        let minX = null
        let maxZ = null
        let minZ = null
        for (let j = 0; j < areas.length; j++) {
          let p = areas[j]
          maxX = maxX == null ? p.x : analysis.findMax([p.x, maxX])
          maxZ = maxZ == null ? p.z : analysis.findMax([p.z, maxZ])
          minX = minX == null ? p.x : analysis.findMin([p.x, minX])
          minZ = minZ == null ? p.z : analysis.findMin([p.z, minZ])
        }
        let info = {
          areas: data.areas,
          mapId: config.mapId,
          leftUp: { x: minX, z: minZ },
          rightDown: { x: maxX, z: maxZ },
          row: row,
          column: column,
          y: config.y
        }
        let newResult = createLabelGroup(info)
        for (let j = 0; j < revertId.length; j++) {
          let revert = revertId[j]
          newResult.dataList[revert.row][revert.column]['id'] = revert['id']
        }
        data['dataList'] = newResult.dataList
        newList.push(data)
      }
      newList.push(data)
    }
    shapeDataList = newList
    selectIndex = []
    this.showLabelSetting(config)
    return {
      result: true,
      msg: ''
    }
  }

  group.add(css2D)

  return group
}
