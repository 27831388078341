import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";
import * as THREE from 'three/build/three.min.js'

import {
  createAtSameFloorDirectionShader
} from './sameFloorShader'
import {
  createFloorsDirectionShader
} from './floorsShader'

const polygonOffsetFactor = -1
const polygonOffsetUnits = -6
const depthTest = false
const polygonOffset = true
const transparent = true

function createLine() {
  const line = new THREE.BufferGeometry()

  const lineWidth = 11

  const verticalVectors = [] // 法线

  let positions = []

  const analysis = createAnalaysisInfo() // 构造地图解析

  let totalDis = 0

  let animation = true

  let colorInfo = {
    zoneColor: new THREE.Color(0x3490de),
    defaultColor: new THREE.Color(0x32e0c4)
  }

  line.setUpColorInfo = function(info) {
    colorInfo = info
  }

  const uniforms = {
    lineWidth: {
      value: lineWidth
    },
    repeatCount: {
      value: 0.0
    },
    time: {
      value: 1.0
    },
    repeat: {
      value: new THREE.Vector2(1, 1)
    },
    color: {
      value: new THREE.Color(0x9ab3f5)
    },
    positionVec2Array: {
      type: 'v2v',
      value: [new THREE.Vector2(0.1, 0.2)]
    }
  }

  const renderSetting = {
    renderScale: 0,
    renderPath: [{
      position: new THREE.BufferAttribute(new Float32Array([0.1, 0.2]), 2),
      color: new THREE.Color(0x9ab3f5)
    }],
    renderPathCount: 1
  }

  line.setUpAnimation = function(res) {
    animation = res
  }

  line.returnRenderSetting = function() {
    return renderSetting
  }

  line.updateLineGeoRenderSetting = function(geo) {
    const info = geo.returnRenderSetting()
    for (const key in info) {
      renderSetting[key] = info[key]
    }
    uniforms['renderScale'].value = renderSetting.renderScale
  }

  line.updateRender = function(config) {
    if (analysis.jsonKeyIsExist(config, ['mapOrderIndex']) == false) {
      return
    }
    const orderIndex = config['mapOrderIndex']
    const selfOrderIndex = uniforms['orderIndex'].value
    if (orderIndex > selfOrderIndex) {
      renderSetting.renderScale = 0.0
    } else if (orderIndex < selfOrderIndex) {
      renderSetting.renderScale = 1.0
    } else {
      renderSetting.renderScale = config.scale
    }
    uniforms['renderScale'].value = renderSetting.renderScale
  }

  line.updateUniforms = function(config) {
    if (analysis.jsonKeyIsExist(config, ['texture'])) {
      const textureLoader = new THREE.TextureLoader()
      const imageUrl = './static/iconImage/arrow.png'
      const texture = textureLoader.load(imageUrl)
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      uniforms['texture'] = {
        value: texture
      }
    }
    if (analysis.jsonKeyIsExist(config, ['resolution'])) {
      uniforms['resolution'] = {
        value: config.resolution
      }
    }
    if (analysis.jsonKeyIsExist(config, ['orderIndex'])) {
      uniforms['orderIndex'] = {
        value: config.orderIndex
      }
    }
    if (analysis.jsonKeyIsExist(config, ['index'])) {
      uniforms['index'] = {
        value: config.index
      }
    }
    if (analysis.jsonKeyIsExist(uniforms, ['renderScale']) == false) {
      uniforms['renderScale'] = {
        value: 0.0
      }
    }
  }

  line.returnUniforms = function() {
    return uniforms
  }

  function addPoint(vertices, positions, faces, p) {
    vertices.push(new THREE.Vector3(p.x, p.y, p.z))
    vertices.push(new THREE.Vector3(p.x, p.y, p.z))
    positions.push(p.x, p.y, p.z)
    positions.push(p.x, p.y, p.z)
    const length = vertices.length / 2.0
    if (length >= 2) {
      faces.push(length * 2 - 4, length * 2 - 3, length * 2 - 1)
      faces.push(length * 2 - 2, length * 2 - 4, length * 2 - 1)
    }
  }

  function returnNewPath(path, pathScale, zoneColor, defaultColor) {
    function updateColors(color, colors) {
      colors.push(color.r, color.g, color.b)
      colors.push(color.r, color.g, color.b)
    }

    function getPointColor(pointScale, scaleList, colors) {
      if (scaleList.length == 0) {
        colors.push(defaultColor.r, defaultColor.g, defaultColor.b)
        colors.push(defaultColor.r, defaultColor.g, defaultColor.b)
      } else {
        let changeColor = false
        for (let i = 0; i < scaleList.length; i++) {
          const info = scaleList[i]
          if (info.end == pointScale || info.start == pointScale) {
            changeColor = true
            break
          }
        }
        if (changeColor) {
          updateColors(zoneColor, colors)
        } else {
          updateColors(defaultColor, colors)
        }
      }
    }

    if (path.length == 0) {
      return {
        newPath: [],
        colors: []
      }
    }

    const move = 0.000001
    const newPath = []
    const colors = []
    for (let i = 0; i < path.length - 1; i++) {
      const p = path[i]
      const np = path[i + 1]
      let scaleList = []
      if (i < pathScale.length) {
        const scaleInfo = pathScale[i]
        if (scaleInfo.length > 0) {
          scaleList = scaleInfo
        }
      }

      const M = new THREE.Vector3(np.x - p.x, np.y - p.y, np.z - p.z).normalize()

      if (i == 0) {
        // 添加最初的点
        newPath.push(new THREE.Vector3(p.x, p.y, p.z))
        getPointColor(0, scaleList, colors)
      }
      if (i != 0) {
        // p的前一个点
        const m = M.clone().multiplyScalar(move)
        newPath.push(new THREE.Vector3(p.x, p.y, p.z).add(m))
        getPointColor(0, scaleList, colors)
      }

      // 添加区域点
      for (let j = 0; j < scaleList.length; j++) {
        const info = scaleList[j]

        if (info.start != 0) {
          const m1 = M.clone().multiplyScalar(move * -1)
          newPath.push(new THREE.Vector3(info.startP.x, p.y, info.startP.z).add(m1))
          const m2 = M.clone().multiplyScalar(move)
          newPath.push(new THREE.Vector3(info.startP.x, p.y, info.startP.z).add(m2))
          updateColors(defaultColor, colors)
          updateColors(zoneColor, colors)
        }
        if (info.end != 1) {
          const m1 = M.clone().multiplyScalar(-1 * move)
          newPath.push(new THREE.Vector3(info.endP.x, p.y, info.endP.z).add(m1))
          const m2 = M.clone().multiplyScalar(move)
          newPath.push(new THREE.Vector3(info.endP.x, p.y, info.endP.z).add(m2))
          updateColors(zoneColor, colors)
          updateColors(defaultColor, colors)
        }
      }

      if (i == path.length - 1 - 1) {
        // 添加最后一个点
        newPath.push(new THREE.Vector3(np.x, np.y, np.z))
        getPointColor(1, scaleList, colors)
      } else {
        // np的后一个点
        const s = M.clone().multiplyScalar(-1 * move)
        newPath.push(new THREE.Vector3(np.x, np.y, np.z).add(s))
        getPointColor(1, scaleList, colors)
        newPath.push(new THREE.Vector3(np.x, np.y, np.z))
        getPointColor(1, scaleList, colors)
      }
    }

    return {
      newPath: newPath,
      colors: colors
    }
  }

  // 不同楼层的geo
  line.updatePointsAtDiffrentMap = function(ps) {
    const res = returnNewPath(ps, [], colorInfo.zoneColor, colorInfo.defaultColor)
    const path = res.newPath
    if (path.length <= 1) {
      return
    }
    totalDis = 0
    const scaleDis = []
    for (let i = 0; i < path.length - 1; i++) {
      const p = path[i]
      const np = path[i + 1]
      const dis = new THREE.Vector3(p.x, p.y, p.z).distanceTo(new THREE.Vector3(np.x, np.y, np.z))
      totalDis = dis + totalDis
      if (scaleDis == 0) {
        scaleDis.push(0)
        scaleDis.push(0)
      }
      scaleDis.push(totalDis)
      scaleDis.push(totalDis)
    }
    for (let i = 0; i < scaleDis.length; i++) {
      const d = scaleDis[i]
      scaleDis[i] = d / totalDis
    }
    positions = []
    const faces = []
    const width = []
    const vertices = []
    const uvs = []
    const pathDis = []
    const pathColor = res.colors
    for (let i = 0; i < path.length; i++) {
      const p = path[i]
      addPoint(vertices, positions, faces, p)
      uvs.push(i, 0)
      uvs.push(i, 1)
      pathDis.push(totalDis)
      pathDis.push(totalDis)

      if (i > 0) {
        const defaultN = new THREE.Vector3(0, 0, 1)
        const lastP = path[i - 1]
        const s1 = new THREE.Vector3(p.x - lastP.x, p.y - lastP.y, p.z - lastP.z).normalize()
        const s2 = new THREE.Vector3(lastP.x - p.x, lastP.y - p.y, lastP.z - p.z).normalize()
        const v1 = new THREE.Vector3(0, 0, 0).crossVectors(defaultN, s1).normalize()
        const v2 = new THREE.Vector3(0, 0, 0).crossVectors(defaultN, s2).normalize()
        if (verticalVectors.length == 0) {
          verticalVectors.push(v1.x, v1.y, v1.z)
          verticalVectors.push(v2.x, v2.y, v2.z)
          width.push(lineWidth)
          width.push(lineWidth)
        }
        if (i == path.length - 1) {
          verticalVectors.push(v1.x, v1.y, v1.z)
          verticalVectors.push(v2.x, v2.y, v2.z)
          width.push(lineWidth)
          width.push(lineWidth)
        }
      }
    }
    this['attributes']['pathColor'] = new THREE.BufferAttribute(new Float32Array(pathColor), 3)
    this['attributes']['verticalVectors'] = new THREE.BufferAttribute(new Float32Array(verticalVectors), 3)
    this['attributes']['position'] = new THREE.BufferAttribute(new Float32Array(positions), 3)
    this['attributes']['width'] = new THREE.BufferAttribute(new Float32Array(width), 1)
    this['attributes']['uv'] = new THREE.BufferAttribute(new Float32Array(uvs), 2)
    this['attributes']['pathDis'] = new THREE.BufferAttribute(new Float32Array(pathDis), 1)
    this['attributes']['pathScale'] = new THREE.BufferAttribute(new Float32Array(scaleDis), 1)
    this.setIndex(faces)
  }

  // 在同一楼层的geo
  line.updatePointsAtSameMap = function(ps, pathScale) {
    const res = returnNewPath(ps, pathScale, colorInfo.zoneColor, colorInfo.defaultColor)
    const path = res.newPath
    if (path.length <= 1) {
      return
    }
    totalDis = 0
    const scaleDis = []
    for (let i = 0; i < path.length - 1; i++) {
      const p = path[i]
      const np = path[i + 1]
      const dis = new THREE.Vector3(p.x, p.y, p.z).distanceTo(new THREE.Vector3(np.x, np.y, np.z))
      totalDis = dis + totalDis
      if (scaleDis == 0) {
        scaleDis.push(0)
        scaleDis.push(0)
      }
      scaleDis.push(totalDis)
      scaleDis.push(totalDis)
    }
    for (let i = 0; i < scaleDis.length; i++) {
      const d = scaleDis[i]
      scaleDis[i] = d / totalDis
    }
    positions = []
    const faces = []
    const width = []
    const vertices = []
    const uvs = []
    const pathDis = []
    const pathColor = res.colors
    for (let i = 0; i < path.length; i++) {
      const p = path[i]
      addPoint(vertices, positions, faces, p)
      uvs.push(i, 0)
      uvs.push(i, 1)
      pathDis.push(totalDis)
      pathDis.push(totalDis)

      if (i > 0) {
        const defaultN = new THREE.Vector3(0, 1, 0)
        const lastP = path[i - 1]
        const s1 = new THREE.Vector3(p.x - lastP.x, p.y - lastP.y, p.z - lastP.z).normalize()
        const s2 = new THREE.Vector3(lastP.x - p.x, lastP.y - p.y, lastP.z - p.z).normalize()
        const v1 = new THREE.Vector3(0, 0, 0).crossVectors(defaultN, s1).normalize()
        const v2 = new THREE.Vector3(0, 0, 0).crossVectors(defaultN, s2).normalize()
        if (verticalVectors.length == 0) {
          verticalVectors.push(v1.x, v1.y, v1.z)
          verticalVectors.push(v2.x, v2.y, v2.z)
          width.push(lineWidth)
          width.push(lineWidth)
        }
        if (i == path.length - 1) {
          verticalVectors.push(v1.x, v1.y, v1.z)
          verticalVectors.push(v2.x, v2.y, v2.z)
          width.push(lineWidth)
          width.push(lineWidth)
        } else {
          const nextP = path[i + 1]
          const s3 = new THREE.Vector3(nextP.x - p.x, nextP.y - p.y, nextP.z - p.z).normalize()
          const angle = s2.angleTo(s3)

          const m = new THREE.Matrix3()
          const n = new THREE.Vector3(0, 0, 0).crossVectors(s2, s3).normalize()
          n.x = Math.abs(n.x)
          n.y = Math.abs(n.y)
          n.z = Math.abs(n.z)
          m.elements = [
            n.x, n.y, n.z,
            s2.x, s2.y, s2.z,
            s3.x, s3.y, s3.z
          ]
          const r = m.determinant()
          let d = 1
          if (r != 0) {
            d = r / Math.abs(r)
          }
          const v3 = s2.clone().applyAxisAngle(defaultN, angle * d / 2.0).normalize()
          const v4 = v3.clone().negate().normalize()
          if (d < 0) {
            verticalVectors.push(v3.x, v3.y, v3.z)
            verticalVectors.push(v4.x, v4.y, v4.z)
          } else {
            verticalVectors.push(v4.x, v4.y, v4.z)
            verticalVectors.push(v3.x, v3.y, v3.z)
          }

          const sin = Math.abs(Math.sin(angle / 2.0))
          let sinW = lineWidth
          if (sin != 0) {
            sinW = lineWidth / sin
          }
          width.push(sinW)
          width.push(sinW)
        }
      }
    }
    this['attributes']['pathColor'] = new THREE.BufferAttribute(new Float32Array(pathColor), 3)
    this['attributes']['verticalVectors'] = new THREE.BufferAttribute(new Float32Array(verticalVectors), 3)
    this['attributes']['position'] = new THREE.BufferAttribute(new Float32Array(positions), 3)
    this['attributes']['width'] = new THREE.BufferAttribute(new Float32Array(width), 1)
    this['attributes']['uv'] = new THREE.BufferAttribute(new Float32Array(uvs), 2)
    this['attributes']['pathDis'] = new THREE.BufferAttribute(new Float32Array(pathDis), 1)
    this['attributes']['pathScale'] = new THREE.BufferAttribute(new Float32Array(scaleDis), 1)
    this.setIndex(faces)
  }

  line.animation = function() {
    if (animation == false) {
      return
    }
    uniforms.time.value = uniforms.time.value + 1.0
    if (uniforms.time.value >= 1000) {
      uniforms.time.value = 0.0
    }
  }

  line.clearRenderSetting = function() {
    renderSetting.renderScale = 0.0
    uniforms['renderScale'].value = renderSetting.renderScale
  }

  return line
}

export function createLeadLine() {
  const lineGroup = new THREE.Group()

  let linePath = [] // 同一楼层的导航路线

  let lineFloorPath = [] // 不同楼层的导航路线

  const dashLinePath = []

  const zonePath = [] // 1F的区域路径

  const shaderInfo = createAtSameFloorDirectionShader()

  const floorsShaderInfo = createFloorsDirectionShader()

  lineGroup.deleteAll = function() {
    const list = lineFloorPath.concat(linePath).concat(dashLinePath).concat(zonePath)
    for (let i = 0; i < list.length; i++) {
      const line = list[i]
      if (line.parent != null) {
        lineGroup.remove(line)
      }
      line.geometry.dispose()
      line.material.dispose()
    }
    linePath = []
    lineFloorPath = []
  }

  lineGroup.animation = function() {
    const list = lineFloorPath.concat(linePath).concat(dashLinePath).concat(zonePath)
    for (let i = 0; i < list.length; i++) {
      const line = list[i]
      if (line.visible == false) {
        continue
      }
      line.geometry.animation()
    }
  }

  // 不同楼层
  lineGroup.showLeadFloorsWay = function(leadInfo, config) {
    let index = 0
    for (let i = 0; i < leadInfo.length; i++) {
      const info = leadInfo[i]
      const path = info.path
      const lineGeo = createLine()
      lineGeo.updateUniforms({
        texture: '',
        resolution: config.resolution
      })
      lineGeo.setUpAnimation(false)
      lineGeo.updatePointsAtDiffrentMap(path)
      if (index < lineFloorPath.length) {
        const line = lineFloorPath[index]
        line.geometry.dispose()
        line.geometry = lineGeo
        line.material.dispose()
        line.material = returnShaderMaterial(lineGeo)
        line.visible = true
      } else {
        createNewLine(lineGeo, lineFloorPath)
      }
      index = index + 1
    }
    if (index <= lineFloorPath.length) {
      for (let i = index; i < lineFloorPath.length; i++) {
        const line = lineFloorPath[i]
        line.visible = false
      }
    }
  }

  function hiddenUselessLine(index) {
    if (index <= linePath.length) {
      for (let i = index; i < linePath.length; i++) {
        const line = linePath[i]
        line.visible = false
      }
    }
  }

  lineGroup.hiddenUselessLine = function(index) {
    hiddenUselessLine(index)
  }

  lineGroup.showAllLeadPath = function(index, leadInfo, config) {
    let lineIndex = index
    for (let i = 0; i < leadInfo.length; i++) {
      const info = leadInfo[i]
      const path = info.path
      const pathScale = info.pathScale
      const lineGeo = createLine()
      lineGeo.updateUniforms({
        orderIndex: info.orderIndex,
        index: info.index,
        texture: '',
        resolution: config.resolution
      })
      lineGeo.updatePointsAtSameMap(path, pathScale)
      if (lineIndex < linePath.length) {
        const line = linePath[lineIndex]
        lineGeo.updateLineGeoRenderSetting(line.geometry)
        line.geometry.dispose()
        line.geometry = lineGeo
        line.material.dispose()
        line.material = returnShaderMaterial(lineGeo)
        line.visible = true
        line.position.y = config.y
      } else {
        const line = createNewLine(lineGeo, linePath)
        line.position.y = config.y
      }
      lineIndex = lineIndex + 1
    }
    return lineIndex
  }

  // 连接多楼层
  lineGroup.showLinkFloorPath = function(linkPath) {
    let index = 0
    for (let i = 0; i < linkPath.length; i++) {
      const info = linkPath[i]
      const path = info.path
      const pathScale = []
      const lineGeo = createLine()
      lineGeo.updateUniforms({
        orderIndex: 0,
        index: 0,
        texture: '',
        resolution: info.resolution
      })
      lineGeo.updatePointsAtDiffrentMap(path, pathScale)
      if (index < lineFloorPath.length) {
        const line = lineFloorPath[index]
        lineGeo.updateLineGeoRenderSetting(line.geometry)
        line.geometry.dispose()
        line.geometry = lineGeo
        line.material.dispose()
        line.material = returnShaderMaterial(lineGeo)
        line.visible = true
      } else {
        createNewLine(lineGeo, lineFloorPath)
      }
      index = index + 1
    }
    for (let i = index; i < lineFloorPath.length; i++) {
      const line = lineFloorPath[i]
      line.visible = false
    }
  }

  // 显示1楼的路径
  lineGroup.show1FPath = function(leadInfo, config) {
    let index = 0
    for (let i = 0; i < leadInfo.length; i++) {
      const info = leadInfo[i]
      const path = info.path
      const pathScale = info.pathScale
      const lineGeo = createLine()

      lineGeo.updateUniforms({
        orderIndex: info.orderIndex,
        index: info.index,
        texture: '',
        resolution: config.resolution
      })
      lineGeo.setUpColorInfo({
        zoneColor: new THREE.Color(0x000000),
        defaultColor: new THREE.Color(0x32e0c4)
      })
      lineGeo.updatePointsAtSameMap(path, pathScale)
      if (index < zonePath.length) {
        const line = zonePath[index]
        lineGeo.updateLineGeoRenderSetting(line.geometry)
        line.geometry.dispose()
        line.geometry = lineGeo
        line.material.dispose()
        line.material = returnZoneShaderMaterial(lineGeo)
        line.visible = true
        line.position.y = config.y
      } else {
        const line = createZoneLine(lineGeo, zonePath)
        line.position.y = config.y
      }
      index = index + 1
    }
    if (index <= zonePath.length) {
      for (let i = index; i < zonePath.length; i++) {
        const line = zonePath[i]
        line.visible = false
      }
    }
  }

  function returnZoneShaderMaterial(line) {
    const ShaderMaterial = new THREE.ShaderMaterial({
      polygonOffsetFactor: polygonOffsetFactor,
      polygonOffsetUnits: polygonOffsetUnits,
      polygonOffset: polygonOffset,
      depthTest: depthTest,
      transparent: transparent,
      side: THREE.DoubleSide,
      uniforms: line.returnUniforms(),
      vertexShader: shaderInfo.returnVertexShader(),
      fragmentShader: shaderInfo.returnFragmentShader()
    })
    return ShaderMaterial
  }

  function createZoneLine(line, list) {
    const mesh = new THREE.Mesh(line, returnZoneShaderMaterial(line))
    lineGroup.add(mesh)
    list.push(mesh)
    return mesh
  }

  // 显示路径
  lineGroup.showLeadPath = function(leadInfo, config) {
    function updateLine(list, index, pathList, callBack) {
      if (index >= list.length) {
        callBack({
          index: index
        })
        return
      }
      const info = list[index]
      const path = info.path
      const pathScale = info.pathScale
      const lineGeo = createLine()
      lineGeo.updateUniforms({
        orderIndex: info.orderIndex,
        index: info.index,
        texture: '',
        resolution: config.resolution
      })
      lineGeo.updatePointsAtSameMap(path, pathScale)
      if (index < pathList.length) {
        const line = pathList[index]
        lineGeo.updateLineGeoRenderSetting(line.geometry)
        line.geometry.dispose()
        line.geometry = lineGeo
        line.material.dispose()
        line.material = returnShaderMaterial(lineGeo)
        line.visible = true
        line.position.y = config.y
      } else {
        const line = createNewLine(lineGeo, pathList)
        line.position.y = config.y
      }
      setTimeout(() => {
        updateLine(list, index + 1, pathList, callBack)
      }, 50)
    }

    const updateFinishPath = (res) => {
      hiddenUselessLine(res.index)
    }

    updateLine(leadInfo, 0, linePath, updateFinishPath)
  }

  function createNewLine(line, list) {
    const mesh = new THREE.Mesh(line, returnShaderMaterial(line))
    lineGroup.add(mesh)
    list.push(mesh)
    return mesh
  }

  lineGroup.updateRender = function(config) {
    for (let i = 0; i < linePath.length; i++) {
      const line = linePath[i]
      if (line.visible == false) {
        continue
      }
      line.geometry.updateRender(config)
    }
  }

  lineGroup.clearRenderSetting = function() {
    for (let i = 0; i < linePath.length; i++) {
      const line = linePath[i]
      line.geometry.clearRenderSetting()
    }
  }

  function returnShaderMaterial(line) {
    const ShaderMaterial = new THREE.ShaderMaterial({
      needsUpdate: true,
      polygonOffset: polygonOffset,
      depthTest: depthTest,
      transparent: transparent,
      polygonOffsetFactor: polygonOffsetFactor,
      polygonOffsetUnits: polygonOffsetUnits,
      side: THREE.DoubleSide,
      uniforms: line.returnUniforms(),
      vertexShader: floorsShaderInfo.returnVertexShader(),
      fragmentShader: floorsShaderInfo.returnFragmentShader()
    })
    return ShaderMaterial
  }

  lineGroup.hiddenLine = function() {
    hiddenUselessLine(0)
  }

  return lineGroup
}
