import * as THREE from 'three/build/three.min.js'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createObjectAction
} from '../createObject'


import {createLeadLine} from "./leadLine";

export function createLeadWay() {
  const leadPointType = {
    start: 5,
    end: 4,
    louti: 1,
    dianti: 2,
    futi: 3,
    normal: 0,
    po: 99
  }

  const group = new THREE.Group()

  const leadLine = createLeadLine()

  const create = createObjectAction()

  let texts = []

  let images = []

  let nameBlock = null

  let delay = null

  const analysis = createAnalaysisInfo() // 构造地图解析

  group.deleteAll = function() {
    const list = texts.concat(images)
    for (let i = 0; i < list.length; i++) {
      const mesh = list[i]
      if (mesh.parent != null) {
        group.remove(mesh)
      }
      mesh.geometry.dispose()
      mesh.material.dispose()
    }
    texts = []
    images = []
    leadLine.deleteAll()
    if (delay != null) {
      clearTimeout(delay)
      delay = null
    }
  }

  group.hiddenStart = function() {
    for (let i = 0; i < images.length; i++) {
      const icon = images[i]
      if (icon.uuid == 'start') {
        icon.visible = false
        break
      }
    }
  }

  group.findPointNameAction = function(callBack) {
    nameBlock = callBack
  }

  function returnTypeStyle(type) {
    let str = '-null-'
    switch (type) {
      case leadPointType.louti:
        str = '楼梯'
        break
      case leadPointType.dianti:
        str = '电梯'
        break
      case leadPointType.futi:
        str = '扶梯'
        break
      default:
        break
    }
    return str
  }

  group.showFloorsLeadWay = function(leadInfo, config) {
    leadLine.showLeadFloorsWay(leadInfo, config)
    if (leadLine.parent == null) {
      group.add(leadLine)
    }
  }

  group.updateRender = function(config) {
    leadLine.updateRender(config)
  }

  group.clearRenderSetting = function() {
    leadLine.clearRenderSetting()
  }

  // 显示所有楼层的导航路线
  group.showAllLeadWayExist = function(configs, linkPath) {
    for (let i = 0; i < images.length; i++) {
      const image = images[i]
      image.visible = false
    }
    leadLine.hiddenUselessLine(0)

    let textIndex = 0
    let imageIndex = 0
    let lineIndex = 0
    for (let i = 0; i < configs.length; i++) {
      const config = configs[i]
      const leadInfo = config.leadInfo
      const poList = config.poInfo
      const res = createLeadMsg(textIndex, imageIndex, leadInfo, poList, config)
      textIndex = res.textIndex + textIndex
      imageIndex = res.imageIndex + imageIndex
      const index = leadLine.showAllLeadPath(lineIndex, leadInfo, config)
      lineIndex = index + lineIndex
    }
    hiddenUseLessPoint(textIndex, imageIndex)
    leadLine.showLinkFloorPath(linkPath)

    if (leadLine.parent == null) {
      group.add(leadLine)
    }
  }

  function createEndFloorPoint(index, nextMapid, end, y) {
    const textColor = '#ffffff'
    const bgColor = '#00C096'
    const nextName = nameBlock == null ? '' : nameBlock({
      mapid: nextMapid,
      x: end.x,
      z: end.z
    })
    const endMsg = (end.type == leadPointType.louti ? '走' : '搭乘') + returnTypeStyle(end.type) + '到' + nextName
    const setting = {
      position: {
        x: end.x,
        y: y,
        z: end.z
      },
      title: endMsg,
      type: 'important',
      bgColor: bgColor,
      textColor: textColor,
      multiple: 25,
      centerY: 0,
      mode: 0,
      scale: 0.07,
      depthTest: false
    }
    if (index >= texts.length) {
      const remark = create.createTitle(setting)
      texts.push(remark)
      group.add(remark)
    } else {
      const remark = texts[index]
      remark.visible = true
      create.updateTitle(remark, setting)
    }
    return index + 1
  }

  function createEndPoint(imageIndex, mode, end, y) {
    const setting = {
      image: './static/iconImage/zhongdian-phone.png',
      depthTest: false,
      multiple: 20,
      position: {
        x: end.x,
        y: y,
        z: end.z
      },
      centerY: 0.1414,
      mode: mode,
      scale: 0.1,
      zoomNum: 1.1,
      titleLV: 3
    }
    if (imageIndex >= images.length) {
      const icon = create.creteIconSprite(setting)
      group.add(icon)
      images.push(icon)
      icon['uuid'] = 'end'
    } else {
      const icon = images[imageIndex]
      icon.visible = true
      create.updateIconSprite(icon, setting)
      icon['uuid'] = 'end'
    }
    return imageIndex + 1
  }

  function createStartFloorPoint(textIndex, mapid, start, y) {
    const textColor = '#ffffff'
    const bgColor = '#FF7272'
    const startName = nameBlock == null ? '' : nameBlock({
      mapid: mapid,
      x: start.x,
      z: start.z
    })
    const startMsg = '从' + startName + returnTypeStyle(start.type) + '出发'
    const setting = {
      position: {
        x: start.x,
        y: y,
        z: start.z
      },
      title: startMsg,
      type: 'important',
      bgColor: bgColor,
      textColor: textColor,
      multiple: 25,
      centerY: 0,
      mode: 0,
      scale: 0.07,
      depthTest: false
    }

    if (textIndex >= texts.length) {
      const remark = create.createTitle(setting)
      texts.push(remark)
      group.add(remark)
    } else {
      const remark = texts[textIndex]
      remark.visible = true
      create.updateTitle(remark, setting)
    }
    return textIndex + 1
  }

  function createStartPoint(imageIndex, mode, start, y) {
    const setting = {
      image: './static/iconImage/qidian-phone.png',
      depthTest: false,
      multiple: 20,
      position: {
        x: start.x,
        y: y,
        z: start.z
      },
      centerY: 0.1414,
      mode: mode,
      scale: 0.1,
      zoomNum: 1.1,
      titleLV: 3
    }
    if (imageIndex >= images.length) {
      const icon = create.creteIconSprite(setting)
      group.add(icon)
      images.push(icon)
      icon['uuid'] = 'start'
    } else {
      const icon = images[imageIndex]
      icon.visible = true
      create.updateIconSprite(icon, setting)
      icon['uuid'] = 'start'
    }
    return imageIndex + 1
  }

  function createZoneText(pathScale, textIndex, y) {
    let index = textIndex
    for (let i = 0; i < pathScale.length; i++) {
      const infoList = pathScale[i]
      if (infoList.length == 0) {
        continue
      }
      for (let j = 0; j < infoList.length; j++) {
        const info = infoList[j]
        // if (info.start != 0) {
        //   let title = '前往' + info.title;
        //   let pointMsg = {
        //     x: info.startP.x, z: info.startP.z, y: y, msg: title
        //   }
        //   index = createPoInfo(index, pointMsg, y, '#3490de');
        // }
        if (info.end != 1) {
          const title = '前往' + info.title
          const pointMsg = {
            x: info.endP.x,
            z: info.endP.z,
            y: y,
            msg: title
          }
          index = createPoInfo(index, pointMsg, y, '#3490de')
        }
      }
    }
    return index
  }

  function createPoInfo(textIndex, po, y, bgColor) {
    const textColor = '#ffffff'
    const setting = {
      position: {
        x: po.x,
        y: y,
        z: po.z
      },
      title: po.msg,
      type: 'important',
      bgColor: bgColor,
      textColor: textColor,
      multiple: 25,
      centerY: 0,
      mode: 0,
      scale: 0.07,
      depthTest: false
    }

    if (textIndex >= texts.length) {
      const remark = create.createTitle(setting)
      texts.push(remark)
      group.add(remark)
    } else {
      const remark = texts[textIndex]
      remark.visible = true
      create.updateTitle(remark, setting)
    }
    return textIndex + 1
  }

  function createLeadMsg(tIndex, iIndex, leadInfo, poList, config) {
    let textIndex = tIndex
    let imageIndex = iIndex

    for (let i = 0; i < poList.length; i++) {
      const po = poList[i]
      textIndex = createPoInfo(textIndex, po, config.y, '#FF7272')
    }
    let zonePath = []
    if (analysis.jsonKeyIsExist(config, ['zonePath'])) {
      zonePath = config['zonePath']
    }
    for (let i = 0; i < zonePath.length; i++) {
      const info = zonePath[i]
      const path = info.path
      if (path.length == 0) {
        continue
      }
      const pathScale = info.pathScale
      textIndex = createZoneText(pathScale, textIndex, config.y)
    }
    for (let i = 0; i < leadInfo.length; i++) {
      const info = leadInfo[i]
      const path = info.path
      if (path.length == 0) {
        continue
      }
      const pathScale = info.pathScale
      textIndex = createZoneText(pathScale, textIndex, config.y)

      const end = path[0]
      const start = path[path.length - 1]
      const leadOrder = config.leadOrder
      const orderIndex = info.orderIndex
      let mapid = ''
      if (orderIndex < leadOrder.length) {
        const orderInfo = leadOrder[orderIndex]
        mapid = orderInfo.mapid
      }
      let nextIndex = orderIndex - 1
      if (nextIndex < 0) {
        nextIndex = 0
      }
      let nextMapid = ''
      if (nextIndex < leadOrder.length) {
        const nextOrderInfo = leadOrder[nextIndex]
        nextMapid = nextOrderInfo.mapid
      }

      if ((end.type == leadPointType.louti || end.type == leadPointType.dianti || end.type == leadPointType.futi)) {
        textIndex = createEndFloorPoint(textIndex, nextMapid, end, config.y)
      } else if (end.type == leadPointType.end) {
        imageIndex = createEndPoint(imageIndex, config.mode, end, config.y)
      }

      if ((start.type == leadPointType.louti || start.type == leadPointType.dianti || start.type == leadPointType
        .futi)) {
        // textIndex = createStartFloorPoint(textIndex, mapid, start, config.y);
      } else if (start.type == leadPointType.start && config.hiddenStart == false) {
        imageIndex = createStartPoint(imageIndex, config.mode, start, config.y)
      }
    }

    return {
      textIndex: textIndex,
      imageIndex: imageIndex
    }
  }

  function hiddenUseLessPoint(textIndex, imageIndex) {
    if (textIndex < texts.length) {
      for (let i = textIndex; i < texts.length; i++) {
        const remark = texts[i]
        remark.visible = false
      }
    }

    if (imageIndex < images.length) {
      for (let i = imageIndex; i < images.length; i++) {
        const remark = images[i]
        remark.visible = false
      }
    }
  }

  // 显示路径
  group.showLeadWayExist = function(leadInfo, poList, config) {
    leadLine.showLeadPath(leadInfo, config)
    let zonePath = []
    if (analysis.jsonKeyIsExist(config, ['zonePath'])) {
      zonePath = config['zonePath']
    }
    leadLine.show1FPath(zonePath, config)
    if (leadLine.parent == null) {
      group.add(leadLine)
    }
    config['zonePath'] = zonePath
    const res = createLeadMsg(0, 0, leadInfo, poList, config)
    hiddenUseLessPoint(res.textIndex, res.imageIndex)
  }

  group.animation = function() {
    if (group.visible == false) {
      return
    }
    leadLine.animation()
  }

  group.setScale = function() {
    const list = texts.concat(images)
    return list
  }

  return group
}




