import * as THREE from 'three/build/three.min.js'
import {createObjectAction} from "../createObject";
import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";

import {Line2} from 'three/examples/jsm/lines/Line2'
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry'
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial'

export function createGridGroup() {
  let group = new THREE.Group();

  let create = createObjectAction();

  let analysis = createAnalaysisInfo();

  let lineList = []; // 线段集合

  let pointList = []; // 连接点集合

  let otherPointList = []; // 其他点集合

  let otherLineList = []; // 其他线集合

  let otherLineOffset = {
    x:0, z:0,
  }

  group.deleteAll = function (config) {
    for (let i = 0; i < lineList.length; i++) {
      let c = lineList[i]
      if (c.parent != null) {
        c.parent.remove(c)
      }
      c.material.dispose()
      c.geometry.dispose()
    }
    for (let i = 0; i < pointList.length; i++) {
      let c = pointList[i]
      if (c.parent != null) {
        c.parent.remove(c)
      }
      c.material.dispose()
      c.geometry.dispose()
    }

    for (let i = 0; i < otherPointList.length; i++) {
      let c = otherPointList[i]
      if (c.parent != null) {
        c.parent.remove(c)
      }
      c.material.dispose()
      c.geometry.dispose()
    }

    for (let i = 0; i < otherLineList.length; i++) {
      let c = otherLineList[i]
      if (c.parent != null) {
        c.parent.remove(c)
      }
      c.material.dispose()
      c.geometry.dispose()
    }
    let parent = false;
    if (analysis.jsonKeyIsExist(config, ['parent'])) {
      parent = config['parent']
    }
    if (parent && group.parent != null) {
      group.parent.remove(group)
    }
  }

  group.updateCubePoint = function (config) {
    let s = config['size'];
    let geometry = new THREE.BoxBufferGeometry(s, s, s)//THREE.CubeGeometry是一个几何体，有3个参数：width：立方体x轴的长度，height：立方体y轴的长度，depth：立方体z轴的深度
    let material = new THREE.MeshBasicMaterial({
      color: 0xff00ff,
      depthTest: true
    });
    let selectPointInfo = config['selectPointInfo'];
    let y = config['y'];

    let points = config['points'];
    let count = 0;
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      if (i < pointList.length) {
        let color = {r: 1, g: 0, b: 1};
        let selectColor = {r: 1, g: 0, b: 0};
        let point = pointList[i]
        point.visible = true;
        let pid = p.id
        point.position.x = p.x
        point.position.z = p.z
        create.updateCubeColor(point, analysis.jsonKeyIsExist(selectPointInfo, [pid]) ? selectColor : color)
      } else {
        let cube = create.createCubeByGroup(p.x, y, p.z, 0xff00ff, group, geometry, material)
        pointList.push(cube)
      }
      count = count + 1;
    }
    if (count < pointList.length) {
      for (let i = count; i < pointList.length; i++) {
        let p = pointList[i]
        p.visible = false;
      }
    }

  }

  group.updateLine = function (config) {
    let lineInfo = config['lineInfo'];
    let count = 0
    for (let key in lineInfo) {
      if (count < lineList.length) {
        let line = lineList[count]
        let start = lineInfo[key].line[0]
        let end = lineInfo[key].line[1]
        create.updateLineShpae(line, [new THREE.Vector3(start.x, start.y, start.z), new THREE.Vector3(end.x, end.y, end.z)])
        create.updateLineColor(line, {r: 0, g: 0, b: 1})
        line['key'] = key
        line['line_Type'] = key
        line.visible = true;
      } else {
        let line = create.createLine(lineInfo[key].line, 0x0000ff, 5)
        line['key'] = key
        line['line_Type'] = key
        group.add(line)
        lineList.push(line)
      }
      count = count + 1
    }

    if (count < lineList.length) {
      for (let i = count; i < lineList.length; i++) {
        let line = lineList[i]
        line.visible = false;
      }
    }
  }

  group.updateOtherPointList = function (config) {
    let point = config['point'];
    let y = config['y']
    if (otherPointList.length == 0) {
      let cube = create.createCube(point.x, y, point.z, 0x00ff00, group, 3)
      cube['point'] = 'point'
      otherPointList.push(cube);
      return;
    }
    let cube = otherPointList[0];
    cube.visible = true;
    cube.position.x = point.x;
    cube.position.z = point.z;
  }

  group.hiddenOtherPointList = function () {
    for (let i = 0; i < otherPointList.length; i++) {
      let p = otherPointList[i];
      p.visible = false;
    }
  }

  group.getOtherPoint = function () {
    if (otherPointList.length == 0) {
      return {
        result:false,
      }
    }
    return {
      result:true,
      point:otherPointList[0],
    }
  }

  group.hiddenOtherLineList = function () {
    for (let i = 0; i < otherLineList.length; i++) {
      let line = otherLineList[i];
      line.visible = false;
    }
  }

  group.updateOtherLineList = function (config) {
    let points = config['points'];
    if (otherLineList.length == 0) {
      let width = config['width'];
      let height = config['height'];
      let geometry = new LineGeometry();
      geometry.setPositions(points)
      let material = new LineMaterial({
        color: 0xff0000,
        linewidth: 5
      })
      material.resolution.set(width, height);
      let line = new Line2(geometry, material)
      line.computeLineDistances()
      group.add(line)
      otherLineList.push(line);
      return;
    }
    let line = otherLineList[0];
    line.geometry.setPositions(points)
    line.computeLineDistances()
    line.visible = true;
    line.position.x = line.position.x - otherLineOffset.x;
    line.position.z = line.position.z - otherLineOffset.z;
    otherLineOffset.x = 0;
    otherLineOffset.z = 0;
  }

  group.otherLineListOffset = function (x, z) {
    otherLineOffset.x = otherLineOffset.x + x;
    otherLineOffset.z = otherLineOffset.z + z;
    for (let i = 0; i < otherLineList.length; i++) {
      let line = otherLineList[i];
      line.position.x = line.position.x + x;
      line.position.z = line.position.z + z;
    }
  }

  /* ---------------------------------------------------------------- */







  return group;
}
