import {createPointGroup} from "./pointGroup";
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {createIntersects} from "../../intersects/intersects";

import {pointDataManager_togethor} from "./pointDataManager_togethor";

export function createPointDataManager() {
  let manager = new Object();

  let analysis = createAnalaysisInfo();

  // 点的显示
  let group = createPointGroup();


  // 点击射线
  let intersect = createIntersects();


  // 地图旋转时
  let zoomBlock = null;

  manager.setZoomAction = function (callBack) {
    zoomBlock = callBack;
  }

  let cameraBlock = null;

  manager.cameraAction = function (callBack) {
    cameraBlock = callBack;
  }

  let mapInfoBlock = null;

  manager.setMapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  manager.getMapInfoBlock = function () {
    return mapInfoBlock;
  }

  let changeMapBlock = null;

  manager.changeMapAction = function (callBack) {
    changeMapBlock = callBack;
  }


  // 点的显示
  manager.updatePointsInfo = function (info, config) {
    if (mapInfoBlock == null) {
      return;
    }
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];
    let floorHeight = mapInfo['floorHeight']

    group.updatePointInfo(info, {
      mapid: mapid,
      y: floorHeight
    });
    if (zoomBlock == null) {
      return;
    }
    zoomBlock();
  }

  manager.addOrUpdateNewDataInfo = function (infos) {
    manager.updatePointsInfo(infos, {})
  }


  let moveCameraBlock = null;

  manager.moveCameraAction = function (callBack) {
    moveCameraBlock = callBack;
  }

  let hiddenExtraBlock = null;

  manager.hiddenExtraAction = function (callBack) {
    hiddenExtraBlock = callBack;
  }

  let cameraAnimationBlock = null;

  manager.cameraAnimationAction = function (callBack) {
    cameraAnimationBlock = callBack;
  }

  manager.findPointInfo = function (info, config) {
    let mapInfo = mapInfoBlock();
    let res = group.findPointInfo(info, config)
    let mapid = mapInfo['mapid'];
    if (analysis.jsonKeyIsExist(info, ['mapid']) && info['mapid'] != mapid) {
      changeMapBlock(info['mapid']);
    } else {
      manager.updatePointMesh({mapid:info['mapid']})
    }
    if (zoomBlock != null) {
      zoomBlock();
    }

    if (res.result == false) {
      return res;
    }
    if (analysis.jsonKeyIsExist(config, ['animation']) == false) {
      return res;
    }

    if (cameraAnimationBlock == null) {
      return res;
    }

    let rotate = {};
    if (analysis.jsonKeyIsExist(config, ['rotate'])) {
      rotate = config['rotate']
    }

    let hiddenExtra = false;
    if (analysis.jsonKeyIsExist(config, ['hiddenExtra'])) {
      hiddenExtra = true
    }

    let scale = {};

    if (hiddenExtra) {
      scale = hiddenExtraBlock(res);
    }

    let target = {target: res.position};

    cameraAnimationBlock({target: target, rotate: rotate, scale: scale})

    return res
  }

  manager.updatePointsInfoAndFindPoint = function (infos, info, config) {
    if (mapInfoBlock == null) {
      return;
    }
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];
    let floorHeight = mapInfo['floorHeight']
    changeMapBlock(info['mapid']);
    manager.updatePointsInfo(infos, {
      mapid: mapid,
      y: floorHeight,
      viewUpdate: false,
    })
    let res = group.findPointInfo(info, config)
    if (zoomBlock != null) {
      zoomBlock();
    }

    if (res.result == false) {
      return res;
    }
    if (analysis.jsonKeyIsExist(config, ['animation']) == false) {
      return res;
    }

    if (cameraAnimationBlock == null) {
      return res;
    }

    let rotate = {};
    if (analysis.jsonKeyIsExist(config, ['rotate'])) {
      rotate = config['rotate']
    }

    let hiddenExtra = false;
    if (analysis.jsonKeyIsExist(config, ['hiddenExtra'])) {
      hiddenExtra = true
    }

    let scale = {};

    if (hiddenExtra) {
      scale = hiddenExtraBlock(res);
    }

    let target = {target: res.position};

    cameraAnimationBlock({target: target, rotate: rotate, scale: scale})

    return res
  }

  manager.selectPointMesh = function (select, config) {
    group.selectMesh(config, select)
  }

  manager.cancelSelect = function () {
    let mapInfo = mapInfoBlock();
    let config = {
      mapid:mapInfo['mapid']
    }
    group.cancelSelect(config);
  }

  manager.clearPointsInfo = function (clearInfo, config) {
    group.clearPointsInfo(clearInfo, config)
  }


  // 将点添加到scene上
  manager.addGroup = function (scene) {
    if (group.parent != null) {
      scene.remove(group);
    }
    scene.add(group);
  }

  // 获取点的集合对象
  manager.getGroup = function () {
    return group;
  }

  manager.getZoomBlock = function () {
    return zoomBlock;
  }

  manager.clickMapSceneEvent = function (event, config) {
    let camera = cameraBlock();
    config['camera'] = camera
    let type = ''
    if (analysis.jsonKeyIsExist(config, ['type'])) {
      type = config['type']
    }
    let meshs = [];
    if (type == 'pManager-baseStation') {
      meshs = group.returnBaseStationClickMesh()
    } else {
      meshs = group.returnClickMesh();
    }

    config['meshs'] = meshs;
    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return finder;
    }
    let object = finder.object;
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid'];
    return group.clickEvent(object, mapid)
  }

  manager.zoomMapToPointsFence = function (config) {
    function zoomByTwo(list) {
      let mapInfo = mapInfoBlock();
      let floorHeight = mapInfo['floorHeight']
      let zeroInfo = mapInfo['zeroInfo']
      let fence = {
        leftUp: {
          x: null,
          y: floorHeight,
          z: null
        },
        rightDown: {
          x: null,
          y: floorHeight,
          z: null
        }
      }
      for (let i = 0; i < list.length; i++) {
        let p = list[i]
        let x = p.x
        let z = p.z
        fence.leftUp.x = (fence.leftUp.x == null ? x : (fence.leftUp.x > x ? x : fence.leftUp.x))
        fence.leftUp.z = (fence.leftUp.z == null ? z : (fence.leftUp.z > z ? z : fence.leftUp.z))
        fence.rightDown.x = (fence.rightDown.x == null ? x : (fence.rightDown.x < x ? x : fence.rightDown.x))
        fence.rightDown.z = (fence.rightDown.z == null ? z : (fence.rightDown.z < z ? z : fence.rightDown.z))
      }
      let space = 50
      fence.leftUp.x = fence.leftUp.x - space
      fence.leftUp.z = fence.leftUp.z - space
      fence.rightDown.x = fence.rightDown.x + space
      fence.rightDown.z = fence.rightDown.z + space
      let target = {
        x: (fence.leftUp.x + fence.rightDown.x) / 2.0,
        y: 0,
        z: (fence.leftUp.z + fence.rightDown.z) / 2.0
      }
      cameraAnimationBlock({
        target: target, rotate: {
          angleY: 0.01,
          angleXZ: 0
        }, scale: fence
      })
    }

    function zoomByOne() {
      let mapInfo = mapInfoBlock();
      let floorHeight = mapInfo['floorHeight']
      let zeroInfo = mapInfo['zeroInfo']
      let floors = zeroInfo.areas
      let fence = {
        leftUp: {
          x: null,
          y: floorHeight,
          z: null
        },
        rightDown: {
          x: null,
          y: floorHeight,
          z: null
        }
      }
      for (let i = 0; i < floors.length; i++) {
        let p = floors[i]
        let x = p[0]
        let z = p[2]
        fence.leftUp.x = (fence.leftUp.x == null ? x : (fence.leftUp.x > x ? x : fence.leftUp.x))
        fence.leftUp.z = (fence.leftUp.z == null ? z : (fence.leftUp.z > z ? z : fence.leftUp.z))
        fence.rightDown.x = (fence.rightDown.x == null ? x : (fence.rightDown.x < x ? x : fence.rightDown.x))
        fence.rightDown.z = (fence.rightDown.z == null ? z : (fence.rightDown.z < z ? z : fence.rightDown.z))
      }
      let width = fence.rightDown.x - fence.leftUp.x
      let height = fence.rightDown.z - fence.leftUp.z
      let p = list[0]
      fence = {
        leftUp: {
          x: p.x - width / 2.0,
          y: floorHeight,
          z: p.z - height / 2.0
        },
        rightDown: {
          x: p.x + width / 2.0,
          y: floorHeight,
          z: p.z + height / 2.0
        }
      }
      let target = {
        x: (fence.leftUp.x + fence.rightDown.x) / 2.0,
        y: 0,
        z: (fence.leftUp.z + fence.rightDown.z) / 2.0
      }
      cameraAnimationBlock({
        target: target, rotate: {
          angleY: 0.01,
          angleXZ: 0
        }, scale: fence
      })
    }

    function zoomZero() {
      let mapInfo = mapInfoBlock();
      let floorHeight = mapInfo['floorHeight']
      let zeroInfo = mapInfo['zeroInfo']
      let fence = {
        leftUp: {
          x: null,
          y: floorHeight,
          z: null
        },
        rightDown: {
          x: null,
          y: floorHeight,
          z: null
        }
      }
      let floors = zeroInfo.areas
      for (let i = 0; i < floors.length; i++) {
        let p = floors[i]
        let x = p[0]
        let z = p[2]
        fence.leftUp.x = (fence.leftUp.x == null ? x : (fence.leftUp.x > x ? x : fence.leftUp.x))
        fence.leftUp.z = (fence.leftUp.z == null ? z : (fence.leftUp.z > z ? z : fence.leftUp.z))
        fence.rightDown.x = (fence.rightDown.x == null ? x : (fence.rightDown.x < x ? x : fence.rightDown.x))
        fence.rightDown.z = (fence.rightDown.z == null ? z : (fence.rightDown.z < z ? z : fence.rightDown.z))
      }
      let target = {
        x: (fence.leftUp.x + fence.rightDown.x) / 2.0,
        z: (fence.leftUp.z + fence.rightDown.z) / 2.0,
        y: floorHeight
      }
      cameraAnimationBlock({
        target: target, rotate: {
          angleY: 0.01,
          angleXZ: 0
        }, scale: {}
      })
    }

    let list = group.fillterList(config)
    if (list.length == 0) {
      zoomZero();
      return;
    }

    if (list.length == 1) {
      zoomByOne();
      return;
    }

    zoomByTwo(list);
  }

  manager.getSelectIbeaconPositon = function () {
    let mapInfo = mapInfoBlock();
    return group.getSelectIbeaconPositon({
      mapid: mapInfo['mapid']
    })
  }

  // 偏移选中信标位置
  manager.offSetIbeacon = function (x, y) {
    let mapInfo = mapInfoBlock();
    group.offSetIbeacon({
      x: x,
      y: y,
      mapid: mapInfo['mapid']
    })
  }

  manager.selectFieldIbeacon = function (start, end) {
    let areas = [
      [start.x, 0, start.z],
      [start.x, 0, end.z],
      [end.x, 0, end.z],
      [end.x, 0, start.z]
    ]
    let mapInfo = mapInfoBlock();
    group.selectAreasIbeacon({
      areas: areas,
      mapid: mapInfo['mapid']
    })
  }

  manager.getIBeacon = function (event, config) {
    let camera = cameraBlock();
    config['camera'] = camera

    let meshs = group.returnIbeaconList();
    let mapInfo = mapInfoBlock();

    config['meshs'] = meshs;
    let finder = intersect.getIntersects(event, config);
    let intersects = finder.intersects;
    return group.getIBeacon(intersects, {
      mapid: mapInfo['mapid']
    })
  }


  manager.updatePointMesh = function (config) {
    group.updatePointMesh(config)
  }

  manager.updateIBeaconList = function (list) {
    let mapInfo = mapInfoBlock();
    group.updateIBeaconList(list, {
      mapid: mapInfo['mapid'],
    })
  }

  manager.changeIbeaconMode = function (type) {
    let mapInfo = mapInfoBlock();
    group.changeIbeaconMode({
      type: type,
      mapid: mapInfo['mapid'],
    })
    zoomBlock();
  }

  let disBlock = null;

  manager.disAction = function (callBack) {
    disBlock = callBack;
  }

  manager.mouseMoveOnMap = function (event, config) {
    let camera = cameraBlock();
    config['camera'] = camera

    let meshs = group.returnClickMesh()
    let mapInfo = mapInfoBlock();

    config['meshs'] = meshs;
    let finder = intersect.getIntersects(event, config);
    if (finder.result == false) {
      return finder;
    }
    let intersects = finder.intersects;
    let result = {
      data: [],
      point: null,
      result: false,
      type: 'together',
      pointResult: false
    }
    let windowWidth = config['windowWidth'];
    let windowHeight = config['windowHeight'];
    let dis = disBlock();
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object
      if (object.meshType != 'together') {
        continue
      }
      let res = group.mouseMoveOnPoint(object, mapInfo['mapid'], {
        isPhone: false,
        dis: dis, // 整个屏幕投影的世界距离
        camera: camera,
        windowWidth: windowWidth,
        windowHeight: windowHeight
      })
      if (res.result == false) {
        continue;
      }
      result.point = res.point
      result.result = true
      result.data = result.data.concat(res.data)
      result.pointResult = true
    }
    return result
  }

  manager.deleteAll = function () {
    group.deleteAll();
  }

  pointDataManager_togethor(manager);



  return manager;
}
