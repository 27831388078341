import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";
import {createLeadJs} from "../../analaysis/LeadJS";

export function gridDataInfo() {
  let gridData = new Object();

  let analysis = createAnalaysisInfo()

  let lead = createLeadJs()

  function returnLineId(line) {
    let start = line[0];
    let end = line[1];
    let id1 = start.x >= end.x ? end.x.toFixed(2) + '_' + start.x.toFixed(2) : start.x.toFixed(2) + '_' + end.x.toFixed(2);
    let id2 = start.z >= end.z ? end.z.toFixed(2) + '_' + start.z.toFixed(2) : start.z.toFixed(2) + '_' + end.z.toFixed(2)
    return id1 + ' ~ ' + id2;
  }

  function returnPointId(p) {
    return p.x.toFixed(2) + '_' + p.z.toFixed(2);
  }

  function reutrnLineK(line) {
    let start = line[0];
    let end = line[1];
    let xCount = start.x - end.x;
    let zCount = start.z - end.z;
    if (xCount == 0) {
      return 'Infinity'
    } else {
      return zCount / xCount;
    }
  }

  function returnChuiK(k) {
    if (k == 'Infinity') {
      return 0;
    } else if (k == 0) {
      return 'Infinity';
    } else {
      return -1 / k;
    }
  }

  function checkPointIsOnLine(point, checked) {
    for (let key in checked) {
      let line = checked[key].line;
      let lineK = reutrnLineK(line);
      if (lineK == reutrnLineK([line[0], point]) || lineK == reutrnLineK([line[1], point])) {
        return true;
      }
    }
    return false;
  }

  function pointIsInFence(point, start, end) {
    let startX = parseFloat((start.x).toFixed(2))
    let startZ = parseFloat((start.z).toFixed(2))

    let endX = parseFloat((end.x).toFixed(2))
    let endZ = parseFloat((end.z).toFixed(2))

    let maxX = analysis.findMax([startX, endX]);
    let minX = analysis.findMin([startX, endX]);

    let maxZ = analysis.findMax([startZ, endZ]);
    let minZ = analysis.findMin([startZ, endZ]);

    if (point.x >= minX && point.x <= maxX && point.z >= minZ && point.z <= maxZ) {
      return true;
    }
    return false;
  }

  function dueToRoomsGetLine(lines, mapJson, height, floor) {
    return lines;
  }

  function getLinesOnMap(getPoints, floor, p) {
    if (getPoints.length > 1) {
      let indexInfo = {
        start: 0,
        end: 1,
      }
      let maxDis = 0;
      for (let j = 0; j < getPoints.length; j++) {
        let p_1 = getPoints[j];
        for (let l = j + 1; l < getPoints.length; l++) {
          let p_2 = getPoints[l];
          let dis = analysis.getDistance(p_1, p_2);

          if (dis > maxDis) {
            indexInfo.start = l;
            indexInfo.end = j;
            maxDis = dis;
          }
        }
      }
      let start = JSON.parse(JSON.stringify(getPoints[indexInfo.start]));
      getPoints.sort(function (a, b) {
        let dis1 = Math.sqrt((start.x - a.x) * (start.x - a.x) + (start.z - a.z) * (start.z - a.z));
        let dis2 = Math.sqrt((start.x - b.x) * (start.x - b.x) + (start.z - b.z) * (start.z - b.z));
        return dis1 - dis2;
      })
      let lines = {};
      for (let i = 0; i < getPoints.length - 1; i++) {
        let p1 = getPoints[i];
        let p2 = getPoints[i + 1];
        let centerX = (p1.x + p2.x) / 2.0;
        let centerZ = (p1.z + p2.z) / 2.0;
        if (analysis.isInPolygon([centerX, 0, centerZ], floor.areas) == true) {
          let lineId = returnLineId([p1, p2]);
          lines[lineId] = {
            start: p,
            line: [p1, p2],
          }
        }
      }
      return {
        result: true,
        lines: lines,
      }
    }
    return {
      result: false
    }
  }


  function getLine(start, end, floor, space, checked, notChecked, points, height, mapJson) {
    let result = {};
    let k = reutrnLineK([start, end]);
    let ck = returnChuiK(k);

    function getPoint(p) {
      let pId = returnPointId(p);
      if (analysis.jsonKeyIsExist(points, [pId]) || analysis.isInPolygon([p.x, 0, p.z], floor.areas) == false || checkPointIsOnLine(p, checked)) {
        return;
      }
      points[pId] = p;
      let otherPX = ck == 'Infinity' ? p.x : p.x + 10;
      let otherPZ = ck == 'Infinity' ? p.z + 10 : (p.z - ck * p.x + ck * otherPX);
      let areas = floor.areas;
      let getPoints = [];
      for (let j = 0; j < areas.length; j++) {
        let index1 = j;
        let index2 = index1 == areas.length - 1 ? 0 : j + 1
        let p1 = areas[index1];
        let p2 = areas[index2];

        let xiaojiao = analysis.twoLineXiangjiao(p.x, p.z, otherPX, otherPZ, p1[0], p1[2], p2[0], p2[2], false);
        if (xiaojiao.result) {
          let newP = {
            x: parseFloat((xiaojiao.x).toFixed(2)),
            y: height,
            z: parseFloat((xiaojiao.y).toFixed(2))
          };
          if (pointIsInFence(newP, {
            x: parseFloat((p1[0]).toFixed(2)),
            z: parseFloat((p1[2]).toFixed(2))
          }, {x: parseFloat((p2[0]).toFixed(2)), z: parseFloat((p2[2]).toFixed(2))})) {
            getPoints.push(newP)
          }
        }
      }
      let linesInfo = getLinesOnMap(getPoints, floor, p);
      if (linesInfo.result) {
        let newlineInfo = dueToRoomsGetLine(linesInfo.lines, mapJson, height, floor);
        for (let key in newlineInfo) {
          if (analysis.jsonKeyIsExist(checked, [key])) {
            continue;
          }
          result[key] = newlineInfo[key];
        }
      }
    }

    let dis = Math.sqrt((start.x - end.x) * (start.x - end.x) + (start.z - end.z) * (start.z - end.z));
    if (dis < space) {
      return {
        result: false,
      }
    }

    let count = parseInt(dis / space);
    for (let i = 1; i <= count; i++) {
      let cutScale = i * space / dis;
      let p = {
        x: parseFloat((start.x + (end.x - start.x) * cutScale).toFixed(2)),
        y: height,
        z: parseFloat((start.z + (end.z - start.z) * cutScale).toFixed(2)),
      }
      getPoint(p);
    }
    return {
      result: true,
      data: result,
    }
  }


  // 开始切割
  function cutLine(checked, notChecked, points, floor, space, height, mapJson, callBack) {
    let empty = true;
    let cancel = {};

    function checkNoChecked(notChecked, keys, checked, index, callBack, emptyCallBack) {
      if (index >= keys.length) {
        callBack();
        return;
      }
      let key = keys[index];
      let lineInfo = notChecked[key];
      let line = lineInfo.line;
      let start = line[0];
      let end = line[1];
      let startCut = lineInfo.start;
      let result1 = getLine(startCut, end, floor, space, checked, notChecked, points, height, mapJson);
      if (result1.result) {
        let data = result1.data;
        for (let key in data) {
          if (analysis.jsonKeyIsExist(checked, [key])) {
            continue;
          }
          notChecked[key] = data[key];
          if (keys.indexOf(key) < 0) {
            keys.push(key);
          }
        }
      }
      let result2 = getLine(startCut, start, floor, space, checked, notChecked, points, height, mapJson);
      if (result2.result) {
        let data = result2.data;
        for (let key in data) {
          if (analysis.jsonKeyIsExist(checked, [key])) {
            continue;
          }
          notChecked[key] = data[key];
          if (keys.indexOf(key) < 0) {
            keys.push(key);
          }
        }
      }
      checked[key] = notChecked[key];
      cancel[key] = '1'
      emptyCallBack({
        result: false
      })
      setTimeout(() => {
        checkNoChecked(notChecked, keys, checked, index + 1, callBack, emptyCallBack);
      }, 10)
    }


    let checkNoCheckedPromise = async () => {
      await new Promise(resolve => {
        let keys = JSON.parse(JSON.stringify(Object.keys(notChecked)));
        checkNoChecked(notChecked, keys, checked, 0, () => {
          resolve({
            result: true,
          })
        }, (res) => {
          empty = res.result;
        })
      })

      for (let key in cancel) {
        delete notChecked[key];
      }

      if (empty == true) {
        callBack();
        return;
      }

      setTimeout(() => {
        cutLine(checked, notChecked, points, floor, space, height, mapJson, callBack);
      }, 10)
    }

    checkNoCheckedPromise();
  }

  // 创建网格信息
  gridData.createGrid = function (datumLine, space, zeroFloor, height, callBack) {
    let start = datumLine[0];
    let end = datumLine[1];
    let datumDis = analysis.getDistance(start, end);
    if (datumDis < space) {
      callBack({
        result: false,
        msg: '起始距离不够'
      })
      return
    }

    let points = {};
    let checked = {};
    let notChecked = {};

    let lineId = returnLineId(datumLine)
    notChecked[lineId] = {
      start: start,
      line: datumLine,
    }
    let newMap = [zeroFloor];
    cutLine(checked, notChecked, points, zeroFloor, space, height, newMap, () => {
      callBack({
        result: true,
        points: points,
        line: checked
      })
    });
  }


  // 更新线段
  gridData.updateGridLine = function (line, zeroFloor, mapJson, height, checked) {
    let newMap = [];
    let isVaild = false;
    for (let i = 0; i < mapJson.length; i++) {
      let room = mapJson[i];
      if (room.areaid == zeroFloor.areaid) {
        isVaild = true;
        newMap.push(room);
      }
      if (isVaild == false || room.color == zeroFloor.color) {
        continue;
      }
      newMap.push(room);
    }

    let areas = zeroFloor.areas;
    let getPoints = [];
    let start = line[0];
    let end = line[1];
    for (let j = 0; j < areas.length; j++) {
      let index1 = j;
      let index2 = index1 == areas.length - 1 ? 0 : j + 1
      let p1 = areas[index1];
      let p2 = areas[index2];

      let xiaojiao = analysis.twoLineXiangjiao(start.x, start.z, end.x, end.z, p1[0], p1[2], p2[0], p2[2], false);
      if (xiaojiao.result) {
        let newP = {
          x: parseFloat((xiaojiao.x).toFixed(2)),
          y: height,
          z: parseFloat((xiaojiao.y).toFixed(2))
        };
        let result = pointIsInFence(newP, {
          x: parseFloat((p1[0]).toFixed(2)),
          z: parseFloat((p1[2]).toFixed(2))
        }, {x: parseFloat((p2[0]).toFixed(2)), z: parseFloat((p2[2]).toFixed(2))})
        if (result) {
          getPoints.push(newP)
        }
      }
    }
    let result = {};
    let linesInfo = getLinesOnMap(getPoints, zeroFloor, start);
    if (linesInfo.result) {
      let newlineInfo = dueToRoomsGetLine(linesInfo.lines, newMap, height, zeroFloor);
      let minDis = 65535
      let selectKey = '';
      for (let key in newlineInfo) {
        let line = newlineInfo[key].line;
        let line_start = line[0];
        let line_end = line[1];
        let dis1 = Math.sqrt((line_start.x - start.x) * (line_start.x - start.x) + (line_start.z - start.z) * (line_start.z - start.z));
        let dis2 = Math.sqrt((line_end.x - end.x) * (line_end.x - end.x) + (line_end.z - end.z) * (line_end.z - end.z));
        if (dis2 + dis1 <= minDis) {
          minDis = dis1 + dis2;
          selectKey = key;
        }
      }
      if (analysis.jsonKeyIsExist(checked, [selectKey]) == false) {
        checked[selectKey] = newlineInfo[selectKey];
        result[selectKey] = newlineInfo[selectKey];
      }
    }
    return {
      result: true,
      data: result
    }
  }

  gridData.updateGridPoint = function (info, config) {
    let lines = [];
    for (let key in info) {
      lines.push(info[key].line);
    }
    let points = [];
    let pointsInfo = {};

    let targetPoint = {
      result:false,
    };
    if (analysis.jsonKeyIsExist(config, ['targetPoint'])) {
      targetPoint = config['targetPoint'];
    }

    for (let i = 0; i < lines.length; i++) {
      let line1 = lines[i];
      let start1 = line1[0];
      let end1 = line1[1];
      for (let j = i + 1; j < lines.length; j++) {
        let line2 = lines[j];
        let start2 = line2[0];
        let end2 = line2[1];
        let xiaojiao = analysis.twoLineXiangjiao(start1.x, start1.z, end1.x, end1.z, start2.x, start2.z, end2.x, end2.z, false);
        if (xiaojiao.result) {
          let p = {
            x: parseFloat((xiaojiao.x).toFixed(2)),
            y: 0,
            z: parseFloat((xiaojiao.y).toFixed(2))
          };

          if (targetPoint.result) {
            let xCount = p.x - targetPoint.x;
            let zCount = p.z - targetPoint.z;
            let dis = Math.sqrt(xCount * xCount + zCount * zCount);
            if (dis >= targetPoint.dis) {
              continue;
            }
          }

          let result1 = pointIsInFence(p, start1, end1);
          let result2 = pointIsInFence(p, start2, end2);
          if (result1 && result2) {
            let pId = returnPointId(p);
            if (analysis.jsonKeyIsExist(pointsInfo, [pId]) == false) {
              p['id'] = pId;
              points.push(p);
              pointsInfo[pId] = '1';
            }
          }
        }
      }
    }
    return {
      points: points,
    };
  }

  gridData.findLine = function (point, info) {
    let selectKey = '';
    for (let key in info) {
      let data = info[key];
      let line = data['line'];
      let width = lead.returnPathLineByWidth(3, line, 3, 3);
      if (analysis.isInPolygon([point.x, 0, point.z], width)) {
        selectKey = key;
        break;
      }
    }
    if (selectKey != '') {
      return {
        result: true,
        key: selectKey
      }
    } else {
      return {
        result: false
      }
    }
  }

  gridData.getPointOnLine = function (point, info) {
    let result = this.findLine(point, info);
    if (result.result == false) {
      return result;
    }
    let line = info[result.key].line;
    let correct = analysis.setPointToLine(point.x, point.z, line);
    return {
      result: true,
      x: correct.x,
      z: correct.z,
      y: 0,
    }
  }

  gridData.selectZoneLine = function (zone, info) {
    let select = {};
    for (let key in info) {
      let line = info[key].line;
      let start = line[0];
      let end = line[1];
      if (analysis.isInPolygon([start.x, 0, start.z], zone) && analysis.isInPolygon([end.x, 0, end.z], zone)) {
        select[key] = '1';
      }
    }
    return select;
  }

  gridData.createNewBrench = function (point, zeroFloor, mapJson, height, checked) {
    let result = this.findLine(point, checked);
    if (result.result == false) {
      return {
        result: false
      };
    }
    let line = checked[result.key].line;
    let correct = analysis.setPointToLine(point.x, point.z, line);
    let ck = returnChuiK(reutrnLineK(line));
    let nextPoint = {x: 0, y: height, z: 0}
    nextPoint.x = ck == 'Infinity' ? correct.x : correct.x + 10;
    nextPoint.z = ck == 'Infinity' ? correct.z + 10 : (correct.z - ck * correct.x + ck * nextPoint.x);
    let updateInfo = this.updateGridLine([{
      x: correct.x,
      y: height,
      z: correct.z
    }, nextPoint], zeroFloor, mapJson, height, checked)
    return updateInfo;
  }

  gridData.returnPointId = function (point) {
    return returnPointId(point);
  }

  gridData.selectZoneLine = function (zone, info) {
    let select = {};
    for (let key in info) {
      let line = info[key].line;
      let start = line[0];
      let end = line[1];
      if (analysis.isInPolygon([start.x, 0, start.z], zone) && analysis.isInPolygon([end.x, 0, end.z], zone)) {
        select[key] = '1';
      }
    }
    return select;
  }

  return gridData;
}
