export function pointDataManager_togethor(manager) {
  let group = manager.getGroup();

  let zoomBlock = manager.getZoomBlock();

  manager.findSelectTogethor = function (config) {
    let result = group.findSelectTogethor(config)
    zoomBlock();
    return result
  }

  let findMapGroupBlock = null;

  manager.findMapGroupAction = function (callBack) {
    findMapGroupBlock = callBack;
  }

  let mapInfoBlock = manager.getMapInfoBlock();

  manager.changeRoomColorByIncubator = function (config) {
    let info = group.returnDataList('together')
    for (let mapid in info) {
      let list = info[mapid]
      let group = findMapGroupBlock(mapid)
      if (group == null) {
        continue
      }
      group.changeRoomColorByIncubator({
        dataList: list,
        color: config.color
      })
    }
  }


}
