import * as THREE from 'three/build/three.min.js'
import {
  pointManagerImageUrl
} from '../../MapConfig'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createObjectAction
} from '../createObject'

import {pointGroup_togethor} from "./pointGroup_togethor";

export function createPointGroup() {
  let manager = new THREE.Group()

  const analysis = createAnalaysisInfo()

  const create = createObjectAction()

  const imageInfo = pointManagerImageUrl()

  const iBeaconModeInfo = {
    rssi: 0,
    mac_rssi: 1,
    rssi_time: 2,
    mac_rssi_time: 3,
    broadcastinterval: 4,
    transpower: 5,
    battery: 6
  }

  let findRoomInfoBlock = null // 获取房间信息

  manager.findRoomInfoAction = function (callBack) {
    findRoomInfoBlock = callBack;
  }

  let iBeaconMode = iBeaconModeInfo.mac_rssi

  let pointsInfo = {} // 打点数据

  let pointsMesh = {} // 打点的mesh

  let selectInfo = {};

  manager.getPointsInfo = function () {
    return {
      pointsInfo: pointsInfo,
      pointsMesh: pointsMesh,
      selectInfo: selectInfo,
    }
  }

  let animationStart = false;

  let findRoomBlock = null;

  manager.findRoomAction = function (callBack) {
    findRoomBlock = callBack;
  }


  manager.updatePointInfo = function (info, config) {
    for (const infoType in info) {
      pointsInfo[infoType] = info[infoType]
      if (analysis.jsonKeyIsExist(pointsMesh, [infoType]) == false) {
        pointsMesh[infoType] = []
      }
    }
    updatePointInfo(config);
  }

  function updatePointInfo(config) {
    for (const type in pointsInfo) {
      let meshList = []
      if (analysis.jsonKeyIsExist(pointsMesh, [type]) == true) {
        meshList = pointsMesh[type]
      } else {
        pointsMesh[type] = meshList
      }
      let select = {}
      if (analysis.jsonKeyIsExist(selectInfo, [type]) == true) {
        select = selectInfo[type]
      } else {
        selectInfo[type] = select
      }

      const infos = pointsInfo[type]
      if (type == 'point') {
        updatePointMesh(meshList, infos, select, config)
      } else if (type == 'leadPoint') {
        updatePointMesh(meshList, infos, select, config)
      } else if (type == 'shareLocation') {
        updatePointMesh(meshList, infos, select, config)
      }  else if (type == 'clickPoint') {
        updatePointMesh(meshList, infos, select, config)
      } else if (type == 'room') {
        updateRoomPoint(meshList, infos, select, config)
      } else if (type == 'clickPoint') {
        updatePointMesh(meshList, infos, select, config)
      } else if (type == 'clickRoom') {
        updateRoomPoint(meshList, infos, select, config)
      } else if (type == 'iBeacon') {
        updateIbeaconPoint(meshList, infos, select, config)
      } else if (type == 'textList') {
        updateTextList(meshList, infos, select, config)
      } else if (type == 'warnist') {
        updateTextList(meshList, infos, select, config)
      } else if (type == 'coordinate') {
        updatePointMesh(meshList, infos, select, config)
      }
    }
  }

  function updateTextList(meshList, infos, select, config) {
    let mapid = '';
    if (analysis.jsonKeyIsExist(config, ['mapid'])) {
      mapid = config['mapid'];
    }
    let count = 0
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i]
      if (analysis.jsonKeyIsExist(info, ['mapid', 'x', 'z']) == false || info.mapid != mapid) {
        continue
      }
      const isSelect = analysis.jsonKeyIsExist(select, [info.id])
      let msg = '';
      if (analysis.jsonKeyIsExist(info, ['title'])) {
        msg = info['title'];
      }

      let textColor = '#000000'
      if (analysis.jsonKeyIsExist(info, ['textColor'])) {
        textColor = info['textColor'];
      }


      let bgColor = '#fffde8'
      if (analysis.jsonKeyIsExist(info, ['bgColor'])) {
        bgColor = info['bgColor'];
      }

      let multiple = 15;
      if (analysis.jsonKeyIsExist(info, ['multiple'])) {
        multiple = info['multiple'];
      }

      let scale = 0.07;
      if (analysis.jsonKeyIsExist(info, ['scale'])) {
        scale = info['scale'];
      }

      let type = 'important'
      if (analysis.jsonKeyIsExist(info, ['type'])) {
        type = info['type'];
      }

      const setting = {
        position: new THREE.Vector3(parseFloat(info.x), config.y, parseFloat(info.z)),
        title: msg,
        type: type,
        bgColor: bgColor,
        textColor: textColor,
        multiple: multiple,
        centerY: 0,
        mode: 0,
        scale:scale,
      }
      if (count < meshList.length) {
        const text = meshList[count]
        // 判断是否需要更新样式
        if (analysis.jsonKeyIsExist(text, ['dataSetting'])) {
          const oldSetting = text['dataSetting']
          let isChange = false
          for (const key in oldSetting) {
            if (key == 'position') {
              const oldP = oldSetting[key]
              const newP = setting[key]
              if (oldP.x != newP.x || oldP.z != newP.z) {
                isChange = true
                break
              }
            } else {
              const oldV = oldSetting[key]
              const newV = setting[key]
              if (oldV != newV) {
                isChange = true
                break
              }
            }
          }
          if (isChange) {
            create.updateTitle(text, setting)
          }
        } else {
          create.updateTitle(text, setting)
        }
        text.visible = true
        if (isSelect) {
          manager.remove(text)
          manager.add(text)
        }
        text['dataInfo'] = info
        text['dataSetting'] = setting
      } else {
        const text = create.createTitle(setting)
        text['dataInfo'] = info
        text['dataSetting'] = setting
        text['meshType'] = 'testList'
        manager.add(text)
        meshList.push(text)
      }
      count = count + 1
    }
    if (count < meshList.length) {
      for (let i = count; i < meshList.length; i++) {
        const p = meshList[i]
        p.visible = false
      }
    }
  }

  function updateIbeaconPoint(meshList, infos, select, config) {
    let mapid = '';
    if (analysis.jsonKeyIsExist(config, ['mapid'])) {
      mapid = config['mapid'];
    }
    let count = 0
    for (let i = 0; i < infos.length; i++) {
      let info = infos[i]
      if (analysis.jsonKeyIsExist(info, ['mapid', 'x', 'y', 'z']) == false || info.mapid != mapid) {
        continue
      }
      let isSelect = analysis.jsonKeyIsExist(select, [info.mac])
      let msg = returnBeaconMsg(info)
      let textColor = '#000000'
      let bgColor = '#fffde8'
      if (isSelect) {
        textColor = '#ffffff'
        bgColor = '#ff0000'
      }
      let setting = {
        position: new THREE.Vector3(parseFloat(info.x), config.y, parseFloat(info.z)),
        title: msg,
        type: 'important',
        bgColor: bgColor,
        textColor: textColor,
        multiple: 15,
        centerY: 0,
        mode: 0
      }
      if (count < meshList.length) {
        let text = meshList[count]
        // 判断是否需要更新样式
        if (analysis.jsonKeyIsExist(text, ['dataSetting'])) {
          let oldSetting = text['dataSetting']
          let isChange = false
          for (let key in oldSetting) {
            if (key == 'position') {
              let oldP = oldSetting[key]
              let newP = setting[key]
              if (oldP.x != newP.x || oldP.z != newP.z) {
                isChange = true
                break
              }
            } else {
              let oldV = oldSetting[key]
              let newV = setting[key]
              if (oldV != newV) {
                isChange = true
                break
              }
            }
          }
          if (isChange) {
            create.updateTitle(text, setting)
          }
        } else {
          create.updateTitle(text, setting)
        }
        text.visible = true
        if (isSelect) {
          manager.remove(text)
          manager.add(text)
        }
        text['dataInfo'] = info
        text['dataSetting'] = setting
      } else {
        let text = create.createTitle(setting)
        text['dataInfo'] = info
        text['dataSetting'] = setting
        text['meshType'] = 'iBeacon'
        manager.add(text)
        meshList.push(text)
      }
      count = count + 1
    }
    if (count < meshList.length) {
      for (let i = count; i < meshList.length; i++) {
        let p = meshList[i]
        p.visible = false
      }
    }
  }

  function returnBeaconMsg(info) {
    let msg = ''
    if (iBeaconMode == iBeaconModeInfo.mac_rssi_time) {
      msg = info.mac + ' - ' + info.msg
    } else if (iBeaconMode == iBeaconModeInfo.mac_rssi) {
      msg = info.mac
    } else if (iBeaconMode == iBeaconModeInfo.rssi) {
      msg = (typeof (info.rssi) == 'undefined' ? '-' : info.rssi) + ''
    } else if (iBeaconMode == iBeaconModeInfo.rssi_time) {
      msg = (typeof (info.rssi) == 'undefined' ? '-' : info.rssi) + ' - ' + info.msg
    } else if (iBeaconMode == iBeaconModeInfo.broadcastinterval) {
      msg = (typeof (info.broadcastinterval) == 'undefined' ? '-' : info.broadcastinterval) + ''
    } else if (iBeaconMode == iBeaconModeInfo.transpower) {
      msg = (typeof (info.transpower) == 'undefined' ? '-' : info.transpower) + ''
    } else if (iBeaconMode == iBeaconModeInfo.battery) {
      msg = (typeof (info.battery) == 'undefined' ? '-' : info.battery) + ''
    } else {
      msg = '0 - 0'
    }
    return msg
  }

  function gifSetting(info, setting) {
    let animation = false;
    if (analysis.jsonKeyIsExist(info, ['animation']) == false) {
      return {
        result: false,
        animation: animation
      }
    }

    animation = info['animation'];
    let hNumber = 4
    if (analysis.jsonKeyIsExist(info, ['hNumber'])) {
      hNumber = info['hNumber']
    }
    let vNumber = 4
    if (analysis.jsonKeyIsExist(info, ['vNumber'])) {
      vNumber = info['vNumber']
    }
    let totalNumber = 10
    if (analysis.jsonKeyIsExist(info, ['totalNumber'])) {
      totalNumber = info['totalNumber']
    }
    let second = 15
    if (analysis.jsonKeyIsExist(info, ['second'])) {
      second = info['second']
    }
    setting['animation'] = true;
    setting['hNumber'] = hNumber;
    setting['vNumber'] = vNumber;
    setting['totalNumber'] = totalNumber;
    setting['second'] = second;
    return {
      result: true,
      animation: animation
    }
  }

  function shouldUpdatePointMesh(p, setting, isSelect, info) {
    // 判断是否需要更新样式
    if (analysis.jsonKeyIsExist(p, ['dataSetting'])) {
      const oldSetting = p['dataSetting']
      let isChange = false
      for (const key in oldSetting) {
        if (key == 'position') {
          const oldP = oldSetting[key]
          const newP = setting[key]
          if (oldP.x != newP.x || oldP.z != newP.z) {
            isChange = true
            break
          }
        } else {
          const oldV = oldSetting[key]
          const newV = setting[key]
          if (oldV != newV) {
            isChange = true
            break
          }
        }
      }
      if (isChange) {
        create.updateIconSprite(p, setting)
      }
    } else {
      create.updateIconSprite(p, setting)
    }
    p.visible = true
    p['dataInfo'] = info
    p['dataSetting'] = setting
    if (isSelect == false) {
      return;
    }
    manager.remove(p)
    manager.add(p)
  }

  function updateRoomPoint(meshList, infos, select, config) {
    let mapid = '';
    if (analysis.jsonKeyIsExist(config, ['mapid'])) {
      mapid = config['mapid'];
    }
    const mode = 0
    let count = 0
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i]
      if (analysis.jsonKeyIsExist(info, ['mapid', 'roomAreaId']) == false || info.mapid != mapid) {
        continue
      }
      const roomid = info.roomAreaId
      if (analysis.jsonKeyIsExist(info, ['x', 'z']) == false) {
        const roomInfo = findRoomInfoBlock(mapid, roomid)
        if (roomInfo.result == false) {
          continue
        }
        info['x'] = roomInfo.x
        info['y'] = 3
        info['z'] = roomInfo.y
        info['mapName'] = roomInfo.mapName
        info['roomName'] = roomInfo.name
      }

      const centerY = typeof (info.centerY) == 'undefined' ? 0.5 : parseFloat(info.centerY)
      const position = new THREE.Vector3(parseFloat(info.x), parseFloat(info.y), parseFloat(info.z))
      const isSelect = analysis.jsonKeyIsExist(select, [info.id + ''])
      let image = ''
      if (isSelect) {
        image = typeof (info.select) == 'undefined' ? imageInfo.pointSelect : info.select
      } else {
        image = typeof (info.unSelect) == 'undefined' ? imageInfo.pointUnSelect : info.unSelect
      }
      const setting = {
        centerY: centerY,
        position: position,
        multiple: 20,
        image: image,
        width: 32,
        mode: mode
      }
      if (count < meshList.length) {
        const p = meshList[count]
        create.updateIconSprite(p, setting)
        p.visible = true
        p['dataInfo'] = info
        if (isSelect) {
          manager.remove(p)
          manager.add(p)
        }
      } else {
        const p = create.creteIconSprite(setting)
        p['dataInfo'] = info
        manager.add(p)
        meshList.push(p)
        p['meshType'] = 'room'
      }
      count = count + 1
    }
    if (count < meshList.length) {
      for (let i = count; i < meshList.length; i++) {
        const room = meshList[i]
        room.visible = false
      }
    }
  }

  // point
  function updatePointMesh(meshList, infos, select, config) {
    let mapid = '';
    if (analysis.jsonKeyIsExist(config, ['mapid'])) {
      mapid = config['mapid'];
    }
    let count = 0
    animationStart = false;
    let shouldAnimation = false;
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i]
      let type = 'point';
      if (analysis.jsonKeyIsExist(info, ['type'])) {
        type = info['type'];
      }
      if (type != 'coordinate' && info.mapid != mapid) {
        continue
      }
      const centerY = typeof (info.centerY) == 'undefined' ? 0.5 : parseFloat(info.centerY)
      const position = new THREE.Vector3(parseFloat(info.x), parseFloat(info.y), parseFloat(info.z))
      const isSelect = analysis.jsonKeyIsExist(select, [info.id + ''])
      let image = ''
      if (isSelect) {
        image = typeof (info.select) == 'undefined' ? imageInfo.pointSelect : info.select
      } else {
        image = typeof (info.unSelect) == 'undefined' ? imageInfo.pointUnSelect : info.unSelect
      }

      const setting = {
        centerY: centerY,
        position: position,
        multiple: 20,
        image: image,
        width: 32,
        mode: 0
      }

      if (analysis.jsonKeyIsExist(info, ['scale'])) {
        setting['scale'] = info['scale']
      }

      let res = gifSetting(info, setting);
      if (res.result) {
        shouldAnimation = true;
      }

      if (count < meshList.length) {
        const p = meshList[count]
        shouldUpdatePointMesh(p, setting, isSelect, info);
      } else {
        const p = create.creteIconSprite(setting)
        p['dataInfo'] = info
        manager.add(p)
        meshList.push(p)
        p['meshType'] = 'point'
        p['dataSetting'] = setting
      }
      count = count + 1
    }
    if (count < meshList.length) {
      for (let i = count; i < meshList.length; i++) {
        const p = meshList[i]
        p.visible = false
      }
    }
    animationStart = shouldAnimation;
  }

  manager.selectMesh = function (config, select) {
    for (const key in select) {
      selectInfo[key] = select[key]
    }
    updatePointInfo(config)
  }

  manager.cancelSelect = function (config) {
    selectInfo = {}
    updatePointInfo(config)
  }

  manager.clearPointsInfo = function (clearInfo, config) {
    if (analysis.jsonKeyIsExist(clearInfo, ['all'])) {
      for (const key in pointsInfo) {
        pointsInfo[key] = []
      }
      selectInfo = {}
    } else {
      for (const key in clearInfo) {
        pointsInfo[key] = []
      }
    }
    updatePointInfo(config)
  }

  manager.returnZoomList = function () {
    let list = []
    for (const pointsMeshKey in pointsMesh) {
      if (pointsMeshKey == 'baseStation') {
        continue;
      }
      const dataList = pointsMesh[pointsMeshKey]
      list = list.concat(dataList)
    }

    for (let i = 0; i < list.length; i++) {
      let s = list[i];
      if (typeof s['animation'] == 'undefined' || s['animation'] == false) {
        continue;
      }
      if (typeof s['animator'] == 'undefined' || s['animator'] == null) {
        continue;
      }
      s['animator'].animate();
    }
    return list
  }

  manager.findPointInfo = function (info, config) {
    let findRoomInfo = (data, mapid) => {
      let roomInfo = findRoomInfoBlock(mapid, data.roomAreaId);
      if (roomInfo.result == false) {
        return {
          result: false
        }
      }
      return {
        result: true,
        mapid: mapid,
        dataInfo: roomInfo,
        position: {
          x: roomInfo.x,
          y: 3,
          z: roomInfo.y
        }
      }
    }

    if (info == null || typeof (info) == 'undefined') {
      return {
        result: false,
        mapid: config.mapid
      }
    }
    let type = ''
    if (analysis.jsonKeyIsExist(info, ['type'])) {
      type = info.type
    }
    const dataMapid = info.mapid
    if (analysis.jsonKeyIsExist(pointsInfo, [type]) == false) {
      return {
        result: false,
        mapid: dataMapid
      }
    }
    const list = pointsInfo[type];
    let result = {
      result: false,
      mapid: dataMapid
    }
    for (let i = 0; i < list.length; i++) {
      const data = list[i]
      if (data.id != info.id) {
        continue;
      }
      selectInfo = {}
      selectInfo[type] = {}
      selectInfo[type][data.id + ''] = ''
      if (type == 'room') {
        result = findRoomInfo(data, dataMapid);
      } else {
        result = {
          result: true,
          mapid: dataMapid,
          dataInfo: data,
          position: {
            x: data.x,
            y: data.y,
            z: data.z
          }
        }
      }
      break;
    }
    return result;
  }

  manager.clickTogethor = function (type, data, object) {
    return {
      result: false
    }
  }

  manager.clickEvent = function (object, mapid) {
    if (typeof (object['meshType']) == 'undefined') {
      return {
        result: false
      }
    }
    const type = object['meshType']
    const data = object['dataInfo']
    const selectInfo = {}
    selectInfo[type] = {}
    if (type == 'point') {
      selectInfo[type][data.id + ''] = ''
      this.selectMesh({
        mapid: mapid
      }, selectInfo)
      return {
        result: true,
        data: data,
        type: 'point'
      }
    } else if (type == 'together') {
      return this.clickTogethor(type, data, object)
    } else if (type == 'iBeacon') {
      selectInfo[type][data.mac + ''] = ''
      this.selectMesh({
        mapid: mapid
      }, selectInfo)
      return {
        result: true,
        msg: data.mac,
        type: type
      }
    }
    return {
      result: false
    }
  }

  manager.fillterList = function (config) {
    const type = config.type
    const mapid = config.mapid
    if (analysis.jsonKeyIsExist(pointsInfo, [type]) == false) {
      return [];
    }
    const list = []
    for (let i = 0; i < pointsInfo[type].length; i++) {
      const p = pointsInfo[type][i]
      if (p.mapid != mapid) {
        continue;
      }
      list.push(p)
    }
    return list
  }

  manager.returnDataList = function (type) {
    if (analysis.jsonKeyIsExist(pointsInfo, [type]) == false) {
      return {}
    }
    const list = pointsInfo[type]
    const info = {}
    for (let i = 0; i < list.length; i++) {
      const p = list[i]
      const mapid = p.mapid
      let l = []
      if (analysis.jsonKeyIsExist(info, [mapid])) {
        l = info[mapid]
      } else {
        info[mapid] = l
      }
      l.push({
        x: p.x,
        y: 0,
        z: p.z
      })
    }
    return info
  }

  manager.getSelectIbeaconPositon = function (config) {
    const mapid = config.mapid
    if (analysis.jsonKeyIsExist(pointsInfo, ['iBeacon']) == false) {
      return {
        result: false
      }
    }
    const list = pointsInfo['iBeacon']
    const dataList = []
    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      if (info.mapid != mapid) {
        continue
      }
      dataList.push({
        mac: info.mac,
        x: info.x,
        y: 2.5,
        z: info.z
      })
    }
    return {
      result: true,
      data: dataList
    }
  }

  manager.offSetIbeacon = function (config) {
    if (analysis.jsonKeyIsExist(selectInfo, ['iBeacon']) == false) {
      return
    }
    if (analysis.jsonKeyIsExist(pointsMesh, ['iBeacon']) == false) {
      return
    }
    const select = selectInfo['iBeacon']
    const list = pointsMesh['iBeacon']
    const x = config.x
    const y = config.y
    for (let i = 0; i < list.length; i++) {
      const mesh = list[i]
      if (mesh.visible == false) {
        continue
      }
      const info = mesh['dataInfo']
      if (analysis.jsonKeyIsExist(select, [info.mac]) == false || info.mapid != config.mapid) {
        continue
      }
      mesh.position.x = parseFloat(mesh.position.x) + parseFloat(x)
      mesh.position.z = parseFloat(mesh.position.z) + parseFloat(y)
      info.x = mesh.position.x
      info.z = mesh.position.z
    }
  }

  // 选择区域内的信标
  manager.selectAreasIbeacon = function (config) {
    let list = []
    if (analysis.jsonKeyIsExist(pointsInfo, ['iBeacon'])) {
      list = pointsInfo['iBeacon']
    }
    if (list.length == 0) {
      return
    }
    const areas = config.areas
    const select = {}
    for (let i = 0; i < list.length; i++) {
      const ibeacon = list[i]
      if (analysis.isInPolygon([ibeacon.x, 0, ibeacon.z], areas) == false) {
        continue;
      }
      select[ibeacon.mac] = ''
    }
    selectInfo['iBeacon'] = select
    updatePointInfo(config)
  }

  manager.returnIbeaconList = function () {
    if (analysis.jsonKeyIsExist(pointsMesh, ['iBeacon']) == false) {
      return []
    }
    return pointsMesh['iBeacon']
  }

  manager.updatePointMesh = function (config) {
    updatePointInfo(config)
  }

  manager.getIBeacon = function (intersects, config) {
    if (intersects.length == 0) {
      return {
        result: false
      }
    }
    const ibeaconList = []
    for (let i = 0; i < intersects.length; i++) {
      const info = intersects[i].object
      ibeaconList.push(info.dataInfo.mac)
    }
    const ibeacon = intersects[0].object
    selectInfo['iBeacon'] = {}
    selectInfo['iBeacon'][ibeacon.dataInfo.mac] = ''
    updatePointInfo(config)
    return {
      result: true,
      mac: ibeacon.dataInfo.mac,
      list: ibeaconList
    }
  }


  manager.animationStart = function () {
    if (animationStart == false) {
      return;
    }
    let list = []
    if (analysis.jsonKeyIsExist(pointsMesh, ['point'])) {
      list = pointsMesh['point']
    }

    for (let i = 0; i < list.length; i++) {
      let s = list[i];
      if (typeof s['animation'] == 'undefined' || s['animation'] == false) {
        continue;
      }
      if (typeof s['animator'] == 'undefined' || s['animator'] == null) {
        continue;
      }
      s['animator'].animate();
    }

    let baseList = []
    if (analysis.jsonKeyIsExist(pointsMesh, ['baseStation'])) {
      baseList = pointsMesh['baseStation']
    }
    for (let i = 0; i < baseList.length; i++) {
      let g = baseList[i];
      if (g.visible == false) {
        continue;
      }
      for (let j = 0; j < g.children.length; j++) {
        let mesh = g.children[j];
        if (mesh.type != 'Group') {
          continue;
        }
        let visible = mesh['animationStart'];
        if (visible == false) {
          continue;
        }
        mesh.rotation.y = mesh.rotation.y + 0.1;
      }
    }
  }

  manager.updateIBeaconList = function (iBeaconlist, config) {
    let list = []
    if (analysis.jsonKeyIsExist(pointsInfo, ['iBeacon'])) {
      list = pointsInfo['iBeacon']
    } else {
      pointsInfo['iBeacon'] = list
    }
    for (let i = 0; i < iBeaconlist.length; i++) {
      const newInfo = iBeaconlist[i]
      let isExist = false
      for (let j = 0; j < list.length; j++) {
        const info = list[j]
        if (info.mac == newInfo.mac) {
          isExist = true
          for (const key in newInfo) {
            info[key] = newInfo[key]
          }
          break
        }
        if (isExist == false) {
          list.push(newInfo)
        }
      }
    }
    updatePointInfo(config);
  }

  manager.changeIbeaconMode = function (config) {
    iBeaconMode = config.type
    selectInfo['iBeacon'] = {}
    updatePointInfo(config);
  }

  manager.mouseMoveOnPoint = function (object, mapid, config) {
    const res = this.clickEvent(object, mapid, [{
      object: object
    }])
    res['pointResult'] = false
    if (res.result == false) {
      return res;
    }
    const camera = config.camera
    const windowWidth = config.windowWidth
    const windowHeight = config.windowHeight
    const pointRes = analysis.getSpriteToDiv(object, 10, windowWidth, windowHeight, camera, config.isPhone, config
      .dis)
    const point = {
      y: pointRes.y,
      x: pointRes.x + pointRes.w / 2.0
    }
    res['pointResult'] = true
    res['point'] = point
    return res
  }

  manager.returnClickMesh = function() {
    let list = []
    for (let pointsMeshKey in pointsMesh) {
      let dataList = pointsMesh[pointsMeshKey]
      for (let i = 0; i < dataList.length; i++) {
        let mesh = dataList[i]
        if (mesh.visible == false) {
          continue;
        }
        list.push(mesh)
      }
    }
    return list
  }

  manager.deleteAll = function () {
    for (const pointsMeshKey in pointsMesh) {
      if (pointsMeshKey == 'baseStation') {
        continue;
      }
      const dataList = pointsMesh[pointsMeshKey]
      for (let i = 0; i < dataList.length; i++) {
        const mesh = dataList[i]
        if (mesh.parent != null) {
          manager.remove(mesh)
        }
        mesh.geometry.dispose()
        mesh.material.dispose()
      }
    }
    pointsMesh = {}
    pointsInfo = {}
    if (manager.parent != null) {
      manager.parent.remove(manager)
    }
  }

  pointGroup_togethor(manager);

  return manager;
}
