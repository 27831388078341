import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";
import {createHeatMap} from "./heatMapJS";
import {mapConfig} from "../../MapConfig";

export function createHeatMapManager () {
  let heatManager = new Object();

  let heat = createHeatMap();

  let analysis = createAnalaysisInfo();

  let mapSetInfo = mapConfig();

  let locationListBlock = null;

  heatManager.getLocationList = function (callBack) {
    locationListBlock = callBack;
  }

  let changeMapBlock = null;
  heatManager.changeMapAction = function (callBack) {
    changeMapBlock = callBack;
  }

  let mapInfoBlock = null;

  heatManager.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }


  heatManager.addGroup = function (scene) {
    if (heat.parent != null) {
      scene.remove(heat);
    }
    scene.add(heat);
  }

  heatManager.showHeatZone = function (config) {
    let nameInfoRes = locationListBlock(config['mapid'])
    if (nameInfoRes.result == false) {
      return
    }
    config['zoneInfo'] = nameInfoRes.data
    heat.showHeatZone(config);
  }

  heatManager.showHeatMap = function (config) {
    let mapid = config.mapid
    changeMapBlock(mapid);
    let data = config.data
    let update = false
    if (analysis.jsonKeyIsExist(config, ['update'])) {
      update = config['update']
    }

    let mapInfo = mapInfoBlock();

    let floorHieght = mapInfo['floorHieght']
    let border = mapInfo['borderInfo']
    let zero = mapInfo['zeroInfo']
    let divId = mapInfo['divId']
    let commonGroup = mapInfo['commonGroup']
    let nowGroup = mapInfo['nowGroup']

    let setting = {
      useCommon: mapSetInfo.useCommonGroup,
      polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
      polygonOffsetUnits: mapSetInfo.polygonOffsetUnits,
      y: floorHieght,
      mapid: mapid,
      fence: border,
      divId: divId,
      data: data,
      zeroAeras: zero.areas,
      update: update
    }
    heat.createHeatMapCanvas(setting, nowGroup, commonGroup)
  }

  heatManager.deleteAll = function () {
    heat.deleteAll();
  }

  return heatManager;
}
