import {createSimulation} from "./simulation";

export function createSimulationManager() {
  let manager = new Object();

  let simulation = createSimulation();

  manager.addGroup = function (scene) {
    if (simulation.parent != null) {
      scene.remove(simulation);
    }
    scene.add(simulation);
  }

  manager.simulationMode = function () {
    return simulation.simulationMode();
  }

  manager.isSimulation = function () {
    return {
      result: simulation.simulationMode()
    }
  }

  let realseSimulationBlock = null;

  manager.realseSimulationAction = function (callBack) {
    realseSimulationBlock = callBack;
  }

  let startSimulationBlock = null;

  manager.startSimulationAction = function (callBack) {
    startSimulationBlock = callBack;
  }

  let pathInfoBlock = null;

  manager.pathInfoAction = function (callBack) {
    pathInfoBlock = callBack;
  }

  let mapInfoBlock = null;

  manager.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  manager.realseSimulation = function () {
    simulation.stopAnimation()
    simulation.endSimulation()
    simulation.visible = false
    realseSimulationBlock({deleteGroupInfo: true});
  }

  let timeOunt = null; // 暂停模拟导航后一段时间可以恢复模拟导航

  // 暂停模拟导航
  manager.stopSimulation = function (isMoveEnd) {
    if (simulation.simulationMode() == false) {
      return
    }
    simulation.stopAnimation()
    realseSimulationBlock({deleteGroupInfo: false});
    if (timeOunt != null) {
      window.clearTimeout(timeOunt)
      timeOunt = null;
    }
    if (isMoveEnd == false) {
      return;
    }
    timeOunt = setTimeout(function () {
      simulation.animationStart()
    }, 3000)
  }

  // 开始模拟导航
  manager.startSimulation = function (start) {

    startSimulationBlock();

    let pathInfo = pathInfoBlock();

    simulation.startSimulation(pathInfo)
  }

  // 结束模拟导航
  manager.endSimulation = function () {
    manager.realseSimulation()
  }


  manager.deleteAll = function () {
    simulation.deleteAll();
  }

  manager.simulationZoom = function () {
    let object = simulation.returnSimulationOb()
    if (object == null) {
      return {
        result: false,
      }
    }
    return {
      result: true,
      object: object,
    }
  }

  let cameraAnimationBlock = null;

  manager.cameraAnimationAction = function (callBack) {
    cameraAnimationBlock = callBack;
  }

  let animationEndBlock = null;

  manager.animationEndAction = function (callBack) {
    animationEndBlock = callBack;
  }

  let animationUpdateBlock = null;

  manager.animationUpdateAction = function (callBack) {
    animationUpdateBlock = callBack;
  }

  let azimuthalAngleBlock = null

  manager.azimuthalAngleAction = function (callBack) {
    azimuthalAngleBlock = callBack;
  }

  let changeMapBlock = null

  manager.changeMapAction = function (callBack) {
    changeMapBlock = callBack;
  }

  manager.returnSimulationStatus = function () {
    return simulation.returnSimulationStatus();
  }

  function simulationAnimationUpdate() {
    simulation.animationEndAction(() => {
      animationEndBlock();
    })

    simulation.animationUpdateAction((point, lastPoint, dis) => {
      animationUpdateBlock(point, lastPoint, dis)
    })

    simulation.animationMoveAction((point) => {
      let mapInfo = mapInfoBlock();
      let target = {
        target: {
          x: point.x,
          y: mapInfo['floorHeight'],
          z: point.z
        }
      }

      cameraAnimationBlock({target: target, rotate: {}, scale: {}, time: 400})
    })

    simulation.animationRotateAction((angle, time, callBack) => {
      let a = azimuthalAngleBlock();
      if (a - angle == 0) {
        callBack()
        return
      }
      cameraAnimationBlock({
        target: {}, rotate: {
          angleY: 0.01,
          angleXZ: angle
        }, scale: {}, time: time
      });
      setTimeout(() => {
        callBack()
      }, time + 50)
    })

    simulation.animationFirstZoom((point, callBack) => {
      let mapInfo = mapInfoBlock();
      let floorHeight = mapInfo['floorHeight'];

      let width = 100
      let height = 100
      let leftUp = {
        x: point.x - width,
        y: floorHeight,
        z: point.z - height
      }
      let rightDown = {
        x: point.x + width,
        y: floorHeight,
        z: point.z + height
      }

      cameraAnimationBlock({
        target: {target:point}, rotate: {
          angleY: 0.001,
          angleXZ: 0
        }, scale: {
          leftUp: leftUp,
          rightDown: rightDown
        }, time: 300
      })
      setTimeout(() => {
        callBack()
      }, 400)
    })

    simulation.changeMapid((mapid, angle, callBack) => {
      let mapInfo = mapInfoBlock();
      let nowMapid = mapInfo['mapid']
      let result = mapid != nowMapid
      if (result) {
        changeMapBlock(mapid);
      }
      setTimeout(()=>{
        callBack({
          result: result
        })
      }, 300)
    })
  }

  simulationAnimationUpdate();

  return manager;
}
