import {
  earcut
} from './earcut'
import * as THREE from 'three/build/three.js'

export function createAnalaysisInfo() {
  const js = new Object()

  /* ====================================== 判断 ======================================*/
  js.jsonKeyIsExist = function(json, keys) {
    if (keys == null || typeof (keys) == 'undefined' || json == null || typeof (json) == 'undefined' || keys.length ==
      0) {
      return false
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (key == null || typeof (key) == 'undefined') {
        continue
      }
      const value = json[key]
      if (value == null || typeof (value) == 'undefined') {
        return false
      }
    }
    return true
  }
  /* ====================================== ======================================*/

  js.findMax = function(list) {
    let max = null
    for (let i = 0; i < list.length; i++) {
      const num = list[i]
      if (i == 0) {
        max = num
      } else {
        max = max > num ? max : num
      }
    }
    return max
  }

  js.findMin = function(list) {
    let min = null
    for (let i = 0; i < list.length; i++) {
      const num = list[i]
      if (i == 0) {
        min = num
      } else {
        min = min < num ? min : num
      }
    }
    return min
  }

  js.setTestDiv = function(sprite2, scale, windowWidth, windowHeight, camera, isPhone, divId, dis) {
    const div = document.getElementById(divId)
    if (div == null || typeof (div) == 'undefined') {
      return
    }
    const result = spriteToDiv(sprite2, scale, windowWidth, windowHeight, camera, isPhone, dis)
    div.style.width = result.w + 'px'
    div.style.height = result.h + 'px'
    div.style.top = result.y + 'px'
    div.style.left = result.x + 'px'
  }

  function spriteToDiv(sprite2, scale, windowWidth, windowHeight, camera, isPhone, dis) {
    const position2 = sprite2.position
    const worldVector2 = new THREE.Vector3(
      position2.x,
      position2.y,
      position2.z
    )
    const standardVector2 = worldVector2.project(camera) // 世界坐标转标准设备坐标
    const a = windowWidth / 2
    const b = windowHeight / 2
    let x2 = Math.round(standardVector2.x * a + a) // 标准设备坐标转屏幕坐标
    let y2 = Math.round(-standardVector2.y * b + b) // 标准设备坐标转屏幕坐标
    let w2 = 1
    let h2 = 1
    let zoomNum = 1.1
    if (typeof sprite2.setting != 'undefined' && typeof sprite2.setting.zoomNum != 'undefined') {
      zoomNum = sprite2.setting.zoomNum
    }
    const w = sprite2['cWidth']
    const h = sprite2['cHeight']
    w2 = windowWidth * sprite2.scale.x * 1.2
    h2 = w2 * sprite2.scale.y / sprite2.scale.x * 1.2
    if (js.jsonKeyIsExist(sprite2, ['strWidth'])) {
      const strWidth = sprite2['strWidth']
      if (strWidth != -1) {
        const widthScale = strWidth / w
        w2 = w2 * widthScale * zoomNum
      }
    }
    x2 = x2 - w2 / 2.0
    y2 = y2 - h2
    return {
      x: x2,
      y: y2,
      w: w2,
      h: h2
    }
  }

  js.getSpriteSize = function(sprite1, scale, width, height, camera, isPhone, dis) {
    return spriteToDiv(sprite1, scale, width, height, camera, isPhone, dis)
  }

  // 检测两个标注sprite是否碰撞
  js.isPOIRect = function(sprite1, sprite2, camera, width, height, scale, isPhone, dis) {
    const result1 = spriteToDiv(sprite1, scale, width, height, camera, isPhone, dis)
    const result2 = spriteToDiv(sprite2, scale, width, height, camera, isPhone, dis)
    const x1 = result1.x
    const y1 = result1.y
    const w1 = result1.w
    const h1 = result1.h

    const x2 = result2.x
    const y2 = result2.y
    const w2 = result2.w
    const h2 = result2.h

    const leftUp1 = {
      x: x1,
      y: y1
    }
    const rightDown1 = {
      x: x1 + w1,
      y: y1 + h1
    }

    const leftUp2 = {
      x: x2,
      y: y2
    }
    const rightDown2 = {
      x: x2 + w2,
      y: y2 + h2
    }

    if (leftUp2.x > rightDown1.x || rightDown2.x < leftUp1.x) {
      return {
        result: false
      }
    } else if (leftUp2.y > rightDown1.y || rightDown2.y < leftUp1.y) {
      return {
        result: false
      }
    } else {
      return {
        result: true
      }
    }
  }

  js.isInPolygon = function(checkPoint, poly, isDim) {
    let dim = false
    if (typeof isDim != 'undefined' && isDim != null) {
      dim = isDim
    }

    let px = checkPoint[0]
    let py = checkPoint[2]

    if (dim) {
      px = parseInt(px)
      py = parseInt(py)
    }

    let flag = false

    for (let i = 0, l = poly.length, j = l - 1; i < l; j = i, i++) {
      let sx = poly[i][0]
      let sy = poly[i][2]
      let tx = poly[j][0]
      let ty = poly[j][2]

      if (dim) {
        sx = parseInt(sx)
        sy = parseInt(sy)
        tx = parseInt(tx)
        ty = parseInt(ty)
      }

      // 点与多边形顶点重合
      if ((sx === px && sy === py) || (tx === px && ty === py)) {
        return true
      }
      if (tx == sx && px == tx && py >= (sy > ty ? ty : sy) && py <= (sy > ty ? sy : ty)) {
        return true
      }
      if (sy == ty && py == ty && px >= (sx > tx ? tx : sx) && px <= (sx > tx ? sx : tx)) {
        return true
      }

      // 判断线段两端点是否在射线两侧
      if ((sy < py && ty >= py) || (sy >= py && ty < py)) {
        // 线段上与射线 Y 坐标相同的点的 X 坐标
        const x = sx + (py - sy) * (tx - sx) / (ty - sy)

        // 点在多边形的边上
        if (x === px) {
          return true
        }

        // 射线穿过多边形的边界
        if (x > px) {
          flag = !flag
        }
      }
    }

    // 射线穿过多边形边界的次数为奇数时点在多边形内
    return flag
  }

  js.segmentsIntr = function(a, b, c, d) {
    /** 1 解线性方程组, 求线段交点. **/
    // 如果分母为0 则平行或共线, 不相交
    const by = b[2]
    const bx = b[0]
    const ay = a[2]
    const ax = a[0]
    const cy = c[2]
    const cx = c[0]
    const dy = d[2]
    const dx = d[0]
    const denominator = (by - ay) * (dx - cx) - (ax - bx) * (cy - dy)
    if (denominator == 0) {
      return [false, 0, 0]
    }

    // 线段所在直线的交点坐标 (x , y)
    const x = ((bx - ax) * (dx - cx) * (cy - ay) +
      (by - ay) * (dx - cx) * ax -
      (dy - cy) * (bx - ax) * cx) / denominator
    const y = -((by - ay) * (dy - cy) * (cx - ax) +
      (bx - ax) * (dy - cy) * ay -
      (dx - cx) * (by - ay) * cy) / denominator

    /** 2 判断交点是否在两条线段上 **/
    if (
      // 交点在线段1上
      (x - ax) * (x - bx) <= 0 && (y - ay) * (y - by) <= 0
      // 且交点也在线段2上
      &&
      (x - cx) * (x - dx) <= 0 && (y - cy) * (y - dy) <= 0
    ) {
      // 返回交点p
      return [true, x.toFixed(2), y.toFixed(2)]
    }
    // 否则不相交
    return [false, 0, 0]
  }

  // 检查连线是否错误
  js.checkLayersIsWorng = function(layers) {
    if (layers.length <= 3) {
      return true
    }
    let flag = true

    for (let i = 0; i < layers.length; i++) {
      let startNext = i + 1
      if (startNext == layers.length) {
        startNext = 0
      }
      const startP1 = layers[i]
      const startP2 = layers[startNext]
      for (let j = startNext + 1; j < layers.length; j++) {
        const endStart = j
        let end = endStart + 1
        if (end == layers.length) {
          end = 0
        }
        const endP1 = layers[endStart]
        const endP2 = layers[end]
        const seg = js.segmentsIntr(startP1, startP2, endP1, endP2)
        if (seg[0] == true) {
          const x = seg[1]
          const y = seg[2]
          if (this.isSamePoint(x, y, startP1) || this.isSamePoint(x, y, startP2) || this.isSamePoint(x, y, endP1) ||
            this.isSamePoint(x, y, endP2)) {

          } else {
            flag = false
            break
          }
        }
      }
    }
    return flag
  }

  // 判断是否是同一个点
  js.isSamePoint = function(x1, y1, p) {
    if (x1 == p[0] && y1 == p[2]) {
      return true
    } else {
      return false
    }
  }

  function arrType(points) {
    let sum_x = 0
    let sum_y = 0
    let sum_area = 0
    const p0 = points[0]
    if (points.length == 1) {
      return [p0[2], p0[0]]
    }
    let p1 = points[1]
    if (points.length == 2) {
      return [(p0[2] + p1[2]) / 2.0, (p0[0] + p1[0]) / 2.0]
    }
    for (let i = 2; i < points.length; i++) {
      const p2 = points[i]
      const area = Area(points[0], p1, p2)
      sum_area += area
      sum_x += (points[0][2] + p1[2] + p2[2]) * area
      sum_y += (points[0][0] + p1[0] + p2[0]) * area
      p1 = p2
    }
    if (sum_area == 0) {
      sum_area = 1
    }
    const xx = (sum_x / sum_area) / 3
    const yy = (sum_y / sum_area) / 3

    if (js.isInPolygon([yy, 0, xx], points) == false) {
      let minDis = 65535
      let newY = xx
      let newX = yy
      for (let i = 0; i < points.length - 1; i++) {
        const p1 = points[i]
        const p2 = points[i + 1]
        const res = projectivePointToLine(p1, p2, [yy, 0, xx])
        if (res.result >= 0 && res.result <= 1) {
          const newP = res.point
          const dis = Math.sqrt((yy - newP.x) * (yy - newP.x) + (xx - newP.y) * (xx - newP.y))
          if (dis <= minDis) {
            minDis = dis
            newX = newP.x
            newY = newP.z
          }
        }
      }
      return [parseFloat(newY.toFixed(1)), parseFloat(newX.toFixed(1))]
    }
    return [parseFloat(xx.toFixed(1)), parseFloat(yy.toFixed(1))]
  }

  function projectivePointToLine(p1, p2, p) {
    const startP = new THREE.Vector3(p1[0], 0, p1[2])
    const endP = new THREE.Vector3(p2[0], 0, p2[2])
    const line = new THREE.Line3(startP, endP)
    const target = new THREE.Vector3(0, 0, 0)
    line.closestPointToPoint(new THREE.Vector3(p[0], 0, p[2]), false, target)
    const scale = line.closestPointToPointParameter(target, false)
    return {
      result: scale,
      point: target
    }
  }

  js.getArreageCenter = function(points) {
    let x = 0
    let y = 0
    for (let i = 0; i < points.length; i++) {
      const p = points[i]
      x = x + p[0]
      y = y + p[2]
    }
    x = x / points.length
    y = y / points.length
    return {
      x: x,
      y: y
    }
  }

  js.getPolygonAreaCenter = function(points) {
    if (points.length == 0) {
      return [0, 0]
    }
    const p0 = points[0]
    const change = !!this.jsonKeyIsExist(p0, ['x', 'z'])
    if (change) {
      const newPoints = []
      for (let i = 0; i < points.length; i++) {
        const p = points[i]
        newPoints.push([p.x, 0, p.z])
      }
      return arrType(newPoints)
    } else {
      return arrType(points)
    }
  }

  function Area(p0, p1, p2) {
    let area = 0.0
    area = p0[0] * p1[2] + p1[0] * p2[2] + p2[0] * p0[2] - p1[0] * p0[2] - p2[0] * p1[2] - p0[0] * p2[2]
    return area / 2
  }

  js.isInfenceFunction = function(x1, y1, x2, y2, x3, y3, x4, y4, x, y) {
    const maxX12 = js.findMax([x1, x2])
    const maxY12 = js.findMax([y1, y2])
    const maxX34 = js.findMax([x3, x4])
    const maxY34 = js.findMax([y3, y4])

    const minX12 = js.findMin([x1, x2])
    const minY12 = js.findMin([y1, y2])
    const minX34 = js.findMin([x3, x4])
    const minY34 = js.findMin([y3, y4])

    if (x >= js.findMax([minX12, minX34]) && x <= js.findMin([maxX12, maxX34]) && y >= js.findMax([minY12, minY34]) &&
      y <= js.findMin([maxY12, maxY34])) {
      return true
    }

    return false
  }

  // x1 y1 为手机的定位 x2 y2 为待测点 x3 y3 x4 y4 为墙的连线
  js.twoLineXiangjiao = function(x1, y1, x2, y2, x3, y3, x4, y4, isInFence /* 是否在范围内 */) {
    const xCount1 = x2 - x1
    const yCount1 = y2 - y1

    const xCount2 = x4 - x3
    const yCount2 = y4 - y3

    if (xCount1 == 0) {
      // 当前的手机的定位与待测点的连线垂直X轴向下
      if (xCount2 == 0) {
        // 平行
        return {
          result: false
        }
      } else {
        const info = getLineWhenOneLineVertical(x1, x3, y3, x4, y4, isInFence, y1, y2)
        if (isInFence == true && info.isIn == true && yCount2 != 0) {
          const k = yCount2 / xCount2
          const b = y3 - k * x3
          const _k_ = -1 / k
          const _b_ = y2 - _k_ * x2
          info.x = (_b_ - b) / (k - _k_)
          info.y = k * info.x + b
        }
        return info
      }
    } else {
      if (xCount2 == 0) {
        // 墙体垂直向下
        const info = getLineWhenOneLineVertical(x3, x1, y1, x2, y2, isInFence, y3, y4)
        if (isInFence == true && info.isIn == true && y2 >= (y3 > y4 ? y4 : y3) && y2 <= (y3 > y4 ? y3 : y4)) {
          info.y = y2
        }
        return info
      }
      if (yCount1 == 0 && yCount2 == 0) {
        // 平行
        return {
          result: false
        }
      } else if (yCount2 == 0) {
        if (isInFence == true) {
          if (js.isInfenceFunction(x1, y1, x2, y2, x3, y3, x4, y4, x2, y3) == true) {
            return {
              result: true,
              x: x2,
              y: y3,
              isIn: true
            }
          } else {
            const k1 = yCount1 / xCount1
            const b1 = y1 - k1 * x1
            const newx = (y3 - b1) / k1
            const newy = y3
            if (js.isInfenceFunction(x1, y1, x2, y2, x3, y3, x4, y4, newx, newy) == true) {
              return {
                result: true,
                x: newx,
                y: newy,
                isIn: true
              }
            } else {
              return {
                result: false,
                x: 0,
                y: 0,
                isIn: false
              }
            }
          }
        }
      }

      const k1 = yCount1 / xCount1
      const b1 = y1 - k1 * x1

      const k2 = yCount2 / xCount2
      const b2 = y3 - k2 * x3

      let newx = (b2 - b1) / (k1 - k2)
      let newy = k1 * newx + b1
      if (isInFence == true) {
        const maxx = js.findMax([x1, x2, x3, x4])
        const minx = js.findMin([x1, x2, x3, x4])

        const maxy = js.findMax([y1, y2, y3, y4])
        const miny = js.findMin([y1, y2, y3, y4])
        if (newx >= minx && newx <= maxx && newy >= miny && newy <= maxy) {
          const k = yCount2 / xCount2
          const b = y3 - k * x3
          const _k_ = -1 / k
          const _b_ = y2 - _k_ * x2
          newx = (_b_ - b) / (k - _k_)
          newy = k * newx + b

          if (js.isInfenceFunction(x1, y1, x2, y2, x3, y3, x4, y4, newx, newy) == true) {
            return {
              result: true,
              x: newx,
              y: newy,
              isIn: true
            }
          }
          return {
            result: true,
            x: newx,
            y: newy,
            isIn: false
          }
        } else {
          return {
            result: true,
            x: newx,
            y: newy,
            isIn: false
          }
        }
      } else {
        return {
          result: true,
          x: newx,
          y: newy
        }
      }
    }
  }

  js.getLinesIntersect = function(x1, y1, x2, y2, x3, y3, x4, y4) {
    const xCount1 = x2 - x1
    const yCount1 = y2 - y1

    const xCount2 = x4 - x3
    const yCount2 = y4 - y3

    if (yCount1 == 0 && yCount2 == 0) {
      // 平行
      return {
        result: false
      }
    }
    const target = new THREE.Vector3(0, 0, 0)

    const line1 = new THREE.Line3(new THREE.Vector3(x1, 0, y1), new THREE.Vector3(x2, 0, y2))
    const line2 = new THREE.Line3(new THREE.Vector3(x3, 0, y3), new THREE.Vector3(x4, 0, y4))

    function returnResult() {
      const res1 = parseFloat(line1.closestPointToPointParameter(target, false).toFixed(2))
      const res2 = parseFloat(line2.closestPointToPointParameter(target, false).toFixed(2))
      const isInLine1 = res1 >= 0 && res1 <= 1
      const isInLine2 = res2 >= 0 && res2 <= 1
      if (isInLine1 && isInLine2) {
        return {
          result: true,
          x: parseFloat(target.x.toFixed(2)),
          y: parseFloat(target.z.toFixed(2)),
          res1: res1,
          res2: res2
        }
      }
      return {
        result: false
      }
    }

    if (xCount1 == 0) {
      // 当前的手机的定位与待测点的连线垂直X轴向下
      if (xCount2 == 0) {
        // 平行
        return {
          result: false
        }
      } else {
        const info = getLineWhenOneLineVertical(x1, x3, y3, x4, y4, false, y1, y2)
        target.x = parseFloat(info.x.toFixed(2))
        target.z = parseFloat(info.y.toFixed(2))
        return returnResult()
      }
    }
    if (xCount2 == 0) {
      // 垂直向下
      const info = getLineWhenOneLineVertical(x3, x1, y1, x2, y2, false, y3, y4)
      target.x = parseFloat(info.x.toFixed(2))
      target.z = parseFloat(info.y.toFixed(2))
      return returnResult()
    }

    const k1 = yCount1 / xCount1
    const b1 = y1 - k1 * x1

    const k2 = yCount2 / xCount2
    const b2 = y3 - k2 * x3

    if (k1 == k2) {
      // 平行
      return {
        result: false
      }
    }

    let newx = 0
    let newy = 0
    if (yCount2 == 0) {
      newy = y3
      newx = (newy - b1) / k1
    } else {
      newx = (b2 - b1) / (k1 - k2)
      newy = k1 * newx + b1
    }
    target.x = parseFloat(newx.toFixed(2))
    target.z = parseFloat(newy.toFixed(2))
    return returnResult()
  }

  // 计算两段线段相交的交点
  js.twoLineXiangjiaoPoint = function(x1, y1, x2, y2, x3, y3, x4, y4, isInFence /* 是否在范围内 */) {
    const xCount1 = x2 - x1
    const yCount1 = y2 - y1

    const xCount2 = x4 - x3
    const yCount2 = y4 - y3

    const maxx1 = js.findMax([x1, x2])
    const minx1 = js.findMin([x1, x2])

    const maxy1 = js.findMax([y1, y2])
    const miny1 = js.findMin([y1, y2])

    const maxx2 = js.findMax([x3, x4])
    const minx2 = js.findMin([x3, x4])

    const maxy2 = js.findMax([y3, y4])
    const miny2 = js.findMin([y3, y4])
    let isIn = false

    if (xCount1 == 0) {
      // 当前的手机的定位与待测点的连线垂直X轴向下
      if (xCount2 == 0) {
        // 平行
        return {
          result: false
        }
      } else {
        const info = getLineWhenOneLineVertical(x1, x3, y3, x4, y4, false, y1, y2)
        if (info.x >= minx1 && info.x <= maxx1 && info.y >= miny1 && info.y <= maxy1 && info.x >= minx2 && info.x <=
          maxx2 && info.y >= miny2 && info.y <= maxy2) {
          isIn = true
        }

        return {
          result: true,
          x: info.x,
          y: info.y,
          isIn: isIn
        }
      }
    }
    if (xCount2 == 0) {
      // 垂直向下
      const info = getLineWhenOneLineVertical(x3, x1, y1, x2, y2, false, y3, y4)

      if (info.x >= minx1 && info.x <= maxx1 && info.y >= miny1 && info.y <= maxy1 && info.x >= minx2 && info.x <=
        maxx2 && info.y >= miny2 && info.y <= maxy2) {
        isIn = true
      }

      return {
        result: true,
        x: info.x,
        y: info.y,
        isIn: isIn
      }
    }

    if (yCount1 == 0 && yCount2 == 0) {
      // 平行
      return {
        result: false
      }
    }

    const k1 = yCount1 / xCount1
    const b1 = y1 - k1 * x1

    const k2 = yCount2 / xCount2
    const b2 = y3 - k2 * x3

    let newx = 0
    let newy = 0
    if (yCount2 == 0) {
      newy = y3
      newx = (newy - b1) / k1
    } else {
      newx = (b2 - b1) / (k1 - k2)
      newy = k1 * newx + b1
    }

    if (newx >= minx1 && newx <= maxx1 && newy >= miny1 && newy <= maxy1 && newx >= minx2 && newx <= maxx2 && newy >=
      miny2 && newy <= maxy2) {
      isIn = true
    }
    return {
      result: true,
      x: newx,
      y: newy,
      isIn: isIn
    }
  }

  function getLineWhenOneLineVertical(verticalX, x_1, y_1, x_2, y_2, isInFence, verticalY1, verticalY2) {
    const k2 = (y_2 - y_1) / (x_2 - x_1)
    const b = y_1 - k2 * x_1
    let newx = 0
    let newy = 0

    if (verticalY1 == verticalY2) {
      newy = verticalY1
      newx = (newy - b) / k2
    } else {
      newx = verticalX
      newy = k2 * newx + b
    }

    if (isInFence == true) {
      const maxx = x_1 > x_2 ? x_1 : x_2
      const minx = x_1 > x_2 ? x_2 : x_1
      const maxy = y_1 > y_2 ? y_1 : y_2
      const miny = y_1 > y_2 ? y_2 : y_1

      const maxVerticalY = verticalY1 > verticalY2 ? verticalY1 : verticalY2
      const minVerticalY = verticalY1 < verticalY2 ? verticalY1 : verticalY2

      if (newx == verticalX && newx >= minx && newx <= maxx && newy >= minVerticalY && newy <= maxVerticalY && newy >=
        miny && newy <= maxy) {
        return {
          result: true,
          x: newx,
          y: newy,
          isIn: true
        }
      } else {
        // 不在范围内
        return {
          result: true,
          x: newx,
          y: newy,
          isIn: false
        }
      }
    } else {
      return {
        result: true,
        x: newx,
        y: newy
      }
    }
  }

  // 获取一个点到一线段的距离
  js.getDistanceForPointToLine = function(x1, y1, x2, y2, x3, y3) {
    const xCount = x2 - x1
    const yCount = y2 - y1
    let dis = 0
    if (xCount == 0) {
      dis = Math.abs(x3 - x2)
      if (y3 >= js.findMin([y1, y2]) && y3 <= js.findMax([y1, y2])) {
        // 在范围内
        return {
          isIn: true,
          dis: dis
        }
      }
      return {
        isIn: false,
        dis: dis
      }
    } else if (yCount == 0) {
      dis = Math.abs(y3 - y2)
      if (x3 >= js.findMin([x1, x2]) && x3 <= js.findMax([x1, x2])) {
        // 在范围内
        return {
          isIn: true,
          dis: dis
        }
      }
      return {
        isIn: false,
        dis: dis
      }
    }
    const k1 = yCount / xCount
    const b1 = y1 - k1 * x1

    const k2 = -1 / k1
    const b2 = y3 - k2 * x3

    const x = (b2 - b1) / (k1 - k2)
    const y = k1 * x + b1
    const maxx = js.findMax([x1, x2])
    const minx = js.findMin([x1, x2])

    const maxy = js.findMax([y1, y2])
    const miny = js.findMin([y1, y2])

    dis = Math.sqrt((x - x3) * (x - x3) + (y - y3) * (y - y3))

    if (x >= minx && x <= maxx && y >= miny && y <= maxy) {
      return {
        isIn: true,
        dis: dis
      }
    }
    return {
      isIn: false,
      dis: dis
    }
  }

  // 判断线段是否穿墙
  js.judgeIsCorssWall = function(line, roomInfo) {
    const start = line[0]
    const end = line[1]
    if (js.jsonKeyIsExist(roomInfo, ['leftUp', 'rightDown'])) {
      const leftUp = roomInfo['leftUp']
      const rightDown = roomInfo['rightDown']
      const minX = js.findMin([start.x, end.x])
      const minZ = js.findMin([start.z, end.z])
      const maxX = js.findMax([start.x, end.x])
      const maxZ = js.findMax([start.z, end.z])
      const res1 = maxX < leftUp.x
      const res2 = minX > rightDown.x
      const res3 = maxZ < leftUp.z
      const res4 = minZ > rightDown.z
      if (res1 || res2 || res3 || res4) {
        return false
      }
    }

    const areas = roomInfo['areas']
    const minDis = 0.1
    for (let i = 0; i < areas.length; i++) {
      const p = areas[i]
      const px = p[0]
      const pz = p[2]
      let next = i + 1
      if (next >= areas.length) {
        next = 0
      }
      const nextP = areas[next]
      const nextPx = nextP[0]
      const nextPz = nextP[2]
      const result = js.twoLineXiangjiaoPoint(start.x, start.z, end.x, end.z, px, pz, nextPx, nextPz, true)
      if (result.isIn == true) {
        return true
      }

      // let res = js.getLinesIntersect(start.x, start.z, end.x, end.z, px, pz, nextPx, nextPz)
      // if (res.result) {
      //   return true
      // }

      const dis1 = js.getDistanceForPointToLine(start.x, start.z, end.x, end.z, px, pz)
      const dis2 = js.getDistanceForPointToLine(start.x, start.z, end.x, end.z, nextPx, nextPz)
      if (dis1.isIn == true && dis1.dis <= minDis) {
        return true
      }

      if (dis2.isIn == true && dis2.dis <= minDis) {
        return true
      }
    }
    return false
  }

  // 两个直线的交点
  js.getLinesPoint = function(k1, b1, k2, b2) {
    if (k1 == k2) {
      return []
    }
    if ((k1 == 'Infinity' || k1 == '-Infinity') && (k2 == 'Infinity' || k2 == '-Infinity')) {
      return []
    } else if (k1 == 'Infinity' || k1 == '-Infinity') {
      const x = b1
      const y = k2 * x + b2
      return [x, 5, y]
    } else if (k2 == 'Infinity' || k2 == '-Infinity') {
      const x = b2
      const y = k1 * x + b1
      return [x, 5, y]
    } else {
      const x = (b2 - b1) / (k1 - k2)
      const y = k1 * x + b1
      return [x, 5, y]
    }
  }

  js.setPointToLine = function(x, z, path) {
    // 先找到x z 直连到路线的最短的距离， 然后将其纠正到路线上
    let minDis = 65535
    let minIndex = -1 // 纠正索引
    let minJiaodianX = x // 纠正位置x
    let minJiaodianZ = z // 纠正位置z
    const isCloseToEnd = false
    for (let i = path.length - 1; i > 0; i--) {
      let jiaodianDis = 65535 // 纠正后的点距离线段两头最近的距离
      const p1 = path[i]
      const p2 = path[i - 1]
      const x1 = p1.x
      const z1 = p1.z
      const x2 = p2.x
      const z2 = p2.z
      let jiaodianX = 0
      let jiaodianZ = 0
      // 找出路线一段 计算该x z 到该线段的垂直距离
      if (x1 == x2) {
        // 该线段平行Z轴
        jiaodianX = x1
        jiaodianZ = z
        jiaodianDis = Math.abs(z - z1) > Math.abs(z - z2) ? Math.abs(z - z2) : Math.abs(z - z1)
      } else if (z1 == z2) {
        // 该线段平行X轴
        jiaodianZ = z1
        jiaodianX = x
        jiaodianDis = Math.abs(x - x1) > Math.abs(x - x2) ? Math.abs(x - x2) : Math.abs(x - x1)
      } else {
        const xspace = x2 - x1
        const zspace = z2 - z1
        const k1 = zspace / xspace
        const b1 = z1 - k1 * x1
        const k2 = -1 / k1
        const b2 = z - k2 * x
        const jiaodian = this.getLinesPoint(k1, b1, k2, b2)
        if (jiaodian.length == 0) {
          continue
        }
        jiaodianX = jiaodian[0]
        jiaodianZ = jiaodian[2]
        const dis1 = Math.sqrt((jiaodianX - x1) * (jiaodianX - x1) + (jiaodianZ - z1) * (jiaodianZ - z1))
        const dis2 = Math.sqrt((jiaodianX - x2) * (jiaodianX - x2) + (jiaodianZ - z2) * (jiaodianZ - z2))
        jiaodianDis = dis1 > dis2 ? dis2 : dis1
      }
      const xIsOut = (jiaodianX - x1) * (jiaodianX - x2) > 0 // 是否超出x
      const zIsOut = (jiaodianZ - z1) * (jiaodianZ - z2) > 0 // 是否超出z

      const xCount = jiaodianX - x
      const zCount = jiaodianZ - z
      let dis = Math.sqrt(xCount * xCount + zCount * zCount)
      if (xIsOut || zIsOut) {
        dis = dis + jiaodianDis
      }
      if (minDis > dis) {
        // 找到距离最近的线段
        minDis = dis
        minIndex = i
        minJiaodianX = jiaodianX
        minJiaodianZ = jiaodianZ
      }
    }
    return {
      isCloseToEnd: isCloseToEnd,
      x: parseFloat(minJiaodianX.toFixed(2)),
      z: parseFloat(minJiaodianZ.toFixed(2)),
      index: minIndex
    }
  }

  js.IEVersion = function() {
    var userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf('Opera') > -1 // 判断是否Opera浏览器
    var isIE = userAgent.indexOf('compatible') > -1 &&
      userAgent.indexOf('MSIE') > -1 && !isOpera // 判断是否IE浏览器
    var isEdge = userAgent.indexOf('Edge') > -1 // 判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf('Firefox') > -1 // 判断是否Firefox浏览器
    var isSafari = userAgent.indexOf('Safari') > -1 &&
      userAgent.indexOf('Chrome') == -1 // 判断是否Safari浏览器
    var isChrome = userAgent.indexOf('Chrome') > -1 &&
      userAgent.indexOf('Safari') > -1 // 判断Chrome浏览器

    if (isIE) {
      var reIE = new RegExp('MSIE (\\d+\\.\\d+);')
      reIE.test(userAgent)
      var fIEVersion = parseFloat(RegExp['$1'])
      if (fIEVersion == 7) {
        return fIEVersion
      } else if (fIEVersion == 8) {
        return fIEVersion
      } else if (fIEVersion == 9) {
        return fIEVersion
      } else if (fIEVersion == 10) {
        return fIEVersion
      } else if (fIEVersion == 11) {
        return fIEVersion
      } else {
        return 1
      } // IE版本过低
      return 1
    }
    if (isEdge) {
      return 'Edge'
    }
    return -1
  }

  js.createLine = function(startP, endP) {
    const line = new THREE.Line3(new THREE.Vector3(startP.x, 0, startP.z), new THREE.Vector3(endP.x, 0, endP.z))
    return line
  }

  js.closestPointToPointParameter = function(line, x, y, z) {
    const target = new THREE.Vector3(x, y, z)
    const scale = line.closestPointToPointParameter(target, false)
    return parseFloat(scale.toFixed(2))
  }

  js.updateLineAndclosestPointToPointParameter = function(line, x, y, z, startP, endP) {
    line.start = new THREE.Vector3(startP.x, startP.y, startP.z)
    line.end = new THREE.Vector3(endP.x, endP.y, endP.z)
    const target = new THREE.Vector3(x, y, z)
    const scale = line.closestPointToPointParameter(target, false)
    return parseFloat(scale.toFixed(2))
  }

  js.closestPointToPoint = function(start, end, point) {
    const startP = new THREE.Vector3(start.x, start.y, start.z)
    const endP = new THREE.Vector3(end.x, end.y, end.z)
    const line = new THREE.Line3(startP, endP)
    const target = new THREE.Vector3(0, 0, 0)
    line.closestPointToPoint(new THREE.Vector3(point.x, point.y, point.z), false, target)
    return target
  }

  js.updateLineAndclosestPointToPointParameterInfo = function(line, x, y, z, startP, endP) {
    line.start = new THREE.Vector3(startP.x, startP.y, startP.z)
    line.end = new THREE.Vector3(endP.x, endP.y, endP.z)
    const target = new THREE.Vector3(0, 0, 0)
    line.closestPointToPoint(new THREE.Vector3(x, y, z), false, target)
    const scale = line.closestPointToPointParameter(target, false)
    return {
      scale: parseFloat(scale.toFixed(2)),
      point: target
    }
  }

  js.getDistance = function (point1, point2) {
    let x1 = this.getValue(point1, 'x', 0);
    let y1 = this.getValue(point1, 'y', 0);
    let z1 = this.getValue(point1, 'z', 0);

    let x2 = this.getValue(point2, 'x', 0);
    let y2 = this.getValue(point2, 'y', 0);
    let z2 = this.getValue(point2, 'z', 0);

    return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2) + (z1 - z2) * (z1 - z2))
  }

  js.getValue = function (data, key, defaultValue) {
    if (this.jsonKeyIsExist(data, [key]) == false) {
      return defaultValue;
    }
    return  data[key]
  }


  js.getSpriteToDiv = function(sprite2, scale, windowWidth, windowHeight, camera, isPhone, dis) {
    return spriteToDiv(sprite2, scale, windowWidth, windowHeight, camera, isPhone, dis);
  }

  return js
}
