export function createUserDirectionShader() {
  const shader = new Object()

  shader.returnVertexShader = function() {
    return `
        uniform float width;
        uniform float height;
        uniform float centerArcX;
        uniform float centerArcY;
        uniform float centerArcRadiu;
        uniform float centerArcOutsideRadiu;
        uniform float insideRadiu;
        uniform float outsideRadiu;
        uniform float closeWise;
        uniform float angle;
        varying vec2 vUv;
        void main() {
          vUv = vec2(position.x / width, position.y / height);
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
    `
  }

  shader.returnFragmentShader = function() {
    return `
      uniform float width;
      uniform float height;
      uniform float centerArcX;
      uniform float centerArcY;
      uniform float centerArcRadiu;
      uniform float centerArcOutsideRadiu;
      uniform float insideRadiu;
      uniform float outsideRadiu;
      uniform float closeWise;
      uniform float angle;
      varying vec2 vUv;
      void main() {
        vec4 color = vec4(1.0,1.0,1.0,1.0);
        if (angle >= 0.0 && angle <= 45.0) {
          color = vec4(0.0,1.0,0.0,1.0);
        } else if (angle > 45.0 && angle <= 90.0) {
          color = vec4(245.0 / 255.5,134.0 / 255.5,52.0 / 255.5,1.0);
        } else {
          color = vec4(1.0,0.0,0.0,1.0);
        }

        float arcX = 0.0;
        float arcY = -0.33;
        float arcRadiu = centerArcRadiu / width;
        float arcOutSideRadiu = centerArcOutsideRadiu / width;
        float arcDisX = vUv.x - arcX;
        float arcDisY = vUv.y - arcY;
        float arcDis = sqrt(arcDisX * arcDisX + arcDisY * arcDisY);
        if (arcDis <= arcRadiu) {
          gl_FragColor = color;
        } else if (arcDis > arcRadiu && arcDis <= arcOutSideRadiu) {
          gl_FragColor = vec4(1.0,1.0,1.0,1.0);
        } else {
          float rotateX = vUv.x - 0.0;
          float rotateY = vUv.y - 0.0;
          float rotateDis = sqrt(rotateX * rotateX + rotateY * rotateY);
          if (rotateDis == 0.0) {
            rotateDis = 0.001;
          }
          float rotateRadiu = 0.3;
          float rotateOutSideRadiu = 0.35;
          float sinAngle = asin(abs(vUv.x) / rotateDis);
          if (vUv.y > 0.0) {
             sinAngle = 3.14159 - sinAngle;
          }
          float rotateAngle = degrees(sinAngle);
          float drawEnd = 0.0;
          if (rotateDis >= rotateRadiu && rotateDis <= rotateOutSideRadiu) {
            if (rotateAngle <= angle) {
              if (closeWise >= 0.0) {
                if (vUv.x >= 0.0) {
                  gl_FragColor = color;
                  drawEnd = 1.0;
                }
              } else {
                if (vUv.x <= 0.0) {
                  gl_FragColor = color;
                  drawEnd = 1.0;
                }
              }
            }
            if (drawEnd == 0.0) {
              float ringAngle = 22.5;
              float startAngle = ringAngle / 2.0;
              float getAngles[4];
              getAngles[0] = 0.0;
              getAngles[1] = 2.0;
              getAngles[2] = 4.0;
              getAngles[3] = 6.0;
              for (int i = 0; i < 4;  i++) {
                float index = getAngles[i];
                float sa = startAngle + index * ringAngle;
                float ea = startAngle + (index + 1.0) * ringAngle;
                if (rotateAngle <= ea && rotateAngle >= sa && rotateDis >= rotateRadiu && rotateDis <= (rotateOutSideRadiu - rotateRadiu) * 0.5 + rotateRadiu) {
                  gl_FragColor = vec4(0.3,0.5,1.0,1.0);
                  drawEnd = 1.0;
                  break;
                }
              }
            }
          }
          if (drawEnd == 0.0) {
            float rectHeight = 0.03;
            float rectWidth = 0.12;
            float rectArray[12];
            rectArray[0] = rotateRadiu; // x
            rectArray[1] = 0.0 - rectHeight / 2.0; // y
            rectArray[2] = rotateRadiu + rectWidth; // x
            rectArray[3] = rectHeight / 2.0; // y

            rectArray[4] = 0.0 - rectHeight / 2.0; // x
            rectArray[5] = rotateRadiu; // y
            rectArray[6] = rectHeight / 2.0; // x
            rectArray[7] = rotateRadiu + rectWidth; // y

            rectArray[8] = 0.0 - rotateRadiu - rectWidth; // x
            rectArray[9] = 0.0 - rectHeight / 2.0; // y
            rectArray[10] = 0.0 - rotateRadiu; // x
            rectArray[11] = rectHeight / 2.0; // y

            for (int i = 0; i < 3;  i++) {
             float leftX = rectArray[i * 4];
             float leftY = rectArray[i * 4 + 1];
             float rightX = rectArray[i * 4 + 2];
             float rightY = rectArray[i * 4 + 3];
             if (vUv.x >= leftX && vUv.x <= rightX && vUv.y >= leftY && vUv.y <= rightY) {
               gl_FragColor = vec4(0.3,0.5,1.0,1.0);
               drawEnd = 1.0;
               break;
             }
           }
         }

          if (drawEnd == 0.0) {
            discard;
          }
        }
      }
    `
  }

  return shader
}
