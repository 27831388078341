export function travlManager() {
  const config = {
    travlImg: require('../icons/dingwei-phone-s.png'),
    travleStyle: 'one',
    pathUseRadius:true,
  }
  return config
}

export function makeRoomConfig() {
  const roomConfig = {
    readMapTime: 5, // 创建一张地图的延迟时间
    drawShapeTime: 5, // 绘制地图形状的延迟时间
    createRoomGeoTime: 5, // 创建地图geo的延迟时间
    createWallMeterialTime: 5, // 创建地图材料的延迟时间
    createWallMeshTime: 5, // 创建地图mesh后的延迟时间
    loadFloorTitleTime: 5, // 创建地图文字层的延迟时间
    loadCommonTitleTime: 5, // 创建地图覆盖层文字的延迟时间
    updateMapDataTime: 5, // 更新地图文件数据的延迟时间
    readMapDataTime: 5, // 读取地图文件数据的延迟时间
    readMapDataFinishTime: 5 // 加载完成地图的延迟时间
  }
  return roomConfig
}

export function mapConfig() {
  const mapSetInfo = {
    showZoneFog:false, // 是否显示雾化
    lazyLoad: false, // 是否延迟加载
    floorLock: false,
    north: null, // 机构北方向
    finialDis: null, // 终点最终距离
    renderBg: 0xEBFFFB, // 渲染器渲染颜色
    isRenderBg: true, // 是否渲染整个场景的背景颜色
    roomIsDrawLine: true, // 绘制房间是否绘制边框线条
    floorIsDrawLine: true, // 绘制地板是否绘制边框线条
    isShowGridHelper: false, // 是否显示网格
    isShowAxesHelper: false, // 是否显示坐标轴
    divid: 'zhiNanZhen',
    layerDefaultColor: 0xf9f8eb,
    renderAdd: false,
    zhiNanZhenAdd: false,
    zhiNanZhenCircle: false,
    polygonOffsetFactor: 0.2,
    polygonOffsetUnits: 2,
    isShowLinkText: false,
    cameraScale: null,
    openStats: false,
    cameraMode: 0,
    render: false,
    isIe: -1,
    fov: 45,
    maxZoom: 2.22 * 2,
    useCommonGroup: true, // 是否使用共同层
    leadWayShowAt1F: true, // 其他楼层的导航路线是否显示
    perspectiveZoomLV: 13,
    orthographicZoomLV: 13,
    advanceText: true, // 是否预先将所有的楼层的文字加载
    isRenderWhenShowDetail:false, // 在显示多楼层的时候是否继续渲染该render
    leadDirectionType:'byWay', // 转向提示 'byWay' 是按照道路方向提示 'byUser' 是按照用户方向提示
    openTheWorld:false, // 是否开启世界地图
  }
  return mapSetInfo
}

export function mapLeadCubeInfo() {
  const leadPointType = {
    start: 5,
    end: 4,
    louti: 1,
    dianti: 2,
    futi: 3,
    normal: 0,
    po: 99
  }

  const leadCubeInfo = {
    linkType: leadPointType.louti, // 1为楼梯 2为电梯 3为扶梯
    isPo: true, // 是否走含有坡度的路 默认true
    infinite: 65535,
    groupInfo: {
      mapList: [], // 存储记录的地图的id
      info: {},
      po: {},
      linkInfo: {}, // 以Link的id为key 比如 E1 里面对应的是key为地图id的json 用于楼层连接
      mapOrderList: [], // 记录楼层之间的顺序 元素是json
      matrix: []
    },
    zoneIdInfo: {}, // 显示区域的id
    leadOrder: [], // 导航时候的顺序
    poInfo: {}, // 路径坡度信息
    leadPath: {}, // 以地图id为key 每个key存储其地图的导航路线
    shouldSendLeadMsg: false, // 是否发送导航信息
    simulation: false, // 模拟导航
  }

  return leadCubeInfo
}

export function detailMapConfig() {
  let mapSetInfo = {
    lazyLoad:false,
    renderBg: 0xEBFEFA, // 渲染器渲染颜色
    fov: 45,
    cameraMode: 0,
    selectMapColor:0xdbf6e9, // 选中楼层地板颜色
    moveMapCenter:false,
    setFloorAlpha:false,
    zoomScale: 0.7,
    renderDivId: 'allMap',
    sendChangeMapId:false,
    defaultAngleXZ:0.0,
    textShouldClone:true, // 多楼层的文字是否使用clone()
    showFloorText:true, // 是否显示楼层文字
    controlZoom:true, // 是否能缩放
  }
  return mapSetInfo
}

export function pointManagerImageUrl() {
  const imageInfo = {
    pointSelect: 'http://img.kang-cloud.com/48cd76565e4c9806819859ad1aedf9d1?f=png',
    pointUnSelect: 'http://img.kang-cloud.com/43cc9e29ba44cbc1d833a787c1827786?f=png'
  }
  return imageInfo
}

export function mapImageInfoUrl() {
  // 地图图片设置
  const mapImageUrl = {
    userImagePhone: 'http://img.kang-cloud.com/3463c3bd9093a561dcaf968e7ce98518?f=png',
    /* 用户定位显示图片 - phone端 */
    phoneUnSelect: 'http://img.kang-cloud.com/c1fca36c926a8cadb3524c042018d0a5?f=png',
    /* 批量打点未选择房间的图片 - 手机端 */
    phoneSelect: 'http://img.kang-cloud.com/48cd76565e4c9806819859ad1aedf9d1?f=png',
    /* 批量打点选择房间的图片 - 手机端 */
    daohangPhone: 'http://img.kang-cloud.com/b105e9b4e8c545196c7a9d127ee21238?f=png',
    /* 点击获取地图坐标的图片 - 手机端 */
    daohangSimulation: 'http://img.kang-cloud.com/a79c479e96b0fdf6ce109348f1cb71c7?f=png',
    /* 点击获取地图坐标的图片 - 手机端 */
    qidianPhone: 'http://img.kang-cloud.com/80e8f3d6d02aff559d3ad0c083b1b2ee?f=png',
    /* 导航路线的起点的图片 - 手机 */
    zhongdianPhone: 'http://img.kang-cloud.com/48cd76565e4c9806819859ad1aedf9d1?f=png',
    /* 导航路线的终点的图片 - 手机 */
    louti: 'http://img.kang-cloud.com/0be573876490b795fdf7ec797fadf524?f=png',
    dianti: 'http://img.kang-cloud.com/5e9e39a22e0e42ddb077522f8266d395?f=png',
    futi: 'http://img.kang-cloud.com/6b87956293ff9cae7ed50706f20c8d95?f=png',
    zhinanzhenPhone: 'http://img.kang-cloud.com/b9420d52b198f963e064043da6415295?f=png'
  }
  return mapImageUrl
}

export function cameraZoomLevel() {
  const levelInfo = {
    lv1: {
      startDis: 0,
      endDis: 91,
      startZoom: 4.5,
      endZoom: 3.8,
      lv: 1
    },
    lv2: {
      startDis: 91,
      endDis: 144,
      startZoom: 3.8,
      endZoom: 3.1,
      lv: 2
    },
    lv3: {
      startDis: 144,
      endDis: 230,
      startZoom: 3.1,
      endZoom: 2.3,
      lv: 3
    },
    lv4: {
      startDis: 230,
      endDis: 320,
      startZoom: 3.1,
      endZoom: 2.3,
      lv: 4
    },
    lv5: {
      startDis: 320,
      endDis: 500,
      startZoom: 2.3,
      endZoom: 1.8,
      lv: 5
    },
    lv6: {
      startDis: 500,
      endDis: 700,
      startZoom: 1.8,
      endZoom: 1.36,
      lv: 6
    },
    lv7: {
      startDis: 700,
      endDis: 870,
      startZoom: 1.36,
      endZoom: 1.0,
      lv: 7
    },
    lv8: {
      startDis: 870,
      endDis: 1068,
      startZoom: 1.0,
      endZoom: 0.81,
      lv: 8
    },
    lv9: {
      startDis: 1068,
      endDis: 1246,
      startZoom: 0.81,
      endZoom: 0.7,
      lv: 9
    },
    lv10: {
      startDis: 1246,
      endDis: 1695,
      startZoom: 0.7,
      endZoom: 0.56,
      lv: 10
    },
    lv11: {
      startDis: 1695,
      endDis: 2081,
      startZoom: 0.56,
      endZoom: 0.44,
      lv: 11
    },
    lv12: {
      startDis: 2081,
      endDis: 2555,
      startZoom: 0.44,
      endZoom: 0.30,
      lv: 12
    },
    lv13: {
      startDis: 2555,
      endDis: 3659,
      startZoom: 0.30,
      endZoom: 0.25,
      lv: 13
    },
    lv14: {
      startDis: 3659,
      endDis: 4729,
      startZoom: 0.25,
      endZoom: 0.21,
      lv: 14
    },
    lv15: {
      startDis: 4729,
      endDis: 7000,
      startZoom: 0.21,
      endZoom: 0.15,
      lv: 15
    },
    lv16: {
      startDis: 7000,
      endDis: 9000,
      startZoom: 0.15,
      endZoom: 0.1,
      lv: 16
    },
    lv17: {
      startDis: 9000,
      endDis: 655350,
      startZoom: 0.1,
      endZoom: 0,
      lv: 17
    }
  }

  return levelInfo
}
