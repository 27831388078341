import * as THREE from 'three/build/three.min.js'
import {
  createCanvasStyle
} from './canvasStyle'
import {
  createLeadJs
} from '../analaysis/LeadJS'
import {
  createAnalaysisInfo
} from '../analaysis/dataAnalaysisInfo'
import {
  mapConfig
} from '../MapConfig'

import {PlainAnimator} from "three-plain-animator/lib/plain-animator";

export function createObjectAction() {
  const create = new Object()

  const textStyle = createCanvasStyle()

  const lead = createLeadJs() // 纠偏计算的js

  const analysis = createAnalaysisInfo()

  const fov = mapConfig().fov

  const textMaterial = new THREE.SpriteMaterial({
    depthTest: false,
    transparent: true,
    polygonOffset: true,
    opacity: 1,
    polygonOffsetFactor: -1,
    polygonOffsetUnits: -10,
    sizeAttenuation: false,
    needsUpdate: true
  })

  function getLeadWayGeo(width, paths, spaceX, spaceY, hSpace) {
    const ps1 = lead.returnPathLineByWidth(width, paths, spaceX, spaceY)
    return getShapceGeo(ps1)
  }

  create.updateLineColor = function (lineMesh, color) {
    lineMesh.material.color = color
  }

  create.updateCubeColor = function (cube, color) {
    cube.material.color = color
  }

  create.updateLineShpae = function (line, list) {
    const lineGeometry = new THREE.Geometry()
    lineGeometry.vertices = list
    line.geometry = lineGeometry
  }

  create.updateLineStyleByConfig = function (line, list, config) {
    const lineGeometry = new THREE.Geometry()
    lineGeometry.vertices = list
    line.geometry = lineGeometry
    line.material.color.set(config.color)
  }


    create.createLineByConfig = function (list /* 线的数组 [{x:0, y:0, z:0}, {x:0, y:0, z:0}] */, config) {
    let lineStyle = 'sold';
    if (analysis.jsonKeyIsExist(config, ['lineStyle'])) {
      lineStyle = config['lineStyle'];
    }

    let color = config['color'];
    let transparent = false;
    if (analysis.jsonKeyIsExist(config, ['transparent'])) {
      transparent = config['transparent'];
    }

    let depthTest = true;
    if (analysis.jsonKeyIsExist(config, ['depthTest'])) {
      depthTest = config['depthTest'];
    }

    let polygonOffset = true
      if (analysis.jsonKeyIsExist(config, ['polygonOffset'])) {
        polygonOffset = config['polygonOffset'];
      }

    let lineMaterial = null;
    if (lineStyle == 'sold') {
      lineMaterial = new THREE.LineBasicMaterial({
        color: color,
        transparent: transparent,
        depthTest: depthTest,
        polygonOffset: true,
        polygonOffsetFactor: -1,
        polygonOffsetUnits: -6
      })
    } else if (lineStyle == 'dash') {
      lineMaterial = new THREE.LineDashedMaterial({
        color: color,//线段的颜色
        transparent: transparent,
        depthTest: depthTest,
        dashSize: 5,//短划线的大小
        gapSize: 20, //短划线之间的距离
        polygonOffset: polygonOffset,
        polygonOffsetFactor: -1,
        polygonOffsetUnits: -6
      })
    } else {
      lineMaterial = new THREE.LineBasicMaterial({
        color: color,
        transparent: transparent,
        depthTest: depthTest,
        polygonOffset: true,
        polygonOffsetFactor: -1,
        polygonOffsetUnits: -6
      })
    }
    lineMaterial.needsUpdate = true // 材质会更新
    const lineGeometry = new THREE.Geometry()
    lineGeometry.vertices = list
    const line = new THREE.Line(lineGeometry, lineMaterial)
    let y = 0;
    if (analysis.jsonKeyIsExist(config, ['y'])) {
      y = config['y'];
    }
    line.position.y = y;
    return line
  }

  create.createLine = function (list /* 线的数组 */, color /* 线的颜色 */, linewidth /* 线宽 */) {
    const lineGeometry = new THREE.Geometry()
    const lineMaterial = new THREE.LineBasicMaterial({
      color: color,
      linewidth: linewidth, // in pixels
      transparent: false,
      depthTest: true,
      polygonOffset: true,
      polygonOffsetFactor: -1,
      polygonOffsetUnits: -6
    })
    lineMaterial.needsUpdate = true // 材质会更新
    lineGeometry.vertices = list
    const line = new THREE.Line(lineGeometry, lineMaterial)
    return line
  }

  // 创建2D宽的线条
  create.create2DLine = function (width, paths, color, polygonOffsetFactor, polygonOffsetUnits, hSpace, lineColor, group,
                                  spaceX, spaceY, isShowLine, showLineColor) {
    const geometry = getLeadWayGeo(width, paths, spaceX, spaceY, hSpace)
    const material = new THREE.MeshBasicMaterial({
      color: color,
      depthTest: true,
      side: THREE.DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: polygonOffsetFactor,
      polygonOffsetUnits: polygonOffsetUnits
    })
    const mesh = new THREE.Mesh(geometry, material)
    mesh.translateY(hSpace)
    mesh.rotateX(Math.PI / 2) // 绕x轴旋转
    group.add(mesh)
    return mesh
  }

  create.createRoomEdge = function (mesh, path, y, color, lineWidth) {
    for (let i = 0; i < path.length; i++) {
      const start = i
      let end = i + 1
      if (end >= path.length) {
        end = 0
      }
      const p1 = path[start]
      const p2 = path[end]
      const list = [{
        x: p1[0],
        y: y + 0.01,
        z: p1[2]
      }, {
        x: p2[0],
        y: y + 0.01,
        z: p2[2]
      }]
      const line = create.createLine(list, 0xaaaaaa, lineWidth)
      mesh.add(line)
    }
  }

  create.updateSpriteImage = function (p, image, config) {
    const loader = new THREE.TextureLoader()
    const texture = loader.load(image)
    p.material.map = texture
    if (analysis.jsonKeyIsExist(config, ['x', 'y', 'z'])) {
      p.position.x = config.x
      p.position.y = config.y
      p.position.z = config.z
    }
    if (analysis.jsonKeyIsExist(config, ['mapid'])) {
      p.mapid = config.mapid
    }
  }

  // 图片标注
  create.creteImageSprite = function (image, mode, scale, config) {
    const loader = new THREE.TextureLoader()
    const texture = loader.load(image)
    const material = new THREE.SpriteMaterial({
      map: texture,
      depthTest: false,
      transparent: true,
      opacity: 1,
      polygonOffset: true,
      polygonOffsetFactor: -1,
      polygonOffsetUnits: -10,
      color: 0xffffff,
      sizeAttenuation: false
    })
    // material.needsUpdate = true//材质会更新

    const sprite = new THREE.Sprite(material)
    const width = 32
    sprite['cWidth'] = width
    sprite['cHeight'] = width
    // sprite.rotateZ(Math.PI / 4.0)
    sprite.uuid = 'image'
    if (mode != 1) {
      const w = scale
      const h = w
      sprite.scale.set(w, h, 1.0)
      const m = 30
      sprite['cWidth'] = width * h * m
      sprite['cHeight'] = width * h * m
    }
    if (analysis.jsonKeyIsExist(config, ['x', 'y', 'z'])) {
      sprite.position.x = config.x
      sprite.position.y = config.y
      sprite.position.z = config.z
    }
    if (analysis.jsonKeyIsExist(config, ['centerY'])) {
      sprite.center.y = config.centerY
    }
    return sprite
  }

  // 创建方块
  create.createCubeByGroup = function (x, y, z, color, group, geometry, material) {
    const cube = new THREE.Mesh(geometry, material)
    cube.position.x = x
    cube.position.y = y
    cube.position.z = z
    if (group != null) {
      group.add(cube)
    }
    return cube
  }

  // 创建方块
  create.createCube = function (x, y, z, color, group, size) {
    const s = (typeof (size) == 'undefined' ? 3 : size)
    const geometry = new THREE.CubeGeometry(s, s,
      s) // THREE.CubeGeometry是一个几何体，有3个参数：width：立方体x轴的长度，height：立方体y轴的长度，depth：立方体z轴的深度
    const material = new THREE.MeshBasicMaterial({
      color: color,
      depthTest: true
    })
    const cube = new THREE.Mesh(geometry, material)
    cube.position.x = x
    cube.position.y = y
    cube.position.z = z
    if (group != null) {
      group.add(cube)
    }
    return cube
  }

  create.updatePlaneGeometry = function (plane, width, height) {
    const planeGeometry = new THREE.PlaneBufferGeometry(width, height)
    planeGeometry.verticesNeedUpdate = true
    planeGeometry.elementsNeedUpdate = true
    planeGeometry.morphTargetsNeedUpdate = true
    planeGeometry.uvsNeedUpdate = true
    planeGeometry.normalsNeedUpdate = true
    planeGeometry.colorsNeedUpdate = true
    planeGeometry.tangentsNeedUpdate = true
    plane.geometry = planeGeometry
  }

  create.createPlane = function (imageUrl, repeatx, repeaty, width, height) {
    const texture = new THREE.TextureLoader().load(imageUrl)
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.x = repeatx
    texture.repeat.y = repeaty
    const planeGeometry = new THREE.PlaneGeometry(width, height)
    planeGeometry.verticesNeedUpdate = true
    planeGeometry.elementsNeedUpdate = true
    planeGeometry.morphTargetsNeedUpdate = true
    planeGeometry.uvsNeedUpdate = true
    planeGeometry.normalsNeedUpdate = true
    planeGeometry.colorsNeedUpdate = true
    planeGeometry.tangentsNeedUpdate = true

    const plane = new THREE.MeshBasicMaterial({
      polygonOffset: true,
      polygonOffsetFactor: -20,
      polygonOffsetUnits: -40,
      transparent: true,
      opacity: 1.0,
    })
    // plane.color = new THREE.Color(0x00ffff)
    plane.map = texture
    plane.transparent = true
    plane.side = THREE.DoubleSide
    return new THREE.Mesh(planeGeometry, plane)
  }

  create.createShapeArc = function (x, y, radius, color, height) {
    const geometry = new THREE.CylinderGeometry(radius, radius, 0.1, 32)
    geometry.verticesNeedUpdate = true
    geometry.elementsNeedUpdate = true
    geometry.morphTargetsNeedUpdate = true
    geometry.uvsNeedUpdate = true
    geometry.normalsNeedUpdate = true
    geometry.colorsNeedUpdate = true
    geometry.tangentsNeedUpdate = true
    const material = new THREE.MeshBasicMaterial({
      color: color,
      side: THREE.DoubleSide,
      transparent: false,
      opacity: 0.1,
      depthTest: true,
      polygonOffset: true,
      polygonOffsetFactor: -2,
      polygonOffsetUnits: -4
    })
    const cylinder = new THREE.Mesh(geometry, material)
    cylinder.position.set(x, height, y)
    return cylinder
  }

  create.updateShapeArc = function (mesh, x, z, radius) {
    const geometry = new THREE.CylinderGeometry(radius, radius, 0.1, 32)
    geometry.verticesNeedUpdate = true
    geometry.elementsNeedUpdate = true
    geometry.morphTargetsNeedUpdate = true
    geometry.uvsNeedUpdate = true
    geometry.normalsNeedUpdate = true
    geometry.colorsNeedUpdate = true
    geometry.tangentsNeedUpdate = true
    mesh.geometry.dispose()
    mesh.geometry = geometry
    mesh.position.x = x
    mesh.position.z = z
  }

  function getShapceGeo(layers) {
    const planeShape = new THREE.Shape()
    for (let i = 0; i < layers.length; i++) {
      const p = layers[i]
      const x = analysis.jsonKeyIsExist(p, ['x']) ? p.x : p[0]
      const z = analysis.jsonKeyIsExist(p, ['z']) ? p.z : p[2]
      if (i == 0) {
        planeShape.moveTo(x, z)
      } else {
        planeShape.lineTo(x, z)
      }
    }
    const geometry = new THREE.ShapeBufferGeometry(planeShape)
    geometry.verticesNeedUpdate = true
    geometry.elementsNeedUpdate = true
    geometry.morphTargetsNeedUpdate = true
    geometry.uvsNeedUpdate = true
    geometry.normalsNeedUpdate = true
    geometry.colorsNeedUpdate = true
    geometry.tangentsNeedUpdate = true
    return geometry
  }

  create.createLayer = function (layers, config) {
    const geometry = getShapceGeo(layers)
    const material = new THREE.MeshBasicMaterial({
      color: config.color,
      depthTest: config.depthTest,
      transparent: true,
      opacity: config.opacity,
      blending: THREE.NormalBlending,
      side: THREE.DoubleSide,
      polygonOffset: false,
      polygonOffsetFactor: config.polygonOffsetFactor,
      polygonOffsetUnits: config.polygonOffsetUnits
    })
    const mesh = new THREE.Mesh(geometry, material)
    mesh.translateY(config.y)
    mesh.rotateX(Math.PI / 2) // 绕x轴旋转
    return mesh
  }

  create.updateLayer = function (layers, mesh, config) {
    const geometry = getShapceGeo(layers)
    mesh.geometry.dispose()
    mesh.geometry = geometry
    // let result = colorRgb(config.color)
    // if (result.result) {
    //   mesh.material.color = result.rgb
    // }
    mesh.material.color.set(config.color)
    mesh.material.opacity = config.opacity
  }

  function colorRgb(colorStr) {
    // 16进制颜色值的正则
    const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
    // 把颜色值变成小写
    let color = colorStr.toLowerCase()
    if (reg.test(color)) {
      // 如果只有三位的值，需变成六位，如：#fff => #ffffff
      if (color.length === 4) {
        let colorNew = '#'
        for (let i = 1; i < 4; i += 1) {
          colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
        }
        color = colorNew
      }
      // 处理六位的颜色值，转为RGB
      var colorChange = []
      const rgb = {
        r: 1,
        g: 1,
        b: 1,
        a: 1
      }
      const keys = ['r', 'g', 'b', 'a']
      let index = 0
      for (let i = 1; i < 7; i += 2) {
        if (index < keys.length) {
          const num = parseInt('0x' + color.slice(i, i + 2)) / 255.0
          const key = keys[index]
          rgb[key] = num
          index = index + 1
        }
      }
      return {
        result: true,
        rgb: rgb
      }
    } else {
      return {
        result: false
      }
    }
  }

  /* ------------------------- 文字 ------------------------- */
  create.createTitle = function (config) {
    if (typeof (config.type) == 'undefined') {
      return
    }
    let texture = null
    let width = 1
    let height = 1
    let length = 1
    let strWidth = -1
    if (config.type == 'normal') {
      const result = textStyle.returnNormalType(config)
      if (result.result == false) {
        return
      }
      length = result.length
      texture = new THREE.Texture(result.map)
      width = result.map.width
      height = result.map.height
      if (analysis.jsonKeyIsExist(result.map, ['strWidth'])) {
        strWidth = result.map['strWidth']
      }
    } else if (config.type == 'important') {
      const result = textStyle.returnImportantType(config)
      texture = new THREE.Texture(result.map)
      width = result.map.width
      height = result.map.height
      if (analysis.jsonKeyIsExist(result.map, ['strWidth'])) {
        strWidth = result.map['strWidth']
      }
    }
    if (texture == null) {
      return
    }
    texture.needsUpdate = true
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    const material = textMaterial.clone()
    material.map = texture
    material.depthTest = typeof (config.depthTest) == 'undefined' ? false : config.depthTest
    const particle = new THREE.Sprite(material)
    if (typeof (config.multiple) != 'undefined') {
      let s = 1
      if (typeof localStorage['titleScale'] != 'undefined' && localStorage['titleScale'] != null) {
        s = parseFloat(localStorage['titleScale'])
      }
      particle['multiple'] = parseFloat(config.multiple) * length * s
    }
    if (typeof (config.position) != 'undefined') {
      particle.position.x = config.position.x
      particle.position.y = config.position.y
      particle.position.z = config.position.z
    }
    if (typeof (config.centerY) != 'undefined') {
      particle.center.y = config.centerY
    }
    if (typeof (config.mode) != 'undefined' && config.mode != 1) {
      let scaleNumber = 1
      if (typeof localStorage['titleScale'] != 'undefined' && localStorage['titleScale'] != null) {
        scaleNumber = parseFloat(localStorage['titleScale'])
      }
      const s = config.type == 'important' ? 0.035 * 2.0 : 0.035 * scaleNumber
      let zoomScale = typeof (config.scale) == 'undefined' ? s : config.scale
      zoomScale = fov / 75 * zoomScale
      const h = zoomScale * length
      const w = h * width / height
      particle.scale.set(w, h, 1)
    }
    particle['cWidth'] = width
    particle['cHeight'] = height
    particle['title'] = config.title
    if (strWidth != -1) {
      particle['strWidth'] = strWidth
    } else {
      delete particle['strWidth']
    }
    return particle
  }

  create.updateTitle = function (particle, config) {
    if (typeof (config.position) != 'undefined') {
      particle.position.x = config.position.x
      particle.position.y = config.position.y
      particle.position.z = config.position.z
    }
    if (typeof (config.type) == 'undefined') {
      return
    }
    let texture = null
    let width = 1
    let height = 1
    let length = 1
    let strWidth = -1
    if (config.type == 'normal') {
      const result = textStyle.returnNormalType(config)
      if (result.result == false) {
        return
      }
      length = result.length
      texture = new THREE.Texture(result.map)
      width = result.map.width
      height = result.map.height
      if (analysis.jsonKeyIsExist(result.map, ['strWidth'])) {
        strWidth = result.map['strWidth']
      }
    } else if (config.type == 'important') {
      const result = textStyle.returnImportantType(config)
      texture = new THREE.Texture(result.map)
      width = result.map.width
      height = result.map.height
      if (analysis.jsonKeyIsExist(result.map, ['strWidth'])) {
        strWidth = result.map['strWidth']
      }
    }
    if (texture == null) {
      return
    }
    texture.needsUpdate = true
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    particle.material.map = texture
    particle['cWidth'] = width
    particle['cHeight'] = height
    particle['title'] = config.title
    if (typeof (config.multiple) != 'undefined') {
      let s = 1
      if (typeof localStorage['titleScale'] != 'undefined' && localStorage['titleScale'] != null) {
        s = parseFloat(localStorage['titleScale'])
      }
      particle['multiple'] = parseFloat(config.multiple) * length * s
    }
    if (typeof (config.mode) != 'undefined' && config.mode != 1) {
      let scaleNumber = 1
      if (typeof localStorage['titleScale'] != 'undefined' && localStorage['titleScale'] != null) {
        scaleNumber = parseFloat(localStorage['titleScale'])
      }
      const s = config.type == 'important' ? 0.035 * 2.0 : 0.035 * scaleNumber
      let zoomScale = typeof (config.scale) == 'undefined' ? s : config.scale
      zoomScale = fov / 75 * zoomScale
      const h = zoomScale * length
      const w = h * width / height
      particle.scale.set(w, h, 1)
    }
    if (strWidth != -1) {
      particle['strWidth'] = strWidth
    } else {
      delete particle['strWidth']
    }
  }
  /* ------------------------- *** ------------------------- */

  /* ------------------------- 标注 ------------------------- */
  create.updateIconSprite = function (sprite, config) {
    if (analysis.jsonKeyIsExist(config, ['image']) == false) {
      return
    }
    const loader = new THREE.TextureLoader()
    let texture = loader.load(config.image)
    let animator = null;
    if (analysis.jsonKeyIsExist(config, ['animation']) && config['animation']) {
      const texturePath = config.image;
      const spriteTexture = new THREE.TextureLoader().load(texturePath)
      animator = new PlainAnimator(spriteTexture, config['hNumber'], config['vNumber'], config['totalNumber'], config['second']);
      texture = animator.init();
    }
    sprite.material.map = texture
    sprite['animation'] = analysis.jsonKeyIsExist(config, ['animation']) && config['animation']
    sprite['animator'] = animator;
    if (typeof (config.position) != 'undefined') {
      sprite.position.x = config.position.x
      sprite.position.y = config.position.y
      sprite.position.z = config.position.z
    }
    if (typeof (config.centerY) != 'undefined') {
      sprite.center.y = config.centerY
    }
    if (typeof (config.mode) != 'undefined' && config.mode != 1) {
      let scale = typeof (config.scale) == 'undefined' ? 0.1 : config.scale
      scale = fov / 75 * scale
      sprite.scale.set(scale, scale, 1)
    }
  }

  create.creteIconSprite = function (config) {
    const loader = new THREE.TextureLoader()
    let texture = loader.load(config.image)
    let animator = null;
    if (analysis.jsonKeyIsExist(config, ['animation']) && config['animation']) {
      const texturePath = config.image;
      const spriteTexture = new THREE.TextureLoader().load(texturePath)
      animator = new PlainAnimator(spriteTexture, config['hNumber'], config['vNumber'], config['totalNumber'], config['second']);
      texture = animator.init();
    }
    const material = new THREE.SpriteMaterial({
      map: texture,
      depthTest: typeof (config.depthTest) == 'undefined' ? false : config.depthTest,
      transparent: true,
      opacity: 1,
      polygonOffset: true,
      polygonOffsetFactor: -2,
      polygonOffsetUnits: -20,
      color: 0xffffff,
      sizeAttenuation: false
    })

    const sprite = new THREE.Sprite(material)
    const width = typeof (config.width) == 'undefined' ? 32 : config.width
    sprite['cWidth'] = width
    sprite['cHeight'] = width
    sprite['multiple'] = typeof (config.multiple) != 'undefined' ? parseFloat(config.multiple) : 10
    sprite['animation'] = analysis.jsonKeyIsExist(config, ['animation']) && config['animation']
    sprite['animator'] = animator;
    if (typeof (config.position) != 'undefined') {
      sprite.position.x = config.position.x
      sprite.position.y = config.position.y
      sprite.position.z = config.position.z
    }
    if (typeof (config.centerY) != 'undefined') {
      sprite.center.y = config.centerY
    }
    if (typeof (config.mode) != 'undefined' && config.mode != 1) {
      let scale = typeof (config.scale) == 'undefined' ? 0.1 : config.scale
      scale = fov / 75 * scale
      sprite.scale.set(scale, scale, 1)
    }
    return sprite
  }
  /* ------------------------- *** ------------------------- */

  /* ------------------------- 实时监控点样式 ------------------------- */
  create.updateImage_style = function (particle, images, config) {
    const info = textStyle.images_Style(images, config)
    const texture = new THREE.Texture(info.map)
    texture.needsUpdate = true
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    particle.material.map = texture
    particle['cWidth'] = info.width
    particle['cHeight'] = info.height
    if (typeof (config.dataType) != 'undefined') {
      particle.pointType = config.dataType
      particle.dataType = config.dataType
    }
    if (typeof (config.id) != 'undefined') {
      particle.pointId = config.id + ''
    }
    if (typeof (config.position) != 'undefined') {
      particle.position.x = config.position.x
      particle.position.y = config.position.y
      particle.position.z = config.position.z
    }
    if (typeof (config.dataIndex) != 'undefined') {
      particle.dataIndex = config.dataIndex
    }
    if (typeof (config.dataInfo) != 'undefined') {
      particle.dataInfo = config.dataInfo
    }
    particle.msg = ''
  }

  // 创建images
  create.createImage_Style = function (images, config) {
    const info = textStyle.images_Style(images, config)
    const texture = new THREE.Texture(info.map)
    texture.needsUpdate = true
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    const material = new THREE.SpriteMaterial({
      map: texture,
      depthTest: typeof (config.depthTest) == 'undefined' ? false : config.depthTest,
      transparent: true,
      polygonOffset: true,
      opacity: 1,
      polygonOffsetFactor: -4,
      polygonOffsetUnits: -40,
      sizeAttenuation: false
    })
    const particle = new THREE.Sprite(material)
    particle['cWidth'] = info.width
    particle['cHeight'] = info.height
    particle.position.set(config.x, config.y, config.z)
    particle['togethor'] = ''
    particle.center.y = 0

    if (typeof (config.multiple) != 'undefined') {
      particle.multiple = config.multiple
    }
    if (typeof (config.dataType) != 'undefined') {
      particle.pointType = config.dataType
      particle.dataType = config.dataType
    }
    if (typeof (config.id) != 'undefined') {
      particle.pointId = config.id + ''
    }
    if (typeof (config.position) != 'undefined') {
      particle.position.x = config.position.x
      particle.position.y = config.position.y
      particle.position.z = config.position.z
    }
    if (typeof (config.centerY) != 'undefined') {
      particle.center.y = config.centerY
    }
    if (typeof (config.dataIndex) != 'undefined') {
      particle.dataIndex = config.dataIndex
    }
    if (typeof (config.dataInfo) != 'undefined') {
      particle.dataInfo = config.dataInfo
    }
    if (typeof (config.mode) != 'undefined' && config.mode != 1) {
      const scale = fov / 75 * 0.1
      particle.scale.set(scale, scale, 1)
    }
    particle.msg = ''
    return particle
  }

  /* ------------------------- *** ------------------------- */

  /* ------------------------- 创建方块 ------------------------- */
  create.createNewCube = function (config) {
    const size = typeof (config.size) == 'undefined' ? 5 : config.size
    const s = (typeof (size) == 'undefined' ? 3 : size)
    const geometry = new THREE.CubeGeometry(s, s,
      s) // THREE.CubeGeometry是一个几何体，有3个参数：width：立方体x轴的长度，height：立方体y轴的长度，depth：立方体z轴的深度
    const material = new THREE.MeshBasicMaterial({
      depthTest: true
    })
    if (typeof (config.image) != 'undefined') {
      const loader = new THREE.TextureLoader()
      const texture = loader.load(config.image)
      material.map = texture
    }
    if (typeof (config.draw) != 'undefined') {
      const res = textStyle.returnLabel_Style(config)
      const texture = new THREE.Texture(res.map)
      texture.needsUpdate = true
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      material.map = texture
    }

    if (typeof (config.color) != 'undefined') {
      material.color.set(config.color)
    }

    const cube = new THREE.Mesh(geometry, material)
    if (typeof (config.position) != 'undefined') {
      cube.position.x = config.position.x
      cube.position.y = config.position.y
      cube.position.z = config.position.z
    }
    if (typeof (config.uuid) != 'undefined') {
      cube.uuid = config.uuid
    }
    if (typeof (config.mapid) != 'undefined') {
      cube.mapid = config.mapid
    }
    return cube
  }

  create.updateCube = function (cube, config) {
    if (typeof (config.image) != 'undefined') {
      const loader = new THREE.TextureLoader()
      const texture = loader.load(config.image)
      cube.material.map = texture
    }

    if (typeof (config.draw) != 'undefined') {
      const res = textStyle.returnLabel_Style(config)
      const texture = new THREE.Texture(res.map)
      texture.needsUpdate = true
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      cube.material.map = texture
    }

    if (typeof (config.color) != 'undefined') {
      cube.material.color.set(config.color)
    }

    if (typeof (config.position) != 'undefined') {
      cube.position.x = config.position.x
      cube.position.y = config.position.y
      cube.position.z = config.position.z
    }
    if (typeof (config.uuid) != 'undefined') {
      cube.uuid = config.uuid
    }
    if (typeof (config.mapid) != 'undefined') {
      cube.mapid = config.mapid
    }
  }

  /* ------------------------- *** ------------------------- */

  function createBaseStationGeo(config) {
    let radialSegments = 4;
    if (analysis.jsonKeyIsExist(config, ['radialSegments'])) {
      radialSegments = config['radialSegments']
    }

    let height = 10;
    if (analysis.jsonKeyIsExist(config, ['height'])) {
      height = config['height']
    }

    let top = 2;
    if (analysis.jsonKeyIsExist(config, ['top'])) {
      top = config['top']
    }

    let bottom = 5;
    if (analysis.jsonKeyIsExist(config, ['bottom'])) {
      bottom = config['bottom']
    }

    let segments = 2;
    if (analysis.jsonKeyIsExist(config, ['segments'])) {
      segments = config['segments']
    }


    let number = (segments >= 1 ? segments : 1);


    let scale = 0.2;

    let segmentsHeight = (segments > 1 ? height * scale / (segments - 1) : 0);

    let meshHeight = (height - segmentsHeight) / number;

    let list = [];

    let ringList = [];

    let position = {x: 0, y: 0, z: 0}

    if (analysis.jsonKeyIsExist(config, ['position'])) {
      position = config['position']
    }

    for (let i = 0; i < number; i++) {
      let th = i * (meshHeight + segmentsHeight);
      let ts = th / height;

      let bh = meshHeight + th;
      let bs = bh / height;

      let tl = top + (bottom - top) * ts;
      let bl = top + (bottom - top) * bs;

      let geometry = new THREE.CylinderBufferGeometry(tl, bl, meshHeight, radialSegments);
      geometry.verticesNeedUpdate = true
      geometry.elementsNeedUpdate = true
      geometry.morphTargetsNeedUpdate = true
      geometry.uvsNeedUpdate = true
      geometry.normalsNeedUpdate = true
      geometry.colorsNeedUpdate = true
      geometry.tangentsNeedUpdate = true
      let y = height - bh + position.y + meshHeight / 2.0
      list.push({geo: geometry, y: y, x: position.x, z: position.z})

      if (i == 0) {
        let ringNumber = 3;
        if (analysis.jsonKeyIsExist(config, ['ringNumber'])) {
          ringNumber = config['ringNumber']
        }

        let ringInnerDis = 1;

        if (analysis.jsonKeyIsExist(config, ['ringInnerDis'])) {
          ringInnerDis = config['ringInnerDis']
        }

        let ringDeapth = 1;

        if (analysis.jsonKeyIsExist(config, ['ringDeapth'])) {
          ringDeapth = config['ringDeapth']
        }

        let ringScale = 1;

        if (analysis.jsonKeyIsExist(config, ['ringScale'])) {
          ringScale = config['ringScale']
        }

        for (let j = 0; j < ringNumber; j++) {
          let iR = ringInnerDis * (j + 1) + ringScale * j;
          let oR = iR + ringDeapth
          let ring1 = new THREE.RingBufferGeometry(iR, oR, 64, 64, Math.PI / -8.0, Math.PI / 4.0);
          ringList.push({geo: ring1, y: y + meshHeight / 2.0, x: position.x, z: position.z, ry: 0})

          let ring2 = new THREE.RingBufferGeometry(iR, oR, 64, 64, Math.PI + Math.PI / -8.0, Math.PI / 4.0);
          ringList.push({geo: ring2, y: y + meshHeight / 2.0, x: position.x, z: position.z, ry: 0})


          if (j > ringNumber / 2.0) {
            continue;
          }

          let ring3 = new THREE.RingBufferGeometry(iR, oR, 64, 64, Math.PI / -8.0, Math.PI / 4.0);
          ringList.push({geo: ring3, y: y + meshHeight / 2.0, x: position.x, z: position.z, ry: Math.PI / 2.0})

          let ring4 = new THREE.RingBufferGeometry(iR, oR, 64, 64, Math.PI + Math.PI / -8.0, Math.PI / 4.0);
          ringList.push({geo: ring4, y: y + meshHeight / 2.0, x: position.x, z: position.z, ry: Math.PI / 2.0})
        }
      }

    }

    return {list: list, ringList: ringList};
  }

  create.updateBaseStationMode = function (config, group) {

    let bobyColor = 0xff0000
    if (analysis.jsonKeyIsExist(config, ['bobyColor'])) {
      bobyColor = config['bobyColor']
    }

    let wifiColor = 0xff0000
    if (analysis.jsonKeyIsExist(config, ['wifiColor'])) {
      wifiColor = config['wifiColor']
    }

    let position = {x: 0, y: 0, z: 0}

    if (analysis.jsonKeyIsExist(config, ['position'])) {
      position = config['position']
    }

    for (let i = 0; i < group.children.length; i++) {
      let m = group.children[i];
      m.position.x = position.x;
      m.position.z = position.z;
      if (m.type == 'Group') {
        for (let j = 0; j < m.children.length; j++) {
          let m1 = m.children[i];
          m1.material.color.set(wifiColor)
        }
        continue;
      }
      m.material.color.set(bobyColor)
    }
  }


  create.createBaseStationMode = function (config) {

    let bobyColor = 0xff0000
    if (analysis.jsonKeyIsExist(config, ['bobyColor'])) {
      bobyColor = config['bobyColor']
    }

    let wifiColor = 0xff0000
    if (analysis.jsonKeyIsExist(config, ['wifiColor'])) {
      wifiColor = config['wifiColor']
    }

    let bobyMaterial = new THREE.MeshBasicMaterial({
      color: bobyColor,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1.0,
      blending: THREE.NormalBlending,
      depthTest: true,
      polygonOffset: true,
      polygonOffsetFactor: -1,
      polygonOffsetUnits: -5,
    });

    let wifiMaterial = new THREE.MeshBasicMaterial({
      color: wifiColor,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1.0,
      blending: THREE.NormalBlending,
      depthTest: false,
      polygonOffset: true,
      polygonOffsetFactor: -1,
      polygonOffsetUnits: -5,
    });

    let group = new THREE.Group();
    let animationGroup = new THREE.Group();
    group.add(animationGroup)


    let result = createBaseStationGeo(config);
    let list = result.list;
    for (let i = 0; i < list.length; i++) {
      let res = list[i];
      let geometry = res.geo;
      let cylinder = new THREE.Mesh(geometry, bobyMaterial);
      cylinder.position.x = res.x;
      cylinder.position.y = res.y;
      cylinder.position.z = res.z;
      animationGroup.position.x = res.x;
      animationGroup.position.z = res.z;
      cylinder.name = 'bastStation-bliuding';
      group.add(cylinder)
    }

    let ringList = result.ringList;

    for (let i = 0; i < ringList.length; i++) {
      let res = ringList[i];
      let geometry = res.geo;
      let ring = new THREE.Mesh(geometry, wifiMaterial);
      ring.rotation.y = res.ry;
      animationGroup.position.y = res.y;
      ring.name = 'ring';
      animationGroup.add(ring)
    }
    group.name = 'bastStation';
    return group;
  }

  /* ------------------------- 线 ------------------------- */
  create.createNewLine = function (list /* 线的数组 */, config) {
    const color = config.color
    const lineGeometry = new THREE.Geometry()
    const lineMaterial = new THREE.LineBasicMaterial({
      color: color
    })
    lineMaterial.needsUpdate = true // 材质会更新
    lineGeometry.vertices = list
    let line = null
    if (typeof (config.cirle) != 'undefined') {
      if (config.cirle) {
        line = new THREE.LineLoop(lineGeometry, lineMaterial)
      } else {
        line = new THREE.Line(lineGeometry, lineMaterial)
      }
    } else {
      line = new THREE.Line(lineGeometry, lineMaterial)
    }
    if (typeof (config.mapid) != 'undefined') {
      line.mapid = config.mapid
    }
    if (typeof (config.uuid) != 'undefined') {
      line.uuid = config.uuid
    }
    return line
  }

  create.updateLine = function (line, list, config) {
    const lineGeometry = new THREE.Geometry()
    lineGeometry.vertices = list
    line.geometry = lineGeometry
    if (typeof (config.mapid) != 'undefined') {
      line.mapid = config.mapid
    }
    if (typeof (config.uuid) != 'undefined') {
      line.uuid = config.uuid
    }
    if (typeof (config.color) != 'undefined') {
      line.material.color.set(config.color)
    }
  }
  /* ------------------------- *** ------------------------- */

  /* ------------------------- 平面 ------------------------- */
  create.updatePlane = function (plane, config) {
    if (typeof (config.image) != 'undefined') {
      const imageUrl = config.image
      const texture = new THREE.TextureLoader().load(imageUrl)
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      texture.repeat.x = 1
      texture.repeat.y = 1
      plane.material.map = texture
    }
    if (typeof (config.width) != 'undefined' && typeof (config.height) != 'undefined') {
      const width = config.width
      const height = config.height
      const planeGeometry = new THREE.PlaneBufferGeometry(width, height)
      planeGeometry.verticesNeedUpdate = true
      planeGeometry.elementsNeedUpdate = true
      planeGeometry.morphTargetsNeedUpdate = true
      planeGeometry.uvsNeedUpdate = true
      planeGeometry.normalsNeedUpdate = true
      planeGeometry.colorsNeedUpdate = true
      planeGeometry.tangentsNeedUpdate = true
      plane.geometry = planeGeometry
    }
  }

  create.createNewPlane = function (config) {
    const imageUrl = config.image
    const texture = new THREE.TextureLoader().load(imageUrl)
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.x = 1
    texture.repeat.y = 1
    const width = config.width
    const height = config.height
    const planeGeometry = new THREE.PlaneGeometry(width, height)
    planeGeometry.verticesNeedUpdate = true
    planeGeometry.elementsNeedUpdate = true
    planeGeometry.morphTargetsNeedUpdate = true
    planeGeometry.uvsNeedUpdate = true
    planeGeometry.normalsNeedUpdate = true
    planeGeometry.colorsNeedUpdate = true
    planeGeometry.tangentsNeedUpdate = true

    const plane = new THREE.MeshBasicMaterial({
      polygonOffset: true,
      polygonOffsetFactor: config.polygonOffsetFactor,
      polygonOffsetUnits: config.polygonOffsetUnits,
      map: texture,
      transparent: true,
      side: THREE.DoubleSide,
      depthTest: false
    })

    const mesh = new THREE.Mesh(planeGeometry, plane)
    mesh.rotation.x = -Math.PI / 2.0
    if (typeof (config.position) != 'undefined') {
      mesh.position.x = config.position.x
      mesh.position.y = config.position.y
      mesh.position.z = config.position.z
    }
    if (typeof (config.mode) != 'undefined' && config.mode != 1) {
      let zoomScale = typeof (config.scale) == 'undefined' ? 0.035 : config.scale
      zoomScale = fov / 75 * zoomScale
      const h = zoomScale
      const w = h * width / height
      mesh.scale.set(w, h, 1)
    }
    if (analysis.jsonKeyIsExist(config, ['multiple'])) {
      mesh['multiple'] = config['multiple']
    }
    return mesh
  }
  /* ------------------------- *** ------------------------- */

  /* ------------------------- 管道 ------------------------- */
  function createTubeLineGeo(path, config) {
    const y = config.y
    const radiu = config.radiu
    const segmentRadiu = config.segmentRadiu
    const CurvePath = new THREE.CurvePath() // 创建CurvePath对象
    for (let i = path.length - 1; i > 0; i--) {
      const p1 = path[i]
      const p2 = path[i - 1]
      const line = new THREE.LineCurve3(new THREE.Vector3(p1.x, y, p1.z), new THREE.Vector3(p2.x, y, p2.z))
      CurvePath.curves.push(line) // 插入多段线条
    }
    const tubeGeometry = new THREE.TubeGeometry(CurvePath, 64, radiu, segmentRadiu, false)
    tubeGeometry.verticesNeedUpdate = true
    tubeGeometry.elementsNeedUpdate = true
    tubeGeometry.morphTargetsNeedUpdate = true
    tubeGeometry.uvsNeedUpdate = true
    tubeGeometry.normalsNeedUpdate = true
    tubeGeometry.colorsNeedUpdate = true
    tubeGeometry.tangentsNeedUpdate = true
    return tubeGeometry
  }

  create.createTubeLine = function (path, config) {
    const geometry = createTubeLineGeo(path, config)
    const materialSetting = {
      color: config.color
    }
    if (analysis.jsonKeyIsExist(config, ['image'])) {
      const textureLoader = new THREE.TextureLoader()
      const texture = textureLoader.load(config.image)
      // 设置阵列模式为 RepeatWrapping
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      // 设置x方向的偏移(沿着管道路径方向)，y方向默认1
      // 等价texture.repeat= new THREE.Vector2(20,20)
      texture.repeat.x = config.repeatX
      texture.repeat.y = 1
      materialSetting['map'] = texture
    }
    const material = new THREE.MeshBasicMaterial(materialSetting)
    const mesh = new THREE.Mesh(geometry, material)
    return mesh
  }

  create.updateTube = function (tube, config) {
    if (analysis.jsonKeyIsExist(config, ['path'])) {
      tube.geometry.dispose()
      tube.geometry = createTubeLineGeo(config.path, config)
    }
    if (analysis.jsonKeyIsExist(config, ['radiu'])) {
      tube.geometry.radius = config.radiu
    }
  }
  /* ------------------------- *** ------------------------- */

  return create
}
