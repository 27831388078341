import {
  createAnalaysisInfo
} from './dataAnalaysisInfo'
import {
  mapConfig
} from '../MapConfig'

// 管理地图数据
export function createMapDataManger() {
  const manager = new Object()

  const analysis = createAnalaysisInfo() // 构造地图解析

  let nameInfo = {}

  let coverInfo = {}

  let extraInfo = {}

  let lvhuaInfo = {}

  let zoneInfo = {}

  let targetInfo = {}

  let showOtherInfo = {}

  let wallInfo = {}

  let roomInfo = {}

  let showInfo = {}

  let orgNameInfo = {}

  let scaleInfo = {}

  let northInfo = {}

  let mapNameInfo = {}

  let zeroInfo = {}

  let mapBorderInfo = {}

  let mapOrderList = []

  let mapOrgClass = {}

  let lookAtZoneInfo = {}

  let lookAtZoneInfoList = []

  let lazyCommon = {}

  let lazyLoadRoom = {}

  let backgroundInfo = {}; // 地图背景

  manager.deleteAll = function () {
    lazyLoadRoom = {}

    lazyCommon = {}

    nameInfo = {}

    coverInfo = {}

    extraInfo = {}

    lvhuaInfo = {}

    zoneInfo = {}

    targetInfo = {}

    showOtherInfo = {}

    wallInfo = {}

    roomInfo = {}

    showInfo = {}

    orgNameInfo = {}

    scaleInfo = {}

    northInfo = {}

    mapNameInfo = {}

    zeroInfo = {}

    mapBorderInfo = {}

    mapOrderList = []

    mapOrgClass = {}

    lookAtZoneInfo = {}

    lookAtZoneInfoList = []
  }

  manager.getMapIdIndex = function (mapid) {
    let index = 0
    for (let i = 0; i < mapOrderList.length; i++) {
      const order = mapOrderList[i]
      if (mapid == order['mapid']) {
        index = i
        break
      }
    }
    return index
  }

  manager.resetData = function () {
    mapOrderList = []
  }

  manager.getScaleInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(scaleInfo, [mapid])) {
      return parseFloat(scaleInfo[mapid])
    }
    return 10
  }

  manager.getPositionAtZone = function (point, mapid) {
    if (analysis.jsonKeyIsExist(zoneInfo, [mapid]) == false) {
      return {
        result: false
      }
    }
    const zoneList = zoneInfo[mapid]
    for (let i = 0; i < zoneList.length; i++) {
      const zone = zoneList[i]
      const areas = zone['areas']
      if (analysis.isInPolygon([point.x, 0, point.z], areas) == true) {
        if (analysis.jsonKeyIsExist(zone, ['leftUp', 'rightDown']) == false) {
          return {
            result: false
          }
        }
        const leftUp = zone['leftUp']
        const rightDown = zone['rightDown']

        const width = rightDown.x - leftUp.x
        const height = rightDown.z - leftUp.z
        return {
          height: height,
          width: width,
          result: true
        }
        break
      }
    }
    return {
      result: false
    }
  }

  manager.getMapFence = function (mapid) {
    const result = this.getMapBorder(mapid)
    const maxX = result.rightDown.x
    const minX = result.leftUp.x
    const maxZ = result.rightDown.y
    const minZ = result.leftUp.y
    const width = maxX - minX
    const height = maxZ - minZ
    return {
      width: width,
      height: height,
      x: maxX - width / 2.0,
      z: maxZ - height / 2.0
    }
  }

  manager.getMapBorder = function (mapid) {
    if (analysis.jsonKeyIsExist(mapBorderInfo, [mapid])) {
      return mapBorderInfo[mapid]
    }
    return {
      leftUp: {
        x: -100,
        y: -100
      },
      rightDown: {
        x: 100,
        y: 100
      }
    }
  }

  manager.updateMapBorderInfo = function (info, mapid) {
    const copyInfo = JSON.parse(JSON.stringify(info))
    const leftUp = copyInfo.leftUp
    const rightDown = copyInfo.rightDown
    const width = rightDown.x - leftUp.x
    const height = rightDown.y - leftUp.y
    const scale = 0.3
    leftUp.x = leftUp.x - width * scale
    leftUp.y = leftUp.y - height * scale
    rightDown.x = rightDown.x + width * scale
    rightDown.y = rightDown.y + height * scale
    mapBorderInfo[mapid] = copyInfo
    const mapSetInfo = mapConfig()
    if (mapSetInfo['useCommonGroup'] == false) {
      return
    }
    for (let i = 0; i < mapOrderList.length; i++) {
      const order = mapOrderList[i]
      mapBorderInfo[order['mapid']] = copyInfo
    }
  }

  manager.updateZeroInfo = function (data, mapid) {
    zeroInfo[mapid] = data
  }

  manager.getZeroInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(zeroInfo, [mapid])) {
      return zeroInfo[mapid]
    }
    return {
      areas: [],
      areaid: '',
      height: 0
    }
  }

  manager.getLeadTarget = function (mapid) {
    if (analysis.jsonKeyIsExist(targetInfo, [mapid])) {
      return targetInfo[mapid]
    }
    return {}
  }

  manager.updateNorth = function (north) {
    northInfo['allNorth'] = north
  }

  manager.getMapNorth = function (mapid) {
    if (analysis.jsonKeyIsExist(northInfo, ['allNorth'])) {
      return parseFloat(northInfo['allNorth'])
    }
    return 0
  }

  manager.getMapName = function (mapid) {
    if (analysis.jsonKeyIsExist(mapNameInfo, [mapid])) {
      return mapNameInfo[mapid]
    }
    return ''
  }

  manager.getLocationInfo = function (config) {
    const mapid = config.mapid
    const x = config.x
    const z = config.z
    let locationName = ''
    if (analysis.jsonKeyIsExist(nameInfo, [mapid]) == false) {
      locationName = manager.getMapName(mapid);
    }
    let mapName = manager.getMapName(mapid);

    const list = nameInfo[mapid]
    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      const areas = info.areas
      if (analysis.isInPolygon([x, 0, z], areas)) {
        locationName = info.title;
        break;
      }
    }
    if (locationName == '') {
      locationName = manager.getMapName(mapid);
    }

    let zoneId = '';
    if (analysis.jsonKeyIsExist(zoneInfo, [mapid])) {
      let zoneList = zoneInfo[mapid];
      for (let i = 0; i < zoneList.length; i++) {
        const info = zoneList[i]
        const areas = info.areas
        if (analysis.isInPolygon([x, 0, z], areas)) {
          zoneId = info.areaid;
          break;
        }
      }
    }

    let orgClassId = '';
    if (analysis.jsonKeyIsExist(mapOrgClass, [mapid])) {
      let orgClassList = mapOrgClass[mapid];
      for (let i = 0; i < orgClassList.length; i++) {
        const info = orgClassList[i]
        const areas = info.areas
        if (analysis.isInPolygon([x, 0, z], areas)) {
          orgClassId = info.areaid;
          break;
        }
      }
    }

    let orgName = manager.getOrgName(mapid)

    return {
      locationName,
      zoneId,
      orgClassId,
      mapName,
      orgName
    }

  }

  manager.getMapLocationName = function (config) {
    const mapid = config.mapid
    const x = config.x
    const z = config.z
    if (analysis.jsonKeyIsExist(nameInfo, [mapid]) == false) {
      return this.getMapName(mapid)
    }
    const list = nameInfo[mapid]
    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      const areas = info.areas
      if (analysis.isInPolygon([x, 0, z], areas)) {
        return info.title
      }
    }
    return this.getMapName(mapid)
  }

  manager.getMapLocationList = function (mapid) {
    if (analysis.jsonKeyIsExist(nameInfo, [mapid]) == false) {
      return {
        result: false
      }
    }
    return {
      result: true,
      data: nameInfo[mapid]
    }
  }

  manager.getOrgClass = function (mapid) {
    if (analysis.jsonKeyIsExist(mapOrgClass, [mapid])) {
      return mapOrgClass[mapid]
    }
    return []
  }

  manager.getBackGround = function () {
    if (analysis.jsonKeyIsExist(backgroundInfo, ['background'])) {
      return backgroundInfo['background']
    }
    return []
  }

  manager.getOrgName = function (mapid) {
    if (analysis.jsonKeyIsExist(orgNameInfo, [mapid])) {
      return orgNameInfo[mapid]
    }
    return ''
  }

  manager.getExtraInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(extraInfo, [mapid])) {
      return extraInfo[mapid]
    }
    return {
      max: 0,
      list: []
    }
  }

  manager.getLvHuaInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(lvhuaInfo, [mapid])) {
      return lvhuaInfo[mapid]
    }
    return []
  }

  manager.getShowInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(showInfo, [mapid])) {
      return showInfo[mapid]
    }
    return []
  }

  manager.getCoverInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(coverInfo, [mapid])) {
      return coverInfo[mapid]
    }
    return []
  }

  manager.getMapOrderList = function () {
    return mapOrderList
  }

  manager.getRoomInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(roomInfo, [mapid])) {
      return roomInfo[mapid]
    }
    return []
  }

  manager.returnLookAtZoneInfo = function (lookAtZone) {
    if (Object.keys(zoneInfo).length == 0) {
      return mapOrderList
    }
    for (const key in lookAtZone) {
      if (analysis.jsonKeyIsExist(lookAtZoneInfo, [key]) == false) {
        continue
      }
      const info = lookAtZone[key]
      if (info.visible == false) {
        continue
      }
      let list = lookAtZoneInfo[key];
      if (Object.keys(list).length == 0) {
        return mapOrderList
      }
      return lookAtZoneInfo[key]
    }
    return mapOrderList
  }

  manager.getLookAtZoneInfo = function () {
    return lookAtZoneInfoList
  }

  manager.getZoneName = function (zoneId) {
    for (let i = 0; i < lookAtZoneInfoList.length; i++) {
      const info = lookAtZoneInfoList[i]
      if (info.id == zoneId) {
        return info['name']
      }
    }
    return ''
  }

  manager.getZoneMapInfoList = function () {
    if (mapOrderList.length == 0) {
      lookAtZoneInfo = {}
      lookAtZoneInfoList = []
      return {}
    }
    let mapInfo = mapOrderList[mapOrderList.length - 1]
    if (analysis.jsonKeyIsExist(zoneInfo, [mapInfo.mapid]) == false) {
      lookAtZoneInfo = {}
      lookAtZoneInfoList = []
      return {}
    }
    let zone = zoneInfo[mapInfo.mapid]
    let zoneMapInfo = {}
    let zero = zeroInfo[mapInfo.mapid]
    let zeroId = ''
    if (analysis.jsonKeyIsExist(zero, ['areaid'])) {
      zeroId = zero['areaid']
    }
    for (let i = 0; i < zone.length; i++) {
      let z = zone[i]
      let areaid = z['areaid']
      if (zeroId == areaid || areaid == '') {
        continue
      }
      zoneMapInfo[z['areaid']] = {}
    }
    let finishedZone = {}
    for (let i = 0; i < mapOrderList.length; i++) {
      let mapid = mapOrderList[i]['mapid']
      let mapRoomInfo = roomInfo[mapid]
      for (let j = 0; j < mapRoomInfo.length; j++) {
        let info = mapRoomInfo[j]
        if (analysis.jsonKeyIsExist(info, ['inZone']) == false) {
          continue
        }
        let inZone = info['inZone']
        if (inZone == zeroId || analysis.jsonKeyIsExist(zoneMapInfo, [inZone]) == false) {
          continue
        }
        let finishedMapZone = {}
        if (analysis.jsonKeyIsExist(finishedZone, [mapid])) {
          finishedMapZone = finishedZone[mapid]
        } else {
          finishedZone[mapid] = finishedMapZone
        }
        if (analysis.jsonKeyIsExist(finishedMapZone, [inZone])) {
          continue
        }
        let getZoneInfo = zoneMapInfo[inZone]
        let getMapInfo = {}
        if (analysis.jsonKeyIsExist(getZoneInfo, [mapid])) {
          getMapInfo = getZoneInfo[mapid]
        } else {
          getZoneInfo[mapid] = getMapInfo
        }
        let count = 0
        if (analysis.jsonKeyIsExist(getMapInfo, ['count'])) {
          count = getMapInfo['count']
        } else {
          getMapInfo['count'] = 0
        }
        count = count + 1
        getMapInfo['order'] = this.getMapIdIndex(mapid);
        getMapInfo['count'] = count
        getMapInfo['name'] = this.getMapName(mapid)
        getMapInfo['floors'] = this.getMapName(mapid)
        getMapInfo['mapid'] = mapid
        if (count >= 5) {
          finishedMapZone[inZone] = '1'
        }
      }
    }
    let mapIdInfo = {}
    let defaultIList = JSON.parse(JSON.stringify(mapOrderList))
    mapIdInfo['default'] = defaultIList
    lookAtZoneInfoList.push({
      list: defaultIList,
      id: '',
      name: '全院'
    })
    for (const key in zoneMapInfo) {
      let info = zoneMapInfo[key]
      let zoneList = []
      mapIdInfo[key] = zoneList
      for (const mapid in info) {
        let mapInfo = info[mapid]
        if (analysis.jsonKeyIsExist(mapInfo, ['count']) == false || mapInfo['count'] < 5) {
          continue
        }
        zoneList.push(mapInfo)
      }
      zoneList.sort((a, b) => {
        return parseInt(b.mapid) - parseInt(a.mapid)
      })

    }
    for (let i = 0; i < zone.length; i++) {
      let info = zone[i]
      let key = info['areaid']
      let zoneList = []
      if (analysis.jsonKeyIsExist(mapIdInfo, [key])) {
        zoneList = mapIdInfo[key]
      }
      zoneList = zoneList.sort((a, b) => {
        if (a.order < b.order) {
          return -1
        } else {
          return 1
        }
      })
      lookAtZoneInfoList.push({
        list: zoneList,
        id: key,
        name: info['title']
      })
    }
    lookAtZoneInfo = mapIdInfo
    return zoneMapInfo
  }

  manager.getZoneInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(zoneInfo, [mapid])) {
      return zoneInfo[mapid]
    }
    return []
  }

  manager.getWallInfo = function (mapid) {
    if (analysis.jsonKeyIsExist(wallInfo, [mapid])) {
      return wallInfo[mapid]
    }
    return []
  }

  manager.getShowOther = function (mapid) {
    if (analysis.jsonKeyIsExist(showOtherInfo, [mapid])) {
      return showOtherInfo[mapid]
    }
    return []
  }

  // 构造新的地图数据
  manager.makeNewMapInfo = function () {
    /* -------------------------------------- */
    function room_WallMatchZoneArea(roomList, wallList, extraList, zoneInfo, mapid) {
      function updateRoomInfo(roomInfo, key, subKey, value) {
        if (analysis.jsonKeyIsExist(roomInfo, [key]) == false) {
          roomInfo[key] = {}
        }
        roomInfo[key][subKey] = value
      }

      function matchZone(dataList, type, zoneInfo, roomInfo, mapid) {
        const zeros = []
        const zone = {}
        for (let i = 0; i < dataList.length; i++) {
          const room = dataList[i]
          if (analysis.jsonKeyIsExist(room, ['inZone']) == false) {
            zeros.push(room)
            continue
          }
          const zoneId = room['inZone']
          if (analysis.jsonKeyIsExist(zoneInfo, [zoneId])) {
            let list = []
            if (analysis.jsonKeyIsExist(zone, [zoneId])) {
              list = zone[zoneId]
            } else {
              zone[zoneId] = list
            }
            list.push(room)
          } else {
            zeros.push(room)
          }
        }

        updateRoomInfo(roomInfo, manager.getZeroInfo(mapid).areaid, type, type == 'extra' ? zeros.length > 0 : zeros)
        for (const zoneKey in zoneInfo) {
          let values = []
          if (analysis.jsonKeyIsExist(zone, [zoneKey])) {
            values = zone[zoneKey]
          }
          updateRoomInfo(roomInfo, zoneKey, type, type == 'extra' ? values.length > 0 : values)
        }
      }

      const roomInfo = {}
      matchZone(roomList, 'room', zoneInfo, roomInfo, mapid)
      matchZone(wallList, 'wall', zoneInfo, roomInfo, mapid)
      matchZone(extraList, 'extra', zoneInfo, roomInfo, mapid)
      return {
        roomInfo: roomInfo
      }
    }

    function analysisMapData(index, mapList, commonInfo, info, callBack) {
      if (index >= mapList.length) {
        callBack({
          mapList: manager.getMapOrderList(),
          mapInfo: info,
          commonInfo: commonInfo
        })
        return
      }
      const mapInfo = mapList[index]
      const mapid = mapInfo['mapid']
      const zoneList = manager.getZoneInfo(mapid)
      const zoneInfo = {}
      for (let j = 0; j < zoneList.length; j++) {
        const zone = zoneList[j]
        const zoneId = zone['areaid']
        zoneInfo[zoneId] = zone
      }
      const data = manager.getRoomInfo(mapid)
      const floor = [data[0]]
      const rooms = data.slice(1)
      const wall = manager.getWallInfo(mapid)
      const extra = manager.getExtraInfo(mapid).list
      const roomInfo = room_WallMatchZoneArea(rooms, wall, extra, zoneInfo, mapid).roomInfo

      if (index == mapList.length - 1) {
        commonInfo.floor = floor
        commonInfo.lvhuaInfo = manager.getLvHuaInfo(mapid)
        commonInfo.showOther = manager.getShowOther(mapid)
        commonInfo.zoneInfo = zoneList
        commonInfo.extraInfo = extra
        commonInfo.show = manager.getShowInfo(mapid)
      }

      info[mapid] = {
        orgName: manager.getOrgName(mapid),
        name: manager.getMapName(mapid),
        mapid: mapid,
        floor: floor,
        roomInfo: roomInfo
      }
      setTimeout(() => {
        analysisMapData(index + 1, mapList, commonInfo, info, callBack)
      }, 10)
    }

    /* -------------------------------------- */

    return new Promise(resolve => {
      lazyCommon = {
        floor: [],
        lvhuaInfo: [],
        showOther: [],
        zoneInfo: [],
        extraInfo: [],
        show: []
      }
      lazyLoadRoom = {}
      analysisMapData(0, manager.getMapOrderList(), lazyCommon, lazyLoadRoom, (res) => {
        resolve(res)
      })
    })
  }

  // 更新地图数据
  manager.updateMapInfo = function (mapInfos, config, updateLeadInfoCallback, callBack) {
    /* ----------------- */
    function fillterData(dataList, config) {
      let newList = [];
      let mapid = config['mapid'];
      let fillterZero = false;
      if (analysis.jsonKeyIsExist(config, ['fillterZero'])) {
        fillterZero = config['fillterZero'];
      }
      let zeroId = '';
      let info = {};
      for (let i = 0; i < dataList.length; i++) {
        let data = dataList[i];
        if (analysis.jsonKeyIsExist(data, ['areaid']) == false) {
          continue;
        }
        if (i == 0) {
          zeroId = data['areaid']
        }
        let areaid = data['areaid'];
        if (analysis.jsonKeyIsExist(info, [areaid])) {
          continue;
        }
        let isZero = (mapid + areaid == zeroId || areaid == zeroId || areaid == mapid + zeroId)

        if (fillterZero && isZero && i != 0) {
          continue;
        }

        info[areaid] = '1';
        newList.push(data);
      }
      return newList;
    }

    function updateZeroInfo(mapJson, mapid) {
      if (mapJson.length == 0) {
        return
      }
      for (let i = 0; i < mapJson.length; i++) {
        const json = mapJson[i]
        if (analysis.jsonKeyIsExist(json, ['zero'])) {
          zeroInfo[mapid] = json
          return
        }
      }
      zeroInfo[mapid] = mapJson[0]
    }

    function updateMapCoverNameInfo(data, mapid) {
      if (data.length == 0) {
        return
      }
      const key = mapid
      const dataList = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let max = 0
        const leftUp = {
          x: null,
          z: null
        }
        const rightDown = {
          x: null,
          z: null
        }
        for (let j = 0; j < item.length; j++) {
          const roomInfo = item[j]
          const areas = roomInfo['areas']
          for (let k = 0; k < areas.length; k++) {
            const p = areas[k]
            const x = p[0]
            const z = p[2]
            leftUp.x = leftUp.x == null ? x : (leftUp.x > x ? x : leftUp.x)
            leftUp.z = leftUp.z == null ? z : (leftUp.z > z ? z : leftUp.z)
            rightDown.x = rightDown.x == null ? x : (rightDown.x < x ? x : rightDown.x)
            rightDown.z = rightDown.z == null ? z : (rightDown.z < z ? z : rightDown.z)
          }
        }
        const size = (rightDown.x - leftUp.x) * (rightDown.z - leftUp.z)
        // max = max < size ? size : max
        max = size
        const info = {
          max: max,
          list: item,
          leadInfo: {}
        }
        dataList.push(info)
      }
      coverInfo[key] = dataList
    }

    function updateMapExtraInfo(data, mapid) {
      if (data.length == 0) {
        return
      }
      const key = mapid
      const leftUp = {
        x: null,
        z: null
      }
      const rightDown = {
        x: null,
        z: null
      }
      for (let i = 0; i < data.length; i++) {
        const roomInfo = data[i]
        const areas = roomInfo['areas']
        for (let k = 0; k < areas.length; k++) {
          const p = areas[k]
          const x = p[0]
          const z = p[2]
          leftUp.x = leftUp.x == null ? x : (leftUp.x > x ? x : leftUp.x)
          leftUp.z = leftUp.z == null ? z : (leftUp.z > z ? z : leftUp.z)
          rightDown.x = rightDown.x == null ? x : (rightDown.x < x ? x : rightDown.x)
          rightDown.z = rightDown.z == null ? z : (rightDown.z < z ? z : rightDown.z)
        }
      }
      const size = (rightDown.x - leftUp.x) * (rightDown.z - leftUp.z)
      const info = {
        max: size,
        list: data
      }
      extraInfo[key] = info
    }

    function getMapData(mapInfos) {
      return new Promise(resolve => {
        let leadWayShowAt1FCount = 0
        let order = 0
        const leadDataList = {}
        for (const index in mapInfos) {
          const info = mapInfos[index]
          if (Object.keys(info).length == 0 || analysis.jsonKeyIsExist(info, ['mapid']) == false) {
            continue
          }
          const mapid = info['mapid']
          // 房间
          if (analysis.jsonKeyIsExist(info, ['data'])) {
            const dataList = info['data']
            if (dataList.length == 0) {
              continue
            }
            let newRoomList = fillterData(dataList, {mapid:mapid, fillterZero:true})
            roomInfo[mapid] = newRoomList
            updateZeroInfo(newRoomList, mapid)
          } else {
            continue
          }

          // 设置地图背景
          if (analysis.jsonKeyIsExist(info, ['background'])) {
            backgroundInfo['background'] = info['background'];
          } else {
            backgroundInfo['background'] = [];
          }

          // 设置区域名称
          if (analysis.jsonKeyIsExist(info, ['nameInfo'])) {
            nameInfo[mapid] = info['nameInfo']
          }
          // 设置覆盖区域名称
          if (analysis.jsonKeyIsExist(info, ['coverInfo'])) {
            updateMapCoverNameInfo(info['coverInfo'], mapid)
          }
          // 设置楼层远景
          if (analysis.jsonKeyIsExist(info, ['extraInfo'])) {
            let extraInfoList = info['extraInfo'];
            let newExtraInfoList = fillterData(extraInfoList, {mapid:mapid})
            updateMapExtraInfo(newExtraInfoList, mapid)
          }
          // 设置绿化
          if (analysis.jsonKeyIsExist(info, ['lvhuaInfo'])) {
            lvhuaInfo[mapid] = info['lvhuaInfo']
          }
          // 设置区域
          if (analysis.jsonKeyIsExist(info, ['zoneInfo'])) {
            let zone = info['zoneInfo']
            for (let i = 0; i < zone.length; i++) {
              let z = zone[i];
              delete z['child'];
            }
            const l = Object.keys(zone).length
            if (l > 0) {
              leadWayShowAt1FCount = leadWayShowAt1FCount + 1
            }
            zoneInfo[mapid] = zone
          }

          // 导航目的地归纳
          if (analysis.jsonKeyIsExist(info, ['target'])) {
            targetInfo[mapid] = info['target']
          }

          // 装饰
          if (analysis.jsonKeyIsExist(info, ['showOther'])) {
            showOtherInfo[mapid] = info['showOther']
          }

          // 墙体
          if (analysis.jsonKeyIsExist(info, ['wall'])) {
            let wallList = info['wall'];
            wallInfo[mapid] = wallList
          }

          if (analysis.jsonKeyIsExist(info, ['show'])) {
            let showList = info['show'];
            let newShowList = fillterData(showList, {mapid:mapid})
            showInfo[mapid] = newShowList
          }

          if (analysis.jsonKeyIsExist(info, ['orgClass'])) {
            mapOrgClass[mapid] = info['orgClass']
          }

          if (analysis.jsonKeyIsExist(info, ['mapName'])) {
            mapNameInfo[mapid] = info['mapName']
          } else if (analysis.jsonKeyIsExist(info, ['name'])) {
            mapNameInfo[mapid] = info['name']
          } else {
            mapNameInfo[mapid] = ''
          }

          if (analysis.jsonKeyIsExist(info, ['north'])) {
            northInfo[mapid] = info['north']
          }

          if (analysis.jsonKeyIsExist(info, ['scale'])) {
            scaleInfo[mapid] = info['scale']
          }

          if (analysis.jsonKeyIsExist(info, ['orgName'])) {
            orgNameInfo[mapid] = info['orgName']
          } else {
            orgNameInfo[mapid] = ''
          }

          let orderIndex = order
          if (analysis.jsonKeyIsExist(info, ['orderIndex'])) {
            orderIndex = info['orderIndex']
          }

          if (analysis.jsonKeyIsExist(info, ['leadInfo'])) {
            leadDataList[mapid] = {
              mapid: mapid,
              leadInfo: info['leadInfo']
            }
          }

          const orderInfo = {
            mapid: mapid,
            order: orderIndex,
            name: mapNameInfo[mapid],
            floors: mapNameInfo[mapid],
            orgName: orgNameInfo[mapid]
          }
          mapOrderList.push(orderInfo)
          order++
        }

        mapOrderList = mapOrderList.sort(function (a, b) {
          if (a.order < b.order) {
            return -1
          } else {
            return 1
          }
        })

        let leadWayShowAt1F = false
        if (leadWayShowAt1FCount == mapOrderList.length) {
          leadWayShowAt1F = true
        } else {
          leadWayShowAt1F = false
        }
        if (Object.keys(leadDataList).length > 0) {
          updateLeadInfoCallback(leadDataList)
        }

        resolve({
          leadWayShowAt1F: leadWayShowAt1F
        })
      })
    }

    /* ----------------- */

    getMapData(mapInfos).then(res => {
      callBack(res)
    })
  }

  manager.returnMap_RoomName = function (mapid, point) {
    const mapInfo = this.getRoomInfo(mapid)
    if (mapInfo.length == 0) {
      return {
        result: false
      }
    }
    const info = {
      result: false,
      name: '',
      id: ''
    }
    for (let i = 0; i < mapInfo.length; i++) {
      const room = mapInfo[i]
      const areas = room['areas']
      if (analysis.isInPolygon([point.x, 0, point.z], areas) == true) {
        info.name = room['title']
        info.id = room['areaid']
        info.height = room['height']
        info.result = true
      }
    }
    return info
  }

  // 返回指定地图id和房间id的中心点
  manager.returnMap_RoomCenter = function (mapid, roomid, config) {
    let key = 'room';
    if (analysis.jsonKeyIsExist(config, ['dataType'])) {
      key = config['dataType'];
    }
    let mapInfo = [];
    if (key == "room") {
      mapInfo = this.getRoomInfo(mapid)
    } else if (key == 'show') {
      mapInfo = this.getShowInfo(mapid);
    } else if (key == 'room&show') {
      mapInfo = this.getRoomInfo(mapid).concat(this.getShowInfo(mapid));
    }

    if (mapInfo.length == 0) {
      return {
        result: false
      }
    }
    for (let i = 0; i < mapInfo.length; i++) {
      const room = mapInfo[i]
      const id = room['areaid']
      if (id == roomid || (mapid + id) == roomid) {
        const areas = room['areas']
        const center = analysis.getPolygonAreaCenter(areas)
        return {
          result: true,
          areas: room['areas'],
          areaid: room['areaid'],
          title: room['title'],
          x: center[1],
          y: center[0],
          height: room.height,
          name: room['title'],
          orgName: this.getOrgName(mapid),
          mapName: this.getMapLocationName({
            mapid: mapid,
            x: center[1],
            z: center[0]
          })
        }
      }
    }
    return {
      result: false
    }
  }

  manager.outPutMsgByName = function (config) {

    function matchZoneInfo(zoneList, r, key, mapid) {
      const zoneId = r[key]
      for (let i = 0; i < zoneList.length; i++) {
        const zone = zoneList[i]
        const areaid = zone['areaid']
        if (zoneId == areaid || zoneId == (mapid + areaid)) {
          let list = []
          if (analysis.jsonKeyIsExist(zone, ['child'])) {
            list = zone['child']
          } else {
            zone['child'] = list
          }
          r['pAreaId'] = areaid
          list.push(r)
          return {
            result: true
          }
        }
      }
      return {
        result: false
      }
    }

    let jsonType = 'jsonStr'
    if (analysis.jsonKeyIsExist(config, ['jsonType'])) {
      jsonType = config['jsonType']
    }
    const result = {}
    const mapLeadInfo = config['mapLeadInfo']

    for (let i = 0; i < mapOrderList.length; i++) {
      const order = mapOrderList[i]
      const mapid = order['mapid']
      const mapJson = manager.getRoomInfo(mapid)
      const leadInfo = mapLeadInfo[mapid]
      for (const key in leadInfo) {
        const data = leadInfo[key]
        if (analysis.jsonKeyIsExist(data, ['floors'])) {
          delete data['floors']
        }
      }
      const coverInfo = manager.getCoverInfo(mapid)
      const covers = []
      for (let j = 0; j < coverInfo.length; j++) {
        const item = coverInfo[j]
        covers.push(item.list)
      }

      let copyMapInfo = JSON.parse(JSON.stringify(mapJson))
      for (let j = 0; j < copyMapInfo.length; j++) {
        const room = copyMapInfo[j]
        delete room['leftUp']
        delete room['rightDown']
        delete room['draw']
        delete room['close']
        delete room['opacity']
        if (jsonType == 'jsonMerge') {
          room['type'] = j == 0 ? 'floor' : 'room'
        }
      }
      const copyLead = JSON.parse(JSON.stringify(leadInfo))
      for (const key in copyLead) {
        const lead = copyLead[key]
        delete lead['index']
        delete lead['matrix']
        delete lead['k']
        if (analysis.jsonKeyIsExist(lead, ['linkPoint'])) {
          const linkPoint = lead['linkPoint']
          for (const key in linkPoint) {
            const info = linkPoint[key]
            delete info['startP']
            delete info['endP']
            delete info['id']
            delete info['dis']
          }
        }
      }

      const extra = manager.getExtraInfo(mapid)
      const copyExtra = JSON.parse(JSON.stringify(extra.list))
      for (let j = 0; j < copyExtra.length; j++) {
        const room = copyExtra[j]
        delete room['leftUp']
        delete room['rightDown']
        delete room['draw']
        delete room['close']
        delete room['opacity']
      }

      const copyShow = JSON.parse(JSON.stringify(manager.getShowInfo(mapid)))

      const copyLvHua = JSON.parse(JSON.stringify(manager.getLvHuaInfo(mapid)))

      const copyZoneInfo = JSON.parse(JSON.stringify(manager.getZoneInfo(mapid)))

      for (let j = 0; j < copyZoneInfo.length; j++) {
        const room = copyZoneInfo[j]
        delete room['leftUp']
        delete room['rightDown']
        delete room['close']
        delete room['once']
        if (jsonType == 'jsonMerge') {
          room['type'] = 'zone'
          if (copyMapInfo.length == 0) {
            continue
          }
          const floor = copyMapInfo[0]
          room['pAreaId'] = floor['areaid']
        }
      }

      const copyShowOtherInfo = JSON.parse(JSON.stringify(manager.getShowOther(mapid)))
      for (let j = 0; j < copyShowOtherInfo.length; j++) {
        const room = copyShowOtherInfo[j]
        delete room['leftUp']
        delete room['rightDown']
        delete room['close']
      }

      const copyWall = JSON.parse(JSON.stringify(manager.getWallInfo(mapid)))
      for (let j = 0; j < copyWall.length; j++) {
        const wall = copyWall[j]
        delete wall['close']
        delete wall['image']
        delete wall['layerHeight']
        delete wall['title']
        delete wall['titleLV']
        delete wall['leftUp']
        delete wall['rightDown']
        if (jsonType == 'jsonMerge') {
          wall['type'] = 'wall'
        }
      }

      let copyOrgClassStr = JSON.stringify(manager.getOrgClass(mapid))
      copyOrgClassStr.replace('subs')
      copyOrgClassStr = copyOrgClassStr.replace(/subs/g, 'child')
      copyOrgClassStr = copyOrgClassStr.replace(/isIn/g, 'root')
      const copyOrgClass = JSON.parse(copyOrgClassStr)

      function deleteData(roomInfo) {
        delete roomInfo['image']
        delete roomInfo['titleLV']
        delete roomInfo['judgeY']
        delete roomInfo['color']
        delete roomInfo['height']
        delete roomInfo['layerHeight']
        delete roomInfo['leftUp']
        delete roomInfo['rightDown']
        let subs = []
        if (analysis.jsonKeyIsExist(roomInfo, ['child'])) {
          subs = roomInfo['child']
        }
        if (subs.length == 0) {
          return
        }
        for (let j = 0; j < subs.length; j++) {
          deleteData(subs[j])
        }
      }

      for (let j = 0; j < copyOrgClass.length; j++) {
        deleteData(copyOrgClass[j])
      }

      let nameInfo = []
      const nameResult = JSON.parse(JSON.stringify(manager.getMapLocationList(mapid)))
      if (nameResult.result) {
        nameInfo = nameResult.data
      }

      if (jsonType == 'jsonMerge') {
        for (let j = 0; j < nameInfo.length; j++) {
          const info = nameInfo[j]
          if (analysis.jsonKeyIsExist(info, ['type']) == false) {
            info['type'] = 'nameInfo'
          }
        }
      }

      const mapName = manager.getMapName(mapid)

      let dataList = [];
      if (jsonType == 'jsonMerge') {
        const floor = copyMapInfo.length > 0 ? copyMapInfo[0] : {
          areas: [],
          areaid: ''
        }
        floor['pAreaId'] = ''
        const rooms = copyMapInfo.concat(copyWall)
        const otherRooms = []
        for (let l = 0; l < rooms.length; l++) {
          const r = rooms[l]
          if (r.type == "floor") {
            continue;
          }
          if (r.areaid == '7a55ef8a-a4c1-4887-9fbc-d42375bd2ea7') {
            console.log('----')
          }
          if (analysis.jsonKeyIsExist(r, ['inNameInfo'])) {
            const res = matchZoneInfo(nameInfo, r, 'inNameInfo', mapid)
            if (res.result == false) {
              const res = matchZoneInfo(copyZoneInfo, r, 'inZone', mapid)
              if (res.result == false) {
                otherRooms.push(r)
              }
            }
          } else {
            const res = matchZoneInfo(copyZoneInfo, r, 'inZone', mapid)
            if (res.result == false) {
              r['pAreaId'] = floor['areaid']
              otherRooms.push(r)
            }
          }
        }
        for (let j = 0; j < nameInfo.length; j++) {
          const r = nameInfo[j]
          const res = matchZoneInfo(copyZoneInfo, r, 'inZone', mapid)
          if (res.result == false) {
            r['pAreaId'] = floor['areaid']
            otherRooms.push(r)
          }
        }
        floor['child'] = copyZoneInfo.concat(otherRooms)
        dataList = [floor]
      } else {
        dataList = copyMapInfo
      }

      const info = {
        mapid: mapid,
        data: dataList,
        name: mapName,
        north: manager.getMapNorth(mapid),
        scale: manager.getScaleInfo(mapid),
        leadInfo: copyLead,
        order: 1,
        show: copyShow,
        coverInfo: covers,
        extraInfo: copyExtra,
        lvhuaInfo: copyLvHua,
        zoneInfo: jsonType == 'jsonMerge' ? [] : copyZoneInfo,
        showOther: copyShowOtherInfo,
        wall: jsonType == 'jsonMerge' ? [] : copyWall,
        orgClass: copyOrgClass,
        nameInfo: jsonType == 'jsonMerge' ? [] : nameInfo,
        background: manager.getBackGround(),
      }
      result[info.name] = JSON.stringify(info)
    }

    return result
  }

  manager.updateLazyLoadMap = function (config) {
    const mapid = config['mapid']
    const lookAtZone = config['lookAtZone']
    if (analysis.jsonKeyIsExist(lazyLoadRoom, [mapid]) == false) {
      return {
        result: false
      }
    }
    const mapInfo = lazyLoadRoom[mapid]['roomInfo']
    for (const zoneKey in lookAtZone) {
      // 只取一个
      if (analysis.jsonKeyIsExist(mapInfo, [zoneKey]) == false) {
        return {
          result: false
        }
      }
      const info = mapInfo[zoneKey]
      const types = config['types']
      for (let i = 0; i < types.length; i++) {
        info[types[i]] = true
      }
      return {
        result: true
      }
    }
    return {
      result: false
    }
  }

  manager.getDefaultLazyZone = function (mapid) {
    if (analysis.jsonKeyIsExist(lazyLoadRoom, [mapid]) == false) {
      return {
        result: false
      }
    }
    const zeroId = manager.getZeroInfo(mapid).areaid
    const mapInfo = lazyLoadRoom[mapid]['roomInfo']
    const defaultInfo = {}
    for (const key in mapInfo) {
      const infoDetail = mapInfo[key]
      if (zeroId == key) {
        defaultInfo[key] = ''
        continue
      }
      const extra = infoDetail['extra']
      if (extra == true) {
        continue
      }
      defaultInfo[key] = ''
    }
    return {
      result: true,
      defaultInfo: defaultInfo
    }
  }

  manager.lazyLoadMap = function (mapid, lookAtZone, types) {
    if (analysis.jsonKeyIsExist(lazyLoadRoom, [mapid]) == false) {
      return {
        result: false
      }
    }
    const mapInfo = lazyLoadRoom[mapid]['roomInfo']
    for (const zoneKey in lookAtZone) {
      // 只取一个
      if (analysis.jsonKeyIsExist(mapInfo, [zoneKey]) == false) {
        return {
          result: false
        }
      }
      const info = mapInfo[zoneKey]
      const result = {
        result: false,
        room: [],
        wall: []
      }
      for (let i = 0; i < types.length; i++) {
        const typeInfo = types[i]
        const type = typeInfo['type']
        if (analysis.jsonKeyIsExist(info, [type]) == false || analysis.jsonKeyIsExist(result, [type]) == false) {
          continue
        }
        const createdName = typeInfo['createdName']
        let created = false
        if (analysis.jsonKeyIsExist(info, [createdName])) {
          created = info[createdName]
        }
        if (created) {
          continue
        }
        result[type] = info[type]
      }

      result.result = (result.wall.length > 0 || result.room.length > 0)
      return result
    }
    return {
      result: false
    }
  }

  return manager
}
