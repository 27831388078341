import * as THREE from 'three/build/three.min.js'
import {
  createObjectAction
} from '../createObject'
import * as TWEEN from 'tween/tween.js'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createUserDirection
} from './userDirection'

export function createUser() {
  let user = null

  const analysis = createAnalaysisInfo() // 构造地图解析

  let userRemark = null

  const create = createObjectAction()

  const userDirection = createUserDirection()

  let isOutOfMap = true // 定位是否在园区外

  const radius = []

  const imageUrl = {
    daohangSimulation: './static/iconImage/simulation.png',
    daohangPhone: './static/iconImage/userLead-phone.png',
    userImagePhone: './static/iconImage/renwu-phone.png'
  }

  const userInfo = {
    isDirectionAble: false,
    mapid: '',
    style: '',
    isWorking: false,
    clearWorking: null
  }

  const checkLocations = [] // 用于纠正惯性导航定位错误

  const checkLocationCount = 2 // 满足该数量后开始纠正

  const group = new THREE.Group()

  let zoomwithUserBlock = null

  let zoomWithRotateWithZoomBlock = null

  let rotateAndMoveAndZoomMapBlock = null

  let rotateAndMoveBlock = null

  let moveBlock = null

  function move(config) {
    if (isOutOfMap) {
      return
    }
    if (moveBlock != null) {
      moveBlock(config)
    }
  }

  group.moveAction = function(callBack) {
    moveBlock = callBack
  }

  function rotateAndMove(config) {
    if (rotateAndMoveBlock != null) {
      rotateAndMoveBlock(config)
    }
  }

  group.rotateAndMoveAction = function(callBack) {
    rotateAndMoveBlock = callBack
  }

  function rotateAndMoveAndZoomMap(config) {
    if (rotateAndMoveAndZoomMapBlock != null) {
      rotateAndMoveAndZoomMapBlock(config)
    }
  }

  group.rotateAndMoveAndZoomMapAction = function(callBack) {
    rotateAndMoveAndZoomMapBlock = callBack
  }

  function zoomwithUser(config) {
    if (isOutOfMap) {
      return
    }
    if (zoomwithUserBlock != null) {
      zoomwithUserBlock(config)
    }
  }

  group.zoomWithUserAction = function(callBack) {
    zoomwithUserBlock = callBack
  }

  function zoomWithRotateWithZoom(config) {
    if (zoomWithRotateWithZoomBlock != null) {
      zoomWithRotateWithZoomBlock(config)
    }
  }

  group.zoomWithRotateWithZoomAction = function(callBack) {
    zoomWithRotateWithZoomBlock = callBack
  }

  function createOrUpdateRemark(config) {
    if (user == null) {
      return
    }
    const setting = {
      position: user.position,
      title: config.msg,
      type: 'important',
      bgColor: '#fffde8',
      textColor: '#000000',
      multiple: 15,
      centerY: 0,
      mode: 0,
      scale: 0.07,
      depthTest: false
    }
    if (userRemark == null) {
      userRemark = create.createTitle(setting)
      group.add(userRemark)
    } else {
      create.updateTitle(userRemark, setting)
    }
  }

  function createUserStyleOne(config) {
    userRealse()
    const setting = {
      centerY: 0.5,
      position: {
        x: config.x,
        y: config.y,
        z: config.z
      },
      multiple: 30,
      image: imageUrl.userImagePhone,
      width: 32,
      mode: 0,
      scale: 0.15
    }
    user = create.creteIconSprite(setting)
    user.uuid = 'Phone-normal'
    user.mapid = userInfo.mapid
    group.add(user)
    createTween()
    if (userDirection.parent == null) {
      group.add(userDirection)
    }
  }

  function createUserStyleTwo(config) {
    userRealse()
    const setting = {
      centerY: 0.5,
      position: {
        x: config.x,
        y: config.y,
        z: config.z
      },
      multiple: 20,
      image: imageUrl.daohangPhone,
      width: 32,
      height: 32,
      polygonOffsetFactor: -0.3,
      polygonOffsetUnits: 3
    }
    user = create.createNewPlane(setting)
    user.uuid = 'Phone-rotate'
    user.mapid = userInfo.mapid
    group.add(user)
    createTween()
    if (userDirection.parent == null) {
      group.add(userDirection)
    }
  }

  function createUserStyleThree(config) {
    userRealse()
    const setting = {
      centerY: 0.5,
      position: {
        x: config.x,
        y: config.y,
        z: config.z
      },
      multiple: 20,
      image: imageUrl.daohangSimulation,
      width: 32,
      height: 32,
      polygonOffsetFactor: -0.3,
      polygonOffsetUnits: 3
    }
    user = create.createNewPlane(setting)
    user.uuid = 'Phone-rotate'
    user.mapid = userInfo.mapid
    group.add(user)
    createTween()
    if (userDirection.parent == null) {
      group.add(userDirection)
    }
  }

  group.setDirectionShow = function(config) {
    userDirection.visible = config.result
  }

  function userRealse() {
    if (user != null) {
      group.remove(user)
      user.geometry.dispose()
      user.material.dispose()
      user = null
    }

    if (userDirection.parent != null) {
      group.remove(userDirection)
    }
    const res = userDirection.returnPlane()
    if (res.result) {
      res.mesh.geometry.dispose()
      res.mesh.material.dispose()
    }
  }

  group.returnZoomObject = function() {
    return user
  }

  group.returnIsOutOfMap = function() {
    return isOutOfMap
  }

  group.returnZoomRemark = function() {
    return userRemark
  }

  group.returnZoomDirection = function() {
    return userDirection.returnPlane()
  }

  function hiddenUser() {
    group.visible = false
  }

  // hiddenUser();

  // 更新用户定位移动动画
  function userMoveAnimation(time, config) {
    if (user == null || userTween == null) {
      return
    }
    user.mapid = userInfo.mapid
    userTween.stop()
    // 更新定位
    const starttween = userTween
      .to({
        x: config.x,
        z: config.z,
        y: config.y
      }, time).onUpdate(() => {
        if (userRemark != null && userRemark.visible == true) {
          userRemark.position.x = user.position.x
          userRemark.position.z = user.position.z
        }
        userDirection.position.x = user.position.x
        userDirection.position.z = user.position.z
        userDirection.position.y = user.position.y
      }).onComplete(function() {})
    starttween.start()
  }

  group.rotateUser = function(config) {
    userInfo.isDirectionAble = config.isDirectionAble
    if (userInfo.isDirectionAble && user != null) {
      user.rotation.z = config.direction
    }
  }

  group.showUser = function(config) {
    // this.showLocationRadius(config);
    userInfo.mapid = config.mapid
    userInfo.isDirectionAble = config.isDirectionAble
    group.visible = true
    isOutOfMap = config.isOutOfMap
    let firstLocation = false
    if (user == null) {
      if (userInfo.isDirectionAble) {
        createUserStyleTwo(config)
        userInfo.style = 'rotate'
      } else {
        createUserStyleOne(config)
        userInfo.style = 'normal'
      }
      firstLocation = true
    }
    userMoveAnimation(300, config)
    const cameraMode = config.cameraMode
    if (firstLocation) {
      if (cameraMode != 4) {
        zoomwithUser(config)
      }
      return {
        result: true,
        firstLocation: firstLocation
      }
    }
    if (cameraMode == 1) {

    } else if (cameraMode == 2) {
      move(config)
    } else if (cameraMode == 3) {
      if (config.shouldZoom) {
        if (userInfo.isDirectionAble == false) {
          zoomwithUser(config)
        } else {
          // rotateAndMove(config);
          move(config)
        }
      } else {
        if (userInfo.isDirectionAble == false) {
          move(config)
        } else {
          move(config)
          // rotateAndMove(config);
        }
      }
    } else if (cameraMode == 4) {

    }
    if (userInfo.isDirectionAble) {
      user.rotation.z = config.direction
    }
    return {
      result: true,
      firstLocation: firstLocation
    }
  }

  let userTween = null

  function createTween() {
    userTween = new TWEEN.Tween(user.position)
  }

  group.changeUserStyle = function(config) {
    if (user == null) {
      return
    }
    const type = config.type
    if (type == userInfo.style) {
      return
    }
    const position = user.position
    if (type == 'normal') {
      userInfo.isDirectionAble = false
      createUserStyleOne(position)
    } else if (type == 'rotate') {
      userInfo.isDirectionAble = true
      createUserStyleTwo(position)
    } else if (type == 'lead') {
      userInfo.isDirectionAble = true
      createUserStyleThree(position)
    }
    userInfo.style = type
  }

  group.setUserPhoneStyle = function(config) {
    if (user == null || typeof (user.mapid) == 'undefined') {
      return
    }
    const nowMapid = config.mapid
    if (nowMapid != user.mapid && isOutOfMap == false) {
      user.material.opacity = 0.7
      createOrUpdateRemark(config)
      userRemark.visible = true
    } else {
      if (userRemark != null) {
        userRemark.visible = false
      }
      user.material.opacity = 1.0
    }
  }

  group.returnDirectionAble = function() {
    return userInfo.isDirectionAble
  }

  group.getUserMapid = function() {
    if (user == null) {
      return null
    } else {
      return user.mapid
    }
  }

  group.deleteAll = function() {
    if (userInfo.clearWorking != null) {
      window.clearTimeout(userInfo.clearWorking)
    }

    if (user != null && user.parent != null) {
      group.remove(user)
      user.geometry.dispose()
      user.material.dispose()
    }
    user = null
    if (userRemark != null && userRemark.parent != null) {
      group.remove(userRemark)
      userRemark.geometry.dispose()
      userRemark.material.dispose()
    }
    userRemark = null
    if (userTween != null) {
      userTween.stop()
    }
    userTween = null
    TWEEN.removeAll()

    for (let i = 0; i < radius.length; i++) {
      const mesh = radius[i]
      if (mesh.parent != null) {
        mesh.parent.remove(mesh)
      }
      mesh.geometry.dispose()
      mesh.material.dispose()
    }
  }

  group.showLocationRadius = function(config) {
    const isOpenRadius = true
    let accuracy = 0
    if (analysis.jsonKeyIsExist(config, ['accuracy'])) {
      accuracy = parseFloat(config['accuracy'])
    }
    if (isOpenRadius == false || accuracy == 0) {
      for (let i = 0; i < radius.length; i++) {
        const arc = radius[i]
        arc.visible = false
      }
      return
    }
    const x = config['x']
    const z = config['z']

    if (radius.length == 0) {
      const arc = create.createShapeArc(x, z, accuracy * 10, 0xff0000, 2)
      group.add(arc)
      radius.push(arc)
    } else {
      const arc = radius[0]
      create.updateShapeArc(arc, x, z, accuracy * 10)
    }
  }

  // 用户计步
  group.userWorking = function(config) {
    function move(stepLength, angle) {
      const x = -1 * Math.sin(angle) * stepLength
      const z = -1 * Math.cos(angle) * stepLength
      return {
        x: x,
        z: z
      }
    }

    if (user == null) {
      return {
        result: false
      }
    }
    if (userInfo.clearWorking != null) {
      window.clearTimeout(userInfo.clearWorking)
      userInfo.clearWorking = null
    }
    const stepLength = config.dis * config.scale
    userInfo.isWorking = true
    const direction = config.direction
    let angle = 0
    if (typeof config['alpha'] != 'undefined') {
      angle = config.alpha / 180 * Math.PI
    }
    let newAngle = direction - angle
    if (newAngle < -1 * Math.PI) {
      newAngle = Math.PI * 2 + newAngle
    }
    const moveDis = move(stepLength, newAngle)
    const position = user.position

    const fence = config.fence
    const newX = position.x + moveDis.x
    const newZ = position.z + moveDis.z
    // if (analysis.isInPolygon([newX, 0, newZ], fence) == false) {
    //   // 超出范围
    //   let shouldContinue = false;
    //   for (let i = 0; i < fence.length; i++) {
    //     let start = i - 1;
    //     if (start < 0) {
    //       start = fence.length - 1
    //     }
    //     let next = i;
    //     let startP = fence[start];
    //     let x1 = startP[0];
    //     let y1 = startP[2];
    //     let nextP = fence[next];
    //     let x2 = nextP[0];
    //     let y2 = nextP[2];
    //     let result = analysis.getLinesIntersect(x2, y2, x1, y1, position.x, position.z, newX, newZ);
    //     if (result.result) {
    //       let target = new THREE.Vector3(newX, 0, newZ);
    //       let line = new THREE.Line3(new THREE.Vector3(x1, 0, y1), new THREE.Vector3(x2, 0, y2));
    //       let newPoint = new THREE.Vector3(0, 0, 0);
    //       line.closestPointToPoint(target, false, newPoint);
    //       let res = line.closestPointToPointParameter(newPoint, false)
    //
    //       if (res >= 0 && res <= 1) {
    //         newX = newPoint.x;
    //         newZ = newPoint.z;
    //       } else {
    //         // 与墙相交得到点
    //         newX = result.x;
    //         newZ = result.y;
    //       }
    //       shouldContinue = true;
    //       break;
    //     }
    //   }
    //   if (shouldContinue == false) {
    //     return;
    //   }
    // }

    // user.position.set(newX, position.y, newZ);
    userInfo.clearWorking = window.setTimeout(() => {
      userInfo.isWorking = false
    }, 1000)
    return {
      result: true,
      x: newX,
      z: newZ
    }
  }

  group.userIsDirectionAble = function() {
    return userInfo.isDirectionAble
  }

  group.updateUserDirection = function(config) {
    const angle = config.angle
    userDirection.rotation.y = angle
    this.updateDirectionStyle(config)
  }

  group.updateDirectionStyle = function(config) {
    if (analysis.jsonKeyIsExist(config, ['closeWise', 'rotateAngle']) == false) {
      return
    }
    let closeWise = config['closeWise']
    const angle = config['rotateAngle']
    if (isNaN(closeWise) || isNaN(angle)) {
      return
    }
    closeWise = closeWise >= 0 ? 1 : -1
    const setting = {
      closeWise: closeWise,
      angle: angle
    }
    userDirection.updateStyle(setting)
  }

  userDirection.visible = false

  group.adjudeTarget = function (point) {
    if (user == null) {
      return point
    }
    // 计算该夹角
    let vector = new THREE.Vector3(0, 0, -1)
    let userAngle = user.rotation.z
    vector.applyAxisAngle(new THREE.Vector3(0, 1, 0), userAngle)
    let p = {
      x: vector.x + point.x,
      y: point.y,
      z: vector.z + point.z
    }
    let dis = Math.sqrt((p.x - point.x) * (p.x - point.x) + (p.z - point.z) * (p.z - point.z))
    let goDis = 20
    let scale = goDis / dis
    return {
      x: p.x + (p.x - point.x) * scale,
      y: point.y,
      z: p.z + (p.z - point.z) * scale
    }
  }

  return group
}
