import {createUser} from "./userJS";

import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";

import {mapConfig} from "../../MapConfig";

import {createLeadWayAnalaysis} from "../../analaysis/LeadJS";

import {createUserManager_location} from "./userManager_location";

export function createUserManager() {
  let manager = new Object();

  let userGroup = createUser();

  manager.getUser = function () {
    return userGroup;
  }

  let analysis = createAnalaysisInfo();

  manager.getAnalysis = function () {
    return analysis;
  }

  let mapSetInfo = mapConfig();

  manager.getMapSetInfo = function () {
    return mapSetInfo;
  }

  let leadWay = createLeadWayAnalaysis();

  manager.getLeadWay = function () {
    return leadWay;
  }

  let phone = {
    statusLead: false, // 是否开始导航
    isDirectionAble: false, // icon是否随着旋转
    direction: 0, // 手机的磁钢方向
    timer: null, // 记步停止后2秒触发根据蓝牙信标定位
    continueLocation: true
  }

  manager.addGroup = function (scene) {
    if (userGroup.parent != null) {
      scene.remove(userGroup);
    }
    scene.add(userGroup);
  }

  manager.deleteAll = function () {
    userGroup.deleteAll();
  }


  let mapInfoBlock = null;

  manager.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  manager.getMapInfo = function () {
    return mapInfoBlock;
  }

  let zoomBlock = null;

  manager.zoomAction = function (callBack) {
    zoomBlock = callBack;
  }

  let changeUserDirectionBlock = null;

  manager.changeUserDirectionAction = function (callBack) {
    changeUserDirectionBlock = callBack;
  }

  let pathInfoBlock = null;

  manager.pathInfoAction = function (callBack) {
    pathInfoBlock = callBack;
  }

  manager.getPathInfo = function () {
    return pathInfoBlock;
  }

  let updateLeadInfoBlock = null;

  manager.updateLeadInfoAction = function (callBack) {
    updateLeadInfoBlock = callBack;
  }

  manager.getUpdateLeadInfo = function () {
    return updateLeadInfoBlock;
  }

  let updateLeadInfo_groupInfoBlock = null;

  manager.updateLeadInfo_groupInfoAction = function (callBack) {
    updateLeadInfo_groupInfoBlock = callBack;
  }

  manager.getUpdateLeadInfo_groupInfo = function () {
    return updateLeadInfo_groupInfoBlock;
  }

  let cameraAnimationBlock = null;

  manager.cameraAnimationAction = function (callBack) {
    cameraAnimationBlock = callBack;
  }

  let zhinanzhenBlock = null;

  manager.zhinanzhenAction = function (callBack) {
    zhinanzhenBlock = callBack;
  }

  let cameraModeBlock = null;

  manager.cameraModeAction = function (callBack) {
    cameraModeBlock = callBack;
  }

  manager.getCameraMode = function () {
    return cameraModeBlock;
  }

  let groupInfoBlock = null;

  manager.groupInfoAction = function (callBack) {
    groupInfoBlock = callBack;
  }

  manager.phoneInfo = function (callBack) {
    return phone;
  }

  manager.getGroupInfo = function (callBack) {
    return groupInfoBlock;
  }

  let roomInfoByPointBlock = null;

  manager.roomInfoByPointAction = function (callBack) {
    roomInfoByPointBlock = callBack;
  }

  manager.getRoomInfoByPoint = function () {
    return roomInfoByPointBlock;
  }

  let roomInfoByIdBlock = null;

  manager.roomInfoByIdAction = function (callBack) {
    roomInfoByIdBlock = callBack;
  }

  manager.getRoomInfoById = function () {
    return roomInfoByIdBlock;
  }

  let locationBlock = null;

  manager.locationAction = function (callBack) {
    locationBlock = callBack;
  }

  manager.getLocation = function () {
    return locationBlock;
  }

  manager.rotateDirection = function () {
    let mapInfo = mapInfoBlock();
    let north = mapInfo['north'];
    let newNorth = north
    while (newNorth < 0) {
      newNorth = newNorth + 360
    }
    if (newNorth >= 0 && newNorth < 180) {
      return -(Math.PI + phone.direction * Math.PI / 180 + newNorth * Math.PI / 180)
    } else {
      return -(-Math.PI + phone.direction * Math.PI / 180 + newNorth * Math.PI / 180)
    }
  }

  manager.getZoomList = function () {
    let zoomList = [];
    let user = userGroup.returnZoomObject()
    if (user != null) {
      zoomList.push(user);
    }
    let res = userGroup.returnZoomDirection()
    if (res.result) {
      zoomList.push(res.mesh);
    }
    return zoomList;
  }

  // 更新用户定位的大小比例
  manager.updateUserPhoneLocation = function () {
    zoomBlock();
  }

  // 当陀螺仪授权改变 phone的图片也改变
  manager.setPhoneLocationIsDirection = function (direction) {
    phone.isDirectionAble = true
    phone.direction = direction
    userGroup.changeUserStyle({
      type: phone.statusLead ? 'lead' : 'rotate'
    })
    userGroup.rotateUser({
      isDirectionAble: true,
      direction: manager.rotateDirection(),
    })
  }

  manager.isLeadWayExist = function () {
    let pathInfo = pathInfoBlock();
    let path = pathInfo.path;
    return Object.keys(path).length == 0 ? false : true
  }

  manager.adjudeTarget = function (point) {
    if (manager.isLeadWayExist() == false || phone.isDirectionAble == false) {
      return point
    }
    return userGroup.adjudeTarget(point);
  }

  manager.changeUserDirection = function () {
    return manager.isLeadWayExist();
  }

  // 根据传过来的方向改变当前的手机方向
  manager.setPersonDirection = function (phoneDirect, isAnimation, time) {
    let user = userGroup.returnZoomObject()
    if (user == null || analysis.jsonKeyIsExist(user, ['mapid']) == false) {
      return
    }
    manager.setPhoneLocationIsDirection(phoneDirect)
    manager.updateUserPhoneLocation()
    let leadDirectionType = mapSetInfo['leadDirectionType'];

    let changeUserDirection = changeUserDirectionBlock();

    let mapInfo = mapInfoBlock();

    if (changeUserDirection) {
      let userNewLocation = mapInfo['userNewLocation'];
      let findInfo = mapInfo['findInfo'];
      let scaleInfo = mapInfo['scaleInfo'];
      let pathInfo = pathInfoBlock();
      let direction = -1 * manager.rotateDirection()
      let config = {
        findInfo: findInfo,
        userNewLocation: userNewLocation,
        shouldDirection: true,
        direction: direction,
        scale: scaleInfo,
        userLocatin: {
          x: user.position.x,
          y: 0,
          z: user.position.z,
          mapid: user.mapid
        },
        distance: 0,
        mapid: user.mapid,
        leadInfo: pathInfo,
        leadDirectionType: leadDirectionType
      };
      let res = leadWay.turnAround(config)
      userGroup.updateUserDirection(res)
      userGroup.updateDirectionStyle(res)
      updateLeadInfo_groupInfoBlock(res);
      if (res.result == false || mapSetInfo['leadDirectionType'] != 'byUser') {
        return;
      }
      updateLeadInfoBlock(res, {
        isPlayMusic: true,
        update: false
      });
    }
    let userStatusLead = mapInfo['userStatusLead'];
    if (isAnimation == false || (leadDirectionType == 'byWay' ? userStatusLead : true)) {
      return
    }
    let angle = manager.rotateDirection()

    let cameraMode = cameraModeBlock();
    if (cameraMode.selectCameraMode == cameraMode.cameraMode.mapMove) {
      let user = userGroup.returnZoomObject()
      let t = manager.adjudeTarget(user.position)
      cameraAnimationBlock({
        target: {
          target: t
        },
        rotate: {
          angleXZ: angle
        },
        scale: {},
        time: time,
      })
    } else {
      zhinanzhenBlock(angle);
    }
  }

  let mapLocationNameBlock = null;

  manager.mapLocationNameAction = function (callBack) {
    mapLocationNameBlock = callBack;
  }

  manager.setUserPhoneStyle = function (config) {
    if (analysis.jsonKeyIsExist(config, ['mapid']) == false) {
      return;
    }
    let user = userGroup.returnZoomObject()
    if (user == null) {
      return
    }
    let x = 0
    let z = 0
    let mapid = ''
    if (config.default) {
      x = user.position.x
      z = user.position.z
    } else {
      x = config.x
      z = config.z
    }
    mapid = config.mapid
    if (mapid == '') {
      return;
    }
    let name = mapLocationNameBlock({
      mapid: user.mapid,
      x: x,
      z: z
    })

    userGroup.setUserPhoneStyle({
      msg: '您在' + name,
      mapid: mapid
    })
  }

  manager.returnIsOutOfMap = function () {
    return userGroup.returnIsOutOfMap();
  }

  manager.userLocationIsExist = function () {
    let user = userGroup.returnZoomObject()
    if (user == null) {
      return {
        result: false
      }
    }
    let mapid = user.mapid
    let roomInfo = roomInfoByPointBlock(mapid, {
      x: user.position.x,
      y: 0,
      z: user.position.z
    })
    let location = locationBlock({
      mapid: mapid,
      x: user.position.x,
      z: user.position.z
    });
    return {
      result: true,
      mapid: mapid,
      x: user.position.x,
      y: user.position.y,
      z: user.position.z,
      mapName: location.mapName,
      orgName: location.orgName,
      roomName: roomInfo['title']
    }
  }

  manager.changeCameraMode = function (angle) {
    let user = userGroup.returnZoomObject()
    if (user == null) {
      return
    }
    let isDirectionAble = userGroup.returnDirectionAble()
    if (isDirectionAble == false) {
      return;
    }
    user.rotation.z = angle
  }

  let positionAtZoneBlock = null;

  manager.positionAtZoneAction = function (callBack) {
    positionAtZoneBlock = callBack;
  }

  let fenceBlock = null;

  manager.fenceZoneAction = function (callBack) {
    fenceBlock = callBack;
  }

  function userAnimationUpdate() {
    let time = 200
    userGroup.rotateAndMoveAction((res) => {
      let t = manager.adjudeTarget(res)
      let angle = manager.rotateDirection()
      cameraAnimationBlock({
        target: {
          target: t,
          lookAt: res
        },
        rotate: {
          angleXZ: angle
        },
        scale: {},
        time: time,
      })
    })

    userGroup.moveAction((res) => {
      let leadDirectionType = mapSetInfo['leadDirectionType'];
      if (leadDirectionType == 'byWay') {
        return;
      }
      let t = manager.adjudeTarget(res)
      cameraAnimationBlock({
        target: {
          target: t,
        },
        rotate: {},
        scale: {},
        time: time,
      })
    })

    // 用户第一次定位
    userGroup.zoomWithUserAction((res) => {
      let t = manager.adjudeTarget(res)
      let width = 0
      let height = 0
      let zoneRes = positionAtZoneBlock(t)
      if (zoneRes.result) {
        width = zoneRes.width * 1 / 6
        height = zoneRes.height * 1 / 6
      } else {
        let size = fenceBlock();
        width = size.width * 1 / 20
        height = size.height * 1 / 20
      }

      let leftUp = {
        x: t.x - width,
        y: t.y,
        z: t.z - height
      }
      let rightDown = {
        x: t.x + width,
        y: t.y,
        z: t.z + height
      }
      let angle = res.isDirectionAble ? manager.rotateDirection() : 0
      cameraAnimationBlock({
        target: {
          target: t,
        },
        rotate: {
          angleXZ: angle
        },
        scale: {
          leftUp: leftUp,
          rightDown: rightDown
        },
        time: time,
      })
    })
  }

  manager.userStatus = function () {
    let user = userGroup.returnZoomObject()
    let isOutOfMap = userGroup.returnIsOutOfMap()
    return {
      user: user == null ? false : true,
      isOutOfMap:isOutOfMap,
      mapid:user == null ? '' : user.mapid,
      position:user == null ? {} : user.position
    }
  }

  userAnimationUpdate();

  createUserManager_location(manager);

  return manager;
}
