import {createNewRoom} from "./addNewRoom";
import {createIntersects} from "../../intersects/intersects";
import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";

export function createLabelGroupManager() {
  let manager = new Object()

  let addNewRoom = createNewRoom() // 新增房间

  let intersect = createIntersects();

  let analysis = createAnalaysisInfo();

  let mapInfoBlock = null;

  manager.manInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  let pointRoomBlock = null;

  manager.pointRoomAction = function (callBack) {
    pointRoomBlock = callBack;
  }

  let zoneInfoByPointBlock = null;

  manager.zoneInfoByPointAction = function (callBack) {
    zoneInfoByPointBlock = callBack;
  }

  let cameraBlock = null;

  manager.cameraAction = function (callBack) {
    cameraBlock = callBack;
  }

  // 将点添加到scene上
  manager.addGroup = function (scene) {
    if (addNewRoom.parent != null) {
      scene.remove(addNewRoom);
    }
    scene.add(addNewRoom);
  }

  manager.deleteAll = function () {
    addNewRoom.deleteAll();
  }

  manager.getObject = function () {
    return addNewRoom;
  }

  manager.setUpNewLabel = function (config) {
    let mapInfo = mapInfoBlock()
    config['y'] = mapInfo['floorHeight']
    config['mapId'] = mapInfo['mapid'];
    addNewRoom.setUpNewLabel(config)
  }

  manager.clickNewRoom = function (event, config) {
    config['camera'] = cameraBlock();
    config['meshs'] = addNewRoom.returnClickRoomList()
    let finder = intersect.getIntersects(event, config);
    if (finder.result == false) {
      return finder;
    }
    let intersects = finder.intersects
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object
      if (object.visible == false) {
        continue;
      }
      let result = addNewRoom.clickRoom(object, config)
      if (result.result) {
        let mapInfo = mapInfoBlock();
        let mapid = mapInfo['mapid']
        let roomid = pointRoomBlock(mapid, result.data)
        result.data['roomId'] = roomid.indexOf(mapid) == 0 ? roomid : mapid + roomid
        let zoneInfo = zoneInfoByPointBlock(mapid, result.data);
        result.data['zoneInfoId'] = zoneInfo.zoneInfoId;
      }
      return result
    }
    return {
      result: false
    }
  }

  manager.clickNewAddRoom = function (event, config) {
    config['camera'] = cameraBlock();
    config['meshs'] = addNewRoom.returnMeshList({
      type: 'plane'
    });
    let finder = intersect.getIntersects(event, config);
    if (finder.result == false) {
      return finder;
    }
    let intersects = finder.intersects
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object
      if (object.visible && analysis.jsonKeyIsExist(object, ['dataInfo'])) {
        return {
          result: true,
          data: object['dataInfo']
        }
      }
    }
    return {
      result: false
    }
  }

  manager.selectShpae = function (event, config) {
    config['camera'] = cameraBlock();
    config['meshs'] = addNewRoom.returnClickRoomList();
    let finder = intersect.getIntersects(event, config);
    if (finder.result == false) {
      return finder;
    }
    let mapInfo = mapInfoBlock()
    let floorHeight = mapInfo['floorHeight']
    let mapid = mapInfo['mapid'];
    let intersects = finder.intersects
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object
      if (object.visible) {
        addNewRoom.selectShpae(object, {
          y: floorHeight + 1,
          mapId: mapid,
          all: ''
        })
      }
    }
  }

  manager.cancelSelectAreas = function (list, config) {
    addNewRoom.cancelSelectAreas()
    addNewRoom.getShapeList(list, config)
    let mapInfo = mapInfoBlock()
    let floorHeight = mapInfo['floorHeight']
    let mapid = mapInfo['mapid'];
    addNewRoom.showLabelSetting({
      y: floorHeight + 1,
      mapId: mapid,
      all: ''
    })
  }

  manager.deleteSelectAreas = function () {
    let mapInfo = mapInfoBlock()
    let floorHeight = mapInfo['floorHeight']
    let mapid = mapInfo['mapid'];
    return addNewRoom.deleteSelectAreas({
      y: floorHeight + 1,
      mapId: mapid,
      all: ''
    })
  }

  manager.editorSelectAreas = function (config) {
    let mapInfo = mapInfoBlock()
    let floorHeight = mapInfo['floorHeight']
    let mapid = mapInfo['mapid'];
    return addNewRoom.editorSelectAreas({
      y: floorHeight + 1,
      mapId: mapid,
      all: '',
      row: config.row,
      column: config.column
    })
  }


  manager.showLabelSetting = function (list, config) {
    let mapInfo = mapInfoBlock()
    config['y'] = mapInfo['floorHeight']
    config['mapId'] = mapInfo['mapid'];
    config['all'] = ''
    addNewRoom.getShapeList(list, config)
    addNewRoom.showLabelSetting(config)
  }

  manager.returnDataList = function (config) {
    return addNewRoom.returnDataList(config)
  }

  manager.cancelClickRoom = function (config) {
    addNewRoom.cancelClickRoom(config)
  }

  manager.cancelSubCube = function () {
    addNewRoom.cancelSubCube();
  }


  return manager;
}
