export function createAtSameFloorDirectionShader() {
  const shader = new Object()

  shader.returnVertexShader = function() {
    return `
        attribute float width;
        attribute float pathDis;
        attribute float pathScale;
        attribute vec3 verticalVectors;
        attribute vec3 pathColor;
        uniform float repeatCount;
        uniform float lineWidth;
        uniform vec2 resolution;
        uniform float time;
        uniform float renderScale;
        varying vec2 vUv;
        varying vec3 vColor;
        varying float scale;
        varying float vPathScale;
        void main() {
        mat4 m = projectionMatrix * modelViewMatrix;
        vec4 finalPosition = m * vec4( position, 1.0 );

        vec3 offset = verticalVectors * width * 1.0;
        offset.xz /= (vec4( resolution, 0., 1. ) * projectionMatrix).xy;
        offset.xz *= finalPosition.w;

        float w = distance(offset, vec3(0.0, 0.0, 0.0));
        float s = w / width ;
        scale = s * 25.0;
        vPathScale = pathScale;
        vColor = pathColor;
        float dis = pathDis * pathScale;
        float y = uv.y;
        float x = dis + mod(time, pathDis);
        vUv = vec2(x, y);
        vec3 newPosition = position + offset;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
        }
      `
  }

  shader.returnFragmentShader = function() {
    return `
      uniform sampler2D texture;
      uniform vec2 resolution;
      uniform float renderScale;
      varying vec2 vUv;
      varying float scale;
      varying float vPathScale;
      varying vec3 vColor;
      void main() {
        if (vColor.x == 0.0 && vColor.y == 0.0 && vColor.z == 0.0) {
           discard;
        }
        vec4 c = vec4(vColor, 1.0);
        vec4 tc = vec4(1.0, 1.0, 1.0, 1.0);
        if (vUv.y >= 0.9 || vUv.y <= 0.1) {
           gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
        } else {
          if ((1.0 - renderScale) <= vPathScale) {
            tc = vec4(0.7, 0.7, 0.7, 0.0);
            c = vec4(0.7, 0.7, 0.7, 0.0);
          }
          float uvx = vUv.x;
          float uvdelta = 1.0 * scale;
          float markerdelta = uvdelta + 50.0;
          float halfd = markerdelta / 2.0;
          float muvx = mod(uvx, markerdelta);
          if(muvx >= halfd && muvx <= halfd + uvdelta) {
            float s = (muvx - halfd) / uvdelta;
            tc = texture2D( texture, vec2(s, vUv.y));
            c.xyzw = tc.w >= 0.5 ? tc.xyzw : c.xyzw;
          }
          if (c.x == 0. && c.y == 0. && c.z == 0.) {
            c = vec4( vColor.x, vColor.y, vColor.z, 1.0 );
          }
          gl_FragColor = c;
      }
      }
      `
  }

  return shader
}
