// 定义地图文字样式
export function createCanvasStyle() {
  const canvasOb = new Object()

  const canvasType = {
    normal: 0,
    arc: 1,
    improten: 2
  }

  canvasOb.getCanvasType = function() {
    return canvasType
  }

  function drawRoundRectPath(cxt, x, y, width, height, radius, type) {
    const sapce = x
    cxt.beginPath(sapce)
    // 从右下角顺时针绘制，弧度从0到1/2PI
    cxt.arc(width + sapce - radius, height - radius, radius, 0, Math.PI / 2)

    if (type == canvasType.arc) {
      // let spaceWidth = 10;
      // let spaceHeight = 15;
      const spaceWidth = width / 15.0
      const spaceHeight = height / 1.0
      cxt.lineTo((width + 2 * sapce) / 2.0 + spaceWidth, height)
      cxt.lineTo((width + 2 * sapce) / 2.0, height + spaceHeight)
      cxt.lineTo((width + 2 * sapce) / 2.0 - spaceWidth, height)
    }

    // 矩形下边线
    cxt.lineTo(radius + sapce, height)

    // 左下角圆弧，弧度从1/2PI到PI
    cxt.arc(radius + sapce, height - radius, radius, Math.PI / 2, Math.PI)

    // 矩形左边线
    cxt.lineTo(sapce, radius)

    // 左上角圆弧，弧度从PI到3/2PI
    cxt.arc(radius + sapce, radius, radius, Math.PI, Math.PI * 3 / 2)

    // 上边线
    cxt.lineTo(width + sapce - radius, 0)

    // 右上角圆弧
    cxt.arc(width + sapce - radius, radius, radius, Math.PI * 3 / 2, Math.PI * 2)

    // 右边线
    cxt.lineTo(width + sapce, height - radius)
    cxt.closePath()
  }

  function fillRoundRect(cxt, x, y, width, height, radius, /* optional*/ fillColor, type) {
    // 圆的直径必然要小于矩形的宽高
    if (2 * radius > width || 2 * radius > height) {
      return false
    }
    cxt.fillStyle = fillColor // 若是给定了值就用给定的值否则给予默认值
    drawRoundRectPath(cxt, x, y, width, height, radius, type)
    cxt.fill()
  }

  function strlen(str) {
    let len = 0
    for (let i = 0; i < str.length; i++) {
      const c = str.charCodeAt(i)
      // 单字节加1
      if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
        len++
      } else {
        len += 2
      }
    }
    return len
  }

  canvasOb.returnTextCanvas = function(text, textColor, backGroundColor, type) {
    const scale = 1
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    // let width = 130;
    const baseHeight = 128 * scale
    const font = baseHeight * (type == canvasType.arc ? 0.85 : 0.75)
    const textSpace = 128
    const textScale = font * 1
    const chrArr = text.split('\n')

    let maxWidth = 0
    for (let i = 0; i < chrArr.length; i++) {
      const subChar = chrArr[i]
      // let strWidth = (strlen(subChar) * 18.0 + 5) * scale
      const strWidth = (strlen(subChar) * textScale) * scale + textSpace
      maxWidth = maxWidth >= strWidth ? maxWidth : strWidth
    }

    let totalHeight = chrArr.length * baseHeight
    const spaceHeight = totalHeight / 2.0
    let canvasHeight = totalHeight
    if (type == canvasType.arc) {
      totalHeight = totalHeight + spaceHeight
      canvasHeight = totalHeight - spaceHeight
    }
    let finialW = 1
    while (finialW < maxWidth) {
      finialW = finialW * 2
    }
    const space = maxWidth / finialW
    maxWidth = finialW
    canvas.width = maxWidth

    let finialH = 1
    while (finialH < totalHeight) {
      finialH = finialH * 2
    }
    totalHeight = finialH
    canvas.height = totalHeight
    if (type == canvasType.arc) {
      fillRoundRect(ctx, canvas.width * (1 - space) / 2.0, 0, canvas.width * space, canvasHeight, 8, backGroundColor,
        type)
    }
    ctx.fillStyle = textColor
    if (type == canvasType.improten) {
      ctx.strokeStyle = '#fffde8'
    } else {
      ctx.strokeStyle = '#ffffff'
    }

    ctx.font = font + 'px Arial'
    ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
    ctx.textAlign = 'center' // 设置文本的水平对齐方式
    // 设置画笔（绘制线条）操作的线条宽度，非必须；如果不写这句，那就是默认1
    ctx.lineWidth = 30

    for (let i = 0; i < chrArr.length; i++) {
      const subChar = chrArr[i]
      if (type == canvasType.normal || type == canvasType.improten) {
        ctx.strokeText(subChar, (maxWidth) / 2.0, type == canvasType.arc ? (baseHeight) / 2.0 : (baseHeight * 2 -
          font) / 2.0 + i * baseHeight)
      }
      ctx.fillText(subChar, (maxWidth) / 2.0, type == canvasType.arc ? (baseHeight) / 2.0 : (baseHeight * 2 - font) /
        2.0 + i * baseHeight)
    }
    return {
      map: canvas,
      width: canvas.width,
      height: canvas.height,
      line: chrArr.length
    }
  }

  function returnDrawImageStyle(images, config) {
    const imageWidth = 64
    const imageSpace = 16
    const totalwidth = (images.length + 1) * imageWidth
    const totalHeight = imageWidth + imageSpace
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    let finialW = 1
    while (finialW < totalwidth) {
      finialW = finialW * 2
    }
    canvas.width = finialW

    let finialH = 1
    while (finialH < totalHeight) {
      finialH = finialH * 2
    }
    canvas.height = finialH
    ctx.fillStyle = config.bgColor
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(finialW, 0)
    const spaceHeight = 20
    const spaceWidth = 20
    ctx.lineTo(finialW, finialH - spaceHeight)
    ctx.lineTo((finialW) / 2.0 + spaceWidth, finialH - spaceHeight)
    ctx.lineTo(finialW / 2.0, finialH)
    ctx.lineTo((finialW) / 2.0 - spaceWidth, finialH - spaceHeight)
    ctx.lineTo(0, finialH - spaceHeight)
    ctx.closePath()
    ctx.fill()

    ctx.fillStyle = config.arcColor

    const arcSpace = 5
    for (let i = 0; i < images.length; i++) {
      const width = finialW / (images.length)
      const startX = width / 2.0
      const x = startX + (i) * width
      const y = (finialH - spaceHeight) / 2.0
      ctx.beginPath()
      ctx.arc(x, y, imageWidth / 2.0 + arcSpace, 0, Math.PI * 2, true)
      ctx.closePath()
      ctx.fill()
      ctx.drawImage(images[i], x - imageWidth / 2.0, y - imageWidth / 2.0, imageWidth, imageWidth)
    }

    const msg = config.msg
    if (typeof (msg) != 'undefined' && msg != '') {
      const strWidth = strlen(msg) * 10
      const radius = 18
      ctx.fillStyle = config.msgColor
      ctx.beginPath()
      ctx.moveTo(finialW - radius, 0)
      ctx.arc(finialW - radius, radius, radius, -1 * Math.PI / 2, Math.PI / 2, false)
      ctx.lineTo(finialW - radius - strWidth, radius * 2)
      ctx.arc(finialW - radius - strWidth + 5, radius, radius, Math.PI / 2, -1 * Math.PI / 2, false)
      ctx.closePath()
      ctx.fill()
      ctx.fillStyle = config.textColor
      ctx.font = 30 + 'px Arial'
      ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
      ctx.textAlign = 'center' // 设置文本的水平对齐方式
      ctx.fillText(msg, finialW - radius / 2.0 - strWidth, radius)
    }

    return {
      map: canvas,
      width: canvas.width,
      height: canvas.height
    }
  }

  function returnImage_Style(images, config) {
    const imageWidth = images.width
    const imageHeight = images.height

    let width = 1
    let height = 1
    while (width < imageWidth) {
      width = width * 2
    }

    while (height < imageHeight) {
      height = height * 2
    }

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx.drawImage(images, (width - imageWidth) / 2.0, height - imageHeight, imageWidth, imageHeight)

    const msg = config.msg
    if (typeof (msg) != 'undefined' && msg != '') {
      const strWidth = strlen(msg) * 9
      const radius = 9
      const y = height - imageHeight
      const centerX = (width - imageWidth) / 2.0 + imageWidth - (strWidth / 2.0)
      const x = centerX + (strWidth / 2.0)
      const x1 = centerX - (strWidth / 2.0)
      ctx.fillStyle = config.msgColor
      ctx.beginPath()
      ctx.moveTo(x, y)
      ctx.arc(x, radius + y, radius, -1 * Math.PI / 2, Math.PI / 2, false)
      ctx.lineTo(x1, radius * 2 + y)
      ctx.arc(x1, radius + y, radius, Math.PI / 2, -1 * Math.PI / 2, false)
      ctx.closePath()
      ctx.fill()

      ctx.fillStyle = config.textColor
      ctx.font = 15 + 'px Arial'
      ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
      ctx.textAlign = 'center' // 设置文本的水平对齐方式
      ctx.fillText(msg, centerX, y + radius)
    }

    return {
      map: canvas,
      width: canvas.width,
      height: canvas.height
    }
  }

  canvasOb.images_Style = function(images, config) {
    const type = typeof (config.type) == 'undefined' ? 'draw' : config.type
    if (type == 'draw') {
      return returnDrawImageStyle(images, config)
    } else {
      return returnImage_Style(images, config)
    }
  }

  canvasOb.returnNormalType = function(config) {
    if (typeof (config.title) == 'undefined') {
      return {
        result: false
      }
    }
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const scale = 1
    const baseHeight = 32 * scale
    const font = baseHeight
    const msg = config.title
    const textColor = config.textColor
    const bgColor = config.bgColor
    // let strWidth = strlen(msg) * font
    let strWidth = 0
    const strArr = msg.split('/')
    for (let i = 0; i < strArr.length; i++) {
      const w = strlen(msg) * font
      strWidth = strWidth < w ? w : strWidth
    }
    let finialW = 1
    while (finialW < strWidth) {
      finialW = finialW * 2
    }
    finialW = finialW / 2.0
    // if (finialW < strWidth) {
    //   finialW = strWidth;
    // }
    const totalHeight = baseHeight * strArr.length
    canvas.width = finialW
    canvas.height = totalHeight
    ctx.fillStyle = textColor
    ctx.strokeStyle = bgColor
    ctx.font = font * 0.7 + 'px Arial'
    ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
    ctx.textAlign = 'center' // 设置文本的水平对齐方式
    // 设置画笔（绘制线条）操作的线条宽度，非必须；如果不写这句，那就是默认1
    ctx.lineWidth = 4
    for (let i = 0; i < strArr.length; i++) {
      const t = strArr[i]
      const y = baseHeight / 2.0 + baseHeight * i
      ctx.strokeText(t, finialW / 2.0, y)
      ctx.fillText(t, finialW / 2.0, y)
    }
    canvas.strWidth = strlen(msg) * font * 0.45
    return {
      result: true,
      map: canvas,
      length: strArr.length
    }
  }

  canvasOb.returnImportantType = function(config) {
    if (typeof (config.title) == 'undefined') {
      return {
        result: false
      }
    }
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const scale = 1
    const baseHeight = 32 * scale
    const font = baseHeight * 0.6
    const msg = config.title
    const textColor = config.textColor
    const bgColor = config.bgColor

    ctx.font = font
    const metrics = ctx.measureText(msg)
    const strWidth = metrics.width

    let finialW = 1
    while (finialW < strWidth) {
      finialW = finialW * 2
    }
    finialW = finialW * 2.0
    canvas.width = finialW
    let offScale = 1
    if (typeof (config.offScale) != 'undefined') {
      offScale = config.offScale
    }
    const offHeight = baseHeight * offScale
    canvas.height = offHeight + baseHeight

    ctx.fillStyle = bgColor // 若是给定了值就用给定的值否则给予默认值

    const drawStartX = (finialW - strWidth * 2) / 2.0 - 5
    const drawEndX = finialW - drawStartX
    ctx.beginPath()
    ctx.moveTo(drawStartX, 0)
    ctx.lineTo(drawEndX, 0)
    const spaceWidth = 10
    ctx.lineTo(drawEndX, baseHeight)
    ctx.lineTo((finialW) / 2.0 + spaceWidth, baseHeight)
    ctx.lineTo(finialW / 2.0, offHeight + baseHeight)
    ctx.lineTo((finialW) / 2.0 - spaceWidth, baseHeight)
    ctx.lineTo(drawStartX, baseHeight)
    ctx.lineTo(drawStartX, 0)
    ctx.closePath()
    ctx.fill()

    ctx.fillStyle = textColor
    // ctx.font = font * 0.7 + 'px Arial';
    ctx.font = font + 'px Arial'
    ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
    ctx.textAlign = 'center' // 设置文本的水平对齐方式
    // 设置画笔（绘制线条）操作的线条宽度，非必须；如果不写这句，那就是默认1
    ctx.lineWidth = 10
    ctx.fillText(msg, finialW / 2.0, baseHeight / 2.0)
    canvas.strWidth = strlen(msg) * font * 0.45
    return {
      result: true,
      map: canvas
    }
  }

  canvasOb.drawTopImg = function(config) {
    return new Promise((resolve, reject) => {
      const scale = 1
      const height = config.height * scale
      const width = config.width * scale
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const finialW = width
      const finialH = height
      canvas.width = finialW
      canvas.height = finialH
      ctx.shadowOffsetX = 0
      ctx.shadowOffsetY = 0

      const color = '#bedcfa'
      const shapeList = config.list
      const leftUp = config.leftUp
      ctx.fillStyle = color
      ctx.beginPath()
      for (let i = 0; i < shapeList.length; i++) {
        const p = shapeList[i]
        const x = p[0] - leftUp.x
        const z = p[2] - leftUp.z
        if (i == 0) {
          ctx.moveTo(x, z)
        } else {
          ctx.lineTo(x, z)
        }
      }
      ctx.closePath()
      ctx.fill()

      ctx.font = 20 + 'px Arial'
      ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
      ctx.textAlign = 'center' // 设置文本的水平对齐方式
      // 设置画笔（绘制线条）操作的线条宽度，非必须；如果不写这句，那就是默认1
      ctx.lineWidth = 30
      ctx.fillStyle = '#ffffff'
      ctx.fillText('1', canvas.width / 2.0, canvas.height / 2.0, canvas.width * scale)

      const image = new Image()
      image.width = finialW
      image.height = finialH
      image.src = canvas.toDataURL('image/png')
      image.onload = function() {
        resolve(image)
      }
      image.onerror = function(res) {
        reject(res)
      }
    })
  }

  canvasOb.drawFrontImg = function(config) {
    return new Promise((resolve, reject) => {
      const scale = 1
      const height = config.height * scale
      const width = config.width * scale
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const finialW = width
      const finialH = height
      canvas.width = finialW
      canvas.height = finialH
      ctx.shadowOffsetX = 0
      ctx.shadowOffsetY = 0

      const list = config.list
      let startx = 0
      const colors = ['#0278ae', '#f08a5d', '#a5ecd7', '#d789d7']

      ctx.font = finialH * 0.8 + 'px Arial'
      ctx.textBaseline = 'middle' // 设置文本的垂直对齐方式
      ctx.textAlign = 'center' // 设置文本的水平对齐方式
      // 设置画笔（绘制线条）操作的线条宽度，非必须；如果不写这句，那就是默认1
      ctx.lineWidth = 30

      for (let i = 0; i < list.length; i++) {
        const shape = list[i]
        let index = i
        while (index >= colors.length) {
          index = index - colors.length
          if (index < 0) {
            index = 0
          }
        }
        const color = colors[index]
        ctx.fillStyle = color
        const w = shape.width * scale
        ctx.fillRect(startx, 0, w, finialH)
        ctx.fillStyle = '#ffffff'
        ctx.fillText((i + 1 + ''), startx + w / 2.0, finialH / 2.0, shape.width * scale)
        startx = startx + shape.width * scale
      }
      const image = new Image()
      image.width = finialW
      image.height = finialH
      image.src = canvas.toDataURL('image/png')
      image.onload = function() {
        resolve(image)
      }
      image.onerror = function(res) {
        reject(res)
      }
    })
  }

  canvasOb.drawZeroTopImage = function(config) {
    return new Promise((resolve, reject) => {
      const scale = 1
      const h = config.height * scale
      const w = config.width * scale
      let height = 1
      let width = 1
      while (width * 2 < w) {
        width = width * 2
      }

      while (height * 2 < h) {
        height = height * 2
      }

      // let height = config.height * scale;
      // let width = config.width * scale;

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const finialW = width
      const finialH = height
      canvas.width = finialW
      canvas.height = finialH
      ctx.shadowOffsetX = 0
      ctx.shadowOffsetY = 0

      const fullColor = config.color
      ctx.fillStyle = fullColor
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      const leftUp = config.leftUp
      const drawList = config.drawList
      for (let i = 0; i < drawList.length; i++) {
        const roomInfo = drawList[i]
        const areas = roomInfo['areas']
        ctx.fillStyle = roomInfo['color']
        ctx.beginPath()
        for (let j = 0; j < areas.length; j++) {
          const p = areas[j]
          const xCount = p[0] - leftUp.x
          const zCount = p[2] - leftUp.z

          const x = xCount / config.width * width
          const z = zCount / config.height * height
          if (j == 0) {
            ctx.moveTo(x, z)
          } else {
            ctx.lineTo(x, z)
          }
        }
        ctx.closePath()
        ctx.fill()
      }

      const image = new Image()
      image.width = finialW
      image.height = finialH
      image.src = canvas.toDataURL('image/png')
      image.onload = function() {
        resolve(image)
      }
      image.onerror = function(res) {
        reject(res)
      }
    })
  }

  function combineTwoImage(image1, image2, width, height, callBack) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx.drawImage(image1, 0, 0, canvas.width, canvas.height)
    ctx.drawImage(image2, 0, 0, canvas.width, canvas.height)
    const image = new Image()
    image.src = canvas.toDataURL('image/png')
    image.onload = function() {
      callBack({
        result: true,
        image: image
      })
    }
    image.onerror = function() {
      callBack({
        result: false
      })
    }
  }

  canvasOb.drawHeatMap = function(zero, canvas, callBack) {
    const width = canvas.width
    const height = canvas.height

    function returnOldImage(src) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = src
        image.width = width
        image.height = height
        image.onload = function() {
          resolve({
            result: true,
            image: image
          })
        }
        image.onerror = function() {
          resolve({
            result: false
          })
        }
      })
    }

    function returnNewImage(canvas) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.width = width
        image.height = height
        image.src = canvas.toDataURL('image/png')
        image.onload = function() {
          resolve({
            result: true,
            image: image
          })
        }
        image.onerror = function(res) {
          resolve({
            result: false
          })
        }
      })
    }

    const material = zero.material
    let resetMaterial = null
    if (Array.isArray(material)) {
      for (let j = 0; j < material.length; j++) {
        const m = material[j]
        if (m.type == 'MeshBasicMaterial') {
          resetMaterial = m
          break
        }
      }
    } else {
      if (material.type == 'MeshBasicMaterial') {
        resetMaterial = material
      }
    }
    if (typeof resetMaterial == 'undefined' || resetMaterial == null) {
      return
    }
    const imageStr = resetMaterial.image
    if (typeof imageStr == 'undefined' || imageStr == null || imageStr == '') {
      return
    }

    const promiseList = [returnOldImage(imageStr), returnNewImage(canvas)]
    Promise.all(promiseList).then((res) => {
      const res1 = res[0]
      const res2 = res[1]
      if (res1.result == false || res2.result == false) {
        return
      }
      combineTwoImage(res1.image, res2.image, width, height, (result) => {
        if (result.result) {
          callBack({
            image: result.image,
            material: resetMaterial
          })
        }
      })
    })
  }

  return canvasOb
}
