import * as THREE from 'three/build/three.min.js'
import {
  createUserDirectionShader
} from './userDirectionShader'

const polygonOffsetFactor = -300
const polygonOffsetUnits = -8000
const depthTest = false
const polygonOffset = false
const transparent = false

export function createUserDirection() {
  const group = new THREE.Group()

  const insideRadiu = 40 + 5
  const outsideRadiu = insideRadiu + 3

  const shaderStyle = createUserDirectionShader()

  const renderConfig = {
    width: {
      value: 64
    },
    height: {
      value: 64
    },
    centerArcX: {
      value: 32
    },
    centerArcY: {
      value: (insideRadiu + outsideRadiu) / 2.0
    },
    centerArcRadiu: {
      value: 3
    },
    centerArcOutsideRadiu: {
      value: 4
    },
    insideRadiu: {
      value: insideRadiu
    },
    outsideRadiu: {
      value: outsideRadiu
    },
    closeWise: {
      value: 0
    }, // 0为左 1为右,
    angle: {
      value: 0
    }
  }

  function returnShaderMaterial() {
    const ShaderMaterial = new THREE.ShaderMaterial({
      transparent: transparent,
      polygonOffset: polygonOffset,
      polygonOffsetFactor: polygonOffsetFactor,
      polygonOffsetUnits: polygonOffsetUnits,
      depthTest: depthTest,
      side: THREE.DoubleSide,
      uniforms: renderConfig,
      vertexShader: shaderStyle.returnVertexShader(),
      fragmentShader: shaderStyle.returnFragmentShader()
    })
    return ShaderMaterial
  }

  function createPlane() {
    const geometry = new THREE.PlaneGeometry(renderConfig.width.value, renderConfig.height.value, 1, 1)
    const plane = new THREE.Mesh(geometry, returnShaderMaterial())
    group.add(plane)
    plane.rotation.x = Math.PI / 2.0
    plane.uuid = 'Phone-direction'
  }

  group.updateStyle = function(config) {
    if (group.visible == false) {
      return
    }
    if (renderConfig.closeWise.value == config.closeWise && renderConfig.angle.value == config.angle) {
      return
    }
    renderConfig.closeWise.value = config.closeWise
    renderConfig.angle.value = config.angle
  }

  createPlane()

  group.returnPlane = function() {
    const children = group.children
    if (children.length == 0) {
      return {
        result: false
      }
    }
    return {
      result: true,
      mesh: children[0]
    }
  }

  return group
}
