import { createMapDataManger } from './analaysis/mapDataManager'

import { detailMapConfig, makeRoomConfig, mapConfig, mapImageInfoUrl, mapLeadCubeInfo } from './MapConfig'

import * as THREE from 'three/build/three.min'

import Stats from 'three/examples/jsm/libs/stats.module'

import { createNewOrbitControls } from './threeCreate/controls/myOrbitControls'

import { shot } from './threeCreate/cutimage/shotImage'

import { createObjectAction } from './threeCreate/createObject'

import * as TWEEN from 'tween/tween.js'

import { createAnalaysisInfo } from './analaysis/dataAnalaysisInfo.js'

import { createCommonGroup, createFloorManager } from './threeCreate/floor/floorManager'

import { createLeadWay } from './threeCreate/leadWay/leadWayJS'

import { createTextGroup } from './threeCreate/floor/floorLayerText'

import { createCSS2DManager } from './threeCreate/CSS2DManager/CSS2Dmanager'

import { createTravlLine } from './threeCreate/travlLineManager/travlLine'

import { createGridModule } from './threeCreate/gridManager/gridManager'

import { createHeatMapManager } from './threeCreate/heatMap/heatMapManager'

import { createPointDataManager } from './threeCreate/point/pointDataManager'

import { createLeadPointManager } from './threeCreate/LeadPoint/leadPointDataManager'

import { createSimulationManager } from './threeCreate/simulation/simulationDataManager'

import { createUserManager } from './threeCreate/user/userManager'

import { createWorldManager } from './threeCreate/theWorld/worldManager'

import { createDetailMapManager } from './threeCreate/DetailMap/detailMapManager'

import { createIntersects } from './intersects/intersects'

import { createLayerDataManager } from './threeCreate/layerManager/layerDataManager'

import { createLabelGroupManager } from './threeCreate/addNewRoom/labelGroupManager'

/* ================================= 地图渲染 =================================*/
export function createMapInfo() {
  let map = new Object() // 地图对象

  let dataManager = createMapDataManger()

  let leadGroup = createLeadWay()

  let create = createObjectAction()

  let css2D = createCSS2DManager()

  let stats = new Stats()

  let commonGroup = createCommonGroup()

  /* 解析 */
  let analysis = createAnalaysisInfo() // 构造地图解析

  let groupInfo = {} // 用于承载地图的group 比如多楼层

  let textGroup = createTextGroup()

  let gridManager = createGridModule()

  let heatMapManager = createHeatMapManager()

  let pointManager = createPointDataManager()

  let leadPointManager = createLeadPointManager()

  let simulationManager = createSimulationManager()

  let userManager = createUserManager()

  let leadPointType = userManager.leadPointType()

  let worldManager = createWorldManager()

  let detailManager = createDetailMapManager()

  let layerDataManager = createLayerDataManager()

  let labelGroupManager = createLabelGroupManager()

  let travlLine = createTravlLine() // 轨迹

  // 点击射线
  let intersect = createIntersects()

  /* 设置 */
  let windowHeight = window.innerHeight // 设置整个地图承载的div的高度
  let windowWidth = window.innerWidth // 设置整个地图承载的div的宽度 w:h 最好是 5：4
  let selectDivId = '' // 设置整个地图承载的div
  let maxPolarAngle = Math.PI / 2.0 // 地图y轴最大的角度
  let minPolarAngle = 0.001 // 地图y轴最小的角度

  let mapSetInfo = mapConfig()

  let leadCubeInfo = mapLeadCubeInfo()

  let detailConfig = detailMapConfig()

  /* 地图控件 */
  let camera = null // 地图的相机
  let cameraMode = {
    normal: 1, //
    locationMove: 2,
    mapMove: 3,
    simulation: 4
  }

  // 对外给出能选择的镜头模式
  map.getCameraMode = function() {
    return [cameraMode.locationMove, cameraMode.mapMove]
  }

  let selectCameraMode = cameraMode.locationMove // 镜头模式

  let scene = new THREE.Scene() //场景构建
  let renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    logarithmicDepthBuffer: false
  }) // 地图的渲染器

  let startRendce = false

  let zhiNanZhenCamera = null
  let zhiNanZhenScene = new THREE.Scene() //场景构建
  let zhiNanZhenRenderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true
  }) // 地图的渲染器
  let zhinanzhen = null

  let controls = null // 地图的轨道控制器

  /* 事件回调 */
  let leadWayMsgBlock = null // 导航信息的回调
  let changeMapidBlock = null // 更改地图id的回调
  let fristLocationBlock = null // 用户第一次定位
  let scaleBlock = null // 比例尺
  let lookAtZoneBlock = null // 看到的区域

  map.setLookAtZone = function(callBack) {
    lookAtZoneBlock = callBack
  }

  map.scaleAction = function(callBack) {
    scaleBlock = callBack
  }

  // 地图图片设置
  let mapImageUrl = mapImageInfoUrl()

  /* ====================================== 设置地图的参数 ======================================*/

  map.setMapImageUrl = function(key, value) {
    mapImageUrl[key] = value
  }

  map.setBGColor = function(color) {
    mapSetInfo.renderBg = color
  }

  // 设置整个地图的宽度和高度
  map.setViewSize = function(width, height) {
    windowHeight = height
    windowWidth = width
  }

  /* 设置承载地图的div并且将其显示 */
  map.addRenderByID = function(divId) {
    if (divId == null || typeof (divId) == 'undefined') {
      return
    }
    selectDivId = divId
    if (mapSetInfo.renderAdd == false) {
      let superdiv = document.getElementById(selectDivId)
      if (superdiv != null) {
        superdiv.appendChild(renderer.domElement)
        mapSetInfo.renderAdd = true
      }
    }

    if (mapSetInfo.zhiNanZhenAdd == false) {
      let zhiNanZhenDiv = document.getElementById(mapSetInfo.divid)
      if (zhiNanZhenDiv != null) {
        zhiNanZhenDiv.appendChild(zhiNanZhenRenderer.domElement)
      }
      mapSetInfo.zhiNanZhenAdd = true
    }
  }

  map.showRender = function() {
    let superdiv = document.getElementById(selectDivId)
    if (superdiv == null) {
      return
    }
    superdiv.appendChild(renderer.domElement)
    let zhiNanZhenDiv = document.getElementById(mapSetInfo.divid)
    if (zhiNanZhenDiv != null) {
      zhiNanZhenDiv.appendChild(zhiNanZhenRenderer.domElement)
    }
  }

  // 设置地图的最大旋转角
  map.setMaxPolarAngle = function(maxAngle) {
    maxPolarAngle = maxAngle
    controls.maxPolarAngle = maxPolarAngle
    controls.maxPolar = maxPolarAngle
  }

  // 设置地图的最小旋转角
  map.setMinPolarAngle = function(minAngle) {
    minPolarAngle = minAngle
    controls.minPolarAngle = minPolarAngle
  }

  // 设置承载地图的div的id
  map.setselectDevice = function(id) {
    selectDivId = id
  }

  /* ============================================================================*/

  /* ====================================== 展示所有存储的地图 ======================================*/
  map.returnFirstMapid = function() {
    let orderMapList = dataManager.getMapOrderList()
    if (orderMapList.length == 0) {
      return {
        result: false,
        id: ''
      }
    }
    let order = orderMapList[0]
    return {
      result: true,
      id: order['mapid']
    }
  }

  function resetGroupPosition() {

    layerDataManager.addGroup(scene)

    if (travlLine.parent != null) {
      scene.remove(travlLine)
    }
    scene.add(travlLine)

    if (leadGroup.parent != null) {
      scene.remove(leadGroup)
    }
    scene.add(leadGroup)

    gridManager.addGroup(scene)

    heatMapManager.addGroup(scene)

    leadPointManager.addGroup(scene)

    simulationManager.addGroup(scene)

    labelGroupManager.addGroup(scene)

    showLeadWayExist()

    if (textGroup.parent != null) {
      scene.remove(textGroup)
    }
    scene.add(textGroup)

    pointManager.addGroup(scene)

    userManager.addGroup(scene)
  }

  // 只显示指定区域
  map.showMainViewDetailView = function(config, callBack) {
    let showShadow = false
    if (analysis.jsonKeyIsExist(config, ['showShadow'])) {
      showShadow = config['showShadow']
    }

    function hiddenMainView(mapOrderList, index, config, complete) {
      if (index >= mapOrderList.length) {
        complete()
        return
      }
      let mapInfo = mapOrderList[index]
      let g = returnMapgroup(mapInfo['mapid'])
      if (g == null) {
        hiddenMainView(mapOrderList, index + 1, config, complete)
      } else {
        g.hiddenOrShowDetail(config)
        setTimeout(() => {
          hiddenMainView(mapOrderList, index + 1, config, complete)
        }, 10)
      }
    }

    function hiddenCommonView(config, complete) {
      let zoneId = config['zoneId']

      let updateHiddenFloor = false
      if (analysis.jsonKeyIsExist(config, ['updateHiddenFloor'])) {
        updateHiddenFloor = config['updateHiddenFloor']
      }

      let hiddenExtra = true
      if (analysis.jsonKeyIsExist(config, ['hiddenExtra'])) {
        hiddenExtra = config['hiddenExtra']
      }

      if (hiddenExtra == false) {
        mapSetInfo['hiddenFloor'] = false
        if (zoneId != '') {
          let lockZone = {}
          lockZone[zoneId] = '1'
          mapSetInfo['lockZone'] = lockZone
        } else {
          delete mapSetInfo['lockZone']
        }
      } else {
        delete mapSetInfo['lockZone']
        if (updateHiddenFloor == false) {
          mapSetInfo['hiddenFloor'] = zoneId == '' ? false : true
        } else {
          mapSetInfo['hiddenFloor'] = true
        }
      }

      let showZero = false
      if (analysis.jsonKeyIsExist(config, ['showZero'])) {
        showZero = config['showZero']
      }
      if (showZero) {
        complete()
        return
      }

      commonGroup.hiddenOrShowDetail(config)
      setTimeout(() => {
        complete()
      }, 10)
    }

    let mainViewCompleteBlock = () => {
      textGroup.hiddenOrShowDetailText(config)
      let move = false
      if (analysis.jsonKeyIsExist(config, ['move'])) {
        move = config['move']
      }

      if (move == false) {
        let border = dataManager.getMapBorder(returnNowMapId())
        let leftUp = { x: border.leftUp.x, y: 0, z: border.leftUp.y }
        let rightDown = { x: border.rightDown.x, y: 0, z: border.rightDown.y }
        let lightConfig = { leftUp: leftUp, rightDown: rightDown }
        if (analysis.jsonKeyIsExist(config, ['color'])) {
          lightConfig['color'] = config['color']
        }
        if (analysis.jsonKeyIsExist(config, ['intensity'])) {
          lightConfig['intensity'] = config['intensity']
        }

        lightConfig['showShadow'] = showShadow
        updateSpotLight(lightConfig)
        setTimeout(() => {
          callBack()
        }, 10)
        return
      }

      let zoneId = config['zoneId']
      let zoneInfoList = dataManager.getZoneInfo(returnNowMapId())
      let leftUp = null
      let rightDown = null
      for (let i = 0; i < zoneInfoList.length; i++) {
        let zone = zoneInfoList[i]
        if (zone['areaid'] == zoneId && analysis.jsonKeyIsExist(zone, ['leftUp', 'rightDown'])) {
          leftUp = JSON.parse(JSON.stringify(zone['leftUp']))
          rightDown = JSON.parse(JSON.stringify(zone['rightDown']))
          break
        }
      }

      let zoomScale = false
      if (analysis.jsonKeyIsExist(config, ['zoomScale'])) {
        zoomScale = config['zoomScale']
      }
      if (leftUp == null || rightDown == null) {
        if (zoomScale) {
          let border = dataManager.getMapBorder(returnNowMapId())
          leftUp = { x: border.leftUp.x, y: 0, z: border.leftUp.y }
          rightDown = { x: border.rightDown.x, y: 0, z: border.rightDown.y }
        } else {
          setTimeout(() => {
            callBack()
          }, 10)
          return
        }
      }
      let centerX = (leftUp.x + rightDown.x) / 2.0
      let centerZ = (leftUp.z + rightDown.z) / 2.0

      let scaleInfo = {}
      if (zoomScale) {

        let widthScale = 1

        let heightScale = 1

        if (analysis.jsonKeyIsExist(config, ['widthScale'])) {
          widthScale = config['widthScale']
        }

        if (analysis.jsonKeyIsExist(config, ['heightScale'])) {
          heightScale = config['heightScale']
        }

        let width = (rightDown.x - leftUp.x) * widthScale
        let height = (rightDown.z - leftUp.z) * heightScale

        leftUp.x = centerX - width / 2.0
        leftUp.z = centerZ - height / 2.0

        rightDown.x = centerX + width / 2.0
        rightDown.z = centerZ + height / 2.0

        scaleInfo['leftUp'] = leftUp
        scaleInfo['rightDown'] = rightDown
      }

      let lightConfig = { leftUp: leftUp, rightDown: rightDown }
      if (analysis.jsonKeyIsExist(config, ['color'])) {
        lightConfig['color'] = config['color']
      }
      if (analysis.jsonKeyIsExist(config, ['intensity'])) {
        lightConfig['intensity'] = config['intensity']
      }
      lightConfig['showShadow'] = showShadow
      updateSpotLight(lightConfig)
      cameraMoveAnimation({
        target: {
          target: {
            x: centerX,
            z: centerZ,
            y: 0
          }
        },
        rotate: {},
        scale: scaleInfo,
        time: 300
      })
      setTimeout(() => {
        callBack()
      }, 10)
    }

    let commonComPleteBlock = () => {
      let mapOrderList = dataManager.getMapOrderList()
      hiddenMainView(mapOrderList, 0, config, mainViewCompleteBlock)
    }

    hiddenCommonView(config, commonComPleteBlock)
  }

  map.getLookAtZoneInfo = function() {
    return dataManager.getLookAtZoneInfo()
  }

  // 获取地图区域名称和id
  map.getZoneInfo = function() {
    let zoneInfo = []
    let mapOrderList = dataManager.getMapOrderList()
    for (let i = mapOrderList.length - 1; i >= 0; i--) {
      let order = mapOrderList[i]
      let mapid = order.mapid
      let zone = dataManager.getZoneInfo(mapid)
      let zoneList = []
      for (let j = 0; j < zone.length; j++) {
        let z = zone[j]
        let zoneInfoId = ''
        if (analysis.jsonKeyIsExist(z, ['zoneInfoId'])) {
          zoneInfoId = z['zoneInfoId']
        }
        let polygon = analysis.getPolygonAreaCenter(z['areas'])
        zoneList.push({
          name: z['title'],
          id: z['areaid'],
          mapid: mapid,
          mapName: dataManager.getMapName(mapid),
          zoneInfoId: zoneInfoId,
          x: polygon[1],
          z: polygon[0],
          y: 0
        })
      }
      zoneInfo.push({
        mapid: mapid,
        mapName: dataManager.getMapName(mapid),
        zoneList: zoneList
      })
    }
    return zoneInfo
  }

  function copyJson(json) {
    return JSON.parse(JSON.stringify(json))
  }

  function returnMapLeadInfo(mapid) {
    if (analysis.jsonKeyIsExist(leadCubeInfo.groupInfo.info, [mapid])) {
      return leadCubeInfo.groupInfo.info[mapid]
    } else {
      return {}
    }
  }

  function setMapLeadInfo(mapid, info) {
    leadCubeInfo.groupInfo.info[mapid] = info
  }

  /* ====================================== *** ======================================*/

  /* ====================================== 将地图缩放到合适的等级 ======================================*/
  map.zoomToSize = function(config) {
    let mapid = returnNowMapId()
    if (mapid == '') {
      return
    }
    let zoneInfo = config.zoneInfo
    if (analysis.jsonKeyIsExist(zoneInfo, [mapid]) == false) {
      pointManager.zoomMapToPointsFence({})
      return
    }
    let paths = zoneInfo[mapid]
    let maxX = null
    let minX = null
    let maxZ = null
    let minZ = null
    for (let i = 0; i < paths.length; i++) {
      let path = paths[i].path
      for (let j = 0; j < path.length; j++) {
        let p = path[j]
        maxX = (maxX == null ? p.x : maxX < p.x ? p.x : maxX)
        maxZ = (maxZ == null ? p.z : maxZ < p.z ? p.z : maxZ)
        minX = (minX == null ? p.x : minX > p.x ? p.x : minX)
        minZ = (minZ == null ? p.z : minZ > p.z ? p.z : minZ)
      }
    }
    let target = {
      x: (minX + maxX) / 2.0,
      z: (minZ + maxZ) / 2.0,
      y: 0
    }
    let leftUp = {
      x: minX,
      y: 0,
      z: minZ
    }
    let rightDown = {
      x: maxX,
      y: 0,
      z: maxZ
    }
    cameraMoveAnimation({
      target: {
        target: target
      },
      rotate: {
        angleY: 0.01,
        angleXZ: 0
      },
      scale: {
        leftUp: leftUp,
        rightDown: rightDown
      },
      time: 300
    })
  }

  map.zoomMapToFixSize = function(mapid) {
    let result = controls.zoomMapToFixSize(mapid, windowWidth, windowHeight, 1, dataManager.getMapBorder(mapid),
      function() {
        mapRotateOrZoom()
      })
    if (result == false && userManager.isLeadWayExist() && analysis.jsonKeyIsExist(leadCubeInfo.leadPath, [mapid]) && simulationManager
      .returnSimulationStatus() == false && leadCubeInfo.shouldSendLeadMsg == false) {
      zoomToFixPath(mapid)
    }
  }

  map.getCameraSetting = function(config) {
    return controls.getCameraSetting(config)
  }

  map.updateOrgSetting = function(config, callBack) {
    let finishCallBack = () => {
      mapRotateOrZoom()
      callBack()
    }
    controls.updateOrgSetting(config, () => {
      mapRotateOrZoom()
    }, finishCallBack)
  }

  map.showMaptextHiddenOrShow = function(hidden) {

  }

  function zoomToFixPath(mapid) {
    controls.enablePan = false
    controls.enableZoom = false
    controls.enableRotate = false
    showLeadWayExist()
    let y = returnFloorHeight(mapid)
    let minX = null
    let minZ = null
    let maxX = null
    let maxZ = null
    let leadPath = []
    if (analysis.jsonKeyIsExist(leadCubeInfo.leadPath, [mapid])) {
      leadPath = leadCubeInfo.leadPath[mapid]
    }
    let paths = leadPath.concat(userManager.getExtraPath({
      mapid: mapid
    }))
    for (let i = 0; i < paths.length; i++) {
      let data = paths[i]
      let path = data.path
      for (let j = 0; j < path.length; j++) {
        let p = path[j]
        minX = (minX == null ? p.x : (minX >= p.x ? p.x : minX))
        minZ = (minZ == null ? p.z : (minZ >= p.z ? p.z : minZ))
        maxX = (maxX == null ? p.x : (maxX <= p.x ? p.x : maxX))
        maxZ = (maxZ == null ? p.z : (maxZ <= p.z ? p.z : maxZ))
      }
    }
    if (maxX == null || maxZ == null || minX == null || minZ == null) {
      return
    }
    let width = Math.abs(maxX - minX) + 30
    let height = Math.abs(maxZ - minZ) + 30
    let center = {
      x: (maxX + minX) / 2.0,
      y: y,
      z: (maxZ + minZ) / 2.0
    }
    let leftUp = {
      x: center.x - width,
      y: center.y,
      z: center.z - height
    }
    let rightDown = {
      x: center.x + width,
      y: center.y,
      z: center.z + height
    }
    let time = 400
    cameraMoveAnimation({
      target: {
        target: center
      }, rotate: {
        angleY: 0.001,
        angleXZ: 0
      }, scale: {
        leftUp: leftUp,
        rightDown: rightDown
      }, time: 300
    })
    setTimeout(function() {
      controls.enablePan = true
      controls.enableZoom = true
      controls.enableRotate = true
      mapRotateOrZoom()
    }, time + 100)
  }

  /* ====================================== *** ======================================*/

  /* ====================================== 中心移动 ======================================*/
  map.zoomOut = function(isAdd /* true为放大 false为缩小 */) {
    controls.zoomNextLevel(isAdd, 200, () => {
      mapRotateOrZoom()
    }, () => {
      mapRotateOrZoom()
    })
  }
  /* ============================================================================*/

  /* ====================================== 手机定位 ======================================*/
  map.changeCameraMode = function(mode) {
    if (selectCameraMode == mode) {
      return
    }
    selectCameraMode = mode
    switch (selectCameraMode) {
      case cameraMode.mapMove:
        break
      default:
        break
    }
    userManager.changeCameraMode(controls.getRotateXZ())
  }

  function dealWithLeadInfoDetalMsg(findInfo, config) {
    function findEndPointRoomInfo() {
      let roomInfo = {
        areaid: '',
        areas: [],
        title: ''
      }
      if (leadCubeInfo.leadOrder.length == 0) {
        return roomInfo
      }
      let endOrder = leadCubeInfo.leadOrder[0]
      if (analysis.jsonKeyIsExist(leadCubeInfo.leadPath, [endOrder.mapid]) == false) {
        return roomInfo
      }
      let pathInfo = leadCubeInfo.leadPath[endOrder.mapid]
      let path = pathInfo[0].path
      let endPoint = path[0]
      if (analysis.jsonKeyIsExist(endPoint, ['room'])) {
        roomInfo = endPoint['room']
        roomInfo['x'] = endPoint.x
        roomInfo['z'] = endPoint.z
      }
      return roomInfo
    }

    if (leadWayMsgBlock == null || findInfo.result == false) {
      return
    }
    let stopWorking = false
    if (analysis.jsonKeyIsExist(findInfo, ['stopWorking'])) {
      stopWorking = findInfo['stopWorking']
    }
    let leadInfo = findInfo.leadInfo
    leadInfo['stopWorking'] = stopWorking
    if (leadInfo.isEnd) {
      leadInfo['update'] = true
      leadWayMsgBlock(leadInfo)
      return
    }
    let startMapName = dataManager.getMapLocationName({
      mapid: findInfo['startMapid'],
      x: findInfo.point.x,
      z: findInfo.point.z
    })
    // let start = dataManager.getOrgName(findInfo['startMapid']) + '->' + startMapName;
    let start = startMapName
    let endRoom = findEndPointRoomInfo()
    let endMapName = ''
    if (analysis.jsonKeyIsExist(endRoom, ['x', 'z'])) {
      endMapName = dataManager.getMapLocationName({
        mapid: findInfo['nextMapid'],
        x: endRoom['x'],
        z: endRoom['z']
      })
    } else {
      endMapName = dataManager.getMapName(findInfo['nextMapid'])
    }
    let end = '目的地 : ' + endRoom.title + '->' + endMapName
    leadInfo['start'] = start
    leadInfo['end'] = end
    leadInfo['endWayPoint'] = {
      mapid: findInfo['nextMapid'],
      x: endRoom['x'],
      z: endRoom['z']
    }

    if (config.update == true) {
      leadInfo['isPlayMusic'] = config.isPlayMusic
      leadInfo['update'] = true
    }

    leadWayMsgBlock(leadInfo)

    if (selectCameraMode != 3) {
      return
    }

    let rotate = false
    if (analysis.jsonKeyIsExist(config, ['rotate'])) {
      rotate = config['rotate']
    }
    if (rotate == false) {
      return
    }

    if (analysis.jsonKeyIsExist(findInfo, ['mapRotateAngle']) == false) {
      return
    }

    let point = findInfo.point

    let target = { x: point.x, z: point.z, y: 2 }

    let nowTarget = controls.target

    let x = nowTarget.x - target.x
    let z = nowTarget.z - target.z

    let dis = Math.sqrt(x * x + z * z)

    let angle = Math.ceil(controls.getRotateXZ() / Math.PI * 180)
    let rotateAngle = Math.ceil(findInfo.mapRotateAngle)

    if (dis >= 5 * 10) {

    } else {
      if (angle == rotateAngle) {
        return
      }
    }

    let setSize = false
    if (analysis.jsonKeyIsExist(config, ['setSize'])) {
      setSize = config['setSize']
    }

    let scaleInfo = {}
    if (setSize) {
      let width = 0
      let height = 0
      let zoneRes = dataManager.getPositionAtZone(target, returnNowMapId())
      if (zoneRes.result) {
        width = zoneRes.width * 1 / 12
        height = zoneRes.height * 1 / 12
      } else {
        let size = dataManager.getMapFence(returnNowMapId())
        width = size.width * 1 / 20
        height = size.height * 1 / 20
      }
      let leftUp = {
        x: target.x - width,
        y: target.y,
        z: target.z - height
      }
      let rightDown = {
        x: target.x + width,
        y: target.y,
        z: target.z + height
      }
      scaleInfo['leftUp'] = leftUp
      scaleInfo['rightDown'] = rightDown
    }

    cameraMoveAnimation({
      target: {
        target: target
      }, rotate: {
        angleY: (Math.PI / 180),
        angleXZ: rotateAngle * -1 / 180 * Math.PI
      }, scale: scaleInfo, time: 200
    })
  }

  /* ====================================== ======================================*/

  /* ====================================== 点击事件 ======================================*/
  map.locationTest = function(event) {
    let finder = getIntersects(event)
    if (finder.result == false) {
      return
    }
    let mapid = returnNowMapId()
    let newClick = new THREE.Vector3()
    let nowGroup = returnNowMapGroup()
    let x = nowGroup.position.x
    let z = nowGroup.position.z
    newClick.set(parseFloat((finder.x - x - parseFloat(0))), parseFloat(finder.y), parseFloat((finder.z - z -
      parseFloat(0))))
    if (mapid == null || typeof (mapid) == 'undefined') {
      return
    }
    let data = returnMapJson(mapid)
    if (data.length == 0) {
      return
    }
    let isInMap = false
    for (let i = 0; i < data.length; i++) {
      let info = data[i]
      if (analysis.isInPolygon([newClick.x, 0, newClick.z], info['areas']) == true) {
        isInMap = true
        break
      }
    }
    if (isInMap == false) {
      return
    }

    userManager.startSetLocaiton({
      x: newClick.x,
      y: newClick.z,
      scale: 10,
      mapid: mapid,
      type: 'location'
    })
  }

  // 点击地图选择起点和终点
  map.clickMapAndLead = function(event) {
    let finder = getIntersects(event)
    if (finder.result == false) {
      return
    }
    let mapid = returnNowMapId()
    let newClick = new THREE.Vector3()
    let nowGroup = returnNowMapGroup()
    let x = nowGroup.position.x
    let z = nowGroup.position.z
    newClick.set(parseFloat((finder.x - x - parseFloat(0))), parseFloat(finder.y), parseFloat((finder.z - z -
      parseFloat(0))))
    if (mapid == null || typeof (mapid) == 'undefined') {
      return
    }
    let data = returnMapJson(mapid)
    if (data.length == 0) {
      return
    }
    let isInMap = false
    let zero = dataManager.getZeroInfo(mapid)
    for (let i = data.length - 1; i >= 0; i--) {
      let info = data[i]
      if (analysis.isInPolygon([newClick.x, 0, newClick.z], info['areas']) == true) {
        if (analysis.jsonKeyIsExist(info, ['areaid']) == false || info.areaid != zero.areaid) {
          let polygon = analysis.getPolygonAreaCenter(info['areas'])
          newClick.x = polygon[1]
          newClick.z = polygon[0]
        }
        isInMap = true
        break
      }
    }
    if (isInMap == false) {
      return
    }
    startByDijkstra(newClick, mapid)
  }

  function startByDijkstra(point, mapid) {
    let leadIconInfo = leadPointManager.getLeadIconInfo()
    let imageUrl = leadIconInfo.start == null ? mapImageUrl.qidianPhone : mapImageUrl.zhongdianPhone
    let res = leadPointManager.createLeadIcon({
      position: new THREE.Vector3(point.x, 0, point.z),
      index: dataManager.getMapIdIndex(mapid),
      imageUrl: imageUrl
    })
    if (res.result == false) {
      return
    }
    let newLeadIconInfo = leadPointManager.getLeadIconInfo()
    userManager.getLeadWay_showLeadWay(newLeadIconInfo.start.data, newLeadIconInfo.start.mapid, point, mapid)
  }

  function showLeadWayExist() {
    if (userManager.isLeadWayExist() == false) {
      clearPath()
      return
    }
    let nextMapid = ''
    if (leadCubeInfo.leadOrder.length > 0) {
      let count = leadCubeInfo.leadOrder.length >= 2 ? leadCubeInfo.leadOrder.length - 2 : leadCubeInfo.leadOrder
        .length - 1
      let order = leadCubeInfo.leadOrder[count]
      nextMapid = order.mapid
    }
    let mapid = returnNowMapId()
    let y = returnFloorHeight(mapid)
    let poList = []
    if (analysis.jsonKeyIsExist(leadCubeInfo.poInfo, [mapid])) {
      poList = leadCubeInfo.poInfo[mapid]
    }

    /* ------------------------------------------------ */
    function isAllFloor() {
      let zoneCount = Object.keys(leadCubeInfo.zoneIdInfo).length
      let orderCount = leadCubeInfo.leadOrder.length
      return zoneCount == 1 && orderCount > 1 && selectCameraMode != cameraMode.simulation && leadCubeInfo
        .shouldSendLeadMsg == false
    }

    // 单楼层显示
    function showSingleFloor() {
      let path = []
      if (analysis.jsonKeyIsExist(leadCubeInfo.leadPath, [mapid])) {
        path = leadCubeInfo.leadPath[mapid]
      }
      let mapOrderList = dataManager.getMapOrderList()
      let floorMapInfo = mapOrderList[mapOrderList.length - 1]
      let floorMapid = floorMapInfo.mapid
      let zonePath = []
      if (mapSetInfo.leadWayShowAt1F && floorMapid != mapid && analysis.jsonKeyIsExist(leadCubeInfo.leadPath, [
        floorMapid
      ])) {
        // 显示1楼的路径
        zonePath = leadCubeInfo.leadPath[floorMapid]
      }
      let extraPath = userManager.getExtraPath({
        mapid: mapid
      })
      path = path.concat(extraPath)

      leadGroup.showLeadWayExist(path, poList, {
        zonePath: zonePath,
        leadOrder: leadCubeInfo.leadOrder,
        resolution: new THREE.Vector2(windowWidth, windowHeight),
        mapid: mapid,
        y: y,
        nextMapid: nextMapid,
        mode: mapSetInfo.cameraMode,
        hiddenStart: (simulationManager.simulationMode() || leadCubeInfo.shouldSendLeadMsg == true)
      })
      leadGroup.visible = true
      leadPointManager.clearLeadIcon()
    }

    /* ------------------------------------------------ */

    // 多楼层单独渲染在另一个div上
    let showDetail = isAllFloor()
    let isOutOfMap = userManager.returnIsOutOfMap()
    if (showDetail && isOutOfMap == false) {
      detailManager.sendDetail({
        result: showDetail
      })
      let id = ''
      for (const zoneId in leadCubeInfo.zoneIdInfo) {
        id = zoneId
      }
      // 多楼层显示
      detailManager.showAllFloorLeadWay(id, mapid, nextMapid)
    } else {
      detailManager.cancelShowAllMap()
    }
    showSingleFloor()
  }

  function clearLeadWay() {
    leadPointManager.clearLeadIcon()
    leadGroup.visible = false
  }

  /* ============================================================================*/

  /* ====================================== 批量打点 ======================================*/

  map.clickMapPoint = function(event) {
    let finder = getIntersects(event)
    if (finder.result == false) {
      return {
        result: false
      }
    }
    return {
      result: true,
      point: finder
    }
  }
  /* ============================================================================*/

  /* ====================================== Dijkstra 导航 ======================================*/
  map.showImitateWay = function(info, lead) {
    let leadStr = lead
    switch (leadStr) {
      case 'louti':
        leadCubeInfo.linkType = leadPointType.louti
        break
      case 'dianti':
        leadCubeInfo.linkType = leadPointType.dianti
        break
      case 'futi':
        leadCubeInfo.linkType = leadPointType.futi
        break
      default:
        break
    }
    if (analysis.jsonKeyIsExist(info, [leadStr]) == false) {
      return {
        isPo: true
      }
    }
    let leadInfo = copyJson(info)[leadStr]
    clearPath()
    leadCubeInfo.leadPath = leadInfo['list']
    leadCubeInfo.poInfo = leadInfo['po']
    leadCubeInfo.leadOrder = leadInfo['order']
    leadCubeInfo.zoneIdInfo = leadInfo['zoneIdInfo']

    if (leadCubeInfo.leadOrder.length == 0) {
      return {
        isPo: Object.keys(leadCubeInfo.poInfo).length == 0
      }
    }
    let order = leadCubeInfo.leadOrder[leadCubeInfo.leadOrder.length - 1]
    let mapid = order.mapid
    if (mapid != returnNowMapId()) {
      changeMap(mapid)
    }
    zoomToFixPath(mapid)
    return {
      isPo: Object.keys(leadCubeInfo.poInfo).length == 0
    }
  }

  map.findRoomInfo = function(roomInfo, config) {
    let result = dataManager.returnMap_RoomCenter(roomInfo.mapid, roomInfo.roomAreaId, config)
    return {
      result: result.result,
      point: {
        x: result.x,
        y: 0,
        z: result.y,
        mapid: roomInfo.mapid,
        roomName: result.name,
        organizationName: result.orgName,
        mapName: result.mapName,
        roomAreaId: roomInfo.roomAreaId,
        type: 'room',
        height: result.height
      }
    }
  }

  map.clearPath = function() {
    clearPath()
  }

  function clearPath() {
    leadPointManager.clearLeadIcon()
    leadCubeInfo.zoneIdInfo = {}
    leadCubeInfo.leadPath = {}
    leadCubeInfo.poInfo = {}
    delete leadCubeInfo.groupInfo['findInfo']
    leadCubeInfo.leadOrder = []
    leadGroup.visible = false
    leadGroup.clearRenderSetting()
  }

  // 设置导航点的类型
  map.setLeadTypeInfo = function(type) {
    let result = (type == leadPointType.louti || type == leadPointType.dianti || type == leadPointType.futi)
    if (type == null || typeof (type) == 'undefined' || result == false) {
      return
    }
    alert('切换为' + returnTypeStyle(type) + '模式')
    leadCubeInfo.linkType = type
  }

  function returnTypeStyle(type) {
    let str = '-null-'
    switch (type) {
      case leadPointType.louti:
        str = '楼梯'
        break
      case leadPointType.dianti:
        str = '电梯'
        break
      case leadPointType.futi:
        str = '扶梯'
        break
      default:
        break
    }
    return str
  }

  /* ====================================== ======================================*/

  /* ====================================== 计算 ======================================*/

  /* 获取射线与平面相交的交点 */
  function getIntersects(event) {
    let mapid = returnNowMapId()
    let commonList = commonGroup.returnGroupMesh({
      zoneInfo: true
    })
    let group = returnNowMapGroup()
    let intersectConfig = {
      divId: selectDivId,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      camera: camera,
      meshs: commonList.concat(group.returnGroupMesh(0))
    }
    let finder = intersect.getIntersects(event, intersectConfig)
    if (finder.result == false) {
      return {
        result: false,
        x: 0,
        y: 0,
        z: 0,
        mapid: mapid
      }
    }
    return {
      result: false,
      x: finder.x,
      y: finder.y,
      z: finder.z,
      mapid: mapid
    }
  }

  function returnCameraScale() {
    let zoom = controls.target.distanceTo(controls.object.position)
    let dis = mapSetInfo['distance']
    return (dis / zoom) * 5
  }

  function returnZoneInfoByPoint(mapid, point) {
    let mapJson = dataManager.getZoneInfo(mapid)
    let roomInfo = {
      areas: [],
      areaid: '',
      zoneInfoId: ''
    }
    for (let i = mapJson.length - 1; i >= 0; i--) {
      let room = mapJson[i]
      let areas = room.areas
      if (analysis.isInPolygon([point.x, 0, point.z], areas) == true) {
        roomInfo = room
        break
      }
    }
    return roomInfo
  }

  map.returnRoomInfoByPoint = function(mapid, point) {
    return returnRoomInfoByPoint(mapid, point)
  }

  function returnRoomInfoByPoint(mapid, point) {
    let mapJson = returnMapJson(mapid)
    let roomInfo = {
      areas: [],
      areaid: ''
    }
    for (let i = mapJson.length - 1; i >= 0; i--) {
      let room = mapJson[i]
      let areas = room.areas
      if (analysis.isInPolygon([point.x, 0, point.z], areas) == true) {
        roomInfo = JSON.parse(JSON.stringify(room))
        break
      }
    }
    return roomInfo
  }

  function reutrnPointToAllowLocation(mapid, point, shouldCorrect) {
    let status = userManager.userStatus()
    if (status.user == false || status.isOutOfMap) {
      return {
        result: true,
        x: point.x,
        z: point.z
      }
    }
    let position = status.user == false ? { x: point.x, z: point.z } : status.position
    let fence = returnFloorArea(mapid)
    if (analysis.isInPolygon([point.x, 0, point.z], fence) == false) {
      // 超出范围
      for (let i = 0; i < fence.length; i++) {
        let start = i - 1
        if (start < 0) {
          start = fence.length - 1
        }
        let next = i
        let startP = fence[start]
        let x1 = startP[0]
        let y1 = startP[2]
        let nextP = fence[next]
        let x2 = nextP[0]
        let y2 = nextP[2]
        let result = analysis.getLinesIntersect(x2, y2, x1, y1, position.x, position.z, point.x, point.z)
        if (result.result) {
          let target = new THREE.Vector3(point.x, 0, point.z)
          let line = new THREE.Line3(new THREE.Vector3(x1, 0, y1), new THREE.Vector3(x2, 0, y2))
          let newPoint = new THREE.Vector3(0, 0, 0)
          line.closestPointToPoint(target, false, newPoint)
          let res = line.closestPointToPointParameter(newPoint, false)
          if (res >= 0 && res <= 1) {
            return {
              result: true,
              x: newPoint.x,
              z: newPoint.z
            }
          } else {
            // 与墙相交得到点
            return {
              result: true,
              x: result.x,
              z: result.y
            }
          }
        }
      }
    }
    if (shouldCorrect == false) {
      return {
        result: true,
        x: point.x,
        z: point.z
      }
    }
    let mapJson = returnMapJson(mapid).concat(dataManager.getWallInfo(mapid))
    for (let i = mapJson.length - 1; i >= 0; i--) {
      let room = mapJson[i]
      let areas = room.areas
      if (analysis.isInPolygon([point.x, 0, point.z], areas) == false) {
        continue
      }
      if (i == 0) {
        return {
          result: true,
          x: point.x,
          z: point.z
        }
      }
      let invaild = '1'
      if (analysis.jsonKeyIsExist(room, ['invaild'])) {
        invaild = room['invaild']
      }
      if (invaild == '1') {
        for (let j = 0; j < areas.length; j++) {
          let startP = areas[j]
          let x1 = startP[0]
          let y1 = startP[2]
          let next = j + 1
          if (next >= areas.length) {
            next = 0
          }
          let nextP = areas[next]
          let x2 = nextP[0]
          let y2 = nextP[2]
          let result = analysis.getLinesIntersect(x2, y2, x1, y1, position.x, position.z, point.x, point.z)
          if (result.result) {
            let line = new THREE.Line3(new THREE.Vector3(position.x, 0, position.z), new THREE.Vector3(result.x, 0,
              result.y))
            let newPoint = line.at(0.9, new THREE.Vector3(0, 0, 0))
            return {
              result: true,
              x: newPoint.x,
              z: newPoint.z
            }
          }
        }
        return {
          result: false
        }
      } else {
        return {
          result: true,
          x: point.x,
          z: point.z
        }
      }
    }
    return {
      result: false
    }
  }

  map.reutrnPointRoomId = function(mapid, point) {
    return reutrnPointRoomId(mapid, point)
  }

  function reutrnPointRoomId(mapid, point) {
    let mapJson = returnMapJson(mapid)
    let roomid = ''
    for (let i = mapJson.length - 1; i >= 0; i--) {
      let room = mapJson[i]
      let aeraid = room.areaid
      let areas = room.areas
      if (analysis.isInPolygon([point.x, 0, point.z], areas) == true) {
        roomid = aeraid
        break
      }
    }
    return roomid
  }

  function setSpriteScale(sprite) {
    let scale = returnCameraScale()
    let fov = mapSetInfo['fov']
    if (sprite.uuid == 'Phone-rotate') {
      let s = 1.5 * fov / 75.0
      sprite.scale.set(s / scale, s / scale, 1.0)
    } else if (sprite.uuid == 'simulation') {
      // let s = 50 * fov / 75.0;
      let s = 1.5 * fov / 75.0
      sprite.scale.set(s / scale, s / scale, 1.0)
    }
    if (sprite.uuid == 'Phone-direction') {
      let s = 0.7 * fov / 75.0
      sprite.scale.set(s / scale, s / scale, 1.0)
    }
  }

  // 创建一个指北针
  function createNorth() {
    let div = document.getElementById('zhiNanZhen')
    if (div == null) {
      return
    }
    let width = div.offsetWidth
    let height = div.offsetHeight
    zhiNanZhenRenderer.setSize(width, height)
    zhiNanZhenRenderer.setPixelRatio(window.devicePixelRatio)

    let count = 2
    zhiNanZhenCamera = new THREE.OrthographicCamera(-count, count, count, -count, -count, count)
    zhiNanZhenCamera.position.x = 0
    zhiNanZhenCamera.position.y = 0
    zhiNanZhenCamera.position.z = 0
    zhiNanZhenScene.add(zhiNanZhenCamera)

    zhiNanZhenCamera.lookAt(zhiNanZhenScene.position)

    if (mapSetInfo['circle'] == true) {
      let geometry = new THREE.CircleGeometry(1.9, 64)
      let material = new THREE.MeshBasicMaterial({
        color: 0xffffff
      })
      let circle = new THREE.Mesh(geometry, material)
      circle.position.z = -1
      zhiNanZhenScene.add(circle)
    }

    let imageUrl = mapImageUrl.zhinanzhenPhone
    zhinanzhen = create.createPlane(imageUrl, 1, 1, 4, 4)
    zhiNanZhenScene.add(zhinanzhen)
    zhinanzhen.rotation.z = Math.PI
    rotateZhiNanZhen()
  }

  /* ============================================================================*/

  /* ====================================== 发送手机端消息 ======================================*/
  // 向页面端发送导航消息
  map.setLeadWayActionBlock = function(callBack) {
    leadWayMsgBlock = callBack
  }

  map.changeMapid = function(callBack) {
    changeMapidBlock = callBack
  }

  /* ============================================================================*/

  /* ====================================== 切换2、3d动画 ======================================*/
  let change2D3Dblock = null
  map.change2D3DDetect = function(callBack) {
    change2D3Dblock = callBack
  }

  function sendChange2D_3Dmsg(msg) {
    if (change2D3Dblock != null) {
      change2D3Dblock({
        result: msg
      })
    }
  }

  // 地图切换成2d
  map.changeTo2D = function(config) {
    let reset = false
    if (analysis.jsonKeyIsExist(config, ['reset'])) {
      reset = config['reset']
    }
    if (reset) {
      cameraMoveAnimation({
        target: {}, scale: {}, rotate: {
          angleY: minPolarAngle,
          angleXZ: 0
        }, time: 300
      })
    } else {
      controls.changeTo2D(150, minPolarAngle, function() {
        mapRotateOrZoom()
      })
    }
  }
  // 地图切换成3d
  map.changeTo3D = function() {
    controls.changeTo3D(maxPolarAngle, minPolarAngle, 150, function() {
      mapRotateOrZoom()
    })
  }
  /* ============================================================================*/

  /* ============================================================================*/

  map.returnAllMapLeadInfo = function() {
    let leadInfo = {}
    let mapOrderList = dataManager.getMapOrderList()
    for (let i = 0; i < mapOrderList.length; i++) {
      let info = mapOrderList[i]
      let mapid = info.mapid
      leadInfo[mapid] = returnMapLeadInfo(mapid)
    }
    return leadInfo
  }

  map.updateMapDataInfo = function(mapInfos, config, callBack) {
    let receiveShadow = false
    if (analysis.jsonKeyIsExist(config, ['receiveShadow'])) {
      receiveShadow = config['receiveShadow']
    }

    /* ---------------------- */
    function updateCameraInitialPosition() {
      let orderList = dataManager.getMapOrderList()
      for (let i = 0; i < orderList.length; i++) {
        let orderInfo = orderList[i]
        let border = dataManager.getMapBorder(orderInfo['mapid'])
        controls.updateCameraInitalPosition({
          border: border,
          forceDis: !mapSetInfo.openTheWorld
        })
      }
    }

    function createFloorGroup(index, orderList, objectList, useCommon, callBack) {
      if (index >= orderList.length) {
        callBack()
        return {
          result: true
        }
      }
      let order = orderList[index]
      let mapId = order['mapid']
      let g = createFloorManager()
      setMapGroup(g, mapId)
      let loadCallBack = () => {
        if (useCommon == false) {
          dataManager.updateZeroInfo(g.returnFloorInfo(), mapId)
          dataManager.updateMapBorderInfo(g.getMapBorder(), mapId)
        }

        let newIndex = index + 1
        let roomConfig = makeRoomConfig()
        setTimeout(() => {
          createFloorGroup(newIndex, orderList, objectList, useCommon, callBack)
        }, roomConfig.readMapTime)
      }

      g.showObjectByPromise({
        mapid: mapId,
        polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
        polygonOffsetUnits: mapSetInfo.polygonOffsetUnits,
        dataInfo: dataManager.getRoomInfo(mapId),
        isIe: mapSetInfo.isIe,
        mode: mapSetInfo.cameraMode,
        show: dataManager.getShowInfo(mapId),
        extraInfo: dataManager.getExtraInfo(mapId),
        lvhua: dataManager.getLvHuaInfo(mapId),
        zone: dataManager.getZoneInfo(mapId),
        showOther: dataManager.getShowOther(mapId),
        wall: dataManager.getWallInfo(mapId),
        updateBorder: '1',
        receiveShadow: receiveShadow
      }, objectList, loadCallBack)
    }

    function createFloorTitle(orderList, callBack) {
      let mapJsonInfo = {}
      let mapLeadInfo = {}
      let mapCoverList = []
      for (let i = orderList.length - 1; i >= 0; i--) {
        let info = orderList[i]
        let mapid = info.mapid
        if (mapCoverList.length == 0) {
          let list = dataManager.getCoverInfo(mapid)
          for (let j = 0; j < list.length; j++) {
            mapCoverList = mapCoverList.concat(list[j].list)
          }
        }
        mapJsonInfo[mapid] = dataManager.getRoomInfo(mapid).concat(dataManager.getShowInfo(mapid))
        mapLeadInfo[mapid] = returnMapLeadInfo(mapid)
      }
      textGroup.getAllMapTitleInfoByPromise({
        layerHeight: 2,
        mapInfo: mapJsonInfo,
        mode: mapSetInfo.cameraMode,
        leadInfo: mapLeadInfo,
        coverInfo: mapCoverList,
        isShowLinkText: mapSetInfo.isShowLinkText
      }, callBack)
    }

    function createCommonGroup(useCommon, orderList, callBack) {
      if (useCommon == false || orderList.length == 0) {
        callBack()
        return
      }
      let order = orderList[orderList.length - 1]
      let mapid = order['mapid']
      let loadCallback = () => {
        dataManager.updateZeroInfo(commonGroup.returnFloorInfo(), mapid)
        dataManager.updateMapBorderInfo(commonGroup.getMapBorder(), mapid)
        callBack()
      }

      commonGroup.showCommonGroupByPromise({
        mapid: mapid,
        polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
        polygonOffsetUnits: mapSetInfo.polygonOffsetUnits,
        dataInfo: dataManager.getRoomInfo(mapid),
        isIe: mapSetInfo.isIe,
        mode: mapSetInfo.cameraMode,
        show: dataManager.getShowInfo(mapid),
        extraInfo: dataManager.getExtraInfo(mapid),
        lvhua: dataManager.getLvHuaInfo(mapid),
        zone: dataManager.getZoneInfo(mapid),
        showOther: dataManager.getShowOther(mapid),
        wall: [],
        background: dataManager.getBackGround()
      }, loadCallback)
    }

    function createMapGroup(mapInfo, mapid, meshOrderList, defaultOrderList, callBack) {
      if (mapSetInfo.useCommonGroup) {
        let commonPromise = () => {
          return new Promise(resolve => {
            if (commonGroup.returnCreateStatus()) {
              resolve()
              return
            }
            commonGroup.showCommonGroupByPromise({
              receiveShadow: receiveShadow,
              mapid: mapid,
              polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
              polygonOffsetUnits: mapSetInfo.polygonOffsetUnits,
              dataInfo: dataManager.getRoomInfo(mapid),
              isIe: mapSetInfo.isIe,
              mode: mapSetInfo.cameraMode,
              show: dataManager.getShowInfo(mapid),
              extraInfo: dataManager.getExtraInfo(mapid),
              lvhua: dataManager.getLvHuaInfo(mapid),
              zone: dataManager.getZoneInfo(mapid),
              showOther: dataManager.getShowOther(mapid),
              wall: [],
              background: dataManager.getBackGround()
            }, () => {
              resolve()
            })
          })
        }

        commonPromise().then(() => {
          let zeroInfo = commonGroup.returnFloorInfo()
          let borderInfo = commonGroup.getMapBorder()
          dataManager.updateZeroInfo(zeroInfo, mapid)
          dataManager.updateMapBorderInfo(borderInfo, mapid)
          let group = createFloorManager()
          group['mapid'] = mapid

          group.showObjectByPromise({
            mapid: mapid,
            polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
            polygonOffsetUnits: mapSetInfo.polygonOffsetUnits,
            dataInfo: dataManager.getRoomInfo(mapid),
            mode: mapSetInfo.cameraMode,
            show: [],
            extraInfo: [],
            lvhua: [],
            zone: dataManager.getZoneInfo(mapid),
            showOther: [],
            wall: dataManager.getWallInfo(mapid),
            updateBorder: '1',
            receiveShadow: receiveShadow
          }, meshOrderList, () => {
            setTimeout(() => {
              callBack({ group: group })
            }, 10)
          })
        })
      } else {
        let group = createFloorManager()
        group['mapid'] = mapid
        group.showObjectByPromise({
          mapid: mapid,
          polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
          polygonOffsetUnits: mapSetInfo.polygonOffsetUnits,
          dataInfo: dataManager.getRoomInfo(mapid),
          mode: mapSetInfo.cameraMode,
          show: dataManager.getShowInfo(mapid),
          extraInfo: dataManager.getExtraInfo(mapid),
          lvhua: dataManager.getLvHuaInfo(mapid),
          zone: dataManager.getZoneInfo(mapid),
          showOther: dataManager.getShowOther(mapid),
          wall: dataManager.getWallInfo(mapid),
          updateBorder: '1',
          receiveShadow: receiveShadow
        }, defaultOrderList, () => {
          dataManager.updateZeroInfo(group.returnFloorInfo(), mapid)
          dataManager.updateMapBorderInfo(group.getMapBorder(), mapid)
          callBack({ group: group })
        })
        return group
      }
    }

    function startLoad(list, i, callBack) {
      if (i >= list.length || i < 0) {
        callBack()
        return
      }
      let info = list[i]
      if (analysis.jsonKeyIsExist(groupInfo, [info.mapid])) {
        startLoad(list, i - 1, callBack)
        return
      }
      createMapGroup(dataManager.getRoomInfo(info.mapid), info.mapid, ['zone'], ['plane', 'zero', 'showOther', 'extra', 'zone'], (res) => {
        let g = res.group
        setMapGroup(g, info.mapid)
        startLoad(list, i - 1, callBack)
      })

    }

    function lazyCreateMapElement(config, roomInfo, callBack) {
      commonGroup.deleteAll()
      let list = dataManager.getMapOrderList()
      startLoad(list, list.length - 1, () => {
        let mapCoverList = []
        for (let i = list.length - 1; i >= 0; i--) {
          let info = list[i]
          let mapid = info.mapid
          if (mapCoverList.length == 0) {
            let coverList = dataManager.getCoverInfo(mapid)
            for (let j = 0; j < coverList.length; j++) {
              mapCoverList = mapCoverList.concat(coverList[j].list)
            }
          }
        }

        // 分层延迟加载文字
        textGroup.lazyCreateLoadMapTitle({
          roomInfo: roomInfo,
          layerHeight: 2,
          mode: mapSetInfo.cameraMode,
          coverInfo: mapCoverList
        })
        callBack()
      })
    }

    function createMapElement(callBack) {
      commonGroup.deleteAll()
      let list = dataManager.getMapOrderList()
      let loadFloorCallBack = () => {
        createFloorTitle(list, callBack)
      }
      let useCommon = false
      if (mapSetInfo.useCommonGroup && commonGroup.returnCreateStatus() == false) {
        useCommon = true
      }
      let objectList = useCommon ? ['zone', 'room', 'wall'] : ['plane', 'zero', 'zone', 'room', 'wall', 'showOther']

      let loadCommonCallback = () => {
        createFloorGroup(0, list, objectList, useCommon, loadFloorCallBack)
      }

      createCommonGroup(useCommon, list, loadCommonCallback)
    }

    /* ---------------------- */

    let updateLeadInfoCallback = (leadData) => {
      leadPointManager.updateNewLeadInfo(leadData)
    }

    if (controls == null) {
      return
    }

    let lazyLoad = false
    if (analysis.jsonKeyIsExist(config, ['lazyLoad'])) {
      lazyLoad = config['lazyLoad']
    }
    mapSetInfo['lazyLoad'] = lazyLoad

    dataManager.updateMapInfo(mapInfos, config, updateLeadInfoCallback, (res) => {
      mapSetInfo.leadWayShowAt1F = res['leadWayShowAt1F']

      let finishLoadBlock = () => {
        dataManager.getZoneMapInfoList()
        userManager.updateAStartLeadInfo()
        updateCameraInitialPosition()
        mapRotateOrZoom()
        let list = dataManager.getMapOrderList()
        if (list.length > 0) {
          let border = dataManager.getMapBorder(list[0].mapid)
          let leftUp = {
            x: border.leftUp.x,
            z: border.leftUp.y
          }
          let rightDown = {
            x: border.rightDown.x,
            z: border.rightDown.y
          }
          let showShadow = false
          if (analysis.jsonKeyIsExist(config, ['showShadow'])) {
            showShadow = config['showShadow']
          }
          updateSpotLight({ leftUp: leftUp, rightDown: rightDown, showShadow: showShadow })
        }
        setTimeout(() => {
          callBack(list)
        }, 500)
      }

      if (mapSetInfo['lazyLoad']) {
        dataManager.makeNewMapInfo().then(res => {
          lazyCreateMapElement({}, res.mapInfo, finishLoadBlock)
        })
        return
      }
      createMapElement(finishLoadBlock)
    })
  }

  function deleteMapGroup(mapid) {
    if (analysis.jsonKeyIsExist(groupInfo, [mapid])) {
      let group = returnMapgroup(mapid)
      group.deleteAll()
      if (group.parent != null) {
        scene.remove(group)
      }
    }
    deleteInfoByKey(groupInfo, mapid)
  }

  // 返回指定的地图的group
  function returnMapgroup(mapid) {
    if (analysis.jsonKeyIsExist(groupInfo, [mapid])) {
      return groupInfo[mapid]
    }
    return null
  }

  map.getMapLocationName = function(config) {
    return dataManager.getMapLocationName(config)
  }

  function returnFloorAreaId(mapid) {
    let room = dataManager.getZeroInfo(mapid)
    return room.areaid
  }

  function returnFloorArea(mapid) {
    return dataManager.getZeroInfo(mapid).areas
  }

  function returnFloorHeight(mapid) {
    return dataManager.getZeroInfo(mapid).height + 0.5
  }

  map.getMapOrgName = function(mapid) {
    return dataManager.getOrgName(mapid)
  }

  function returnNowMapGroup() {
    let nowMap = returnNowMapId()
    let group = returnMapgroup(nowMap)
    if (group == null) {
      return null
    }
    return group
  }

  function returnNowMapId() {
    let nowMap = leadCubeInfo.groupInfo['select']
    if (nowMap == null || typeof (nowMap) == 'undefined') {
      let mapOrderList = dataManager.getMapOrderList()
      if (mapOrderList.length > 0) {
        let order = mapOrderList[0]
        nowMap = order['mapid']
        setNowMapId(nowMap)
      } else {
        nowMap = ''
      }
    }
    return nowMap
  }

  function setNowMapId(mapid) {
    leadCubeInfo.groupInfo['select'] = mapid // 记录当前的地图的id
    travlLine.updateShowMapid(mapid)
  }

  function deleteInfoByKey(info, key) {
    delete info[key]
  }

  // 返回指定mapid的json数据
  function returnMapJson(mapid) {
    return dataManager.getRoomInfo(mapid)
  }

  function setMapGroup(group, mapid) {
    groupInfo[mapid + ''] = group
  }

  map.getMapData = function(mapid) {
    return returnMapJson(mapid)
  }

  // 是否存在地图 以groupInfo 里面的地图json字符串和group判断
  map.isExistMap = function(mapId) {
    let mapInfo = returnMapJson(mapId)
    let mapGroup = returnMapgroup(mapId)
    return mapInfo != null && typeof (mapInfo) != 'undefined' && mapGroup != null && typeof (mapGroup) != 'undefined'
  }

  function changeMap(mapid) {
    let mapJson = returnMapJson(mapid)
    if (mapJson.length == 0) {
      return
    }
    map.deleteNowMap()
    map.loadMap(mapid)
    if (changeMapidBlock != null) {
      changeMapidBlock(mapid)
    }
  }

  // 删除当前地图
  map.deleteNowMap = function() {
    let mapid = returnNowMapId()
    if (mapid != null) {
      this.deleteMap(mapid)
    }
  }

  map.reset = function() {
    this.deleteMapGroup()
    pointManager.deleteAll()
    gridManager.deleteAll()
    dataManager.deleteAll()
    heatMapManager.deleteAll()
    userManager.deleteAll()
    leadCubeInfo.groupInfo.info = {}
    leadCubeInfo.groupInfo.po = {}
    groupInfo = {}
    deleteInfoByKey(leadCubeInfo.groupInfo, 'select')
    let superdiv = document.getElementById(selectDivId)
    if (superdiv != null && renderer.domElement != null && superdiv.contains(renderer.domElement)) {
      superdiv.removeChild(renderer.domElement)
    }
    let zhiNanZhenDiv = document.getElementById(mapSetInfo.divid)
    if (zhiNanZhenDiv != null && zhiNanZhenRenderer.domElement != null && zhiNanZhenDiv.contains(zhiNanZhenRenderer.domElement)) {
      zhiNanZhenDiv.removeChild(zhiNanZhenRenderer.domElement)
    }
    mapSetInfo.renderAdd = false
    mapSetInfo.zhiNanZhenAdd = false
    leadWayMsgBlock = null
    changeMapidBlock = null
    fristLocationBlock = null
  }

  map.forceContextLoss = function() {
    if (renderer != null) {
      renderer.forceContextLoss()
      renderer = null
    }
    if (zhiNanZhenRenderer != null) {
      zhiNanZhenRenderer.forceContextLoss()
      zhiNanZhenRenderer = null
    }
  }

  // 删除所有地图组件
  map.deleteAll = function() {
    mapSetInfo.render = false
    this.reset()
    layerDataManager.deleteAll()
    leadPointManager.deleteAll()
    simulationManager.deleteAll()
    leadGroup.deleteAll()
    textGroup.deleteAll()
    travlLine.deleteAll()
    worldManager.deleteAll()
    detailManager.deleteAll()

    if (controls != null) {
      controls.deleteAll()
    }

    if (textGroup.parent != null) {
      scene.remove(textGroup)
    }

    for (let i = 0; i < observers.length; i++) {
      let observer = observers[i]
      if (observer != null) {
        observer.disconnect()
        observer.takeRecords()
        observer = null
      }
    }
    startRendce = false
    let children = scene.children
    for (let i = 0; i < children.length; i++) {
      let c = children[i]
      scene.remove(c)
    }
    scene.dispose()
    delete localStorage['titleScale']
  }

  map.deleteMapGroup = function() {
    leadPointManager.hiddenLeadCube(true)
    pointManager.clearPointsInfo({
      all: ''
    })
    let mapOrderList = dataManager.getMapOrderList()
    for (let i = 0; i < mapOrderList.length; i++) {
      let order = mapOrderList[i]
      let mapid = order['mapid']
      this.deleteMap(mapid)
      deleteMapGroup(mapid)
    }
    if (mapSetInfo.useCommonGroup) {
      if (commonGroup.parent != null) {
        scene.remove(commonGroup)
      }
      commonGroup.deleteAll()
    }
    travlLine.deleteAll()
    leadPointManager.deleteAll()
    leadGroup.deleteAll()
    textGroup.deleteAll()
    dataManager.deleteAll()
  }

  // 指定id删除地图
  map.deleteMap = function(mapId) {
    if (typeof (groupInfo[mapId]) == 'undefined' || groupInfo[mapId] == null) {
      return
    }
    let mapScene = returnMapgroup(mapId)
    if (mapScene.parent != null) {
      mapScene.parent.remove(mapScene)
    }
  }

  /* 根据地图id和divid显示地图 */
  map.loadMap = function(mapId) {
    if (mapSetInfo.renderAdd == false) {
      this.addRenderByID(selectDivId)
    }
    let group = returnMapgroup(mapId)
    if (group == null) {
      return false
    }
    setNowMapId(mapId) // 记录当前的地图的id
    group.position.y = 0
    scene.add(group)
    if (commonGroup.parent != null) {
      commonGroup.parent.remove(commonGroup)
    }
    scene.add(commonGroup)
    resetGroupPosition({})

    let lv = controls.getCameraZoomLevel()
    let judgeLV = mapSetInfo.perspectiveZoomLV
    textGroup.loadMap({
      mapid: mapId,
      lv: lv,
      judgeLV: judgeLV
    })
    pointManager.updatePointMesh({
      mapid: mapId
    })

    userManager.setUserPhoneStyle({
      default: true,
      mapid: mapId
    })

    detailManager.showMap(mapId)
    mapRotateOrZoom()
    if (analysis.jsonKeyIsExist(leadCubeInfo.groupInfo, ['findInfo']) == false) {
      return
    }
    let leadRes = leadCubeInfo.groupInfo['findInfo']
    if (leadRes.result) {
      leadGroup.updateRender(leadRes)
    }
    return true
  }

  /* ============================================================================*/

  /* ====================================== 地图事件 ======================================*/
  function showDis(scaleWidth, scale) {
    let radius = controls.target.distanceTo(camera.position)
    let fov = camera.fov * Math.PI / 180.0
    let s1 = windowHeight / windowWidth
    let sin = 1 / Math.sin(fov / 2.0)
    let number = Math.abs(s1 * s1 * (sin * sin - 1) - 1)
    let width = Math.sqrt(radius * radius / (number)) * 2 / scale * scaleWidth / windowWidth
    width = parseFloat(width.toFixed(1))
    return width
  }

  // 当前地图比例尺
  function mapScaleShow() {
    let scaleRule = document.getElementById('scaleRule')
    if (scaleRule == null || scaleBlock == null || typeof (scaleBlock) == 'undefined') {
      return
    }
    let mapid = returnNowMapId()
    if (mapid == null || typeof (mapid) == 'undefined') {
      scaleBlock({
        result: false
      })
    }
    let scale = dataManager.getScaleInfo(mapid)
    let scaleWidth = scaleRule.offsetWidth
    let dis = showDis(scaleWidth, scale)
    scaleBlock({
      result: true,
      msg: dis + '米'
    })
  }

  function getEXGroup() {
    if (mapSetInfo.useCommonGroup) {
      return commonGroup
    } else {
      return returnNowMapGroup()
    }
  }

  function lazyLoadTitleGroup(mapid, lookAtZone) {
    if (mapSetInfo.lazyLoad == false) {
      return
    }
    for (const key in lookAtZone) {
      let zone = {}
      zone[key] = ''
      let loadRoomRes = dataManager.lazyLoadMap(mapid, zone, [{
        type: 'room',
        createdName: 'createRoomTitle'
      }])
      if (loadRoomRes.result == false) {
        continue
      }
      textGroup.lazyLoadMapTitle({
        room: loadRoomRes.room,
        mapid: mapid,
        lookAtZone: zone,
        layerHeight: returnFloorHeight(mapid),
        mode: mapSetInfo.cameraMode
      }, (res) => {
        dataManager.updateLazyLoadMap(res)
      }, () => {
        updateRoomText()
      })
    }
  }

  function lazyLoadGroup(mapid, lookAtZone, g) {
    if (mapSetInfo.lazyLoad == false) {
      return
    }
    for (const key in lookAtZone) {
      let zone = {}
      zone[key] = ''
      let loadRoomRes = dataManager.lazyLoadMap(mapid, zone, [{
        type: 'room',
        createdName: 'createRoom'
      }, {
        type: 'wall',
        createdName: 'createWall'
      }])
      if (loadRoomRes.result == false) {
        continue
      }
      g.updateObjectByPromise({
        mapid: mapid,
        lookAtZone: zone,
        layerHeight: returnFloorHeight(mapid),
        wall: loadRoomRes.wall,
        room: loadRoomRes.room,
        polygonOffsetFactor: mapSetInfo.polygonOffsetFactor,
        polygonOffsetUnits: mapSetInfo.polygonOffsetUnits
      }, (res) => {
        dataManager.updateLazyLoadMap(res)
        detailManager.updateDetailMapGroup(key, mapid)
      })
    }
  }

  // 地图动画贴图
  function hiddenOrShowExtraGroup() {
    let mapid = returnNowMapId()
    let lv = controls.getCameraZoomLevel()
    let judgeLV = mapSetInfo.perspectiveZoomLV
    let config = {
      lv: lv,
      judgeLV: judgeLV,
      lookAtZone: {}
    }
    let defaultInfoRes = dataManager.getDefaultLazyZone(mapid)
    if (defaultInfoRes.result) {
      config['lookAtZone'] = defaultInfoRes.defaultInfo
    }

    let group = getEXGroup()
    if (group == null) {
      showTextLayer(config)
      return
    }
    let count = group.extraGroupIsExist()
    // if (count == 0) {
    //   // 没有贴图
    //   showTextLayer(config)
    //   return
    // }
    let g = returnNowMapGroup()
    if (g == null) {
      return
    }

    let groupInfoRes = g.getDefaultLazyZone(mapid)
    if (groupInfoRes.result) {
      let defaultInfo = groupInfoRes.defaultInfo
      let zeroId = returnFloorAreaId(mapid)
      if (analysis.jsonKeyIsExist(defaultInfo, ['zero-1'])) {
        defaultInfo[zeroId] = ''
      }
      config['lookAtZone'] = defaultInfo
    }

    let fence = dataManager.getMapFence(mapid)
    let lookAtZone = {}
    let lookAtExtra = {}

    let forceHidden = false
    let otherLookAtZone = {}
    if (userManager.isLeadWayExist()) {
      // forceHidden = true;
      let zoneIdInfo = {}
      if (analysis.jsonKeyIsExist(leadCubeInfo, ['zoneIdInfo'])) {
        zoneIdInfo = leadCubeInfo['zoneIdInfo']
      }
      for (const key in zoneIdInfo) {
        otherLookAtZone[key] = zoneIdInfo
      }
    }
    let forceShow = false
    if (analysis.jsonKeyIsExist(mapSetInfo, ['hiddenFloor'])) {
      forceHidden = mapSetInfo['hiddenFloor']
    }

    let lockZone = false
    if (analysis.jsonKeyIsExist(mapSetInfo, ['lockZone'])) {
      lookAtExtra = {}
      lookAtZone = mapSetInfo['lockZone']
      lv = judgeLV - 1
      lockZone = true
    } else {
      lookAtZone = g.getLookAtZone({
        target: controls.target,
        camera: camera,
        fence: fence
      })
      lookAtExtra = group.getLookAtExtra({
        target: controls.target,
        camera: camera,
        fence: fence,
        lv: lv
      })
    }

    let res = group.extraGroupHiddenOrShow({
      lockZone: lockZone,
      target: controls.target,
      camera: camera,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      floorLock: mapSetInfo.floorLock,
      forceHidden: forceHidden,
      forceShow: forceShow,
      lookAtZone: lookAtZone,
      lookAtExtra: lookAtExtra,
      otherLookAtZone: otherLookAtZone,
      lv: lv,
      judgeLV: judgeLV
    })
    g.showOrHiddenZone(res['showZoneKey'])

    css2D.updateCss2DPosition({
      isAllVisible: analysis.jsonKeyIsExist(res, ['isAllVisible']) ? res.isAllVisible : false,
      lookAtZone: lookAtZone,
      lookAtExtra: lookAtExtra,
      lv: lv,
      judgeLV: judgeLV
    })
    if (lookAtZoneBlock != null) {
      lookAtZoneBlock(dataManager.returnLookAtZoneInfo(lookAtZone), mapid, lookAtZone)
    }
    if (Object.keys(lookAtZone).length > 0) {
      config['lookAtZone'] = lookAtZone
    }

    showTextLayer(config)

  }

  function showTextLayer(config) {
    let mapid = returnNowMapId()
    if (mapSetInfo.lazyLoad) {
      let g = returnNowMapGroup()
      lazyLoadGroup(mapid, config['lookAtZone'], g)
      lazyLoadTitleGroup(mapid, config['lookAtZone'])
    }
    let dis = showDis(windowWidth, 1) * returnCameraScale() * 2.5
    let setting = {
      isPhone: false,
      camera: camera,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      zoomNum: controls.getZoomScaleNumber(),
      dis: dis, // 整个屏幕投影的世界距离
      lv: config.lv,
      judgeLV: config.judgeLV,
      mapid: mapid,
      lookAtZone: config['lookAtZone'],
      lazyLoad: mapSetInfo.lazyLoad
    }
    textGroup.textRotate(setting)
  }

  // 地图文字标注
  function updateRoomText() {
    if (detailManager.returnRender() && mapSetInfo.isRenderWhenShowDetail == false) {
      return
    }
    simulationZoom()
    userZoom()
    hiddenOrShowExtraGroup({})
    updatePointZoom()
    textGroup.roomTextAnimation()
  }

  // 指南针旋转
  function rotateZhiNanZhen() {
    if (zhinanzhen == null) {
      return
    }
    let mapid = returnNowMapId()
    let north = dataManager.getMapNorth(mapid)
    zhinanzhen.rotation.z = -controls.getRotateXZ() + Math.PI - north * Math.PI / 180
  }

  /* ================================= *** =================================*/

  let skip = 1

  /* ============================================================================*/
  function updateSpotLight(lightConfig) {
    function createSpotLight(config) {
      let spotLight = new THREE.SpotLight(config.color, config.intensity)
      let position = config.position
      spotLight.position.set(position.x, position.y, position.z)
      spotLight.shadow.mapSize.width = 4096
      spotLight.shadow.mapSize.height = 4096
      let showShadow = config.showShadow

      let target = config.target

      spotLight.target.position.x = target.x
      spotLight.target.position.y = target.y
      spotLight.target.position.z = target.z

      if (showShadow) {
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        spotLight.shadow.camera.far = 65535
        spotLight.shadow.camera.near = 1
        spotLight.shadow.radius = 1
        spotLight.castShadow = true
        spotLight.shadow.camera.fov = 80
      } else {
        spotLight.castShadow = false
        renderer.shadowMap.enabled = false
      }

      return spotLight
    }

    let lightList = []
    for (let i = 0; i < scene.children.length; i++) {
      let c = scene.children[i]
      if (c.type != 'SpotLight' && c.type != 'Object3D') {
        continue
      }
      lightList.push(c)

    }

    for (let i = 0; i < lightList.length; i++) {
      let light = lightList[i]
      if (light.parent == null) {
        continue
      }
      light.parent.remove(light)
    }
    lightList = []
    let leftUp = lightConfig.leftUp
    let rightDown = lightConfig.rightDown
    let intensity = 0.3

    let wScale = 0.03
    let hScale = 0.03
    let width = (rightDown.x - leftUp.x) * wScale
    let height = (rightDown.z - leftUp.z) * hScale
    let color = 0xffffff

    if (analysis.jsonKeyIsExist(lightConfig, ['color'])) {
      color = lightConfig['color']
    }

    if (analysis.jsonKeyIsExist(lightConfig, ['intensity'])) {
      intensity = lightConfig['intensity']
    }

    let y = 500

    let centerX = (rightDown.x + leftUp.x) / 2.0
    let centerZ = (rightDown.z + leftUp.z) / 2.0

    let target = { x: centerX, y: 0, z: centerZ }

    let showShadow = false
    if (analysis.jsonKeyIsExist(lightConfig, ['showShadow'])) {
      showShadow = lightConfig['showShadow']
    }

    if (showShadow) {
      let light = createSpotLight({
        color: color,
        position: { x: centerX + 100, y: y, z: centerZ + 100 },
        target: target,
        intensity: intensity,
        showShadow: showShadow
      })

      scene.add(light)
      scene.add(light.target)

    } else {
      let pList = [{ x: rightDown.x + width, y: y, z: rightDown.z + height },
        { x: leftUp.x - width, y: y, z: leftUp.z - height },
        { x: rightDown.x + width, y: y, z: leftUp.z - height },
        { x: leftUp.x - width, y: y, z: rightDown.z + height }]
      for (let i = 0; i < pList.length; i++) {
        let p = pList[i]
        let light = createSpotLight({
          color: color,
          position: p,
          target: target,
          intensity: intensity - i * 0.05,
          showShadow: showShadow
        })
        scene.add(light)
        scene.add(light.target)
      }
    }
  }

  // 地图的初始化
  map.init = function() {

    if (renderer == null) {
      renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        logarithmicDepthBuffer: false
      })
    }

    if (zhiNanZhenRenderer == null) {
      zhiNanZhenRenderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      })
    }

    TWEEN.update = (time) => {
      let _tweens = TWEEN.getAll()
      if (_tweens.length === 0) return false
      let i = 0,
        numTweens = _tweens.length
      time = time !== undefined ? time : Date.now()
      while (i < numTweens) {
        let t = _tweens[i]
        if (typeof t == 'undefined') {
          i++
          continue
        }
        if (_tweens[i].update(time)) {
          i++
        } else {
          _tweens.splice(i, 1)
          numTweens--
        }
      }
      return true
    }

    mapSetInfo.render = true
    mapSetInfo.isIe = analysis.IEVersion()
    if (navigator.platform.indexOf('Win') != -1 || navigator.platform.indexOf('Mac') != -1) {
      mapSetInfo['phone'] = false
    } else {
      mapSetInfo['phone'] = true
    }
    if (mapSetInfo.cameraMode == 1) {
      let count = 200
      camera = new THREE.OrthographicCamera(-count * (windowWidth / windowHeight), count * (windowWidth /
        windowHeight), count, -count, -65535, 65535)
      camera.zoom = 1
      camera['aspectCount'] = count
    } else {
      // camera = new THREE.PerspectiveCamera(mapSetInfo.fov, windowWidth / windowHeight, 0.0001, 1000000)
      worldManager.analaysisZoomInfo({
        scale: 1,
        fov: mapSetInfo.fov
      })
      camera = new THREE.PerspectiveCamera(mapSetInfo.fov, windowWidth / windowHeight, mapSetInfo.maxZoom, worldManager.returnZoomInfo(6))
    }
    camera.position.x = 0
    camera.position.y = 50
    camera.position.z = 0

    //渲染视图视角
    let zeroPoint = new THREE.Vector3(0, 0, 0)
    camera.lookAt(zeroPoint)

    //渲染器构建
    if (mapSetInfo.isRenderBg == true) {
      renderer.setClearColor(mapSetInfo.renderBg)
    } else {
      renderer.setClearColor(0xffffff, 0)
    }

    renderer.setPixelRatio(window.devicePixelRatio)

    renderer.setSize(windowWidth, windowHeight)

    renderer.sortObjects = false

    leadGroupUpdate()

    // 控制相机
    controls = new createNewOrbitControls(camera, renderer.domElement)
    controls.target = zeroPoint
    controls.minDistance = 180
    controls.maxDistance = 1000
    mapSetInfo['distance'] = 50
    controls.minPolarAngle = minPolarAngle
    controls.maxPolarAngle = maxPolarAngle
    controls.maxPolar = maxPolarAngle
    // 右键拖拽
    controls.enablePan = true
    controls.panBlockAction(function() {
      mapRotateOrZoom()
    })

    let ambientLight = new THREE.AmbientLight(0xffffff, 0.5) //环境光
    scene.add(ambientLight)

    createNorth()
    if (startRendce == false) {
      renderScene()
      startRendce = true
    }
    mapSetInfo['minAngle'] = minPolarAngle
    controls.minPolarAngle = minPolarAngle

    if (mapSetInfo.openStats) {
      stats.setMode(0)
      stats.domElement.style.position = 'absolute'
      stats.domElement.left = '0px'
      stats.domElement.top = '200px'
      document.body.appendChild(stats.domElement)
    }

    window.onresize = function() {
      resize()
    }

    css2D.init({
      width: windowWidth,
      height: windowHeight,
      divId: selectDivId
    })

    function renderScene() {
      if (mapSetInfo.render == false) {
        return
      }
      pointManager.getGroup().animationStart()
      requestAnimationFrame(renderScene)
      if (skip == 1) {
        skip = 0
      } else {
        skip = 1
        return
      }

      let clock = new THREE.Clock()
      let delta = clock.getDelta()
      controls.update(delta)
      if (detailManager.returnRender()) {
        if (mapSetInfo.isRenderWhenShowDetail) {
          renderer.render(scene, camera)
          leadGroup.animation()
          css2D.updateRender(camera, {})
        }
      } else {
        renderer.render(scene, camera)
        leadGroup.animation()
        css2D.updateRender(camera, {})
      }
      detailManager.randerScene()
      if (zhiNanZhenCamera != null) {
        zhiNanZhenRenderer.render(zhiNanZhenScene, zhiNanZhenCamera)
      }
      if (mapSetInfo.openStats) {
        stats.update()
      }
      TWEEN.update()
    }
  }

  function mapRotate() {
    sendChange2D_3Dmsg(controls.return3Dor2DMsg())
    // 指南针旋转
    rotateZhiNanZhen()
    // 地图文字缩放旋转
    updateRoomText()

    if (mapSetInfo.openTheWorld == false) {
      return
    }
    worldManager.lookingWorld({
      camera: camera.position,
      target: controls.target,
      scale: 1,
      fov: mapSetInfo.fov
    })
  }

  function mapRotateOrZoom() {
    mapRotate()
    mapScaleShow()
  }

  map.outPutMsgByName = function(config) {
    let mapOrderList = dataManager.getMapOrderList()
    let mapLeadInfo = {}
    for (let i = 0; i < mapOrderList.length; i++) {
      let mapid = mapOrderList[i]['mapid']
      let leadInfo = copyJson(returnMapLeadInfo(mapid))
      if (analysis.jsonKeyIsExist(leadInfo, ['startP'])) {
        deleteInfoByKey(leadInfo, 'startP')
      }
      if (analysis.jsonKeyIsExist(leadInfo, ['endP'])) {
        deleteInfoByKey(leadInfo, 'endP')
      }
      mapLeadInfo[mapid] = leadInfo
    }
    let setting = {
      mapLeadInfo: mapLeadInfo
    }
    if (typeof config != 'undefined' && config != null) {
      for (const key in config) {
        setting[key] = config[key]
      }
    }
    return dataManager.outPutMsgByName(setting)
  }

  let logGroup = null

  map.logShow = function(info, info_two) {
    if (logGroup != null) {
      scene.remove(logGroup)
      logGroup = null
    }
    logGroup = new THREE.Group()
    scene.add(logGroup)

    let fences = info.fence.fence
    let points = info.fence.point
    for (let i = 0; i < fences.length; i++) {
      let fence = fences[i]
      let arc = create.createShapeArc(fence.x, fence.y, parseFloat(fence.distance) * dataManager.getScaleInfo(
        returnNowMapId()), 0xff0000, returnFloorHeight(returnNowMapId()))
      logGroup.add(arc)
    }
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      create.createCube(p.x, returnFloorHeight(returnNowMapId()), p.y, 0x00ff00, logGroup, 2)
    }
    let result = info.result
    if (analysis.jsonKeyIsExist(result, ['result']) && result.result == true) {
      create.createCube(result.x, returnFloorHeight(returnNowMapId()), result.y, 0xff0000, logGroup, 2)
    }

    if (analysis.jsonKeyIsExist(info, ['finailPoint'])) {
      let finailPoint = info['finailPoint']
      create.createCube(finailPoint.x, returnFloorHeight(returnNowMapId()), finailPoint.y, 0xff0000, logGroup, 2)
    }

    if (analysis.jsonKeyIsExist(info_two, ['finailPoint'])) {
      let finailPoint = info_two['finailPoint']
      create.createCube(finailPoint.x, returnFloorHeight(returnNowMapId()), finailPoint.y, 0x481380, logGroup, 2)
    }
  }

  map.setLinkStyle = function(result) {
    mapSetInfo.isShowLinkText = result
  }

  map.getNowCameraMode = function() {
    return mapSetInfo.cameraMode
  }

  map.setCreateCamertaMode = function(mode) {
    mapSetInfo.cameraMode = mode
  }

  /* --------------------------- 地图打点 ---------------------------*/

  map.updateCss2DList = function(config) {
    let group = getEXGroup()
    if (group != null) {
      let judgeHeight = true
      if (analysis.jsonKeyIsExist(config, ['judgeHeight'])) {
        judgeHeight = config['judgeHeight']
      }
      if (judgeHeight) {
        group.getFloorHeightByPoint(config)
      }
    }
    css2D.updateCSS2DList(config)
    if (css2D.parent == null) {
      scene.add(css2D)
    }
  }

  map.selectCss2D = function(config) {
    css2D.selectCss2D(config)
  }

  map.cancelSelectCss2D = function() {
    css2D.cancelSelectCss2D()
  }

  map.downFrontEXImage = function(config) {
    let group = returnNowMapGroup()
    if (group == null || config.id == '') {
      return
    }
    group.downFrontEXImage(config)
    if (mapSetInfo.useCommonGroup) {
      commonGroup.downFrontEXImage(config)
    }
  }

  map.changeChartColor = function(config) {
    let group = returnNowMapGroup()
    if (group == null || config.id == '') {
      return
    }
    group.changeChartColor(config)
    if (mapSetInfo.useCommonGroup) {
      commonGroup.changeChartColor(config)
    }
  }

  map.getFaceInfo = function(config) {
    let group = getEXGroup()
    if (group == null) {
      return []
    }
    return group.getFaceInfo(config)
  }

  map.showFrontEXImage = function(config, callBack) {
    let group = returnNowMapGroup()
    if (group == null || config.id == '') {
      return
    }
    group.showFrontEXImage(config, callBack)
    if (mapSetInfo.useCommonGroup) {
      commonGroup.showFrontEXImage(config, callBack)
    }
  }

  map.clickChartLet = function(event) {
    let group = returnNowMapGroup()
    let clickExtra = {
      result: true,
      dataInfo: null
    }

    let res1 = commonGroup.clickChartLet({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      camera: camera,
      divId: selectDivId
    }, event)
    let res2 = group.clickChartLet({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      camera: camera,
      divId: selectDivId
    }, event)
    if (res1.dataInfo != null) {
      clickExtra.dataInfo = res1.dataInfo
    }
    if (res2.dataInfo != null) {
      clickExtra.dataInfo = res2.dataInfo
    }
    return clickExtra
  }

  map.changeRoomColorByIncubator = function(config) {
    let info = pointManager.returnDataList('together')
    for (let mapid in info) {
      let list = info[mapid]
      let group = returnMapgroup(mapid)
      if (group == null) {
        continue
      }
      group.changeRoomColorByIncubator({ dataList: list, color: config.color })
    }
    if (Object.keys(info).length == 0) {
      let group = returnNowMapGroup()
      if (group == null) {
        return
      }
      group.changeRoomColorByIncubator({ dataList: [], color: config.color })
    }
  }


  map.changeRoomColor = function(config) {
    let group = returnNowMapGroup();
    group.changeRoomColor(config)
  }

  // 地点地图房间
  map.clickMapRoomEvent = function(event, room, config) {
    let group = returnNowMapGroup()
    if (group == null) {
      return {
        result: false
      }
    }
    let mapid = returnNowMapId()
    let clickExtra = {
      result: false
    }

    let clickZoneInfo = true
    if (analysis.jsonKeyIsExist(config, ['zoneInfo'])) {
      let zoneInfo = dataManager.getZoneInfo(mapid)
      if (Object.keys(zoneInfo).length > 0) {
        clickZoneInfo = config['zoneInfo']
      }
    }

    if (mapSetInfo.useCommonGroup) {
      // 使用共同层
      clickExtra = commonGroup.clickExtraEvent({
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        camera: camera,
        divId: selectDivId
      }, event)
    } else {
      clickExtra = group.clickExtraEvent({
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        camera: camera,
        divId: selectDivId
      }, event)
    }
    let moveCamera = true
    if (analysis.jsonKeyIsExist(config, ['moveCamera'])) {
      moveCamera = config['moveCamera']
    }
    if (clickExtra.result) {
      if (moveCamera) {
        let hiddenExtra = true
        if (analysis.jsonKeyIsExist(config, ['hiddenExtra'])) {
          hiddenExtra = config['hiddenExtra']
        }

        let judgeInfo = controls.judgeFence({
          leftUp: clickExtra['fence']['leftUp'],
          rightDown: clickExtra['fence']['rightDown'],
          camera: camera,
          judgeLV: mapSetInfo.perspectiveZoomLV,
          hiddenExtra: hiddenExtra
        })
        let moveConfig = {
          target: { target: clickExtra.center },
          scale: {
            leftUp: hiddenExtra ? judgeInfo['leftUp'] : clickExtra.fence.leftUp,
            rightDown: hiddenExtra ? judgeInfo['rightDown'] : clickExtra.fence.rightDown,
            w: hiddenExtra ? 0 : clickExtra.fence.w,
            h: hiddenExtra ? 0 : clickExtra.fence.h
          },
          rotate: {},
          time: 300
        }
        cameraMoveAnimation(moveConfig)
      }
      return {
        dataInfo: clickExtra['dataInfo'],
        result: false
      }
    }

    let titleLimit = true;
    if (analysis.jsonKeyIsExist(config, ['titleLimit'])) {
      titleLimit = config['titleLimit']
    }

    let res = group.clickMapRoomEvent({
      windowHeight: windowHeight,
      windowWidth: windowWidth,
      camera: camera,
      divId: selectDivId,
      room: room,
      mapid: mapid,
      zoneInfo: clickZoneInfo,
      titleLimit:titleLimit,
    }, event)
    if (res.result == false) {
      let clickText = false
      if (analysis.jsonKeyIsExist(config, ['clickText'])) {
        clickText = config['clickText']
      }
      if (clickText == false) {
        return res
      }
      return textGroup.clickText({
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        camera: camera,
        divId: selectDivId,
        clickText: clickText,
        mapid: mapid,
        titleLV: 1
      }, event)
    }
    let data = res.data
    data['organizationName'] = dataManager.getOrgName(mapid)
    data['mapName'] = dataManager.getMapLocationName({
      mapid: mapid,
      x: data['x'],
      z: data['z']
    })
    if (moveCamera) {
      let moveConfig = {
        target: {
          target: {
            x: data['x'],
            z: data['z'],
            y:3,
          }
        },
        scale: {},
        rotate: {},
        time: 300
      }
      cameraMoveAnimation(moveConfig)
    }
    return res
  }

  // 点击打点
  map.addPoistion = function(event, positionid) {
    let intersects = getIntersects(event)
    let newClick = new THREE.Vector3()
    let mapid = returnNowMapId()
    newClick.set(intersects.x, intersects.y, intersects.z)
    let data = {
      mapid: mapid,
      x: newClick.x,
      y: newClick.y,
      z: newClick.z,
      type: 'clickPoint',
      centerY: 0.1414

    }
    newClick['mapid'] = mapid
    let roomInfo = returnRoomInfoByPoint(mapid, data)
    let zeroInfo = dataManager.getZeroInfo(mapid)
    if (roomInfo['areaid'] == zeroInfo['areaid']) {
      let zoneInfo = returnZoneInfoByPoint(mapid, data)
      if (zoneInfo.areaid != '') {
        roomInfo['inZone'] = zoneInfo['areaid']
        roomInfo['areas'] = zoneInfo['areas']
        roomInfo['title'] = ''
      }
    }

    newClick['roomId'] = mapid + roomInfo['areaid']
    newClick['areaid'] = roomInfo['areaid']
    let title = ''
    if (analysis.jsonKeyIsExist(roomInfo, ['title'])) {
      title = roomInfo['title']
    }
    let zoneId = ''
    if (analysis.jsonKeyIsExist(roomInfo, ['inZone'])) {
      zoneId = roomInfo['inZone']
    }
    newClick['mapName'] = dataManager.getMapName(mapid)
    newClick['roomName'] = title
    newClick['zoneId'] = zoneId
    newClick['zoneName'] = dataManager.getZoneName(zoneId)
    let zoneInfoId = ''
    if (analysis.jsonKeyIsExist(roomInfo, ['zoneInfoId'])) {
      zoneInfoId = roomInfo['zoneInfoId']
    }

    if (zoneInfoId == '') {
      let zoneInfo = returnZoneInfoByPoint(mapid, newClick)
      zoneInfoId = zoneInfo.zoneInfoId
    }

    newClick['zoneInfoId'] = zoneInfoId

    pointManager.updatePointsInfo({
      clickPoint: [data],
      clickRoom: []
    }, {})
    return {
      result: true,
      data: newClick
    }
  }
  /* --------------------------- ****** ---------------------------*/
  map.renderAddNewEvent = function(events) {
    for (let i = 0; i < events.length; i++) {
      let event = events[i]
      renderer.domElement.addEventListener(event.key, event.func, false)
    }
  }

  map.renderRemoveEvent = function(events) {
    for (let i = 0; i < events.length; i++) {
      let event = events[i]
      renderer.domElement.removeEventListener(event.key, event.func, false)
    }
  }

  map.shotimage = function(callBlock) {
    let mapid = returnNowMapId()
    if (mapid == '') {
      callBlock({
        result: false
      })
    }
    let fence = {
      leftUp: {
        x: null,
        y: null
      },
      rightDown: {
        x: null,
        y: null
      }
    }
    let floors = returnFloorArea(mapid)
    for (let i = 0; i < floors.length; i++) {
      let p = floors[i]
      let x = p[0]
      let z = p[2]
      fence.leftUp.x = (fence.leftUp.x == null ? x : (fence.leftUp.x > x ? x : fence.leftUp.x))
      fence.leftUp.y = (fence.leftUp.y == null ? z : (fence.leftUp.y > z ? z : fence.leftUp.y))

      fence.rightDown.x = (fence.rightDown.x == null ? x : (fence.rightDown.x < x ? x : fence.rightDown.x))
      fence.rightDown.y = (fence.rightDown.y == null ? z : (fence.rightDown.y < z ? z : fence.rightDown.y))

    }
    controls.zoomMapToFixSize(mapid, windowWidth, windowHeight, 1.6, fence, () => {
      leadPointManager.setLeadPointVisible(false)
      renderer.render(scene, camera)
      shot(renderer.domElement, {
        scale: 16 / 9
      }, (image) => {
        callBlock({
          result: true,
          image: image
        })
        leadPointManager.setLeadPointVisible(true)
      })
    })
  }

  let observers = []

  map.resizeAction = function(selector) {
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      return
    }
    let version = analysis.IEVersion()
    if (version != -1) {
      return
    }
    let MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
    let element = document.querySelector(selector)
    let observer = new MutationObserver((objList) => {
      setTimeout(() => {
        resize()
      }, 300)
    })
    observer.observe(element, {
      childList: true,
      attributeFilter: ['class', 'style']
    })
    observers.push(observer)
  }

  map.forceToResize = function() {
    resize()
  }

  let delay = null

  function resize() {
    if (delay != null) {
      window.clearTimeout(delay)
      delay = null
    }
    delay = window.setTimeout(() => {
      detailManager.resize({})
      let superdiv = document.getElementById(selectDivId)
      if (superdiv == null) {
        return
      }
      if (superdiv.offsetHeight == windowHeight && superdiv.offsetWidth == windowWidth) {
        return
      }
      windowHeight = superdiv.offsetHeight
      windowWidth = superdiv.offsetWidth
      resizeRender(windowWidth, windowHeight)
      css2D.reSize({
        width: windowWidth,
        height: windowHeight
      })
    }, 200)
  }

  function resizeRender(width, height) {
    renderer.setSize(width, height)
    let k = width / height //窗口宽高比
    if (k <= 0) {
      k = 0.001
    }
    camera.aspect = k
    camera.updateProjectionMatrix()
  }

  map.setMapConfig = function(configList) {
    for (let i = 0; i < configList.length; i++) {
      let info = configList[i]
      if (analysis.jsonKeyIsExist(info, ['type']) == false) {
        continue
      }
      if (info.type == 'map') {
        for (let key in info.config) {
          if (key == 'deleteLockZone') {
            delete mapSetInfo['lockZone']
            continue
          }
          mapSetInfo[key] = info.config[key]
          if (key == 'north') {
            dataManager.updateNorth(info.config[key])
          }
        }
      } else if (info.type == 'controller' && controls != null) {
        for (let key in info.config) {
          controls.setConfig(key, info.config[key])
        }
      }
    }
  }

  map.mouseMoveOnMap = function(event, config) {
    if (typeof camera == 'undefined' || camera == null) {
      return {
        result: false
      }
    }

    let setting = {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      camera: camera,
      divId: selectDivId
    }

    let meshArr = []
    if (config.type == 'extra') {
      let g = getEXGroup()
      if (g == null) {
        return {
          result: false
        }
      }
      meshArr = g.getExtraList()
    }
    setting['meshs'] = meshArr
    let finder = intersect.getIntersects(event, setting)
    if (finder.result == false) {
      if (config.type == 'extra') {
        let g = getEXGroup()
        if (g == null) {
          return {
            result: false
          }
        }
        g.setExtraMeshOpacity(null, {})
      }
      return {
        result: false
      }
    }

    let object = finder.object//楼层中的元素
    if (config.type == 'extra') {
      let g = getEXGroup()
      if (g == null) {
        return {
          result: false
        }
      }
      g.setExtraMeshOpacity(object, {})
    }
    return {
      result: false
    }
  }

  map.updateFaceImage = function(config) {
    let group = returnNowMapGroup()
    if (group == null) {
      return
    }
    group.updateFaceImage(config)
    if (mapSetInfo.useCommonGroup) {
      commonGroup.updateFaceImage(config)
    }
  }

  map.returnScene = function() {
    return scene
  }

  map.hiddenZero = function() {
    let zeroId = returnFloorAreaId(returnNowMapId())
    for (let i = 0; i < commonGroup.children.length; i++) {
      let g = commonGroup.children[i]
      for (let j = 0; j < g.children.length; j++) {
        let subG = g.children[j]
        if ((subG.uuid.indexOf(zeroId) == 0 || subG.name == zeroId || subG.name == '') && subG.parent != null) {
          g.remove(subG)
        }
      }
    }
  }

  map.getTravl = function() {
    return travlLine
  }

  map.getGrid = function() {
    return gridManager
  }

  map.getHeat = function() {
    return heatMapManager
  }

  map.getPoint = function() {
    return pointManager
  }

  map.getText = function() {
    return textGroup;
  }

  map.getLeadPoint = function() {
    return leadPointManager
  }

  map.getSimulation = function() {
    return simulationManager
  }

  map.getUser = function() {
    return userManager
  }

  map.getWorld = function() {
    return worldManager
  }

  map.getDetaiMap = function() {
    return detailManager
  }

  function leadGroupUpdate() {
    leadGroup.findPointNameAction((config) => {
      return dataManager.getMapLocationName(config)
    })
  }

  function setUpLeadPointManager() {
    leadPointManager.clearPathAction((clear) => {
      if (clear == false) {
        return
      }
      clearPath()
    })

    leadPointManager.updateLeadInfoAction(() => {
      userManager.updateAStartLeadInfo()
      userManager.updateLeadInfo()
    })

    leadPointManager.cameraAction(() => {
      return camera
    })

    leadPointManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHeight: returnFloorHeight(mapid),
        scaleInfo: 10,
        leadInfo: leadCubeInfo.groupInfo.info,
        mapJson: returnMapJson(mapid),
        mapOrderList: dataManager.getMapOrderList()
      }
    })

    leadPointManager.textAction((dataInfo) => {
      pointManager.updatePointsInfo(dataInfo, {})
    })

    leadPointManager.mapJsonAction((mapid) => {
      return returnMapJson(mapid)
    })

    leadPointManager.zeroAction((mapid) => {
      return dataManager.getZeroInfo(mapid)
    })

    leadPointManager.leadInfoAction((mapid, leadInfo) => {
      setMapLeadInfo(mapid, leadInfo)
    })

  }

  function setUpGridManager() {
    gridManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHieght: 3,
        scaleInfo: 10,
        fenceInfo: dataManager.getMapFence(mapid),
        zeroInfo: dataManager.getZeroInfo(mapid),
        mapJson: returnMapJson(mapid)
      }
    })

    gridManager.cameraAction(() => {
      return camera
    })

    gridManager.mapOrderListAction(() => {
      return dataManager.getMapOrderList()
    })

    gridManager.zeroAction(() => {
      let group = returnNowMapGroup()
      let config = {
        zoneInfo: true
      }
      let meshs = commonGroup.returnGroupMesh(config).concat(group.returnGroupMesh(0))
      return meshs
    })

  }

  function setUpHeatmapManager() {
    heatMapManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHieght: 3,
        divId: selectDivId,
        borderInfo: dataManager.getMapBorder(mapid),
        zeroInfo: dataManager.getZeroInfo(mapid),
        commonGroup: commonGroup,
        nowGroup: returnNowMapGroup()
      }
    })

    heatMapManager.changeMapAction((mapid) => {
      changeMap(mapid)
    })

    heatMapManager.getLocationList((mapid) => {
      return dataManager.getMapLocationList(mapid)
    })
  }

  function updatePointZoom() {
    let list = pointManager.getGroup().returnZoomList()
    for (let i = 0; i < list.length; i++) {
      setSpriteScale(list[i])
    }
    pointManager.getGroup().togetherZoom({
      mapid: returnNowMapId(),
      camera: camera,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      scale: controls.getZoomScaleNumber(),
      mode: mapSetInfo.cameraMode,
      dis: showDis(windowWidth, 1) * returnCameraScale() * 2.5
    })
  }

  function hideenExtra(config) {
    let mapid = returnNowMapId()
    let res = dataManager.getExtraInfo(mapid)
    if (res.list.length == 0) {
      return {}
    }
    let size = dataManager.getMapFence(mapid)
    let position = config['position']
    let zoomScale = 1
    let width = size.width * zoomScale
    let height = size.height * zoomScale
    let x = position.x
    let y = controls.target.y
    let z = position.z

    let dataInfo = {
      fence: {
        leftUp: {
          x: x - width / 2.0,
          y: y,
          z: z - height / 2.0
        },
        rightDown: {
          x: x + width / 2.0,
          y: y,
          z: z + height / 2.0
        },
        w: 0,
        h: 0
      },
      center: {
        x: x,
        y: y,
        z: z
      }
    }

    return controls.judgeFence({
      leftUp: dataInfo.fence.leftUp,
      rightDown: dataInfo.fence.rightDown,
      camera: camera,
      judgeLV: mapSetInfo.perspectiveZoomLV,
      hiddenExtra: true
    })
  }

  function setUpPointManager() {
    pointManager.getGroup().findRoomInfoAction((mapid, roomid) => {
      return dataManager.returnMap_RoomCenter(mapid, roomid, {})
    })

    pointManager.changeMapAction((mapid) => {
      if (typeof mapid == 'undefined' || mapid == null || mapid == returnNowMapId()) {
        return
      }
      changeMap(mapid)
    })

    pointManager.cameraAction(() => {
      return camera
    })

    pointManager.setZoomAction(() => {
      if (controls == null) {
        return
      }
      updatePointZoom()
    })

    pointManager.disAction(() => {
      return showDis(windowWidth, 1) * returnCameraScale() * 2.5
    })

    pointManager.getGroup().findRoomAction((config) => {
      return {}
    })

    pointManager.setMapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHieght: returnFloorHeight(mapid)
      }
    })

    pointManager.hiddenExtraAction((config) => {
      return hideenExtra(config)
    })

    pointManager.cameraAnimationAction((config) => {
      cameraMoveAnimation(config)
    })
  }

  function setUpSimulation() {
    simulationManager.realseSimulationAction((config) => {
      controls.stopAnimation()
      if (config.deleteGroupInfo) {
        delete leadCubeInfo.groupInfo['simulation_findInfo']
      }
    })

    simulationManager.startSimulationAction(() => {
      if (leadWayMsgBlock == null) {
        return
      }
      leadPointManager.clearLeadIcon()
      // 将镜头切换至模拟导航
      map.changeCameraMode(cameraMode.simulation)
      detailManager.cancelShowAllMap()
      let startData = {
        upOrDown: false,
        reality: false,
        url: null,
        timeMsg: '',
        start: '',
        end: '',
        direction: '开始模拟导航',
        status: 2,
        dis: '',
        isEnd: false,
        leadUrl: '',
        update: true
      }
      leadWayMsgBlock(startData)
    })

    simulationManager.pathInfoAction(() => {
      return {
        paths: leadCubeInfo.leadPath,
        leadOrder: leadCubeInfo.leadOrder,
        y: returnFloorHeight(returnNowMapId())
      }
    })

    simulationManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHieght: returnFloorHeight(mapid)
      }
    })

    simulationManager.cameraAnimationAction((config) => {
      cameraMoveAnimation(config)
    })

    simulationManager.azimuthalAngleAction(() => {
      return controls.getAzimuthalAngle()
    })

    simulationManager.changeMapAction((mapid) => {
      changeMap(mapid)
      showLeadWayExist()
    })

    simulationManager.animationEndAction(() => {
      dealWithLeadInfoDetalMsg({
        result: true,
        leadInfo: {
          isEnd: true
        }
      }, {
        isPlayMusic: false,
        update: true
      })
    })

    simulationManager.animationUpdateAction((point, lastPoint, dis) => {
      let mapid = returnNowMapId()
      let config = {
        useInertia: '1',
        useCorrect: false,
        finialDis: 0,
        useRoomDis: false,
        zeroId: returnFloorAreaId(mapid),
        shouldDirection: true,
        direction: 0,
        scale: dataManager.getScaleInfo(mapid),
        userLocatin: {
          x: lastPoint.x,
          y: 0,
          z: lastPoint.z,
          mapid: mapid
        },
        userNewLocation: {
          x: lastPoint.x,
          y: 0,
          z: lastPoint.z,
          mapid: mapid
        },
        distance: dis,
        mapid: mapid,
        leadInfo: {
          path: leadCubeInfo.leadPath,
          order: leadCubeInfo.leadOrder,
          orderList: dataManager.getMapOrderList()
        },
        leadDirectionType: mapSetInfo['leadDirectionType']
      }
      let promise = null
      if (analysis.jsonKeyIsExist(leadCubeInfo.groupInfo, ['simulation_findInfo'])) {
        config['findInfo'] = leadCubeInfo.groupInfo['simulation_findInfo']
        promise = userManager.leadWhenMove(config)
      } else {
        promise = userManager.startLead(config)
      }
      if (promise == null) {
        return
      }

      promise.then(res => {
        res.leadInfo.direction = '请按道路前方前行'
        leadCubeInfo.groupInfo['simulation_findInfo'] = res
        if (res.result == false) {
          return
        }
        dealWithLeadInfoDetalMsg(res, {
          isPlayMusic: false,
          update: true
        })
      })
    })
  }

  function simulationZoom() {
    let res = simulationManager.simulationZoom()
    if (res.result == false) {
      return
    }
    setSpriteScale(res.object)
  }

  // 显示最后的一段路线
  function showLastWay() {
    if (userManager.isLeadWayExist() == false) {
      clearPath()
      return
    }
    if (analysis.jsonKeyIsExist(leadCubeInfo.groupInfo, ['findInfo']) == false) {
      clearPath()
      return
    }
    let status = userManager.userStatus()
    if (status.user == false || status.isOutOfMap) {
      clearPath()
      return
    }
    if (analysis.jsonKeyIsExist(leadCubeInfo.leadPath, [status.mapid]) == false) {
      clearPath()
      return
    }
    let leadOrder = leadCubeInfo.leadOrder
    if (leadOrder.length == 0) {
      clearPath()
      return
    }
    let endOrder = leadOrder[0]

    let pathInfo = leadCubeInfo.leadPath[endOrder.mapid]
    let lastPathInfo = pathInfo[0]
    let lastPath = lastPathInfo.path
    let lastPoint = lastPath[0]
    let findInfo = leadCubeInfo.groupInfo['findInfo']
    let point = findInfo.point
    let newPath = {
      path: [{
        x: lastPoint.x,
        y: lastPoint.y,
        z: lastPoint.z
      }, {
        x: point.x,
        y: lastPoint.y,
        z: point.z
      }],
      index: 0,
      orderIndex: 0,
      pathScale: []
    }
    let order = {
      index: 0,
      mapid: endOrder.mapid
    }
    leadCubeInfo.leadOrder = [order]
    leadCubeInfo.leadPath[endOrder.mapid] = [newPath]
    showLeadWayExist()
    leadGroup.visible = true
    leadCubeInfo.groupInfo['showLastWay'] = true
    userManager.setPhoneLocation({
      accuracy: 0,
      x: point.x,
      z: point.z,
      shouldZoom: false,
      showImage: false,
      mapid: endOrder.mapid
    })
  }

  function userZoom() {
    let list = userManager.getZoomList()
    for (let i = 0; i < list.length; i++) {
      let mesh = list[i]
      setSpriteScale(mesh)
    }
  }

  function setUpUserManager() {
    userManager.updatePointInfoAction((infos) => {
      pointManager.updatePointsInfo(infos, {})
    })

    userManager.leadInfoAction((mapid) => {
      return JSON.parse(JSON.stringify(returnMapLeadInfo(mapid)))
    })

    userManager.positionAtZoneAction((t) => {
      return dataManager.getPositionAtZone(t, returnNowMapId())
    })

    userManager.fenceZoneAction(() => {
      return dataManager.getMapFence(returnNowMapId())
    })

    userManager.changeUserDirectionAction(() => {
      return simulationManager.simulationMode() == false && analysis.jsonKeyIsExist(leadCubeInfo.groupInfo, [
        'findInfo'
      ])
    })

    userManager.zoomAction((list) => {
      for (let i = 0; i < list.length; i++) {
        setSpriteScale(list[i])
      }
    })

    userManager.zoomFixAction((mapid) => {
      zoomToFixPath(mapid)
    })

    userManager.mapNameAction((mapid) => {
      return dataManager.getMapName(mapid)
    })

    userManager.updateLeadWayAction((res) => {
      if (res.result) {
        showLeadWayExist()
      } else {
        let clearPath = false
        if (analysis.jsonKeyIsExist((res, ['clearPath']))) {
          clearPath = res['clearPath']
        }
        clearLeadWay()
        if (clearPath == false) {
          return
        }
        clearPath()
      }
    })

    userManager.zoneInfoAction((mapid) => {
      return dataManager.getZoneInfo(mapid)
    })

    userManager.mapJson_WallAction((mapid) => {
      return {
        mapJson: returnMapJson(mapid),
        wall: dataManager.getWallInfo(mapid)
      }
    })

    userManager.targetAction((mapid) => {
      return dataManager.getLeadTarget(mapid)
    })

    userManager.roomInfoByPointAction((mapid, point) => {
      return returnRoomInfoByPoint(mapid, point)
    })

    userManager.locationAction((config) => {
      return dataManager.getLocationInfo(config)
    })

    userManager.mapLocationNameAction((point) => {
      return dataManager.getMapLocationName(point)
    })

    userManager.changeMapAction((mapid) => {
      return new Promise(resolve => {
        changeMap(mapid)
        setTimeout(() => {
          resolve()
        }, 100)
      })
    })

    userManager.updateLeadInfo_groupInfoAction((res) => {
      leadCubeInfo.groupInfo['findInfo'] = res
    })

    userManager.updateLeadInfoAction((res, config) => {
      dealWithLeadInfoDetalMsg(res, config)
    })

    userManager.updateLeadGroupAction((res) => {
      leadGroup.updateRender(res)
    })

    userManager.cameraModeAction(() => {
      return {
        selectCameraMode: selectCameraMode,
        cameraMode: cameraMode
      }
    })

    userManager.zhinanzhenAction((angle) => {
      if (zhinanzhen == null) {
        return
      }
      zhinanzhen.rotation.z = angle
    })

    userManager.cameraAnimationAction((config) => {
      cameraMoveAnimation(config)
    })

    userManager.pathInfoAction(() => {
      return {
        path: leadCubeInfo.leadPath,
        order: leadCubeInfo.leadOrder,
        orderList: dataManager.getMapOrderList()
      }
    })

    userManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        orderList: dataManager.getMapOrderList(),
        zeroId: returnFloorAreaId(mapid),
        mapid: mapid,
        floorHieght: returnFloorHeight(mapid),
        north: dataManager.getMapNorth(mapid),
        findInfo: leadCubeInfo.groupInfo['findInfo'],
        scaleInfo: 10,
        userStatusLead: leadCubeInfo.userStatusLead
      }
    })
    userManager.zoomAction((list) => {
      userZoom()
    })

    userManager.groupInfoAction(() => {
      return leadCubeInfo
    })

    userManager.pointToAllowLocationAction((mapid, x, z, shouldCorrect) => {
      return reutrnPointToAllowLocation(mapid, {
        x: x,
        z: z
      }, shouldCorrect)
    })

    userManager.threeToCoordinateAction((x, z) => {
      return worldManager.threeToCoordinate(x, z)
    })

    userManager.zeroInfoAction((mapid) => {
      return dataManager.getZeroInfo(mapid)
    })

    userManager.coordinateToThreeAction((lng, lat) => {
      return worldManager.coordinateToThree(lng, lat, 16)
    })

    userManager.startLeadAction((config) => {
      pointManager.clearPointsInfo(config)
      detailManager.cancelShowAllMap()
      leadGroup.hiddenStart()
      showLeadWayExist()
    })

    userManager.showLastAction((showLast) => {
      if (showLast) {
        showLastWay()
      } else {
        clearPath()
      }
    })

    userManager.zoomFixAction((mapid) => {
      zoomToFixPath(mapid)
    })

    userManager.roomInfoByIdAction((pointInfo) => {
      let newPoint = {}
      if (analysis.jsonKeyIsExist(pointInfo, ['mapid'])) {
        newPoint['mapid'] = pointInfo['mapid']
      } else if (analysis.jsonKeyIsExist(pointInfo, ['mapId'])) {
        newPoint['mapid'] = pointInfo['mapId']
      } else {
        return {
          result: false
        }
      }

      if (analysis.jsonKeyIsExist(pointInfo, ['roomid'])) {
        newPoint['roomid'] = pointInfo['roomid']
      } else if (analysis.jsonKeyIsExist(pointInfo, ['roomAreaId'])) {
        newPoint['roomid'] = pointInfo['roomAreaId']
      } else if (analysis.jsonKeyIsExist(pointInfo, ['areaid'])) {
        newPoint['roomid'] = pointInfo['areaid']
      } else if (analysis.jsonKeyIsExist(pointInfo, ['areaId'])) {
        newPoint['roomid'] = pointInfo['areaId']
      }
      newPoint['y'] = 0
      newPoint['name'] = ''
      newPoint['height'] = 0
      newPoint['orgName'] = ''
      newPoint['mapName'] = ''

      let result = dataManager.returnMap_RoomCenter(newPoint.mapid, newPoint.roomid, {})
      if (result.result == false) {
        newPoint['name'] = result.name
        newPoint['height'] = 0
        newPoint['orgName'] = result.orgName
        newPoint['mapName'] = result.mapName
        newPoint['areas'] = result['areas']
        newPoint['areaid'] = result['areaid']
        newPoint['title'] = result['title']
      }

      if (analysis.jsonKeyIsExist(pointInfo, ['x', 'z'])) {
        newPoint['x'] = parseFloat(pointInfo['x'])
        newPoint['z'] = parseFloat(pointInfo['z'])
      } else {
        newPoint['x'] = result['x']
        newPoint['z'] = result['y']
      }

      return {
        result: true,
        pointInfo: newPoint
      }
    })
  }

  function setUpWorldManager() {
    worldManager.sceneAction(() => {
      return scene
    })

    worldManager.maxDistanceAction(() => {
      controls.maxDistance = camera.far
    })

    worldManager.mapSetInfoAction(() => {
      return mapSetInfo
    })
  }

  function setUpDetailMapManager() {
    detailManager.leadCubeInfoAction(() => {
      return leadCubeInfo
    })

    detailManager.changeMapAction((mapid) => {
      changeMap(mapid)
    })

    detailManager.updateHeightAction((fence, spaceHeight, width, w, h, zoneId, textInfo) => {
      let y = controls.getHeight(fence.leftUp, fence.rightDown, w * 0.1, h * 0.1, width /
        windowHeight) + spaceHeight
      if (detailConfig.showFloorText) {
        textGroup.findMapZone({
          zoneId: zoneId,
          textInfo: textInfo
        })
      }
      return y
    })

    detailManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        mapOrderList: dataManager.getMapOrderList()
      }
    })

    detailManager.exGroupAction(() => {
      return getEXGroup()
    })

    detailManager.groupAction((mapid) => {
      return returnMapgroup(mapid)
    })

    detailManager.judgeFenceInfoAction((config) => {
      config['camera'] = camera
      config['judgeLV'] = mapSetInfo.perspectiveZoomLV
      return controls.judgeFence(config)
    })

    detailManager.maxPolarAngleAction(() => {
      return maxPolarAngle
    })

    detailManager.cameraAnimationAction((config) => {
      cameraMoveAnimation(config)
    })
  }

  function setUpLayerManager() {
    layerDataManager.mapInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHeight: returnFloorHeight(mapid) + 1
      }
    })

    layerDataManager.cameraAction(() => {
      return camera
    })

    layerDataManager.getLayer().showTextAction((list) => {
      pointManager.updatePointsInfo({ textList: list }, {})
    })
  }

  function setRoomScale(point) {
    function findRoom(mesh, id) {
      if (mesh.type == 'Group') {
        let children = mesh.children
        for (let i = 0; i < children.length; i++) {
          let m = children[i]
          let res = findRoom(m, id)
          if (res.result) {
            return res
          }
        }
        return {
          result: false
        }
      }
      if (mesh.type != 'Mesh') {
        return {
          result: false
        }
      }

      let mapid = returnNowMapId()
      if (mesh.uuid != id && (mapid + mesh.uuid != id)) {
        return {
          result: false
        }
      }
      mesh.scale.set(1, 1, -0.001)
      mesh.position.y = returnFloorHeight(mapid)
      return {
        result: true
      }
    }

    let result = dataManager.returnMap_RoomName(returnNowMapId(), point)
    if (result.result == false) {
      return
    }
    let id = result.id
    let areaid = returnFloorAreaId(returnNowMapId())
    if (id == areaid) {
      return
    }
    let group = returnNowMapGroup()
    let children = group.children
    for (let i = 0; i < children.length; i++) {
      let res = findRoom(children[i], id)
      if (res.result) {
        break
      }
    }
  }

  function setUpLabelGroupManager() {
    labelGroupManager.zoneInfoByPointAction((mapid, data) => {
      return returnZoneInfoByPoint(mapid, data)
    })

    labelGroupManager.pointRoomAction((mapid, data) => {
      return reutrnPointRoomId(mapid, data)
    })

    labelGroupManager.getObject().checkRoomAction((point) => {
      setRoomScale(point)
    })

    labelGroupManager.manInfoAction(() => {
      let mapid = returnNowMapId()
      return {
        mapid: mapid,
        floorHeight: returnFloorHeight(mapid)
      }
    })
  }

  setUpGridManager()
  setUpHeatmapManager()
  setUpPointManager()
  setUpLeadPointManager()
  setUpSimulation()
  setUpUserManager()
  setUpWorldManager()
  setUpDetailMapManager()
  setUpLayerManager()
  setUpLabelGroupManager()

  function cameraMoveAnimation(config) {
    let time = 400
    if (analysis.jsonKeyIsExist(config, ['time'])) {
      time = config['time']
    }
    controls.controlCameraAniamtion(config.target, config.rotate, config.scale, {
      time: time,
      windowWidth: windowWidth,
      windowHeight: windowHeight
    }, () => {
      mapRotateOrZoom()
    }, () => {
      mapRotateOrZoom()
    })
  }

  return map
}
