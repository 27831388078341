import {
  createAnalaysisInfo
} from './dataAnalaysisInfo.js'
import {
  createMatrixInfo
} from './makeMatrix.js'
import * as THREE from 'three/build/three.min.js'

export function createInfo() {
  const matrix = createMatrixInfo() // 导航路线计算的js

  const floorLinkType = matrix.leadPointType()

  const analysis = createAnalaysisInfo() // 构造地图解析

  let zero = {}

  function jsonKeyIsExist(json, keys) {
    if (keys == null || typeof (keys) == 'undefined' || json == null || typeof (json) == 'undefined' || keys.length ==
      0) {
      console.log('json 格式出错')
      return false
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (json.hasOwnProperty(key)) {
        const value = json[key]
        if (value == null || typeof (value) == 'undefined') {
          return false
        }
      } else {
        return false
      }
    }
    return true
  }

  const svg = new Object()
  const leadInfo = {}

  svg.getSvgFileStr = function (str, config) {
    const parser = new DOMParser(str)
    const xmlDoc = parser.parseFromString(str, 'text/xml')
    // 提取数据
    const countrys = xmlDoc.getElementsByTagName('svg')
    const svgJosn = {
      north: 0,
      id: '',
      name: '',
      scale: 10,
      order: 1
    }
    const transFrom = {}
    let fileName = ''
    for (let i = 0; i < countrys.length; i++) {
      const obj = countrys[i]
      const viewBox = obj['viewBox']
      const objAttributes = obj['attributes']
      let north = objAttributes['north']
      let fileNameInfo = objAttributes['sodipodi:docname']
      if (typeof objAttributes['docname'] != 'undefined' && objAttributes['docname'] != null) {
        fileNameInfo = objAttributes['docname']
      }

      if (fileNameInfo != null && typeof (fileNameInfo) != 'undefined') {
        fileName = fileNameInfo['value']
      }
      if (north != null && typeof (north) != 'undefined') {
        const angle = north['value']
        if (angle != null && typeof (angle) != 'undefined') {
          svgJosn.north = parseFloat(angle)
        }
      } else {
        north = objAttributes['North']
        if (north != null && typeof (north) != 'undefined') {
          const angle = north['value']
          if (angle != null && typeof (angle) != 'undefined') {
            if (parseFloat(angle) < 0) {
              svgJosn.north = parseFloat(angle) * (-1)
            } else {
              svgJosn.north = 360 - parseFloat(angle)
            }
          }
        }
      }
      const svgid = objAttributes['id']
      svgJosn.id = '-' + svgid['value'] + '-'

      const nameJson = objAttributes['Name']
      if (nameJson != null && typeof (nameJson) != 'undefined') {
        svgJosn.name = nameJson['value']
      }

      const scaleJson = objAttributes['Scale']
      if (scaleJson != null && typeof (scaleJson) != 'undefined') {
        svgJosn.scale = scaleJson['value']
      }

      const orderJson = objAttributes['Order']
      if (orderJson != null && typeof (orderJson) != 'undefined') {
        svgJosn.order = parseInt(orderJson['value'])
      }

      const animVal = viewBox['animVal']
      const height = animVal['height']
      const width = animVal['width']
      const x = animVal['x']
      const y = animVal['y']
      svgJosn['viewBox'] = {
        'x': x,
        'y': y,
        'width': width * 1.2.toFixed(7),
        'height': height * 1.2.toFixed(7)
      }
      svgJosn['mapid'] = fileName;
      svgJosn['mapinfo'] = {}
      svgJosn['mapinfo']['extraInfo'] = [] // 动画层
      svgJosn['mapinfo']['chartLetInfo'] = [] // 贴图层
      svgJosn['mapinfo']['lvhuaInfo'] = [] // 绿化层
      svgJosn['mapinfo']['zoneInfo'] = [] // 区域层
      svgJosn['mapinfo']['coverInfo'] = []
      svgJosn['mapinfo']['target'] = [] // 区域终点
      svgJosn['mapinfo']['targetZone'] = [] // 导航区域归纳
      svgJosn['mapinfo']['showOther'] = [] // 装饰
      svgJosn['mapinfo']['wall'] = [] // 墙体
      svgJosn['mapinfo']['orgClass'] = [] // 机构科室
      svgJosn['mapinfo']['background'] = [] // 地图背景图
      svgJosn['mapinfo']['nameInfo'] = []
      const children = obj['children']
      for (const key in children) {
        const childjson = children[key]
        const nodeName = childjson['nodeName']
        if (nodeName == null || nodeName != 'g') {
          continue
        }
        const attributes = childjson['attributes']
        let type = 'layer' // 绘制图层
        let trans = ''
        for (const attributesKey in attributes) {
          const node = attributes[attributesKey]
          const subNodeName = node['nodeName']
          if (subNodeName == 'name') {
            // 命名层
            type = subNodeName
          } else if (subNodeName == 'transform') {
            trans = node['nodeValue']
          } else if (subNodeName == 'cover') {
            type = subNodeName
          } else if (subNodeName == 'leadLine') {
            type = subNodeName
          } else if (subNodeName == 'extra') {
            type = subNodeName
          } else if (subNodeName == 'chartLet') {
            type = subNodeName
          } else if (subNodeName == 'lvhua') {
            type = subNodeName
          } else if (subNodeName == 'zone') {
            type = subNodeName
          } else if (subNodeName == 'targetZone') {
            type = subNodeName
          } else if (subNodeName == 'target') {
            type = subNodeName
          } else if (subNodeName == 'showOther') {
            type = subNodeName
          } else if (subNodeName == 'wall') {
            type = subNodeName
          } else if (subNodeName == 'orgClass') {
            type = subNodeName
          } else if (subNodeName == 'background') {
            type = subNodeName
          }
        }
        if (type == 'name') {
          svgJosn['mapinfo']['nameInfo'] = findNameLayer(childjson, svgJosn)
        } else if (type == 'layer') {
          drawLayer(childjson, svgJosn)
        } else if (type == 'cover') {
          const infos = getCoverNameInfo(childjson, svgJosn)
          if (infos.length > 0) {
            svgJosn['mapinfo']['coverInfo'] = infos
          }
        } else if (type == 'leadLine') {
          getLeadLine(childjson, svgJosn, fileName)
        } else if (type == 'extra') {
          const extraInfo = {}
          extraInfo['mapinfo'] = {}
          drawLayer(childjson, extraInfo, fileName)
          svgJosn['mapinfo']['extraInfo'] = extraInfo['mapinfo']['mapinfo']
        } else if (type == 'chartLet') {
          const chartLetInfo = {}
          chartLetInfo['mapinfo'] = {}
          drawLayer(childjson, chartLetInfo, fileName)
          svgJosn['mapinfo']['chartLetInfo'] = chartLetInfo['mapinfo']['mapinfo']
        } else if (type == 'lvhua') {
          const lvhuaInfo = {}
          lvhuaInfo['mapinfo'] = {}
          drawLayer(childjson, lvhuaInfo, fileName)
          svgJosn['mapinfo']['lvhuaInfo'] = lvhuaInfo['mapinfo']['mapinfo']
        } else if (type == 'zone') {
          const zoneInfo = {}
          zoneInfo['mapinfo'] = {}
          drawLayer(childjson, zoneInfo, fileName)
          svgJosn['mapinfo']['zoneInfo'] = zoneInfo['mapinfo']['mapinfo']
        } else if (type == 'targetZone') {
          const zoneInfo = {}
          zoneInfo['mapinfo'] = {}
          drawLayer(childjson, zoneInfo, fileName)
          svgJosn['mapinfo']['targetZone'] = zoneInfo['mapinfo']['mapinfo']
        } else if (type == 'target') {
          const zoneInfo = {}
          zoneInfo['mapinfo'] = {}
          drawLayer(childjson, zoneInfo, fileName)
          svgJosn['mapinfo']['target'] = zoneInfo['mapinfo']['mapinfo']
        } else if (type == 'showOther') {
          const zoneInfo = {}
          zoneInfo['mapinfo'] = {}
          drawLayer(childjson, zoneInfo, fileName)
          svgJosn['mapinfo']['showOther'] = zoneInfo['mapinfo']['mapinfo']
        } else if (type == 'wall') {
          const wallInfo = {}
          wallInfo['mapinfo'] = {}
          drawLayer(childjson, wallInfo, fileName)
          svgJosn['mapinfo']['wall'] = wallInfo['mapinfo']['mapinfo']
        } else if (type == 'orgClass') {
          const orgClassInfo = {}
          orgClassInfo['mapinfo'] = {}
          drawLayer(childjson, orgClassInfo, fileName)
          svgJosn['mapinfo']['orgClass'] = orgClassInfo['mapinfo']['mapinfo']
        } else if (type == 'background') {
          const backgroundInfo = {}
          backgroundInfo['mapinfo'] = {}
          getBackgroundData(childjson, backgroundInfo, fileName)
          svgJosn['mapinfo']['background'] = backgroundInfo['mapinfo']['mapinfo']
        }
        if (trans != '') {
          transFrom[type] = trans
        }
      }
    }
    judgeLeadInfoLink(leadInfo, fileName)
    setLeadCubeMapInfoId(svgJosn['mapinfo']['mapinfo'])
    splitMapInfo(svgJosn)
    const mapinfo = svgJosn['mapinfo']
    findZero(mapinfo)
    svgJosn['leadInfo'] = leadInfo
    // findInvaildFence(mapinfo['mapinfo'])
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['zoneInfo'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['zoneInfo'], 'zone')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['nameInfo'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['nameInfo'], 'name')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['coverInfo'])) {
      judgeCoverInfo(transFrom, svgJosn['mapinfo']['coverInfo'])
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['extraInfo'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['extraInfo'], 'extra')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['chartLetInfo'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['chartLetInfo'], 'chartLet')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['background'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['background'], 'background')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['orgClass'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['orgClass'], 'orgClass')
      roomMatchZone(svgJosn['mapinfo']['orgClass'], svgJosn['mapinfo']['zoneInfo'], {
        key: 'inZone',
        setDefault: false
      })
      svgJosn['mapinfo']['orgClass'] = correctExtraHeight(svgJosn['mapinfo']['orgClass'])
    }
    // 将 贴图层的ex分到extra层上
    divideExtra(svgJosn['mapinfo'])
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['extraInfo'])) {
      svgJosn['mapinfo']['extraInfo'] = correctExtraHeight(svgJosn['mapinfo']['extraInfo'])
      createExtraCoverName(svgJosn['mapinfo']['extraInfo'], svgJosn['mapinfo']['coverInfo'])
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['lvhuaInfo'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['lvhuaInfo'], 'lvhua')
    }

    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['targetZone'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['targetZone'], 'targetZone')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['target'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['target'], 'target')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['showOther'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['showOther'], 'showOther')
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['wall'])) {
      judgeNameInfo(transFrom, svgJosn['mapinfo']['wall'], 'wall')
    }
    roomMatchZone(svgJosn['mapinfo']['chartLetInfo'], svgJosn['mapinfo']['zoneInfo'], {
      key: 'inZone',
      setDefault: false
    })
    roomMatchZone(svgJosn['mapinfo']['extraInfo'], svgJosn['mapinfo']['zoneInfo'], {
      key: 'inZone',
      setDefault: false
    })
    roomMatchZone(svgJosn['mapinfo']['mapinfo'], svgJosn['mapinfo']['zoneInfo'], {
      key: 'inZone',
      setDefault: false
    })
    roomMatchZone(svgJosn['mapinfo']['show'], svgJosn['mapinfo']['zoneInfo'], {
      key: 'inZone',
      setDefault: false
    })
    roomMatchZone(svgJosn['mapinfo']['wall'], svgJosn['mapinfo']['zoneInfo'], {
      key: 'inZone',
      setDefault: false
    })
    roomMatchZone(svgJosn['mapinfo']['nameInfo'], svgJosn['mapinfo']['zoneInfo'], {
      key: 'inZone',
      setDefault: false
    })
    let inNameInfo = false
    if (analysis.jsonKeyIsExist(config, ['inNameInfo'])) {
      inNameInfo = config['inNameInfo']
    }
    if (inNameInfo) {
      roomMatchZone(svgJosn['mapinfo']['mapinfo'], svgJosn['mapinfo']['nameInfo'], {
        key: 'inNameInfo',
        setDefault: false
      })
    }

    judgeTargetAndTargetZone(svgJosn)
    filterSvgInfo(svgJosn)
    adjudeLeadPointAtRoom(svgJosn['leadInfo'], svgJosn['mapinfo']['mapinfo'], fileName)
    roomMatchOrgClass(svgJosn['mapinfo']['mapinfo'], svgJosn['mapinfo']['orgClass'])
    roomMatchOrgClass(svgJosn['mapinfo']['nameInfo'], svgJosn['mapinfo']['orgClass'])
    return svgJosn
  }

  // 链接楼层连接点与房间外面的点
  function linkFloorPoint(leadInfo, wallInfo, mapId) {
    function testupdateLinkPointInfo(point1, point2) {
      const dis = Math.sqrt((point1.x - point2.x) * (point1.x - point2.x) + (point1.y - point2.y) * (point1.y - point2.y))
      let linkPoint1 = {}
      if (analysis.jsonKeyIsExist(point1, ['linkPoint'])) {
        linkPoint1 = point1['linkPoint']
      } else {
        point1['linkPoint'] = linkPoint1
      }

      let linkPoint2 = {}
      if (analysis.jsonKeyIsExist(point2, ['linkPoint'])) {
        linkPoint2 = point2['linkPoint']
      } else {
        point2['linkPoint'] = linkPoint2
      }

      let linkId = ''
      const p1Id = point1.id
      const p2Id = point2.id
      if (p1Id > p2Id) {
        linkId = 'line' + p1Id + '-' + p2Id
      } else {
        linkId = 'line' + p2Id + '-' + p1Id
      }

      const p_Info = {
        startP: {
          x: point1.x,
          y: 0,
          z: point1.y
        },
        endP: {
          x: point2.x,
          y: 0,
          z: point2.y
        },
        id: linkId,
        start: p1Id,
        end: p2Id,
        dis: dis
      }
      linkPoint1[p2Id] = JSON.parse(JSON.stringify(p_Info))
      linkPoint2[p1Id] = JSON.parse(JSON.stringify(p_Info))
    }

    for (const key in leadInfo) {
      let info = leadInfo[key];
      if (analysis.jsonKeyIsExist(info, ['type']) == false || info['type'] == 0) {
        continue;
      }
      let bestKey = '';
      let minDis = 65535;
      for (const linkKey in leadInfo) {
        let linkInfo = leadInfo[linkKey];
        if (analysis.jsonKeyIsExist(linkInfo, ['type']) == false || linkInfo['type'] != 0) {
          continue;
        }
        let xCount = info.x - linkInfo.x;
        let yCount = info.y - linkInfo.y;
        let dis = Math.sqrt(yCount * yCount + xCount * xCount);
        if (dis >= 5 * 10 || minDis < dis) {
          continue;
        }
        let shouldContiune = true
        for (let k = 1; k < wallInfo.length; k++) {
          const room = wallInfo[k]
          const result = analysis.judgeIsCorssWall([{
            x: info.x,
            z: info.y
          }, {
            x: linkInfo.x,
            z: linkInfo.y
          }], room)
          if (result == true) {
            // 穿墙了
            shouldContiune = false
            break
          }
        }
        if (shouldContiune == false) {
          continue
        }
        bestKey = linkKey;
        minDis = dis;
      }

      if (analysis.jsonKeyIsExist(leadInfo, [bestKey]) == false) {
        continue;
      }
      updateLinkPointInfo(info, leadInfo[bestKey]);
    }
  }

  function updateLinkPointInfo(point1, point2) {
    const dis = Math.sqrt((point1.x - point2.x) * (point1.x - point2.x) + (point1.y - point2.y) * (point1.y - point2.y))
    let linkPoint1 = {}
    if (analysis.jsonKeyIsExist(point1, ['linkPoint'])) {
      linkPoint1 = point1['linkPoint']
    } else {
      point1['linkPoint'] = linkPoint1
    }

    let linkPoint2 = {}
    if (analysis.jsonKeyIsExist(point2, ['linkPoint'])) {
      linkPoint2 = point2['linkPoint']
    } else {
      point2['linkPoint'] = linkPoint2
    }

    let linkId = ''
    const p1Id = point1.id
    const p2Id = point2.id
    if (p1Id > p2Id) {
      linkId = 'line' + p1Id + '-' + p2Id
    } else {
      linkId = 'line' + p2Id + '-' + p1Id
    }

    const p_Info = {
      startP: {
        x: point1.x,
        y: 0,
        z: point1.y
      },
      endP: {
        x: point2.x,
        y: 0,
        z: point2.y
      },
      id: linkId,
      start: p1Id,
      end: p2Id,
      dis: dis
    }
    linkPoint1[p2Id] = JSON.parse(JSON.stringify(p_Info))
    linkPoint2[p1Id] = JSON.parse(JSON.stringify(p_Info))
  }

  // 对那些似连非连的点进行调整
  function judgeLeadInfoLink(leadInfo, mapId) {


    function updateNewPoint(leadInfo, point, mapId, line, index) {
      const startId = line['start']
      const endId = line['end']
      if (analysis.jsonKeyIsExist(leadInfo, [startId, endId]) == false) {
        return {
          result: false
        }
      }
      // 将该点和该投影点放入leadinfo
      const newId = mapId + 'T' + index
      const newPoint = {
        id: newId,
        x: point.x,
        y: point.z
      }
      const startInfo = leadInfo[startId]
      const endInfo = leadInfo[endId]
      if (startInfo.x == newPoint.x && startInfo.y == newPoint.y) {
        console.log('start')
        return {
          result: false
        }
      }
      if (endInfo.x == newPoint.x && endInfo.y == newPoint.y) {
        console.log('end')
        return {
          result: false
        }
      }
      updateLinkPointInfo(newPoint, startInfo)
      updateLinkPointInfo(newPoint, endInfo)
      leadInfo[newId] = newPoint
      return {
        result: true,
        pointInfo: newPoint
      }
    }

    const lineInfo = {}
    let index = 0
    for (const leadId in leadInfo) {
      const info = leadInfo[leadId]
      if (analysis.jsonKeyIsExist(info, ['linkPoint']) == false) {
        continue
      }
      const linkPoint = info['linkPoint']
      for (const linkPointKey in linkPoint) {
        const linkInfo = linkPoint[linkPointKey]
        lineInfo[linkInfo['id']] = linkInfo
      }
    }
    const a_line = new THREE.Line3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0))
    for (const key in lineInfo) {
      const line = lineInfo[key]
      for (const leadId in leadInfo) {
        const lead = leadInfo[leadId]
        const x = lead['x']
        const y = lead['y']
        const res = analysis.updateLineAndclosestPointToPointParameterInfo(a_line, x, 0, y, line['startP'], line['endP'])
        const scale = res['scale']
        if (scale <= 0 || scale >= 1) {
          continue
        }
        const t = res['point']
        const dis = Math.sqrt((t.x - x) * (t.x - x) + (t.z - y) * (t.z - y))
        const spaceNum = 1
        if (dis > spaceNum) {
          // 大于1px则返回
          continue
        }
        if (t.x == lead.x && t.z == lead.y) {
          continue
        }
        const pointRes = updateNewPoint(leadInfo, t, mapId, line, index)
        if (pointRes.result == false) {
          continue
        }
        updateLinkPointInfo(lead, pointRes.pointInfo)
        index++
      }
    }
  }

  // 调整在房间内的连接点
  function adjudeLeadPointAtRoom(leadInfo, roomInfo, mapid) {
    function deleteLinkInfo(id1, id2, leadInfo) {
      if (analysis.jsonKeyIsExist(leadInfo, [id1]) == false) {
        return
      }
      const lead = leadInfo[id1]
      if (analysis.jsonKeyIsExist(lead, ['linkPoint']) == false) {
        return
      }
      const linkPoint = lead['linkPoint']
      if (analysis.jsonKeyIsExist(linkPoint, [id2]) == false) {
        return
      }
      delete linkPoint[id2]
    }

    function addNewPoint(leadInfo, id, newId) {
      if (analysis.jsonKeyIsExist(leadInfo, [id, newId]) == false) {
        return
      }
      const lead = leadInfo[id]
      let linkPoint1 = {}
      if (analysis.jsonKeyIsExist(lead, ['linkPoint'])) {
        linkPoint1 = lead['linkPoint']
      } else {
        lead['linkPoint'] = linkPoint1
      }

      const newLead = leadInfo[newId]
      let linkPoint2 = {}
      if (analysis.jsonKeyIsExist(newLead, ['linkPoint'])) {
        linkPoint2 = newLead['linkPoint']
      } else {
        newLead['linkPoint'] = linkPoint2
      }

      let linkId = ''
      const p1Id = id
      const p2Id = newId
      if (p1Id > p2Id) {
        linkId = 'line' + p1Id + '-' + p2Id
      } else {
        linkId = 'line' + p2Id + '-' + p1Id
      }

      const x = newLead.x - lead.x
      const y = newLead.y - lead.y
      const dis = Math.sqrt(x * x + y * y)

      const p_Info = {
        startP: {
          x: newLead.x,
          y: 0,
          z: newLead.y
        },
        endP: {
          x: lead.x,
          y: 0,
          z: lead.y
        },
        id: linkId,
        start: p2Id,
        end: p1Id,
        dis: dis
      }
      linkPoint1[newId] = JSON.parse(JSON.stringify(p_Info))
      linkPoint2[id] = JSON.parse(JSON.stringify(p_Info))
    }

    function updateLeadInfo(leadInfo, id1, id2, newId) {
      deleteLinkInfo(id1, id2, leadInfo)
      deleteLinkInfo(id2, id1, leadInfo)
      addNewPoint(leadInfo, id1, newId)
      addNewPoint(leadInfo, id2, newId)
    }

    function findRoomByAreaId(id, roomInfo) {
      const result = {
        result: false
      }
      for (let i = 1; i < roomInfo.length; i++) {
        const room = roomInfo[i]
        if (room['areaid'] == id) {
          result.result = true
          result['areas'] = room['areas']
          break
        }
      }
      return result
    }

    let zeroId = ''
    if (analysis.jsonKeyIsExist(zero, ['areaid'])) {
      zeroId = zero['areaid']
    }
    for (const leadId in leadInfo) {
      const info = leadInfo[leadId]
      if (analysis.jsonKeyIsExist(info, ['inMapId', 'linkPoint']) == false) {
        continue
      }
      const inMapId = info['inMapId']
      if (inMapId == zeroId) {
        continue
      }
      const res = findRoomByAreaId(inMapId, roomInfo)
      if (res.result == false) {
        continue
      }
      const areas = res['areas']
      const linkPoint = info['linkPoint']
      for (const linkId in linkPoint) {
        const linkInfo = linkPoint[linkId]
        const startP = linkInfo['startP']
        const endP = linkInfo['endP']
        for (let i = 0; i < areas.length; i++) {
          const p1 = areas[i]
          let index = i + 1
          if (index >= areas.length) {
            index = 0
          }
          const p2 = areas[index]
          const result = analysis.getLinesIntersect(startP.x, startP.z, endP.x, endP.z, p1[0], p1[2], p2[0], p2[2])
          if (result.result == false) {
            continue
          }
          const id = mapid + result.x.toFixed(2) + '~' + result.y.toFixed(2)
          if (analysis.jsonKeyIsExist(leadInfo, [id])) {
            continue;
          }
          let copy = JSON.parse(JSON.stringify(info));
          delete copy['link'];
          const newPoint = copy
          delete newPoint['linkPoint']
          newPoint['id'] = id
          newPoint['x'] = result.x
          newPoint['y'] = result.y
          newPoint['type'] = 0;
          leadInfo[id] = newPoint
          updateLeadInfo(leadInfo, info['id'], linkId, id)
          break
        }
      }
    }
  }

  // 剔除多余信息
  function filterSvgInfo(svgJosn) {
    function deleteInfo(svgJosn, key, deleteKeys) {
      let editorInfo = {}
      if (analysis.jsonKeyIsExist(svgJosn, [key])) {
        editorInfo = svgJosn[key]
      }
      for (const key in editorInfo) {
        const info = editorInfo[key]
        if (analysis.jsonKeyIsExist(info, ['length'])) {
          for (let i = 0; i < info.length; i++) {
            const checkInfo = info[i]
            if (analysis.jsonKeyIsExist(checkInfo, ['areas'])) {
              const areas = checkInfo['areas']
              for (let j = 0; j < areas.length; j++) {
                const point = areas[j]
                point[0] = parseFloat(point[0].toFixed(1))
                point[2] = parseFloat(point[2].toFixed(1))
              }
            }
            for (let j = 0; j < deleteKeys.length; j++) {
              const findKey = deleteKeys[j]
              delete checkInfo[findKey]
            }
          }
        } else {
          if (analysis.jsonKeyIsExist(info, ['areas'])) {
            const areas = info['areas']
            for (let j = 0; j < areas.length; j++) {
              const point = areas[j]
              point[0] = parseFloat(point[0].toFixed(1))
              point[2] = parseFloat(point[2].toFixed(1))
            }
          }
          for (let i = 0; i < deleteKeys.length; i++) {
            const findKey = deleteKeys[i]
            delete info[findKey]
          }
        }
      }
    }

    deleteInfo(svgJosn, 'leadInfo', ['title', 'url'])
    let mapinfo = {}
    if (analysis.jsonKeyIsExist(svgJosn, ['mapinfo'])) {
      mapinfo = svgJosn['mapinfo']
    }
    deleteInfo(mapinfo, 'chartLetInfo', ['image', 'layerHeight'])
    deleteInfo(mapinfo, 'coverInfo', ['color', 'layerHeight'])
    deleteInfo(mapinfo, 'extraInfo', ['image', 'layerHeight'])
    deleteInfo(mapinfo, 'mapinfo', ['layerHeight'])
    deleteInfo(mapinfo, 'show', ['layerHeight'])
    deleteInfo(mapinfo, 'showOther', ['layerHeight'])
    deleteInfo(mapinfo, 'zoneInfo', ['layerHeight'])
  }

  function judgeTargetAndTargetZone(svgJosn) {
    const newTarget = {}
    let zeroId = ''
    if (analysis.jsonKeyIsExist(zero, ['areaid'])) {
      zeroId = zero['areaid']
    }
    if (analysis.jsonKeyIsExist(svgJosn['mapinfo'], ['targetZone']) && analysis.jsonKeyIsExist(svgJosn['mapinfo'], [
      'target'
    ])) {
      const target = svgJosn['mapinfo']['target']
      const targetZone = svgJosn['mapinfo']['targetZone']
      if (target.length == 0 || targetZone.length == 0) {
        delete svgJosn['mapinfo']['targetZone']
        delete svgJosn['mapinfo']['target']
        svgJosn['mapinfo']['target'] = newTarget
        return
      }

      const mapinfo = svgJosn['mapinfo']['mapinfo']
      for (let i = 0; i < target.length; i++) {
        const t = target[i]
        t['areaid'] = '-'
        const polygon = analysis.getPolygonAreaCenter(t['areas'])
        const mapinfo = svgJosn['mapinfo']['mapinfo']
        for (let j = 1; j < mapinfo.length; j++) {
          const roomInfo = mapinfo[j]
          if (analysis.isInPolygon([polygon[1], 0, polygon[0]], roomInfo['areas'])) {
            t['areaid'] = roomInfo['areaid']
            break
          }
        }
      }

      for (let i = 0; i < targetZone.length; i++) {
        const zone = targetZone[i]
        const findInfo = {}
        for (let j = 1; j < mapinfo.length; j++) {
          const roomInfo = mapinfo[j]
          const polygon = analysis.getPolygonAreaCenter(roomInfo['areas'])
          if (analysis.isInPolygon([polygon[1], 0, polygon[0]], zone['areas'])) {
            const id = parseInt(polygon[1]) + ' ~ ' + parseInt(polygon[0])
            findInfo[id] = {
              id: id,
              x: polygon[1],
              y: 0,
              z: polygon[0]
            }
          }
        }
        const targetInfo = {}

        for (let j = 0; j < target.length; j++) {
          const roomInfo = target[j]
          const polygon = analysis.getPolygonAreaCenter(roomInfo['areas'])
          if (analysis.isInPolygon([polygon[1], 0, polygon[0]], zone['areas'])) {
            const id = roomInfo['areaid']
            targetInfo[id] = {
              isZeroId: id == zeroId,
              targetId: id,
              tx: polygon[1],
              ty: 0,
              tz: polygon[0]
            }
          }
        }

        for (const key in findInfo) {
          const info = findInfo[key]
          for (const targetKey in targetInfo) {
            const tInfo = targetInfo[targetKey]
            info['isZeroId'] = tInfo['isZeroId']
            info['targetId'] = tInfo['targetId']
            info['tx'] = tInfo['tx']
            info['ty'] = tInfo['ty']
            info['tz'] = tInfo['tz']
            break
          }
          newTarget[key] = info
        }
      }
    }
    delete svgJosn['mapinfo']['targetZone']
    delete svgJosn['mapinfo']['target']
    svgJosn['mapinfo']['target'] = newTarget
  }

  function createExtraCoverName(extrasInfo, coversInfo) {
    const list = []
    for (let i = 0; i < extrasInfo.length; i++) {
      const r = extrasInfo[i]
      list.push(r)
    }
    if (coversInfo.length == 0) {
      if (list.length > 0) {
        coversInfo.push(list)
      }
    } else {
      let last = coversInfo[coversInfo.length - 1]
      last = last.concat(list)
    }
  }

  // 将房间和orgClass匹配
  function roomMatchOrgClass(roomInfo, orgClass) {
    function isInAreas(roomAreas, orgAreas) {
      let x = null;
      let z = null;
      for (let i = 0; i < roomAreas.length; i++) {
        let p = roomAreas[i];
        let px = p[0];
        let pz = p[2];
        x = (x == null ? px : (px + x));
        z = (z == null ? pz : (pz + z));
      }
      if (x == null || z == null) {
        return false;
      }
      let newX = x / roomAreas.length;
      let newZ = z / roomAreas.length;
      return analysis.isInPolygon([newX, 0, newZ], orgAreas)
    }

    function findSubOrgClass(subs, index, roomInfo) {
      if (index >= subs.length) {
        return
      }
      let nextSubs = []
      for (let i = 0; i < subs.length; i++) {
        const info = subs[i]
        if (analysis.jsonKeyIsExist(info, ['subs'])) {
          nextSubs = nextSubs.concat(info['subs'])
        }
        const res = isInAreas(roomInfo['areas'], info['areas'])
        if (res == false) {
          continue
        }
        roomInfo['orgClassId'] = info['areaid']
      }
      findSubOrgClass(nextSubs, 0, roomInfo)
    }

    for (let i = 0; i < roomInfo.length; i++) {
      const room = roomInfo[i]
      room['orgClassId'] = ''
      const areas = room['areas']
      for (let j = 0; j < orgClass.length; j++) {
        const org = orgClass[j]
        const orgAreas = org['areas']
        const result = isInAreas(areas, orgAreas)
        if (result == false) {
          continue
        }
        room['orgClassId'] = org['areaid']
        if (analysis.jsonKeyIsExist(org, ['subs']) == false) {
          continue
        }
        findSubOrgClass(org['subs'], 0, room)
      }
    }
  }

  // 将房间和区域匹配
  function roomMatchZone(roomInfo, zoneInfo, config) {
    const key = config['key']
    let setDefault = true
    if (analysis.jsonKeyIsExist(config, ['setDefault'])) {
      setDefault = config['setDefault']
    }

    let zeroId = ''
    if (analysis.jsonKeyIsExist(zero, ['areaid'])) {
      zeroId = zero['areaid']
    }
    if (zeroId == '') {
      return
    }
    for (let i = 0; i < roomInfo.length; i++) {
      const room = roomInfo[i]
      if (setDefault) {
        room[key] = zeroId
      }
      // const polygon = analysis.getPolygonAreaCenter(room.areas)
      let polygon = []
      let x = null;
      let z = null;
      for (let j = 0; j < room.areas.length; j++) {
        let p = room.areas[j];
        if (p.length != 3) {
          continue;
        }
        let px = p[0];
        let pz = p[2];

        x = (x == null ? px : (x + px))
        z = (z == null ? pz : (z + pz))
      }
      if (x != null && z != null && room.areas.length > 0) {
        polygon = [z / room.areas.length, x / room.areas.length];
      }
      if (polygon.length == 0) {
        continue;
      }
      for (let j = 0; j < zoneInfo.length; j++) {
        const zone = zoneInfo[j]
        if (analysis.isInPolygon([polygon[1], 0, polygon[0]], zone['areas']) == true) {
          room[key] = zone['areaid']
          room['zoneTitle'] = zone['title']
          break
        }
      }
    }
  }

  // 将extraInfo 和 chartLet 分离
  function divideExtra(info) {
    const chartLet = info['chartLetInfo']
    const extraInfo = info['extraInfo']
    const mapinfo = info['mapinfo']
    for (let i = 0; i < chartLet.length; i++) {
      const chart = chartLet[i]
      chart['chartLet'] = '1' // 贴图层标示
      if (analysis.jsonKeyIsExist(chart, ['EX'])) {
        extraInfo.push(chart)
      } else {
        mapinfo.push(chart)
      }
    }
  }

  function correctExtraHeight(roomInfos) {
    function getRoomPosition(roomInfos, shouldTask) {
      const newRoomInfos = []
      const checkIndex = []
      for (let i = 0; i < roomInfos.length; i++) {
        const big = roomInfos[i]
        if (checkIndex.indexOf(i) >= 0) {
          continue
        }
        for (let j = 0; j < roomInfos.length; j++) {
          if (i == j) {
            continue
          }
          if (checkIndex.indexOf(j) >= 0) {
            continue
          }
          const small = roomInfos[j]
          let isIn = true
          for (let k = 0; k < small['areas'].length; k++) {
            const p = small['areas'][k]
            if (analysis.isInPolygon(p, big['areas'], true) == false) {
              isIn = false
              break
            }
          }
          if (isIn == false) {
            continue;
          }
          let bigAreaid = big['areaid']
          if (small['areaid'] == bigAreaid) {
            continue;
          }
          let judgeY = 0
          if (analysis.jsonKeyIsExist(small, ['judgeY'])) {
            judgeY = small['judgeY']
          }
          judgeY = judgeY + big['height']
          small['judgeY'] = judgeY

          let subs = []
          if (analysis.jsonKeyIsExist(big, ['subs'])) {
            subs = big['subs']
          } else {
            big['subs'] = subs
          }

          subs.push(small)
          checkIndex.push(j)
          if (shouldTask) {
            small['isIn'] = bigAreaid
          }
          small['parentID'] = bigAreaid

        }
        newRoomInfos.push(big)
        checkIndex.push(i)
      }
      return newRoomInfos
    }

    function returnNewRoom(roomList) {
      for (let i = 0; i < roomList.length; i++) {
        const r = roomList[i]
        if (analysis.jsonKeyIsExist(r, ['subs']) == false) {
          continue
        }
        const subs = r['subs']
        const l = getRoomPosition(subs, false)
        if (subs.length != l.length) {
          r['subs'] = l
          returnNewRoom(l)
        }
      }
    }

    const nL = getRoomPosition(roomInfos, true)
    returnNewRoom(nL)
    return nL
  }

  function judgeCoverInfo(transfrom, list) {
    for (let i = 0; i < list.length; i++) {
      judgeNameInfo(transfrom, list[i], 'cover')
    }
  }

  function judgeNameInfo(transfrom, list, key) {
    let layerX = 0
    let layerZ = 0
    let nameX = 0
    let nameZ = 0
    const layer = analysis.jsonKeyIsExist(transfrom, ['layer'])
    const name = analysis.jsonKeyIsExist(transfrom, [key])
    if (layer == false && name == false) {
      return
    }
    const reg = new RegExp('\\d+(\\.\\d+)?', 'gmi')

    if (layer) {
      const layerStr = transfrom['layer']
      const rep = layerStr.match(reg)
      for (let i = 0; i < rep.length; i++) {
        if (i == 0) {
          layerX = parseFloat(rep[i])
        } else {
          layerZ = parseFloat(rep[i])
        }
      }
    }

    if (name) {
      const nameStr = transfrom[key]
      const matchTransMatrix = new RegExp('matrix\\((-?[0-9]+\\.?[0-9]?\\s?,?)+\\)', 'gmi')
      const matchRep = nameStr.match(matchTransMatrix)
      if (matchRep) {
        const getRep = new RegExp('matrix\\((.*)\\)', 'gmi')
        const matrixStr = getRep.exec(nameStr)[1].trim()
        let matrixArr = matrixStr.split(' ')
        if (matrixArr.length == 1) {
          matrixArr = matrixStr.split(',')
        }
        if (matrixArr.length == 6) {
          const a = parseFloat(matrixArr[0])
          const b = parseFloat(matrixArr[1])
          const c = parseFloat(matrixArr[2])
          const d = parseFloat(matrixArr[3])
          const e = parseFloat(matrixArr[4])
          const f = parseFloat(matrixArr[5])
          for (let i = 0; i < list.length; i++) {
            const areas = list[i].areas
            for (let j = 0; j < areas.length; j++) {
              const p = areas[j]
              const x = p[0] * a + p[2] * c + e
              const y = p[0] * b + p[2] * d + f
              p[0] = x
              p[2] = y
            }
          }
        }
        return
      }
      const nameRep = nameStr.match(reg)
      for (let i = 0; i < nameRep.length; i++) {
        if (i == 0) {
          nameX = parseFloat(nameRep[i])
        } else {
          nameZ = parseFloat(nameRep[i])
        }
      }
    }

    for (let i = 0; i < list.length; i++) {
      const info = list[i]
      const areas = info.areas
      for (let j = 0; j < areas.length; j++) {
        const p = areas[j]
        p[0] = p[0] - layerX + nameX
        p[2] = p[2] - layerZ + nameZ
      }
    }
  }

  // 多层级文字显示
  function getCoverNameInfo(childjson, svgJosn) {
    const nodeChild = childjson['children']
    const coverInfo = []
    for (const nodeKey in nodeChild) {
      const node = nodeChild[nodeKey]
      const childNodeName = node['nodeName']
      if (childNodeName == 'g') {
        const texts = findNameLayer(node, svgJosn)
        coverInfo.push(texts)
      }
    }
    return coverInfo
  }

  function findNameLayer(childjson, svgJosn) {
    let mapid = '';
    if (analysis.jsonKeyIsExist(svgJosn, ['mapid'])) {
      mapid = svgJosn['mapid']
    }
    // 找到g节点
    const nodeChild = childjson['children']
    const rooms = []
    for (const nodeKey in nodeChild) {
      const node = nodeChild[nodeKey]
      const childNodeName = node['nodeName']
      if (childNodeName == 'rect') {
        const outerHTML = node['outerHTML']
        dealWithRectOuterHTML(outerHTML, rooms, mapid)
      }
      if (childNodeName == 'circle' || childNodeName == 'path' || childNodeName == 'ellipse' || childNodeName ==
        'polygon' || childNodeName == 'polyline') {
        const outerHTML = node['outerHTML']
        dealWithPathOuterHTML(outerHTML, rooms, childNodeName)
      }
    }
    return rooms
  }

  function setMapid(mapid, id) {
    let mapidStr = mapid.replace(/\)/g, '\\)')
    mapidStr = mapidStr.replace(/\(/g, '\\(')
    mapidStr = mapidStr.replace(/\./g, '\\.')
    const m = eval('/' + mapidStr + '/')
    const match = id.match(m)
    if (match == null) {
      id = mapid + id
    }
    return id
  }

  // 导航线
  function getLeadLine(childjson, svgJosn, fileName) {


    // function linkTwoPoint(data, lastdata, fileName, leadIdInfo) {
    //   if (lastdata != null) {
    //     let lastX = lastdata[0].toFixed(1);
    //     let lastZ = lastdata[2].toFixed(1);
    //     let key = lastX + '~' + lastZ
    //     let lastKey = lastX + '~' + lastZ;
    //     lastKey = setMapid(fileName, lastKey);
    //     if (typeof leadInfo[lastKey] != 'undefined') {
    //       let lastData = leadInfo[lastKey];
    //       matrix.linkTwoPoint(lastData, data)
    //     }
    //   }
    // }

    function linkTwoPoint(data, lastdata, fileName, leadIdInfo) {
      if (lastdata != null) {
        const lastX = lastdata[0].toFixed(2)
        const lastZ = lastdata[2].toFixed(2)
        const key = lastX + '~' + lastZ
        let lastKey = ''
        if (typeof leadIdInfo[key] != 'undefined') {
          lastKey = leadIdInfo[key].id
        } else {
          lastKey = key
        }
        lastKey = setMapid(fileName, lastKey)
        if (typeof leadInfo[lastKey] != 'undefined') {
          const lastData = leadInfo[lastKey]
          matrix.linkTwoPoint(lastData, data)
        }
      }
    }

    function pushPoint(x, y, areas, info) {
      const newX = parseFloat(x.toFixed(0))
      const newY = parseFloat(y.toFixed(0))
      const key = newX + ' ~ ' + newY
      if (analysis.jsonKeyIsExist(info, [key]) == false) {
        areas.push([newX, 0, newY])
        info[key] = '1'
      }
    }

    function createNewRooms(points, rooms) {
      const info = {}
      const newRooms = []
      for (let i = 0; i < rooms.length; i++) {
        const p = rooms[i]
        const key = i + ''
        if (analysis.jsonKeyIsExist(points, [key])) {
          const list = points[key]
          for (let j = 0; j < list.length; j++) {
            const res = list[j].res
            pushPoint(res.x, res.y, newRooms, info)
          }
        }
        pushPoint(p[0], p[2], newRooms, info)
      }
      return newRooms
    }

    function sortInfoList(info, valueKey) {
      for (const key in info) {
        const list = info[key]
        const newList = list.sort((a, b) => {
          const index1 = a.index
          const index2 = b.index
          if (index1 != index2) {
            return index1 - index2
          }
          const n1 = a.res[valueKey]
          const n2 = b.res[valueKey]
          return n1 - n2
        })
        info[key] = newList
      }
    }

    function getAreasLine(areas1, areas2, close1, close2) {
      const point1 = {}
      const point2 = {}
      for (let i = 0; i < areas1.length; i++) {
        const index_i = i
        let lastIndex_i = i - 1
        if (lastIndex_i < 0) {
          lastIndex_i = areas1.length - 1
        }
        const p_i = areas1[index_i]
        const p_last_i = areas1[lastIndex_i]
        const x1 = p_i[0]
        const y1 = p_i[2]
        const x2 = p_last_i[0]
        const y2 = p_last_i[2]
        if (close1 == false && i == 0) {
          continue
        }
        for (let j = 0; j < areas2.length; j++) {
          const index_j = j
          let lastIndex_j = j - 1
          if (lastIndex_j < 0) {
            lastIndex_j = areas2.length - 1
          }
          const p_j = areas2[index_j]
          const p_last_j = areas2[lastIndex_j]
          const x3 = p_j[0]
          const y3 = p_j[2]
          const x4 = p_last_j[0]
          const y4 = p_last_j[2]
          if (close2 == false && j == 0) {
            continue
          }
          const result = analysis.getLinesIntersect(x2, y2, x1, y1, x4, y4, x3, y3)
          if (result.result) {
            // 相交的点
            let list1 = []
            const key1 = i + ''
            let list2 = []
            const key2 = j + ''
            if (analysis.jsonKeyIsExist(point1, [key1])) {
              list1 = point1[key1]
            } else {
              point1[key1] = list1
            }

            if (analysis.jsonKeyIsExist(point2, [key2])) {
              list2 = point2[key2]
            } else {
              point2[key2] = list2
            }
            list1.push({
              index: i,
              res: result
            })
            list2.push({
              index: j,
              res: result
            })
          }
        }
      }
      sortInfoList(point1, 'res1')
      sortInfoList(point2, 'res2')
      const newAreas1 = createNewRooms(point1, areas1)
      const newAreas2 = createNewRooms(point2, areas2)
      return {
        areas1: newAreas1,
        areas2: newAreas2
      }
    }

    // 找到g节点
    const nodeChild = childjson['children']
    const rooms = []
    for (const nodeKey in nodeChild) {
      const node = nodeChild[nodeKey]
      const childNodeName = node['nodeName']
      if (childNodeName == 'circle' || childNodeName == 'path' || childNodeName == 'ellipse' || childNodeName ==
        'polygon' || childNodeName == 'polyline') {
        const outerHTML = node['outerHTML']
        dealWithPathOuterHTML(outerHTML, rooms, childNodeName)
      }
    }

    for (let i = 0; i < rooms.length; i++) {
      const room1 = rooms[i]
      const close1 = room1['close']
      const room2 = rooms[i]
      const close2 = room2['close']
      const areas1 = room1['areas']
      const areas2 = room2['areas']
      const res = getAreasLine(areas1, areas2, close1, close2)
      room1['areas'] = res.areas1
      room2['areas'] = res.areas2
    }

    for (let i = 0; i < rooms.length; i++) {
      const room1 = rooms[i]
      const close1 = room1['close']
      for (let j = i + 1; j < rooms.length; j++) {
        const room2 = rooms[j]
        const close2 = room2['close']
        const areas1 = room1['areas']
        const areas2 = room2['areas']
        const res = getAreasLine(areas1, areas2, close1, close2)
        room1['areas'] = res.areas1
        room2['areas'] = res.areas2
      }
    }

    const leadIdInfo = {}
    for (let i = 0; i < rooms.length; i++) {
      const info = rooms[i]
      const areas = info['areas']
      if (typeof areas == 'undefined' || areas.length == 0) {
        continue
      }
      for (let j = 0; j < areas.length; j++) {
        const p = areas[j]
        const x = p[0].toFixed(2)
        const z = p[2].toFixed(2)
        const index = Object.keys(leadIdInfo).length
        const key = x + '~' + z
        if (typeof leadIdInfo[key] == 'undefined') {
          leadIdInfo[key] = {
            key: key,
            id: index + ''
          }
        }
      }
    }

    const leadCheck = {}
    for (let i = 0; i < rooms.length; i++) {
      const info = rooms[i]
      const areas = info['areas']
      const close = info['close']
      if (typeof areas == 'undefined' || areas.length == 0) {
        continue
      }
      // 将线上的点连接
      for (let j = 0; j < areas.length; j++) {
        const p = areas[j]
        const x = p[0].toFixed(2)
        const z = p[2].toFixed(2)
        const findKey = x + '~' + z
        let key = ''
        if (typeof leadIdInfo[findKey] != 'undefined') {
          key = leadIdInfo[findKey].id
        } else {
          key = findKey
        }
        key = setMapid(fileName, key)
        // 与上一个相连接
        let last = j - 1
        let lastInfo = null
        if (last < 0 && close == true) {
          last = areas.length - 1
        }
        if (last < areas.length && last >= 0) {
          lastInfo = areas[last]
        }
        if (typeof leadInfo[key] == 'undefined') {
          const store = {
            INDEX: i,
            index: j
          }
          leadCheck[key] = store
          const data = {
            id: key,
            x: p[0],
            y: p[2],
            title: '',
            type: floorLinkType.normal,
            url: '' // 存在实景图url
          }
          linkTwoPoint(data, lastInfo, fileName, leadIdInfo)
          leadInfo[key] = data
        } else {
          const data = leadInfo[key]
          linkTwoPoint(data, lastInfo, fileName, leadIdInfo)
        }
        p[0] = parseFloat(x)
        p[2] = parseFloat(z)
      }
    }

    const copyLeadInfo = JSON.parse(JSON.stringify(leadInfo))
    for (const key in copyLeadInfo) {
      const info = copyLeadInfo[key]
      if (info.type != 0) {
        continue
      }
      if (analysis.jsonKeyIsExist(info, ['linkPoint']) == false) {
        delete leadInfo[key]
        continue
      }
      const linkPoint = info['linkPoint']
      const count = Object.keys(linkPoint).length
      if (count == 0) {
        delete leadInfo[key]
        continue
      }
      if (count != 1) {
        continue
      }
      let shouldCut = false
      let linkId = ''
      for (const linkKey in linkPoint) {
        const linkInfo = linkPoint[linkKey]
        if (linkInfo.dis <= 7) {
          shouldCut = true
          linkId = linkKey
        }
      }
      if (shouldCut == false || linkId == '' || analysis.jsonKeyIsExist(leadInfo, [linkId]) == false) {
        continue
      }
      delete leadInfo[key]
      const findInfo = leadInfo[linkId]
      if (findInfo.type != 0) {
        continue
      }
      if (analysis.jsonKeyIsExist(findInfo, ['linkPoint']) == false) {
        delete leadInfo[linkId]
        continue
      }
      const findLinkPoint = findInfo['linkPoint']
      delete findLinkPoint[key]
      const endCount = Object.keys(findLinkPoint).length
      if (endCount == 0) {
        delete leadInfo[linkId]
      }
    }

    // for (let i = 0; i < rooms.length; i++) {
    //   let info = rooms[i];
    //   let areas = info['areas'];
    //   let close = info['close'];
    //   if (typeof areas == 'undefined' || areas.length == 0) {
    //     continue;
    //   }
    //   // 将线上的点连接
    //   for (let j = 0; j < areas.length; j++) {
    //     let p = areas[j];
    //     let x = p[0].toFixed(1);
    //     let z = p[2].toFixed(1);
    //     let findKey = x + '~' + z;
    //
    //     let key = x + '~' + z;
    //     key = setMapid(fileName, key);
    //     // 与上一个相连接
    //     let last = j - 1;
    //     let lastInfo = null;
    //     if (last < 0 && close == true) {
    //       last = areas.length - 1;
    //     }
    //     if (last < areas.length && last >= 0) {
    //       lastInfo = areas[last];
    //     }
    //     if (typeof leadInfo[key] == 'undefined') {
    //       let store = {
    //         INDEX: i,
    //         index: j,
    //       }
    //       leadCheck[key] = store;
    //       let data = {
    //         id: key,
    //         x: parseFloat(x),
    //         y: parseFloat(z),
    //         title: '',
    //         type: floorLinkType.normal,
    //         url: '', // 存在实景图url
    //       }
    //       linkTwoPoint(data, lastInfo, fileName)
    //       leadInfo[key] = data
    //     } else {
    //       let data = leadInfo[key];
    //       linkTwoPoint(data, lastInfo, fileName)
    //     }
    //     p[0] = parseFloat(x);
    //     p[2] = parseFloat(z);
    //   }
    // }
  }

  // 绘制房间
  function drawLayer(childjson, svgJosn) {
    let mapid = '';
    if (analysis.jsonKeyIsExist(svgJosn, ['mapid'])) {
      mapid = svgJosn['mapid']
    }
    // 找到g节点
    const nodeChild = childjson['children']
    const rooms = []
    const images = []
    for (const nodeKey in nodeChild) {
      const node = nodeChild[nodeKey]
      const childNodeName = node['nodeName']
      if (childNodeName == 'rect') {
        const outerHTML = node['outerHTML']
        dealWithRectOuterHTML(outerHTML, rooms, mapid)
      }
      if (childNodeName == 'circle' || childNodeName == 'path' || childNodeName == 'ellipse' || childNodeName ==
        'polygon' || childNodeName == 'polyline') {
        const outerHTML = node['outerHTML']
        dealWithPathOuterHTML(outerHTML, rooms, childNodeName)
      }
      if (childNodeName == 'image') {
        // 图片
        const outerHTML = node['outerHTML']
        dealWithImageDataHTML(outerHTML, images)
      }
    }
    pushImageToroom(rooms, images)
    svgJosn['mapinfo']['mapinfo'] = rooms
  }

  // 将只显示的房间的数据和用于计算的房间的数据分开
  function splitMapInfo(json) {
    const newMapInfos = []
    const showMapInfos = []
    const mapInfo = json['mapinfo']['mapinfo']
    for (let i = 0; i < mapInfo.length; i++) {
      const room = mapInfo[i]
      if (jsonKeyIsExist(room, ['hidden']) || jsonKeyIsExist(room, ['once'])) {
        showMapInfos.push(room)
      } else {
        newMapInfos.push(room)
      }
    }
    json['mapinfo']['mapinfo'] = newMapInfos
    json['mapinfo']['show'] = showMapInfos
  }

  function findZero(rooms) {
    if (rooms.mapinfo.length == 0) {
      return
    }
    for (let i = 0; i < rooms.mapinfo.length; i++) {
      const info = rooms.mapinfo[i]
      if (analysis.jsonKeyIsExist(info, ['zero'])) {
        zero = info
        return
      }
    }
    zero = rooms[0]
  }

  // 没有title的则定位无效
  function findInvaildFence(rooms) {
    for (let i = rooms.length - 1; i >= 0; i--) {
      const room = rooms[i]
      if (i == 0) {
        room['invaild'] = '0'
        continue
      }
      let invaild = '1'
      if (analysis.jsonKeyIsExist(room, ['areaid']) == false) {
        room['areaid'] = generateUUID()
      }
      if (analysis.jsonKeyIsExist(room, ['title']) == false) {
        room['title'] = ''
      }
      const areaid = room['areaid']
      const title = room['title']
      for (const key in leadInfo) {
        const data = leadInfo[key]
        const inMapId = data['inMapId']
        if (typeof inMapId == 'undefined' || inMapId == null) {
          continue
        }
        if (inMapId == areaid || inMapId == title) {
          const areas = room['areas']
          if (analysis.isInPolygon([parseFloat(data.x), 0, parseFloat(data.y)], areas) == true) {
            invaild = '0'
            break
          }
        }
      }
      if (invaild == '1') {
        room['title'] = ''
      }
      room['invaild'] = invaild
    }
  }

  // 找出连接点落在地图的房间
  function setLeadCubeMapInfoId(rooms) {
    for (const key in leadInfo) {
      const data = leadInfo[key]
      for (let i = rooms.length - 1; i >= 0; i--) {
        const room = rooms[i]
        const id = room['areaid']
        const areas = room['areas']
        if (analysis.isInPolygon([parseFloat(data.x), 0, parseFloat(data.y)], areas) == true) {
          data['inMapId'] = id
          break
        }
      }
    }
  }

  function pushImageToroom(rooms, images) {
    for (let i = 0; i < images.length; i++) {
      for (let j = rooms.length - 1; j >= 0; j--) {
        const room = rooms[j]
        // let type = room['type']
        // if (type == '1') {
        //   continue
        // }
        const image = images[i]
        const areas = room['areas']
        const x = parseFloat(image['x'])
        const y = parseFloat(image['y'])
        if (analysis.isInPolygon([x, 0, y], areas) == true) {
          // 在该区域
          room['image'] = image['data']
          break
        }
      }
    }
  }

  function xiangduiDingWei(pointStrs, areas, lastx, lasty) {
    const x = (parseFloat(pointStrs[0]) + lastx).toFixed(7)
    const y = (parseFloat(pointStrs[1]) + lasty).toFixed(7)
    areas.push([parseFloat((x)), 0, parseFloat((y))])
    return [parseFloat(x), parseFloat(y)]
  }

  function jueduiDingWei(pointStrs, areas) {
    const x = (parseFloat(pointStrs[0])).toFixed(7)
    const y = (parseFloat(pointStrs[1])).toFixed(7)
    areas.push([parseFloat((x)), 0, parseFloat((y))])
    return [parseFloat(x), parseFloat(y)]
  }

  // 计算圆和椭圆的点数
  function PointInArc(arcs, num) {
    const cx = arcs[0]
    const cy = arcs[1]
    const rx = arcs[2]
    const ry = arcs[3]
    const point = PointAtArc(0, Math.PI * 2, false, cx, cy, rx, ry)
    return point
  }

  // 使用角度计算圆和椭圆
  function PointAtArc(startAngle, endAngle, Clockwise /* 是否顺时针 */, cx, cy, rx, ry) {
    if (startAngle == endAngle) {
      return
    }
    const d = 5.0
    const count = 30.0
    const space = Math.abs((startAngle - endAngle) * 1.0 / count)
    const points = []
    for (let i = 0; i <= count; i++) {
      let angle = 0
      if (Clockwise == false) /* 逆时针 */ {
        angle = parseFloat((startAngle + i * space).toFixed(d))
        if (angle > parseFloat(endAngle.toFixed(d))) {
          angle = parseFloat(endAngle.toFixed(d))
        }
      } else {
        /* 顺时针 */
        angle = parseFloat((startAngle - i * space).toFixed(d))
        if (angle < 0) {
          angle = parseFloat((Math.PI * 2 + angle).toFixed(d))
          if (angle < endAngle) {
            angle = endAngle
          }
        }
      }
      const x_2 = rx * rx * ry * ry / (ry * ry + rx * rx * Math.tan(angle) * Math.tan(angle))
      let x = 0
      let y = 0
      if ((angle >= 0 && angle < parseFloat((Math.PI / 2).toFixed(d))) || (angle > parseFloat((Math.PI * 3 / 2).toFixed(
        d)) && angle <= parseFloat((Math.PI * 2).toFixed(d)))) {
        x = Math.sqrt(x_2)
        y = x * Math.tan(angle)
        points.push([x + cx, 0, y + cy])
      } else if (angle > parseFloat((Math.PI / 2).toFixed(d)) && angle < parseFloat((Math.PI * 3 / 2).toFixed(d))) {
        x = -Math.sqrt(x_2)
        y = x * Math.tan(angle)
        points.push([x + cx, 0, y + cy])
      } else if (angle == parseFloat((Math.PI / 2).toFixed(d))) {
        x = 0
        y = ry
        points.push([x + cx, 0, y + cy])
      } else if (angle == parseFloat((Math.PI * 3 / 2).toFixed(d))) {
        x = 0
        y = -ry
        points.push([x + cx, 0, y + cy])
      } else {
        console.log('else' + angle * 180 / Math.PI)
      }
    }
    return points
  }

  // anchorpoints：贝塞尔基点
  // pointsAmount：生成的点数
  // return 路径点的Array
  function PointOnCubicBezier(cp, t) {
    let ax, bx, cx
    let ay, by, cy
    let tSquared, tCubed
    /* 計算多項式係數*/
    cx = 3.0 * (cp[1][0] - cp[0][0])
    bx = 3.0 * (cp[2][0] - cp[1][0]) - cx
    ax = cp[3][0] - cp[0][0] - cx - bx
    cy = 3.0 * (cp[1][2] - cp[0][2])
    by = 3.0 * (cp[2][2] - cp[1][2]) - cy
    ay = cp[3][2] - cp[0][2] - cy - by
    /* 計算位於參數值t的曲線點*/
    tSquared = t * t
    tCubed = tSquared * t
    const x = (ax * tCubed) + (bx * tSquared) + (cx * t) + cp[0][0]
    const y = (ay * tCubed) + (by * tSquared) + (cy * t) + cp[0][2]
    return [parseFloat(x.toFixed(2)), 0, parseFloat(y.toFixed(2))]
  }

  function ellipseAtPoint(json) {
    const cx = json['sodipodi:cx']
    const cy = json['sodipodi:cy']
    const rx = json['sodipodi:rx']
    const ry = json['sodipodi:ry']
    const start = json['sodipodi:start']
    const end = json['sodipodi:end']
    if (cx == null || cy == null || rx == null || ry == null || start == null || end == null) {
      return
    }
    const point = PointAtArc(start, end, false, cx, cy, rx, ry)
    const open = json['sodipodi:open']
    if (open == 'true') {

    } else {
      point.push([cx, 0, cy])
    }
    return point
  }

  // 24.327675,24.21875 0 0 0 -19.93945,23.810549
  function dealWithA(i, paths, LastLink, lastX, lastY) {
    let rx_ry = ''
    let rotation = ''
    let hudu = ''
    let clockwise = ''
    let end = ''

    // if (LastLink == "A") {
    //   rx_ry = paths[i] + "," + paths[i + 1];
    //   rotation = paths[i + 2];
    //   hudu = paths[i + 3];
    //   clockwise = paths[i + 4];
    //   end = paths[i + 5] + "," + paths[i + 6];
    // } else {
    //   rx_ry = paths[i];
    //   rotation = paths[i + 1];
    //   hudu = paths[i + 2];
    //   clockwise = paths[i + 3];
    //   end = paths[i + 4];
    // }
    rx_ry = paths[i]
    rotation = paths[i + 1]
    hudu = paths[i + 2]
    clockwise = paths[i + 3]
    end = paths[i + 4]

    const str = rx_ry + ' ' + rotation + ' ' + hudu + ' ' + clockwise + ' ' + end
    const reg = /\-?[0-9]+.?[0-9]+,-?[0-9]+.?[0-9]+ -?[0-9]+.?[0-9]? [0-1]+ [0-1]+ -?[0-9]+.?[0-9]+,-?[0-9]+.?[0-9]+/
    let endX = 0
    let endY = 0
    const result = reg.test(str)
    if (result == true) {
      const endPoints = end.split(',')
      const radius = rx_ry.split(',')
      const x = parseFloat(endPoints[0])
      const y = parseFloat(endPoints[1])
      const rx = parseFloat(radius[0])
      const ry = parseFloat(radius[1])
      endX = x + (LastLink == 'A' ? 0 : lastX)
      endY = y + (LastLink == 'A' ? 0 : lastY)
      const info = findArcCenter(lastX, lastY, endX, endY, rx, ry, parseFloat(clockwise), parseFloat(hudu))
      if (info[2] == true) {
        const areas = findApathAear(lastX, lastY, endX, endY, rx, ry, parseFloat(clockwise), parseFloat(hudu), info[0],
          info[1])
        return [parseFloat(endX.toFixed(2)), parseFloat(endY.toFixed(2)), result, areas]
      }
    }
    return [parseFloat(endX.toFixed(2)), parseFloat(endY.toFixed(2)), result, []]
  }

  function findApathAear(x1, y1, x2, y2, rx, ry, clockwise, hudu, cx, cy) {
    const line1 = [parseFloat((x1 - cx).toFixed(3)), parseFloat((y1 - cy).toFixed(3))]
    const inital = [1, 0]
    const cos1 = (line1[0] * inital[0] + line1[1] * inital[1]) / ((Math.sqrt(line1[0] * line1[0] + line1[1] * line1[1])) *
      Math.sqrt(1))
    let arccos1 = Math.acos(cos1)
    if (line1[1] < 0) {
      arccos1 = Math.PI * 2 - arccos1
    }

    const line2 = [parseFloat((x2 - cx).toFixed(3)), parseFloat((y2 - cy).toFixed(3))]
    const cos2 = (line2[0] * inital[0] + line2[1] * inital[1]) / ((Math.sqrt(line2[0] * line2[0] + line2[1] * line2[1])) *
      Math.sqrt(1))
    let arccos2 = Math.acos(cos2)
    if (line2[1] < 0) {
      arccos2 = Math.PI * 2 - arccos2
    }

    let clock = false // 逆时针
    if (clockwise == 1) {

    } else {
      clock = !clock
    }
    const point = PointAtArc(arccos1, arccos2, clock, cx, cy, rx, ry)
    return point
  }

  // 寻找圆心
  function findArcCenter(x1, y1, x2, y2, rx, ry, clockwise, hudu) {
    function radian(ux, uy, vx, vy) {
      const dot = ux * vx + uy * vy
      const mod = Math.sqrt((ux * ux + uy * uy) * (vx * vx + vy * vy))
      let rad = Math.acos(dot / mod)
      if (ux * vy - uy * vx < 0.0) rad = -rad
      return rad
    }

    // conversion_from_endpoint_to_center_parameterization
    // sample :  convert(200,200,300,200,1,1,50,50,0,{})
    function convert(x1, y1, x2, y2, fA, fS, rx, ry, phi) {
      let cx, cy, theta1, delta_theta

      if (rx == 0.0 || ry == 0.0) return -1 // invalid arguments

      const s_phi = Math.sin(phi)
      const c_phi = Math.cos(phi)
      const hd_x = (x1 - x2) / 2.0 // half diff of x
      const hd_y = (y1 - y2) / 2.0 // half diff of y
      const hs_x = (x1 + x2) / 2.0 // half sum of x
      const hs_y = (y1 + y2) / 2.0 // half sum of y

      // F6.5.1
      const x1_ = c_phi * hd_x + s_phi * hd_y
      const y1_ = c_phi * hd_y - s_phi * hd_x

      const rxry = rx * ry
      const rxy1_ = rx * y1_
      const ryx1_ = ry * x1_
      const sum_of_sq = rxy1_ * rxy1_ + ryx1_ * ryx1_ // sum of square
      let coe = Math.sqrt((rxry * rxry - sum_of_sq) / sum_of_sq)
      if (fA == fS) coe = -coe

      // F6.5.2
      const cx_ = coe * rxy1_ / ry
      const cy_ = -coe * ryx1_ / rx

      // F6.5.3
      cx = c_phi * cx_ - s_phi * cy_ + hs_x
      cy = s_phi * cx_ + c_phi * cy_ + hs_y

      const xcr1 = (x1_ - cx_) / rx
      const xcr2 = (x1_ + cx_) / rx
      const ycr1 = (y1_ - cy_) / ry
      const ycr2 = (y1_ + cy_) / ry

      // F6.5.5
      theta1 = radian(1.0, 0.0, xcr1, ycr1)

      // F6.5.6
      delta_theta = radian(xcr1, ycr1, -xcr2, -ycr2)
      const PIx2 = Math.PI * 2.0
      while (delta_theta > PIx2) delta_theta -= PIx2
      while (delta_theta < 0.0) delta_theta += PIx2
      if (fS == false) delta_theta -= PIx2

      const outputObj = {
        /* cx, cy, theta1, delta_theta */
        cx: cx,
        cy: cy,
        theta1: theta1,
        delta_theta: delta_theta
      }
      return outputObj
    }

    const obj = convert(x1, y1, x2, y2, hudu, clockwise, rx, ry, 0)
    return [obj.cx, obj.cy, true]
  }

  function dealWithPathOuterHTML(outerHTML, rooms, tagName) {
    const parser = new DOMParser(outerHTML)
    const xmlDoc = parser.parseFromString(outerHTML, 'text/xml')
    const countrys = xmlDoc.getElementsByTagName(tagName)
    if (countrys.length <= 0) {
      return
    }
    const obj = countrys[0]
    const json = {}
    json['layerHeight'] = 1
    json['title'] = ''
    json['image'] = ''
    json['titleLV'] = 3
    json['color'] = '#ffffff'
    const pathInfo = [] // 一个path类对应多个path
    const pathClose = []
    const attributes = obj['attributes']
    const arcsJson = {}
    const transform = {
      result: false
    }
    for (const key in attributes) {
      const node = attributes[key]
      const nodeName = node['nodeName']
      let nodeValue = node['nodeValue']
      switch (nodeName) {
        case 'transform':
          const matchTransMatrix = new RegExp('matrix\\((-?[0-9]+e?-?[0-9]?\\.?[0-9]?\\s?)+\\)', 'gmi')
          const matchRep = nodeValue.match(matchTransMatrix)
          if (matchRep) {
            const getRep = new RegExp('matrix\\((.*)\\)', 'gmi')
            const res = getRep.exec(nodeValue)[1].trim()
            transform.result = true
            transform.matrix = res
          }
          break
        case 'cross':
          json['cross'] = nodeValue
          break
        case 'once':
          json['once'] = nodeValue
          break
        case 'moveY':
          json['moveY'] = nodeValue
          break
        case 'hidden':
          json['hidden'] = nodeValue
          break
        case 'zero':
          json['zero'] = nodeValue
          break
        case 'titleLV':
          json[nodeName] = parseInt(nodeValue)
          break
        case 'title':
          json[nodeName] = nodeValue
          break
        case 'EX':
          json[nodeName] = nodeValue
          break
        case 'layerHeight':
          json[nodeName] = parseFloat(nodeValue)
          break
        case 'sodipodi:type':
        case 'sodipodi:open':
          arcsJson[nodeName] = nodeValue
          break
        case 'r':
          arcsJson['rx'] = parseFloat(parseFloat(nodeValue).toFixed(2))
          arcsJson['ry'] = parseFloat(parseFloat(nodeValue).toFixed(2))

          break
        case 'cx':
        case 'ry':
        case 'cy':
        case 'rx':
        case 'sodipodi:cx':
        case 'sodipodi:cy':
        case 'sodipodi:end':
        case 'sodipodi:rx':
        case 'sodipodi:ry':
        case 'sodipodi:start':
          arcsJson[nodeName] = parseFloat(parseFloat(nodeValue).toFixed(3))
          break
        case 'style':
          const strs = nodeValue.split(';')
          for (let i = 0; i < strs.length; i++) {
            const value = strs[i]
            const values = value.split(':')
            const name = values[0]
            const nameValue = values[1]
            if (name == 'fill') {
              json['color'] = nameValue
              break
            }
          }
          break
        case 'points':
          const ptype = attributes['sodipodi:type']
          if (ptype == 'arc') {
            continue
          }
          const ppaths = nodeValue.split(' ')
          if (ppaths.length == 1) {
            return
          }
          const pareas = []
          for (let i = 0; i < ppaths.length; i++) {
            const pointStr = ppaths[i]
            if (pointStr == '') {
              continue
            }

            const pointStrs = pointStr.split(' ')
            for (const item of pointStrs) {
              // console.log("item")
              // console.log(item)
              const xys = item.split(',')
              // console.log(xys)
              if (xys.length === 2) {
                const x = parseFloat(xys[0]).toFixed(2)
                const z = parseFloat(xys[1]).toFixed(2)
                pareas.push([parseFloat(x), 0, parseFloat(z)])
              }
            }
          }
          pathInfo.push(pareas)
          pathClose.push({
            index: 0,
            close: false
          })
          break
        case 'd':
          const type = attributes['sodipodi:type']
          if (type == 'arc') {
            continue
          }
          nodeValue = nodeValue.trim()
          // nodeValue = nodeValue.replace(/ /g, "");
          let result = ''
          const reg = /^[a-zA-Z]/i
          for (let i = 0; i < nodeValue.length; i++) {
            const char = nodeValue.charAt(i)
            if (reg.test(char)) {
              if (char === 'M' || char === 'm') {
                result += char + ' '
              } else if (char === 'Z' || char === 'm') {
                result += ' ' + char
              } else if (char === 'e') {
                result += char
              } else {
                result += ' ' + char + ' '
              }
            } else {
              result += char
            }
          }
          nodeValue = result.split('  ').join(' ')
          const paths = []
          const list = nodeValue.split(' ')
          const matchReg = new RegExp('[0-9]+-', 'gmi')
          const matchReg_2 = new RegExp('([0-9]+\\.?[0-9]?,)+', 'gmi')
          for (let i = 0; i < list.length; i++) {
            const subStr = list[i]
            const matchRep = subStr.match(matchReg)
            const matchRep_2 = subStr.match(matchReg_2)
            if (matchRep || matchRep_2) {
              const newStr = subStr.replace(/-/g, ',-')
              const strArr = newStr.split(',')
              const checkStrArr = []
              let shouldPush = true
              for (let j = 0; j < strArr.length; j++) {
                const c = strArr[j]
                if (c.search('e') >= 0) {
                  shouldPush = false
                } else {
                  if (shouldPush == false) {
                    const last = j - 1
                    if (last < strArr.length && last >= 0) {
                      const lastC = strArr[last]
                      checkStrArr.push(lastC + c)
                    }
                  } else {
                    checkStrArr.push(c)
                  }
                  shouldPush = true
                }
              }
              let content = []
              for (let j = 0; j < checkStrArr.length; j++) {
                const s = checkStrArr[j]
                if (s != '') {
                  content.push(s)
                  if (content.length == 2) {
                    let newChat = ''
                    content.map((a) => {
                      newChat = newChat == '' ? a : newChat + ',' + a
                    })
                    paths.push(newChat)
                    content = []
                  }
                }
              }
            } else {
              paths.push(subStr)
            }
          }
          let realPath = []
          let lastX = 0
          let lastY = 0
          for (let k = 0; k < paths.length; k++) {
            const chat = paths[k]
            realPath.push(chat)
            let end = false
            let close = true // 是否封闭
            // if (chat == 'z' || chat == 'Z') {
            //   end = true;
            // }
            const index = paths.length - 1
            if (chat == 'z' || chat == 'Z') {
              end = true
            } else if (k == index) {
              end = true
              close = false
            }
            if (end) {
              const res = getPathArea(realPath, lastX, lastY)
              realPath = []
              if (res.result) {
                pathInfo.push(res.paths)
                pathClose.push({
                  index: k,
                  close: close
                })
                lastX = res.lastX
                lastY = res.lastY
              }
            }
          }
          break
        case 'id':
          if (nodeValue == '') {
            json['areaid'] = generateUUID()
          } else {
            json['areaid'] = nodeValue
          }
          break
        default:
          break
      }
    }
    if (arcsJson['cx'] != null && arcsJson['cy'] != null && arcsJson['rx'] != null && arcsJson['ry'] != null) {
      const arr = PointInArc([arcsJson['cx'], arcsJson['cy'], arcsJson['rx'], arcsJson['ry']], 25)
      pathInfo.push(arr)
    }

    const type = arcsJson['sodipodi:type']
    if (type == 'arc') {
      json['areas'] = ellipseAtPoint(arcsJson)
    }

    const layerHeight = json['layerHeight']
    json['height'] = layerHeight

    if (pathInfo.length > 0) {
      for (let i = 0; i < pathInfo.length; i++) {
        const newAreas = pathInfo[i]
        if (newAreas.length <= 1) {
          continue
        }
        let close = true
        if (i < pathClose.length && typeof pathClose[i] != 'undefined') {
          const closeInfo = pathClose[i]
          close = closeInfo.close
        }
        if (transform.result) {
          const matrixStr = transform.matrix
          const matrixArr = matrixStr.split(' ')
          if (matrixArr.length == 6) {
            const a = parseFloat(matrixArr[0])
            const b = parseFloat(matrixArr[1])
            const c = parseFloat(matrixArr[2])
            const d = parseFloat(matrixArr[3])
            const e = parseFloat(matrixArr[4])
            const f = parseFloat(matrixArr[5])
            for (let i = 0; i < newAreas.length; i++) {
              const p = newAreas[i]
              const x = p[0] * a + p[2] * c + e
              const y = p[0] * b + p[2] * d + f
              p[0] = x
              p[2] = y
            }
          }
        }
        if (newAreas.length > 0) {
          const newJson = JSON.parse(JSON.stringify(json))
          newJson['areas'] = newAreas
          newJson['close'] = close
          if (analysis.jsonKeyIsExist(newJson, ['areaid']) == false) {
            newJson['areaid'] = generateUUID()
          }
          rooms.push(newJson)
        }
      }
    }
  }

  function getPathArea(paths, lx, ly) {
    if (paths.length < 3) {
      false
    }

    let areas = []
    let lastx = 0
    let lasty = 0
    let LastLink = paths[0]
    let beisaier = []
    const storeBeisaier = []
    for (let i = 1; i < paths.length; i++) {
      const pointStr = paths[i]
      if (pointStr == '') {
        continue
      }
      const pointStrs = pointStr.split(',')
      if (i == 1) {
        const last = paths[0]
        let x = parseFloat(pointStrs[0]).toFixed(2)
        let y = parseFloat(pointStrs[1]).toFixed(2)
        if (last == 'm') {
          // 取上一次的lastx 和 lasty
          x = parseFloat(x) + lx
          y = parseFloat(y) + lx
        } else {
          // M
        }
        lastx = parseFloat(x)
        lasty = parseFloat(y)
        areas.push([parseFloat(x), 0, parseFloat(y)])
      } else {
        const reg = /^[a-zA-Z]/i
        if (reg.test(pointStr) == false) {
          // 不是类型符
          if (LastLink == 'm' || LastLink == 'l') {
            const info = xiangduiDingWei(pointStrs, areas, lastx, lasty)
            lastx = info[0]
            lasty = info[1]
          } else if (LastLink == 'L' || LastLink == 'M') {
            // 绝对定位
            const info = jueduiDingWei(pointStrs, areas)
            lastx = info[0]
            lasty = info[1]
          } else if (LastLink == 'C') {
            const info = jueduiDingWei(pointStrs, beisaier)
            lastx = info[0]
            lasty = info[1]
          } else if (LastLink == 'c') {
            const info = xiangduiDingWei(pointStrs, beisaier, lastx, lasty)
            if ((beisaier.length - 1) % 3 == 0 && beisaier.length > 1) {
              lastx = info[0]
              lasty = info[1]
            }
          } else if (LastLink == 'A' || LastLink == 'a') {
            const info = dealWithA(i, paths, LastLink, lastx, lasty)
            if (info[2] == true) {
              lastx = info[0]
              lasty = info[1]
              const points = info[3]
              areas = areas.concat(points)
            }
          } else if (LastLink == 'H') {
            lastx = parseFloat(pointStr)
            areas.push([parseFloat(lastx.toFixed(2)), 0, parseFloat(lasty.toFixed(2))])
          } else if (LastLink == 'h') {
            lastx = parseFloat(pointStr) + lastx
            areas.push([parseFloat(lastx.toFixed(2)), 0, parseFloat(lasty.toFixed(2))])
          } else if (LastLink == 'V') {
            lasty = parseFloat(pointStr)
            areas.push([parseFloat(lastx.toFixed(2)), 0, parseFloat(lasty.toFixed(2))])
          } else if (LastLink == 'v') {
            lasty = parseFloat(pointStr) + lasty
            areas.push([parseFloat(lastx.toFixed(2)), 0, parseFloat(lasty.toFixed(2))])
          } else if (LastLink == 'S') {
            if (storeBeisaier.length > 0 && beisaier.length == 1) {
              const lastBeisaier = storeBeisaier[storeBeisaier.length - 1]
              if (lastBeisaier.length >= 4) {
                const endControl = lastBeisaier[2]
                const endPoint = lastBeisaier[3]
                const ex = endPoint[0] * 2 - endControl[0]
                const ez = endPoint[2] * 2 - endControl[2]
                beisaier.push([parseFloat(ex), 0, parseFloat(ez)])
              }
            }
            const info = jueduiDingWei(pointStrs, beisaier)
            lastx = info[0]
            lasty = info[1]
          } else if (LastLink == 's') {
            if (storeBeisaier.length > 0 && beisaier.length == 1) {
              const lastBeisaier = storeBeisaier[storeBeisaier.length - 1]
              if (lastBeisaier.length >= 4) {
                const endControl = lastBeisaier[2]
                const endPoint = lastBeisaier[3]
                const ex = endPoint[0] * 2 - endControl[0]
                const ez = endPoint[2] * 2 - endControl[2]
                beisaier.push([parseFloat(ex), 0, parseFloat(ez)])
              }
            }
            const info = xiangduiDingWei(pointStrs, beisaier, lastx, lasty)
            if ((beisaier.length - 1) % 3 == 0 && beisaier.length > 1) {
              lastx = info[0]
              lasty = info[1]
            }
          }
        } else {
          LastLink = pointStr
          if (beisaier.length > 0) {
            const numberOfPoints = 6
            const dt = 1.0 / (numberOfPoints - 1)
            for (let k = 0; k < beisaier.length; k++) {
              if ((k) % 3 == 0 && k != 0) {
                for (let j = 1; j < numberOfPoints - 1; j++) {
                  const ps = [beisaier[k - 3], beisaier[k - 2], beisaier[k - 1], beisaier[k]]
                  const p = PointOnCubicBezier(ps, j * dt)
                  areas.push(p)
                }
              }
            }
            areas.push(beisaier[beisaier.length - 1])
            storeBeisaier.push(beisaier)
            beisaier = []
          }
          if (pointStr == 'C' || pointStr == 'c') {
            beisaier = []
            beisaier.push(areas[areas.length - 1])
          } else if (pointStr == 'S' || pointStr == 's') {
            beisaier = []
            beisaier.push(areas[areas.length - 1])
          }
        }
      }
    }
    return {
      result: true,
      paths: areas,
      lastX: lastx,
      lastY: lasty
    }
  }

  // 解析rect
  function dealWithRectOuterHTML(outerHTML, rooms, mapid) {
    const parser = new DOMParser(outerHTML)
    const xmlDoc = parser.parseFromString(outerHTML, 'text/xml')
    const countrys = xmlDoc.getElementsByTagName('rect')
    if (countrys.length <= 0) {
      return
    }
    const obj = countrys[0]
    const json = {}
    json['layerHeight'] = 1
    json['title'] = ''
    json['image'] = ''
    json['titleLV'] = 3 // 默认文字等级为3 等级越小优先级越高
    json['color'] = '#ffffff'
    const boxJson = {}
    const attributes = obj['attributes']
    const transform = {
      result: false
    }
    for (const key in attributes) {
      const node = attributes[key]
      const nodeName = node['nodeName']
      const nodeValue = node['nodeValue']
      switch (nodeName) {
        case 'transform':
          const matchTransMatrix = new RegExp('matrix\\((-?[0-9]+e?-?[0-9]?\\.?[0-9]?\\s?)+\\)', 'gmi')
          const matchRep = nodeValue.match(matchTransMatrix)
          if (matchRep) {
            const getRep = new RegExp('matrix\\((.*)\\)', 'gmi')
            const res = getRep.exec(nodeValue)[1].trim()
            transform.result = true
            transform.matrix = res
          } else {

          }
          break
        case 'label':
          json['label'] = nodeValue
          break
        case 'cross':
          json['cross'] = nodeValue
          break
        case 'once':
          json['once'] = nodeValue
          break
        case 'moveY':
          json['moveY'] = nodeValue
          break
        case 'EX':
          json[nodeName] = nodeValue
          break
        case 'hidden':
          json['hidden'] = nodeValue
          break
        case 'zero':
          json['zero'] = nodeValue
          break
        case 'style':
          const strs = nodeValue.split(';')
          for (let i = 0; i < strs.length; i++) {
            const value = strs[i]
            const values = value.split(':')
            const name = values[0]
            const nameValue = values[1]
            if (name == 'fill') {
              json['color'] = nameValue
              break
            }
          }
          break
        case 'width':
          boxJson[nodeName] = parseFloat(nodeValue).toFixed(7)
          break
        case 'height':
          boxJson[nodeName] = parseFloat(nodeValue).toFixed(7)
          break
        case 'x':
          boxJson[nodeName] = parseFloat(nodeValue).toFixed(7)
          break
        case 'y':
          boxJson[nodeName] = parseFloat(nodeValue).toFixed(7)
          break
        case 'id':
          if (nodeValue == '') {
            json['areaid'] = generateUUID()
          } else {
            json['areaid'] = nodeValue
          }
          break
        case 'titleLV':
          json[nodeName] = parseInt(nodeValue)
          break
        case 'title':
          json[nodeName] = nodeValue
          break
        case 'layerHeight':
          json[nodeName] = parseFloat(nodeValue)
          break
        case 'Link':
          // 表示楼层之间的连接点
          if (nodeValue.search('GPS') >= 0) {
            // 门口
            json[nodeName] = nodeValue
            json['type'] = parseInt(floorLinkType.gps)
          } else if (nodeValue.search('L') >= 0) {
            // 楼梯
            json[nodeName] = nodeValue
            json['type'] = parseInt(floorLinkType.louti)
          } else if (nodeValue.search('D') >= 0) {
            // 电梯
            json[nodeName] = nodeValue
            json['type'] = parseInt(floorLinkType.dianti)
          } else if (nodeValue.search('F') >= 0) {
            // 扶梯
            json[nodeName] = nodeValue
            json['type'] = parseInt(floorLinkType.futi)
          } else if (nodeValue.search('P') >= 0) {
            // 坡度
            json[nodeName] = nodeValue
            json['type'] = parseInt(floorLinkType.po)
          }
          break
        case 'pHeight':
          // 坡度高度 单位为米
          json[nodeName] = parseFloat(nodeValue)
          break
        case 'W':
          // 坡度宽度
          json[nodeName] = parseFloat(nodeValue)
          break
        case 'H':
          // 坡度高度
          json[nodeName] = parseFloat(nodeValue)
          break
        default:
          break
      }
    }
    const x = parseFloat(boxJson['x'])
    const y = parseFloat(boxJson['y'])
    const width = parseFloat(boxJson['width'])
    const height = parseFloat(boxJson['height'])
    const areas = [
      [x, 0, y],
      [x, 0, y + height],
      [x + width, 0, y + height],
      [x + width, 0, y]
    ]
    if (transform.result) {
      const matrixStr = transform.matrix
      const matrixArr = matrixStr.split(' ')
      if (matrixArr.length == 6) {
        const a = parseFloat(matrixArr[0])
        const b = parseFloat(matrixArr[1])
        const c = parseFloat(matrixArr[2])
        const d = parseFloat(matrixArr[3])
        const e = parseFloat(matrixArr[4])
        const f = parseFloat(matrixArr[5])
        for (let i = 0; i < areas.length; i++) {
          const p = areas[i]
          const x = p[0] * a + p[2] * c + e
          const y = p[0] * b + p[2] * d + f
          p[0] = x
          p[2] = y
        }
      }
    }

    json['areas'] = areas
    const layerHeight = json['layerHeight']
    json['height'] = layerHeight
    const color = json['color']
    if (color == '#000000') {
      // 黑色即为导航块
      const pointX = x + width / 2.0
      const pointY = y + height / 2.0
      const id = setMapid(mapid, json['areaid'])

      if (id == null) {
        console.log('解析导航格子id为空')
        return
      }
      let title = json['title']
      if (title == null) {
        title = ''
      }

      const data = {
        id: id,
        x: parseFloat(pointX.toFixed(2)),
        y: parseFloat(pointY.toFixed(2)),
        title: title,
        type: floorLinkType.normal,
        url: '' // 存在实景图url
      }
      if (jsonKeyIsExist(json, ['type', 'Link'])) {
        data['link'] = json['Link']
        data['type'] = parseInt(json['type'])
      }
      if (jsonKeyIsExist(json, ['pHeight'])) {
        data['pHeight'] = json['pHeight']
      }
      data['titleLV'] = json['titleLV']
      if (jsonKeyIsExist(json, ['W'])) {
        data['W'] = json['W']
      }
      if (jsonKeyIsExist(json, ['H'])) {
        data['H'] = json['H']
      }
      if (jsonKeyIsExist(json, ['label'])) {
        data['label'] = json['label']
      }
      leadInfo[id] = data
    } else {
      if (areas.length > 0) {
        if (analysis.jsonKeyIsExist(json, ['areaid']) == false) {
          json['areaid'] = generateUUID()
        }
        rooms.push(json)
      }
    }
  }

  function getBackgroundData(childjson, svgJosn) {
    // 找到g节点
    const nodeChild = childjson['children']
    const images = []
    for (const nodeKey in nodeChild) {
      const node = nodeChild[nodeKey]
      const childNodeName = node['nodeName']
      if (childNodeName != 'image') {
        continue;
        // 图片
      }
      const outerHTML = node['outerHTML']
      dealWithBackgroundImageDataHTML(outerHTML, images)
    }
    svgJosn['mapinfo']['mapinfo'] = images
  }

  function dealWithBackgroundImageDataHTML(outerHTML, images) {
    const parser = new DOMParser(outerHTML)
    const xmlDoc = parser.parseFromString(outerHTML, 'text/xml')
    const countrys = xmlDoc.getElementsByTagName('image')
    if (countrys.length <= 0) {
      return
    }
    for (let i = 0; i < countrys.length; i++) {
      const obj = countrys[i]
      if (typeof obj['attributes'] == 'undefined' || obj['attributes'] == null) {
        continue;
      }
      const json = {}
      const attributes = obj['attributes']
      let transform = {
        result: false,
      }
      for (const key in attributes) {
        const node = attributes[key]
        const nodeName = node['nodeName']
        const nodeValue = node['nodeValue']
        switch (nodeName) {
          case 'transform':
            let matchTransMatrix = new RegExp("matrix\\((-?[0-9]+e?-?[0-9]?\\.?[0-9]?\\s?)+\\)", "gmi");
            let matchRep = nodeValue.match(matchTransMatrix);
            if (matchRep) {
              let getRep = new RegExp("matrix\\((.*)\\)", "gmi");
              let res = getRep.exec(nodeValue)[1].trim()
              transform.result = true;
              transform.matrix = res;
            } else {

            }
            break;
          case 'width':
            json['width'] = parseFloat(nodeValue).toFixed(7)
            break
          case 'height':
            json['height'] = parseFloat(nodeValue).toFixed(7)
            break
          case 'x':
            json['x'] = parseFloat(nodeValue).toFixed(7)
            break
          case 'y':
            json['y'] = parseFloat(nodeValue).toFixed(7)
            break
          case 'xlink:href':
            json['image'] = nodeValue;
            break;
          default:
            break
        }
      }
      if (typeof json['x'] == 'undefined'
        || typeof json['y'] == 'undefined'
        || typeof json['width'] == 'undefined'
        || typeof json['height'] == 'undefined'
        || typeof json['image'] == 'undefined') {
        continue;
      }

      const width = parseFloat(json['width'])
      const height = parseFloat(json['height'])
      const areas = [
        [0, 0, 0],
        [width, 0, 0],
        [width, 0, height],
        [0, 0, height]
      ]
      if (transform.result) {
        let matrixStr = transform.matrix;
        let matrixArr = matrixStr.split(' ');
        if (matrixArr.length == 6) {
          let a = parseFloat(matrixArr[0]);
          let b = parseFloat(matrixArr[1]);
          let c = parseFloat(matrixArr[2]);
          let d = parseFloat(matrixArr[3]);
          let e = parseFloat(matrixArr[4]);
          let f = parseFloat(matrixArr[5]);
          for (let i = 0; i < areas.length; i++) {
            let p = areas[i];
            let x = p[0] * a + p[2] * c + e;
            let y = p[0] * b + p[2] * d + f;
            p[0] = x;
            p[2] = y;
          }
        }
      }

      let dataJson = {}
      dataJson['layerHeight'] = 1
      dataJson['title'] = ''
      dataJson['image'] = json['image']
      dataJson['titleLV'] = 3 // 默认文字等级为3 等级越小优先级越高
      dataJson['color'] = '#ffff00'
      dataJson['areas'] = areas;
      images.push(dataJson)
    }
  }

  function dealWithImageDataHTML(outerHTML, images) {
    const parser = new DOMParser(outerHTML)
    const xmlDoc = parser.parseFromString(outerHTML, 'text/xml')
    const countrys = xmlDoc.getElementsByTagName('image')
    if (countrys.length <= 0) {
      return
    }

    const obj = countrys[0]
    const json = {}
    const href = obj['href']
    if (href == null || typeof (href) == 'undefined') {
      return
    }

    const baseVal = href['baseVal']
    if (baseVal == null || typeof (baseVal) == 'undefined') {
      return
    }

    json['data'] = baseVal
    json['x'] = null
    json['y'] = null
    json['width'] = null
    json['height'] = null
    const attributes = obj['attributes']
    for (const key in attributes) {
      const node = attributes[key]
      const nodeName = node['nodeName']
      const nodeValue = node['nodeValue']
      switch (nodeName) {
        case 'width':
          json['width'] = parseFloat(nodeValue).toFixed(7)
          break
        case 'height':
          json['height'] = parseFloat(nodeValue).toFixed(7)
          break
        case 'x':
          json['x'] = parseFloat(nodeValue).toFixed(7)
          break
        case 'y':
          json['y'] = parseFloat(nodeValue).toFixed(7)
          break
        default:
          break
      }
    }
    if (json.width > 200 || json.height > 200) {
      return
    }
    images.push(json)
  }

  function generateUUID() {
    let d = new Date().getTime()
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now() // use high-precision timer if available
    }
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
  }

  return svg
}
