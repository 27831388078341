import * as THREE from 'three/build/three.min.js'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createFloorTitle
} from '../floor/floorText'

export function createDetailGroup() {
  const group = new THREE.Group()

  let groupInfo = {} // 区域group

  let zoneInfo = {} // 区域信息

  let analysis = createAnalaysisInfo() // 构造地图解析

  group.checkZoneIsExist = function(zoneId) {
    return analysis.jsonKeyIsExist(groupInfo, [zoneId])
  }

  group.deleteAll = function() {
    for (const zoneId in groupInfo) {
      const g = groupInfo[zoneId]
      const children = g.children
      for (let i = 0; i < children.length; i++) {
        const subG = children[i]
        if (subG.parent != null) {
          subG.parent.remove(subG)
        }
      }
    }
  }

  group.returnNowGroupMeshList = function(zoneId) {
    if (analysis.jsonKeyIsExist(groupInfo, [zoneId]) == false) {
      return []
    }
    const g = groupInfo[zoneId]
    const meshList = []
    for (let i = 0; i < g.children.length; i++) {
      const subG = g.children[i]
      const children = subG.children
      for (let j = 0; j < children.length; j++) {
        const mesh = children[j]
        if (mesh.type != 'Mesh') {
          continue
        }
        mesh['groupIndex'] = i
        meshList.push(mesh)
      }
    }
    return meshList
  }

  group.showZoneGroupByPromise = function(config, callBack) {
    /* -------------------------------- */
    function zoneIsExist(config, callBack) {
      const zoneId = config['zoneId']
      if (analysis.jsonKeyIsExist(zoneInfo, [zoneId]) == false) {
        callBack({
          result: false
        })
        return
      }
      const zone = zoneInfo[zoneId]
      const centerList = zone['center']
      let index = 0
      for (let i = 0; i < centerList.length; i++) {
        const centerInfo = centerList[i]
        if (centerInfo['mapid'] == config['mapid']) {
          index = i
          break
        }
      }
      zone['index'] = index
      callBack({
        result: true,
        zone: zone
      })
    }

    function addZoneGroup(groupList, index, y, centerList, dataInfo, callBack) {
      function updateZoneText(textData, textG) {
        return new Promise(resolve => {
          textG.updateZoneText(textData)
          setTimeout(() => {
            textG.advanceAnimation()
            setTimeout(() => {
            resolve()
            }, 100)
          }, 100)
        })
      }

      function createTitle(config) {
        return new Promise(resolve => {
          const textInfo = config['textInfo']
          const textG = createFloorTitle()
          textG.name = zoneId + ' ~ ' + mapid
          zoneGroup.add(textG)
          if (analysis.jsonKeyIsExist(textInfo, [mapid])) {
            updateZoneText(textInfo[mapid], textG).then(res => {
              resolve()
            })
          } else {
            resolve()
          }
        })
      }

      if (index >= groupList.length) {
        callBack()
        return
      }

      const zoneGroup = groupList[index]
      zoneGroup.position.y = index * y
      zoneGroup.visible = true
      dataInfo['groupHeight'] = zoneGroup.position.y

      g.add(zoneGroup)

      let centerPoint = {
        x: 0,
        z: 0
      }
      let fence = {}
      let roomInfo = []

      if (analysis.jsonKeyIsExist(zoneGroup, ['centerPoint'])) {
        centerPoint = zoneGroup['centerPoint']
      }

      if (analysis.jsonKeyIsExist(zoneGroup, ['fence'])) {
        fence = JSON.parse(JSON.stringify(zoneGroup['fence']))
      }

      if (analysis.jsonKeyIsExist(zoneGroup, ['roomList'])) {
        roomInfo = zoneGroup['roomList']
      }

      centerPoint['y'] = zoneGroup.position.y

      if (dataInfo['minY'] == null) {
        dataInfo['minY'] = zoneGroup.position.y;
      } else {
        dataInfo['minY'] = dataInfo['minY'] >= zoneGroup.position.y ? zoneGroup.position.y : dataInfo['minY']
      }

      if (dataInfo['maxY'] == null) {
        dataInfo['maxY'] = zoneGroup.position.y;
      } else {
        dataInfo['maxY'] = dataInfo['maxY'] <= zoneGroup.position.y ? zoneGroup.position.y : dataInfo['maxY']
      }

      const mapid = zoneGroup['mapid']
      const info = {
        roomInfo: roomInfo,
        mapid: mapid,
        center: centerPoint,
        fence: fence,
        mapName: zoneGroup['name']
      }
      centerList.push(info)
      dataInfo['findFence'] = fence
      dataInfo['center'] = centerPoint

      createTitle(config).then(res => {
        setTimeout(() => {
        addZoneGroup(groupList, index + 1, y, centerList, dataInfo, callBack)
      })
      })
    }

    /* -------------------------------- */

    const zoneId = config['zoneId']
    for (const key in groupInfo) {
      const g = groupInfo[key]
      g.visible = (key == zoneId)
    }
    if (analysis.jsonKeyIsExist(groupInfo, [zoneId])) {
      // 已经存在group
      zoneIsExist(config, callBack)
      return
    }
    const groupList = config['groupList']
    const minPolarAngle = config['minPolarAngle']
    const g = new THREE.Group()
    g.name = zoneId
    group.add(g)
    groupInfo[zoneId] = g

    const centerList = []
    const dataInfo = {}
    dataInfo['minY'] = null
    dataInfo['maxY'] = null
    const y = config['y'] * Math.cos(minPolarAngle)
    const zone = {}
    const updateCallBack = () => {
      if (analysis.jsonKeyIsExist(dataInfo, ['findFence']) == false) {
        callBack({
          result: false,
          zone: zone
        })
        return;
      }
      const center = dataInfo['center']
      const findFence = dataInfo['findFence']
      const minY = dataInfo['minY']
      const maxY = dataInfo['maxY']
      const groupHeight = dataInfo['groupHeight']
      const mapBox = new THREE.Box3()
      const minV = new THREE.Vector3(-100, -100, -100)
      const maxV = new THREE.Vector3(100, 100, 100)
      const leftUp = findFence.leftUp
      const rightDown = findFence.rightDown
      findFence.y = (maxY + minY) / 2.0
      const width = rightDown.x - leftUp.x
      const height = rightDown.z - leftUp.z
      const num = 0.7
      minV.x = leftUp.x - width * num
      minV.z = leftUp.z - height * num
      minV.y = -1000
      maxV.x = rightDown.x + width * num
      maxV.z = rightDown.z + height * num
      maxV.y = groupHeight + 1000
      mapBox.set(minV, maxV)
      zone['mapBox'] = mapBox
      zone['center'] = centerList
      zone['zoneId'] = zoneId
      zone['centerPoint'] = center
      zone['findFence'] = findFence
      zone['mapid'] = config['mapid']
      let index = 0
      for (let i = 0; i < centerList.length; i++) {
        const centerInfo = centerList[i]
        if (centerInfo['mapid'] == config['mapid']) {
          index = i
          break
        }
      }
      zone['index'] = index
      zoneInfo[zoneId] = zone
      callBack({
        result: true,
        zone: zone
      })
    }
    addZoneGroup(groupList, 0, y, centerList, dataInfo, updateCallBack)
  }

  group.showZoneGroup = function(config) {
    const zoneId = config['zoneId']
    for (const key in groupInfo) {
      const g = groupInfo[key]
      g.visible = (key == zoneId)
    }
    if (analysis.jsonKeyIsExist(groupInfo, [zoneId])) {
      // 已经存在group
      const zone = zoneInfo[zoneId]
      return {
        result: true,
        zone: zone
      }
    }
    const groupList = config['groupList']
    const minPolarAngle = config['minPolarAngle']
    const g = new THREE.Group()
    g.name = zoneId
    group.add(g)
    const y = config['y'] * Math.cos(minPolarAngle)
    const zone = {}
    const center = {
      x: 0,
      y: 0,
      z: 0
    }
    let findFence = {
      leftUp: {
        x: 0,
        z: 0
      },
      rightDown: {
        x: 0,
        z: 0
      },
      y: 0
    }
    const centerList = []
    let groupHeight = 0
    let minY = 0
    let maxY = 0
    for (let i = 0; i < groupList.length; i++) {
      const zoneGroup = groupList[i]
      zoneGroup.position.y = i * y
      zoneGroup.visible = true
      groupHeight = zoneGroup.position.y
      g.add(zoneGroup)

      let centerPoint = {
        x: 0,
        z: 0
      }
      let fence = {}
      let roomInfo = []

      if (analysis.jsonKeyIsExist(zoneGroup, ['centerPoint'])) {
        centerPoint = zoneGroup['centerPoint']
      }
      if (analysis.jsonKeyIsExist(zoneGroup, ['fence'])) {
        fence = JSON.parse(JSON.stringify(zoneGroup['fence']))
      }

      if (analysis.jsonKeyIsExist(zoneGroup, ['roomList'])) {
        roomInfo = zoneGroup['roomList']
      }
      centerPoint['y'] = zoneGroup.position.y

      minY = minY >= zoneGroup.position.y ? zoneGroup.position.y : minY

      maxY = maxY <= zoneGroup.position.y ? zoneGroup.position.y : maxY

      const mapid = zoneGroup['mapid']
      const info = {
        roomInfo: roomInfo,
        mapid: mapid,
        center: centerPoint,
        fence: fence,
        mapName: zoneGroup['name']
      }
      centerList.push(info)
      findFence = fence
      center.x = centerPoint.x
      center.z = centerPoint.z

      const textInfo = config['textInfo']
      const textG = createFloorTitle()
      textG.name = zoneId + ' ~ ' + mapid
      zoneGroup.add(textG)
      if (analysis.jsonKeyIsExist(textInfo, [mapid])) {
        textG.updateZoneText(textInfo[mapid])
        textG.advanceAnimation()
      }
    }
    groupInfo[zoneId] = g
    const mapBox = new THREE.Box3()
    const minV = new THREE.Vector3(-100, -100, -100)
    const maxV = new THREE.Vector3(100, 100, 100)
    const leftUp = findFence.leftUp
    const rightDown = findFence.rightDown
    findFence.y = (maxY + minY) / 2.0
    const width = rightDown.x - leftUp.x
    const height = rightDown.z - leftUp.z
    const num = 0.7
    minV.x = leftUp.x - width * num
    minV.z = leftUp.z - height * num
    minV.y = -1000
    maxV.x = rightDown.x + width * num
    maxV.z = rightDown.z + height * num
    maxV.y = groupHeight + 1000
    mapBox.set(minV, maxV)
    zone['mapBox'] = mapBox
    zone['index'] = 0
    zone['center'] = centerList
    zone['zoneId'] = zoneId
    zone['centerPoint'] = center
    zone['findFence'] = findFence
    zone['mapid'] = config['mapid']
    zoneInfo[zoneId] = zone
    return {
      result: true,
      zone: zone
    }
  }

  group.textRotate = function(config, scaleBlock) {
    for (const zoneId in groupInfo) {
      const zoneGroup = groupInfo[zoneId]
      if (zoneGroup.visible == false) {
        continue
      }
      const children = zoneGroup.children
      for (let i = 0; i < children.length; i++) {
        const g = children[i]
        if (g.type != 'Group') {
          continue
        }
        const mapid = g['mapid']
        const name = zoneId + ' ~ ' + mapid
        const textG = g.getObjectByName(name)
        textG.textRotate(config, scaleBlock)
        textG.roomTextAnimation()
      }
    }
  }

  return group
}
