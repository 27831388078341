import * as THREE from 'three/build/three.min.js'
import * as TWEEN from 'tween/tween.js'
import {
  createObjectAction
} from '../createObject'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'


export function createTravlLine() {
  const travl = new THREE.Group()

  const create = createObjectAction()

  const analysis = createAnalaysisInfo()

  let tween = null // 动画

  let changeMapBlock = null;

  travl.changeMapAction = function (callBack) {
    changeMapBlock = callBack;
  }

  let indexBlock = null;

  travl.indexChange = function (callBack) {
    indexBlock = callBack;
  }

  let stopTravlBlock = null;

  travl.stopTravl = function (callBack) {
    stopTravlBlock = callBack;
  }

  const travlLine = {
    dataInfo: {},
    showRemark: true,
    user: '../../../static/iconImage/dingwei-phone-s.png',

    drawTravlLineInfo: {}, // 绘制轨迹 数据 按mapid
    moveTravlLineInfo: {}, // 运动轨迹 数据 按mapid
    drawTravlList: [], // 绘制轨迹
    moveTravlList: [], // 运动轨迹
    drawTravlLineList: [], // 绘制轨迹
    moveTravlLineList: [], // 运动轨迹
    drawTravlLineConfig: {
      color: 0xff00ff,
      y: 2.5,
      transparent: false,
      depthTest: true,
      show: true,
      lineStyle: 'sold',
    },
    moveTravlLineConfig: {
      color: 0x0000ff,
      y: 2.5,
      transparent: false,
      depthTest: true,
      show: true,
      lineStyle: 'dash',
    },
    markConfig: {
      image: '../../../static/iconImage/dingwei-phone-s.png',
      showRemarkTitle: true,
      userCenter: 0,
      remarkCenter: 0,
      style: 'one',
    },
    point: null, // 当前轨迹的标注
    pointRemark: null, // 轨迹的标注
    animationIndex: 0, // 当前动画移动到的点的索引
    index: 0, // 原始数据的点的索引
    startAnimation: true,
    travlMesh: null,
    travlRemark: null,
    mapId: '',
  }

  travl.updateShowMapid = function (mapId) {
    travlLine.mapId = mapId;
    // if (travlLine.travlMesh != null) {
    //   travlLine.travlMesh.visible = travlLine.travlMesh['mapId'] == mapId;
    // }
    // if (travlLine.travlRemark != null) {
    //   travlLine.travlRemark.visible = travlLine.travlRemark['mapId'] == mapId;
    // }
    // updateLine(travlLine.drawTravlLineConfig.show ? travlLine.drawTravlLineInfo : {}, mapId, travlLine.drawTravlLineList, travlLine.drawTravlLineConfig);
    // updateLine(travlLine.moveTravlLineConfig.show ? travlLine.moveTravlLineInfo : {}, mapId, travlLine.moveTravlLineList, travlLine.moveTravlLineConfig);
  }

  function updateLine(dataInfo, key, linesMesh, style) {
    let list = []
    if (analysis.jsonKeyIsExist(dataInfo, [key]) == false) {
      dataInfo[key] = []
    }
    list = dataInfo[key]

    for (let i = 0; i < list.length; i++) {
      const lines = list[i]
      if (i < linesMesh.length) {
        const lineMesh = linesMesh[i]
        create.updateLineStyleByConfig(lineMesh, lines, style)
        lineMesh.visible = true
      } else {
        const line = create.createLineByConfig(lines, style)
        line.position.y = style.y;
        linesMesh.push(line)
        travl.add(line)
      }
    }

    if (list.length < linesMesh.length) {
      for (let i = list.length; i < linesMesh.length; i++) {
        const line = linesMesh[i]
        line.visible = false
      }
    }
  }

  // 显示轨迹线
  travl.showTravlModel = function (config) {

    let addMesh = () => {
      let position = {
        x: 0,
        y: 2.5,
        z: 0
      }

      let index = 0;
      if (analysis.jsonKeyIsExist(config, ['index'])) {
        index = config['index'];
      }

      if (travlLine.drawTravlList.length > 0) {
        let item = travlLine.drawTravlList[index]
        position.x = item.x;
        position.z = item.z;
      }


      if (travlLine.travlMesh != null) {
        travlLine.travlMesh.position.x = position.x
        travlLine.travlMesh.position.z = position.z
        return;
      }


      let setting = {
        image: travlLine.markConfig.image,
        depthTest: false,
        width: 32,
        multiple: 30,
        position: position,
        mode: 0,
        centerY: travlLine.markConfig.userCenter,
        scale: 0.10
      }
      if (travlLine.markConfig.style == 'one') {
        travlLine.travlMesh = create.creteIconSprite(setting)
      } else if (travlLine.markConfig.style == 'two') {
        travlLine.travlMesh = create.createNewPlane(setting)
        travlLine.travlMesh.uuid = 'Phone-rotate'
      } else {
        travlLine.travlMesh = create.creteIconSprite(setting)
      }
      travl.add(travlLine.travlMesh)
    }

    let addRemark = () => {

      let index = 0;
      if (analysis.jsonKeyIsExist(config, ['index'])) {
        index = config['index'];
      }

      let position = {
        x: 0,
        y: 2.5,
        z: 0
      }
      let msg = ' '
      if (travlLine.drawTravlList.length > 0) {
        let item = travlLine.drawTravlList[index]
        position.x = item.x;
        position.z = item.z;
        msg = getTimerStr(item)
      }

      if (travlLine.travlRemark != null) {
        travlLine.travlRemark.position.x = position.x
        travlLine.travlRemark.position.z = position.z
        return;
      }

      let setting = {
        position: position,
        type: 'normal',
        title: msg,
        textColor: '#000000',
        bgColor: '#fffde8',
        multiple: 10,
        centerY: travlLine.markConfig.remarkCenter,
        mode: 0,
        depthTest: false,
        zoomNum: 1.1
      }
      travlLine.travlRemark = create.createTitle(setting)
      travl.add(travlLine.travlRemark)
      // travlLine.travlRemark.visible = travlLine.markConfig.showRemarkTitle
    }


    if (analysis.jsonKeyIsExist(config, ['mapId']) == false) {
      return;
    }
    let mapId = config['mapId'];

    updateLine(travlLine.drawTravlLineConfig.show ? travlLine.drawTravlLineInfo : {}, mapId, travlLine.drawTravlLineList, travlLine.drawTravlLineConfig);
    updateLine(travlLine.moveTravlLineConfig.show ? travlLine.moveTravlLineInfo : {}, mapId, travlLine.moveTravlLineList, travlLine.moveTravlLineConfig);
    addMesh();
    addRemark();
  }


  travl.updateTravlData = function (config) {
    let updateTravlInfo = (lines, dataInfo) => {
      let lastMapid = ''
      for (let i = 0; i < lines.length; i++) {
        const point = lines[i]
        if (analysis.jsonKeyIsExist(point, ['mapId']) == false) {
          continue
        }
        const position = new THREE.Vector3(point.x, 0, point.z)
        let info = []
        if (analysis.jsonKeyIsExist(dataInfo, [point.mapId])) {
          info = dataInfo[point.mapId]
        } else {
          dataInfo[point.mapId] = info
        }
        if (lastMapid != point.mapId) {
          // 不同相加
          info.push([position])
        } else {
          if (info.length == 0) {
            info.push([position])
          } else {
            const last = info[info.length - 1]
            last.push(position)
          }
        }
        lastMapid = point.mapId
      }
    }


    let drawTravlLine = [];
    if (analysis.jsonKeyIsExist(config, ['drawTravlLine'])) {
      drawTravlLine = config['drawTravlLine'];
    }

    let moveTravlLine = [];
    if (analysis.jsonKeyIsExist(config, ['moveTravlLine'])) {
      moveTravlLine = config['moveTravlLine'];
    }


    travlLine.drawTravlList = drawTravlLine;
    travlLine.moveTravlList = moveTravlLine;

    travlLine.drawTravlLineInfo = {};
    travlLine.moveTravlLineInfo = {};

    updateTravlInfo(drawTravlLine, travlLine.drawTravlLineInfo);
    updateTravlInfo(moveTravlLine, travlLine.moveTravlLineInfo);


    let index = 0;
    if (analysis.jsonKeyIsExist(config, ['index'])) {
      index = config['index'];
    }

    travlLine.animationIndex = 0;
    updateIndex(index);

    if (analysis.jsonKeyIsExist(config, ['drawTravlLineConfig'])) {
      travlLine.drawTravlLineConfig = config['drawTravlLineConfig'];
    }

    if (analysis.jsonKeyIsExist(config, ['moveTravlLineConfig'])) {
      travlLine.moveTravlLineConfig = config['moveTravlLineConfig'];
    }

    if (analysis.jsonKeyIsExist(config, ['markConfig'])) {
      travlLine.markConfig = config['markConfig'];
    }

    this.showTravlModel(config);
  }


  travl.returnIndex = function () {
    return travlLine.index
  }


  function updateIndex(index) {
    travlLine.index = index;
    // 将animationIndex与index同步
    for (let i = 0; i < travlLine.moveTravlList.length; i++) {
      let data = travlLine.moveTravlList[i];
      if (data.index == index) {
        travlLine.animationIndex = i;
        break;
      }
    }
    let item = travlLine.drawTravlList[index];
    updateItem(item);
  }

  function updateItem(item) {
    if (analysis.jsonKeyIsExist(item, ['mapId']) == false) {
      return;
    }
    let mapId = item['mapId']
    if (travlLine.travlMesh != null) {
      travlLine.travlMesh.position.x = item.x
      travlLine.travlMesh.position.z = item.z
      travlLine.travlMesh.visible = travlLine.mapId == mapId;
      travlLine.travlMesh['mapId'] = mapId;
    }

    updateTimeRemark(item, true);
  }

  function updateTimeRemark(item, updatePosition) {
    if (travlLine.travlRemark == null) {
      return;
    }
    if (travlLine.markConfig.showRemarkTitle == false) {
      travlLine.travlRemark.visible = false;
      return;
    }
    let mapId = item['mapId']
    const msg = getTimerStr(item)
    create.updateTitle(travlLine.travlRemark, {
      type: 'normal',
      title: msg,
      textColor: '#000000',
      bgColor: '#fffde8',
      mode: 0
    })
    travlLine.travlRemark.visible = travlLine.mapId == mapId
    if (updatePosition) {
      travlLine.travlRemark.position.x = item.x
      travlLine.travlRemark.position.z = item.z
    }
    travlLine.travlRemark['mapId'] = mapId;
  }

  travl.selectIndex = function (config) {
    travl.stopAnimation(config);
    let index = config['index'];
    if (index >= travlLine.drawTravlList.length) {
      return {
        result: false
      };
    }
    let item = travlLine.drawTravlList[index];
    let mapId = item['mapId']
    if (mapId != travlLine.mapId) {
      // 更改地图
      return {
        result: false
      };
    }
    updateIndex(index);
  }

  travl.stopAnimation = function (config) {
    if (tween != null) {
      tween.stop()
    }
    travlLine.startAnimation = false
    updateIndex(travlLine.index);
    if (stopTravlBlock != null) {
      stopTravlBlock();
    }
  }

  travl.startAnimation = function (config) {
    travlLine.startAnimation = true
    let index = travlLine.index
    if (analysis.jsonKeyIsExist(config, ['index'])) {
      index = config['index']
    }
    updateIndex(index)
    animation(config, () => {

    });
  }


  // 执行动画
  function animation(config, callBack) {
    if (travlLine.startAnimation == false) {
      callBack({result: false})
      return;
    }
    let index = travlLine.animationIndex;
    if (index >= travlLine.moveTravlList.length - 1) {
      travl.stopAnimation({});
      return;
    }
    let nextIndex = index + 1;
    let p = travlLine.moveTravlList[index];
    let nextP = travlLine.moveTravlList[nextIndex];

    if (analysis.jsonKeyIsExist(p, ['mapId', 'x', 'z']) == false || analysis.jsonKeyIsExist(nextP, ['mapId', 'x', 'z']) == false) {
      travlLine.animationIndex = travlLine.animationIndex + 1;
      animation(config, callBack);
      return;
    }

    let mapId = p['mapId'];
    let nextMapId = nextP['mapId'];
    if (mapId != nextMapId) {
      // 地图改变了
      if (indexBlock != null) {
        indexBlock({mapId: nextMapId, index: nextP['index']})
      }
      return;
    }

    if (tween == null) {
      tween = new TWEEN.Tween(travlLine.travlMesh.position)
    }

    updateTimeRemark(p, false);


    const starttween = tween
      .to({
        x: nextP.x,
        y: travlLine.travlMesh.position.y,
        z: nextP.z
      }, config.speed)
      .onUpdate(() => {
        travlLine.travlRemark.position.x = travlLine.travlMesh.position.x
        travlLine.travlRemark.position.z = travlLine.travlMesh.position.z
      })
      .onComplete(() => {
        travlLine.travlRemark.position.x = travlLine.travlMesh.position.x
        travlLine.travlRemark.position.z = travlLine.travlMesh.position.z
        setTimeout(() => {
          travlLine.animationIndex = travlLine.animationIndex + 1;
          if (indexBlock != null) {
            indexBlock({mapId: nextMapId, index: nextP['index']})
          }
          animation(config, callBack);
        }, 300)
      })
    starttween.start()
  }

  function getTimerStr(p) {
    function fixNum(num) {
      return num <= 9 ? '0' + num : num
    }

    let time = 0
    const keys = ['timestamp', 'creatTime']
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (analysis.jsonKeyIsExist(p, [key])) {
        time = p[key]
        break
      }
    }
    const date = new Date(time)
    const hour = date.getHours()
    const minute = date.getMinutes()
    const seconds = date.getSeconds()
    return fixNum(hour) + ':' + fixNum(minute) + ':' + fixNum(seconds)
  }

  travl.deleteAll = function () {
    let lineDelete = (key) => {
      let linesMesh = travlLine[key];
      for (let i = 0; i < linesMesh.length; i++) {
        const line = linesMesh[i]
        if (line.parent != null) {
          travl.remove(line)
        }
        line.geometry.dispose()
        line.material.dispose()
      }
      travlLine[key] = []
    }
    lineDelete('drawTravlLineList')
    lineDelete('moveTravlLineList')


    if (travlLine.travlRemark != null) {
      if (travlLine.travlRemark.parent != null) {
        travl.remove(travlLine.travlRemark)
      }
      travlLine.travlRemark.geometry.dispose()
      travlLine.travlRemark.material.dispose()
      travlLine.travlRemark = null;
    }
    if (travlLine.travlMesh != null) {
      if (travlLine.travlMesh.parent != null) {
        travl.remove(travlLine.travlMesh)
      }
      travlLine.travlMesh.geometry.dispose()
      travlLine.travlMesh.material.dispose()
      travlLine.travlMesh = null;
    }
    if (travl.parent != null) {
      travl.parent.remove(travl)
    }
    if (tween != null) {
      TWEEN.removeAll()
      tween = null;
    }
  }

  return travl;
}
