/*
* 导航定位网格绘制
* */

import {gridDataInfo} from "./girdData";
import {createGridGroup} from "./gridGroup";
import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";
import {createIntersects} from "../../intersects/intersects";

export function createGridModule() {

  let grid = new Object();

  let griddata = gridDataInfo();

  let gridGroup = createGridGroup();

  let analysis = createAnalaysisInfo()

  let intersect = createIntersects();

  function createGridFirstInfo() {
    return {
      firstLine: {start: null, end: null},
      firstLineMesh: null,
      line: {},
      point: {},
      specialPoint: {},
      pointManager: [],
      lineInfo: {},
      lineManager: [],
      selectLineInfo: {},
      selectPointInfo: {},
      group: null
    }
  }

  let gridInfo = createGridFirstInfo()

  grid.deleteAll = function (config) {
    gridGroup.deleteAll(config);
    gridInfo = createGridFirstInfo();
  }


  let mapInfoBlock = null;

  grid.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  let mapOrderListBlock = null;

  grid.mapOrderListAction = function (callBack) {
    mapOrderListBlock = callBack;
  }

  let cameraBlock = null;

  grid.cameraAction = function (callBack) {
    cameraBlock = callBack;
  }

  let getZeroMeshBlock = null;

  grid.zeroAction = function (callBack) {
    getZeroMeshBlock = callBack;
  }

  // 绘制网格
  grid.createGrid = function (space) {
    let mapInfo = mapInfoBlock();

    let mapid = mapInfo['mapid']
    let floorHieght = mapInfo['floorHieght']
    let scale = mapInfo['scaleInfo'];
    let fence = mapInfo['fenceInfo']
    let zero = mapInfo['zeroInfo']

    let path = [{
      x: 0, z: -1 * fence.height / 2.0, y: floorHieght
    }, {x: 0, z: fence.height / 2.0, y: floorHieght}]

    griddata.createGrid(path, space * scale, zero, floorHieght, (res) => {
      gridInfo.line[mapid] = {}
      if (res.result == false) {
        return;
      }
      gridInfo.line[mapid] = res.line
      gridGroup.updateLine({
        lineInfo: res.line,
      })
    });
  }

  // 更新点
  grid.updateGridPoint = function (config) {
    let points = [];
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let floorHieght = mapInfo['floorHieght']
    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid])) {
      let lineInfo = gridInfo.line[mapid]
      let pointsInfo = griddata.updateGridPoint(lineInfo, config)
      points = pointsInfo.points
    }
    gridInfo.point[mapid] = points
    gridGroup.updateCubePoint({
      size: 5,
      selectPointInfo: gridInfo.selectPointInfo,
      y: floorHieght,
      points: points,
    })
  }

  // 添加点
  grid.addNewPoint_Line = function (event, config) {
    let mapInfo = mapInfoBlock();

    let mapid = mapInfo['mapid']

    let floorHieght = mapInfo['floorHieght']

    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid]) == false) {
      return
    }
    let lineInfo = gridInfo.line[mapid]
    let camera = cameraBlock();
    config['camera'] = camera
    config['meshs'] = getZeroMeshBlock();
    let finder = intersect.getIntersects(event, config)
    let result = griddata.getPointOnLine(finder, lineInfo)
    if (result.result == false) {
      return
    }
    gridGroup.updateOtherPointList({
      point: result,
      y: floorHieght,
    })
  }

  grid.createNewBrench = function () {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let floorHieght = mapInfo['floorHieght']
    let zero = mapInfo['zeroInfo']
    let mapJson = mapInfo['mapJson']

    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid]) == false) {
      return
    }
    let lineInfo = gridInfo.line[mapid]
    let res = gridGroup.getOtherPoint();
    if (res.result == false) {
      return;
    }
    let p = res.point;
    let point = {x: p.position.x, y: floorHieght, z: p.position.z}
    let result = griddata.createNewBrench(point, zero, mapJson, floorHieght, lineInfo)
    if (result.result == false) {
      return
    }
    gridGroup.updateLine({
      lineInfo: lineInfo,
    })
    gridGroup.hiddenOtherPointList();
  }

  // 点击线
  grid.clickGridLine = function (event, config) {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let floorHieght = mapInfo['floorHieght']

    if (analysis.jsonKeyIsExist(gridInfo.selectLineInfo, [mapid]) == false) {
      gridInfo.selectLineInfo[mapid] = {}
    }
    let empty = Object.keys(gridInfo.selectLineInfo[mapid]).length == 0 ? true : false;
    if (empty == false) {
      gridInfo.selectLineInfo[mapid] = {}
      gridGroup.hiddenOtherLineList();
      // 隐藏选择的线段
      return
    }
    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid]) == false) {
      gridInfo.selectLineInfo[mapid] = {}
      gridGroup.hiddenOtherLineList();
      return
    }
    let lineInfo = gridInfo.line[mapid]
    let camera = cameraBlock();
    config['camera'] = camera
    config['meshs'] = getZeroMeshBlock();
    let finder = intersect.getIntersects(event, config)
    let result = griddata.findLine(finder, lineInfo)
    if (result.result == false) {
      gridInfo.selectLineInfo[mapid] = {}
      gridGroup.hiddenOtherLineList();
      return;
    }
    let line = lineInfo[result.key].line
    if (line.length < 2) {
      return;
    }
    gridInfo.selectLineInfo[mapid] = {}
    gridInfo.selectLineInfo[mapid][result.key] = '1'

    let p1 = line[0];
    let p2 = line[1];
    let points = [
      p1.x, floorHieght, p1.z,
      p2.x, floorHieght, p2.z
    ]

    let windowWidth = config['windowWidth'];
    let windowHeight = config['windowHeight'];

    gridGroup.updateOtherLineList({
      width: windowWidth,
      height: windowHeight,
      points: points,
    })
  }

  // 平移线
  grid.LineOffSet = function (x, z) {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']

    if (analysis.jsonKeyIsExist(gridInfo.selectLineInfo, [mapid]) == false) {
      gridGroup.hiddenOtherLineList();
      return;
    }
    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid]) == false) {
      gridGroup.hiddenOtherLineList();
      return;
    }
    gridGroup.otherLineListOffset(x, z);
    let select = gridInfo.selectLineInfo[mapid]
    let lineInfo = gridInfo.line[mapid]
    for (let key in select) {
      let data = lineInfo[key].line
      let start = data[0]
      let end = data[1]
      start.x = start.x + x
      start.z = start.z + z
      end.x = end.x + x
      end.z = end.z + z
    }
  }

  // 更新线段
  grid.updateSelectLine = function () {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let floorHieght = mapInfo['floorHieght']
    let zero = mapInfo['zeroInfo']
    let mapJson = mapInfo['mapJson']

    if (analysis.jsonKeyIsExist(gridInfo.selectLineInfo, [mapid]) == false) {
      gridInfo.selectLineInfo[mapid] = {}
    }
    let empty = Object.keys(gridInfo.selectLineInfo[mapid]).length == 0 ? true : false;
    if (empty == true) {
      return
    }
    gridGroup.hiddenOtherLineList();

    let lineInfo = gridInfo.line[mapid]
    for (let key in gridInfo.selectLineInfo[mapid]) {
      let line = lineInfo[key].line
      let result = griddata.updateGridLine(line, zero, mapJson, floorHieght, lineInfo)
      let dataEmpty = Object.keys(result.data).length == 0 ? true : false;
      if (dataEmpty == false) {
        delete lineInfo[key];
      }
    }
    gridInfo.selectLineInfo[mapid] = {}
    gridGroup.updateLine({
      lineInfo: lineInfo,
    })
  }

  grid.deleteGridInfo = function () {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let floorHieght = mapInfo['floorHieght']

    if (analysis.jsonKeyIsExist(gridInfo.selectLineInfo, [mapid]) && Object.keys(gridInfo.selectLineInfo[mapid]).length > 0) {

      if (analysis.jsonKeyIsExist(gridInfo.line, [mapid]) && Object.keys(gridInfo.line[mapid]).length > 0) {
        let info = gridInfo.line[mapid];
        for (const key in gridInfo.selectLineInfo[mapid]) {
          delete info[key];
        }
        gridGroup.updateLine({
          lineInfo: gridInfo.line[mapid],
        })
      }
      gridInfo.selectLineInfo[mapid] = {}
      gridGroup.hiddenOtherLineList();
      return;
    }


    if (analysis.jsonKeyIsExist(gridInfo.point, [mapid]) && gridInfo.point[mapid].length > 0) {
      gridInfo.point[mapid] = []
      gridGroup.updateCubePoint({
        size: 5,
        selectPointInfo: gridInfo.selectPointInfo,
        y: floorHieght,
        points: [],
      })
      return
    }

    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid]) && Object.keys(gridInfo.line[mapid]).length > 0) {
      gridInfo.line[mapid] = {}
      gridGroup.updateLine({
        lineInfo: {},
      })
    }
  }

  grid.setGridHidden = function (change) {
    gridGroup.visible = change;
  }

  grid.getGridInfo = function (data, config) {
    for (let i = 0; i < data.length; i++) {
      let info = data[i]
      try {
        let mapid = info.id
        let dataInfo = JSON.parse(info.gird)
        let empty = Object.keys(dataInfo).length == 0 ? true : false
        if (empty) {
          continue
        }
        gridInfo.line[mapid] = dataInfo.line
      } catch (e) {

      }
    }
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let lineInfo = {};
    if (analysis.jsonKeyIsExist(gridInfo.line, [mapid])) {
      lineInfo = gridInfo.line[mapid];
    }

    gridGroup.updateLine({
      lineInfo: lineInfo,
    })

    this.updateGridPoint(config);
  }

  grid.outPutGridInfo = function () {
    let data = []
    let mapOrderList = mapOrderListBlock();
    for (let i = 0; i < mapOrderList.length; i++) {
      let order = mapOrderList[i]
      let mapid = order['mapid']
      let line = {}
      if (analysis.jsonKeyIsExist(gridInfo.line, [mapid])) {
        line = gridInfo.line[mapid]
      }
      let info = {
        line: line,
        special: []
      }
      data.push({
        mapId: mapid,
        gird: JSON.stringify(info)
      })
    }
    return data
  }

  grid.rebuildGridInfo = function (config) {

    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']

    let showLine = true;
    if (analysis.jsonKeyIsExist(config, ['showLine'])) {
      showLine = config['showLine']
    }
    if (showLine) {
      let lineInfo = {};
      if (analysis.jsonKeyIsExist(gridInfo.line, [mapid])) {
        lineInfo = gridInfo.line[mapid];
      }

      gridGroup.updateLine({
        lineInfo: lineInfo,
      })
    }

    this.updateGridPoint(config)
  }

  grid.clickIbeaconPoint = function (event, config) {
    let mapInfo = mapInfoBlock();
    let mapid = mapInfo['mapid']
    let camera = cameraBlock();
    config['camera'] = camera
    config['meshs'] = getZeroMeshBlock();
    let finder = intersect.getIntersects(event, config)
    if (finder.result == false) {
      return {
        result: false
      }
    }

    let floor = mapInfo['zeroInfo']
    let areas = floor.areas
    if (analysis.isInPolygon([finder.x, 0, finder.z], areas) == false) {
      return {
        result: false
      }
    }
    let points = []
    if (analysis.jsonKeyIsExist(gridInfo.point, [mapid]) == true) {
      points = gridInfo.point[mapid]
    }

    let total = points.length
    let floorHieght = mapInfo['floorHieght']

    for (let i = 0; i < total; i++) {
      let p = points[i]
      let dis = Math.sqrt((p.x - finder.x) * (p.x - finder.x) + (p.z - finder.z) * (p.z - finder.z))
      if (dis <= 3) {
        return {
          result: true,
          point: {
            x: p.x,
            y: floorHieght,
            z: p.z,
            mapid: mapid
          }
        }
      }
    }
    return {
      result: false
    }
  }

  grid.addGroup = function (scene) {
    if (gridGroup.parent != null) {
      scene.remove(gridGroup);
    }
    scene.add(gridGroup);
  }

  return grid;
}
