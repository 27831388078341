import {
  MapControls
} from 'three/examples/jsm/controls/OrbitControls.js'
import * as TWEEN from 'tween/tween.js'
import {
  Ray
} from 'three/src/math/Ray'
import {
  Vector3
} from 'three/src/math/Vector3'
import {
  cameraZoomLevel
} from '../../MapConfig'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'

class newOrbitControls extends MapControls {

}

const createNewOrbitControls = function(object, domElement) {
  const cameraLevel = cameraZoomLevel()

  const config = {
    zoomSize: true
  }

  let cameraMoveInfo = null // 记录相机的最终位置信息

  // 更新信息
  function updateCameraMoveInfo(position, zoom) {
    cameraMoveInfo = {
      position: position,
      zoom: zoom
    }
  }

  const controls = new newOrbitControls(object, domElement)
  const analysis = createAnalaysisInfo() // 构造地图解析
  controls.enableKeys = false

  controls.setConfig = function(key, value) {
    config[key] = value
  }

  function getZoomLvevl(dis, zoom, camera) {
    let lv = 17
    const minLv = cameraLevel['lv1']
    if (dis <= minLv.endDis) {
      lv = 1
      return lv
    }
    const maxLv = cameraLevel['lv17']
    if (dis >= maxLv.endDis) {
      lv = 17
      return lv
    }
    for (const key in cameraLevel) {
      const info = cameraLevel[key]
      const start = info.startDis
      const end = info.endDis
      if (dis > start && dis <= end) {
        lv = info.lv
        break
      }
    }
    return lv
  }

  controls.getCameraZoomLevel = function() {
    const camera = this.object
    const target = this.target
    const dis = camera.position.distanceTo(target)
    const zoom = camera.zoom
    const lv = getZoomLvevl(dis, zoom, camera)
    return lv
  }

  // 旋转的回调
  let rotateBlock = null
  controls.rotateBlockAction = function(callback) {
    rotateBlock = callback
  }

  let zoomBlock = null
  controls.zoomBlockAction = function(callback) {
    zoomBlock = callback
  }

  let panBlock = null
  controls.panBlockAction = function(callback) {
    panBlock = callback
  }

  controls.getZoomScaleNumber = function() {
    const zoom = controls.object.zoom
    return zoom
  }

  controls.getRotateXZ = function() {
    let angleXZ = this.getAzimuthalAngle()
    if (angleXZ < 0) {
      angleXZ = Math.PI * 2 + angleXZ
    }
    return angleXZ
  }

  // 获取y旋转的角度
  controls.getRotateY = function() {
    return this.getPolarAngle()
  }

  controls.domElement.addEventListener('touchstart', onTouchStart, false)
  controls.domElement.addEventListener('touchmove', onTouchMove, false)
  controls.domElement.addEventListener('touchend', onTouchEnd, false)

  controls.domElement.addEventListener('mousedown', onMouseDown, false)
  controls.domElement.addEventListener('mousemove', onMouseMove, false)
  controls.domElement.addEventListener('mouseup', onMouseUp, false)

  controls.domElement.addEventListener('wheel', onMouseWheel, false)

  let touch = null

  function onTouchStart(event) {
    touch = event
  }

  function onTouchMove(event) {
    if (touch == null) {
      return
    }
    if (panBlock) {
      panBlock()
    }
  }

  function onTouchEnd(event) {
    touch = null
  }

  let mouse = null

  function onMouseDown(event) {
    mouse = event
  }

  function onMouseMove(event) {
    if (mouse == null) {
      return
    }
    if (panBlock) {
      panBlock()
    }
  }

  function onMouseUp(event) {
    mouse = null
  }

  function onMouseWheel(event) {
    if (panBlock) {
      panBlock()
    }
  }

  /*  ---------------- 旋转角度 ----------------  */
  /* 切换成2D */
  controls.changeTo2D = function(time, minPolarAngle, updateCall) {
    this.controlCameraAniamtion({}, {
      angleY: minPolarAngle,
      angleXZ: this.getRotateXZ()
    }, {}, {
      time: time
    }, () => {
      updateCall()
    }, () => {
      updateCall()
    })
  }

  // 地图切换成3d
  controls.changeTo3D = function(maxPolarAngle, minPolarAngle, time, updateCall) {
    this.maxPolarAngle = maxPolarAngle
    this.minPolarAngle = minPolarAngle
    this.controlCameraAniamtion({}, {
      angleY: maxPolarAngle,
      angleXZ: this.getRotateXZ()
    }, {}, {
      time: time
    }, () => {
      updateCall()
    }, () => {
      updateCall()
    })
  }

  function animationEnd() {
    if (cameraMoveInfo == null) {
      return false
    }
    const p = controls.object.position
    const toP = cameraMoveInfo.position
    if (p.x.toFixed(2) == toP.x.toFixed(2) && p.y.toFixed(2) == toP.y.toFixed(2) && p.z.toFixed(2) == toP.z.toFixed(
      2)) {
      return true
    }
    return false
  }

  /*  ---------------- **** ----------------  */

  /*  ---------------- 缩放----------------  */
  controls.updateCameraInitalPosition = function(config) {
    const camera = controls.object
    if (camera == null) {
      return
    }
    const border = config['border']
    const leftUp = border['leftUp']
    const rightDown = border['rightDown']
    const leftUpX = leftUp.x
    const leftUpZ = leftUp.y
    const rightDownX = rightDown.x
    const rightDownZ = rightDown.y
    const width = (rightDownX - leftUpX) * 0.05
    const height = (rightDownZ - leftUpZ) * 0.05
    const result = zoomWithTwoPoints({
      x: leftUpZ,
      z: leftUpZ
    }, {
      x: rightDownX,
      z: rightDownZ
    }, width, height)
    controls.target.x = 0
    controls.target.y = 0
    controls.target.z = 0

    camera.position.x = 0
    camera.position.z = 0
    const y = camera.position.y < result.dis ? result.dis : camera.position.y
    let moveY = true
    if (analysis.jsonKeyIsExist(config, ['moveY'])) {
      moveY = config['moveY']
    }
    if (moveY) {
      camera.position.y = y
    }

    let forceDis = false
    if (analysis.jsonKeyIsExist(config, ['forceDis'])) {
      forceDis = config['forceDis']
    }

    const maxDistance = forceDis ? result.dis : controls.maxDistance < result.dis ? result.dis : controls
      .maxDistance
    controls.maxDistance = maxDistance
  }

  const zoomMapInfo = {} // 缩放过一次的地图下一次不会再次缩放

  controls.zoomMapToFixSize = function(mapid, windowWidth, windowHeight, scale, mapPoints, finishCall) {
    if (mapid == '') {
      return
    }
    if (config.zoomSize) {
      for (const id in zoomMapInfo) {
        // 缩放过一次后就不缩放了
        finishCall()
        return false
      }
    }
    zoomMapInfo[mapid] = 1
    this.controlCameraAniamtion({
      target: {
        x: 0,
        y: 0,
        z: 0
      }
    }, {
      angleY: 0.01,
      angleXZ: 0
    }, {
      leftUp: {
        x: mapPoints.leftUp.x,
        y: 0,
        z: mapPoints.leftUp.y
      },
      rightDown: {
        x: mapPoints.rightDown.x,
        y: 0,
        z: mapPoints.rightDown.y
      }
    }, {
      time: 100,
      zoomSize: true
    }, () => {}, () => {
      finishCall()
    })
    setTimeout(function() {
      finishCall()
    }, 150)
    return true
  }

  // 计算地图缩放到下一个等级
  controls.zoomNextLevel = function(zoomOut /* 是否放大 true为放大 false为缩小 */, time, updateCall, completeCall) {
    const camera = this.object
    const target = this.target
    const scale = 10.0
    if (tween != null) {
      tween.stop()
    }
    const orgin = new Vector3(target.x, target.y, target.z)
    const cameraPosition = new Vector3(camera.position.x, camera.position.y, camera.position.z)
    const ray = new Ray(orgin, cameraPosition.sub(orgin).normalize())
    const distance = this.maxDistance - this.minDistance
    const radius = orgin.distanceTo(camera.position)
    const space = distance / scale
    let dis = radius + space * (zoomOut ? -1 : 1)
    if (dis <= this.minDistance) {
      dis = this.minDistance
    }
    if (dis >= this.maxDistance) {
      dis = this.maxDistance
    }
    let position = new Vector3(camera.position.x, camera.position.y, camera.position.z)
    ray.at(dis, position)
    const newPosition = {
      x: position.x,
      y: position.y,
      z: position.z
    }
    const starttween = tween
      .to(newPosition, time)
      .onUpdate(function() {
        updateCall()
      })
    starttween.start()
  }
  /*  ---------------- **** ----------------  */

  let cameraTween = null
  let tween = null
  controls.setUpTween = function() {
    tween = new TWEEN.Tween(this.object.position)
  }

  controls.setUpTween()

  controls.stopAnimation = function() {
    if (tween != null) {
      tween.stop()
    }
  }

  controls.deleteAll = function() {
    controls.domElement.removeEventListener('wheel', onMouseWheel)
    controls.domElement.removeEventListener('touchstart', onTouchStart)
    controls.domElement.removeEventListener('touchmove', onTouchMove)
    controls.domElement.removeEventListener('touchend', onTouchEnd)
    controls.domElement.removeEventListener('mousedown', onMouseDown)
    controls.domElement.removeEventListener('mousemove', onMouseMove)
    controls.domElement.removeEventListener('mouseup', onMouseUp)

    if (tween != null) {
      tween.stop()
    }
    tween = null
    TWEEN.removeAll()
  }

  controls.return3Dor2DMsg = function() {
    const angleY = this.getRotateY()
    if (angleY <= 10 / 180 * Math.PI) {
      return '2D'
    } else {
      return '3D'
    }
  }

  controls.getHeight = function(p1, p2, w, h, aspect) {
    const camera = controls.object
    let width = Math.abs(p1.x - p2.x) + w * 2
    let height = Math.abs(p1.z - p2.z) + h * 2
    if (height <= 50) {
      height = 50
    }
    if (width <= 50) {
      width = 50
    }
    let fovAngle = 75
    if (analysis.jsonKeyIsExist(camera, ['fov'])) {
      fovAngle = camera.fov
    }
    const fov = fovAngle / 180 * Math.PI
    const getH = height / 2.0 / Math.tan(fov / 2.0)
    const getW = width / 2.0 / Math.tan(fov / 2.0) / aspect
    return (getH >= getW ? getH : getW) / 1.5
  }

  function zoomWithTwoPoints(p1, p2, w, h) {
    const camera = controls.object
    let width = Math.abs(p1.x - p2.x) + w * 2
    let height = Math.abs(p1.z - p2.z) + h * 2
    if (height <= 50) {
      height = 50
    }
    if (width <= 50) {
      width = 50
    }
    const fov = camera.fov / 180 * Math.PI
    const aspect = camera.aspect
    const getH = height / 2.0 / Math.tan(fov / 2.0)
    const getW = width / 2.0 / Math.tan(fov / 2.0) / aspect
    return {
      result: true,
      dis: getH >= getW ? getH : getW
    }
  }

  function rotatePostion(angleY, angleXZ, radius) {
    const x = radius * Math.sin(angleY) * Math.sin(angleXZ)
    const z = radius * Math.sin(angleY) * Math.cos(angleXZ)
    const y = parseFloat((radius * Math.cos(angleY)).toFixed(1))
    return {
      x: x,
      y: y,
      z: z
    }
  }

  // 控制相机平移 旋转 缩放
  controls.controlCameraAniamtion = function(transLateInfo, rotateInfo, scaleInfo, config, updateCall, completeCall) {
    let moveY = false
    if (analysis.jsonKeyIsExist(config, ['moveY'])) {
      moveY = config.moveY
    }

    if (cameraTween != null) {
      cameraTween.stop()
      cameraTween = null
    }
    const camera = controls.object
    let target = null
    if (analysis.jsonKeyIsExist(transLateInfo, ['target'])) {
      target = transLateInfo['target']
    } else if (analysis.jsonKeyIsExist(cameraMoveInfo, ['target'])) {
      target = cameraMoveInfo['target']
    } else {
      target = controls.target
    }

    let radius = 0
    let zoom = camera.zoom
    if (analysis.jsonKeyIsExist(cameraMoveInfo, ['radius'])) {
      radius = cameraMoveInfo['radius']
    } else {
      const p = camera.position
      radius = Math.sqrt((target.x - p.x) * (target.x - p.x) + (0 - p.y) * (0 - p.y) + (target.z - p.z) * (target
        .z - p.z))
    }

    // 先缩放
    if (analysis.jsonKeyIsExist(scaleInfo, ['leftUp', 'rightDown'])) {
      const leftUp = scaleInfo['leftUp']
      const rightDown = scaleInfo['rightDown']
      target.x = (leftUp.x + rightDown.x) / 2.0
      target.z = (leftUp.z + rightDown.z) / 2.0
      const windowWidth = config.windowWidth
      const windowHeight = config.windowHeight

      let w = 0
      if (analysis.jsonKeyIsExist(scaleInfo, ['w'])) {
        w = scaleInfo['w']
      }

      let h = 0
      if (analysis.jsonKeyIsExist(scaleInfo, ['h'])) {
        h = scaleInfo['h']
      }

      const result = zoomWithTwoPoints(leftUp, rightDown, w, h)
      radius = result.dis

      if (analysis.jsonKeyIsExist(config, ['zoomSize'])) {
        controls.maxDistance = result.dis
      }
    }

    let angleY = this.getRotateY()
    if (analysis.jsonKeyIsExist(rotateInfo, ['angleY'])) {
      angleY = rotateInfo['angleY']
    } else if (analysis.jsonKeyIsExist(cameraMoveInfo, ['angleY'])) {
      angleY = cameraMoveInfo['angleY']
    }

    let angleXZ = this.getRotateXZ()
    if (analysis.jsonKeyIsExist(rotateInfo, ['angleXZ'])) {
      angleXZ = rotateInfo['angleXZ']
    } else if (analysis.jsonKeyIsExist(cameraMoveInfo, ['angleXZ'])) {
      angleXZ = cameraMoveInfo['angleXZ']
    }

    // 后旋转
    const newP = rotatePostion(angleY, angleXZ, radius)
    // 在平移
    newP.x = newP.x + target.x
    newP.z = newP.z + target.z
    if (moveY) {
      newP.y = newP.y + target.y
    }

    const oldPro = {
      zoom: camera.zoom,
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
      tx: controls.target.x,
      tz: controls.target.z,
      ty: controls.target.y
    }

    const toPro = {
      zoom: zoom,
      x: newP.x,
      y: newP.y,
      z: newP.z,
      tx: target.x,
      tz: target.z,
      ty: target.y
    }

    cameraMoveInfo = {
      target: target,
      radius: radius,
      angleY: angleY,
      angleXZ: angleXZ,
      zoom: zoom,
      position: newP
    }

    cameraTween = new TWEEN.Tween(oldPro).to(toPro, config.time).onUpdate((o) => {
      camera.position.x = oldPro.x
      camera.position.z = oldPro.z
      camera.position.y = oldPro.y
      camera.zoom = oldPro.zoom
      controls.target.x = oldPro.tx
      controls.target.z = oldPro.tz
      if (moveY) {
        controls.target.y = oldPro.ty
      }
      camera.updateProjectionMatrix()
      if (animationEnd()) {
        cameraMoveInfo = null
        completeCall()
      } else {
        updateCall()
      }
    })
    cameraTween.start()
  }

  // 将范围校正选择的lv内
  controls.judgeFence = function(config) {
    function getFixSize(leftUp, rightDown, camera, judgeLV) {
      const result = zoomWithTwoPoints(leftUp, rightDown, 0, 0)
      const getLv = getZoomLvevl(result.dis, result.zoom, camera)
      if (getLv < judgeLV) {
        return {
          leftUp: leftUp,
          rightDown: rightDown
        }
      }
      const scale = getLv / judgeLV * 1.1
      if (scale <= 0) {
        return {
          leftUp: leftUp,
          rightDown: rightDown
        }
      }

      const width = rightDown.x - leftUp.x
      const height = rightDown.z - leftUp.z

      const centerX = leftUp.x + width / 2.0
      const centerZ = leftUp.z + height / 2.0

      const newWidth = width / scale
      const newHeight = height / scale

      const newLeftUp = {
        x: centerX - newWidth / 2.0,
        y: 0,
        z: centerZ - newHeight / 2.0
      }

      const newRightDown = {
        x: centerX + newWidth / 2.0,
        y: 0,
        z: centerZ + newHeight / 2.0
      }
      return getFixSize(newLeftUp, newRightDown, camera, judgeLV)
    }

    const hiddenExtra = config['hiddenExtra']
    const leftUp = config['leftUp']
    const rightDown = config['rightDown']
    if (hiddenExtra == false) {
      return {
        leftUp: leftUp,
        rightDown: rightDown
      }
    }
    const judgeLV = config['judgeLV']
    const camera = config['camera']

    return getFixSize(leftUp, rightDown, camera, judgeLV)
  }

  controls.getCameraSetting = function(config) {
    const camera = controls.object
    const position = camera.position
    const zoom = camera.zoom
    // console.log(position);
    // console.log(target);
    // console.log(zoom);

    // let angleY = this.getRotateY()
    //
    // let angleXZ = this.getRotateXZ()

    const target = controls.target

    const setting = {
      position: {
        x: position.x.toFixed(2),
        y: position.y.toFixed(2),
        z: position.z.toFixed(2)
      },
      zoom: zoom,
      // angleY:angleY,
      // angleXZ:angleXZ,
      target: {
        x: target.x.toFixed(2),
        y: target.y.toFixed(2),
        z: target.z.toFixed(2)
      }
    }
    return setting
  }

  controls.updateOrgSetting = function(config, updateCall, completeCall) {
    if (analysis.jsonKeyIsExist(config, ['position']) == false) {
      completeCall();
      return
    }

    if (analysis.jsonKeyIsExist(config, ['target']) == false) {
      completeCall();
      return;
    }

    if (cameraTween != null) {
      cameraTween.stop()
      cameraTween = null
    }
    const camera = controls.object

    const oldPro = {
      zoom: camera.zoom,
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
      tx: controls.target.x,
      tz: controls.target.z,
      ty: controls.target.y
    }

    const position = config['position']
    const target = config['target']
    let zoom = config['zoom']
    zoom = 1

    const toPro = {
      zoom: parseFloat(zoom),
      x: parseFloat(position.x),
      y: parseFloat(position.y),
      z: parseFloat(position.z),
      tx: parseFloat(target.x),
      tz: parseFloat(target.z),
      ty: parseFloat(target.y)
    }

    cameraTween = new TWEEN.Tween(oldPro).to(toPro, config.time).onUpdate((o) => {
      camera.position.x = oldPro.x
      camera.position.z = oldPro.z
      camera.position.y = oldPro.y
      camera.zoom = oldPro.zoom
      controls.target.x = oldPro.tx
      controls.target.z = oldPro.tz
      if (oldPro.x.toFixed(2) == toPro.x.toFixed(2) && oldPro.y.toFixed(2) == toPro.y.toFixed(2) && oldPro.z
        .toFixed(2) == toPro.z.toFixed(2)) {
        cameraMoveInfo = null
        completeCall()
      } else {
        updateCall()
      }
    })
    cameraTween.start()
  }

  return controls
}

export {
  createNewOrbitControls
}
