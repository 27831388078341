import * as THREE from 'three/build/three.min.js'
import * as h337 from 'heatmap.js/build/heatmap.min.js'
import {
  createRoomObject
} from '../floor/floorWall'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'

// 热力图
export function createHeatMap() {
  const heatMap = new THREE.Group()

  let heatGroupInfo = {}

  let zoneGroupInfo = {} // 区域提示

  let heatInfo = {}

  const analysis = createAnalaysisInfo() // 构造地图解析

  const makeRoom = createRoomObject()

  heatMap.showHeatZone = function(config) {
    function createPlane(areas, config) {
      const planeShape = new THREE.Shape()
      for (let i = 0; i < areas.length; i++) {
        const p = areas[i]
        if (i == 0) {
          planeShape.moveTo(p[0], p[2])
        } else {
          planeShape.lineTo(p[0], p[2])
        }
      }
      const geometry = new THREE.ShapeGeometry(planeShape)
      const heatMapMaterial = new THREE.MeshBasicMaterial({
        color: 0xff0000,
        transparent: true,
        side: THREE.DoubleSide,
        opacity: 0.3,
        depthTest: false,
        polygonOffset: true,
        polygonOffsetFactor: config.polygonOffsetFactor - 2,
        polygonOffsetUnits: config.polygonOffsetUnits - 20
      })
      const mesh = new THREE.Mesh(geometry, heatMapMaterial)
      mesh.position.set(0, 0, 0)
      mesh.rotateX(Math.PI / 2.0)
      return mesh
    }

    const zoneInfo = config['zoneInfo']
    if (zoneInfo.length == 0) {
      return
    }
    const mapid = config['mapid'] + ''
    if (analysis.jsonKeyIsExist(zoneGroupInfo, [mapid]) == false) {
      const group = new THREE.Group()
      heatMap.add(group)
      zoneGroupInfo[mapid] = group
    }
    for (const gMapid in zoneGroupInfo) {
      const g = zoneGroupInfo[gMapid]
      g.visible = gMapid == mapid
    }
    const g = zoneGroupInfo[mapid]
    const areaid = config['areaid']
    const children = g.children
    let shouldCreate = true
    let select = true
    if (analysis.jsonKeyIsExist(config, ['select'])) {
      select = config['select']
    }
    for (let i = 0; i < children.length; i++) {
      const m = children[i]
      if (m.uuid == areaid) {
        shouldCreate = false
        m.visible = select
      } else {
        m.visible = false
      }
    }
    if (shouldCreate == false || select == false) {
      return
    }
    for (let i = 0; i < zoneInfo.length; i++) {
      const zone = zoneInfo[i]
      if (zone['areaid'] == areaid) {
        const m = createPlane(zone['areas'], {})
        m.uuid = areaid
        g.add(m)
        break
      }
    }
  }

  heatMap.deleteAll = function() {
    for (let i = 0; i < heatMap.children.length; i++) {
      const g = heatMap.children[i]
      heatMap.remove(g)
      if (g.type == 'Group') {
        for (let j = 0; j < g.children.length; j++) {
          const mesh = g.children[j]
          g.remove(mesh)
          mesh.material.dispose()
          mesh.geometry.dispose()
        }
      } else if (g.type == 'Mesh') {
        g.material.dispose()
        g.geometry.dispose()
      }
    }
    zoneGroupInfo = {}
    heatGroupInfo = {}
    heatInfo = {}
  }

  heatMap.createHeatMapCanvas = function(config, group, commonGroup) {

    function setHeatMapData(data, leftUp) {
      let max = 0
      let min = 0
      const points = []
      for (let i = 0; i < data.length; i++) {
        const dataInfo = data[i]
        const value = dataInfo.value * 10
        max = max > value ? max : value
        min = min < value ? min : value
        const info = {
          x: parseInt(dataInfo.x - leftUp.x),
          y: parseInt(dataInfo.z - leftUp.z),
          value: value
        }
        points.push(info)
      }
      return {
        max: max,
        min: min,
        data: points
      }
    }

    heatMap.visible = true
    const mapid = config.mapid
    if (analysis.jsonKeyIsExist(heatInfo, [mapid])) {
      if (analysis.jsonKeyIsExist(config, ['update']) && config['update'] == true) {
        heatMap.deleteAll()
      } else {
        for (const id in heatGroupInfo) {
          const existGroup = heatGroupInfo[id]
          existGroup.visible = id == mapid
        }
        return
      }
    }

    let zeroGroup = null
    if (config.useCommon == false) {
      zeroGroup = group.getObjectByName('zero')
    } else {
      zeroGroup = commonGroup.getObjectByName('zero')
    }
    if (typeof zeroGroup == 'undefined' || zeroGroup == null) {
      return
    }
    const zeroGroupChildren = zeroGroup.children
    if (zeroGroupChildren.length == 0) {
      return
    }

    const divId = config.divId
    const fence = zeroGroup.fence
    const div = document.getElementById(divId)
    const application = document.createElement('div')
    div.appendChild(application)

    const width = fence.rightDown.x - fence.leftUp.x
    const height = fence.rightDown.z - fence.leftUp.z
    application.style.width = width + 'px'
    application.style.height = height + 'px'
    const setting = {
      container: application,
      radius: 30,
      maxOpacity: 1,
      minOpacity: 0.1,
      blur: 0.75,
      gradient: {
        // enter n keys between 0 and 1 here
        // for gradient color customization
        '.5': '#0000ff',
        '.7': 'green',
        '.9': 'red'
      }
    }


    const heatmapInstance = h337.create(setting)
    const infoList = setHeatMapData(config.data, fence.leftUp)
    heatmapInstance.setData(infoList)
    const canvas = application.getElementsByTagName('canvas')[0]

    let g = null
    if (analysis.jsonKeyIsExist(heatGroupInfo, [mapid])) {
      g = heatGroupInfo[mapid]
    } else {
      g = new THREE.Group()
      heatMap.add(g)
      heatGroupInfo[mapid] = g
    }



    for (const id in heatGroupInfo) {
      const existGroup = heatGroupInfo[id]
      existGroup.visible = id == mapid
    }

    groupAddHeatMap(canvas, group, commonGroup, config, width, height, g, () => {
      div.removeChild(application)
    })
  }

  function groupAddHeatMap(canvas, group, commonGroup, config, width, height, zeroGroupChildren, callBack) {
    const time = 10

    function createMesh(zero, geometry, config) {
      return new Promise(resolve => {
        const heatMapMaterial = new THREE.MeshBasicMaterial({
          transparent: true,
          side: THREE.DoubleSide,
          opacity: 1.0,
          depthTest: false,
          polygonOffset: true,
          polygonOffsetFactor: config.polygonOffsetFactor - 2,
          polygonOffsetUnits: config.polygonOffsetUnits - 20
        })

        const mesh = new THREE.Mesh(geometry, heatMapMaterial)
        zero.add(mesh)
        mesh.position.set(0, 0, 0)
        mesh.rotateX(Math.PI / 2.0)
        setTimeout(() => {
          resolve(heatMapMaterial)
        }, time)
      })
    }

    function judgeAssignUVs(geometry, shapeList, length) {
      return new Promise(resolve => {
        makeRoom.judgeAssignUVs(geometry, shapeList, length)
        setTimeout(() => {
          resolve(geometry)
        }, time)
      })
    }

    function createGeo(config) {
      return new Promise(resolve => {
        const zeroAeras = config.zeroAeras
        const planeShape = new THREE.Shape()
        const shapeList = []
        let length = 0
        for (let i = 0; i < zeroAeras.length; i++) {
          const p = zeroAeras[i]
          if (i == 0) {
            planeShape.moveTo(p[0], p[2])
          } else {
            planeShape.lineTo(p[0], p[2])
          }
          let next = i + 1
          if (next >= zeroAeras.length) {
            next = 0
          }
          const nextP = zeroAeras[next]
          const nx = parseFloat(nextP[0].toFixed(2))
          const nz = parseFloat(nextP[2].toFixed(2))
          const dis = Math.sqrt((nx - p[0]) * (nx - p[0]) + (nz - p[2]) * (nz - p[2]))
          if (dis == 0) {
            continue
          }
          const info = {
            dis: dis,
            length: length,
            width: dis,
            height: height
          }
          shapeList.push(info)
          length = dis + length
        }
        setTimeout(() => {
          resolve({
            geo: new THREE.ShapeGeometry(planeShape),
            shapeList: shapeList,
            length: length
          })
        }, time)
      })
    }

    if (zeroGroupChildren.length == 0) {
      return
    }
    setUpGroupAlpha(group, {
      returnAlpha: false,
      alpha: 0.3,
      returnColor: false,
      color: '#ffffff'
    })
    // setUpGroupAlpha(commonGroup, {
    //   returnAlpha: false,
    //   alpha: 0.3,
    //   returnColor: false,
    //   color: '#ffffff'
    // })


    // let zero = zeroGroupChildren[0];

    const zero = zeroGroupChildren
    createGeo(config).then(res => {
      return judgeAssignUVs(res['geo'], res['shapeList'], res['length'])
    }).then(res => {
      return createMesh(zero, res, config)
    }).then(res => {
      heatInfo[config.mapid] = '1'
      const image = new Image()
      image.width = width
      image.height = height
      image.src = canvas.toDataURL('image/png')
      image.onload = function() {
        callBack()
        setTimeout(() => {
          makeRoom.updateRoomTexttrue(res, image.src, zero['clockwise'])
        }, 100)
      }
    })
  }

  function changeAlpha(mesh, config) {
    const returnAlpha = config.returnAlpha
    const returnColor = config.returnColor
    if (analysis.jsonKeyIsExist(mesh, ['type']) && mesh.type == 'Mesh') {
      const material = mesh.material
      if (Array.isArray(material)) {
        for (let j = 0; j < material.length; j++) {
          const m = material[j]
          if (returnAlpha) {
            m.opacity = m['alpha']
          } else {
            m['alpha'] = m.opacity
            m.opacity = config.alpha
          }
          // if (returnColor) {
          //   if (analysis.jsonKeyIsExist(m, ['saveColor'])) {
          //     m.color.set(JSON.parse(m['saveColor']))
          //   }
          // } else {
          //   m['saveColor'] = JSON.stringify(m.color);
          //   m.color.set(config['color'])
          // }
        }
      } else {
        if (returnAlpha) {
          material.opacity = material['alpha']
        } else {
          material['alpha'] = material.opacity
          material.opacity = config.alpha
        }
        // if (returnColor) {
        //   if (analysis.jsonKeyIsExist(material, ['saveColor'])) {
        //     material.color.set(JSON.parse(material['saveColor']))
        //   }
        // } else {
        //   material['saveColor'] = JSON.stringify(material.color);
        //   material.color.set(config['color'])
        // }
      }
    }
  }

  function setUpGroupAlpha(g, config) {
    for (let i = 0; i < g.children.length; i++) {
      const mesh = g.children[i]
      if (analysis.jsonKeyIsExist(mesh, ['type'])) {
        if (mesh.type == 'Mesh') {
          changeAlpha(mesh, config)
        } else if (mesh.type == 'Group') {
          setUpGroupAlpha(mesh, config)
        }
      }
    }
  }

  return heatMap
}
