import * as THREE from 'three/build/three.min.js'
import * as TWEEN from 'tween/tween.js'
import {createObjectAction} from '../createObject'
import {createAnalaysisInfo} from '../../analaysis/dataAnalaysisInfo'
import {detailMapConfig} from '../../MapConfig'

// 楼层文字
export function createFloorTitle() {
  let analysis = createAnalaysisInfo() // 构造地图解析

  let detailConfig = detailMapConfig()

  let create = createObjectAction()

  let group = new THREE.Group()

  let alertText = []

  let rotates = []

  let texts = [] // 文字

  let images = [] // 图标

  let textPosition = {}

  let alertTextIndex = 0

  let textIndex = 0

  let imageIndex = 0

  let animationIndex = []

  let mapImageUrl = {
    louti: getRootPath_dc() + '/louti.png',
    dianti: getRootPath_dc() + '/dianti.png',
    futi: getRootPath_dc() + '/futi.png'
  }

  function getRootPath_dc() {
    return './static/iconImage'
  }

  function returnPointImage(type) {
    let image = mapImageUrl.qidianPhone
    switch (parseInt(type)) {
      case leadPointType.louti:
        image = mapImageUrl.louti
        break
      case leadPointType.dianti:
        image = mapImageUrl.dianti
        break
      case leadPointType.futi:
        image = mapImageUrl.futi
        break
      case leadPointType.end:
        image = mapImageUrl.zhongdianPhone
        break
      default:
        break

    }
    return image
  }

  let leadPointType = {
    start: 5,
    end: 4,
    louti: 1,
    dianti: 2,
    futi: 3,
    normal: 0,
    po: 99
  }

  group.updateZoneText = function (list) {
    for (let i = 0; i < list.length; i++) {
      let sprite = list[i]
      group.add(sprite)
    }
    rotates = list
  }

  group.showOrHiddenZoneText = function (config) {
    let findZoneId = config['zoneId'];
    for (let i = 0; i < texts.length; i++) {
      let text = texts[i]
      let zoneId = text['inZone']
      if (findZoneId == '') {
        text.visible == true
      } else {
        let visible = findZoneId == zoneId
        text.visible = visible
      }
    }
  }

  group.getZoneText = function (config) {
    let findZoneId = config.zoneId
    let list = []
    for (let i = 0; i < texts.length; i++) {
      let text = texts[i]
      if (text.visible == false) {
        continue
      }
      let zoneId = text['inZone']
      if (zoneId != findZoneId) {
        continue
      }
      if (analysis.jsonKeyIsExist(text, ['setting']) == false) {
        continue
      }
      let cloneText = detailConfig.textShouldClone ? text.clone() : create.createTitle(text['setting'])
      let keys = ['cWidth', 'cHeight', 'inZone', 'multiple', 'setting', 'show', 'strWidth', 'title']
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j]
        if (analysis.jsonKeyIsExist(text, [key]) == false) {
          continue
        }
        if (key == 'setting') {
          cloneText[key] = JSON.parse(JSON.stringify(text[key]))
        } else {
          cloneText[key] = text[key]
        }
      }
      list.push(cloneText)
    }
    list = list.sort((a, b) => {
      let setting1 = a.setting
      let setting2 = b.setting
      let lv1 = setting1.titleLV
      let lv2 = setting2.titleLV
      return lv1 - lv2
    })
    return list
  }

  group.advanceAnimation = function () {
    let list = texts.concat(images)
    let count = 150
    for (let i = 0; i < list.length; i++) {
      let mesh = list[i]
      let show = (i % count == 0)
      mesh['show'] = show
      mesh.material.opacity = show ? 1.0 : 0.0
    }
  }

  group.updateTextByPromise = function (list, setting, callBack) {
    function updateSprite(list, index, setting, callBack) {
      if (index >= list.length) {
        callBack();
        return;
      }
      let data = list[index]
      addOrUpdateSprite(data, setting)
      setTimeout(() => {
        updateSprite(list, index + 1, setting, callBack);
      }, 50)
    }

    textIndex = 0
    imageIndex = 0
    alertTextIndex = 0

    updateSprite(list, 0, setting, () => {
      updateTextList(textIndex, imageIndex, alertTextIndex);
      callBack();
    })
  }

  group.updateText = function (list, setting, leadInfo) {
    textIndex = 0
    imageIndex = 0
    alertTextIndex = 0
    for (let i = 0; i < list.length; i++) {
      let data = list[i]
      addOrUpdateSprite(data, setting)
    }
    let isShowLinkText = setting.isShowLinkText
    let layerHeight = setting.layerHeight
    for (let key in leadInfo) {
      let info = leadInfo[key]
      if (analysis.jsonKeyIsExist(info, ['type']) == true && (info.type == leadPointType.futi || info.type == leadPointType.dianti || info.type == leadPointType.louti)) {
        if (isShowLinkText) {
          updateOrAddAlertTextSprite(info, layerHeight, setting)
        } else {
          setting['scale'] = 0.1
          setting['centerY'] = 0.43
          setting['multiple'] = 30
          setting['titleLV'] = 2
          updateOrAddImageSprite(returnPointImage(info.type), {
            x: info.x,
            y: layerHeight,
            z: info.y
          }, setting)
        }
      }
    }
    updateTextList(textIndex, imageIndex, alertTextIndex);
  }

  group.updateMapTitle = function(roomInfo, position, config) {
    for (let i = 0; i < texts.length; i++) {
      let text = texts[i];
      let setting = text['setting'];
      if (setting['areaid'] == roomInfo['areaid']) {
        setting['title'] = roomInfo['title'];
        create.updateTitle(text, setting)
        text['setting'] = setting
        return
      }
    }

    updateOrAddTextSprite(roomInfo, position, config)
  }

  function updateTextList(textIndex, imageIndex, alertTextIndex) {
    if (textIndex < texts.length) {
      for (let i = textIndex; i < texts.length; i++) {
        let t = texts[i]
        t.visible = false
      }
    }
    if (imageIndex < images.length) {
      for (let i = imageIndex; i < images.length; i++) {
        let t = images[i]
        t.visible = false
      }
    }
    if (alertTextIndex < alertText.length) {
      for (let i = alertTextIndex; i < alertText.length; i++) {
        let t = alertText[i]
        t.visible = false
      }
    }

    let filters = texts.concat(images).filter(a => {
      return a.visible
    })

    rotates = filters.sort((a, b) => {
      let setting1 = a.setting
      let setting2 = b.setting
      let lv1 = setting1.titleLV
      let lv2 = setting2.titleLV
      return lv1 - lv2
    })
  }

  function addOrUpdateSprite(roomInfo, config) {
    let title = roomInfo['title']
    let image = roomInfo['image']
    if (title == '' && image == '') {
      return {
        result: false
      }
    }
    let mapid = config.mapid
    let areaId = roomInfo['areaid']
    let key = mapid + areaId
    let height = roomInfo['height']
    if (analysis.jsonKeyIsExist(textPosition, [key]) == false) {
      let points = roomInfo['areas']
      let polygon = analysis.getPolygonAreaCenter(points)
      textPosition[key] = {
        x: polygon[1],
        y: height + config.y,
        z: polygon[0]
      }
    }
    let position = textPosition[key]
    if (image != '') {
      config['multiple'] = 15
      updateOrAddImageSprite(image, position, config)
    } else if (title != '') {
      return updateOrAddTextSprite(roomInfo, position, config)
    }
    return {
      result: false
    }
  }

  function updateOrAddAlertTextSprite(info, layerHeight, config) {
    let msg = info['link']
    let textColor = '#ffffff'
    let bgColor = '#28df99'
    if (msg.search('D') >= 0) {
      bgColor = '#d57149'
    } else if (msg.search('F') >= 0) {
      bgColor = '#40a8c4'
    }
    let zoomNum = 1
    let setting = {
      position: {x: info.x, y: layerHeight, z: info.y},
      type: 'important',
      title: msg,
      textColor: textColor,
      bgColor: bgColor,
      multiple: 20,
      centerY: 0,
      mode: config.mode,
      depthTest: false,
      zoomNum: zoomNum,
      titleLV: 1
    }
    updateAlert(setting)
  }

  function updateAlert(setting) {
    if (alertTextIndex < alertText.length) {
      let text = alertText[alertTextIndex]
      create.updateTitle(text, setting)
      text.setting = setting
      text.visible = true
    } else {
      let text = create.createTitle(setting)
      text.setting = setting
      alertText.push(text)
      group.add(text)
    }
    alertTextIndex = alertTextIndex + 1
  }

  group.updateAlertTextDataList = function (dataList, config) {
    let textColor = '#ffffff'
    let bgColor = '#ff0000'
    for (let i = 0; i < dataList.length; i++) {
      let item = dataList[i]
      let setting = {
        position: {x: item.x, y: config.y, z: item.z},
        type: 'important',
        title: item.msg,
        textColor: textColor,
        bgColor: bgColor,
        multiple: 20,
        centerY: 0,
        mode: config.mode,
        depthTest: false,
        zoomNum: 1.1,
        titleLV: 1
      }
      updateAlert(setting)
    }
  }

  function updateOrAddTextSprite(roomInfo, position, config) {
    if (analysis.jsonKeyIsExist(roomInfo, ['title']) == false) {
      return {
        result: false
      }
    }
    let title = roomInfo['title'];
    if (title == '') {
      return {
        result: false
      }
    }
    // let matchTransMatrix = new RegExp('(茶水|驾驶员|备用|配餐间|水电房|储藏室|储物室|物品柜|会议室|电井|沟通室|缓冲|餐厅|体验室|备用间|病房|财务|清洗室|控制室|实验室|休息室|办公室|女卫|男卫|女洗手间|男洗手间|洗手间|机房|卫生间|残卫|值班室|配电间|更衣|库房|污物|污洗|弱电|电气|强电|水井)', 'gmi')
    //
    // let matchRep = title.match(matchTransMatrix)
    // if (matchRep) {
    //   return {
    //     result: false
    //   }
    // }
    let inZone = ''
    if (analysis.jsonKeyIsExist(roomInfo, ['inZone'])) {
      inZone = roomInfo['inZone']
    }

    let zoneTitle = ''
    if (analysis.jsonKeyIsExist(roomInfo, ['zoneTitle'])) {
      zoneTitle = roomInfo['zoneTitle']
    }

    let titleLV = roomInfo['titleLV']
    let textColor = '#666666'
    let bgColor = titleLV <= 1 ? '#fffde8' : '#ffffff'
    let zoomNum = titleLV <= 1 ? 2 : 1.1
    let textType = 'normal'
    let multiple = titleLV > 0 ? 10 : 20
    let setting = {
      position: position,
      type: textType,
      title: title,
      textColor: textColor,
      bgColor: bgColor,
      multiple: multiple,
      centerY: 0,
      mode: config.mode,
      depthTest: false,
      zoomNum: zoomNum,
      titleLV: titleLV,
      areaid:roomInfo['areaid']
    }
    if (textIndex < texts.length) {
      let text = texts[textIndex]
      text.visible = true
      text['show'] = true
      let oldSetting = text.setting
      let styleChange = false
      let positionChange = false
      for (let key in oldSetting) {
        if (key == 'position') {
          let oldP = oldSetting[key]
          if (oldP.x != position.x || oldP.y != position.y || oldP.z != position.z) {
            positionChange = true
          }
        } else {
          let oldStyle = oldSetting[key]
          let newStyle = setting[key]
          if (oldStyle != newStyle) {
            styleChange = true
            break
          }
        }
      }
      if (styleChange) {
        create.updateTitle(text, setting)
      } else if (positionChange) {
        text.position.set(position.x, position.y, position.z)
      }
      text.setting = setting
      text['inZone'] = inZone
      text['zoneTitle'] = zoneTitle
    } else {
      let text = create.createTitle(setting)
      text['show'] = true
      text.setting = setting
      texts.push(text)
      group.add(text)
      text['inZone'] = inZone
      text['zoneTitle'] = zoneTitle
    }
    textIndex = textIndex + 1
    return {
      result: true
    }
  }

  function updateOrAddImageSprite(image, position, config) {
    let setting = {
      image: image, depthTest: false,
      multiple: typeof (config.multiple) == 'undefined' ? 20 : config.multiple,
      position: position,
      centerY: typeof (config.centerY) == 'undefined' ? 0.1414 : config.centerY,
      mode: config.mode,
      scale: typeof (config.scale) == 'undefined' ? 0.05 : config.scale,
      zoomNum: 1.1,
      titleLV: typeof (config.titleLV) == 'undefined' ? 3 : config.titleLV
    }
    if (imageIndex < images.length) {
      // 只更新样式
      let icon = images[imageIndex]
      let oldSetting = icon.setting
      let styleChange = false
      let positionChange = false
      for (let key in oldSetting) {
        if (key == 'position') {
          let oldP = oldSetting[key]
          if (oldP.x != position.x || oldP.y != position.y || oldP.z != position.z) {
            positionChange = true
          }
        } else {
          let oldStyle = oldSetting[key]
          let newStyle = setting[key]
          if (oldStyle != newStyle) {
            styleChange = true
            break
          }
        }
      }
      if (styleChange) {
        create.updateIconSprite(icon, setting)
      } else if (positionChange) {
        icon.position.set(position.x, position.y, position.z)
      }
      icon['setting'] = setting
      icon.visible = true
    } else {
      let icon = create.creteIconSprite(setting)
      icon['show'] = true
      images.push(icon)
      group.add(icon)
      icon['setting'] = setting
    }
    imageIndex = imageIndex + 1
    return {
      result: true
    }
  }

  let isRotate = false

  let animationTotalCount = 2

  let animationCount = animationTotalCount - 1

  group.textRotate = function (config, scaleBlock) {
    let lazyLoad = false;
    if (analysis.jsonKeyIsExist(config, ['lazyLoad'])) {
      lazyLoad = config['lazyLoad'];
    }

    function isInSide(sprite, camera) {
      let tempV = sprite.position.clone().applyMatrix4(camera.matrixWorldInverse).applyMatrix4(camera.projectionMatrix);
      if ((Math.abs(tempV.x) > 1) || (Math.abs(tempV.y) > 1) || (Math.abs(tempV.z) > 1)) {
        // 在视野外了
        return false;
      }
      // 在视野内
      return true;
    }


    if (group.visible == false || isRotate == true) {
      animationCount = 0
      isRotate = false
      return
    }

    for (let i = 0; i < alertText.length; i++) {
      let text = alertText[i]
      if (scaleBlock != null) {
        scaleBlock(text)
      }
    }

    animationCount = animationCount + 1
    if (animationCount > animationTotalCount) {
      animationCount = 0
    }
    if (animationCount < animationCount) {
      for (let i = 0; i < rotates.length; i++) {
        let mesh = rotates[i]
        if (mesh.visible == false) {
          continue
        }
        if (scaleBlock != null) {
          scaleBlock(mesh)
        }
      }
      isRotate = false
      return
    }

    let boxList = []
    let space = 5
    let camera = config['camera']
    for (let i = 0; i < rotates.length; i++) {
      let mesh = rotates[i]
      if (mesh.visible == false) {
        continue
      }
      if (lazyLoad == false && isInSide(mesh, camera) == false) {
        mesh['show'] = false;
        continue;
      }
      let res = analysis.getSpriteSize(mesh, config.zoomNum, config.windowWidth, config.windowHeight, config.camera, config.isPhone, config.dis)
      let box = new THREE.Box2(new THREE.Vector2(res.x - space, res.y - space), new THREE.Vector2(res.x + res.w + space, res.y + res.h + space))
      boxList.push({
        box: box,
        index: i
      })
    }

    let checkInfo = {}
    for (let i = 0; i < boxList.length; i++) {
      if (analysis.jsonKeyIsExist(checkInfo, [i + ''])) {
        continue
      }
      let boxInfo1 = boxList[i]
      let box1 = boxInfo1.box
      for (let j = i + 1; j < boxList.length; j++) {
        if (analysis.jsonKeyIsExist(checkInfo, [i + ''])) {
          continue
        }
        let boxInfo2 = boxList[j]
        let box2 = boxInfo2.box
        let intersect = box1.intersectsBox(box2)
        let sprite2 = rotates[boxInfo2.index]
        if (intersect) {
          checkInfo[j + ''] = '1'
          sprite2['show'] = false
        } else {
          if (scaleBlock != null) {
            scaleBlock(sprite2)
          }
        }
      }
      checkInfo[i + ''] = '1'
      let sprite1 = rotates[boxInfo1.index]
      sprite1['show'] = true
      if (scaleBlock != null) {
        scaleBlock(sprite1)
      }
    }
    isRotate = false
  }

  group.roomTextAnimation = function () {
    if (group.visible == false || isRotate == true) {
      return
    }
    animationIndex = []
    for (let i = 0; i < rotates.length; i++) {
      let compare = rotates[i]
      if (compare.visible == false) {
        continue
      }
      let show = compare['show']
      let opacity = compare.material.opacity
      if (show == false) {
        if (opacity <= 0) {
          continue
        }
        opacity = opacity - 0.2
        if (opacity <= 0) {
          opacity = 0
        }
      } else {
        if (opacity >= 1) {
          continue
        }
        opacity = opacity + 0.2
        if (opacity >= 1) {
          opacity = 1
        }
      }
      animationIndex.push(i)
      compare.material.opacity = opacity
    }
    animation()
  }

  let delayAnimation = null

  let tween = null

  group.stopAnimation = function () {
    if (delayAnimation != null) {
      clearTimeout(delayAnimation)
      delayAnimation = null
    }
    if (tween != null) {
      tween.stop()
    }
  }

  function animation() {
    if (animationIndex.length == 0) {
      return
    }
    if (delayAnimation != null) {
      clearTimeout(delayAnimation)
      delayAnimation = null
    }
    delayAnimation = setTimeout(() => {
      if (tween != null) {
        tween.stop()
      }
      let oldPro = {
        o: 0
      }

      let newPro = {
        o: 1
      }
      tween = new TWEEN.Tween(oldPro)
      tween.to(newPro, 100).onUpdate(() => {
        for (let i = 0; i < rotates.length; i++) {
          let s = rotates[i]
          if (s.visible == false) {
            continue
          }
          let show = s['show']
          let opacity = s.material.opacity
          if (show == false) {
            // 逐渐消失
            let o = 1 - oldPro.o
            if (opacity >= o) {
              s.material.opacity = o
            }
          } else {
            // 逐渐显示
            if (opacity <= oldPro.o) {
              s.material.opacity = oldPro.o
            }
          }
        }
      })
      tween.start()
    }, 50)
  }

  group.deleteAll = function () {
    animationIndex = []
    let allRotates = texts.concat(images)
    for (let i = 0; i < allRotates.length; i++) {
      let p = allRotates[i]
      if (p.parent != null) {
        group.remove(p)
      }
      p.material.dispose()
      p.geometry.dispose()
    }
    texts = []
    images = []
    if (group.parent != null) {
      group.parent.remove(group)
    }
    textIndex = 0
    imageIndex = 0
  }

  group.getTexts = function (config) {
    let titleLV = 3;
    if (analysis.jsonKeyIsExist(config, ['titleLV'])) {
      titleLV = config['titleLV'];
    }
    return rotates.filter(a => {
      if (a.visible == false) {
        return false;
      }
      let setting = a.setting
      let lv = setting.titleLV
      return lv == titleLV
    })
  }

  return group
}

/* ------------------------------------------------------------------------------------------------------------------------------------ */

export function createFloorTextManager() {
  let group = new THREE.Group()

  let groupList = []

  let groupSelectIndex = 0

  let dataList = []

  let scaleBlock = null

  group.scaleAction = function (callBack) {
    scaleBlock = callBack
  }

  // 加载所有的文字
  group.getAllFloorText = function (config) {
    let mapJson = config.dataInfo
    let layerHeight = config.layerHeight
    let setting = {
      y: layerHeight,
      mode: config.mode,
      layerHeight: layerHeight
    }
    let coverList = []
    for (let i = 0; i < config.coverInfo.length; i++) {
      let info = config.coverInfo[i]
      coverList.push(info.list)
    }
    coverList.push(mapJson)
    for (let i = 0; i < coverList.length; i++) {
      let l = coverList[i]
      if (i < groupList.length) {

      } else {
        let textGroup = createFloorTitle()
        textGroup.getAllFloorText(l, setting)
        groupList.push(textGroup)
        group.add(textGroup)
      }
    }
  }

  group.updateFloorText = function (config) {
    let mapJson = config.dataInfo
    let show = config.show
    let layerHeight = config.layerHeight
    let leadInfo = config.leadInfo
    let isShowLinkText = config.isShowLinkText
    let setting = {
      mapid: config.mapid,
      y: layerHeight,
      mode: config.mode,
      isShowLinkText: isShowLinkText,
      layerHeight: layerHeight
    }
    dataList = []
    let coverInfo = config.coverInfo
    let list = mapJson.concat(show)
    let info = {max: 0, list: list, leadInfo: leadInfo}
    dataList = coverInfo.concat([info])

    for (let i = 0; i < dataList.length; i++) {
      let d = dataList[i]
      if (i < groupList.length) {
        let textGroup = groupList[i]
        textGroup.updateText(d.list, setting, d.leadInfo)
      } else {
        let textGroup = createFloorTitle()
        textGroup.updateText(d.list, setting, d.leadInfo)
        groupList.push(textGroup)
        group.add(textGroup)
      }
    }
    for (let i = 0; i < groupList.length; i++) {
      let textGroup = groupList[i]
      textGroup.visible = (groupSelectIndex == i)
    }
  }

  group.textRotate = function (config) {
    function groupRotate(newIndex) {
      if (newIndex >= groupList.length) {
        return
      }
      let newGroup = groupList[newIndex]
      newGroup.visible = true
      newGroup.textRotate(config, scaleBlock)
      groupSelectIndex = newIndex
      for (let i = 0; i < groupList.length; i++) {
        let textGroup = groupList[i]
        textGroup.visible = (groupSelectIndex == i)
      }
    }

    if (groupList.length == 1) {
      groupRotate(0)
      return
    }
    let lv = config.lv
    let judgeLV = config.judgeLV

    let newIndex = lv > judgeLV ? 0 : dataList.length - 1
    groupRotate(newIndex)
  }

  group.roomTextAnimation = function () {
    if (groupSelectIndex >= groupList.length) {
      return
    }
    let textGroup = groupList[groupSelectIndex]
    if (textGroup.visible == false) {
      textGroup.stopAnimation()
      return
    }
    textGroup.roomTextAnimation()
  }

  group.deleteAll = function () {
    for (let i = 0; i < groupList.length; i++) {
      let textGroup = groupList[i]
      textGroup.deleteAll()
    }
    groupList = []
    if (group.parent != null) {
      group.parent.remove(group)
    }
  }

  group.updateAlerts = function (alerts, config) {
    if (groupList.length == 0) {
      return
    }
    let g = groupList[groupList.length - 1]
    g.updateAlertTextDataList(alerts, config)
  }

  return group
}
