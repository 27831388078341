import {createDetailMapInfo} from "./detailMapJS";

import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";

import {detailMapConfig} from "../../MapConfig";

export function createDetailMapManager() {
  let manager = new Object();

  let detailMap = createDetailMapInfo()

  let analysis = createAnalaysisInfo();

  let detailConfig = detailMapConfig();

  let showDetailBlock = null;

  manager.showDetaiAction = function (callBack) {
    showDetailBlock = callBack;
  }

  let exGroupBlock = null;

  manager.exGroupAction = function (callBack) {
    exGroupBlock = callBack;
  }

  let groupBlock = null;

  manager.groupAction = function (callBack) {
    groupBlock = callBack;
  }

  let cameraAnimationBlock = null;

  manager.cameraAnimationAction = function (callBack) {
    cameraAnimationBlock = callBack;
  }

  let mapInfoBlock = null;

  manager.mapInfoAction = function (callBack) {
    mapInfoBlock = callBack;
  }

  let maxPolarAngleBlock = null;

  manager.maxPolarAngleAction = function (callBack) {
    maxPolarAngleBlock = callBack;
  }

  let judgeFenceInfoBlock = null;

  manager.judgeFenceInfoAction = function (callBack) {
    judgeFenceInfoBlock = callBack;
  }

  let updateHeightBlock = null;

  manager.updateHeightAction = function (callBack) {
    updateHeightBlock = callBack;
  }

  let changeMapBlock = null;

  manager.changeMapAction = function (callBack) {
    changeMapBlock = callBack;
  }

  let leadCubeInfoBlock = null;

  manager.leadCubeInfoAction = function (callBack) {
    leadCubeInfoBlock = callBack;
  }

  manager.addGroup = function (scene) {
    if (detailMap.parent != null) {
      scene.remove(detailMap);
    }
    scene.add(detailMap);
  }

  manager.deleteAll = function () {
    detailMap.deleteAll();
  }

  manager.mouseMoveOnDetail = function (event) {
    return detailMap.mouseMoveOnDetail(event)
  }

  // 取消多楼层
  manager.cancelShowAllMap = function () {
    let res = {
      result: false
    }
    detailMap.renderStartSetting(res)
    if (showDetailBlock == null) {
      return;
    }
    showDetailBlock(res)
  }

  manager.getDetailBlock = function () {
    return showDetailBlock;
  }

  manager.sendDetail = function (config) {
    if (showDetailBlock == null) {
      return;
    }
    showDetailBlock(config)
  }

  manager.judgeCamera = function () {
    detailMap.judgeCamera()
  }

  manager.showMap = function (mapid) {
    detailMap.showMap(mapid)
  }

  manager.returnRender = function () {
    return detailMap.returnRender();
  }

  manager.randerScene = function () {
    detailMap.randerScene();
  }

  manager.resize = function () {
    detailMap.resize();
  }

  manager.updateDetailCss2DList = function (config) {
    let group = exGroupBlock()
    if (group == null) {
      return
    }
    group.getFloorHeightByPoint(config)
    detailMap.updateCss2DList(config)
  }

  // 显示多楼层
  manager.showAllMap = function (zoneId, config, callBack) {
    let width = config.windowWidth / 3.0
    if (analysis.jsonKeyIsExist(config, ['width'])) {
      width = config['width']
    }
    showAllFloor(width, zoneId, config, callBack)
  }

  manager.showAllFloor = function (width, zoneId, config, callBack) {
    showAllFloor(width, zoneId, config, callBack)
  }


  function showAllFloor(width, zoneId, config, callBack) {
    let mapInfo = mapInfoBlock();
    let nowMapid = mapInfo['mapid'];
    let windowHeight = mapInfo['windowHeight'];
    let mapOrderList = mapInfo['mapOrderList'];

    /* -------------------------------- */
    function setControlCameraAniamtion(res) {
      return new Promise(resolve => {
        let zoneSetting = res['zoneSetting']
        let moveCamera = res['moveCamera']
        let hiddenExtra = false
        if (analysis.jsonKeyIsExist(config, ['hiddenExtra'])) {
          hiddenExtra = config['hiddenExtra']
        }
        let finishCallBack = (result) => {
          resolve()
          if (result.result == false) {
            return
          }
          if (moveCamera == false) {
            return
          }

          let judgeInfo = judgeFenceInfoBlock({
            leftUp: result['fence']['leftUp'],
            rightDown: result['fence']['rightDown'],
            hiddenExtra: hiddenExtra
          })

          cameraAnimationBlock({
            target: {
              target: result.center
            }, rotate: {
              angleY: maxPolarAngleBlock()
            }, scale: {
              leftUp: judgeInfo['leftUp'],
              rightDown: judgeInfo['rightDown']
            }, time: 400
          })
        }
        detailMap.getZoneAreaCloneByPromise(zoneSetting, finishCallBack)
      })
    }

    function loadZoneGroup(mapOrderList, index, zoneId, groupList, fence, callBack) {
      if (index < 0) {
        callBack()
        return
      }
      let order = mapOrderList[index]
      let mapid = order.mapid
      let g = groupBlock(mapid)
      let zoneRes = g.returnZoneGroup(zoneId)
      if (zoneRes.result == false) {
        setTimeout(() => {
          loadZoneGroup(mapOrderList, index - 1, zoneId, groupList, fence, callBack)
        }, 10)
        return
      }

      zoneRes.group['mapid'] = mapid
      zoneRes.group['name'] = mapid
      groupList.push(zoneRes.group)
      let groupFence = zoneRes.group['fence']
      for (const key in groupFence) {
        fence[key] = groupFence[key]
      }
      setTimeout(() => {
        loadZoneGroup(mapOrderList, index - 1, zoneId, groupList, fence, callBack)
      }, 10)
    }

    function startLoadDetailMap(zoneId, moveCamera) {
      return new Promise(resolve => {
        let groupList = []
        let textInfo = {}
        let zoneSetting = {
          textInfo: textInfo,
          groupList: groupList,
          zoneId: zoneId,
          y: 0,
          mapid: nowMapid
        }
        let zoneIsExitst = detailMap.checkZoneIsExist(zoneId)
        let fence = {}
        let callBack = () => {
          if (analysis.jsonKeyIsExist(fence, ['rightDown', 'leftUp'])) {
            let w = fence.rightDown.x - fence.leftUp.x
            let h = fence.rightDown.z - fence.leftUp.z
            let spaceHeight = 0
            if (analysis.jsonKeyIsExist(config, ['spaceHeight'])) {
              spaceHeight = config['spaceHeight']
            }
            let y = updateHeightBlock(fence, spaceHeight, width, w, h, zoneId, textInfo);
            zoneSetting.y = y
          }
          resolve({
            zoneSetting: zoneSetting,
            moveCamera: moveCamera
          })
        }
        if (zoneIsExitst == false) {
          loadZoneGroup(mapOrderList, mapOrderList.length - 1, zoneId, groupList, fence, callBack)
        } else {
          callBack()
        }
      })
    }

    function setUpDetailMap() {
      return new Promise(resolve => {
        let setUpRes = detailMap.setUpConfig({
          windowHeight: windowHeight,
          windowWidth: width,
          x: 0,
          y: 0,
          renderDiv: detailConfig.renderDivId
        })
        resolve(setUpRes)
      })
    }

    function changeMapId(setUpRes) {
      return new Promise(resolve => {
        if (setUpRes.result) {
          detailMap.changeMapId((id) => {
            changeMapBlock(id);
          })
          detailMap.moveZone((result) => {
            cameraAnimationBlock({
              target: {
                target: result.center
              }, rotate: {
                angleY: maxPolarAngleBlock(),
                angleXZ: 0
              }, scale: {leftUp: result['fence']['leftUp'], rightDown: result['fence']['rightDown']}, time: 300
            })
          })
        }
        resolve(setUpRes)
      })
    }

    function renderStartSetting() {
      return new Promise(resolve => {
        detailMap.renderStartSetting({
          result: true
        })
        setTimeout(() => {
          resolve()
        }, 10)
      })
    }

    /* -------------------------------- */
    if (analysis.jsonKeyIsExist(config, ['moveCamera']) == false) {
      config['moveCamera'] = true
    }

    setUpDetailMap().then(res => {
      return changeMapId(res)
    }).then(res => {
      return renderStartSetting()
    }).then(res => {
      return startLoadDetailMap(zoneId, config.moveCamera)
    }).then(res => {
      return setControlCameraAniamtion(res)
    }).then(res => {
      callBack()
    })
  }

  manager.updateDetailMapGroup = function (zoneId, mapid) {
    if (detailMap.returnRender() == false) {
      return;
    }
    let g = groupBlock(mapid)
    let zoneRes = g.returnZoneGroup(zoneId)
    if (zoneRes.result == false) {
      return;
    }
    detailMap.updateZoneGroup({
      zoneId: zoneId,
      mapid: mapid,
      mapName: '',
      zoneGroup: zoneRes.group,
    })
  }

  // 多楼层路径显示
  manager.showAllFloorLeadWay = function (zoneId, mapid, nextMapid) {
    let leadCubeInfo = leadCubeInfoBlock();
    let mapInfo = mapInfoBlock();
    let selectMapid = mapid
    if (leadCubeInfo.leadOrder.length > 0) {
      let order = leadCubeInfo.leadOrder[leadCubeInfo.leadOrder.length - 1]
      selectMapid = order.mapid
    }
    let updateCallBack = () => {
      setTimeout(() => {
        detailMap.updateLeadWay({
          leadOrder: leadCubeInfo.leadOrder,
          poList: leadCubeInfo.poInfo,
          nextMapid: nextMapid,
          leadInfo: leadCubeInfo.leadPath,
          mapid: selectMapid,
          y: mapInfo['floorHeight'],
          mode: 0,
          hiddenStart: false
        })
      }, 500)
    }

    if (manager.returnRender() == false) {
      showAllFloor(mapInfo['windowWidth'], zoneId, {
        moveCamera: true
      }, updateCallBack)
    } else {
      updateCallBack()
    }
  }


  return manager;
}
