import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'

export function pointGroup_togethor(manager) {

  const analysis = createAnalaysisInfo()

  let dataInfo = manager.getPointsInfo();

  let pointsInfo = dataInfo.pointsInfo;

  let pointsMesh = dataInfo.pointsMesh // 打点的mesh

  let selectInfo = dataInfo.selectInfo;

  // 选择的Together对象
  let selectTogether = null

  function cancelSelectTogethor() {
    selectInfo['together'] = {}
    if (selectTogether == null) {
      return
    }
    selectTogether.pointType = selectTogether.dataType
    if (analysis.jsonKeyIsExist(selectTogether, ['use'])) {
      selectTogether.visible = selectTogether['use'] == 0 ? false : true
    }
    selectTogether = null
  }

  // 聚合点缩放
  manager.togetherZoom = function (config) {
    if (typeof config.camera == 'undefined' || config.camera == null) {
      return;
    }
    if (analysis.jsonKeyIsExist(pointsMesh, ['together']) == false) {
      return
    }
    const list = pointsMesh['together']
    const index = []
    const textIndex = {} // 标记文字
    const type = 'together'
    const infos = pointsInfo[type]
    // 检测同类型的聚合点 相同类型则将其聚合
    for (let i = 0; i < list.length; i++) {
      const p1 = list[i]
      p1['indexs'] = [p1.dataIndex]
      if (i >= infos.length) {
        p1.visible = false
        continue
      }
      if (index.indexOf(i) < 0) {
        p1.visible = true
        textIndex[i + ''] = {
          count: 1,
          x: p1.position.x,
          y: p1.position.y,
          z: p1.position.z
        }
      } else {
        delete textIndex[i + '']
        continue
      }
      for (let j = 0; j < list.length; j++) {
        const p2 = list[j]
        if (j == i) {
          continue
        }
        if (j >= infos.length) {
          p2.visible = false
          continue
        }

        if ((p1.pointType != p2.pointType && p1.mapid == p2.mapid)) {
          continue
        }
        if (index.indexOf(j) >= 0) {
          continue
        }
        // 检测是否碰撞
        const result = analysis.isPOIRect(p1, p2, config.camera, config.windowWidth, config.windowHeight, config.scale, false, config.dis)
        if (result.result == false) {
          continue;
        }
        p2.visible = false
        const dataIndex = p2.dataIndex
        if (p1['indexs'].indexOf(dataIndex) < 0) {
          p1['indexs'].push(dataIndex)
          textIndex[i + ''].count = textIndex[i + ''].count + 1
        }
        if (index.indexOf(j) < 0) {
          index.push(j)
        }
      }
    }
    let meshList = []
    if (analysis.jsonKeyIsExist(pointsMesh, [type]) == true) {
      meshList = pointsMesh[type]
    } else {
      pointsMesh[type] = meshList
    }
    let select = {}
    if (analysis.jsonKeyIsExist(selectInfo, [type]) == true) {
      select = selectInfo[type]
    } else {
      selectInfo[type] = select
    }
    updateImagesTogethor(meshList, infos, select, config)
  }

  function updateImagesTogethor(meshList, infos, select, config) {
    const mapid = typeof (config.mapid) == 'undefined' ? '' : config.mapid
    const mode = 0
    let count = 0
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i]
      let show = true
      if (analysis.jsonKeyIsExist(info, ['show'])) {
        show = info['show']
      }
      if (info.mapid != mapid || show == false) {
        continue
      }
      const showType = typeof (info.showType) == 'undefined' ? 'draw' : info.showType
      const y = typeof (info.y) == 'undefined' ? 2.5 : parseFloat(info.y)
      const x = parseFloat(info.x)
      const z = parseFloat(info.z)
      const isSelect = analysis.jsonKeyIsExist(select, [info.id + ''])
      const images = showType == 'draw' ? info.images : (isSelect == true ? info.select : info.unSelect)
      const bgColor = isSelect == true ? '#fab7b7' : '#ffffff'
      let msg = ''
      const setting = {
        bgColor: bgColor,
        arcColor: '#ffffff',
        position: {
          x: x,
          y: y,
          z: z
        },
        dataType: info.pointType,
        id: info.id,
        centerY: typeof (info.centerY) == 'undefined' ? 0 : info.centerY,
        dataIndex: i,
        multiple: 17,
        dataInfo: info,
        msg: msg,
        msgColor: '#ff0000',
        textColor: '#ffffff',
        mode: mode,
        type: showType
      }
      if (count < meshList.length) {
        const p = meshList[count]

        if (analysis.jsonKeyIsExist(p, ['indexs']) && p.indexs.length > 1) {
          msg = p.indexs.length + ''
          setting.msg = msg
          manager.remove(p)
          manager.add(p)
        }
        let shouldChange = false
        const oldSetting = p['dataInfo']
        for (const key in oldSetting) {
          const oldValue = oldSetting[key]
          if (key == 'position') {
            const p = setting.position
            if (oldValue.x != p.x || oldValue.z != p.z) {
              shouldChange = true
              break
            }
          } else {
            if (oldValue != setting[key]) {
              shouldChange = true
              break
            }
          }
        }
        if (shouldChange) {
          create.updateImage_style(p, images, setting)
          p.visible = true
        }
        p['dataInfo'] = setting
      } else {
        const p = create.createImage_Style(images, setting)
        p['meshType'] = 'together'
        manager.add(p)
        meshList.push(p)
      }
      count = count + 1
    }
    if (count < meshList.length) {
      for (let i = count; i < meshList.length; i++) {
        const p = meshList[i]
        p.visible = false
      }
    }
    if (selectTogether == null) {
      return;
    }
    manager.remove(selectTogether)
    manager.add(selectTogether)
    selectTogether.visible = true
  }

  function selectTogethor(object) {
    object.pointType = '65535'
    selectTogether = object
    selectTogether.visible = true
  }

  manager.findSelectTogethor = function (config) {
    cancelSelectTogethor()
    if (analysis.jsonKeyIsExist(pointsMesh, ['together']) == false) {
      return {
        result: false
      }
    }
    const list = pointsMesh['together']
    const id = config.id
    for (let i = 0; i < list.length; i++) {
      const mesh = list[i]
      if (analysis.jsonKeyIsExist(mesh, ['pointId']) == false) {
        continue
      }
      if (mesh.pointId + '' != id + '') {
        continue;
      }
      mesh.visible = true
      cancelSelectTogethor()
      selectTogethor(mesh)
      selectInfo['together'][id + ''] = ''
      return {
        result: true,
        data: mesh.dataInfo.dataInfo
      }
    }
    return {
      result: false
    }
  }

  let cancelSelect = manager.cancelSelect;


  manager.cancelSelect = function (config) {
    cancelSelect(config);
    cancelSelectTogethor();
  }


  manager.clickTogethor = function (type, data, object) {
    selectInfo[type][data.id + ''] = ''
    const dataList = []
    const infos = pointsInfo['together']
    for (let i = 0; i < list.length; i++) {
      const o = list[i].object
      if (o.visible == false) {
        continue
      }
      const indexs = o.indexs
      for (let i = 0; i < indexs.length; i++) {
        const mesh = infos[indexs[i]]
        if (typeof mesh == 'undefined' || mesh == null) {
          continue
        }
        dataList.push(mesh)
      }
    }
    cancelSelectTogethor()
    if (dataList.length == 1) {
      this.selectMesh({
        mapid: mapid
      }, selectInfo)
      selectTogethor(object)
    }
    return {
      result: true,
      data: dataList,
      type: 'together'
    }
  }



}
