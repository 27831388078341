import * as THREE from 'three/build/three.min.js'
import * as TWEEN from 'tween/tween.js'
import {
  createObjectAction
} from '../createObject'
import {
  createLeadJs
} from '../../analaysis/LeadJS'

export function createSimulation() {
  const create = createObjectAction()

  let loadPath = []

  let leadOrder = []

  let tween = null

  let animationEnd = null

  let animationUpdate = null

  let animationRotate = null

  let animationMove = null

  let animationFirstZoom = null

  let animationChangeMapid = null

  const simulationInfo = {
    image: './static/iconImage/simulation.png'
  }

  const leadCubeInfo = {
    simulation: false, // 模拟导航
    simuLationOb: null, // 模拟导航的对象
    timeOunt: null, // 模拟导航时 移动地图暂停动画的timeout
    orderIndex: 0,
    index: 0,
    speed: 3,
    moveDis: 5,
    nowDis: 65535,
    lastPoint: null
  }

  const group = new THREE.Group()

  function createSimulation() {
    const setting = {
      centerY: 0.5,
      position: {
        x: 0,
        y: 3,
        z: 0
      },
      multiple: 20,
      image: simulationInfo.image,
      width: 32,
      height: 32,
      polygonOffsetFactor: -2,
      polygonOffsetUnits: -3
    }
    const user = create.createNewPlane(setting)
    user.uuid = 'simulation'
    group.add(user)
    leadCubeInfo.simuLationOb = user
    leadCubeInfo.simuLationOb.visible = false
    if (tween == null) {
      tween = new TWEEN.Tween(leadCubeInfo.simuLationOb.position)
    }
  }

  createSimulation()

  group.changeMapid = function (callBack) {
    animationChangeMapid = callBack
  }

  group.animationEndAction = function (callBack) {
    animationEnd = callBack
  }

  group.animationFirstZoom = function (callBack) {
    animationFirstZoom = callBack
  }

  group.animationUpdateAction = function (callBack) {
    animationUpdate = callBack
  }

  group.animationRotateAction = function (callBack) {
    animationRotate = callBack
  }

  group.animationMoveAction = function (callBack) {
    animationMove = callBack
  }

  group.startSimulation = function (config) {
    const paths = config.paths
    loadPath = paths
    leadOrder = config.leadOrder
    leadCubeInfo.orderIndex = leadOrder.length - 1
    const order = leadOrder[leadCubeInfo.orderIndex]
    const pathInfo = loadPath[order.mapid]
    const path = pathInfo[order.index].path
    leadCubeInfo.index = path.length - 2
    if (path.length <= 1) {
      return {
        result: false
      }
    }
    group.visible = true
    leadCubeInfo.animaiton = true
    leadCubeInfo.simulation = true
    leadCubeInfo.simuLationOb.visible = true
    const first = path[path.length - 1]
    leadCubeInfo.simuLationOb.position.x = first.x
    leadCubeInfo.simuLationOb.position.z = first.z
    leadCubeInfo.simuLationOb.position.y = config.y
    leadCubeInfo.lastPoint = JSON.parse(JSON.stringify(leadCubeInfo.simuLationOb.position))
    if (animationFirstZoom != null) {
      animationFirstZoom(first, () => {
        startAnimation()
      })
    } else {
      startAnimation()
    }
    return {
      result: true
    }
  }

  group.endSimulation = function () {
    leadCubeInfo.simulation = false
    leadCubeInfo.orderIndex = 0
    leadCubeInfo.index = 0
    loadPath = {}
    leadOrder = []
  }

  group.simulationMode = function () {
    return leadCubeInfo.simulation
  }

  group.returnSimulationOb = function () {
    return leadCubeInfo.simuLationOb
  }

  group.returnAnimaitonStatus = function () {
    return leadCubeInfo.animaiton
  }

  group.returnSimulationStatus = function () {
    return leadCubeInfo.simulation
  }

  group.animationStart = function () {
    // if (tween != null) {
    //   tween.start();
    // }
    leadCubeInfo.animaiton = true
    startAnimation()
  }

  group.stopAnimation = function (callBack) {
    if (tween != null) {
      tween.stop()
    }
    leadCubeInfo.animaiton = false
  }

  function getPathInfo() {
    function getPath() {
      if (leadCubeInfo.orderIndex >= leadOrder.length || leadCubeInfo.orderIndex < 0) {
        return {
          result: false
        }
      }
      const order = leadOrder[leadCubeInfo.orderIndex]
      const pathInfo = loadPath[order.mapid]
      const path = pathInfo[order.index].path
      return {
        result: true,
        path: path
      }
    }

    return new Promise((resolve, reject) => {
      const result = getPath()
      if (result.result == false) {
        reject({
          result: false
        })
        return
      }
      let path = result.path
      if (leadCubeInfo.index >= 0) {

      } else {
        leadCubeInfo.orderIndex = leadCubeInfo.orderIndex - 1
        const newResult = getPath()
        if (newResult.result == false) {
          reject({
            result: false
          })
          return
        }
        path = newResult.path
        leadCubeInfo.index = path.length - 2
      }
      if (leadCubeInfo.index < path.length && leadCubeInfo.index + 1 < path.length) {
        let nextPoint = path[leadCubeInfo.index]
        let lastPoint = path[leadCubeInfo.index + 1]


        if (typeof nextPoint['radius'] != 'undefined') {
          for (let i = leadCubeInfo.index; i < path.length; i++) {
            let p = path[i];
            if (typeof p['radius'] != 'undefined') {
              continue;
            }
            lastPoint = p;
            break;
          }
          // 当前点是转成圆角的点 应该跳过
          for (let i = leadCubeInfo.index; i >= 0; i--) {
            let p = path[i];
            if (typeof p['radius'] != 'undefined') {
              continue;
            }
            nextPoint = p;
            leadCubeInfo.index = i;
            break;
          }
        }

        if (animationChangeMapid == null) {
          resolve({
            result: true,
            next: nextPoint,
            last: lastPoint,
            path: result.path
          })
          return;
        }
        const rotate = rotateSimulation(lastPoint, nextPoint)
        animationChangeMapid(lastPoint.mapid, rotate.angle, (res) => {
          if (res.result) {
            leadCubeInfo.simuLationOb.position.set(lastPoint.x, leadCubeInfo.simuLationOb.position.y,
              lastPoint.z)
            const newRes = getPath()
            path = newRes.path
            leadCubeInfo.index = path.length - 2
            nextPoint = path[leadCubeInfo.index]
            lastPoint = path[leadCubeInfo.index + 1]
          }
          resolve({
            result: true,
            next: nextPoint,
            last: lastPoint,
            path: path
          })
        })
      } else {
        reject({
          result: false
        })
      }
    })
  }

  function rotateSimulation(p1, p2) {
    const test1 = new THREE.Vector3(p2.x - p1.x, 0, p2.z - p1.z)
    const test2 = new THREE.Vector3(0, 0, -1)
    let angle = test2.angleTo(test1)
    if (test1.x > 0) {
      angle = Math.PI * 2 - angle
    }
    while (angle >= Math.PI * 2) {
      angle = Math.PI * 2 - angle
    }
    while (angle < 0) {
      angle = Math.PI * 2 + angle
    }
    leadCubeInfo.simuLationOb.rotation.z = angle
    return {
      result: true,
      angle: angle
    }
  }

  function startAnimation() {
    const result = getPathInfo()
    result.then((res) => {
      const nextPoint = res.next
      const lastPoint = res.last
      const path = res.path
      const rotate = rotateSimulation(lastPoint, nextPoint)
      if (animationRotate != null) {
        // 镜头旋转
        animationRotate(rotate.angle, 400, () => {
          animation(nextPoint, lastPoint, path)
        })
      } else {
        // 不需要镜头旋转
        animation(nextPoint, lastPoint, path)
      }
    }).catch((res) => {
      if (animationEnd == null) {
        return;
      }
      animationEnd()
    })
  }

  function animation(nextPoint, lastPoint, path) {
    const distance = Math.sqrt((nextPoint.x - leadCubeInfo.simuLationOb.position.x) * (nextPoint.x - leadCubeInfo
      .simuLationOb.position.x) + (nextPoint.z - leadCubeInfo.simuLationOb.position.z) * (nextPoint.z - leadCubeInfo
      .simuLationOb.position.z))
    const time = distance / (leadCubeInfo.speed * 10) * 1000
    const starttween = tween
      .to({
        x: nextPoint.x,
        z: nextPoint.z
      }, time)
      .onUpdate(() => {
        const dis = leadCubeInfo.simuLationOb.position.distanceTo(leadCubeInfo.lastPoint)
        // updateSimulationMsg(path, leadCubeInfo.lastPoint, dis)
        const px = parseFloat(leadCubeInfo.simuLationOb.position.x.toFixed(1))
        const pz = parseFloat(leadCubeInfo.simuLationOb.position.z.toFixed(1))
        const x = parseFloat(nextPoint.x.toFixed(1))
        const z = parseFloat(nextPoint.z.toFixed(1))
        if (px == x && pz == z) {
          leadCubeInfo.index = leadCubeInfo.index - 1
          startAnimation()
        } else {
          if (dis >= 5 && animationMove != null && leadCubeInfo.animaiton) {
            animationMove(leadCubeInfo.simuLationOb.position)
            leadCubeInfo.lastPoint = JSON.parse(JSON.stringify(leadCubeInfo.simuLationOb.position))
            updateSimulationMsg(path, leadCubeInfo.lastPoint, dis)
          }
        }
      })
    starttween.start()
    tween['animaiton'] = true
  }

  function updateSimulationMsg(path, lastPoint, dis) {
    if (leadCubeInfo.orderIndex >= leadOrder.length || leadCubeInfo.orderIndex < 0) {
      return
    }
    if (animationUpdate != null) {
      animationUpdate(leadCubeInfo.simuLationOb.position, lastPoint, dis)
    }
  }

  group.getLeftDis = function () {
    if (leadCubeInfo.orderIndex >= leadOrder.length) {
      return {
        dis: 0
      }
    }
    let dis = 0
    for (let i = leadCubeInfo.orderIndex; i >= 0; i--) {
      const order = leadOrder[i]
      const pathInfos = loadPath[order.mapid]
      const paths = pathInfos[order.index].path
      if (i == leadCubeInfo.orderIndex) {
        for (let j = leadCubeInfo.index; j >= 1; j--) {
          const p1 = paths[j]
          const p2 = paths[j - 1]
          const distance = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
          dis = dis + distance
        }
        const last = paths[leadCubeInfo.index]
        dis = dis + Math.sqrt((last.x - leadCubeInfo.simuLationOb.position.x) * (last.x - leadCubeInfo.simuLationOb
          .position.x) + (last.z - leadCubeInfo.simuLationOb.position.z) * (last.z - leadCubeInfo.simuLationOb
          .position.z))
      } else {
        dis = dis + pathInfos[order.index].dis
      }
    }
    return {
      dis: dis
    }
  }

  group.deleteAll = function () {
    if (tween != null) {
      tween.stop()
    }
    tween = null
    if (leadCubeInfo.simuLationOb != null) {
      if (leadCubeInfo.simuLationOb.parent != null) {
        group.remove(leadCubeInfo.simuLationOb)
      }
      leadCubeInfo.simuLationOb.geometry.dispose()
      leadCubeInfo.simuLationOb.material.dispose()
    }
    leadCubeInfo.simuLationOb = null
  }

  return group
}
