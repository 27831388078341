import * as THREE from 'three/build/three.min.js'

function createStepManager() {

  let k = createKalmen();

  let step = {};

  let zWaveList = [];

  let workSpace = 0;

  let listCount = 6;

  let waveTop = 0.135;

  let workTime = 400;

  let stepWaveInfo = {
    value: waveTop,
    count: 1,
  }

  step.getWorkDis = function () {
    return workSpace;
  }

  function getValueData(x, y, value, x_y, timeStamp, index,) {
    return {value: value, timeStamp: timeStamp, index: index, x_y: x_y, x: x, y: y};
  }

  function createWaveInfo(x, y, value, x_y, status, timeStamp, index,) {
    return {
      status: status,
      list: [getValueData(x, y, value, x_y, timeStamp, index)],
      able: false, // 是否可用,
      max: value, // 波峰
      min: value, // 波谷
      use: false,
      forcebreak: false,
      totalMax: {
        value: 0,
        timeStamp: timeStamp
      },
      totalMin: {
        value: 65535,
        timeStamp: timeStamp
      }
    }
  }


  function updateZwaveList(x, y, z, x_y, time, index) {
    function judgeData(two, three) {
      let twoHieght = two.max - two.min;

      let threeHeight = three.max - three.min;

      let scale = 0.2;

      if (twoHieght * scale >= threeHeight) {
        return {
          result: true
        }
      }
      return {
        result: false
      }
    }

    function setDataAble(last) {
      if ((Math.abs(last.max) >= waveTop || Math.abs(last.min) >= waveTop) && last.list.length >= listCount) {
        last.able = true;
      }
    }

    function updateMax_Min(two, one) {
      two.max = one.max >= two.max ? one.max : two.max;
      two.min = one.min <= two.min ? one.min : two.min;

      if (two.totalMax.value <= one.totalMax.value) {
        two.totalMax.value = one.totalMax.value;
        two.totalMax.timeStamp = one.totalMax.timeStamp;
      }

      if (two.totalMin.value >= one.totalMin.value) {
        two.totalMin.value = one.totalMin.value;
        two.totalMin.timeStamp = one.totalMin.timeStamp;
      }
    }

    function one_unAble_two_able(one, two) {
      if (one.able == false) {
        if (two.able) {
          let two_one_res = judgeData(two, one);
          if (two_one_res.result == false) {
            return {
              result: false
            };
          }
          two.list = one.list.concat(two.list);
          updateMax_Min(two, one);
          zWaveList.splice(zWaveList.length - 4, 1)
        }
        return {
          result: false
        };
      }
      return {
        result: true
      };
    }

    function one_able_two_unAble(one, two, three) {
      if (two.able) {
        return {
          result: true
        }
      }
      let one_two_res = judgeData(one, two);
      if (one_two_res.result == false) {
        return {
          result: false
        }
      }
      one.list = one.list.concat(two.list);
      updateMax_Min(one, two);
      // two 已经并到one 里面了 删除 wavelist 的two数据 同时判断three与one的趋势是否一致 一致也并到一起 同时更新最大 最小值
      if (one.status == three.status) {
        one.list = one.list.concat(three.list);
        updateMax_Min(one, three);
        zWaveList.splice(zWaveList.length - 3, 2)
      } else {
        zWaveList.splice(zWaveList.length - 3, 1)
      }
      return {
        result: false
      }
    }

    function three_unable(one, two, three, up) {
      if (three.able == true) {
        return {
          result: true
        }
      }
      // 判断three的趋势是否偶然
      let two_three_res = judgeData(two, three);
      if (two_three_res.result == false) {
        // 判断one 和 three 是否能并到一起
        let one_three_res = judgeData(one, three);
        if (one_three_res.result) {
          one.list = one.list.concat(two.list);
          updateMax_Min(one, two);
          one.list = one.list.concat(three.list);
          updateMax_Min(one, three);
          zWaveList.splice(zWaveList.length - 3, 2)
        }
        return {
          result: false
        };
      }
      two.list = two.list.concat(three.list);
      updateMax_Min(two, three);
      if (two.status == up) {
        let newData = zWaveList[zWaveList.length - 1];
        two.list = two.list.concat(newData.list);
        updateMax_Min(two, newData);
        zWaveList.splice(zWaveList.length - 2, 2)
      } else {
        zWaveList.splice(zWaveList.length - 2, 1)
      }
      return {
        result: false
      };
    }

    function isOverCount(one, two) {
      let startTime = one.list[0].timeStamp;
      let endTime = two.list[two.list.length - 1].timeStamp;
      let timeCount = endTime - startTime;
      two.forcebreak = timeCount >= 1000
      return {
        result: two.forcebreak,
      }
    }

    function getStep(one, two) {
      if (one.able == false || two.able == false) {
        return {
          result: false
        };
      }
      if (one.status != true || two.status != false) {
        return {
          result: false
        };
      }
      one.use = true;
      two.use = true;

      let startTime = one.list[0].timeStamp;
      let endTime = two.list[two.list.length - 1].timeStamp;
      let timeCount = endTime - startTime;

      if (timeCount <= workTime) {
        return {
          result: false
        };
      }

      let one_height = one.max - one.min;

      let two_height = two.max - two.min;

      let height = two_height >= one_height ? one_height : two_height;

      let newWave = height * 0.5;

      let n = k.updateKalmenValue(newWave);

      if (n >= 0.7) {
        n = 0.7
      }
      stepWaveInfo.value = stepWaveInfo.value + n;
      stepWaveInfo.count = stepWaveInfo.count + 1;

      waveTop = stepWaveInfo.value / stepWaveInfo.count;

      // let H = (one_height + two_height) / 2.0;
      // let length = 0.59 - 0.000155 * timeCount + 0.1638 * Math.sqrt(H);
      // workSpace = workSpace + length;

      let maxH = 0;
      let minH = 0;
      let maxTime = 0;
      let minTime = 0;

      if (one.totalMax.value >= two.totalMax.value) {
        maxH = one.totalMax.value;
        maxTime = one.totalMax.timeStamp;
      } else {
        maxH = two.totalMax.value;
        maxTime = two.totalMax.timeStamp;
      }

      if (one.totalMin.value >= two.totalMin.value) {
        minH = two.totalMin.value;
        minTime = two.totalMin.timeStamp;
      } else {
        minH = one.totalMin.value;
        minTime = one.totalMin.timeStamp;
      }

      let H = Math.abs(maxH - minH);
      let waveTime = Math.abs((maxTime - minTime) * 2)
      let length = 0.64 - 0.000155 * waveTime + 0.1638 * Math.sqrt(H);
      workSpace = workSpace + length;

      console.log('-----');
      console.log(length);
      console.log('一步');
      // console.log(one.list[0].index);
      // console.log(two.list[two.list.length - 1].index);
      // console.log('你已经走了' + workSpace + '米')
      return {
        result: true,
        length: length,
        distance:length,
      };
    }

    function different(z, time, up, x_y) {
      let res = {
        result: false
      };
      // 趋势不一致
      zWaveList.push(createWaveInfo(x, y, z, x_y, up, time, index));
      if (zWaveList.length < 4) {
        return res;
      }
      let one = zWaveList[zWaveList.length - 4];
      let two = zWaveList[zWaveList.length - 3];
      let three = zWaveList[zWaveList.length - 2];

      let twoRes = isOverCount(one, two);
      if (twoRes.result) {
        return getStep(one, two)
      }

      if (one.use || two.use) {
        return res;
      }

      let one_unable_two_able_res = one_unAble_two_able(one, two);
      if (one_unable_two_able_res.result == false) {
        return res;
      }

      let one_able_two_unable_res = one_able_two_unAble(one, two, three);
      if (one_able_two_unable_res.result == false) {
        return res;
      }

      let three_unable_res = three_unable(one, two, three, up);
      if (three_unable_res.result == false) {
        return res;
      }
      return getStep(one, two)
    }

    if (zWaveList.length == 0) {
      // 没有数据 先push一个进来
      zWaveList.push(createWaveInfo(x, y, z, x_y, null, time, index));
      return {
        result: false
      };
    }
    let last = zWaveList[zWaveList.length - 1];
    let lastList = last.list;
    let lastValue = lastList[lastList.length - 1].value;
    let up = true;
    if (lastValue > z) {
      up = false; // 下降
    } else {
      up = true; // 上升
    }
    if (last.status == null) {
      // 初始没有上升或者下降的判断
      last.status = up;
      lastList.push(getValueData(x, y, z, x_y, time, index));
      return {
        result: false
      };
    }

    if (last.status != up) {
      // 趋势不一致
      return different(z, time, up, x_y);
    }
    // 趋势一致
    lastList.push(getValueData(x, y, z, x_y, time, index));
    last.max = last.max >= z ? last.max : z;
    last.min = last.min <= z ? last.min : z;
    if (last.totalMax.value <= x_y) {
      last.totalMax.value = x_y;
      last.totalMax.timeStamp = time;
    }

    if (last.totalMin.value >= x_y) {
      last.totalMin.value = x_y;
      last.totalMin.timeStamp = time;
    }

    setDataAble(last);
    return {
      result: false
    };
  }

  step.updateWave = function (x, y, z, time, index) {
    let x_y = Math.sqrt(x * x + y * y);
    let res = updateZwaveList(x, y, z, x_y, time, index);
    let stroeCount = 20;
    if (zWaveList.length > stroeCount) {
      zWaveList.splice(0, zWaveList.length - stroeCount);
    }
    return res;
  }

  step.getZwaveList = function () {
    // console.log(zWaveList);
  }

  step.setUpConfig = function () {
    let isChrome =  navigator.userAgent.indexOf('Chrome') > -1  // 是否是谷歌
    if(isChrome == false){
      // 苹果
      if (typeof localStorage['appleWave'] != 'undefined' && localStorage['appleWave'] != null) {
        waveTop = parseFloat(localStorage['appleWave']);
      }
    } else {
      // 谷歌
      if (typeof localStorage['googleWave'] != 'undefined' && localStorage['googleWave'] != null) {
        waveTop = parseFloat(localStorage['googleWave']);
      }
    }

    if (typeof localStorage['workTime'] != 'undefined' && localStorage['workTime'] != null) {
      workTime = parseFloat(localStorage['workTime']);
    }

  }


  return step;
}

function createKalmen() {
  let kalmen = {};

  let x_n_n = null;

  let x_n1_n = null;

  let error = 0.0001;

  let p_n_n = 0.01;

  let p_n1_n = null;

  let k = null;

  kalmen.updateKalmenValue = function (value) {
    function init(value) {
      x_n_n = value;
      x_n1_n = value;
      p_n1_n = p_n_n + error;
    }

    if (x_n1_n == null) {
      init(value);
    }
    k = p_n1_n / (p_n1_n + 0.001);
    x_n_n = x_n1_n + k * (value - x_n_n);
    p_n_n = (1 - k) * p_n_n;
    x_n1_n = x_n_n;
    p_n1_n = p_n_n + error;
    return x_n_n;
  }

  return kalmen;
}

// 纠正北的方向
function createCorrectDirection() {
  const d = new Object()

  let north = null

  let alpha = null

  let oldAlpha = null

  let wxDirection = null

  function getCorrect(direction) {
    let angle = direction
    while (angle > 360) {
      angle = angle - 360
    }
    while (angle < 0) {
      angle = 360 + direction
    }
    return angle
  }

  function getCount(angle1, angle2) {
    const c1 = getCorrect(angle1 - angle2)
    const c2 = getCorrect(angle2 - angle1)
    return c1 > c2 ? c2 : c1
  }

  let judgeArr = []

  d.getNorth = function (config) {
    if (typeof config.direction == 'undefined' || config.direction == null) {
      return
    }
    const direction = getCorrect(parseFloat(config.direction))
    wxDirection = direction
    if (north == null) {
      north = direction
    } else {
      const res = this.returnNowAngle()
      if (res.result == false) {
        return
      }
      // 对比前后差值
      const angle = getCorrect(res.angle)
      const errorAngle = 35
      const angle1 = getCorrect(angle - errorAngle)
      const angle2 = getCorrect(angle + errorAngle)
      const a1 = getCount(angle1, direction)
      const a2 = getCount(angle2, direction)
      if (a1 + a2 == errorAngle * 2) {
        judgeArr = []
        return
      } else {
        judgeArr.push('')
        if (judgeArr.length == 2) {
          judgeArr = []
          north = direction
        }
      }
    }
  }

  d.update = function (angle) {
    alpha = angle
  }

  d.returnNowAngle = function () {
    if (north == null) {
      return {
        result: false
      }
    }

    if (north != null && alpha == null) {
      north = wxDirection
      return {
        result: true,
        angle: north,
        wxDirection: wxDirection
      }
    }
    if (oldAlpha == null) {
      oldAlpha = alpha
      return {
        result: true,
        angle: north,
        wxDirection: wxDirection
      }
    }
    const angle = getCorrect(north + (oldAlpha - alpha))
    oldAlpha = alpha
    north = angle
    return {
      result: true,
      angle: angle,
      wxDirection: wxDirection
    }
  }
  return d
}

export function createDeviceMotionInfo_2() {
  const direction = createCorrectDirection()

  let directionBlock = null

  const motionInfo = new Object()

  let moveBlock = null

  let decectBlock = null

  let firstAlphaBlock = null;

  let rotateBlock = null

  let devicemotion = false

  let deviceRotate = false

  let getValues = []

  const accelerationInfo = {
    changeAlpha: 0,
    changeBeta: 0,
    changeGama: 0,
    firstAlpha: null,
    alpha: 0,
    beta: 0,
    gamma: 0,
    y: 0,
    x: 0,
    z: 0,
    interval: 16,
    start: false,
    realY: 0,
    realX: 0,
    realZ: 0,
    speedX: 0,
    speedY: 0,
    speedZ: 0,
    accelerationIncludingGravityX: 0,
    accelerationIncludingGravityY: 0,
    accelerationIncludingGravityZ: 0
  }

  let xKalmenValue = createKalmen();

  let yKalmenValue = createKalmen();

  let zKalmenValue = createKalmen();

  let xKalmen = createKalmen();

  let yKalmen = createKalmen();

  let zKalmen = createKalmen();

  let stepManager = createStepManager();


  motionInfo.open = function (callBack) {
    getPower(callBack)
  }

  motionInfo.returAngleAction = function (callBack) {
    directionBlock = callBack
  }

  motionInfo.getDirection = function (config) {
    direction.getNorth(config)
    sendAngle()
  }

  motionInfo.getCollection = function () {
    accelerationInfo.start = false
    const msg = JSON.stringify(getValues)
    getValues = []
    setTimeout(() => {
      accelerationInfo.start = true
    }, 1000)
    return msg
  }

  motionInfo.showMsg = function (callBack) {
    decectBlock = callBack
  }

  motionInfo.roateBlock = function (callBack) {
    rotateBlock = callBack
  }

  motionInfo.clearCollection = function () {
    getValues = []
  }

  function getPower(callBack) {
    if (typeof DeviceMotionEvent != 'undefined' && typeof DeviceMotionEvent.requestPermission === 'function') {
      // IOS 13
      DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            detectMotion()
          } else {
            callBack(false)
          }
        })
        .catch((err) => {
          // alert('用户未允许权限')
          callBack(false)
        })
    } else {
      detectMotion()
    }

    if (typeof DeviceOrientationEvent != 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
      // IOS 13
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            detectDeviceorientation();
          } else {
            callBack(false)
          }
        })
        .catch((err) => {
          // alert('用户未允许权限')
          callBack(false)
        })
    } else {
      detectDeviceorientation();
    }
  }

  motionInfo.detectDeviceorientation = function () {
    detectDeviceorientation();
    detectMotion();
  }

  function detectDeviceorientation() {
    if (deviceRotate == false) {
      window.addEventListener('deviceorientation', DeviceOrientationHandler, true)
      deviceRotate = true
    }
  }

  function detectMotion() {
    if (devicemotion == false) {
      window.addEventListener('devicemotion', detectAction, false)
      devicemotion = true
    }
    if (devicemotion == false) {
      return;
    }
    setTimeout(() => {
      accelerationInfo.start = true
    }, 500)
  }

  motionInfo.move = function (callBack) {
    moveBlock = callBack
  }

  motionInfo.close = function () {
    if (devicemotion == true) {
      window.removeEventListener('devicemotion', detectAction, false)
      devicemotion = false
    }
    if (deviceRotate == true) {
      window.removeEventListener('deviceorientation', DeviceOrientationHandler, false)
      deviceRotate = false
    }
  }

  motionInfo.updateAlpha = function (alpha) {
    direction.update(alpha)
    sendAngle()
  }

  motionInfo.firstAlpha = function (callBack) {
    firstAlphaBlock = callBack;
  }

  function DeviceOrientationHandler(event) {
    direction.update(event.alpha)
    sendAngle()
    accelerationInfo.alpha = event.alpha
    accelerationInfo.beta = event.beta
    accelerationInfo.gamma = event.gamma
    if (accelerationInfo.firstAlpha != null) {
      return;
    }
    if (firstAlphaBlock == null) {
      return;
    }
    accelerationInfo.firstAlpha = event.alpha;
    firstAlphaBlock(accelerationInfo.firstAlpha)
  }

  function sendAngle() {
    if (directionBlock != null) {
      // 计算当前的方向
      const res = direction.returnNowAngle()
      if (res.result == false) {
        return
      }
      directionBlock(res.angle, res.wxDirection)
    }
  }

  function detectAction(event) {
    if (accelerationInfo.start == false) {
      return
    }
    let x = event.acceleration.x;
    let y = event.acceleration.y;
    let z = event.acceleration.z;
    let alpha = event.rotationRate.alpha;
    let gamma = event.rotationRate.gamma;
    let beta = event.rotationRate.beta;
    let accelerationIncludingGravityX = event.accelerationIncludingGravity.x;
    let accelerationIncludingGravityY = event.accelerationIncludingGravity.y;
    let accelerationIncludingGravityZ = event.accelerationIncludingGravity.z;
    accelerationInfo.accelerationIncludingGravityX = accelerationIncludingGravityX;
    accelerationInfo.accelerationIncludingGravityY = accelerationIncludingGravityY;
    accelerationInfo.accelerationIncludingGravityZ = accelerationIncludingGravityZ;
    accelerationInfo.changeAlpha = alpha;
    accelerationInfo.changeBeta = beta;
    accelerationInfo.changeGama = gamma;
    accelerationInfo.x = x;
    accelerationInfo.y = y;
    accelerationInfo.z = z;
    startWorking();
  }

  function getRotateMatrix4(config) {
    let alpha = config.alpha / 180 * Math.PI;
    let beta = config.beta / 180 * Math.PI;
    let gamma = config.gamma / 180 * Math.PI;

    let orderStr = 'XYZ';
    let a = new THREE.Euler(beta, gamma, alpha, orderStr);
    let m = new THREE.Matrix4();
    m.makeRotationFromEuler(a);
    return {
      matrix: m,
      alpha: alpha,
      beta: beta,
      gamma: gamma,
    };
  }

  function updateAcceleration() {
    let copy = JSON.parse(JSON.stringify(accelerationInfo))
    let result = getRotateMatrix4(copy);
    let determinant = result.matrix.determinant();
    if (determinant == 0) {
      return;
    }

    let dataX = xKalmenValue.updateKalmenValue(accelerationInfo.x)
    let dataY = yKalmenValue.updateKalmenValue(accelerationInfo.y)
    let dataZ = zKalmenValue.updateKalmenValue(accelerationInfo.z)


    let m = result.matrix.getInverse(result.matrix);
    let x = new THREE.Vector3(dataX, 0, 0)
    let y = new THREE.Vector3(0, dataY, 0)
    let z = new THREE.Vector3(0, 0, dataZ)

    let cx = x.applyMatrix4(m);
    let cy = y.applyMatrix4(m);
    let cz = z.applyMatrix4(m);

    let totalX = cx.x + cy.x + cz.x;
    let totalY = cx.y + cy.y + cz.y;
    let totalZ = cx.z + cy.z + cz.z;

    let newZ = zKalmen.updateKalmenValue(totalZ);

    let newX = xKalmen.updateKalmenValue(totalX);

    let newY = yKalmen.updateKalmenValue(totalY);

    let time = new Date().getTime();

    let res = stepManager.updateWave(newX, newY, newZ, time, 0);
    if (res.result == false || moveBlock == null) {
      return;
    }
    moveBlock(res);
  }


  // 发送旋转后的数据到前端
  function sendRotateData() {
    if (rotateBlock == null) {
      return;
    }
    let copy = JSON.parse(JSON.stringify(accelerationInfo))
    let result = getRotateMatrix4(copy);
    let m = result.matrix
    let cX = new THREE.Vector3(50, 0, 0)
    let cY = new THREE.Vector3(0, 50, 0)
    let cZ = new THREE.Vector3(0, 0, 50)

    let cx_ = cX.applyMatrix4(m);
    let cy_ = cY.applyMatrix4(m);
    let cz_ = cZ.applyMatrix4(m);
    rotateBlock({
      alpha: result.alpha,
      beta: result.beta,
      gamma: result.gamma,
      x_: cx_,
      y_: cy_,
      z_: cz_,
    });
  }

  function startWorking() {
    if (accelerationInfo.start == false) {
      return;
    }
    // sendRotateData();
    // sendCollectionData();
    updateAcceleration();
  }


  function sendCollectionData() {
    if (decectBlock == null) {
      return
    }
    let copy = JSON.parse(JSON.stringify(accelerationInfo))
    let time = new Date().getTime();
    decectBlock({
      data: copy,
      time: time,
      result: true,
      type: 'acceleration'
    });

  }

  motionInfo.test = function () {

  }

  motionInfo.simulationMove = function (dataList) {

  }

  motionInfo.getLastestData = function (dataList) {

    let zStr = 'z';

    let zK = createKalmen();

    let xk = createKalmen();

    let yk = createKalmen();

    let z_k = createKalmen();

    let x_k = createKalmen();

    let y_k = createKalmen();

    let zkStr = ''

    let xkStr = 'xk'

    let ykStr = 'yk'

    let totalStr = '';


    let step = createStepManager();


    function getAcceleration(data, time, index) {
      let result = getRotateMatrix4(data);
      let determinant = result.matrix.determinant();
      if (determinant == 0) {
        return;
      }
      let dataX = x_k.updateKalmenValue(data.x)
      let dataY = y_k.updateKalmenValue(data.y)
      let dataZ = z_k.updateKalmenValue(data.z)


      let m = result.matrix.getInverse(result.matrix);
      let x = new THREE.Vector3(dataX, 0, 0)
      let y = new THREE.Vector3(0, dataY, 0)
      let z = new THREE.Vector3(0, 0, dataZ)

      let cx = x.applyMatrix4(m);
      let cy = y.applyMatrix4(m);
      let cz = z.applyMatrix4(m);


      let totalX = cx.x + cy.x + cz.x;
      let totalY = cx.y + cy.y + cz.y;
      let totalZ = cx.z + cy.z + cz.z;

      zStr = zStr + ',' + totalZ

      let newZ = zK.updateKalmenValue(totalZ);

      let newx = xk.updateKalmenValue(totalX);

      let newy = yk.updateKalmenValue(totalY);

      zkStr = zkStr + ',' + newZ

      xkStr = xkStr + ',' + newx

      ykStr = ykStr + ',' + newy

      step.updateWave(newx, newy, newZ, time, index);
    }

    function getHistoryData(text) {
      try {
        let dataList = JSON.parse(text);
        for (let i = 0; i < dataList.length; i++) {
          let dataInfo = dataList[i]
          getAcceleration(dataInfo.data, dataInfo.time, i);
        }
        step.getZwaveList()
        // console.log(zStr)
      } catch (e) {

      }
    }


    if (dataList.length == 0) {
      return;
    }
    let data = dataList[dataList.length - 1];
    let json = data['json'];
    try {
      let data = JSON.parse(json);
      getHistoryData(data['text'])
    } catch (e) {

    }
  }

  motionInfo.setUpConfig = function () {

    let useInertia = '0';
    if (typeof localStorage['useInertia'] != 'undefined' && localStorage['useInertia'] != null) {
      useInertia = localStorage['useInertia'];
    }

    if (useInertia == '0' && devicemotion == true) {
      window.removeEventListener('devicemotion', detectAction, false)
      devicemotion = false
    }

    stepManager.setUpConfig();
  }

  return motionInfo
}
