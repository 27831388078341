import * as THREE from 'three/build/three.min.js'
import {createFloorTitle} from './floorText'
import {createAnalaysisInfo} from '../../analaysis/dataAnalaysisInfo'
import {makeRoomConfig} from '../../MapConfig'
import {createIntersects} from "../../intersects/intersects";

export function createTextGroup() {
  let group = new THREE.Group()

  let intersect = createIntersects();

  let makeConfig = makeRoomConfig()

  let textGroup = {}

  let showGroupInfo = {
    mapid: '',
    groupType: ''
  }

  let coverGroup = null

  let scaleBlock = null

  let mapGroup = {}

  let showZoneTitle = {}

  group.scaleAction = function (callBack) {
    scaleBlock = callBack
  }

  let analysis = createAnalaysisInfo() // 构造地图解析



  group.getAllMapTitleInfoByPromise = function (config, callBack) {
    /* ------------------ */
    function loadFloorTitle(config, mapKeys, index, mapInfo, leadInfo, callBack) {
      if (index >= mapKeys.length) {
        callBack()
        return
      }
      let key = mapKeys[index]
      let lead = {}
      if (analysis.jsonKeyIsExist(leadInfo, [key])) {
        lead = leadInfo[key]
      }
      let setting = {
        mapid: key,
        y: config.layerHeight,
        mode: config.mode,
        isShowLinkText: config.isShowLinkText,
        layerHeight: config.layerHeight
      }
      let g = createFloorTitle()
      textGroup[key] = g
      g.updateText(mapInfo[key], setting, lead)
      group.add(g)
      g.visible = false
      g.advanceAnimation()
      setTimeout(() => {
        loadFloorTitle(config, mapKeys, index + 1, mapInfo, leadInfo, callBack)
      }, makeConfig.loadFloorTitleTime)
    }

    function createCoverGroup(config, callBack) {
      let coverList = config['coverInfo']
      if (coverList.length == 0) {
        callBack()
        return
      }
      let setting = {
        mapid: '-',
        y: config.layerHeight,
        mode: config.mode,
        isShowLinkText: config.isShowLinkText,
        layerHeight: config.layerHeight
      }
      coverGroup = createFloorTitle()
      coverGroup.updateText(coverList, setting, {})
      group.add(coverGroup)
      coverGroup.visible = false
      coverGroup.advanceAnimation()
      setTimeout(() => {
        callBack()
      }, makeConfig.loadCommonTitleTime)
    }

    /* ------------------ */

    let mapInfo = config['mapInfo']
    let leadInfo = config['leadInfo']
    let mapKeys = Object.keys(mapInfo)

    let loadCoverCallback = () => {
      loadFloorTitle(config, mapKeys, 0, mapInfo, leadInfo, () => {
        callBack()
      })
    }
    createCoverGroup(config, loadCoverCallback)
  }

  group.updateMapTitle = function(roomInfo, position, config) {
    let mapid = config['mapid'];
    if (analysis.jsonKeyIsExist(textGroup, [mapid]) == false) {
      return
    }
    let g = textGroup[mapid]
    g.updateMapTitle(roomInfo, position, config)

  }

  function createCoverGroup(config) {
    if (analysis.jsonKeyIsExist(config, ['coverInfo']) == false) {
      return
    }
    let coverList = config['coverInfo']
    if (coverList.length == 0) {
      return
    }
    let setting = {
      mapid: '-',
      y: config.layerHeight,
      mode: config.mode,
      isShowLinkText: config.isShowLinkText,
      layerHeight: config.layerHeight
    }
    if (coverGroup == null) {
      coverGroup = createFloorTitle()
      group.add(coverGroup)
    }
    coverGroup.updateTextByPromise(coverList, setting, () => {

    })
    coverGroup.visible = false
    coverGroup.advanceAnimation()
    coverGroup.uuid = 'cover'
  }

  // 延迟加载地图
  group.lazyCreateLoadMapTitle = function (config) {
    createCoverGroup(config)
    let mapRoomInfo = config['roomInfo']
    for (const mapid in mapRoomInfo) {
      let g = new THREE.Group()
      group.add(g)
      mapGroup[mapid] = g
      g.visible = false
    }
  }

  let updateObjectInfo = {}

  group.lazyLoadMapTitle = function (config, callBack, updateTextBack) {
    let mapid = config['mapid']
    if (analysis.jsonKeyIsExist(mapGroup, [mapid]) == false) {
      return
    }
    let mapG = mapGroup[mapid]

    let lookAtZone = config['lookAtZone']
    let mapInfo = {}
    if (analysis.jsonKeyIsExist(updateObjectInfo, [mapid])) {
      mapInfo = updateObjectInfo[mapid]
    } else {
      updateObjectInfo[mapid] = mapInfo
    }

    let createRoomTitle = false

    let makeInfo = {}

    let zoneId = ''

    for (const zonekey in lookAtZone) {
      if (analysis.jsonKeyIsExist(mapInfo, [zonekey])) {
        makeInfo = mapInfo[zonekey]
        if (analysis.jsonKeyIsExist(makeInfo, ['createRoomTitle'])) {
          createRoomTitle = true
        }
      } else {
        mapInfo[zonekey] = makeInfo
      }
      zoneId = zonekey
    }

    for (let i = 0; i < mapG.children.length; i++) {
      let g = mapG.children[i]
      g.visible = zoneId == g.uuid
    }

    if (createRoomTitle) {
      return
    }

    let setting = {
      mapid: mapid,
      y: config.layerHeight,
      mode: config.mode,
      isShowLinkText: false,
      layerHeight: config.layerHeight
    }
    let g = createFloorTitle()
    // g.updateTextByPromise(config['room'], setting, () => {
    //   updateTextBack();
    // })
    g.updateText(config['room'], setting, {})
    mapG.add(g)
    g.uuid = zoneId
    makeInfo['createRoom'] = ''
    createRoomTitle = true,
      callBack({
        mapid: mapid,
        lookAtZone: lookAtZone,
        createRoomTitle: createRoomTitle,
        types: ['createRoomTitle']
      })
  }

  // 加载全部地图文字
  group.getAllMapTitleInfo = function (config) {
    createCoverGroup(config)
    let mapInfo = config['mapInfo']
    let leadInfo = config['leadInfo']
    for (const key in mapInfo) {
      let map = mapInfo[key]
      let lead = {}
      if (analysis.jsonKeyIsExist(leadInfo, [key])) {
        lead = leadInfo[key]
      }
      let setting = {
        mapid: key,
        y: config.layerHeight,
        mode: config.mode,
        isShowLinkText: config.isShowLinkText,
        layerHeight: config.layerHeight
      }
      let g = createFloorTitle()
      textGroup[key] = g
      g.updateText(map, setting, lead)
      group.add(g)
      g.visible = false
      g.advanceAnimation()
    }
  }

  group.loadMap = function (config) {
    let mapid = config.mapid
    let lv = config.lv
    let judgeLV = config.judgeLV
    showGroupInfo.mapid = mapid
    if (coverGroup != null && lv >= judgeLV) {
      coverGroup.visible = true
      showGroupInfo.groupType = 'cover'
      return
    }
    showGroupInfo.groupType = 'normal'
    for (const key in textGroup) {
      let g = textGroup[key]
      g.visible = key == mapid
    }
    for (const key in mapGroup) {
      let g = mapGroup[key]
      g.visible = key == mapid
    }
  }

  group.textRotate = function (config) {
    let showZone = Object.keys(showZoneTitle).length == 0 ? false : true // 是否只显示区域名称
    let lv = config.lv
    let judgeLV = config.judgeLV
    if (showZone == false && lv >= judgeLV && coverGroup != null) {
      coverGroup.visible = true
      showGroupInfo.groupType = 'cover'
      coverGroup.textRotate(config, scaleBlock)
      for (const key in textGroup) {
        let g = textGroup[key]
        g.visible = false
      }
      for (const key in mapGroup) {
        let g = mapGroup[key]
        g.visible = false
      }
      return
    }

    if (coverGroup != null) {
      coverGroup.visible = false
    }
    showGroupInfo.groupType = 'normal'
    let lazyLoad = false
    if (analysis.jsonKeyIsExist(config, ['lazyLoad'])) {
      lazyLoad = config['lazyLoad']
    }
    if (lazyLoad) {
      for (const mapid in mapGroup) {
        let mapG = mapGroup[mapid]
        if (mapid != config.mapid) {
          mapG.visible = false
          continue
        }
        mapG.visible = true
        let lookAtZone = config['lookAtZone']
        for (let i = 0; i < mapG.children.length; i++) {
          let g = mapG.children[i]
          if (showZone) {
            // 只显示区域名称
            if (analysis.jsonKeyIsExist(showZoneTitle, [g.uuid])) {
              g.visible = true
              g.textRotate(config, scaleBlock)
            } else {
              g.visible = false
            }
            continue
          }

          if (analysis.jsonKeyIsExist(lookAtZone, [g.uuid]) == false) {
            g.visible = false
            continue
          }
          g.visible = true
          g.textRotate(config, scaleBlock)
        }
      }
    } else {
      for (const key in textGroup) {
        let select = key == config.mapid
        let g = textGroup[key]
        g.visible = select
        if (select) {
          g.textRotate(config, scaleBlock)
        }
      }
    }
  }

  group.roomTextAnimation = function () {
    if (showGroupInfo.groupType == 'cover') {
      coverGroup.roomTextAnimation()
      for (const key in textGroup) {
        let g = textGroup[key]
        g.stopAnimation()
      }
      return
    }
    if (coverGroup != null) {
      coverGroup.stopAnimation()
    }
    for (const key in textGroup) {
      let g = textGroup[key]
      if (g.visible == true) {
        g.roomTextAnimation()
      } else {
        g.stopAnimation()
      }
    }
    for (const mapid in mapGroup) {
      let mapG = mapGroup[mapid]
      if (mapG.visible == false) {
        continue
      }
      for (let i = 0; i < mapG.children.length; i++) {
        let g = mapG.children[i]
        if (g.visible == false) {
          g.stopAnimation()
          continue
        }
        g.roomTextAnimation()
      }
    }

  }

  group.deleteAll = function () {
    updateObjectInfo = {}
    showZoneTitle = {}
    if (coverGroup != null) {
      coverGroup.deleteAll()
    }
    for (const key in textGroup) {
      let g = textGroup[key]
      g.deleteAll()
    }
    for (const key in mapGroup) {
      let mapG = mapGroup[key]
      for (let i = 0; i < mapG.children.length; i++) {
        let g = mapG.children[i]
        g.deleteAll()
      }
      if (mapG.parent != null) {
        mapG.parent.remove(mapG)
      }
    }
    mapGroup = {}
    textGroup = {}
  }

  group.findMapZone = function (config) {
    let textInfo = config.textInfo
    for (const key in textGroup) {
      let g = textGroup[key]
      let list = g.getZoneText({zoneId: config.zoneId})
      textInfo[key] = list
    }
    for (const map in mapGroup) {
      let magG = mapGroup[map]
      for (let i = 0; i < magG.children.length; i++) {
        let g = magG.children[i]
        let list = g.getZoneText({zoneId: config.zoneId})
        textInfo[map] = list
      }
    }
  }

  group.hiddenOrShowDetailText = function (config) {
    let zoneId = config['zoneId']
    if (zoneId == '') {
      showZoneTitle = {}
    } else {
      showZoneTitle[zoneId] = ''
    }
    if (coverGroup != null) {
      coverGroup.visible = (zoneId == '')
    }
    for (const key in textGroup) {
      let g = textGroup[key]
      g.showOrHiddenZoneText(config)
    }
    for (const map in mapGroup) {
      let magG = mapGroup[map]
      for (let i = 0; i < magG.children.length; i++) {
        let textG = magG.children[i]
        textG.visible = (zoneId == '' ? true : (textG.uuid == zoneId))
      }
    }
  }


  group.clickText = function (config, event) {
    function clickCoverGroup(config) {
      if (coverGroup == null) {
        return {
          result: false,
        }
      }
      return {result: true, list: coverGroup.getTexts(config)};
    }

    // 是否点击显示遮罩
    let clickCover = false;
    if (analysis.jsonKeyIsExist(config, ['clickCover'])) {
      clickCover = config['clickCover'];
    }

    let list = [];

    if (clickCover) {
      let res = clickCoverGroup(config)
      if (res.result) {
        list = res.list;
      }
    } else {
      for (const key in textGroup) {
        let g = textGroup[key]
        if (g.visible == false) {
          continue;
        }
        list = list.concat(g.getTexts(config))
      }

      for (const map in mapGroup) {
        let magG = mapGroup[map]
        for (let i = 0; i < magG.children.length; i++) {
          let g = magG.children[i]
          list = list.concat(g.getTexts(config))
        }
      }
    }

    config['meshs'] = list;
    let finder = intersect.getIntersects(event, config)


    let intersects = finder.intersects
    let result = {
      result: false,
      isSameRoom: false,
      data: {}
    }
    for (let i = 0; i < intersects.length; i++) {
      let object = intersects[i].object

      if (analysis.jsonKeyIsExist(object, ['setting']) == false) {
        continue
      }
      let setting = object['setting']
      result.result = true
      result.data['roomid'] = ''
      result.data['mapid'] = config['mapid']
      result.data['organizationName'] = config['organizationName']
      result.data['roomName'] = setting['title']
      result.data['title'] = setting['title']
      result.data['roomAreaId'] = setting['areaid']
      result.data['areaid'] = setting['areaid']
      result.data['x'] = object.position.x
      result.data['y'] = 3
      result.data['z'] = object.position.z
      result.data['type'] = 'clickRoom'
      result.data['centerY'] = 0
      result.data['zoneTitle'] = object['zoneTitle']
      break
    }
    return result

  }

  return group
}

