import {
  detailMapConfig
} from '../../MapConfig'
import * as THREE from 'three/build/three.min.js'
import {
  createDetailGroup
} from './detailGroup'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createNewOrbitControls
} from '../controls/myOrbitControls'
import {
  createLeadWay
} from '../leadWay/leadWayJS'
import {
  createCSS2DManager
} from '../CSS2DManager/CSS2Dmanager'

export function createDetailMapInfo() {
  const scene = new THREE.Scene()

  const css2D = createCSS2DManager()

  const mapConfig = detailMapConfig()

  /* 解析 */
  const analysis = createAnalaysisInfo() // 构造地图解析

  const detailGroup = createDetailGroup()

  const leadWay = createLeadWay()

  let windowWidth = 0

  let windowHeight = 0

  let camera = null

  let cameraMode = 0

  let controls = null // 地图的轨道控制器

  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    logarithmicDepthBuffer: true
  }) // 地图的渲染器

  let renderDiv = ''

  const minPolarAngle = Math.PI / 3

  const maxPolarAngle = Math.PI / 3

  const textLayerInfo = {}

  let mapList = []

  const lightList = []

  let floorInfo = {
    center: [],
    index: 0,
    zoneId: '',
    mapid: ''
  }

  let renderStart = false

  let changeMapidBlock = null

  let moveZoneBlock = null

  scene.moveZone = function (callBack) {
    moveZoneBlock = callBack
  }

  scene.changeMapId = function (callBack) {
    changeMapidBlock = callBack
  }

  scene.setUpConfig = function (config) {
    function createSpotLight(config) {
      const spotLight = new THREE.SpotLight(config.color, 0.4)
      const position = config.position
      spotLight.position.set(position.x, position.y, position.z)
      spotLight.castShadow = true
      spotLight.shadow.mapSize.width = 65535
      spotLight.shadow.mapSize.height = 65535
      return spotLight
    }

    function addRenderByID(divId) {
      if (divId == null || typeof (divId) == 'undefined') {
        return
      }
      const superdiv = document.getElementById(divId)
      if (superdiv == null) {
        return
      }
      superdiv.appendChild(renderer.domElement)
    }

    if (camera != null) {
      return {
        result: false
      }
    }
    if (analysis.jsonKeyIsExist(config, ['windowWidth'])) {
      windowWidth = config['windowWidth']
    }
    if (analysis.jsonKeyIsExist(config, ['windowHeight'])) {
      windowHeight = config['windowHeight']
    }

    cameraMode = mapConfig.cameraMode

    css2D.init({
      width: windowWidth,
      height: windowHeight,
      divId: mapConfig.renderDivId
    })

    renderDiv = mapConfig.renderDivId
    addRenderByID(renderDiv)

    if (cameraMode == 1) {
      const count = 200
      camera = new THREE.OrthographicCamera(-count * (windowWidth / windowHeight), count * (windowWidth /
        windowHeight), count, -count, -65535, 65535)
      camera.zoom = 1
      camera['aspectCount'] = count
    } else {
      camera = new THREE.PerspectiveCamera(mapConfig.fov, windowWidth / windowHeight, 0.1, 10000000)
    }
    camera.position.x = 0
    camera.position.y = 50
    camera.position.z = 0

    // 渲染视图视角
    const zeroPoint = new THREE.Vector3(0, 0, 0)
    camera.lookAt(zeroPoint)

    const color = mapConfig.renderBg

    renderer.setClearColor(color)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(windowWidth, windowHeight)
    renderer.sortObjects = false

    // 控制相机
    controls = new createNewOrbitControls(camera, renderer.domElement)
    controls.target = zeroPoint

    if (camera.isPerspectiveCamera) {
      controls.minDistance = 200
      controls.maxDistance = 1000
      camera['distance'] = controls.target.distanceTo(controls.object.position)
    } else {
      const x1 = ((0) / windowWidth) * 2 - 1
      const y1 = -((0) / windowHeight) * 2 + 1
      const p1 = new THREE.Vector3(x1, y1, -1).unproject(camera)

      const x2 = (1) * 2 - 1
      const y2 = -(1) * 2 + 1
      const p2 = new THREE.Vector3(x2, y2, -1).unproject(camera)
      camera.leftUp = p1
      camera.rightDown = p2
    }

    // 右键拖拽
    controls.enableZoom = mapConfig.controlZoom
    controls.enablePan = true
    controls.screenSpacePanning = true
    controls.panBlockAction(function () {
      mapRotate()
    })

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5) // 环境光
    scene.add(ambientLight)

    const c = 0xe4e4e4
    const light1 = createSpotLight({
      color: c,
      position: {
        x: 1000,
        y: 0,
        z: 0
      }
    })
    light1['positionType'] = 'x+'
    const light2 = createSpotLight({
      color: c,
      position: {
        x: -1000,
        y: 0,
        z: 0
      }
    })
    light2['positionType'] = 'x-'
    const light3 = createSpotLight({
      color: c,
      position: {
        x: 0,
        y: 0,
        z: 1000
      }
    })
    light3['positionType'] = 'z+'
    const light4 = createSpotLight({
      color: c,
      position: {
        x: 0,
        y: 0,
        z: -1000
      }
    })
    light4['positionType'] = 'z-'

    lightList.push(light1)
    lightList.push(light2)
    lightList.push(light3)
    lightList.push(light4)

    scene.add(light1)
    scene.add(light2)
    scene.add(light3)
    scene.add(light4)

    controls.minPolarAngle = minPolarAngle
    controls.maxPolarAngle = maxPolarAngle

    scene.add(detailGroup)

    leadWay.findPointNameAction((res) => {
      const mapid = res.mapid
      let name = ''
      for (let i = 0; i < floorInfo.center.length; i++) {
        const info = floorInfo.center[i]
        if (mapid == info.mapid) {
          name = info.mapName
          break
        }
      }
      return name
    })
    return {
      result: true
    }
  }

  function floorsShowMap(config) {
    if (floorInfo.center.length == 0) {
      return
    }
    const findIndex = config.findIndex
    const judgeAngle = config.judgeAngle
    const judgeScale = config.judgeScale
    const zoomSize = config.zoomSize
    const showMapid = config.showMapid
    const judgeTarget = config.judgeTarget
    let judgePanTarget = config.judgePanTarget
    if (mapConfig.moveMapCenter) {
      judgePanTarget = false
    }
    let changeMap = false
    if (analysis.jsonKeyIsExist(config, ['changeMap'])) {
      changeMap = config.changeMap
    }

    function judgeC(findInfo, changeMap) {
      let angle = {}
      if (judgeAngle) {
        angle = {
          angleY: maxPolarAngle,
          angleXZ: mapConfig.defaultAngleXZ
        }
      }
      let scale = {}
      if (judgeScale) {
        scale = findInfo.fence
        const w = findInfo.fence.rightDown.x - findInfo.fence.leftUp.x
        const h = findInfo.fence.rightDown.z - findInfo.fence.leftUp.z
        scale['w'] = w * mapConfig.zoomScale
        scale['h'] = h * mapConfig.zoomScale
      }
      let target = {}
      if (judgeTarget) {
        target = {
          target: center
        }
      }

      if (changeMap || mapConfig.moveMapCenter) {
        target = {
          target: center
        }
        controls.controlCameraAniamtion(target, angle, scale, {
          time: 300,
          moveY: true,
          zoomSize: zoomSize
        }, () => {
          mapRotate()
        }, () => {
          mapRotate()
        })
      }
    }

    function judgePan() {
      if (analysis.jsonKeyIsExist(floorInfo, ['mapBox']) == false) {
        return {
          result: false
        }
      }
      const mapBox = floorInfo['mapBox']
      const containsPoint = mapBox.containsPoint(controls.target)
      if (containsPoint == true) {
        return {
          result: false
        }
      }

      let y = controls.target.y
      if (y > mapBox.max.y) {
        y = mapBox.max.y
      }
      if (y < mapBox.min.y) {
        y = mapBox.min.y
      }

      const centerx = (mapBox.max.x + mapBox.min.x) / 2.0
      const centerz = (mapBox.max.z + mapBox.min.z) / 2.0

      const target = new THREE.Vector3(centerx, y, centerz)
      const direction = new THREE.Vector3(controls.target.x - target.x, controls.target.y - target.y, controls.target.z -
        target.z).normalize()
      const ray = new THREE.Ray(target, direction)
      const res = ray.intersectBox(mapBox, new THREE.Vector3(0, 0, 0))
      if (res == null) {
        return
      }
      controls.controlCameraAniamtion({
        target: res
      }, {}, {}, {
        time: 100,
        moveY: true
      }, () => {
      }, () => {

      })
      return {
        result: true
      }
    }

    floorInfo.index = findIndex
    const findInfo = floorInfo.center[findIndex]
    const center = findInfo.center
    if (floorInfo.mapid != findInfo.mapid) {
      changeMap = true
      if (showMapid && changeMapidBlock != null && mapConfig.sendChangeMapId) {
        changeMapidBlock(findInfo.mapid)
      }
    }

    const zoneGroup = scene.getObjectByName(floorInfo.zoneId)
    if (zoneGroup != null) {
      for (let i = 0; i < zoneGroup.children.length; i++) {
        const mapGroup = zoneGroup.children[i]
        const mapId = findInfo['mapid']
        setUpNowMap(mapGroup, mapId != mapGroup['mapid'] ? {
          returnColor: true
        } : {
          returnColor: false,
          color: mapConfig.selectMapColor
        })
      }
    }

    floorInfo.mapid = findInfo.mapid

    if (judgePanTarget) {
      const res = judgePan()
      if (res.result == false) {
        judgeC(findInfo, changeMap)
      }
    } else {
      judgeC(findInfo, changeMap)
    }

    return {
      dataInfo: findInfo
    }
  }

  scene.showMap = function (mapid) {
    if (camera == null || renderStart == false) {
      return
    }
    let index = 0
    for (let i = 0; i < floorInfo.center.length; i++) {
      const info = floorInfo.center[i]
      if (info.mapid == mapid) {
        index = i
        break
      }
    }
    floorsShowMap({
      findIndex: index,
      judgeAngle: false,
      judgeScale: false,
      zoomSize: true,
      showMapid: false,
      judgeTarget: true,
      judgePanTarget: false,
      changeMap: true
    })
  }

  scene.judgeCamera = function () {
    if (camera == null || renderStart == false) {
      return
    }
    const meshList = detailGroup.returnNowGroupMeshList(floorInfo['zoneId'])
    const direction = controls.target.clone().sub(camera.position.clone()).normalize()

    const raycaster = new THREE.Raycaster(camera.position, direction) // 地图射线
    const resList = raycaster.intersectObjects(meshList)
    if (resList.length == 0) {

    } else {
      const floor = resList[0].object
      if (floor['groupIndex'] == floorInfo.index && mapConfig.moveMapCenter == false) {
        return
      }
      floorInfo.index = floor['groupIndex']
    }

    floorsShowMap({
      findIndex: floorInfo.index,
      judgeAngle: false,
      judgeScale: false,
      zoomSize: false,
      judgeTarget: false,
      judgePanTarget: !mapConfig.moveMapCenter,
      changeMap: false,
      showMapid: mapConfig.moveMapCenter
    })
  }

  scene.updateLeadWay = function (config) {
    const configs = []
    const poInfo = config.poList
    const leadInfo = config.leadInfo
    const leadOrder = config.leadOrder
    const linkPath = []
    for (let i = 0; i < leadOrder.length - 1; i++) {
      const order1 = leadOrder[i]
      const order2 = leadOrder[i + 1]
      const path1 = leadInfo[order1.mapid][order1.index].path
      const path2 = leadInfo[order2.mapid][order2.index].path
      const p1 = path1[path1.length - 1]
      const p2 = path2[0]
      let y1 = 0
      let y2 = 0
      for (let j = 0; j < floorInfo.center.length; j++) {
        const info = floorInfo.center[j]
        if (info.mapid == order1.mapid) {
          y1 = info.center.y
        }
        if (info.mapid == order2.mapid) {
          y2 = info.center.y
        }
      }
      const point1 = {
        x: p1.x,
        y: y1,
        z: p1.z
      }
      const point2 = {
        x: p2.x,
        y: y2,
        z: p2.z
      }

      linkPath.push({
        path: [point1, point2],
        resolution: new THREE.Vector2(windowWidth, windowHeight)
      })
    }

    for (let i = 0; i < floorInfo.center.length; i++) {
      const info = floorInfo.center[i]
      let lead = []
      if (analysis.jsonKeyIsExist(leadInfo, [info.mapid])) {
        lead = leadInfo[info.mapid]
      }

      if (info.mapid == config.mapid) {
        floorInfo.index = i
      }

      if (lead.length == 0) {
        continue
      }

      let po = []
      if (analysis.jsonKeyIsExist(poInfo, [info.mapid])) {
        po = poInfo[info.mapid]
      }
      const setting = {
        leadOrder: leadOrder,
        y: info.center.y,
        mapid: info.mapid,
        nextMapid: '',
        mode: config.mode,
        hiddenStart: false,
        poInfo: po,
        leadInfo: lead,
        resolution: new THREE.Vector2(windowWidth, windowHeight)
      }
      configs.push(setting)
    }
    leadWay.showAllLeadWayExist(configs, linkPath)
    floorsShowMap({
      findIndex: floorInfo.index,
      judgeAngle: true,
      judgeScale: true,
      zoomSize: true,
      showMapid: true,
      judgeTarget: true,
      judgePanTarget: true,
      changeMap: true
    })
    if (leadWay.parent != null) {
      leadWay.parent.remove(leadWay)
    }
    leadWay.visible = true
    scene.add(leadWay)
  }

  scene.checkZoneIsExist = function (zoneId) {
    return detailGroup.checkZoneIsExist(zoneId)
  }

  function updateLight() {
    if (analysis.jsonKeyIsExist(floorInfo, ['findFence'])) {
      const findFence = floorInfo['findFence']
      const y = findFence['y']
      const scale = 16
      const w = (findFence.rightDown.x - findFence.leftUp.x) * scale
      const h = (findFence.rightDown.z - findFence.leftUp.z) * scale

      const centerX = (findFence.leftUp.x + findFence.rightDown.x) / 2.0
      const centerZ = (findFence.leftUp.z + findFence.rightDown.z) / 2.0

      for (let i = 0; i < lightList.length; i++) {
        const light = lightList[i]
        light.position.y = y
        const type = light['positionType']
        if (type == 'x+') {
          light.position.x = centerX + w / 2.0
        } else if (type == 'x-') {
          light.position.x = centerX - w / 2.0
        } else if (type == 'z+') {
          light.position.z = centerZ + h / 2.0
        } else {
          light.position.z = centerZ - h / 2.0
        }
      }
    }
  }

  scene.getZoneAreaCloneByPromise = function (config, callBack) {
    if (floorInfo.zoneId == config.zoneId) {
      updateLight()
      callBack({
        result: true,
        center: floorInfo['centerPoint'],
        fence: floorInfo['findFence']
      })
      return
    }
    config['minPolarAngle'] = minPolarAngle
    const showCallBack = (res) => {
      if (res.result == false) {
        callBack({
          result: false
        })
        return
      }
      const zone = res.zone
      floorInfo = zone
      updateLight()
      floorsShowMap({
        findIndex: floorInfo.index,
        judgeAngle: true,
        judgeScale: true,
        zoomSize: false,
        showMapid: false,
        judgeTarget: true,
        judgePanTarget: false,
        changeMap: true
      })
      callBack({
        result: true,
        center: floorInfo['centerPoint'],
        fence: floorInfo['findFence']
      })
    }
    detailGroup.showZoneGroupByPromise(config, showCallBack)
  }

  scene.updateZoneGroup = function (config) {
    function updateMesh (config, group) {
      if (group.children == 0) {
        return;
      }
      let zoneGroup = config['zoneGroup']
      let roomGroup = group.children[group.children.length - 1];
      if (roomGroup.children.length == zoneGroup.children.length) {
        return;
      }
      for (let i = 0; i < zoneGroup.children.length; i++) {
        let mesh = zoneGroup.children[i];
        roomGroup.add(mesh);
      }
    }

    let zoneId = config['zoneId'];
    let mapid = config['mapid'];
    let key = zoneId + ' ~ ' + mapid;
    let mapName = config['mapName'];
    for (let i = 0; i < detailGroup.children.length; i++) {
      let group = detailGroup.children[i];
      if (group.type != 'Group') {
        continue;
      }
      if (group.name != zoneId) {
        continue;
      }
      for (let j = 0; j < group.children.length; j++) {
        let g = group.children[j];
        if (g.type != 'Group') {
          continue;
        }
        if (g.name != mapName) {
          continue;
        }
        updateMesh(config, g)
        break;
      }
      break;

    }
  }

  scene.getZoneAreaClone = function (config) {
    if (floorInfo.zoneId == config.zoneId) {
      updateLight()
      return {
        result: false
      }
    }
    config['minPolarAngle'] = minPolarAngle
    const res = detailGroup.showZoneGroup(config)
    const zone = res.zone
    floorInfo = zone
    updateLight()
    setTimeout(() => {
      floorsShowMap({
        findIndex: floorInfo.index,
        judgeAngle: true,
        judgeScale: true,
        zoomSize: false,
        showMapid: false,
        judgeTarget: true,
        judgePanTarget: false,
        changeMap: true
      })
    }, 100)
    return {
      result: true,
      center: floorInfo['centerPoint'],
      fence: floorInfo['findFence']
    }
  }

  scene.randerScene = function () {
    if (camera == null || renderStart == false) {
      return
    }
    const clock = new THREE.Clock()
    const delta = clock.getDelta()
    controls.update(delta)
    renderer.render(scene, camera)
    for (const key in textLayerInfo) {
      const text = textLayerInfo[key]
      text.roomTextAnimation()
    }
    leadWay.animation()
    css2D.updateRender(camera, {})
  }

  function showDis(scaleWidth, scale) {
    if (camera.isPerspectiveCamera) {
      const radius = controls.target.distanceTo(camera.position)
      const fov = camera.fov * Math.PI / 180.0
      const s1 = windowHeight / windowWidth
      const sin = 1 / Math.sin(fov / 2.0)
      const number = Math.abs(s1 * s1 * (sin * sin - 1) - 1)
      let width = Math.sqrt(radius * radius / (number)) * 2 / scale * scaleWidth / windowWidth
      width = parseFloat(width.toFixed(1))
      return width
    } else {
      // 将屏幕的两边转为地图坐标
      const x1 = ((0) / windowWidth) * 2 - 1
      const y1 = -((0) / windowHeight) * 2 + 1
      const p1 = new THREE.Vector3(x1, y1, -1).unproject(camera)

      const x2 = ((scaleWidth) / windowWidth) * 2 - 1
      const y2 = -((0) / windowHeight) * 2 + 1
      const p2 = new THREE.Vector3(x2, y2, -1).unproject(camera)
      // 计算两点x-z的距离
      let dis = Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.z - p1.z) * (p2.z - p1.z)) / scale
      dis = parseFloat(dis.toFixed(0))
      return dis
    }
  }

  function returnCameraScale() {
    let scale = 1
    if (camera.isPerspectiveCamera) {
      const zoom = controls.target.distanceTo(controls.object.position)
      const dis = camera['distance']
      scale = (dis / zoom) * 5
    } else {
      scale = controls.getZoomScaleNumber()
    }
    return scale
  }

  function setSpriteScale(sprite) {
    if (camera.isPerspectiveCamera) {
      return
    }
    let multiple = sprite['multiple']
    if (typeof (multiple) == 'undefined') {
      multiple = 10
    }
    const scale = controls.getZoomScaleNumber()
    let width = sprite['cWidth']
    let height = sprite['cHeight']
    if ((typeof (width) == 'undefined')) {
      width = 1
    }
    if ((typeof (height) == 'undefined')) {
      height = 1
    }
    const padding = 0.75
    multiple = multiple / padding
    const access = width / height
    const xScale = access * multiple / scale
    const yScale = multiple / scale
    sprite.scale.set(xScale, yScale, 1.0)
  }

  function updateRoomText() {
    const dis = camera.isPerspectiveCamera ? showDis(windowWidth, 1) * returnCameraScale() * 2.5 : showDis(windowWidth, 1)
    const config = {
      animation: false,
      isPhone: false,
      camera: camera,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      zoomNum: controls.getZoomScaleNumber(),
      dis: dis, // 整个屏幕投影的世界距离
      size: 0 // 看见的视野
    }

    detailGroup.textRotate(config, (sprite) => {
      setSpriteScale(sprite)
    })

    // for (const key in textLayerInfo) {
    //   let text = textLayerInfo[key]
    //   text.textRotate(config)
    // }
  }

  function mapRotate() {
    updateRoomText()
  }

  scene.mapRotate = function () {
    if (camera == null || renderStart == false) {
      return
    }
    mapRotate()
  }

  function resizeRender(width, height) {
    renderer.setSize(width, height)
    const k = width / height // 窗口宽高比
    if (isNaN(k)) {
      return;
    }
    if (camera.isPerspectiveCamera) {
      camera.aspect = k
    } else {
      const s = 200
      camera.left = -s * k
      camera.right = s * k
      camera.top = s
      camera.bottom = -s
    }
    camera.updateProjectionMatrix()
  }

  scene.setUpRenderView = function (config) {
    if (renderStart == true) {
      return {
        result: false
      }
    }
    renderStart = true
    if (analysis.jsonKeyIsExist(config, ['windowWidth'])) {
      windowWidth = config['windowWidth']
    }
    if (analysis.jsonKeyIsExist(config, ['windowHeight'])) {
      windowHeight = config['windowHeight']
    }
    renderer.setViewport(config.x, config.y, windowWidth, windowHeight)
    resizeRender(windowWidth, windowHeight)
    return {
      result: true
    }
  }

  function setUpNowMap(g, config) {
    function cloneMaterial(mesh) {
      const material = mesh.material
      if (Array.isArray(material)) {
        const list = []
        for (let j = 0; j < material.length; j++) {
          list.push(material[j].clone())
        }
        mesh.material = list
      } else {
        mesh.material = material.clone()
      }
      mesh['cloneMaterial'] = '1'
    }

    function colorRGBtoHex(color) {
      const rgb = color.split(',')
      const r = parseInt(rgb[0].split('(')[1])
      const g = parseInt(rgb[1])
      const b = parseInt(rgb[2].split(')')[0])
      const hex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
      return hex
    }

    if (mapConfig.setFloorAlpha == false || typeof g == 'undefined' || g == null) {
      return
    }
    const returnColor = config.returnColor
    for (let i = 0; i < g.children.length; i++) {
      const mesh = g.children[i]
      if (analysis.jsonKeyIsExist(mesh, ['type']) && mesh.type == 'Mesh') {
        if (analysis.jsonKeyIsExist(mesh, ['cloneMaterial']) == false) {
          cloneMaterial(mesh)
        }
        const material = mesh.material
        if (Array.isArray(material)) {
          for (let j = 0; j < material.length; j++) {
            const m = material[j]
            if (returnColor) {
              if (analysis.jsonKeyIsExist(m, ['saveColor'])) {
                m.color.set(m['saveColor'])
              }
            } else {
              const r = m.color.r * 255.0
              const g = m.color.g * 255.0
              const b = m.color.b * 255.0
              const color = 'rgb(' + r + ',' + g + ',' + b + ')'
              const colorStr = colorRGBtoHex(color)
              if (colorStr != config['color']) {
                m['saveColor'] = colorStr
              }
              m.color.set(config['color'])
            }
          }
        } else {
          if (returnColor) {
            if (analysis.jsonKeyIsExist(material, ['saveColor'])) {
              material.color.set(material['saveColor'])
            }
          } else {
            const r = material.color.r * 255.0
            const g = material.color.g * 255.0
            const b = material.color.b * 255.0
            const color = 'rgb(' + r + ',' + g + ',' + b + ')'
            const colorStr = colorRGBtoHex(color)
            if (colorStr != config['color']) {
              material['saveColor'] = colorStr
            }
            material.color.set(config['color'])
          }
        }
        break
      }
    }
  }

  scene.cancelAllMap = function () {
    renderStart = false
  }

  scene.renderStartSetting = function (config) {
    leadWay.visible = false
    renderStart = config.result
  }

  scene.deleteAll = function () {
    const children = scene.children
    for (let i = 0; i < children.length; i++) {
      const g = children[i]
      if (g.type != 'Group') {
        continue
      }
      for (let j = 0; j < g.children.length; j++) {
        const mapGroup = g.children[i]
        setUpNowMap(mapGroup, {
          returnColor: true
        })
      }
      scene.remove(g)
    }

    for (const mapid in textLayerInfo) {
      const text = textLayerInfo[mapid]
      if (text.parent != null) {
        text.parent.remove(text)
      }
      text.deleteAll()
    }
    leadWay.deleteAll()
    detailGroup.deleteAll()
    floorInfo.zoneId = ''
    floorInfo.mapid = ''
    floorInfo.center = []
    floorInfo.index = 0
    mapList = []
  }

  scene.resize = function (config) {
    if (renderStart == false) {
      return
    }
    const div = document.getElementById(mapConfig.renderDivId)
    if (typeof div == 'undefined' || div == null) {
      return
    }
    if (div.offsetWidth == windowWidth && div.offsetHeight == windowHeight) {
      return
    }
    windowWidth = div.offsetWidth
    windowHeight = div.offsetHeight
    renderer.setViewport(0, 0, windowWidth, windowHeight)
    resizeRender(windowWidth, windowHeight)
    css2D.reSize({
      width: windowWidth,
      height: windowHeight
    })
  }

  scene.returnRender = function () {
    return renderStart
  }

  scene.returnWidth = function () {
    return windowWidth
  }

  scene.addEventListener = function (key, func) {
    renderer.domElement.addEventListener(key, func, false)
  }

  scene.renderRemoveEvent = function (key, func) {
    renderer.domElement.removeEventListener(key, func, false)
  }

  scene.clickDetailMap = function (event, config) {
    const zoneGroup = scene.getObjectByName(floorInfo.zoneId)
    if (zoneGroup == null) {
      return {
        result: false
      }
    }
    const mouse = returnScreenToThreePoint(event)
    const vector = new THREE.Vector3() // 三维坐标对象
    vector.set(
      mouse.x,
      mouse.y,
      0.5)
    vector.unproject(camera)
    const raycaster = new THREE.Raycaster() // 地图射线
    raycaster.setFromCamera(mouse, camera)
    const meshList = []
    for (let i = 0; i < zoneGroup.children.length; i++) {
      const g = zoneGroup.children[i]
      if (g.children.length == 0) {
        continue
      }
      const mesh = g.children[0]
      mesh['mapid'] = g['mapid']
      mesh['groupIndex'] = i
      meshList.push(mesh)
    }

    const intersects = raycaster.intersectObjects(meshList) // 楼层中的元素
    if (intersects.length == 0) {
      return {
        result: false
      }
    }
    const mesh = intersects[0].object
    if (analysis.jsonKeyIsExist(mesh, ['mapid']) == false) {
      return {
        result: false
      }
    }
    floorInfo.index = mesh['groupIndex']
    floorsShowMap({
      findIndex: floorInfo.index,
      judgeAngle: false,
      judgeScale: false,
      zoomSize: false,
      judgeTarget: false,
      judgePanTarget: !mapConfig.moveMapCenter,
      changeMap: false,
      showMapid: mapConfig.moveMapCenter
    })
    if (moveZoneBlock != null) {
      moveZoneBlock({
        result: true,
        center: floorInfo['centerPoint'],
        fence: floorInfo['findFence']
      })
    }
    return {
      result: true,
      zoneId: floorInfo.zoneId
    }
  }

  function returnScreenToThreePoint(event) {
    const selectDivId = mapConfig.renderDivId
    if (analysis.jsonKeyIsExist(event, ['offsetX', 'offsetY'])) {
      const mouse = new THREE.Vector2()
      let x = event.offsetX
      if (typeof (x) == 'undefined') {
        const touch = event.touches[0] // 获取第一个触点
        x = parseFloat(touch.pageX)
      }
      let y = event.offsetY
      if (typeof (y) == 'undefined') {
        const touch = event.touches[0] // 获取第一个触点
        y = parseFloat(touch.pageY)
      }
      if (window.pageXOffset) {
        x = x + window.pageXOffset
        y = y + window.pageYOffset
      } else {
        x = x + document.body.scrollLeft + document.documentElement.scrollLeft
        y = y + document.body.scrollTop + document.documentElement.scrollTop
      }
      mouse.x = ((x - 0) / windowWidth) * 2 - 1
      mouse.y = -((y - 0) / windowHeight) * 2 + 1
      return mouse
    } else {
      const mouse = new THREE.Vector2()
      const superdiv = document.getElementById(selectDivId)
      if (superdiv == null) {
        return mouse
      }
      const superDivLeft = superdiv.offsetLeft
      const superDivLeftTop = superdiv.offsetTop + document.body.offsetHeight - superdiv.offsetHeight
      let x = event.clientX
      if (typeof (x) == 'undefined') {
        const touch = event.touches[0] // 获取第一个触点
        x = parseFloat(touch.pageX)
      }
      let y = event.clientY
      if (typeof (y) == 'undefined') {
        const touch = event.touches[0] // 获取第一个触点
        y = parseFloat(touch.pageY)
      }
      if (window.pageXOffset) {
        x = x + window.pageXOffset
        y = y + window.pageYOffset
      } else {
        x = x + document.body.scrollLeft + document.documentElement.scrollLeft
        y = y + document.body.scrollTop + document.documentElement.scrollTop
      }
      mouse.x = ((x - superDivLeft) / windowWidth) * 2 - 1
      mouse.y = -((y - superDivLeftTop) / windowHeight) * 2 + 1
      return mouse
    }
  }

  scene.updateCss2DList = function (config) {
    if (analysis.jsonKeyIsExist(floorInfo, ['center']) == false) {
      return
    }
    if (css2D.parent == null) {
      scene.add(css2D)
    }
    let showDiv = true
    if (analysis.jsonKeyIsExist(config, ['showDiv'])) {
      showDiv = config['showDiv']
    }
    let divId = 'testtest'
    const center = floorInfo['center']
    const detailDataList = config['dataList']
    for (let i = 0; i < detailDataList.length; i++) {
      const data = detailDataList[i]
      data['height'] = 0
      const mapid = data['mapid']
      if (mapid == floorInfo['mapid'] && showDiv == true && data['zoneId'] == floorInfo['zoneId']) {
        divId = data['divId']
      }

      for (let j = 0; j < center.length; j++) {
        const centerInfo = center[j]
        if (mapid == centerInfo['mapid']) {
          data['x'] = centerInfo['center']['x']
          data['y'] = centerInfo['center']['y']
          data['z'] = centerInfo['center']['z']
          break
        }
      }
    }
    config['divId'] = divId
    css2D.updateCSS2DList(config)
  }

  scene.mouseMoveOnDetail = function (event) {
    const zoneGroup = scene.getObjectByName(floorInfo.zoneId)
    if (zoneGroup == null) {
      return {
        result: false
      }
    }
    const mouse = returnScreenToThreePoint(event)
    const vector = new THREE.Vector3() // 三维坐标对象
    vector.set(
      mouse.x,
      mouse.y,
      0.5)
    vector.unproject(camera)
    const raycaster = new THREE.Raycaster() // 地图射线
    raycaster.setFromCamera(mouse, camera)
    const meshList = []
    for (let i = 0; i < zoneGroup.children.length; i++) {
      const g = zoneGroup.children[i]
      if (g.children.length == 0) {
        continue
      }
      const mesh = g.children[0]
      mesh['mapid'] = g['mapid']
      mesh['groupIndex'] = i
      meshList.push(mesh)
    }

    const intersects = raycaster.intersectObjects(meshList) // 楼层中的元素
    if (intersects.length == 0) {
      return {
        result: false
      }
    }
    const mesh = intersects[0].object
    if (analysis.jsonKeyIsExist(mesh, ['mapid']) == false) {
      return {
        result: false
      }
    }
    return {
      result: true,
      mapid: mesh['mapid'],
      zoneId: floorInfo.zoneId,
    }
  }

  return scene
}
