import {
  CSS2DRenderer
} from 'three/examples/jsm/renderers/CSS2DRenderer'
import * as THREE from 'three/build/three.min.js'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createObjectAction
} from '../createObject'

export function createCSS2DManager() {
  const manager = new THREE.Group()

  const render = new CSS2DRenderer()

  const analysis = createAnalaysisInfo() // 构造地图解析

  const create = createObjectAction()

  const css2DList = []

  let windowWidth = 0

  let windowHeight = 0

  manager.init = function(config) {
    const divId = config['divId']
    const div = document.getElementById(divId)
    if (typeof div == 'undefined' || div == null) {
      return
    }
    const width = config['width']
    const height = config['height']
    render.setSize(width, height)
    windowWidth = width
    windowHeight = height
  }

  manager.reSize = function(config) {
    const width = config['width']
    const height = config['height']
    render.setSize(width, height)
    windowWidth = width
    windowHeight = height
  }

  manager.returnRender = function(config) {
    return {
      result: css2DList.length != 0,
      render: render
    }
  }

  manager.updateRender = function(camera, config) {
    if (css2DList.length == 0) {
      return
    }

    for (let i = 0; i < css2DList.length; i++) {
      const cube = css2DList[i]
      if (cube.visible == false) {
        continue
      }
      const viewProjectionMatrix = cube['viewProjectionMatrix']
      const viewMatrix = new THREE.Matrix4()
      viewMatrix.copy(camera.matrixWorldInverse)
      viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, viewMatrix)
    }
    this.updateDivPosition(config)
  }

  manager.updateDivPosition = function(config) {
    const _widthHalf = windowWidth / 2
    const _heightHalf = windowHeight / 2
    for (let i = 0; i < css2DList.length; i++) {
      const cube = css2DList[i]
      if (cube.visible == false || analysis.jsonKeyIsExist(cube, ['div']) == false) {
        continue
      }
      const element = cube['div']
      if (element.id != cube['divId']) {
        continue
      }
      const left = cube['left']
      const top = cube['top']
      // let width = element.offsetWidth
      // let height = element.offsetHeight
      const vector = cube['vector']
      const padding = cube['padding']

      const viewProjectionMatrix = cube['viewProjectionMatrix']
      vector.setFromMatrixPosition(cube.matrixWorld)
      vector.applyMatrix4(viewProjectionMatrix)
      // let offsetX = (left / width * -1 * 100).toFixed(2) + '%'
      // let offsetY = (top / height * -1 * 100).toFixed(2) + '%'
      // let style = 'translate(' + offsetX + ',' + offsetY + ') translate(' + (vector.x * _widthHalf + _widthHalf) + 'px,' + (-vector.y * _heightHalf + _heightHalf) + 'px)'
      const style = 'translate(' + (vector.x * _widthHalf + _widthHalf - left + padding) + 'px,' + (-vector.y *
        _heightHalf + _heightHalf - top) + 'px)'

      element.style.WebkitTransform = style
      element.style.MozTransform = style
      element.style.oTransform = style
      element.style.transform = style
      element.style.top = 0
      element.style.left = 0

    }
  }

  manager.updateCss2DPosition = function(config) {
    const lookAtZone = config['lookAtZone']
    const lookAtExtra = config['lookAtExtra']
    const judgeLV = config['judgeLV']
    const lv = config['lv']
    const isAllVisible = config['isAllVisible']
    for (let i = 0; i < css2DList.length; i++) {
      const css = css2DList[i]
      let y = css.position.y;
      if (typeof css['height'] != 'undefined' && css['height'] != null) {
        y = css['height'];
      }
      let judgePosition = css['judgePosition'];

      if ((lv < judgeLV || isAllVisible == false) && judgePosition) {
        y = (analysis.jsonKeyIsExist(lookAtZone, [css['zoneId']]) || analysis.jsonKeyIsExist(lookAtExtra, [css[
          'zoneId']])) ? 5 : y
      }

      css.position.y = y
    }
  }

  manager.selectCss2D = function(config) {
    const data = config['data']
    for (let i = 0; i < css2DList.length; i++) {
      const cube = css2DList[i]
      if (cube.visible == false || analysis.jsonKeyIsExist(cube, ['div']) == false) {
        continue
      }
      const div = cube['div']
      div.style.display = data['divId'] == cube['divId'] ? 'block' : 'none'
    }
  }

  manager.cancelSelectCss2D = function() {
    for (let i = 0; i < css2DList.length; i++) {
      const cube = css2DList[i]
      if (cube.visible == false || analysis.jsonKeyIsExist(cube, ['div']) == false) {
        continue
      }
      const div = cube['div']
      div.style.display = 'block'
    }
  }

  manager.updateCSS2DList = function(config) {
    const dataList = config['dataList']
    const left = config['left']
    const top = config['top']
    let index = 0
    let selectDivId = ''
    if (analysis.jsonKeyIsExist(config, ['divId'])) {
      selectDivId = config['divId']
    }
    let padding = 0
    if (analysis.jsonKeyIsExist(config, ['padding'])) {
      padding = config['padding']
    }
    for (let i = 0; i < dataList.length; i++) {
      const data = dataList[i]
      const divId = data['divId']
      if (analysis.jsonKeyIsExist(data, ['divId']) == false) {
        continue
      }
      const div = document.getElementById(divId)
      if (typeof div == 'undefined' || div == null) {
        continue
      }
      const x = data['x']
      const y = data['y']
      const z = data['z']
      const height = data['height']
      let judgePosition = true;
      if (typeof data['judgePosition'] != 'undefined')  {
        judgePosition = data['judgePosition']
      }
      if (index >= css2DList.length) {
        const cube = create.createCube(x, y, z, '#ff0000', null, 0.1)
        css2DList.push(cube)
        cube['divId'] = divId
        cube['div'] = div
        cube['vector'] = new THREE.Vector3()
        cube['viewProjectionMatrix'] = new THREE.Matrix4()
        cube['left'] = left
        cube['top'] = top
        cube['height'] = height
        cube['zoneId'] = data['zoneId']
        cube['padding'] = padding
        cube['judgePosition'] = judgePosition;
        manager.add(cube)
      } else {
        const cube = css2DList[index]
        cube.visible = true
        cube['divId'] = divId
        cube['div'] = div
        cube['vector'] = new THREE.Vector3()
        cube['viewProjectionMatrix'] = new THREE.Matrix4()
        cube['left'] = left
        cube['top'] = top
        cube['height'] = height
        cube['zoneId'] = data['zoneId']
        cube['padding'] = padding
        cube['judgePosition'] = judgePosition;
        cube.position.set(x, y, z)
      }
      if (selectDivId == '') {
        div.style.display = 'block'
      } else {
        const style = data['divId'] == selectDivId ? 'block' : 'none'
        div.style.display = style
      }
      index++
    }
    for (let i = index; i < css2DList.length; i++) {
      const cube = css2DList[i]
      cube.visible = false
    }
  }

  manager.getZoneIndex = function(res) {
    if (res.result == false) {
      return res
    }
    const zoneId = res['zoneId']
    for (let i = 0; i < css2DList.length; i++) {
      const css = css2DList[i]
      if (analysis.jsonKeyIsExist(css, ['zoneId']) == false) {
        continue
      }
      if (css['zoneId'] == zoneId) {
        return {
          result: true,
          index: i
        }
      }
    }
    return res
  }

  return manager
}
