import * as THREE from 'three/build/three.min.js'
import {makeRoomConfig} from "../../MapConfig";
import {createAnalaysisInfo} from "../../analaysis/dataAnalaysisInfo";
import {createCanvasStyle} from "../canvasStyle";

export function createRoomObject() {
  let makeRoom = new Object();

  let roomConfig = makeRoomConfig();

  let style = createCanvasStyle()

  let plane = new THREE.Plane();

  let borderInfo = {leftUp: {x: -100, y: -100}, rightDown: {x: 100, y: 100}, height: 15}

  let analysis = createAnalaysisInfo() // 构造地图解析

  let groupMaterial = [
    new THREE.MeshBasicMaterial({
      needsUpdate: true,
      color: '#ffffff',
      side: THREE.DoubleSide,
      transparent: false,
      opacity: 1.0,
      blending: THREE.NormalBlending,
      blendEquation: THREE.NormalBlending,
      depthTest: true,
      polygonOffset: true,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    }),   //不受光照影响
    new THREE.MeshLambertMaterial({
      needsUpdate: true,
      color: '#ffffff',
      side: THREE.DoubleSide,
      transparent: false,
      opacity: 1.0,
      blending: THREE.NormalBlending,
      depthTest: true,
      polygonOffset: true,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    })     //受光照影响
  ]

  makeRoom.returnBorderInfo = function () {
    return borderInfo;
  }

  makeRoom.clearBorderInfo = function () {
    borderInfo = {leftUp: {x: -100, y: -100}, rightDown: {x: 100, y: 100}, height: 15}
  }

  function createPlane(config) {
    let roomInfo = config.roomInfo
    let heightSpace = config.heightSpace
    let mapid = config.mapid
    let y = heightSpace
    let points = roomInfo['areas']
    let color = roomInfo['color']
    let planeShape = new THREE.Shape()
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      let x = p[0]
      let z = p[2]
      if (i == 0) {
        planeShape.moveTo(x, z)
      } else {
        planeShape.lineTo(x, z)
      }
    }
    let polygonOffsetFactor = config.polygonOffsetFactor
    let polygonOffsetUnits = config.polygonOffsetUnits
    let geometry = new THREE.ShapeGeometry(planeShape)

    let materialName = 'basicMaterial';

    if (analysis.jsonKeyIsExist(config, ['materialName'])) {
      materialName = config['materialName'];
    }

    let opacity = 1;
    if (analysis.jsonKeyIsExist(config, ['opacity'])) {
      opacity = config['opacity'];
    }

    let material = null;

    if (materialName == 'basicMaterial') {
      material = new THREE.MeshBasicMaterial({
        color: '#ffffff',
        depthTest: true,
        transparent: false,
        opacity: opacity,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide,
        polygonOffset: true,
        polygonOffsetFactor: polygonOffsetFactor,
        polygonOffsetUnits: polygonOffsetUnits,
      })
    } else if (materialName == 'lambertMaterial') {
      material = new THREE.MeshLambertMaterial({
        color: '#ffffff',
        depthTest: true,
        transparent: false,
        opacity: opacity,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide,
        polygonOffset: true,
        polygonOffsetFactor: polygonOffsetFactor,
        polygonOffsetUnits: polygonOffsetUnits,
      })
    } else {
      material = new THREE.MeshBasicMaterial({
        color: '#ffffff',
        depthTest: true,
        transparent: false,
        opacity: opacity,
        blending: THREE.NormalBlending,
        side: THREE.DoubleSide,
        polygonOffset: true,
        polygonOffsetFactor: polygonOffsetFactor,
        polygonOffsetUnits: polygonOffsetUnits,
      })
    }


    geometry.computeBoundingBox();
    let mesh = new THREE.Mesh(geometry, material)
    mesh.translateY(y)
    mesh.rotateX(Math.PI / 2)//绕x轴旋转
    let id = roomInfo['areaid'] /* 房间id */
    if (typeof (id) == 'undefined') {
      id = mesh.uuid
      roomInfo['areaid'] = id
    } else {
      mesh.uuid = id
    }
    let boundingBox = geometry.boundingBox;
    updateMapBorder(boundingBox.min, boundingBox.max, mapid)
    let receiveShadow = false;
    if (analysis.jsonKeyIsExist(config, ['receiveShadow'])) {
      receiveShadow = config['receiveShadow']
    }
    mesh['dataInfo'] = config;
    mesh.receiveShadow = receiveShadow;
    return mesh;
  }

  makeRoom.createMapPlaneByPromise = function (config) {
    return new Promise(resolve => {
      resolve(createPlane(config))
    })
  }

  makeRoom.createMapPlane = function (config) {
    return createPlane(config);
  }

  function returnPointToLine(line, point) {
    let target = new THREE.Vector3(0, 0, 0);
    line.closestPointToPoint(point, false, target);
    let res = line.closestPointToPointParameter(target, false);
    return parseFloat(res.toFixed(2))
  }

  function updateLine(line, startP, endP) {
    line.start.x = startP.x;
    line.start.y = startP.y;
    line.start.z = startP.z;

    line.end.x = endP.x;
    line.end.y = endP.y;
    line.end.z = endP.z;
  }

  function returnFaceVertexUv(line1, line2, point, length, width, allDis, clockwise) {
    let x = returnPointToLine(line1, point) * width / length + allDis / length;
    let y = returnPointToLine(line2, point);
    return new THREE.Vector2(x, y)
  }

  function pointIsInArea(point, p1, p2, p3, line1, line2) {
    plane.setFromCoplanarPoints(p1, p2, p3);
    let dis = plane.distanceToPoint(point);
    if (dis.toFixed(1) == 0) {
      updateLine(line1, p1, p2);
      updateLine(line2, p1, p3);
      let res1 = returnPointToLine(line1, point)
      let res2 = returnPointToLine(line2, point)
      if (res1 >= 0 && res1 <= 1 && res2 >= 0 && res2 <= 1) {
        return true;
      }
      return false
    }
    return false
  }

  makeRoom.judgeAssignUVs = function (geometry, shapeList, length) {
    assignUVs(geometry, shapeList, length);
  }

  function assignUVs(geometry, shapeList, length, clockwise) {
    geometry.computeBoundingBox();
    let max = geometry.boundingBox.max,
      min = geometry.boundingBox.min;
    let faces = geometry.faces;
    geometry.faceVertexUvs[0] = [];
    let fontnormal = new THREE.Vector3(0, 0, 1);
    let backnormal = new THREE.Vector3(0, 0, -1);

    let line1 = new THREE.Line3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0));
    let line2 = new THREE.Line3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0));
    for (var i = 0; i < faces.length; i++) {
      let face = faces[i];
      let p1 = geometry.vertices[face.a]
      let p2 = geometry.vertices[face.b]
      let p3 = geometry.vertices[face.c]

      let v1 = new THREE.Vector3(parseFloat(p1.x.toFixed(2)), parseFloat(p1.y.toFixed(2)), parseFloat(p1.z.toFixed(2)))
      let v2 = new THREE.Vector3(parseFloat(p2.x.toFixed(2)), parseFloat(p2.y.toFixed(2)), parseFloat(p2.z.toFixed(2)))
      let v3 = new THREE.Vector3(parseFloat(p3.x.toFixed(2)), parseFloat(p3.y.toFixed(2)), parseFloat(p3.z.toFixed(2)))

      let facenormal = face.normal.normalize();
      let offset = new THREE.Vector2(0 - min.x, 0 - min.z);
      let range = new THREE.Vector2(max.x - min.x, max.z - min.z);
      let newV1 = new THREE.Vector2((v1.x + offset.x) / range.x, (v1.z + offset.y) / range.y);
      let newV2 = new THREE.Vector2((v2.x + offset.x) / range.x, (v2.z + offset.y) / range.y);
      let newV3 = new THREE.Vector2((v3.x + offset.x) / range.x, (v3.z + offset.y) / range.y);

      if (backnormal.equals(facenormal)) {
        // 房间顶部
        offset = new THREE.Vector2(0 - min.x, 0 - min.y);
        range = new THREE.Vector2(max.x - min.x, max.y - min.y);
        newV1 = new THREE.Vector2((v1.x + offset.x) / range.x, (v1.y + offset.y) / range.y);
        newV2 = new THREE.Vector2((v2.x + offset.x) / range.x, (v2.y + offset.y) / range.y);
        newV3 = new THREE.Vector2((v3.x + offset.x) / range.x, (v3.y + offset.y) / range.y);
      } else if (fontnormal.equals(facenormal)) {
        // 房间底部
        offset = new THREE.Vector2(0 - min.x, 0 - min.y);
        range = new THREE.Vector2(max.x - min.x, max.y - min.y);
        newV1 = new THREE.Vector2((v1.x + offset.x) / range.x, (v1.y + offset.y) / range.y);
        newV2 = new THREE.Vector2((v2.x + offset.x) / range.x, (v2.y + offset.y) / range.y);
        newV3 = new THREE.Vector2((v3.x + offset.x) / range.x, (v3.y + offset.y) / range.y);
      } else {
        // 侧边
        for (let j = 0; j < shapeList.length; j++) {
          let shape = shapeList[j];
          if (pointIsInArea(v1, shape.leftUp, shape.rightUp, shape.leftDown, line1, line2) && pointIsInArea(v2, shape.leftUp, shape.rightUp, shape.leftDown, line1, line2) && pointIsInArea(v3, shape.leftUp, shape.rightUp, shape.leftDown, line1, line2)) {
            updateLine(line1, shape.leftUp, shape.rightUp);
            updateLine(line2, shape.leftUp, shape.leftDown);
            newV1 = returnFaceVertexUv(line1, line2, v1, length, shape.width, shape.length, clockwise);
            newV2 = returnFaceVertexUv(line1, line2, v2, length, shape.width, shape.length, clockwise);
            newV3 = returnFaceVertexUv(line1, line2, v3, length, shape.width, shape.length, clockwise);
            break;
          }
        }
      }
      geometry.faceVertexUvs[0].push([
        newV1,
        newV2,
        newV3,
      ]);
    }
    geometry.uvsNeedUpdate = true;
  }


  function drawShape(config) {
    let roomInfo = config['roomInfo']
    let height = roomInfo['height']
    let points = roomInfo['areas']
    let clockwise = true;
    let planeShape = new THREE.Shape()
    let shapeList = [];
    let length = 0;

    let isChartlet = false;
    if (analysis.jsonKeyIsExist(roomInfo, ['chartLet']) || (analysis.jsonKeyIsExist(config, ['drawTop']) && config['drawTop'] == true)) {
      isChartlet = true;
    }

    let minYPoint = null;
    let minXPoint = null;
    let maxYPoint = null;
    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      let x = parseFloat(p[0].toFixed(1))
      let z = parseFloat(p[2].toFixed(1))
      if (i == 0) {
        planeShape.moveTo(x, z)
      } else {
        planeShape.lineTo(x, z)
      }
      if (isChartlet == false) {
        continue;
      }
      if (minYPoint == null) {
        minYPoint = {
          index: i,
          x: x,
          z: z,
        }
      } else {
        if (minYPoint.z >= z) {
          minYPoint = {
            index: i,
            x: x,
            z: z,
          }
        }
      }

      if (minXPoint == null) {
        minXPoint = {
          index: i,
          x: x,
          z: z,
        }
      } else {
        if (minXPoint.x >= x) {
          minXPoint = {
            index: i,
            x: x,
            z: z,
          }
        }
      }

      if (maxYPoint == null) {
        maxYPoint = {
          index: i,
          x: x,
          z: z,
        }
      } else {
        if (maxYPoint.z <= z) {
          maxYPoint = {
            index: i,
            x: x,
            z: z,
          }
        }
      }


      let next = i + 1;
      if (next >= points.length) {
        next = 0;
      }
      let nextP = points[next];
      let nx = parseFloat(nextP[0].toFixed(2))
      let nz = parseFloat(nextP[2].toFixed(2))
      let dis = Math.sqrt((nx - x) * (nx - x) + (nz - z) * (nz - z));
      if (dis == 0) {
        continue;
      }
      let info = {
        dis: dis,
        length: length,
        width: dis,
        height: height,
        leftUp: new THREE.Vector3(x, z, 0),
        rightUp: new THREE.Vector3(nx, nz, 0),
        leftDown: new THREE.Vector3(x, z, height)
      }
      shapeList.push(info);
      length = dis + length;
    }

    if (isChartlet) {
      let order = [minYPoint, minXPoint, maxYPoint].sort((a, b) => {
        return a.index - b.index
      })

      for (let i = 0; i < order.length - 2; i++) {
        let p1 = order[i];
        let p2 = order[i + 1];
        let p3 = order[i + 2];

        let p1x = p1.x;
        let p1z = p1.z;

        let p2x = p2.x;
        let p2z = p2.z;

        let p3x = p3.x;
        let p3z = p3.z;

        let m11 = p2x - p1x;
        let m12 = p2z - p1z;

        let m21 = p3x - p1x;
        let m22 = p3z - p1z;

        let v1 = new THREE.Vector3(m11, m12, 0).normalize();
        let v2 = new THREE.Vector3(m21, m22, 0).normalize();

        let m = new THREE.Matrix3();
        m.elements = [
          v1.x, v1.y, 0,
          v2.x, v2.y, 0,
          0, 0, 1
        ]
        let determinant = m.determinant();
        clockwise = parseFloat(determinant.toFixed(2)) >= 0 ? true : false;
      }
    }

    roomInfo['clockwise'] = clockwise;

    let cutShape = false;
    if (analysis.jsonKeyIsExist(config, ['cutShape'])) {
      cutShape = config['cutShape'];
    }

    if (cutShape == true) {
      let cutShapeList = [];
      if (analysis.jsonKeyIsExist(config, ['cutList'])) {
        cutShapeList = config['cutList']
      }
      for (let i = 0; i < cutShapeList.length; i++) {
        let dataInfo = cutShapeList[i];
        let areas = dataInfo['areas'];
        let shape = new THREE.Shape();
        for (let j = 0; j < areas.length; j++) {
          let p = areas[j]
          let x = parseFloat(p[0].toFixed(1))
          let z = parseFloat(p[2].toFixed(1))
          if (j == 0) {
            shape.moveTo(x, z)
          } else {
            shape.lineTo(x, z)
          }
        }
        planeShape.holes.push(shape);
      }
    }


    return {
      planeShape: planeShape,
      clockwise: clockwise,
      shapeList: shapeList,
      length: length,
    };
  }

  function createRoomGeo(config, shapeRes) {
    let roomInfo = config.roomInfo;
    let height = roomInfo['height']
    let clockwise = shapeRes['clockwise'];
    let planeShape = shapeRes['planeShape'];
    let length = shapeRes['length'];
    let shapeList = shapeRes['shapeList'];
    let extrudeSettings = {
      steps: 1,
      depth: height,
      bevelEnabled: true,
      bevelThickness: 0,
      bevelSize: 0,
      bevelOffset: 0,
      bevelSegments: 1
    }
    let isChartlet = false;
    if (analysis.jsonKeyIsExist(roomInfo, ['chartLet']) || (analysis.jsonKeyIsExist(config, ['drawTop']) && config['drawTop'] == true)) {
      isChartlet = true;
    }

    if (analysis.jsonKeyIsExist(config, ['type']) && config['type'] == 'background') {
      isChartlet = true;
    }

    let geometry = new THREE.ExtrudeGeometry(planeShape, extrudeSettings)


    if (isChartlet) {
      assignUVs(geometry, shapeList, length, clockwise);
    }
    geometry.computeBoundingBox();

    let boundingBox = geometry.boundingBox;
    let max = boundingBox.max;
    let min = boundingBox.min;
    updateMapBorder(min, max, config.mapid)
    let leftUp = {
      x: min.x,
      y: 0,
      z: min.y
    }
    let rightDown = {
      x: max.x,
      y: 0,
      z: max.y
    }
    roomInfo['leftUp'] = leftUp
    roomInfo['rightDown'] = rightDown

    return {
      geometry: geometry,
      leftUp: leftUp,
      rightDown: rightDown
    };
  }

  function createWallMeterial(config, clockwise, leftUp, rightDown) {
    let roomInfo = config.roomInfo;
    let color = roomInfo['color']
    let opacity = 1
    if (analysis.jsonKeyIsExist(config, ['opacity'])) {
      opacity = config['opacity']
    }
    if (color == '#FFFFFF' || color == '#ffffff' || color == '#fff' || color == '#FFF') {
      opacity = 1
    }

    let face = {
      id: '',
      topFace: '',
      frontFace: '',
    }


    if (analysis.jsonKeyIsExist(roomInfo, ['face'])) {
      face = roomInfo['face'];
    }

    let type = config['type'];
    if (type == 'background') {
      let image = roomInfo['image'];
      if (image != '') {
        face.topFace = image;
        color = '#ffffff'
      }
    }


    let polygonOffsetFactor = config.polygonOffsetFactor + (color == '#FFFFFF' ? -0.1 : 0)
    let polygonOffsetUnits = config.polygonOffsetUnits + (color == '#FFFFFF' ? -1 : 0)

    let topM = groupMaterial[0].clone();
    let forntM = groupMaterial[1].clone();

    let drawTop = false;
    if (analysis.jsonKeyIsExist(config, ['drawTop'])) {
      drawTop = config['drawTop'];
    }

    if (analysis.jsonKeyIsExist(config, ['transparent'])) {
      topM.transparent = config['transparent'];
      forntM.transparent = config['transparent'];
    }

    if (analysis.jsonKeyIsExist(config, ['depthTest'])) {
      topM.depthTest = config['depthTest'];
      forntM.depthTest = config['depthTest'];
    }

    if (analysis.jsonKeyIsExist(config, ['polygonOffset'])) {
      topM.polygonOffset = config['polygonOffset'];
      forntM.polygonOffset = config['polygonOffset'];
    }

    if (analysis.jsonKeyIsExist(config, ['depthFunc'])) {
      // topM.depthFunc = config['depthFunc'];
      // forntM.depthFunc = config['depthFunc'];
    }

    if (drawTop) {
      topM.color.set('#ffffff')
      forntM.color.set('#ffffff')
    } else {
      topM.color.set(color)
      forntM.color.set(color)
    }

    topM.polygonOffsetFactor = polygonOffsetFactor;
    topM.polygonOffsetUnits = polygonOffsetUnits;
    topM.opacity = opacity;

    forntM.polygonOffsetFactor = polygonOffsetFactor
    forntM.polygonOffsetUnits = polygonOffsetUnits
    forntM.opacity = opacity;

    if (drawTop == true) {
      let drawList = [];
      if (analysis.jsonKeyIsExist(config, ['drawList'])) {
        drawList = config['drawList'];
      }
      topM.transparent = true;
      forntM.transparent = true;
      topM.opacity = 0;
      forntM.opacity = 0;
      style.drawZeroTopImage({
        drawList: drawList,
        height: rightDown.z - leftUp.z,
        width: rightDown.x - leftUp.x,
        color: color,
        leftUp: leftUp,
      }).then((img) => {
        updateTexttrue(topM, img.src, clockwise);
        topM.opacity = 1;
        forntM.opacity = 1;
        setTimeout(() => {
          topM.transparent = false;
          forntM.transparent = false;
        }, 500)
      })
    } else {
      if (typeof face.frontFace != 'undefined' && face.frontFace != null && face.frontFace != '') {
        updateTexttrue(forntM, face.frontFace, clockwise);
      }
      if (typeof face.topFace != 'undefined' && face.topFace != null && face.topFace != '') {
        updateTexttrue(topM, face.topFace, clockwise);
      }
    }

    return {
      meterial: [
        topM,   //不受光照影响
        forntM     //受光照影响
      ],
      face: face
    }
  }


  function createWallMesh(config, geometry, wallMaterial) {
    let roomInfo = config['roomInfo'];
    let height = roomInfo['height'];
    let heightSpace = config['heightSpace'];
    let mesh = new THREE.Mesh(geometry, wallMaterial)
    mesh.rotateX(Math.PI / 2)
    let moveY = config.moveY
    if (moveY) {
      if (analysis.jsonKeyIsExist(roomInfo, ['moveY'])) {
        let my = roomInfo['moveY'];
        mesh.position.y = height + heightSpace + parseFloat(my);
      } else {
        mesh.position.y = height + heightSpace
      }
    } else {
      mesh.position.y = heightSpace
    }

    let id = roomInfo['areaid'] /* 房间id */
    if (typeof (id) == 'undefined' || id == null || id == '') {
      id = mesh.uuid
      roomInfo['areaid'] = id
    } else {
      mesh.uuid = id
    }
    let castShadow = false;
    if (analysis.jsonKeyIsExist(config, ['castShadow'])) {
      castShadow = config['castShadow'];
    }

    mesh.castShadow = castShadow;
    mesh.receiveShadow = castShadow;

    return mesh;
  }

  function updateMeshInfo(face, mesh, roomInfo, clockwise, shapeList) {
    face['id'] = mesh['id'];
    mesh['dataInfo'] = roomInfo
    mesh['clockwise'] = clockwise;
    mesh['shapeList'] = shapeList;
    mesh['face'] = face;
  }

  function createWallInfoByPromise(config) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({shapeRes: drawShape(config)})
      }, roomConfig.drawShapeTime)
      // resolve({shapeRes: drawShape(config)})
    }).then(res => {
      let shapeRes = res['shapeRes'];
      return new Promise(resolve => {
        // setTimeout(() => {
        //   resolve({shapeRes: shapeRes, geometryRes: createRoomGeo(config, shapeRes)})
        // }, roomConfig.createRoomGeoTime)
        resolve({shapeRes: shapeRes, geometryRes: createRoomGeo(config, shapeRes)})
      })
    }).then((res) => {
      let shapeRes = res['shapeRes'];
      let geometryRes = res['geometryRes'];
      let meterialRes = createWallMeterial(config, shapeRes['clockwise'], geometryRes['leftUp'], geometryRes['rightDown']);
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({shapeRes: shapeRes, geometryRes: geometryRes, meterialRes: meterialRes})
        }, roomConfig.createWallMeterialTime)
      })
    }).then((res) => {
      let type = config['type']
      let shapeRes = res['shapeRes'];
      let geometryRes = res['geometryRes'];
      let meterialRes = res['meterialRes'];
      return new Promise(resolve => {
        let mesh = createWallMesh(config, geometryRes['geometry'], meterialRes['meterial']);
        updateMeshInfo(meterialRes['face'], mesh, config['roomInfo'], shapeRes['clockwise'], shapeRes['shapeList']);
        setTimeout(() => {
          resolve({
            result: true,
            mesh: mesh,
            type: type,
          })
        }, roomConfig.createWallMeshTime)
      })
    })
  }

  function createWallInfo(config) {
    let roomInfo = config.roomInfo
    if (analysis.jsonKeyIsExist(roomInfo, ['hidden'])) {
      return {
        result: false
      }
    }

    let res = drawShape(config);
    let clockwise = res['clockwise'];
    let shapeList = res['shapeList'];

    let geometryRes = createRoomGeo(config, res);
    let geometry = geometryRes['geometry'];
    let leftUp = geometryRes['leftUp'];
    let rightDown = geometryRes['rightDown'];

    let meterialRes = createWallMeterial(config, clockwise, leftUp, rightDown)
    let wallMaterial = meterialRes['meterial'];
    let face = meterialRes['face'];

    let mesh = createWallMesh(config, geometry, wallMaterial);
    updateMeshInfo(face, mesh, roomInfo, clockwise, shapeList);

    return {
      result: true,
      mesh: mesh
    }
  }

  makeRoom.createWallByPromise = function (config) {
    let roomInfo = config.roomInfo
    if (analysis.jsonKeyIsExist(roomInfo, ['hidden'])) {
      return new Promise(resolve => {
        resolve({
          result: false
        })
      })
    }

    return createWallInfoByPromise(config)
  }

  makeRoom.createWall = function (config) {
    return createWallInfo(config)
  }

  function updateMapBorder(leftUp, rightDown, height) {
    let oldLeftUp = borderInfo.leftUp
    let oldRightDown = borderInfo.rightDown
    let oldHeight = borderInfo.height
    if (oldLeftUp.x > leftUp.x) {
      oldLeftUp.x = leftUp.x
    }
    if (oldLeftUp.y > leftUp.y) {
      oldLeftUp.y = leftUp.y
    }
    if (oldRightDown.x < rightDown.x) {
      oldRightDown.x = rightDown.x
    }
    if (oldRightDown.y < rightDown.y) {
      oldRightDown.y = rightDown.y
    }
    if (oldHeight < height) {
      oldHeight = height
    }
    borderInfo = {
      leftUp: oldLeftUp,
      rightDown: oldRightDown,
      height: parseFloat(oldHeight),
    }
  }

  makeRoom.updateRoomTexttrue = function (material, imageUrl, clockwise) {
    updateTexttrue(material, imageUrl, clockwise);
  }

  function updateTexttrue(material, imageUrl, clockwise) {
    function getBase64Image(img) {
      let canvas = document.createElement("canvas");
      let w = img.width;
      let h = img.height;

      let width = 1;
      let height = 1;

      while (width < w) {
        if (width * 2 > w) {
          break;
        }
        width = width * 2;
      }

      while (height < h) {
        if (height * 2 > h) {
          break;
        }
        height = height * 2
      }

      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      let dataURL = canvas.toDataURL("image/png");  // 可选其他值 image/jpeg
      return dataURL;
    }

    function main(src) {
      return new Promise((resolve => {
        let image = new Image();
        image.setAttribute("crossOrigin", 'Anonymous')
        image.src = src
        image.onload = function () {
          let base64 = getBase64Image(image);
          resolve(base64);
        }
        image.onerror = function (res) {

        }
      }))
    }

    if (typeof imageUrl == 'undefined' || imageUrl == null || imageUrl == '') {
      return;
    }
    let oldImageUrl = material['image'];
    if (typeof oldImageUrl == 'undefined' || oldImageUrl == null) {
      oldImageUrl = ''
    }
    if (oldImageUrl == imageUrl) {
      return;
    }
    let type = material.type;
    main(imageUrl).then(res => {
      material.needsUpdate = true;
      let textureLoader = new THREE.TextureLoader();
      let texture = textureLoader.load(res);
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.y = -1;
      if (clockwise == true && type == 'MeshLambertMaterial') {
        texture.wrapS = THREE.RepeatWrapping;
        texture.repeat.x = -1;
      }
      material.map = texture;
      material['image'] = imageUrl;
    })
  }

  return makeRoom;
}
