import {
  createAnalaysisInfo
} from './dataAnalaysisInfo.js'
import workerize from 'workerize/dist/workerize.m.js'
import * as THREE from 'three/build/three.min.js'

export function createMatrixInfo() {
  const matrixInfo = new Object()

  const analysis = createAnalaysisInfo() // 构造地图解析

  const cubeY = 0

  const infinite = 65535

  let findMapInfoBlock = null

  matrixInfo.findMapInfo = function(callBack) {
    findMapInfoBlock = callBack
  }

  const leadPointType = {
    start: 5,
    end: 4,
    louti: 1,
    dianti: 2,
    futi: 3,
    normal: 0,
    po: 99,
    gps: 100
  }

  matrixInfo.leadPointType = function() {
    return leadPointType
  }

  // p1指向p2
  function getKstring(p1, p2) {
    const yCount = p2.y - p1.y
    const xCount = p2.x - p1.x
    let k = ''
    if (xCount == 0) {
      k = (yCount >= 0 ? '' : '-') + 'identified'
    } else if (yCount == 0) {
      k = (xCount >= 0 ? '' : '-') + '0.0'
    } else {
      k = (Math.abs(yCount / xCount) * (yCount >= 0 ? 1 : -1)).toFixed(2)
    }
    return k
  }

  // 构建两个连接点的数据
  function createTwoPointData(p1, p2, dis) {
    const p1Id = p1['id']
    const p2Id = p2['id']

    let id = 'line' + p1Id + '-' + p2Id
    if (p1Id > p2Id) {

    } else {
      id = 'line' + p2Id + '-' + p1Id
    }

    const p_Info = {
      startP: {
        x: p1.x,
        y: cubeY,
        z: p1.y
      },
      endP: {
        x: p2.x,
        y: cubeY,
        z: p2.y
      },
      id: id,
      start: p1Id,
      end: p2Id,
      dis: dis
    }

    if (analysis.jsonKeyIsExist(p1, ['linkPoint']) == false) {
      p1['linkPoint'] = {}
    }
    p1['linkPoint'][p2Id] = p_Info
    let p1K_info = {}
    if (analysis.jsonKeyIsExist(p1, ['k']) == false) {
      p1['k'] = p1K_info
    } else {
      p1K_info = p1['k']
    }
    const k1 = getKstring(p1, p2)
    if (analysis.jsonKeyIsExist(p1K_info, [k1])) {
      const arr = p1K_info[k1]
      let isExist = false
      for (let i = 0; i < arr.length; i++) {
        const oldInfo = arr[i]
        if (oldInfo.id == p2Id) {
          isExist = true
          break
        }
      }
      if (isExist == false) {
        arr.push({
          id: p2Id,
          dis: dis,
          x: p2.x,
          y: p2.y
        })
        arr.sort(function(a, b) {
          return a.dis - b.dis
        })
      }
    } else {
      p1K_info[k1] = []
      p1K_info[k1].push({
        id: p2Id,
        dis: dis,
        x: p2.x,
        y: p2.y
      })
    }

    let p2K_info = {}
    if (analysis.jsonKeyIsExist(p2, ['k']) == false) {
      p2['k'] = p2K_info
    } else {
      p2K_info = p2['k']
    }
    const k2 = getKstring(p2, p1)
    if (analysis.jsonKeyIsExist(p2K_info, [k2])) {
      const arr = p2K_info[k2]
      let isExist = false
      for (let i = 0; i < arr.length; i++) {
        const oldInfo = arr[i]
        if (oldInfo.id == p1Id) {
          isExist = true
          break
        }
      }
      if (isExist == false) {
        arr.push({
          id: p1Id,
          dis: dis,
          x: p1.x,
          y: p1.y
        })
        arr.sort(function(a, b) {
          return a.dis - b.dis
        })
      }
    } else {
      p2K_info[k2] = []
      p2K_info[k2].push({
        id: p1Id,
        dis: dis,
        x: p1.x,
        y: p1.y
      })
    }

    if (analysis.jsonKeyIsExist(p2, ['linkPoint']) == false) {
      p2['linkPoint'] = {}
    }
    p2['linkPoint'][p1Id] = p_Info
    return {
      id: id,
      data: p_Info
    }
  }

  matrixInfo.returnTypeStyle = function(type) {
    let str = '-null-'
    switch (type) {
      case leadPointType.louti:
        str = '楼梯'
        break
      case leadPointType.dianti:
        str = '电梯'
        break
      case leadPointType.futi:
        str = '扶梯'
        break
      default:
        break
    }
    return str
  }

  function findWayByFloors(leadData, startMapid, endMapid, leadType, mapidList) {
    const leadInfo = getLeadTypeWay(leadData, mapidList, startMapid, endMapid, true, leadType)
    return leadInfo
  }

  matrixInfo.startFindWay = function(leadData, startMapid, endMapid, leadType, mapidList) {
    return findWayByFloors(leadData, startMapid, endMapid, leadType, mapidList)
  }

  // 将起点或者终点传入 创建其连接
  matrixInfo.findPointLinkDueToInfo = function(point, mapid, id, dataInfo, group, mapJson, zeroRoom, matrix, zoneInfo) {
    const matrixIndex = (id == 'startP' ? matrix.length - 2 : matrix.length - 1)
    const info = {
      x: point.x,
      y: point.z,
      title: '',
      mapid: mapid,
      id: id,
      index: matrixIndex,
      type: (id == 'startP' ? leadPointType.start : leadPointType.end),
      room: point.room,
      matrix: matrixIndex,
      inMapId: point.room.areaid
    }
    matrix[matrixIndex].mapid = mapid
    const x = group.position.x
    const z = group.position.z
    const startX = point.x + x
    const startZ = point.z + z
    const space = 20

    for (const key in dataInfo) {
      const link = dataInfo[key]
      if (analysis.jsonKeyIsExist(link, ['id']) == false) {
        continue
      }
      const linkid = link['id']
      if (id == linkid) {
        continue
      }
      let px = link.x
      let py = link.y
      if (px == null || py == null || linkid == null) {
        continue
      }

      px = px + x
      py = py + z

      const xCount = px - startX
      const yCount = py - startZ
      const dis = Math.sqrt(xCount * xCount + yCount * yCount)
      if (dis > space * 10) {
        continue
      }
      let isCorss = false
      for (let j = 0; j < mapJson.length; j++) {
        const room = mapJson[j]
        isCorss = analysis.judgeIsCorssWall([{
          x: px - x,
          z: py - z
        }, {
          x: startX - x,
          z: startZ - z
        }], room)
        if (isCorss == true) {
          break
        }
      }
      if (isCorss == true) {
        continue
      }

      createTwoPointData(info, link, dis)
      const linkMatrix = link['matrix']
      // 修改matrix表
      if (matrix.length > linkMatrix && matrix.length > matrixIndex && linkMatrix != matrixIndex) {
        matrix[linkMatrix].arr[matrixIndex] = dis
        matrix[matrixIndex].arr[linkMatrix] = dis
      }
    }
    dataInfo[id] = info
    return info
  }

  // 链接同一层楼所有能链接的点
  matrixInfo.linkTheSameMap = function(y, mapJson, info, zeroRoom, link) {
    const list = []
    for (const key in info) {
      list.push(info[key])
    }

    if (link) {
      for (let i = 0; i < list.length; i++) {
        const p1 = list[i]
        for (let j = i + 1; j < list.length; j++) {
          const p2 = list[j]
          if ((p1.x.toFixed(2) == p2.x.toFixed(2) || p1.y.toFixed(2) == p2.y.toFixed(2))) {

          } else {
            continue
          }
          const origen = {
            x: p1.x,
            y: y,
            z: p1.y
          }
          const p = {
            x: p2.x,
            y: y,
            z: p2.y
          }
          const xCount = origen.x - p.x
          const yCount = origen.z - p.z
          const dis = Math.sqrt(xCount * xCount + yCount * yCount)
          let shouldContiune = true
          for (let k = 1; k < mapJson.length; k++) {
            const room = mapJson[k]
            const result = analysis.judgeIsCorssWall([{
              x: p1.x,
              z: p1.y
            }, {
              x: p2.x,
              z: p2.y
            }], room)
            if (result == true) {
              // 穿墙了
              shouldContiune = false
              break
            }
          }
          if (shouldContiune == false) {
            continue
          }
          createTwoPointData(p1, p2, dis)
        }
      }
    }

    for (let i = 0; i < list.length; i++) {
      const linkP1 = list[i]
      if (linkP1.inMapId == zeroRoom.areaid || analysis.jsonKeyIsExist(linkP1, ['linkPoint'])) {
        continue
      }
      let mindis = 65535
      let linkIndex = -1
      for (let j = 0; j < list.length; j++) {
        if (j == i) {
          continue
        }
        const linkP2 = list[j]
        if (linkP2.inMapId == zeroRoom.areaid) {
          const origen = {
            x: linkP1.x,
            y: y,
            z: linkP1.y
          }
          const p = {
            x: linkP2.x,
            y: y,
            z: linkP2.y
          }
          const xCount = origen.x - p.x
          const yCount = origen.z - p.z
          const dis = Math.sqrt(xCount * xCount + yCount * yCount)
          if (dis < mindis && dis <= 5 * 10) {
            mindis = dis
            linkIndex = j
          }
        }
      }
      if (linkIndex >= 0) {
        const linkP3 = list[linkIndex]
        createTwoPointData(linkP1, linkP3, mindis)
      }
    }
    // 连接完后检查是否存在相同斜率的线 存在则剔除
    // for (let i = 0; i < list.length; i++) {
    //   let point = list[i];
    //   if (analysis.jsonKeyIsExist(point, ['linkPoint', 'k']) == false) {
    //     continue;
    //   }
    //   let linkPoint = point['linkPoint'];
    //   let kInfo = point['k'];
    //   for (let key in kInfo) {
    //     let arr = kInfo[key];
    //     for (let j = arr.length - 1; j > 0; j--) {
    //       let deleteP = arr[j];
    //       if (analysis.jsonKeyIsExist(linkPoint, [deleteP.id])) {
    //         delete linkPoint[deleteP.id];
    //       }
    //     }
    //   }
    //   delete point['k'];
    // }
  }

  // 删除两个点
  matrixInfo.deleteTwoPointInfo = function(json1, json2, start, end) {
    if (analysis.jsonKeyIsExist(json1, ['linkPoint']) && analysis.jsonKeyIsExist(json1['linkPoint'], [json2.id])) {
      const linkInfo = json1['linkPoint'][json2.id]
      if (analysis.jsonKeyIsExist(json1, ['linkPoint']) && analysis.jsonKeyIsExist(json1['linkPoint'], [end])) {
        delete json1['linkPoint'][end]
      }
      if (analysis.jsonKeyIsExist(json2, ['linkPoint']) && analysis.jsonKeyIsExist(json2['linkPoint'], [start])) {
        delete json2['linkPoint'][start]
      }
      return {
        result: true,
        id: linkInfo.id
      }
    } else {
      return {
        result: false
      }
    }
  }

  // 生成matrix矩阵
  matrixInfo.getMatrix = function(info) {
    const list = []
    const newPo = {}
    const matrix = []
    for (const mapid in info) {
      const leadInfo = info[mapid]
      for (const leadKey in leadInfo) {
        const data = leadInfo[leadKey] // 获取到路径点的信息
        const id = data['id']
        if (id == 'startP' || id == 'endP') {
          continue
        }
        data['matrix'] = list.length
        list.push(data)
        if (analysis.jsonKeyIsExist(data, ['link']) && data['link'] == 'P') {
          newPo[id] = data
        }
      }
    }
    list.push({
      id: 'startP',
      mapid: ''
    })
    list.push({
      id: 'endP',
      mapid: ''
    })
    // 构造矩阵
    for (let i = 0; i < list.length; i++) {
      const data = list[i]
      const distances = []
      for (let j = 0; j < list.length; j++) {
        if (j == i) {
          distances.push(0)
        } else {
          const link = list[j]
          const linkId = link.id
          let isExist = false
          if (analysis.jsonKeyIsExist(data, ['linkPoint']) && analysis.jsonKeyIsExist(data['linkPoint'], [linkId])) {
            // 同一楼层连接点
            const result = data['linkPoint'][linkId]
            distances.push(result.dis)
            isExist = true
          }
          if (analysis.jsonKeyIsExist(data, ['floors']) && analysis.jsonKeyIsExist(data['floors'], [link.mapid])) {
            // 楼层之间连接点
            const result = data['floors'][link.mapid]
            if (result.id == linkId) {
              distances.push(result.dis)
              isExist = true
            }
          }
          if (isExist == false) {
            distances.push(infinite)
          }
        }
      }
      matrix.push({
        arr: distances,
        id: data.id,
        mapid: data.mapid
      })
    }
    return matrix
  }

  matrixInfo.resetMatrix = function(cashInfo, info, linkInfo, matrix) {
    for (const key in linkInfo) {
      if (cashInfo.indexOf(key) < 0) {
        const links = linkInfo[key]
        for (const linkMapid in links) {
          for (const linkKey in links[linkMapid]) {
            const data = info[linkMapid][linkKey]
            const dataMatrix = data.matrix
            for (const floorKey in data.floors) {
              const floorsMapid = data.floors[floorKey].mapid
              const floorsid = data.floors[floorKey].id
              const floorsmatrix = info[floorsMapid][floorsid].matrix
              matrix[dataMatrix].arr[floorsmatrix] = infinite
              matrix[floorsmatrix].arr[dataMatrix] = infinite
            }
          }
        }
      }
    }
    return matrix
  }

  // 根据所选的导航类型给出路径 如果该类型寻找不到路径 则改为混合路径
  function getLeadTypeWay(leadData, mapidList, startMapId, endMapid, isType, linkType) {
    const judges = []

    function editorMatrix(leadData, data) {
      if (analysis.jsonKeyIsExist(data, ['floors', 'matrix']) == false) {
        return
      }
      const dataMatrix = data.matrix
      const dataInfo = leadData.info
      const matrix = leadData.matrix
      for (const mapid in data.floors) {
        const mapData = dataInfo[mapid]
        const link = data.floors[mapid]
        const point = mapData[link.id]
        const index = point.matrix
        const dis = matrix[dataMatrix].arr[index]
        const old = {
          index1: dataMatrix,
          index2: index,
          dis: dis
        }
        judges.push(old)
        matrix[dataMatrix].arr[index] = infinite
      }
    }

    if (isType) {
      let linkStr = ''
      if (linkType == leadPointType.futi) {
        linkStr = 'F'
      } else if (linkType == leadPointType.dianti) {
        linkStr = 'D'
      } else {
        linkStr = 'L'
      }
      const floos = leadData.floor
      const dataInfo = leadData.info
      for (const key in floos) {
        if (key.search(linkStr) < 0) {
          // 对矩阵进行修改 不是该类型的则将其修改为不能通过
          const mapFloor = floos[key]
          for (const mapid in mapFloor) {
            const floorInfo = mapFloor[mapid]
            const mapDataInfo = dataInfo[mapid]
            for (const floorKey in floorInfo) {
              editorMatrix(leadData, mapDataInfo[floorKey])
            }
          }
        }
      }
    }

    const leadInfo = matrixInfo.makeMatrix('startP', 'endP', leadData)
    const matrix = leadData.matrix
    for (let i = 0; i < judges.length; i++) {
      const data = judges[i]
      matrix[data.index1].arr[data.index2] = data.dis
    }
    return leadInfo
  }

  // 连接两个点
  matrixInfo.linkTwoPoint = function(json1, json2) {
    const x = json2.x - json1.x
    const y = json2.y - json1.y
    const dis = parseFloat(Math.sqrt(x * x + y * y).toFixed(2))
    const result = createTwoPointData(json1, json2, dis)
    return result
  }

  // 根据数据计算路径
  matrixInfo.makeMatrix = function(start, end, leadData) {
    const dataInfo = leadData.info
    const poInfo = leadData.po
    const matrix = leadData.matrix
    const zoneInfo = leadData.zoneInfo

    // 定义图结构
    function MGraph() {
      const G = {
        vexs: [], // 顶点表
        arc: [], // 邻接矩阵，可看作边表
        numVertexes: null
      }
      return G
    }

    const G = new MGraph() // 创建图使用

    // 创建图
    function createMGraph(matrix) {
      G.numVertexes = matrix.length // 设置顶点数
      // 邻接矩阵初始化
      G.arc = matrix
    }

    createMGraph(matrix)
    return Dijkstra(start, end, dataInfo, G, poInfo, zoneInfo)
  }

  // 开始计算路径
  function Dijkstra(startid, endid, dataInfo, G, po, zoneInfo) {
    let data = null
    for (const mapid in dataInfo) {
      const info = dataInfo[mapid]
      if (analysis.jsonKeyIsExist(info, [startid])) {
        data = info[startid]
        break
      }
    }
    if (data == null) {
      console.log('没有该起点')
      return {
        isLink: false,
        indexs: [],
        info: []
      }
    }

    let endData = null
    for (const mapid in dataInfo) {
      const info = dataInfo[mapid]
      if (analysis.jsonKeyIsExist(info, [endid])) {
        endData = info[endid]
        break
      }
    }

    if (endData == null) {
      console.log('没有该起点')
      return {
        isLink: false,
        indexs: [],
        info: []
      }
    }

    const start = data['matrix']
    const end = endData['matrix']
    const Pathmatirx = [] // 用于存储最短路径下标的数组，下标为各个顶点，值为下标顶点的前驱顶点
    const ShortPathTable = [] // 用于存储到各点最短路径的权值和
    let k, min
    const final = []

    for (let v = 0; v < G.numVertexes; v++) {
      final[v] = 0
      ShortPathTable[v] = G.arc[start].arr[v]
      Pathmatirx[v] = infinite
    }
    ShortPathTable[start] = 0
    final[start] = 1
    Pathmatirx[start] = -1

    for (let v = 0; v < G.numVertexes; v++) { // 初始化数据
      min = infinite
      for (let w = 0; w < G.numVertexes; w++) {
        if (!final[w] && G.arc[start].arr[w] < min) {
          Pathmatirx[w] = start
        }
        // 寻找离V0最近的顶点
        if (!final[w] && ShortPathTable[w] < min) {
          k = w
          min = ShortPathTable[w] // w 顶点离V0顶点更近
        }
      }
      if (typeof (k) == 'undefined') {
        console.log('没有链接点')
        return {
          isLink: false,
          indexs: [],
          info: []
        }
      }
      final[k] = 1 // 将目前找到的最近的顶点置位1
      for (let w = 0; w < G.numVertexes; w++) { // 修正当前最短路径及距离
        const dis_KW = G.arc[k].arr[w]
        const dis = ShortPathTable[w]
        if (!final[w] && (min + dis_KW < dis)) { // 说明找到了更短的路径，修改Pathmatirx[w]和ShortPathTable[w]
          const newDis = min + G.arc[k].arr[w]
          ShortPathTable[w] = newDis
          Pathmatirx[w] = k
        }
      }
    }

    function returnPointType(info) {
      let type = leadPointType.normal
      if (analysis.jsonKeyIsExist(info, ['type'])) {
        type = info['type']
      }
      return type
    }

    function returnPoint(info) {
      const type = returnPointType(info)
      const mapid = info['mapid']
      const id = info['id']
      let height = 0
      if (analysis.jsonKeyIsExist(info, ['pHeight'])) {
        height = info['pHeight']
      }
      let imgs = []
      if (analysis.jsonKeyIsExist(info, ['imgs']) && info['imgs'].length > 0) {
        imgs = info['imgs']
      }
      const p = {
        x: parseFloat(info.x.toFixed(2)),
        y: 2,
        z: parseFloat(info.y.toFixed(2)),
        mapid: mapid,
        type: type,
        id: id,
        height: height,
        url: '',
        room: analysis.jsonKeyIsExist(info, ['room']) ? info.room : {
          areas: [],
          areaid: '',
          color: '',
          height: 0
        },
        inMapId: info.inMapId
      }
      if (imgs.length > 0) {
        p['imgs'] = imgs
      }
      return p
    }

    // 对终点进行校正
    function judgeEndPoint(endPoint, pointTwo, pointThree, indexs) {
      if (endPoint.x == pointTwo.x || endPoint.z == pointTwo.z) {
        return
      }
      const res = findMapInfoBlock(endPoint.mapid)
      const mapInfo = res.mapJson
      if (typeof mapInfo == 'undefined' || mapInfo == null || mapInfo.length == 0 || typeof endPoint.room ==
        'undefined' || endPoint.room == null) {
        return
      }
      const zeroInfo = mapInfo[0]
      const roomId = endPoint.room['areaid']
      if (zeroInfo['areaid'] != roomId) {
        return
      }
      const t = analysis.closestPointToPoint(pointTwo, pointThree, endPoint)
      const p0 = {
        x: t.x,
        z: t.z,
        newPoint: false
      }
      const p1 = {
        x: pointTwo.x,
        z: pointThree.z,
        newPoint: true,
        beforeStart: false,
        newx: pointTwo.x,
        newz: endPoint.z
      }
      const p2 = {
        x: pointThree.x,
        z: pointTwo.z,
        newPoint: true,
        beforeStart: false,
        newx: endPoint.x,
        newz: pointTwo.z
      }
      const p3 = {
        x: (endPoint.x + pointTwo.x) / 2.0,
        z: pointTwo.z,
        newPoint: true,
        beforeStart: true,
        newx: pointTwo.x,
        newz: pointTwo.z
      }
      const p4 = {
        x: pointTwo.x,
        z: (endPoint.z + pointTwo.z) / 2.0,
        newPoint: true,
        beforeStart: true,
        newx: pointTwo.x,
        newz: pointTwo.z
      }
      const p5 = {
        x: pointTwo.x,
        z: endPoint.z,
        newPoint: false
      }
      const p6 = {
        x: endPoint.x,
        z: pointTwo.z,
        newPoint: false
      }

      // 校正的选择
      const judges = [p0, p1, p2, p3, p4, p5, p6]
      let selectIndex = -1
      for (let i = 0; i < judges.length; i++) {
        const judge = judges[i]
        const lineList = []
        if (judge.newPoint == false) {
          lineList.push([endPoint, judge])
          lineList.push([judge, pointThree])
        } else {
          if (judge.beforeStart) {
            lineList.push([endPoint, judge])
            lineList.push([judge, {
              x: judge.newx,
              z: judge.newz
            }])
            lineList.push([{
              x: judge.newx,
              z: judge.newz
            }, pointThree])
          } else {
            lineList.push([endPoint, {
              x: judge.newx,
              z: judge.newz
            }])
            lineList.push([{
              x: judge.newx,
              z: judge.newz
            }, judge])
            lineList.push([judge, pointThree])
          }
        }

        let result = true
        for (let j = 1; j < mapInfo.length; j++) {
          const roomInfo = mapInfo[j]
          for (let l = 0; l < lineList.length; l++) {
            const line = lineList[l]
            const res = analysis.judgeIsCorssWall(line, roomInfo)
            if (res) {
              result = false
              break
            }
          }
        }
        if (result) {
          selectIndex = i
          break
        }
      }
      if (selectIndex < 0) {
        return
      }
      const info = judges[selectIndex]
      if (info.newPoint == false) {
        pointTwo.x = info.x
        pointTwo.z = info.z
      } else {
        pointTwo.x = info.newx
        pointTwo.z = info.newz
        const newP = {
          x: info.x,
          y: 2,
          z: info.z,
          mapid: endPoint.mapid,
          type: leadPointType.normal,
          id: 'newPoint' + info.beforeStart ? '-1' : '-0',
          height: 0,
          url: ''
        }
        if (info.beforeStart) {
          indexs.splice(1, 0, newP)
        } else {
          indexs.splice(2, 0, newP)
        }
      }
    }

    // 对起点进行校正
    function judgeStartPoint(startPoint, pointTwo, pointThree, indexs) {
      if (startPoint.x == pointTwo.x || startPoint.z == pointTwo.z) {
        return
      }
      const res = findMapInfoBlock(startPoint.mapid)
      const mapInfo = res.mapJson
      if (typeof mapInfo == 'undefined' || mapInfo == null || mapInfo.length == 0 || typeof startPoint.room ==
        'undefined' || startPoint.room == null) {
        return
      }
      const zeroInfo = mapInfo[0]
      const roomId = startPoint.room['areaid']
      if (zeroInfo['areaid'] != roomId) {
        return
      }
      const t = analysis.closestPointToPoint(pointTwo, pointThree, startPoint)
      const p0 = {
        x: t.x,
        z: t.z,
        newPoint: false
      }

      const p1 = {
        x: pointTwo.x,
        z: pointThree.z,
        newPoint: true,
        beforeStart: false,
        newx: pointTwo.x,
        newz: startPoint.z
      }
      const p2 = {
        x: pointThree.x,
        z: pointTwo.z,
        newPoint: true,
        beforeStart: false,
        newx: startPoint.x,
        newz: pointTwo.z
      }
      const p3 = {
        x: (startPoint.x + pointTwo.x) / 2.0,
        z: pointTwo.z,
        newPoint: true,
        beforeStart: true,
        newx: pointTwo.x,
        newz: pointTwo.z
      }
      const p4 = {
        x: pointTwo.x,
        z: (startPoint.z + pointTwo.z) / 2.0,
        newPoint: true,
        beforeStart: true,
        newx: pointTwo.x,
        newz: pointTwo.z
      }
      const p5 = {
        x: pointTwo.x,
        z: startPoint.z,
        newPoint: false
      }
      const p6 = {
        x: startPoint.x,
        z: pointTwo.z,
        newPoint: false
      }

      // 校正的选择
      const judges = [p0, p1, p2, p3, p4, p5, p6]
      let selectIndex = -1
      for (let i = 0; i < judges.length; i++) {
        const judge = judges[i]
        const lineList = []
        if (judge.newPoint == false) {
          lineList.push([startPoint, judge])
          lineList.push([judge, pointThree])
        } else {
          if (judge.beforeStart) {
            lineList.push([startPoint, judge])
            lineList.push([judge, {
              x: judge.newx,
              z: judge.newz
            }])
            lineList.push([{
              x: judge.newx,
              z: judge.newz
            }, pointThree])
          } else {
            lineList.push([startPoint, {
              x: judge.newx,
              z: judge.newz
            }])
            lineList.push([{
              x: judge.newx,
              z: judge.newz
            }, judge])
            lineList.push([judge, pointThree])
          }
        }

        let result = true
        for (let j = 1; j < mapInfo.length; j++) {
          const roomInfo = mapInfo[j]
          for (let l = 0; l < lineList.length; l++) {
            const line = lineList[l]
            const res = analysis.judgeIsCorssWall(line, roomInfo)
            if (res) {
              result = false
              break
            }
          }
        }
        if (result) {
          selectIndex = i
          break
        }
      }
      if (selectIndex < 0) {
        return
      }
      const info = judges[selectIndex]
      if (info.newPoint == false) {
        pointTwo.x = info.x
        pointTwo.z = info.z
      } else {
        pointTwo.x = info.newx
        pointTwo.z = info.newz
        const newP = {
          x: info.x,
          y: 2,
          z: info.z,
          mapid: startPoint.mapid,
          type: leadPointType.normal,
          id: 'newPoint' + info.beforeStart ? '-1' : '-0',
          height: 0,
          url: ''
        }
        if (info.beforeStart) {
          indexs.push(newP)
        } else {
          indexs.splice(indexs.length - 1, 0, newP)
        }
      }
    }

    // 判断实景图
    function findRealtiyImage(endP, startP) {
      if (analysis.jsonKeyIsExist(endP, ['imgs']) == false) {
        return
      }
      const imgs = endP['imgs']
      const v = new THREE.Vector3(endP.x - startP.x, 0, endP.z - startP.z)
      for (let i = 0; i < imgs.length; i++) {
        const info = imgs[i]
        const imageV = new THREE.Vector3(endP.x - info.x, 0, endP.z - info.z)
        const angle = v.angleTo(imageV)
        if (angle <= Math.PI / 4.0) {
          endP['img'] = {
            url: info.url,
            title: info.title
          }
          break
        }
      }
    }

    function leadPathAtSameMap(indexs, point) {
      if (point.type == leadPointType.futi || point.type == leadPointType.dianti || point.type == leadPointType.louti) {
        point['isInRoom'] = false
      }

      if (indexs.length >= 1) {
        const lastP = indexs[indexs.length - 1]
        if (lastP.x == point.x && lastP.z == point.z) {
          // 同一个点
        } else {
          findRealtiyImage(lastP, point)
          if (point.type == leadPointType.start && indexs.length >= 2) {
            const p = indexs[indexs.length - 2]
            judgeStartPoint(point, lastP, p, indexs)
          }
          indexs.push(point)
        }
      } else {
        indexs.push(point)
      }
    }

    // 开始分楼层
    function leadPathGoFloor(indexs, linkWayInfo, linkMap, info, mapOrdList, idOrder) {
      if (indexs.length > 1) {
        // 大于1的时候才返回
        const newIndexs = indexs
        if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
          const arr = linkWayInfo[linkMap]
          arr.push({
            path: newIndexs,
            dis: 0,
            start: newIndexs[0],
            end: newIndexs[newIndexs.length - 1],
            index: arr.length,
            orderIndex: mapOrdList.length
          })
        } else {
          linkWayInfo[linkMap] = []
          linkWayInfo[linkMap].push({
            index: 0,
            orderIndex: mapOrdList.length,
            path: newIndexs,
            dis: 0,
            start: newIndexs[0],
            end: newIndexs[newIndexs.length - 1]
          })
        }
        if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
          let from = ''
          let to = ''
          if (mapOrdList.length == 0) {
            if (analysis.jsonKeyIsExist(info, ['link'])) {
              from = info['link']
            }
          } else {
            const lastOrder = mapOrdList[mapOrdList.length - 1]
            to = lastOrder.from
            if (analysis.jsonKeyIsExist(info, ['link'])) {
              from = info['link']
            }
          }
          mapOrdList.push({
            mapid: linkMap,
            index: linkWayInfo[linkMap].length - 1,
            id: linkMap + '-' + (linkWayInfo[linkMap].length - 1),
            from: from,
            to: to,
            idOrder: idOrder
          })
          return {
            result: true
          }
        }
      }
      return {
        result: false
      }
    }

    // 终点
    function endPath(indexs, linkWayInfo, json1, mapOrdList) {
      // 楼层终止 对部分路径进行优化
      if (indexs.length >= 3) {
        const endPoint = indexs[0]
        const p1 = indexs[1]
        const p2 = indexs[2]
        judgeEndPoint(endPoint, p1, p2, indexs)
      }
      const newIndexs = indexs
      if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
        const arr = linkWayInfo[linkMap]
        arr.push({
          index: arr.length,
          orderIndex: mapOrdList.length,
          path: newIndexs,
          dis: 0,
          start: newIndexs[0],
          end: newIndexs[newIndexs.length - 1]
        })
      } else {
        linkWayInfo[linkMap] = []
        linkWayInfo[linkMap].push({
          index: 0,
          orderIndex: mapOrdList.length,
          path: newIndexs,
          dis: 0,
          start: newIndexs[0],
          end: newIndexs[newIndexs.length - 1]
        })
      }
      if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
        let from = ''
        let to = ''
        if (mapOrdList.length == 0) {
          if (analysis.jsonKeyIsExist(json1, ['link'])) {
            from = json1['link']
          }
        } else {
          const lastOrder = mapOrdList[mapOrdList.length - 1]
          to = lastOrder.from
          if (analysis.jsonKeyIsExist(json1, ['link'])) {
            from = json1['link']
          }
        }
        mapOrdList.push({
          mapid: linkMap,
          index: linkWayInfo[linkMap].length - 1,
          id: linkMap + '-' + (linkWayInfo[linkMap].length - 1),
          from: from,
          to: to,
          idOrder: idOrder
        })
      }
    }

    let indexs = []
    let temp = end
    let linkMap = ''
    const linkWayInfo = {}
    const poInfo = {}
    const mapOrdList = [] // 走地图的顺序
    let idOrder = {}
    while (temp != -1) {
      const l = G.arc[temp]
      const json1 = dataInfo[l.mapid][l.id]
      const p1 = returnPoint(json1)
      const mapid = p1.mapid
      idOrder[json1.id] = {
        mapid: mapid,
        id: json1.id
      }
      if (linkMap == '') {
        // 开始的信息
        linkMap = mapid
      }
      if (linkMap != mapid) {
        // 开始分楼层
        const res = leadPathGoFloor(indexs, linkWayInfo, linkMap, json1, mapOrdList, idOrder)
        if (res.result) {
          idOrder = {}
        }
        indexs = []
        linkMap = mapid
      }
      leadPathAtSameMap(indexs, p1)
      temp = Pathmatirx[temp]
      if (temp == -1) {
        // 楼层终止 对部分路径进行优化
        endPath(indexs, linkWayInfo, json1, mapOrdList)
      }
      if (temp == infinite) {
        return {
          isLink: false,
          indexs: {},
          poInfo: {},
          mapOrder: [],
          zoneIdInfo: {}
        }
      }
    }
    judgePoInfo(poInfo, linkWayInfo, dataInfo, po)
    // resetDistance(linkWayInfo)
    const zoneIdInfo = updatePathInfo(linkWayInfo, zoneInfo)
    return {
      isLink: true,
      indexs: linkWayInfo,
      poInfo: poInfo,
      mapOrder: mapOrdList,
      zoneIdInfo: zoneIdInfo
    }
  }

  // 查找线段在区域的位置
  function getAreasPoint(zoneInfo, startP, endP, zoneIdInfo) {
    let startList = []
    let endList = []
    const line = analysis.createLine(startP, endP)
    for (let i = 0; i < zoneInfo.length; i++) {
      const areaInfo = zoneInfo[i]
      const areas = areaInfo['areas']
      let scaleList = []
      scaleList.push({
        x: startP.x,
        y: startP.z,
        scale: 0
      })
      for (let j = 0; j < areas.length; j++) {
        const p = areas[j]
        const np = areas[(j + 1 >= areas.length ? 0 : j + 1)]
        const res = analysis.twoLineXiangjiaoPoint(p[0], p[2], np[0], np[2], startP.x, startP.z, endP.x, endP.z)
        if (res.result == false) {
          continue
        }
        const scale1 = analysis.updateLineAndclosestPointToPointParameter(line, res.x, 0, res.y, {
          x: p[0],
          y: 0,
          z: p[2]
        }, {
          x: np[0],
          y: 0,
          z: np[2]
        })
        const scale2 = analysis.updateLineAndclosestPointToPointParameter(line, res.x, 0, res.y, startP, endP)
        if (isNaN(scale2) || isNaN(scale1)) {
          continue
        }
        if (scale1 < 0 || scale1 > 1 || scale2 < 0 || scale2 > 1) {
          continue
        }
        scaleList.push({
          x: res.x,
          y: res.y,
          scale: scale1
        })
      }

      scaleList.push({
        x: endP.x,
        y: endP.z,
        scale: 1
      })

      // 按照scale由小到大排序
      scaleList = scaleList.sort((a, b) => {
        return a.scale - b.scale
      })
      for (let i = 0; i < scaleList.length - 1; i++) {
        const info = scaleList[i]
        const nextInfo = scaleList[i + 1]
        const center = {
          x: (info.x + nextInfo.x) / 2.0,
          y: (info.y + nextInfo.y) / 2.0
        }
        if (analysis.isInPolygon([center.x, 0, center.y], areas)) {
          startList.push({
            title: areaInfo['title'],
            id: areaInfo['areaid'],
            start: info.scale,
            end: nextInfo.scale,
            startP: {
              x: info.x,
              y: 2,
              z: info.y
            },
            endP: {
              x: nextInfo.x,
              y: 2,
              z: nextInfo.y
            }
          })
          endList.push({
            title: areaInfo['title'],
            id: areaInfo['areaid'],
            start: info.scale,
            end: nextInfo.scale,
            startP: {
              x: info.x,
              y: 2,
              z: info.y
            },
            endP: {
              x: nextInfo.x,
              y: 2,
              z: nextInfo.y
            }
          })
          i = i + 1
          zoneIdInfo[areaInfo['areaid']] = '1'
        }
      }
    }

    // 按照scale由小到大排序
    endList = endList.sort((a, b) => {
      return a.start - b.start
    })
    startList = startList.sort((a, b) => {
      return a.start - b.start
    })
    return {
      start: startList,
      end: endList
    }
  }

  // 直角画圆角
  function createRadiusPath(path) {
    const newPath = []
    const defaultN = new THREE.Vector3(0, 1, 0)
    const radiusCount = 6
    const radiusAngle = 160 / 180 * Math.PI

    function getRadiusPoint(newPath, p1, p2, p3) {
      const fixNum = 1
      const x1 = parseFloat((p1.x - p2.x).toFixed(fixNum))
      const y1 = parseFloat((p1.y - p2.y).toFixed(fixNum))
      const z1 = parseFloat((p1.z - p2.z).toFixed(fixNum))

      const x2 = parseFloat((p3.x - p2.x).toFixed(fixNum))
      const y2 = parseFloat((p3.y - p2.y).toFixed(fixNum))
      const z2 = parseFloat((p3.z - p2.z).toFixed(fixNum))

      const v1 = new THREE.Vector3(x1, y1, z1)
      const v2 = new THREE.Vector3(x2, y2, z2)
      const angle = v1.angleTo(v2)
      if (angle >= radiusAngle) {
        newPath.unshift(p2)
        return
      }
      let radius = 1 * 10
      const dis1 = v1.length()
      const dis2 = v2.length()
      const minDis = dis1 > dis2 ? dis2 : dis1
      if (minDis <= radius) {
        radius = minDis * 0.2
      }
      const halfAngle = angle / 2.0
      const sin = Math.sin(halfAngle)
      const getDis = radius / sin

      const m = new THREE.Matrix3()
      const n = new THREE.Vector3(0, 0, 0).crossVectors(v1, v2).normalize()
      n.x = Math.abs(n.x)
      n.y = Math.abs(n.y)
      n.z = Math.abs(n.z)
      m.elements = [
        n.x, n.y, n.z,
        v1.x, v1.y, v1.z,
        v2.x, v2.y, v2.z
      ]
      const r = m.determinant()
      let d = 1
      if (r != 0) {
        d = r / Math.abs(r)
      }
      const v3 = v1.clone().applyAxisAngle(defaultN, halfAngle * d).normalize()
      const v4 = v3.clone().multiplyScalar(getDis)
      const radiusPoint = v4.clone().add(new THREE.Vector3(p2.x, p2.y, p2.z))
      const v5 = v3.clone().negate().applyAxisAngle(defaultN, (Math.PI / 2.0 - halfAngle) * d).multiplyScalar(radius)
      const totalAngle = Math.PI - angle
      const rotateA = totalAngle / radiusCount
      for (let i = 0; i < radiusCount + 1; i++) {
        const A = i * rotateA * d * -1
        const v6 = v5.clone().applyAxisAngle(defaultN, A)
        const p = radiusPoint.clone().add(v6)
        const copyInfo = JSON.parse(JSON.stringify(p2))
        copyInfo.x = p.x
        copyInfo.z = p.z
        newPath.unshift(copyInfo)
      }
    }

    if (path.length >= 3) {
      for (let i = path.length - 1; i > 1; i--) {
        const p1 = newPath.length == 0 ? path[i] : newPath[0]
        const p2 = path[i - 1]
        const p3 = path[i - 2]
        if (i == path.length - 1) {
          newPath.unshift(p1)
        }
        getRadiusPoint(newPath, p1, p2, p3)

        if (i == 2) {
          newPath.unshift(p3)
        }
      }
    } else if (path.length == 2) {
      for (let i = path.length - 1; i > 0; i--) {
        const p1 = newPath.length == 0 ? path[i] : newPath[0]
        const p2 = path[i - 1]
        newPath.unshift(p1)
        newPath.unshift(p2)
      }
    } else if (path.length == 1) {
      const p1 = path[0]
      const p2 = path[0]
      newPath.unshift(p1)
      newPath.unshift(p2)
    }

    return newPath
  }

  function updatePathInfo(linkWayInfo, zoneInfo) {
    const zoneIdInfo = {} // 记录所在区域
    for (const mapid in linkWayInfo) {
      const pathInfos = linkWayInfo[mapid]
      for (let i = 0; i < pathInfos.length; i++) {
        const info = pathInfos[i]
        const path = info.path
        const newPath = createRadiusPath(path)
        let dis = 0 // 计算路径距离
        let zoneList = []
        const paths = []
        if (analysis.jsonKeyIsExist(zoneInfo, [mapid])) {
          zoneList = zoneInfo[mapid]
        }

        for (let j = 0; j < newPath.length - 1; j++) {
          const p1 = newPath[j]
          const p2 = newPath[j + 1]
          dis = dis + Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
          const res = getAreasPoint(zoneList, p1, p2, zoneIdInfo)
          paths.push(res.end)
        }
        info.path = newPath
        info.dis = dis
        info.pathScale = paths
      }
    }
    return zoneIdInfo
  }

  function judgePoInfo(poInfo, linkWayInfo, mapDataInfo, po) {
    function findPoInfo(link, dataInfo, existInfo) {
      const linkPoint = link['linkPoint']
      for (const key in linkPoint) {
        const info = dataInfo[key]
        if (info.type == leadPointType.po && (link.x != info.x || link.y != info.y)) {
          const key1 = link.id + info.id
          const key2 = info.id + link.id
          if (analysis.jsonKeyIsExist(existInfo, [link.mapid]) && analysis.jsonKeyIsExist(existInfo[link.mapid], [key1,
            key2
          ])) {
            continue
          }
          const maxX = analysis.findMax([link.x, info.x])
          const minX = analysis.findMin([link.x, info.x])
          const maxZ = analysis.findMax([link.y, info.y])
          const minZ = analysis.findMin([link.y, info.y])
          const leftUp = {
            x: minX,
            z: minZ
          }
          const rightDown = {
            x: maxX,
            z: maxZ
          }
          if (leftUp.x == rightDown.x) {
            leftUp.x = leftUp.x - Math.abs(parseFloat(link.W / 2.0))
            rightDown.x = rightDown.x + Math.abs(parseFloat(link.W / 2.0))
          } else if (leftUp.z == rightDown.z) {
            leftUp.z = leftUp.z - Math.abs(parseFloat(link.H / 2.0))
            rightDown.z = rightDown.z + Math.abs(parseFloat(link.H / 2.0))
          } else {
            leftUp.x = leftUp.x - Math.abs(parseFloat(link.W / 2.0))
            rightDown.x = rightDown.x + Math.abs(parseFloat(link.W / 2.0))
            leftUp.z = leftUp.z - Math.abs(parseFloat(link.H / 2.0))
            rightDown.z = rightDown.z + Math.abs(parseFloat(link.H / 2.0))
          }
          const paths = linkWayInfo[link.mapid]
          for (let l = 0; l < paths.length; l++) {
            const path = paths[l].path
            for (let j = 0; j < path.length - 1; j++) {
              const p1 = path[j]
              const p2 = path[j + 1]
              const isCorss = analysis.judgeIsCorssWall([{
                x: p1.x,
                z: p1.z
              }, {
                x: p2.x,
                z: p2.z
              }], {
                areas: [
                  [leftUp.x, 0, leftUp.z],
                  [leftUp.x, 0, rightDown.z],
                  [rightDown.x, 0, rightDown.z],
                  [rightDown.x, 0, leftUp.z]
                ]
              })
              if (isCorss) {
                // 经过该坡度区域
                const copyLink = JSON.parse(JSON.stringify(link))
                copyLink.z = copyLink.y
                const copyInfo = JSON.parse(JSON.stringify(info))
                copyInfo.z = copyInfo.y

                const goX = p1.x - p2.x
                const goZ = p1.z - p2.z
                const go = Math.abs(goX) > Math.abs(goZ) ? {
                  x: goX / Math.abs(goX),
                  z: 0
                } : {
                  x: 0,
                  z: goZ / Math.abs(goZ)
                }
                let first = copyLink
                let next = copyInfo
                if (go.x == 0) {
                  if (go.z > 0) {
                    first = copyLink.z > copyInfo.z ? copyInfo : copyLink
                    next = copyLink.z > copyInfo.z ? copyLink : copyInfo
                  } else {
                    next = copyLink.z > copyInfo.z ? copyInfo : copyLink
                    first = copyLink.z > copyInfo.z ? copyLink : copyInfo
                  }
                } else {
                  if (go.x > 0) {
                    first = copyLink.x > copyInfo.x ? copyInfo : copyLink
                    next = copyLink.x > copyInfo.x ? copyLink : copyInfo
                  } else {
                    next = copyLink.x > copyInfo.x ? copyInfo : copyLink
                    first = copyLink.x > copyInfo.x ? copyLink : copyInfo
                  }
                }

                const poMsg = getPoMsg(first, next)
                if (poMsg.result == true) {
                  const correct = analysis.setPointToLine(poMsg.x, poMsg.z, [{
                    x: p1.x,
                    z: p1.z
                  }, {
                    x: p2.x,
                    z: p2.z
                  }])
                  poMsg.z = correct.z
                  poMsg.x = correct.x
                  if (analysis.jsonKeyIsExist(poInfo, [link.mapid])) {
                    const poList = poInfo[link.mapid]
                    let isExist = false
                    for (let k = 0; k < poList.length; k++) {
                      const po = poList[k]
                      if (po.x == poMsg.x && po.z == poMsg.z) {
                        isExist = true
                        break
                      }
                    }
                    if (isExist == false) {
                      poList.push(poMsg)
                    }
                  } else {
                    const poList = []
                    poList.push(poMsg)
                    poInfo[link.mapid] = poList
                  }
                }
                let record = {}
                if (analysis.jsonKeyIsExist(existInfo, [link.mapid])) {
                  record = existInfo[link.mapid]
                } else {
                  existInfo[link.mapid] = record
                }
                const key = link.id + info.id
                record[key] = 1
                break
              }
            }
          }
        }
      }
    }

    // 防止丢失坡度信息
    const existInfo = {}
    for (const mapid in linkWayInfo) {
      if (analysis.jsonKeyIsExist(po, [mapid])) {
        const mapPo = po[mapid]
        const dataInfo = mapDataInfo[mapid]
        for (const dataKey in mapPo) {
          const link = dataInfo[dataKey]
          findPoInfo(link, dataInfo, existInfo)
        }
      }
    }
  }

  // 获取坡度信息
  function getPoMsg(first, next) {
    let height1 = 0
    let height2 = 0
    if (analysis.jsonKeyIsExist(first, ['height'])) {
      height1 = first.height
    } else if (analysis.jsonKeyIsExist(first, ['pHeight'])) {
      height1 = first.pHeight
    }
    if (analysis.jsonKeyIsExist(next, ['height'])) {
      height2 = next.height
    } else if (analysis.jsonKeyIsExist(next, ['pHeight'])) {
      height2 = next.pHeight
    }
    const height = Math.abs(height1 - height2)
    const xCount = first.x - next.x
    const yCount = first.z - next.z
    const dis = Math.sqrt(xCount * xCount + yCount * yCount)
    if (height == 0) {
      return {
        result: false,
        x: 0,
        z: 0,
        msg: ''
      }
    }
    const tan = height / dis
    const angle = Math.atan(tan) * 180 / Math.PI
    let msg = height1 < height2 ? '上坡 ' : '下坡 '
    if (angle > 0 && angle <= 15) {
      msg = msg + ''
    } else if (angle > 15 && angle <= 45) {
      // msg = msg + '坡度较陡'
    } else {
      // msg = msg + '坡度非常陡'
    }
    return {
      result: true,
      x: (first.x + next.x) / 2.0,
      z: (first.z + next.z) / 2.0,
      msg: msg
    }
  }

  // 比对导航点的信息
  matrixInfo.compareLeadInfo = function(compareInfo, oldLeadInfo, leadType, mapid) {
    function setMapid(mapid, id) {
      let mapidStr = mapid.replace(/\)/g, '\\)')
      mapidStr = mapidStr.replace(/\(/g, '\\(')
      mapidStr = mapidStr.replace(/\./g, '\\.')
      const m = eval('/' + mapidStr + '/')
      const match = id.match(m)
      if (match == null) {
        id = mapid + id
      }
      return id
    }

    // 先判断数据是否完整
    for (const key in compareInfo) {
      const lead = compareInfo[key]
      if (analysis.jsonKeyIsExist(lead, ['linkPoint']) == false) {
        continue
      }
      const linkPoint = lead['linkPoint']
      for (const linkId in linkPoint) {
        const linkInfo = linkPoint[linkId]
        if (analysis.jsonKeyIsExist(linkInfo, ['id']) == false) {
          const startId = linkInfo['start']
          const endId = linkInfo['end']
          let id = ''
          if (startId > endId) {
            id = 'line' + startId + '-' + endId
          } else {
            id = 'line' + endId + '-' + startId
          }
          linkInfo['id'] = id
        }
        if (analysis.jsonKeyIsExist(linkInfo, ['startP']) == false) {
          const startId = linkInfo['start']
          if (analysis.jsonKeyIsExist(compareInfo, [startId])) {
            const startInfo = compareInfo[startId]
            const startP = {
              x: startInfo.x,
              y: 0,
              z: startInfo.y
            }
            linkInfo['startP'] = startP
          }
        }
        if (analysis.jsonKeyIsExist(linkInfo, ['endP']) == false) {
          const endId = linkInfo['end']
          if (analysis.jsonKeyIsExist(compareInfo, [endId])) {
            const endInfo = compareInfo[endId]
            const endP = {
              x: endInfo.x,
              y: 0,
              z: endInfo.y
            }
            linkInfo['endP'] = endP
          }
        }
        if (analysis.jsonKeyIsExist(linkInfo, ['dis']) == false) {
          const startP = linkInfo['startP']
          const endP = linkInfo['endP']
          const dis = Math.sqrt((startP.x - endP.x) * (startP.x - endP.x) + (startP.z - endP.z) * (startP.z - endP.z))
          linkInfo['dis'] = parseFloat(dis.toFixed(2))
        }
      }
    }

    const leadInfo = {}
    for (const key in compareInfo) {
      const data = JSON.parse(JSON.stringify(compareInfo[key]))
      let id = data['id']
      id = setMapid(mapid, id)
      data['id'] = id
      leadInfo[id] = data
    }
    const deleteInfo = {}
    const copyLead = JSON.parse(JSON.stringify(leadInfo))
    delete oldLeadInfo['startP']
    delete oldLeadInfo['endP']
    for (const oldKey in oldLeadInfo) {
      const oldData = oldLeadInfo[oldKey]
      const key = oldKey
      if (analysis.jsonKeyIsExist(leadInfo, [key])) {
        // 新的leadinfo存在该连接点
        delete copyLead[oldKey] // 查找新增的点
        const newData = leadInfo[key]
        const oldLinkType = analysis.jsonKeyIsExist(oldData, ['link'])
        const newLinkType = analysis.jsonKeyIsExist(newData, ['link'])
        if (oldLinkType == true && newLinkType == true) {
          oldData['link'] = newData['link']
        } else if (oldLinkType == false && newLinkType == true) {
          // 新增link
          oldData['link'] = newData['link']
        } else if (oldLinkType == true && newLinkType == false) {
          // 删除link
          delete oldData['link']
        }

        const oldlinkPoint = analysis.jsonKeyIsExist(oldData, ['linkPoint'])
        const newlinkPoint = analysis.jsonKeyIsExist(newData, ['linkPoint'])
        if (oldlinkPoint && newlinkPoint) {
          oldData['linkPoint'] = newData['linkPoint']
        } else if (oldlinkPoint == false && newlinkPoint == true) {
          oldData['linkPoint'] = newData['linkPoint']
        } else if (oldlinkPoint == true && newlinkPoint == false) {
          if (leadType == 'svg') {

          } else {
            delete oldData['linkPoint']
          }
        }

        if (oldData.x == newData.x && oldData.y == newData.y) {
          // 位置没有更改
        } else {
          // 位置更改
          oldData.x = newData.x
          oldData.y = newData.y
          if (analysis.jsonKeyIsExist(oldData, ['linkPoint'])) {
            const linkPoint = oldData['linkPoint']
            for (const link in linkPoint) {
              if (analysis.jsonKeyIsExist(oldLeadInfo, [link]) == false) {
                continue
              }
              const data = oldLeadInfo[link]
              if (analysis.jsonKeyIsExist(data, ['linkPoint']) == false) {
                continue
              }
              const oLinkData = data['linkPoint']
              if (analysis.jsonKeyIsExist(oLinkData, [oldKey]) == false) {
                continue
              }

              const linkData = linkPoint[link]
              const start = linkData['start']
              const end = linkData['end']
              const startP = linkData['startP']
              if (start == oldKey) {
                startP.x = oldData.x
                startP.z = oldData.y
              }
              const endP = linkData['endP']
              if (end == oldKey) {
                endP.x = oldData.x
                endP.z = oldData.y
              }
              const dis = Math.sqrt(Math.abs((endP.x - startP.x) * (endP.x - startP.x) + (endP.z - startP.z) * (endP
                .z - startP.z)))
              linkData['dis'] = parseFloat(dis.toFixed(2))

              const findData = oLinkData[oldKey]
              const oStart = findData['start']
              const oEnd = findData['end']
              const oStartP = findData['startP']
              const oEndP = findData['endP']
              if (oStart == oldKey) {
                oStartP.x = oldData.x
                oStartP.z = oldData.y
              }
              if (oEnd == oldKey) {
                oEndP.x = oldData.x
                oEndP.z = oldData.y
              }
              findData['dis'] = parseFloat(dis.toFixed(2))
            }
          }
        }
      } else {
        // 不存在该连接点
        deleteInfo[oldKey] = oldData
      }
    }

    for (const deleteKey in deleteInfo) {
      for (const oldKey in oldLeadInfo) {
        if (oldKey != deleteKey) {
          const data = oldLeadInfo[oldKey]
          if (analysis.jsonKeyIsExist(data, ['linkPoint'])) {
            const linkPoint = data['linkPoint']
            if (analysis.jsonKeyIsExist(linkPoint, [deleteKey])) {
              delete linkPoint[deleteKey]
            }
          }
        }
      }
      delete oldLeadInfo[deleteKey]
    }
    for (const key in copyLead) {
      oldLeadInfo[key] = copyLead[key]
    }
    return oldLeadInfo
  }

  matrixInfo.adjustDistance = function(leadInfo) {
    for (const oldKey in leadInfo) {
      const oldData = leadInfo[oldKey]
      if (analysis.jsonKeyIsExist(oldData, ['linkPoint'])) {
        const linkPoint = oldData['linkPoint']
        for (const link in linkPoint) {
          if (analysis.jsonKeyIsExist(leadInfo, [link]) == false) {
            continue
          }
          const data = leadInfo[link]
          if (analysis.jsonKeyIsExist(data, ['linkPoint']) == false) {
            continue
          }
          const oLinkData = data['linkPoint']
          if (analysis.jsonKeyIsExist(oLinkData, [oldKey]) == false) {
            continue
          }
          const linkData = linkPoint[link]
          const start = linkData['start']
          const end = linkData['end']
          const startP = linkData['startP']
          if (start == oldKey) {
            startP.x = oldData.x
            startP.z = oldData.y
          }
          const endP = linkData['endP']
          if (end == oldKey) {
            endP.x = oldData.x
            endP.z = oldData.y
          }
          const dis = Math.sqrt(Math.abs((endP.x - startP.x) * (endP.x - startP.x) + (endP.z - startP.z) * (endP.z -
            startP.z)))
          linkData['dis'] = parseFloat(dis.toFixed(2))
          const findData = oLinkData[oldKey]
          const oStart = findData['start']
          const oEnd = findData['end']
          const oStartP = findData['startP']
          const oEndP = findData['endP']
          if (oStart == oldKey) {
            oStartP.x = oldData.x
            oStartP.z = oldData.y
          }
          if (oEnd == oldKey) {
            oEndP.x = oldData.x
            oEndP.z = oldData.y
          }
          findData['dis'] = parseFloat(dis.toFixed(2))
        }
      }
    }
  }

  // 将坡度信息剔除
  matrixInfo.deletePoInfo = function(info, martix, po, start, end) {
    if (analysis.jsonKeyIsExist(start, ['linkPoint']) && analysis.jsonKeyIsExist(info, [start.mapid])) {
      const matrixIndex = start.matrix
      for (const key in start['linkPoint']) {
        const startPo = {}
        const startList = {}
        const find = info[start.mapid]
        const point = find[key]
        if (point.x == null || point.y == null) {
          continue
        }
        startList[start.mapid] = [{
          path: [{
            x: point.x,
            z: point.y
          }, {
            x: start.x,
            z: start.y
          }]
        }]
        judgePoInfo(startPo, startList, info, po)
        if (analysis.jsonKeyIsExist(startPo, [start.mapid])) {
          // 将矩阵里面的连接信息删除
          const point = info[start.mapid][key]
          const pointIndex = point.matrix
          martix[matrixIndex].arr[pointIndex] = infinite
          martix[pointIndex].arr[matrixIndex] = infinite
        }
      }
    }
    if (analysis.jsonKeyIsExist(end, ['linkPoint']) && analysis.jsonKeyIsExist(info, [end.mapid])) {
      const matrixIndex = end.matrix
      for (const key in end['linkPoint']) {
        const endList = {}
        const endPo = {}
        const find = info[end.mapid]
        const point = find[key]
        if (point.x == null || point.y == null) {
          continue
        }
        endList[end.mapid] = [{
          path: [{
            x: point.x,
            z: point.y
          }, {
            x: end.x,
            z: end.y
          }]
        }]
        judgePoInfo(endPo, endList, info[end.mapid], info[end.mapid])
        if (analysis.jsonKeyIsExist(endPo, [end.mapid])) {
          const point = info[end.mapid][key]
          const pointIndex = point.matrix
          martix[matrixIndex].arr[pointIndex] = infinite
          martix[pointIndex].arr[matrixIndex] = infinite
        }
      }
    }
    for (const mapid in po) {
      const pointInfo = po[mapid]
      for (const key in pointInfo) {
        const point = pointInfo[key]
        const pointMatrix = point.matrix
        if (point.type == leadPointType.po && analysis.jsonKeyIsExist(point, ['linkPoint'])) {
          const linkPoint = point['linkPoint']
          for (const linkId in linkPoint) {
            const linkInfo = info[mapid][linkId]
            const linkIndex = linkInfo.matrix
            martix[pointMatrix].arr[linkIndex] = infinite
            martix[linkIndex].arr[pointMatrix] = infinite
          }
        }
      }
    }
  }

  matrixInfo.testOpenThreadFindTheWay = function(lead, startMapid, endMapid, leadTypes, mapidList, mapCheckInfo,
    startMapInfo, mapInfo, callBack) {
    function startFindWay(lead, startMapid, endMapid, leadTypes, mapidList, mapCheckInfo, startMapInfo, mapInfo) {
      function returnLeadTitle(type) {
        let leadStr = ''
        switch (type) {
          case leadPointType.futi:
            leadStr = 'futi'
            break
          case leadPointType.dianti:
            leadStr = 'dianti'
            break
          case leadPointType.louti:
            leadStr = 'louti'
            break
        }
        return leadStr
      }

      function jsonIsEmpty(info) {
        let empty = true
        for (const key in info) {
          empty = false
          break
        }
        return empty
      }

      let isSameMap = (startMapid == endMapid)
      const mapOrderInfo = {}
      const wayInfo = {}
      let type = leadPointType.dianti
      let noWay = false
      let count = 0
      let poInfo = {}
      for (let i = 0; i < leadTypes.length; i++) {
        const leadType = leadTypes[i]
        const infoKey = returnLeadTitle(leadType)
        const dataInfoResult = findWayByFloors(lead, startMapid, endMapid, leadType, mapidList)
        if (dataInfoResult.isLink == false) {
          continue
        }
        wayInfo[infoKey] = {}
        wayInfo[infoKey]['list'] = []
        type = infoKey
        count = count + 1
        wayInfo[infoKey]['list'] = dataInfoResult.indexs
        wayInfo[infoKey]['po'] = dataInfoResult.poInfo
        wayInfo[infoKey]['order'] = dataInfoResult.mapOrder
        wayInfo[infoKey]['zoneIdInfo'] = dataInfoResult.zoneIdInfo
        poInfo = dataInfoResult.poInfo
        mapInfo['leadType'][infoKey] = 1
        startMapInfo['leadType'][infoKey] = 1
        if (isSameMap == true) {
          // 为同一地图 查找 leadCubeInfo.leadPath 里面有几个地图id 一个地图id即在同一张地图上存在路径 多个id表示多条路径
          let idCount = 0
          for (const id in dataInfoResult.indexs) {
            idCount = idCount + 1
          }
          if (idCount >= 2) {
            isSameMap = false
          } else {
            break
          }
        }
        const mapOrder = dataInfoResult.mapOrder
        const arr = []
        for (let j = mapOrder.length - 1; j >= 0; j--) {
          const id = mapOrder[j].mapid
          const orderInfo = {
            mapName: '',
            mapid: id
          }
          arr.push(orderInfo)
        }
        mapOrderInfo[infoKey] = arr
      }

      if (count == 0) {
        noWay = true
      } else {
        let minDis = 65535
        for (const key in wayInfo) {
          const data = wayInfo[key]
          const list = data['list']
          let dis = 0
          for (const mapKey in list) {
            const mapList = list[mapKey]
            for (let i = 0; i < mapList.length; i++) {
              dis = mapList[i].dis + dis
            }
          }
          if (minDis >= dis && dis != 0) {
            minDis = dis
            type = key
            poInfo = data.po
          }
        }
      }
      const result = {
        result: !noWay,
        msg: '',
        data: {
          mapid: startMapid,
          way: wayInfo,
          type: type,
          isSameMap: isSameMap,
          noWay: noWay,
          isPo: !jsonIsEmpty(poInfo),
          tuijian: '',
          mapOrderInfo: mapOrderInfo
        }
      }
      return result
    }

    const result = startFindWay(lead, startMapid, endMapid, leadTypes, mapidList, mapCheckInfo, startMapInfo, mapInfo)
    callBack(result)
  }

  matrixInfo.openThreadFindTheWay = function(lead, startMapid, endMapid, leadTypes, mapidList, mapCheckInfo,
    startMapInfo, mapInfo, callBack) {
    const worker = workerize(function(m) {
      const leadPointType = {
        start: 5,
        end: 4,
        louti: 1,
        dianti: 2,
        futi: 3,
        normal: 0,
        po: 99
      }

      m.jsonKeyIsExist = function(json, keys) {
        if (keys == null || typeof (keys) == 'undefined' || json == null || typeof (json) == 'undefined' || keys
          .length == 0) {
          // console.log('json 格式出错')
          return false
        }
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          if (key == null || typeof (key) == 'undefined') {
            continue
          }
          const value = json[key]
          if (value == null || typeof (value) == 'undefined') {
            return false
          }
        }
        return true
      }

      m.findWayByFloors = function(leadData, startMapid, endMapid, leadType, mapidList) {
        const leadInfo = m.getLeadTypeWay(leadData, mapidList, startMapid, endMapid, true, leadType)
        return leadInfo
      }

      m.getLeadTypeWay = function(leadData, mapidList, startMapId, endMapid, isType, linkType) {
        const judges = []

        function editorMatrix(leadData, data) {
          if (m.jsonKeyIsExist(data, ['floors', 'matrix']) == false) {
            return
          }
          const infinite = 65535
          const dataMatrix = data.matrix
          const dataInfo = leadData.info
          const matrix = leadData.matrix
          for (const mapid in data.floors) {
            const mapData = dataInfo[mapid]
            const link = data.floors[mapid]
            const point = mapData[link.id]
            const index = point.matrix
            const dis = matrix[dataMatrix].arr[index]
            const old = {
              index1: dataMatrix,
              index2: index,
              dis: dis
            }
            judges.push(old)
            matrix[dataMatrix].arr[index] = infinite
          }
        }

        if (isType) {
          let linkStr = ''
          if (linkType == leadPointType.futi) {
            linkStr = 'F'
          } else if (linkType == leadPointType.dianti) {
            linkStr = 'D'
          } else {
            linkStr = 'L'
          }
          const floos = leadData.floor
          const dataInfo = leadData.info
          for (const key in floos) {
            if (key.search(linkStr) < 0) {
              // 对矩阵进行修改 不是该类型的则将其修改为不能通过
              const mapFloor = floos[key]
              for (const mapid in mapFloor) {
                const floorInfo = mapFloor[mapid]
                const mapDataInfo = dataInfo[mapid]
                for (const floorKey in floorInfo) {
                  editorMatrix(leadData, mapDataInfo[floorKey])
                }
              }
            }
          }
        }

        const leadInfo = m.makeMatrix('startP', 'endP', leadData)
        const matrix = leadData.matrix
        for (let i = 0; i < judges.length; i++) {
          const data = judges[i]
          matrix[data.index1].arr[data.index2] = data.dis
        }
        return leadInfo
      }

      // 根据数据计算路径
      m.makeMatrix = function(start, end, leadData) {
        const dataInfo = leadData.info
        const poInfo = leadData.po
        const matrix = leadData.matrix
        const zoneInfo = leadData.zoneInfo

        // 定义图结构
        function MGraph() {
          const G = {
            vexs: [], // 顶点表
            arc: [], // 邻接矩阵，可看作边表
            numVertexes: null
          }
          return G
        }

        const G = new MGraph() // 创建图使用

        // 创建图
        function createMGraph(matrix, pointList) {
          G.numVertexes = matrix.length // 设置顶点数
          // 邻接矩阵初始化
          G.arc = matrix
        }

        createMGraph(matrix)
        return m.Dijkstra(start, end, dataInfo, G, poInfo, zoneInfo)
      }

      m.findMax = function(list) {
        let max = null
        for (let i = 0; i < list.length; i++) {
          const num = list[i]
          if (i == 0) {
            max = num
          } else {
            max = max > num ? max : num
          }
        }
        return max
      }

      m.findMin = function(list) {
        let min = null
        for (let i = 0; i < list.length; i++) {
          const num = list[i]
          if (i == 0) {
            min = num
          } else {
            min = min < num ? min : num
          }
        }
        return min
      }

      // 计算两段线段相交的交点
      m.twoLineXiangjiaoPoint = function(x1, y1, x2, y2, x3, y3, x4, y4, isInFence /* 是否在范围内 */) {
        const xCount1 = x2 - x1
        const yCount1 = y2 - y1

        const xCount2 = x4 - x3
        const yCount2 = y4 - y3

        const maxx1 = m.findMax([x1, x2])
        const minx1 = m.findMin([x1, x2])

        const maxy1 = m.findMax([y1, y2])
        const miny1 = m.findMin([y1, y2])

        const maxx2 = m.findMax([x3, x4])
        const minx2 = m.findMin([x3, x4])

        const maxy2 = m.findMax([y3, y4])
        const miny2 = m.findMin([y3, y4])
        let isIn = false

        if (xCount1 == 0) {
          // 当前的手机的定位与待测点的连线垂直X轴向下
          if (xCount2 == 0) {
            // 平行
            return {
              result: false
            }
          } else {
            const info = m.getLineWhenOneLineVertical(x1, x3, y3, x4, y4, false, y1, y2)
            if (info.x >= minx1 && info.x <= maxx1 && info.y >= miny1 && info.y <= maxy1 && info.x >= minx2 &&
              info.x <= maxx2 && info.y >= miny2 && info.y <= maxy2) {
              isIn = true
            }

            return {
              result: true,
              x: info.x,
              y: info.y,
              isIn: isIn
            }
          }
        }
        if (xCount2 == 0) {
          // 垂直向下
          const info = m.getLineWhenOneLineVertical(x3, x1, y1, x2, y2, false, y3, y4)

          if (info.x >= minx1 && info.x <= maxx1 && info.y >= miny1 && info.y <= maxy1 && info.x >= minx2 &&
            info.x <= maxx2 && info.y >= miny2 && info.y <= maxy2) {
            isIn = true
          }

          return {
            result: true,
            x: info.x,
            y: info.y,
            isIn: isIn
          }
        }

        if (yCount1 == 0 && yCount2 == 0) {
          // 平行
          return {
            result: false
          }
        }

        const k1 = yCount1 / xCount1
        const b1 = y1 - k1 * x1

        const k2 = yCount2 / xCount2
        const b2 = y3 - k2 * x3

        let newx = 0
        let newy = 0
        if (yCount2 == 0) {
          newy = y3
          newx = (newy - b1) / k1
        } else {
          newx = (b2 - b1) / (k1 - k2)
          newy = k1 * newx + b1
        }

        if (newx >= minx1 && newx <= maxx1 && newy >= miny1 && newy <= maxy1 && newx >= minx2 && newx <=
          maxx2 && newy >= miny2 && newy <= maxy2) {
          isIn = true
        }
        return {
          result: true,
          x: newx,
          y: newy,
          isIn: isIn
        }
      }

      m.getLineWhenOneLineVertical = function(verticalX, x_1, y_1, x_2, y_2, isInFence, verticalY1, verticalY2) {
        const k2 = (y_2 - y_1) / (x_2 - x_1)
        const b = y_1 - k2 * x_1
        let newx = 0
        let newy = 0

        if (verticalY1 == verticalY2) {
          newy = verticalY1
          newx = (newy - b) / k2
        } else {
          newx = verticalX
          newy = k2 * newx + b
        }

        if (isInFence == true) {
          const maxx = x_1 > x_2 ? x_1 : x_2
          const minx = x_1 > x_2 ? x_2 : x_1
          const maxy = y_1 > y_2 ? y_1 : y_2
          const miny = y_1 > y_2 ? y_2 : y_1

          const maxVerticalY = verticalY1 > verticalY2 ? verticalY1 : verticalY2
          const minVerticalY = verticalY1 < verticalY2 ? verticalY1 : verticalY2

          if (newx == verticalX && newx >= minx && newx <= maxx && newy >= minVerticalY && newy <=
            maxVerticalY && newy >= miny && newy <= maxy) {
            return {
              result: true,
              x: newx,
              y: newy,
              isIn: true
            }
          } else {
            // 不在范围内
            return {
              result: true,
              x: newx,
              y: newy,
              isIn: false
            }
          }
        } else {
          return {
            result: true,
            x: newx,
            y: newy
          }
        }
      }

      // 获取一个点到一线段的距离
      m.getDistanceForPointToLine = function(x1, y1, x2, y2, x3, y3) {
        const xCount = x2 - x1
        const yCount = y2 - y1
        let dis = 0
        if (xCount == 0) {
          dis = Math.abs(x3 - x2)
          if (y3 >= m.findMin([y1, y2]) && y3 <= m.findMax([y1, y2])) {
            // 在范围内
            return {
              isIn: true,
              dis: dis
            }
          }
          return {
            isIn: false,
            dis: dis
          }
        } else if (yCount == 0) {
          dis = Math.abs(y3 - y2)
          if (x3 >= m.findMin([x1, x2]) && x3 <= m.findMax([x1, x2])) {
            // 在范围内
            return {
              isIn: true,
              dis: dis
            }
          }
          return {
            isIn: false,
            dis: dis
          }
        }
        const k1 = yCount / xCount
        const b1 = y1 - k1 * x1

        const k2 = -1 / k1
        const b2 = y3 - k2 * x3

        const x = (b2 - b1) / (k1 - k2)
        const y = k1 * x + b1
        const maxx = m.findMax([x1, x2])
        const minx = m.findMin([x1, x2])

        const maxy = m.findMax([y1, y2])
        const miny = m.findMin([y1, y2])

        dis = Math.sqrt((x - x3) * (x - x3) + (y - y3) * (y - y3))

        if (x >= minx && x <= maxx && y >= miny && y <= maxy) {
          return {
            isIn: true,
            dis: dis
          }
        }
        return {
          isIn: false,
          dis: dis
        }
      }

      // 判断线段是否穿墙
      m.judgeIsCorssWall = function(line, roomInfo) {
        const start = line[0]
        const end = line[1]
        const areas = roomInfo['areas']
        const minDis = 0.9
        for (let i = 0; i < areas.length; i++) {
          const p = areas[i]
          const px = p[0]
          const pz = p[2]
          let next = i + 1
          if (next >= areas.length) {
            next = 0
          }
          const nextP = areas[next]
          const nextPx = nextP[0]
          const nextPz = nextP[2]
          const result = m.twoLineXiangjiaoPoint(start.x, start.z, end.x, end.z, px, pz, nextPx, nextPz, true)
          if (result.isIn == true) {
            return true
          }
          const dis1 = m.getDistanceForPointToLine(start.x, start.z, end.x, end.z, px, pz)
          const dis2 = m.getDistanceForPointToLine(start.x, start.z, end.x, end.z, nextPx, nextPz)
          if (dis1.isIn == true && dis1.dis <= minDis) {
            return true
          }

          if (dis2.isIn == true && dis2.dis <= minDis) {
            return true
          }
        }
        return false
      }

      // 开始计算路径
      m.Dijkstra = function(startid, endid, dataInfo, G, po, zoneInfo) {
        // 获取坡度信息
        function getPoMsg(first, next) {
          let height1 = 0
          let height2 = 0
          if (m.jsonKeyIsExist(first, ['height'])) {
            height1 = first.height
          } else if (m.jsonKeyIsExist(first, ['pHeight'])) {
            height1 = first.pHeight
          }
          if (m.jsonKeyIsExist(next, ['height'])) {
            height2 = next.height
          } else if (m.jsonKeyIsExist(next, ['pHeight'])) {
            height2 = next.pHeight
          }

          const height = Math.abs(height1 - height2)
          const xCount = first.x - next.x
          const yCount = first.z - next.z
          const dis = Math.sqrt(xCount * xCount + yCount * yCount)
          if (height == 0) {
            return {
              result: false,
              x: 0,
              z: 0,
              msg: ''
            }
          }
          const tan = height / dis
          const angle = Math.atan(tan) * 180 / Math.PI
          let msg = height1 < height2 ? '上坡 ' : '下坡 '
          if (angle > 0 && angle <= 15) {
            msg = msg + ''
          } else if (angle > 15 && angle <= 45) {
            // msg = msg + '坡度较陡'
          } else {
            // msg = msg + '坡度非常陡'
          }
          return {
            result: true,
            x: (first.x + next.x) / 2.0,
            z: (first.z + next.z) / 2.0,
            msg: msg
          }
        }

        function deletePoint(path) {
          if (path.length > 2) {
            const newPath = []
            const storeIndex = {}
            for (let i = 0; i < path.length - 3; i++) {
              const p1 = path[i]
              let count = 0
              for (let j = i + 1; j < path.length - 2; j++) {
                const p2 = path[j]
                const p3 = path[j + 1]
                if (p1.x == p2.x) {
                  if (p2.x == p3.x) {
                    // 都是垂直x轴
                    if (p1.inMapId != p2.inMapId) {
                      i = i + count
                      break
                    } else {
                      storeIndex[j + ''] = 1
                      count = count + 1
                    }
                  } else {
                    i = i + count
                    break
                  }
                } else {
                  // 求出斜率
                  const k1 = (p2.z - p1.z) / (p2.x - p1.x)
                  const k2 = (p3.z - p2.z) / (p3.x - p2.x)
                  if (k1 == k2) {
                    if (p1.inMapId != p2.inMapId) {
                      i = i + count
                      break
                    } else {
                      storeIndex[j + ''] = 1
                      count = count + 1
                    }
                  } else {
                    i = i + count
                    break
                  }
                }
              }
            }
            for (let i = 0; i < path.length; i++) {
              if (m.jsonKeyIsExist(storeIndex, [i + '']) == false) {
                const p = path[i]
                newPath.push(p)
              }
            }
            return newPath
          } else {
            return path
          }
        }

        function judgePoInfo(poInfo, linkWayInfo, mapDataInfo, po) {
          function findPoInfo(link, dataInfo, existInfo) {
            const linkPoint = link['linkPoint']
            for (const key in linkPoint) {
              const info = dataInfo[key]
              if (info.type == leadPointType.po && (link.x != info.x || link.y != info.y)) {
                const key1 = link.id + info.id
                const key2 = info.id + link.id
                if (m.jsonKeyIsExist(existInfo, [link.mapid]) && m.jsonKeyIsExist(existInfo[link.mapid], [key1,
                  key2
                ])) {
                  continue
                }
                const maxX = m.findMax([link.x, info.x])
                const minX = m.findMin([link.x, info.x])
                const maxZ = m.findMax([link.y, info.y])
                const minZ = m.findMin([link.y, info.y])
                const leftUp = {
                  x: minX,
                  z: minZ
                }
                const rightDown = {
                  x: maxX,
                  z: maxZ
                }
                if (leftUp.x == rightDown.x) {
                  leftUp.x = leftUp.x - Math.abs(parseFloat(link.W / 2.0))
                  rightDown.x = rightDown.x + Math.abs(parseFloat(link.W / 2.0))
                } else if (leftUp.z == rightDown.z) {
                  leftUp.z = leftUp.z - Math.abs(parseFloat(link.H / 2.0))
                  rightDown.z = rightDown.z + Math.abs(parseFloat(link.H / 2.0))
                } else {
                  leftUp.x = leftUp.x - Math.abs(parseFloat(link.W / 2.0))
                  rightDown.x = rightDown.x + Math.abs(parseFloat(link.W / 2.0))
                  leftUp.z = leftUp.z - Math.abs(parseFloat(link.H / 2.0))
                  rightDown.z = rightDown.z + Math.abs(parseFloat(link.H / 2.0))
                }
                const paths = linkWayInfo[link.mapid]
                for (let l = 0; l < paths.length; l++) {
                  const path = paths[l].path
                  for (let j = 0; j < path.length - 1; j++) {
                    const p1 = path[j]
                    const p2 = path[j + 1]
                    const isCorss = m.judgeIsCorssWall([{
                      x: p1.x,
                      z: p1.z
                    }, {
                      x: p2.x,
                      z: p2.z
                    }], {
                      areas: [
                        [leftUp.x, 0, leftUp.z],
                        [leftUp.x, 0, rightDown.z],
                        [rightDown.x, 0, rightDown.z],
                        [rightDown.x, 0, leftUp.z]
                      ]
                    })
                    if (isCorss) {
                      // 经过该坡度区域
                      const copyLink = link
                      copyLink.z = copyLink.y
                      const copyInfo = info
                      copyInfo.z = copyInfo.y

                      const goX = p1.x - p2.x
                      const goZ = p1.z - p2.z
                      const go = Math.abs(goX) > Math.abs(goZ) ? {
                        x: goX / Math.abs(goX),
                        z: 0
                      } : {
                        x: 0,
                        z: goZ / Math.abs(goZ)
                      }
                      let first = copyLink
                      let next = copyInfo
                      if (go.x == 0) {
                        if (go.z > 0) {
                          first = copyLink.z > copyInfo.z ? copyInfo : copyLink
                          next = copyLink.z > copyInfo.z ? copyLink : copyInfo
                        } else {
                          next = copyLink.z > copyInfo.z ? copyInfo : copyLink
                          first = copyLink.z > copyInfo.z ? copyLink : copyInfo
                        }
                      } else {
                        if (go.x > 0) {
                          first = copyLink.x > copyInfo.x ? copyInfo : copyLink
                          next = copyLink.x > copyInfo.x ? copyLink : copyInfo
                        } else {
                          next = copyLink.x > copyInfo.x ? copyInfo : copyLink
                          first = copyLink.x > copyInfo.x ? copyLink : copyInfo
                        }
                      }

                      const poMsg = getPoMsg(first, next)
                      if (poMsg.result == true) {
                        const correct = m.setPointToLine(poMsg.x, poMsg.z, [{
                          x: p1.x,
                          z: p1.z
                        }, {
                          x: p2.x,
                          z: p2.z
                        }])
                        poMsg.z = correct.z
                        poMsg.x = correct.x
                        if (m.jsonKeyIsExist(poInfo, [link.mapid])) {
                          const poList = poInfo[link.mapid]
                          let isExist = false
                          for (let k = 0; k < poList.length; k++) {
                            const po = poList[k]
                            if (po.x == poMsg.x && po.z == poMsg.z) {
                              isExist = true
                              break
                            }
                          }
                          if (isExist == false) {
                            poList.push(poMsg)
                          }
                        } else {
                          const poList = []
                          poList.push(poMsg)
                          poInfo[link.mapid] = poList
                        }
                      }
                      let record = {}
                      if (m.jsonKeyIsExist(existInfo, [link.mapid])) {
                        record = existInfo[link.mapid]
                      } else {
                        existInfo[link.mapid] = record
                      }
                      const key = link.id + info.id
                      record[key] = 1
                      break
                    }
                  }
                }
              }
            }
          }

          // 防止丢失坡度信息
          const existInfo = {}
          for (const mapid in linkWayInfo) {
            if (m.jsonKeyIsExist(po, [mapid])) {
              const mapPo = po[mapid]
              const dataInfo = mapDataInfo[mapid]
              for (const dataKey in mapPo) {
                const link = dataInfo[dataKey]
                findPoInfo(link, dataInfo, existInfo)
              }
            }
          }
        }

        function judgePath(indexs, mapOrder) {
          function pointIsSame(point1, point2) {
            if (point1.x == point2.x && point1.z == point2.z) {
              return true
            }
            return false
          }

          function startJudge(path, isStart, mapid, index) {
            if (path.length <= 2) {
              return
            }
            const mapInfo = m.mapInfo[mapid]
            const mapJson = mapInfo['mapJson']
            const zeroRoom = mapInfo['zeroRoom']
            const p1 = path[isStart == true ? path.length - 1 : 0]
            const p2 = path[isStart == true ? path.length - 2 : 1]
            const p3 = path[isStart == true ? path.length - 3 : 2]

            if (p1.room.areaid == zeroRoom['areaid'] || (p1.room.color == zeroRoom['color'] && p1.room.height ==
                zeroRoom['height'])) {

            } else {
              return
            }

            const final = {
              x: p2.x,
              z: p2.z,
              addNew: false
            }
            const p1p2Dis = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
            const p2p3Dis = Math.sqrt((p3.x - p2.x) * (p3.x - p2.x) + (p3.z - p2.z) * (p3.z - p2.z))

            const direction1 = parseFloat(((p2.x - p1.x) / p1p2Dis).toFixed(2))
            const direction2 = parseFloat(((p2.z - p1.z) / p1p2Dis).toFixed(2))

            const direction3 = parseFloat(((p3.x - p2.x) / p2p3Dis).toFixed(2))
            const direction4 = parseFloat(((p3.z - p2.z) / p2p3Dis).toFixed(2))

            let minDis = 65535
            const scale = isStart == true ? 1 : -1

            for (let i = 0; i < 4; i++) {
              let result = {
                result: false
              }
              if (i < 2) {
                result = m.twoLineXiangjiaoPoint(p1.x, p1.z, p1.x + (i != 0 ? ((direction1 <= 0) ? -10 * scale
                  : 10 * scale) : 0), p1.z + (i == 0 ? ((direction2 <= 0) ? -10 * scale : 10 * scale) : 0), p2
                  .x, p2.z, p3.x, p3.z, false)
              } else if (i < 4) {
                result = m.twoLineXiangjiaoPoint(p2.x, p2.z, p2.x + (i != 2 ? ((direction3 <= 0) ? -10 * scale
                  : 10) : 0), p2.z + (i == 2 ? ((direction4 <= 0) ? -10 * scale : 10) : 0), p1.x, p1.z, p1.x +
                  (i == 2 ? 10 * scale : 0), p1.z + (i != 2 ? 10 * scale : 0), false)
              }
              if (result.result == true) {
                let checkErr = true
                for (let j = 0; j < mapJson.length; j++) {
                  const room = mapJson[j]
                  const isCorssWall1 = m.judgeIsCorssWall([{
                    x: p1.x,
                    z: p1.z
                  }, {
                    x: result.x,
                    z: result.y
                  }], room)
                  const isCorssWall2 = i < 2 ? m.judgeIsCorssWall([{
                    x: p3.x,
                    z: p3.z
                  }, {
                    x: result.x,
                    z: result.y
                  }], room) : m.judgeIsCorssWall([{
                    x: p2.x,
                    z: p2.z
                  }, {
                    x: result.x,
                    z: result.y
                  }], room)
                  if (isCorssWall1 || isCorssWall2) {
                    // 穿墙了 判断穿墙的room是否和zero的房间的颜色一致 高度一致 一致则认为可以穿墙
                    if (zeroRoom['height'] == room['height'] && zeroRoom['color'] == room['color'] && zeroRoom[
                      'areaid'] != room['areaid']) {

                    } else {
                      checkErr = false
                      break
                    }
                  }
                }
                if (checkErr == true) {
                  let add = false
                  if (i < 2) {
                    add = false
                  } else {
                    const xCount1 = result.x - p2.x
                    const zCount1 = result.y - p2.z
                    const xCount2 = p3.x - p2.x
                    const zCount2 = p3.z - p2.z
                    if ((xCount1 == 0 && xCount2 == 0) || zCount2 == 0 && zCount1 == 0) {
                      add = false
                    } else {
                      if (xCount1 == 0 || xCount2 == 0) {
                        add = true
                      } else {
                        const k1 = (Math.abs(zCount1 / xCount1)).toFixed(2)
                        const k2 = (Math.abs(zCount2 / xCount2)).toFixed(2)
                        if (k1 == k2) {
                          add = false
                        } else {
                          add = true
                        }
                      }
                    }
                  }
                  if (add == false) {
                    const dis1 = Math.sqrt((p1.x - result.x) * (p1.x - result.x) + (p1.z - result.y) * (p1.z -
                      result.y))
                    const dis2 = Math.sqrt((p3.x - result.x) * (p3.x - result.x) + (p3.z - result.y) * (p3.z -
                      result.y))
                    if (dis2 + dis1 <= minDis) {
                      minDis = dis2 + dis1
                      final.x = result.x
                      final.z = result.y
                      final.addNew = add
                    }
                  } else {
                    const dis1 = Math.sqrt((p1.x - result.x) * (p1.x - result.x) + (p1.z - result.y) * (p1.z -
                      result.y))
                    const dis2 = Math.sqrt((p2.x - result.x) * (p2.x - result.x) + (p2.z - result.y) * (p2.z -
                      result.y))
                    const dis3 = Math.sqrt((p3.x - p2.x) * (p3.x - p2.x) + (p3.z - p2.z) * (p3.z - p2.z))
                    if (dis2 + dis1 + dis3 <= minDis) {
                      minDis = dis2 + dis1 + dis3
                      final.x = result.x
                      final.z = result.y
                      final.addNew = add
                    }
                  }
                }
              }
            }
            if (final.addNew == false) {
              p2.x = final.x
              p2.z = final.z
            } else {
              if (pointIsSame(final, p1) == false && pointIsSame(final, p2) == false && pointIsSame(final,
                p2) == false) {
                // 添加新的点
                const newP = {
                  x: final.x,
                  y: 0,
                  z: final.z,
                  mapid: mapid,
                  type: leadPointType.normal,
                  id: 'newPoint' + isStart ? '-1' : '-0',
                  height: 0,
                  url: ''
                }
                path.splice(isStart == true ? path.length - 1 : 1, 0, newP)
                path = deletePoint(path)
              }
            }
            let dis = 0
            for (let i = 0; i < path.length - 2; i++) {
              const p1 = path[i]
              const p2 = path[i + 1]
              dis = dis + Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
            }
            indexs[mapid][index]['dis'] = dis
          }

          if (mapOrder.length == 0) {
            return
          }
          // 只对起点和终点的路线纠正
          const firstOrder = mapOrder[mapOrder.length - 1]
          const endOrder = mapOrder[0]
          startJudge(indexs[firstOrder.mapid][firstOrder.index]['path'], true, firstOrder.mapid, firstOrder
            .index)
          startJudge(indexs[endOrder.mapid][endOrder.index]['path'], false, endOrder.mapid, endOrder.index)
        }

        const infinite = 65535

        let data = null
        for (const mapid in dataInfo) {
          const info = dataInfo[mapid]
          if (m.jsonKeyIsExist(info, [startid])) {
            data = info[startid]
            break
          }
        }
        if (data == null) {
          console.log('没有该起点')
          return {
            isLink: false,
            indexs: [],
            info: []
          }
        }

        let endData = null
        for (const mapid in dataInfo) {
          const info = dataInfo[mapid]
          if (m.jsonKeyIsExist(info, [endid])) {
            endData = info[endid]
            break
          }
        }

        if (endData == null) {
          console.log('没有该起点')
          return {
            isLink: false,
            indexs: [],
            info: []
          }
        }

        const start = data['matrix']
        const end = endData['matrix']
        const Pathmatirx = [] // 用于存储最短路径下标的数组，下标为各个顶点，值为下标顶点的前驱顶点
        const ShortPathTable = [] // 用于存储到各点最短路径的权值和
        let k, min
        const final = []

        for (let v = 0; v < G.numVertexes; v++) {
          final[v] = 0
          ShortPathTable[v] = G.arc[start].arr[v]
          Pathmatirx[v] = infinite
        }
        ShortPathTable[start] = 0
        final[start] = 1
        Pathmatirx[start] = -1

        for (let v = 0; v < G.numVertexes; v++) { // 初始化数据
          min = infinite
          for (let w = 0; w < G.numVertexes; w++) {
            if (!final[w] && G.arc[start].arr[w] < min) {
              Pathmatirx[w] = start
            }
            // 寻找离V0最近的顶点
            if (!final[w] && ShortPathTable[w] < min) {
              k = w
              min = ShortPathTable[w] // w 顶点离V0顶点更近
            }
          }
          if (typeof (k) == 'undefined') {
            console.log('没有链接点')
            return {
              isLink: false,
              indexs: [],
              info: []
            }
          }
          final[k] = 1 // 将目前找到的最近的顶点置位1
          for (let w = 0; w < G.numVertexes; w++) { // 修正当前最短路径及距离
            const dis_KW = G.arc[k].arr[w]
            const dis = ShortPathTable[w]
            if (!final[w] && (min + dis_KW < dis)) { // 说明找到了更短的路径，修改Pathmatirx[w]和ShortPathTable[w]
              ShortPathTable[w] = min + G.arc[k].arr[w]
              Pathmatirx[w] = k
            }
          }
        }

        function returnPointType(info) {
          let type = leadPointType.normal
          if (analysis.jsonKeyIsExist(info, ['type'])) {
            type = info['type']
          }
          return type
        }

        function returnPoint(info) {
          const type = returnPointType(info)
          const mapid = info['mapid']
          const id = info['id']
          let height = 0
          if (analysis.jsonKeyIsExist(info, ['pHeight'])) {
            height = info['pHeight']
          }
          let imgs = []
          if (analysis.jsonKeyIsExist(info, ['imgs']) && info['imgs'].length > 0) {
            imgs = info['imgs']
          }
          const p = {
            x: parseFloat(info.x.toFixed(2)),
            y: 2,
            z: parseFloat(info.y.toFixed(2)),
            mapid: mapid,
            type: type,
            id: id,
            height: height,
            url: '',
            room: analysis.jsonKeyIsExist(info, ['room']) ? info.room : {
              areas: [],
              areaid: '',
              color: '',
              height: 0
            },
            inMapId: info.inMapId
          }
          if (imgs.length > 0) {
            p['imgs'] = imgs
          }
          return p
        }

        // 对终点进行校正
        function judgeEndPoint(endPoint, pointTwo, pointThree, indexs) {
          if (endPoint.x == pointTwo.x || endPoint.z == pointTwo.z) {
            return
          }
          const res = findMapInfoBlock(endPoint.mapid)
          const mapInfo = res.mapJson
          if (typeof mapInfo == 'undefined' || mapInfo == null || mapInfo.length == 0 || typeof endPoint.room ==
            'undefined' || endPoint.room == null) {
            return
          }
          const zeroInfo = mapInfo[0]
          const roomId = endPoint.room['areaid']
          if (zeroInfo['areaid'] != roomId) {
            return
          }
          const t = analysis.closestPointToPoint(pointTwo, pointThree, endPoint)
          const p0 = {
            x: t.x,
            z: t.z,
            newPoint: false
          }
          const p1 = {
            x: pointTwo.x,
            z: pointThree.z,
            newPoint: true,
            beforeStart: false,
            newx: pointTwo.x,
            newz: endPoint.z
          }
          const p2 = {
            x: pointThree.x,
            z: pointTwo.z,
            newPoint: true,
            beforeStart: false,
            newx: endPoint.x,
            newz: pointTwo.z
          }
          const p3 = {
            x: (endPoint.x + pointTwo.x) / 2.0,
            z: pointTwo.z,
            newPoint: true,
            beforeStart: true,
            newx: pointTwo.x,
            newz: pointTwo.z
          }
          const p4 = {
            x: pointTwo.x,
            z: (endPoint.z + pointTwo.z) / 2.0,
            newPoint: true,
            beforeStart: true,
            newx: pointTwo.x,
            newz: pointTwo.z
          }
          const p5 = {
            x: pointTwo.x,
            z: endPoint.z,
            newPoint: false
          }
          const p6 = {
            x: endPoint.x,
            z: pointTwo.z,
            newPoint: false
          }

          // 校正的选择
          const judges = [p0, p1, p2, p3, p4, p5, p6]
          let selectIndex = -1
          for (let i = 0; i < judges.length; i++) {
            const judge = judges[i]
            const lineList = []
            if (judge.newPoint == false) {
              lineList.push([endPoint, judge])
              lineList.push([judge, pointThree])
            } else {
              if (judge.beforeStart) {
                lineList.push([endPoint, judge])
                lineList.push([judge, {
                  x: judge.newx,
                  z: judge.newz
                }])
                lineList.push([{
                  x: judge.newx,
                  z: judge.newz
                }, pointThree])
              } else {
                lineList.push([endPoint, {
                  x: judge.newx,
                  z: judge.newz
                }])
                lineList.push([{
                  x: judge.newx,
                  z: judge.newz
                }, judge])
                lineList.push([judge, pointThree])
              }
            }

            let result = true
            for (let j = 1; j < mapInfo.length; j++) {
              const roomInfo = mapInfo[j]
              for (let l = 0; l < lineList.length; l++) {
                const line = lineList[l]
                const res = analysis.judgeIsCorssWall(line, roomInfo)
                if (res) {
                  result = false
                  break
                }
              }
            }
            if (result) {
              selectIndex = i
              break
            }
          }
          if (selectIndex < 0) {
            return
          }
          const info = judges[selectIndex]
          if (info.newPoint == false) {
            pointTwo.x = info.x
            pointTwo.z = info.z
          } else {
            pointTwo.x = info.newx
            pointTwo.z = info.newz
            const newP = {
              x: info.x,
              y: 2,
              z: info.z,
              mapid: endPoint.mapid,
              type: leadPointType.normal,
              id: 'newPoint' + info.beforeStart ? '-1' : '-0',
              height: 0,
              url: ''
            }
            if (info.beforeStart) {
              indexs.splice(1, 0, newP)
            } else {
              indexs.splice(2, 0, newP)
            }
          }
        }

        // 对起点进行校正
        function judgeStartPoint(startPoint, pointTwo, pointThree, indexs) {
          if (startPoint.x == pointTwo.x || startPoint.z == pointTwo.z) {
            return
          }
          const res = findMapInfoBlock(startPoint.mapid)
          const mapInfo = res.mapJson
          if (typeof mapInfo == 'undefined' || mapInfo == null || mapInfo.length == 0 || typeof startPoint
            .room == 'undefined' || startPoint.room == null) {
            return
          }
          const zeroInfo = mapInfo[0]
          const roomId = startPoint.room['areaid']
          if (zeroInfo['areaid'] != roomId) {
            return
          }
          const t = analysis.closestPointToPoint(pointTwo, pointThree, startPoint)
          const p0 = {
            x: t.x,
            z: t.z,
            newPoint: false
          }

          const p1 = {
            x: pointTwo.x,
            z: pointThree.z,
            newPoint: true,
            beforeStart: false,
            newx: pointTwo.x,
            newz: startPoint.z
          }
          const p2 = {
            x: pointThree.x,
            z: pointTwo.z,
            newPoint: true,
            beforeStart: false,
            newx: startPoint.x,
            newz: pointTwo.z
          }
          const p3 = {
            x: (startPoint.x + pointTwo.x) / 2.0,
            z: pointTwo.z,
            newPoint: true,
            beforeStart: true,
            newx: pointTwo.x,
            newz: pointTwo.z
          }
          const p4 = {
            x: pointTwo.x,
            z: (startPoint.z + pointTwo.z) / 2.0,
            newPoint: true,
            beforeStart: true,
            newx: pointTwo.x,
            newz: pointTwo.z
          }
          const p5 = {
            x: pointTwo.x,
            z: startPoint.z,
            newPoint: false
          }
          const p6 = {
            x: startPoint.x,
            z: pointTwo.z,
            newPoint: false
          }

          // 校正的选择
          const judges = [p0, p1, p2, p3, p4, p5, p6]
          let selectIndex = -1
          for (let i = 0; i < judges.length; i++) {
            const judge = judges[i]
            const lineList = []
            if (judge.newPoint == false) {
              lineList.push([startPoint, judge])
              lineList.push([judge, pointThree])
            } else {
              if (judge.beforeStart) {
                lineList.push([startPoint, judge])
                lineList.push([judge, {
                  x: judge.newx,
                  z: judge.newz
                }])
                lineList.push([{
                  x: judge.newx,
                  z: judge.newz
                }, pointThree])
              } else {
                lineList.push([startPoint, {
                  x: judge.newx,
                  z: judge.newz
                }])
                lineList.push([{
                  x: judge.newx,
                  z: judge.newz
                }, judge])
                lineList.push([judge, pointThree])
              }
            }

            let result = true
            for (let j = 1; j < mapInfo.length; j++) {
              const roomInfo = mapInfo[j]
              for (let l = 0; l < lineList.length; l++) {
                const line = lineList[l]
                const res = analysis.judgeIsCorssWall(line, roomInfo)
                if (res) {
                  result = false
                  break
                }
              }
            }
            if (result) {
              selectIndex = i
              break
            }
          }
          if (selectIndex < 0) {
            return
          }
          const info = judges[selectIndex]
          if (info.newPoint == false) {
            pointTwo.x = info.x
            pointTwo.z = info.z
          } else {
            pointTwo.x = info.newx
            pointTwo.z = info.newz
            const newP = {
              x: info.x,
              y: 2,
              z: info.z,
              mapid: startPoint.mapid,
              type: leadPointType.normal,
              id: 'newPoint' + info.beforeStart ? '-1' : '-0',
              height: 0,
              url: ''
            }
            if (info.beforeStart) {
              indexs.push(newP)
            } else {
              indexs.splice(indexs.length - 1, 0, newP)
            }
          }
        }

        // 判断实景图
        function findRealtiyImage(endP, startP) {
          if (analysis.jsonKeyIsExist(endP, ['imgs']) == false) {
            return
          }
          const imgs = endP['imgs']
          const v = new THREE.Vector3(endP.x - startP.x, 0, endP.z - startP.z)
          for (let i = 0; i < imgs.length; i++) {
            const info = imgs[i]
            const imageV = new THREE.Vector3(endP.x - info.x, 0, endP.z - info.z)
            const angle = v.angleTo(imageV)
            if (angle <= Math.PI / 4.0) {
              endP['img'] = {
                url: info.url,
                title: info.title
              }
              break
            }
          }
        }

        function leadPathAtSameMap(indexs, point) {
          if (point.type == leadPointType.futi || point.type == leadPointType.dianti || point.type ==
            leadPointType.louti) {
            point['isInRoom'] = false
          }

          if (indexs.length >= 1) {
            const lastP = indexs[indexs.length - 1]
            if (lastP.x == point.x && lastP.z == point.z) {
              // 同一个点
            } else {
              findRealtiyImage(lastP, point)
              if (point.type == leadPointType.start && indexs.length >= 2) {
                const p = indexs[indexs.length - 2]
                judgeStartPoint(point, lastP, p, indexs)
              }
              indexs.push(point)
            }
          } else {
            indexs.push(point)
          }
        }

        // 开始分楼层
        function leadPathGoFloor(indexs, linkWayInfo, linkMap, info, mapOrdList, idOrder) {
          if (indexs.length > 1) {
            // 大于1的时候才返回
            const newIndexs = indexs
            if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
              const arr = linkWayInfo[linkMap]
              arr.push({
                path: newIndexs,
                dis: 0,
                start: newIndexs[0],
                end: newIndexs[newIndexs.length - 1],
                index: arr.length,
                orderIndex: mapOrdList.length
              })
            } else {
              linkWayInfo[linkMap] = []
              linkWayInfo[linkMap].push({
                index: 0,
                orderIndex: mapOrdList.length,
                path: newIndexs,
                dis: 0,
                start: newIndexs[0],
                end: newIndexs[newIndexs.length - 1]
              })
            }
            if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
              let from = ''
              let to = ''
              if (mapOrdList.length == 0) {
                if (analysis.jsonKeyIsExist(info, ['link'])) {
                  from = info['link']
                }
              } else {
                const lastOrder = mapOrdList[mapOrdList.length - 1]
                to = lastOrder.from
                if (analysis.jsonKeyIsExist(info, ['link'])) {
                  from = info['link']
                }
              }
              mapOrdList.push({
                mapid: linkMap,
                index: linkWayInfo[linkMap].length - 1,
                id: linkMap + '-' + (linkWayInfo[linkMap].length - 1),
                from: from,
                to: to,
                idOrder: idOrder
              })
              return {
                result: true
              }
            }
          }
          return {
            result: false
          }
        }

        // 终点
        function endPath(indexs, linkWayInfo, json1, mapOrdList) {
          // 楼层终止 对部分路径进行优化
          if (indexs.length >= 3) {
            const endPoint = indexs[0]
            const p1 = indexs[1]
            const p2 = indexs[2]
            judgeEndPoint(endPoint, p1, p2, indexs)
          }
          const newIndexs = indexs
          if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
            const arr = linkWayInfo[linkMap]
            arr.push({
              index: arr.length,
              orderIndex: mapOrdList.length,
              path: newIndexs,
              dis: 0,
              start: newIndexs[0],
              end: newIndexs[newIndexs.length - 1]
            })
          } else {
            linkWayInfo[linkMap] = []
            linkWayInfo[linkMap].push({
              index: 0,
              orderIndex: mapOrdList.length,
              path: newIndexs,
              dis: 0,
              start: newIndexs[0],
              end: newIndexs[newIndexs.length - 1]
            })
          }
          if (analysis.jsonKeyIsExist(linkWayInfo, [linkMap])) {
            let from = ''
            let to = ''
            if (mapOrdList.length == 0) {
              if (analysis.jsonKeyIsExist(json1, ['link'])) {
                from = json1['link']
              }
            } else {
              const lastOrder = mapOrdList[mapOrdList.length - 1]
              to = lastOrder.from
              if (analysis.jsonKeyIsExist(json1, ['link'])) {
                from = json1['link']
              }
            }
            mapOrdList.push({
              mapid: linkMap,
              index: linkWayInfo[linkMap].length - 1,
              id: linkMap + '-' + (linkWayInfo[linkMap].length - 1),
              from: from,
              to: to,
              idOrder: idOrder
            })
          }
        }

        // 查找线段在区域的位置
        function getAreasPoint(zoneInfo, startP, endP, zoneIdInfo) {
          let startList = []
          let endList = []
          const line = analysis.createLine(startP, endP)
          for (let i = 0; i < zoneInfo.length; i++) {
            const areaInfo = zoneInfo[i]
            const areas = areaInfo['areas']
            let scaleList = []
            scaleList.push({
              x: startP.x,
              y: startP.z,
              scale: 0
            })
            for (let j = 0; j < areas.length; j++) {
              const p = areas[j]
              const np = areas[(j + 1 >= areas.length ? 0 : j + 1)]
              const res = analysis.twoLineXiangjiaoPoint(p[0], p[2], np[0], np[2], startP.x, startP.z, endP.x,
                endP.z)
              if (res.result == false) {
                continue
              }
              const scale1 = analysis.updateLineAndclosestPointToPointParameter(line, res.x, 0, res.y, {
                x: p[0],
                y: 0,
                z: p[2]
              }, {
                x: np[0],
                y: 0,
                z: np[2]
              })
              const scale2 = analysis.updateLineAndclosestPointToPointParameter(line, res.x, 0, res.y, startP,
                endP)
              if (isNaN(scale2) || isNaN(scale1)) {
                continue
              }
              if (scale1 < 0 || scale1 > 1 || scale2 < 0 || scale2 > 1) {
                continue
              }
              scaleList.push({
                x: res.x,
                y: res.y,
                scale: scale1
              })
            }

            scaleList.push({
              x: endP.x,
              y: endP.z,
              scale: 1
            })

            // 按照scale由小到大排序
            scaleList = scaleList.sort((a, b) => {
              return a.scale - b.scale
            })
            for (let i = 0; i < scaleList.length - 1; i++) {
              const info = scaleList[i]
              const nextInfo = scaleList[i + 1]
              const center = {
                x: (info.x + nextInfo.x) / 2.0,
                y: (info.y + nextInfo.y) / 2.0
              }
              if (m.isInPolygon([center.x, 0, center.y], areas)) {
                startList.push({
                  title: areaInfo['title'],
                  id: areaInfo['areaid'],
                  start: info.scale,
                  end: nextInfo.scale,
                  startP: {
                    x: info.x,
                    y: 2,
                    z: info.y
                  },
                  endP: {
                    x: nextInfo.x,
                    y: 2,
                    z: nextInfo.y
                  }
                })
                endList.push({
                  title: areaInfo['title'],
                  id: areaInfo['areaid'],
                  start: info.scale,
                  end: nextInfo.scale,
                  startP: {
                    x: info.x,
                    y: 2,
                    z: info.y
                  },
                  endP: {
                    x: nextInfo.x,
                    y: 2,
                    z: nextInfo.y
                  }
                })
                i = i + 1
                zoneIdInfo[areaInfo['areaid']] = '1'
              }
            }
          }

          // 按照scale由小到大排序
          endList = endList.sort((a, b) => {
            return a.start - b.start
          })
          startList = startList.sort((a, b) => {
            return a.start - b.start
          })
          return {
            start: startList,
            end: endList
          }
        }

        function updatePathInfo(linkWayInfo, zoneInfo) {
          const zoneIdInfo = {} // 记录所在区域
          for (const mapid in linkWayInfo) {
            const pathInfos = linkWayInfo[mapid]
            for (let i = 0; i < pathInfos.length; i++) {
              const info = pathInfos[i]
              const path = info.path
              let dis = 0 // 计算路径距离
              let zoneList = []
              const paths = []
              if (m.jsonKeyIsExist(zoneInfo, [mapid])) {
                zoneList = zoneInfo[mapid]
              }

              for (let j = 0; j < path.length - 1; j++) {
                const p1 = path[j]
                const p2 = path[j + 1]
                dis = dis + Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z))
                const res = getAreasPoint(zoneList, p1, p2, zoneIdInfo)
                paths.push(res.end)
              }

              info.dis = dis
              info.pathScale = paths
            }
          }
          return zoneIdInfo
        }

        let indexs = []
        let temp = end
        let linkMap = ''
        const linkWayInfo = {}
        const poInfo = {}
        const mapOrdList = [] // 走地图的顺序
        let idOrder = {}
        while (temp != -1) {
          const l = G.arc[temp]
          const json1 = dataInfo[l.mapid][l.id]
          const p1 = returnPoint(json1)
          const mapid = p1.mapid
          idOrder[json1.id] = {
            mapid: mapid,
            id: json1.id
          }
          if (linkMap == '') {
            // 开始的信息
            linkMap = mapid
          }
          if (linkMap != mapid) {
            // 开始分楼层
            const res = leadPathGoFloor(indexs, linkWayInfo, linkMap, json1, mapOrdList, idOrder)
            if (res.result) {
              idOrder = {}
            }
            indexs = []
            linkMap = mapid
          }
          leadPathAtSameMap(indexs, p1)
          temp = Pathmatirx[temp]
          if (temp == -1) {
            // 楼层终止 对部分路径进行优化
            endPath(indexs, linkWayInfo, json1, mapOrdList)
          }
          if (temp == infinite) {
            return {
              isLink: false,
              indexs: {},
              poInfo: {},
              mapOrder: [],
              zoneIdInfo: {}
            }
          }
        }
        judgePoInfo(poInfo, linkWayInfo, dataInfo, po)
        const zoneIdInfo = updatePathInfo(linkWayInfo, zoneInfo)
        return {
          isLink: true,
          indexs: linkWayInfo,
          poInfo: poInfo,
          mapOrder: mapOrdList,
          zoneIdInfo: zoneIdInfo
        }
      }

      m.updateLineAndclosestPointToPointParameter = function(line, x, y, z, startP, endP) {
        line.start = new THREE.Vector3(startP.x, startP.y, startP.z)
        line.end = new THREE.Vector3(endP.x, endP.y, endP.z)
        const target = new THREE.Vector3(x, y, z)
        const scale = line.closestPointToPointParameter(target, false)
        return parseFloat(scale.toFixed(2))
      }

      // 两个直线的交点
      m.getLinesPoint = function(k1, b1, k2, b2) {
        if (k1 == k2) {
          return []
        }
        if ((k1 == 'Infinity' || k1 == '-Infinity') && (k2 == 'Infinity' || k2 == '-Infinity')) {
          return []
        } else if (k1 == 'Infinity' || k1 == '-Infinity') {
          const x = b1
          const y = k2 * x + b2
          return [x, 5, y]
        } else if (k2 == 'Infinity' || k2 == '-Infinity') {
          const x = b2
          const y = k1 * x + b1
          return [x, 5, y]
        } else {
          const x = (b2 - b1) / (k1 - k2)
          const y = k1 * x + b1
          return [x, 5, y]
        }
      }

      m.setPointToLine = function(x, z, path) {
        // 先找到x z 直连到路线的最短的距离， 然后将其纠正到路线上
        let minDis = 65535
        let minIndex = -1 // 纠正索引
        let minJiaodianX = x // 纠正位置x
        let minJiaodianZ = z // 纠正位置z
        const isCloseToEnd = false
        for (let i = path.length - 1; i > 0; i--) {
          let jiaodianDis = 65535 // 纠正后的点距离线段两头最近的距离
          const p1 = path[i]
          const p2 = path[i - 1]
          const x1 = p1.x
          const z1 = p1.z
          const x2 = p2.x
          const z2 = p2.z
          let jiaodianX = 0
          let jiaodianZ = 0
          // 找出路线一段 计算该x z 到该线段的垂直距离
          if (x1 == x2) {
            // 该线段平行Z轴
            jiaodianX = x1
            jiaodianZ = z
            jiaodianDis = Math.abs(z - z1) > Math.abs(z - z2) ? Math.abs(z - z2) : Math.abs(z - z1)
          } else if (z1 == z2) {
            // 该线段平行X轴
            jiaodianZ = z1
            jiaodianX = x
            jiaodianDis = Math.abs(x - x1) > Math.abs(x - x2) ? Math.abs(x - x2) : Math.abs(x - x1)
          } else {
            const xspace = x2 - x1
            const zspace = z2 - z1
            const k1 = zspace / xspace
            const b1 = z1 - k1 * x1
            const k2 = -1 / k1
            const b2 = z - k2 * x
            const jiaodian = m.getLinesPoint(k1, b1, k2, b2)
            if (jiaodian.length == 0) {
              continue
            }
            jiaodianX = jiaodian[0]
            jiaodianZ = jiaodian[2]
            const dis1 = Math.sqrt((jiaodianX - x1) * (jiaodianX - x1) + (jiaodianZ - z1) * (jiaodianZ - z1))
            const dis2 = Math.sqrt((jiaodianX - x2) * (jiaodianX - x2) + (jiaodianZ - z2) * (jiaodianZ - z2))
            jiaodianDis = dis1 > dis2 ? dis2 : dis1
          }
          const xIsOut = (jiaodianX - x1) * (jiaodianX - x2) > 0 // 是否超出x
          const zIsOut = (jiaodianZ - z1) * (jiaodianZ - z2) > 0 // 是否超出z

          const xCount = jiaodianX - x
          const zCount = jiaodianZ - z
          let dis = Math.sqrt(xCount * xCount + zCount * zCount)
          if (xIsOut || zIsOut) {
            dis = dis + jiaodianDis
          }
          if (minDis > dis) {
            // 找到距离最近的线段
            minDis = dis
            minIndex = i
            minJiaodianX = jiaodianX
            minJiaodianZ = jiaodianZ
          }
        }
        return {
          isCloseToEnd: isCloseToEnd,
          x: minJiaodianX,
          z: minJiaodianZ,
          index: minIndex
        }
      }

      m.startFindWay = function(lead, startMapid, endMapid, leadTypes, mapidList, mapCheckInfo, startMapInfo,
        mapInfo) {
        function returnLeadTitle(type) {
          let leadStr = ''
          switch (type) {
            case leadPointType.futi:
              leadStr = 'futi'
              break
            case leadPointType.dianti:
              leadStr = 'dianti'
              break
            case leadPointType.louti:
              leadStr = 'louti'
              break
          }
          return leadStr
        }

        function jsonIsEmpty(info) {
          let empty = true
          for (const key in info) {
            empty = false
            break
          }
          return empty
        }

        let isSameMap = (startMapid == endMapid)
        const mapOrderInfo = {}
        const wayInfo = {}
        let type = leadPointType.dianti
        let noWay = false
        let count = 0
        let poInfo = {}
        m.mapInfo = mapCheckInfo
        for (let i = 0; i < leadTypes.length; i++) {
          const leadType = leadTypes[i]
          const infoKey = returnLeadTitle(leadType)
          const dataInfoResult = m.findWayByFloors(lead, startMapid, endMapid, leadType, mapidList)
          if (dataInfoResult.isLink == false) {
            continue
          }
          wayInfo[infoKey] = {}
          wayInfo[infoKey]['list'] = []
          type = leadType
          count = count + 1
          wayInfo[infoKey]['list'] = dataInfoResult.indexs
          wayInfo[infoKey]['po'] = dataInfoResult.poInfo
          wayInfo[infoKey]['order'] = dataInfoResult.mapOrder
          poInfo = dataInfoResult.poInfo
          mapInfo['leadType'][infoKey] = 1
          startMapInfo['leadType'][infoKey] = 1
          if (isSameMap == true) {
            // 为同一地图 查找 leadCubeInfo.leadPath 里面有几个地图id 一个地图id即在同一张地图上存在路径 多个id表示多条路径
            let idCount = 0
            for (const id in dataInfoResult.indexs) {
              idCount = idCount + 1
            }
            if (idCount >= 2) {
              isSameMap = false
            } else {
              break
            }
          }
          const mapOrder = dataInfoResult.mapOrder
          const arr = []
          for (let j = mapOrder.length - 1; j >= 0; j--) {
            const id = mapOrder[j].mapid
            const orderInfo = {
              mapName: '',
              mapid: id
            }
            arr.push(orderInfo)
          }
          mapOrderInfo[infoKey] = arr
        }

        if (count == 0) {
          noWay = true
        } else {
          let minDis = 65535
          for (const key in wayInfo) {
            const data = wayInfo[key]
            const list = data['list']
            let dis = 0
            for (const mapKey in list) {
              const mapList = list[mapKey]
              for (let i = 0; i < mapList.length; i++) {
                dis = mapList[i].dis + dis
              }
            }
            if (minDis >= dis && dis != 0) {
              minDis = dis
              type = key
              poInfo = data.po
            }
          }
        }
        return {
          result: !noWay,
          msg: '规划好路线',
          data: {
            mapid: startMapid,
            way: wayInfo,
            type: isSameMap == true ? '' : type,
            isSameMap: isSameMap,
            noWay: noWay,
            isPo: !jsonIsEmpty(poInfo),
            tuijian: '',
            mapOrderInfo: mapOrderInfo
          }
        }
      }
    })
    worker.call('startFindWay', [lead, startMapid, endMapid, leadTypes, mapidList, mapCheckInfo, startMapInfo,
      mapInfo]).then((res) => {
      callBack(res)
    })
  }

  matrixInfo.findInfoIndex = function(leadInfo, id) {
    for (const key in leadInfo) {
      if (key == id) {
        const info = leadInfo[key]
        return {
          result: true,
          index: info.matrix
        }
      }
    }
    return {
      result: false
    }
  }

  matrixInfo.findRoomCenterCouldNotLink = function(mapJson, mapLead) {
    return new Promise((resolve) => {
      const alertTexts = []
      for (let i = 1; i < mapJson.length; i++) {
        const room = mapJson[i]
        const roomId = room['areaid']
        const title = room['title']
        if (typeof roomId == 'undefined' || roomId == null || roomId == '') {
          continue
        }
        const polygon = analysis.getPolygonAreaCenter(room['areas'])
        for (const key in mapLead) {
          const lead = mapLead[key]
          const inMapId = lead['inMapId']
          if (typeof inMapId == 'undefined' || inMapId == null || inMapId == '') {
            continue
          }
          if (inMapId == roomId || title == inMapId) {
            if (analysis.isInPolygon([lead.x, 0, lead.y], room['areas']) == false) {
              continue
            }
            const isCorss = analysis.judgeIsCorssWall([{
              x: polygon[1],
              z: polygon[0]
            }, {
              x: lead.x,
              z: lead.y
            }], room)
            if (isCorss == true) {
              // 穿墙
              alertTexts.push({
                x: lead.x,
                z: lead.y,
                msg: 'WRANING'
              })
            }
          }
        }
      }
      resolve(alertTexts)
    })
  }

  matrixInfo.updateLinkFloor = function(config) {
    const dataInfo = config.dataInfo
    const leadInfo = config.leadInfo
    let should = false
    for (const mapid in dataInfo) {
      if (analysis.jsonKeyIsExist(leadInfo, [mapid]) == false) {
        continue
      }
      should = true
      const info = dataInfo[mapid]
      const lead = leadInfo[mapid]
      for (const id in info) {
        const itemInfo = info[id]
        if (analysis.jsonKeyIsExist(lead, [id]) == false) {
          continue
        }
        const itemLeadInfo = lead[id]
        if (analysis.jsonKeyIsExist(itemLeadInfo, ['floors']) == false) {
          continue
        }
        const floors = itemLeadInfo['floors']
        for (const itemMapid in itemInfo) {
          const findInfo = itemInfo[itemMapid]
          for (const findId in findInfo) {
            if (analysis.jsonKeyIsExist(floors, [itemMapid]) == false) {
              continue
            }
            const getId = floors[itemMapid].id
            if (getId == findId) {
              delete floors[itemMapid]
            }
          }
        }
      }
    }
    if (should == false) {
      return {
        result: false
      }
    }
    const matrix = this.getMatrix(leadInfo)
    return {
      result: true,
      matrix: matrix
    }
  }

  return matrixInfo
}
