import * as THREE from 'three/build/three.min.js'
import {
  createAnalaysisInfo
} from '../../analaysis/dataAnalaysisInfo'
import {
  createObjectAction
} from '../createObject'
import {
  createMatrixInfo
} from '../../analaysis/makeMatrix'


export function createLeadCubeManager() {
  const group = new THREE.Group()
  const create = createObjectAction()
  const analysis = createAnalaysisInfo() // 构造地图解析

  const matrix = createMatrixInfo() // 导航路线计算的js

  const leadCubeInfo = {
    normalColor: 0x0000ff,
    selectColor: 0xff0000,
    floorColor: 0xb206b0, // 楼梯的颜色
    evaluateColor: 0xffeaa5, // 电梯的颜色
    EscalatorColor: 0x52de97, // 扶梯的颜色
    poColor: 0xeec1ea // 坡度的颜色
  }

  const leadPointType = {
    louti: 1,
    dianti: 2,
    futi: 3,
    normal: 0,
    po: 99
  }


  const linkType = leadPointType.louti

  function returnTypeColor(type) {
    let color = leadCubeInfo.normalColor
    switch (type) {
      case leadPointType.louti:
        color = leadCubeInfo.floorColor
        break
      case leadPointType.dianti:
        color = leadCubeInfo.evaluateColor
        break
      case leadPointType.futi:
        color = leadCubeInfo.EscalatorColor
        break
      case leadPointType.po:
        color = leadCubeInfo.poColor
        break
      default:
        break
    }
    return color
  }

  let selectMeshList = []

  let cubeMeshList = []

  let lineMeshList = []

  group.showLeadCube = function (config) {
    const pointInfo = config.pointInfo
    const textList = config.textList
    const mapid = config.mapid
    const floorHeight = config.floorHeight;
    let pointCount = 0
    let lineCount = 0

    const position = {} // 检测连接点是否重复
    for (const key in pointInfo) {
      const info = pointInfo[key]
      const x = info['x']
      const y = info['y']
      const storeKey = x + '-' + y
      if (analysis.jsonKeyIsExist(position, [storeKey])) {
        // 出现重复的点
        textList.push({
          x: x,
          y: floorHeight,
          z: y,
          title: '存在重复点',
          type: 'textList',
          id: storeKey,
          textColor: '#ffffff',
          bgColor: '#ff0000',
        })
        continue
      } else {
        position[storeKey] = '1'
      }

      const setting = {
        position: {
          x: parseFloat(x),
          y: floorHeight,
          z: parseFloat(y)
        },
        size: 3,
        color: returnTypeColor(info.type),
        uuid: info['id'],
        mapid: mapid,
        cameraMode: config['cameraMode']
      }
      if (pointCount < cubeMeshList.length) {
        const cube = cubeMeshList[pointCount]
        create.updateCube(cube, setting)
        cube['meshType'] = info.type
      } else {
        const cube = create.createNewCube(setting)
        group.add(cube)
        cubeMeshList.push(cube)
        cube['meshType'] = info.type
      }
      pointCount = pointCount + 1
      lineCount = lineCount + linkLineTwoPointAtSameMap(info, floorHeight, '#0000ff', lineCount)
    }

    if (lineCount < lineMeshList.length) {
      for (let i = lineCount; i < lineMeshList.length; i++) {
        const line = lineMeshList[i]
        line.visible = false
      }
    }

    if (pointCount < cubeMeshList.length) {
      for (let i = pointCount; i < cubeMeshList.length; i++) {
        const cube = cubeMeshList[i]
        cube.visible = false
      }
    }

    return textList;

  }

  function linkLineTwoPointAtSameMap(info, y, color, lineCount) {
    let index = 0
    if (analysis.jsonKeyIsExist(info, ['linkPoint']) == false) {
      return index
    }
    const result = info['linkPoint']
    for (const key in result) {
      const value = result[key]
      const start = value['start']
      if (start == info.id) {
        if (lineCount < lineMeshList.length) {
          const line = lineMeshList[lineCount]
          line.visible = true
          create.updateLine(line, [value.startP, value.endP], {
            mapid: info['mapid'],
            uuid: value.id
          })
        } else {
          const line = create.createNewLine([value.startP, value.endP], {
            color: color,
            mapid: info['mapid'],
            uuid: value.id
          })
          line.position.y = y
          group.add(line)
          lineMeshList.push(line)
        }
        lineCount = lineCount + 1
        index = index + 1
      }
    }
    return index
  }

  group.returnMeshList = function () {
    return cubeMeshList
  }

  group.clickLeadCube = function (object, leadInfo) {
    const mapid = object['mapid']
    const linkInfo = leadInfo[mapid]
    if (analysis.jsonKeyIsExist(linkInfo, [object.uuid]) == false) {
      console.log('storeClickCubeInfo - 不存在该cube信息')
      return
    }
    const cubeInfo = linkInfo[object.uuid]
    const info = {
      id: object.uuid,
      mapid: mapid,
      type: linkType
    }
    selectMeshList.push(object)
    if (selectMeshList.length == 2) {
      const first = selectMeshList[0]
      if (first.uuid == object.uuid && first.mapid == mapid) {
        // 点击相同的cube则取消
        first.material.color.set(returnTypeColor(cubeInfo.type))
        selectMeshList = []
        return
      }
      if (first.mapid == mapid) {
        // 同一地图的时候 为普通的链接点
        const firstInfo = linkInfo[first.uuid]
        linkPointAtSameMap(first.uuid, object.uuid, [returnTypeColor(firstInfo.type), returnTypeColor(cubeInfo.type)],
          linkInfo)
      } else {
        // 不同地图则为楼层链接
        for (let i = 0; i < selectMeshList.length; i++) {
          const mesh = selectMeshList[i]
          const info = linkInfo[mesh.uuid]
          mesh.material.color.set(returnTypeColor(info.type))
        }
        selectMeshList = []
        alert('不同楼层不能连接')
      }
      selectMeshList = []
      return
    }
    object.material.color.set(leadCubeInfo.selectColor)
  }

  // 同一楼层的连线
  function linkPointAtSameMap(start, end, colors, info) {
    for (let i = 0; i < selectMeshList.length; i++) {
      const mesh = selectMeshList[i]
      mesh.material.color.set(colors[i])
    }
    const json1 = info[start]
    const json2 = info[end]
    const isDelete = matrix.deleteTwoPointInfo(json1, json2, start, end)
    if (isDelete.result == true) {
      for (let i = 0; i < lineMeshList.length; i++) {
        const lineMesh = lineMeshList[i]
        if (lineMesh.uuid == isDelete.id) {
          lineMesh.visible = false
          return
        }
      }
    }
    const result = matrix.linkTwoPoint(json1, json2)
    for (let i = 0; i < lineMeshList.length; i++) {
      const line = lineMeshList[i]
      if (line.visible == false) {
        create.updateLine(line, [result.data.startP, result.data.endP], {})
        line.visible = true
        return
      }
    }
    const line = create.createNewLine([result.data.startP, result.data.endP], {
      color: '#0000ff'
    })
    group.add(line)
    lineMeshList.push(line)
  }

  group.clickCube = function (object) {
    for (let i = 0; i < selectMeshList.length; i++) {
      const mesh = selectMeshList[i]
      mesh.scale.set(1, 1, 1.0)
      mesh.material.color.set(returnTypeColor(mesh.meshType))
      if (mesh.uuid == object.uuid) {
        selectMeshList = []
        return {
          result: false
        }
      }
    }
    object.material.color.set(leadCubeInfo.selectColor)
    object.scale.set(3, 3, 3.0)
    selectMeshList = [object]
    return {
      result: true,
      id: object.uuid,
      mapid: object.mapid,
      x: object.position.x,
      y: object.position.y,
      z: object.position.z
    }
  }

  group.getSelectCube = function (id) {
    for (let i = 0; i < cubeMeshList.length; i++) {
      const mesh = cubeMeshList[i]
      if (mesh.uuid == id) {
        mesh.material.color.set(leadCubeInfo.selectColor)
        mesh.scale.set(3, 3, 3.0)
        selectMeshList = [mesh]
        return
      }
    }
  }

  group.deleteAll = function () {
    selectMeshList = []
    let allList = []
    allList = allList.concat(cubeMeshList)
    allList = allList.concat(lineMeshList)

    for (let i = 0; i < allList.length; i++) {
      const arr = allList[i]
      if (arr.parent != null) {
        group.remove(arr)
      }
      arr.geometry.dispose()
      arr.material.dispose()
    }
    cubeMeshList = []
    lineMeshList = []
  }

  group.getMatrix = function () {
    return matrix;
  }

  return group
}
