import {
  CSS2DRenderer
} from 'three/examples/jsm/renderers/CSS2DRenderer'
import * as THREE from 'three/build/three.min.js'
import {createAnalaysisInfo} from '@/assets/JS/analaysis/dataAnalaysisInfo'

import {createObjectAction} from '@/assets/JS/threeCreate/createObject'

export function createCSS2DManager() {
  const manager = new THREE.Group()

  const render = new CSS2DRenderer()

  const analysis = createAnalaysisInfo() // 构造地图解析

  const create = createObjectAction()

  const css2DList = []

  let windowWidth = 0

  let windowHeight = 0

  manager.init = function(config) {
    const divId = config['divId']
    const div = document.getElementById(divId)
    if (typeof div == 'undefined' || div == null) {
      return
    }
    const width = config['width']
    const height = config['height']
    render.setSize(width, height)
    windowWidth = width
    windowHeight = height
  }

  manager.reSize = function(config) {
    const width = config['width']
    const height = config['height']
    render.setSize(width, height)
    windowWidth = width
    windowHeight = height
  }

  manager.returnRender = function() {
    return {
      result: css2DList.length != 0,
      render: render
    }
  }

  manager.updateRender = function(camera, config) {
    if (css2DList.length == 0) {
      return
    }

    for (let i = 0; i < css2DList.length; i++) {
      const cube = css2DList[i]
      if (cube.visible == false) {
        continue
      }
      const viewProjectionMatrix = cube['viewProjectionMatrix']
      const viewMatrix = new THREE.Matrix4()
      viewMatrix.copy(camera.matrixWorldInverse)
      viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, viewMatrix)
    }
    this.updateDivPosition(config)
  }

  manager.updateDivPosition = function() {
    const _widthHalf = windowWidth / 2
    const _heightHalf = windowHeight / 2
    for (let i = 0; i < css2DList.length; i++) {
      const cube = css2DList[i]
      if (cube.visible == false || analysis.jsonKeyIsExist(cube, ['div']) == false) {
        continue
      }
      const element = cube['div']
      if (element.id != cube['divId']) {
        continue
      }
      const left = cube['left']
      const top = cube['top']
      const vector = cube['vector']
      const padding = cube['padding']

      const viewProjectionMatrix = cube['viewProjectionMatrix']
      vector.setFromMatrixPosition(cube.matrixWorld)
      vector.applyMatrix4(viewProjectionMatrix)
      const style = 'translate(' + (vector.x * _widthHalf + _widthHalf - left + padding) + 'px,' + (-vector.y *
        _heightHalf + _heightHalf - top) + 'px)'
      element.style.WebkitTransform = style
      element.style.MozTransform = style
      element.style.oTransform = style
      element.style.transform = style
    }
  }



  manager.updateCSS2DList = function(config) {
    const dataList = config['dataList']
    let index = 0
    let padding = 0
    if (analysis.jsonKeyIsExist(config, ['padding'])) {
      padding = config['padding']
    }
    for (let i = 0; i < dataList.length; i++) {
      const data = dataList[i]
      const divId = data['divId']
      if (analysis.jsonKeyIsExist(data, ['divId']) == false) {
        continue
      }
      const div = document.getElementById(divId)
      if (typeof div == 'undefined' || div == null) {
        continue
      }
      const x = data['x']
      const y = data['y']
      const z = data['z']
      let left = data['left']
      let top = data['top']
      if (index >= css2DList.length) {
        const cube = create.createCube(x, y, z, '#ff0000', null, 0.1)
        css2DList.push(cube)
        cube['divId'] = divId
        cube['div'] = div
        cube['vector'] = new THREE.Vector3()
        cube['viewProjectionMatrix'] = new THREE.Matrix4()
        cube['left'] = left
        cube['top'] = top
        cube['padding'] = padding
        manager.add(cube)
      } else {
        const cube = css2DList[index]
        cube.visible = true
        cube['divId'] = divId
        cube['div'] = div
        cube['vector'] = new THREE.Vector3()
        cube['viewProjectionMatrix'] = new THREE.Matrix4()
        cube['left'] = left
        cube['top'] = top
        cube['padding'] = padding
        cube.position.set(x, y, z)
      }
      div.style.display = 'block'
      index++
    }
    for (let i = index; i < css2DList.length; i++) {
      const cube = css2DList[i]
      cube.visible = false
    }
  }

  return manager
}
